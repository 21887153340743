import { SUPPORT_LIST_LOADING, SUPPORT_LIST_SUCCESS, SUPPORT_LIST_FAILURE, SUPPORT_LOADING, SUPPORT_SUCCESS, SUPPORT_FAILURE } from '../constants';

const INITIAL_VALUE = {
    supportListData: {},
    supportListSuccess: false,
    supportListLoading: false,
    supportListMessage: null,
    supportData: {},
    supportSuccess: false,
    supportLoading: false,
    supportMessage: null,
};

const SupportReducer = (state = INITIAL_VALUE, action) => {
    switch (action.type) {    
        case SUPPORT_LIST_LOADING:
            return {
                ...state,
                supportListLoading: true,
                supportListData: {},
                supportListSuccess: false,
                supportListMessage: null,
            };
        case SUPPORT_LIST_SUCCESS:
            return {
                ...state,
                supportListLoading: false,
                supportListSuccess: true,
                supportListData:  action.payload.data,
                supportListMessage: action.payload.message,
            };

        case SUPPORT_LIST_FAILURE:
            return {
                ...state,
                supportListLoading: false,
                supportListSuccess: false,
                supportListData:  {},
                supportListMessage: action.payload.message,
            };

            case SUPPORT_LOADING:
            return {
                ...state,
                supportLoading: true,
                supportData: {},
                supportSuccess: false,
                supportMessage: null,
            };
        case SUPPORT_SUCCESS:
            return {
                ...state,
                supportLoading: false,
                supportSuccess: true,
                supportData:  action.payload.data,
                supportMessage: action.payload.message,
            };

        case SUPPORT_FAILURE:
            return {
                ...state,
                supportLoading: false,
                supportSuccess: false,
                supportData:  {},
                supportMessage: action.payload.message,
            };

        default:
            return state;
    }
};

export default SupportReducer;
