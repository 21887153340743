import {
  GET_DUE_PAYMENT_FAILURE, GET_DUE_PAYMENT_SUCCESS, GET_DUE_PAYMENT_LOADING,
  GET_INVOICE_HISTORY_FAILURE, GET_INVOICE_HISTORY_SUCCESS, GET_INVOICE_HISTORY_LOADING,
} from '../constants';

const INITIAL_STATE = {
  duePaymentData: [],
  duePaymentSuccess: false,
  duePaymentLoading: false,
  duePaymentMessage: null,

  invoiceHistoryData: [],
  invoiceHistorySuccess: false,
  invoiceHistoryLoading: false,
  invoiceHistoryMessage: null,
};

const InvoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    /**
     * DUE PAYMENT REDUCERS
     */

    case GET_DUE_PAYMENT_LOADING:
      return {
        ...state,
        duePaymentLoading: true,
        duePaymentData: []
      };

    case GET_DUE_PAYMENT_SUCCESS:
      return {
        ...state,
        duePaymentLoading: false,
        duePaymentSuccess: true,
        duePaymentData: action.payload.data,
        duePaymentMessage: action.payload.message,
      };

    case GET_DUE_PAYMENT_FAILURE:
      return {
        ...state,
        duePaymentLoading: false,
        duePaymentSuccess: false,
        duePaymentMessage: action.payload.message,
      };

    /**
     * INVOICE HISTORY REDUCERS
     */
    case GET_INVOICE_HISTORY_LOADING:
      return {
        ...state,
        invoiceHistoryLoading: true,
        invoiceHistoryData: []
      };

    case GET_INVOICE_HISTORY_SUCCESS:
      return {
        ...state,
        invoiceHistoryLoading: false,
        invoiceHistorySuccess: true,
        invoiceHistoryData: action.payload.data,
        invoiceHistoryMessage: action.payload.message,
      };

    case GET_INVOICE_HISTORY_FAILURE:
      return {
        ...state,
        invoiceHistoryLoading: false,
        invoiceHistorySuccess: false,
        invoiceHistoryMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default InvoiceReducer;
