import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { SERVER_URL, SUPPORT_LIST_LOADING, SUPPORT_LIST_SUCCESS, SUPPORT_LIST_FAILURE, SUPPORT_LOADING, SUPPORT_SUCCESS, SUPPORT_FAILURE} from '../constants';
import { Logout } from './AuthAction';

export function SupportList(token, data, page = null, pageSize = null) {

  let queryString = '';
  if (data?.employee_id != null) {
    queryString += `employee_id=${data?.employee_id}&`;
  }
  if (data?.ticket_no != null) {
    queryString += `ticket_no=${data?.ticket_no}&`;
  }
  if (page !== null  && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}&`;
  }
  if (data?.yearMonth!=null) {
    queryString += `year_month=${data?.yearMonth}&`
  }
  if (data?.sortBy!=null) {
    queryString +=`sort=${data?.sortBy}&`
  }

  return (dispatch) => {
    dispatch(supportLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/support/ticket-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(supportSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(supportFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function CreateTicket(token, Data, close = null) {  
  const data = new FormData();
  data.append('support_title', Data.subject);
  data.append('support_category', Data.department);
  data.append('support_status', 'OPEN'); 
  data.append('support_priority', Data.priority); 
  data.append('created_by', Data.createdBy); 
  data.append('support_description', Data.message);
  if(Data.attachment && Data.attachment != "" && Data.attachment.length>0){
    for(let i=0;i<Data.attachment.length;i++)
    data.append('support_file', Data.attachment[i]); 
  }    
  return (dispatch) => {
    dispatch(supportAddLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/support/create-ticket`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(supportAddSuccess(res.data))
          if(close){close()};
        }
      }).catch((error) => {
        
        if(close){close()};
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(supportAddFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
/**[2022-05-17]
  * Created by: Aanchal Sahu
  * Description:- changes in Data.attachment due to multiple image in attachment
  **/
export function ReplyTicket(token, Data) {  
  const data = new FormData();   
  data.append('ticket_id', Data.ticket_id); 
  data.append('created_by', Data.createdBy); 
  data.append('reply_message', Data.message);
  if(Data.attachment && Data.attachment != "" && Data.attachment.length>0){
    for(let i=0;i<Data.attachment.length;i++)
    data.append('support_file', Data.attachment[i]); 
  }   

  return (dispatch) => {
    dispatch(supportAddLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/support/reply-ticket`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(supportAddSuccess(res.data))
          dispatch(TicketDetails(token, Data.ticket_id))          
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(supportAddFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function TicketDetails(token, ticket_id) {

  let queryString = '';
  if (ticket_id != null) {
    queryString += `ticket_id=${ticket_id}`;
  }  

  return (dispatch) => {
    dispatch(supportAddLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/support/ticket-detail?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(supportAddSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(supportAddFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function supportLoading() {
    return {
      type: SUPPORT_LIST_LOADING,
    };
  }
  export function supportSuccess(payload) {
    return {
      type: SUPPORT_LIST_SUCCESS,
      payload: payload
    };
  }
  
  export function supportFailure(payload) {
    return {
      type: SUPPORT_LIST_FAILURE,
      payload
    };
  }

  export function supportAddLoading() {
    return {
      type: SUPPORT_LOADING,
    };
  }
  export function supportAddSuccess(payload) {
    return {
      type: SUPPORT_SUCCESS,
      payload: payload
    };
  }
  
  export function supportAddFailure(payload) {
    return {
      type: SUPPORT_FAILURE,
      payload
    };
  }
