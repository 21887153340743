import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { DEPARTMENT_FAILURE, DEPARTMENT_LOADING, DEPARTMENT_SUCCESS, DEPARTMENT_LIST_FAILURE, DEPARTMENT_LIST_SUCCESS, DEPARTMENT_LIST_LOADING, SERVER_URL } 
from '../constants';

export function CreateDepartment(token, Data, close=null) {  
    const data = new FormData();
    data.append('department_name',Data.department_name);
    data.append('status',Data.status);  

  return (dispatch) => {
    dispatch(departmentLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/department/create-department`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: Data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(departmentSuccess(res.data))
          dispatch(DepartmentList(token))
          if(close){close()}
          // navigate('/master/department', { replace: true });
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(departmentFailure(error.response.data));
          if(close){close()}
          if (error?.response?.status == 401) {
            // dispatch(Logout())
          }
        }
      });
  };
}
export function UpdateDepartment(token, Data, close=null) {  
  const data = new FormData();
  data.append('department_name',Data.department_name);
  data.append('status',Data.status);  
  data.append('id',Data.departmentId)

return (dispatch) => {
  dispatch(departmentLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/department/update-department`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data
  })
    .then((res) => {
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        })
        dispatch(departmentSuccess(res.data))
        // navigate('/master/department', { replace: true });
        if(close){close()}
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        dispatch(departmentFailure(error.response.data));
        if(close){close()}
        if (error?.response?.status == 401) {
          // dispatch(Logout())
        }
      }
    });
};
}


export function DeleteDepartment(token, Data, close=null) {  
  const data = new FormData();
  data.append('department_id',Data.id)

return (dispatch) => {
  dispatch(departmentLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/department/delete-department`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data
  })
    .then((res) => {
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        })
        dispatch(departmentSuccess(res.data))
        // navigate('/master/department', { replace: true });
        if(close){close()}
        dispatch(DepartmentList(token))
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        dispatch(departmentFailure(error.response.data));
        if(close){close()}
        if (error?.response?.status == 401) {
          // dispatch(Logout())
        }
      }
    });
};
}


export function departmentLoading() {
  return {
    type: DEPARTMENT_LOADING,
  };
}
export function departmentSuccess(payload) {
  return {
    type: DEPARTMENT_SUCCESS,
    payload
  };
}

export function departmentFailure(payload) {
  return {
    type: DEPARTMENT_FAILURE,
    payload
  };
}


export function DepartmentList(token, Data = {}, page = null, pageSize = null) {

let queryString = "";

  if (page !== null  && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }

  if (Data?.department && Data?.department !=='') {
    queryString += `&department_name=${Data?.department}`;
  }


  return (dispatch) => {
    dispatch(departmentListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/department/department-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(departmentListSuccess(res.data))
        }
      }).catch((error) => {
       
        if (error?.response) {
          dispatch(departmentListFailure(error.response.data));
          // if (error?.response?.status == 401) {
          // } ^ upper commented code block was blank
          if (error?.response?.status == 401) {
            // dispatch(Logout())
          }
        }
      });
  };
}


export function departmentListLoading() {
  return {
    type: DEPARTMENT_LIST_LOADING,
  };
}
export function departmentListSuccess(payload) {  
  return {
    type: DEPARTMENT_LIST_SUCCESS,
    payload
  };
}

export function departmentListFailure(payload) {
  return {
    type: DEPARTMENT_LIST_FAILURE,
    payload
  };
}

