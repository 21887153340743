import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../BaseComponents/EPage';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EBoxPage } from '../../BaseComponents/EBox';
import { EDivider } from '../../BaseComponents/EDivider';
import { StateList } from '../../action/StateCityAction';
import { SUPER_ADMIN } from '../../constants';
import { useCompanySettingProvider } from "../../context/CompanySettingContext";
// import { useCheckPermission } from '../../hooks';
import CompanyInfo from '../../PageComponents/MyCompany/CompanyInfo';
import AddressDetails from '../../PageComponents/MyCompany/AddressDetails';
import PrimaryContract from '../../PageComponents/MyCompany/PrimaryContract';


function MyCompany(props) {
   const { setting } = useCompanySettingProvider();
   const { auth } = props;
   const dispatch = useDispatch();
   const company = useSelector((state) => state.CompanyReducer?.companyData);
   const primaryAdmin = setting.accountSettingData?.companySetting?.company_data?.primaryAdmin;
   // const allowEdit = useCheckPermission([PERMISSIONS.ViewEditCompanyProfile])
   const [open, setOpen] = useState({ isCompanyInfo: false, isAddressDetails: false });

   useEffect(() => {
      if (auth.authData?.id) {
         dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
      }
   }, [auth]);

   return (
      <>
         <Page title="My Company" >

            <Grid container display="row" justifyContent="space-between" className='align-center'>
               <Grid item >
                  <EHeaderBreadcrumbs
                     heading='My Company'
                     links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Profile', href: '' },
                        {
                           name: 'My Company',
                        }
                     ]}
                  />
               </Grid>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
               <Grid item>
                  <ETypographyPageHeading variant='h5'> My Company</ETypographyPageHeading>
               </Grid>
               <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid>
            </Grid>

            <EBoxPage>
               <CompanyInfo
                  company={company}
                  open={open}
                  setOpen={setOpen}
                  allowEdit={auth?.authData?.role == SUPER_ADMIN}
               />
               <AddressDetails
                  company={company}
                  open={open}
                  setOpen={setOpen}
                  allowEdit={auth?.authData?.role == SUPER_ADMIN}
                  auth={auth}
               />
               {auth.authData.role=="SUPERADMIN" ?<PrimaryContract primaryAdmin={primaryAdmin} />:""}

   
            
               
            </EBoxPage>
         </Page>
      </>
   );
}
export default MyCompany;
