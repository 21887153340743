import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Grid, styled, useTheme } from "@mui/material";
import { THEMES } from "../../constants";
import { EBox, EBoxHRDash, EBoxHrLEave } from "../../BaseComponents/EBox";
import { ETypography } from "../../BaseComponents/ETypography";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ETextField } from "../../BaseComponents/ETextField";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend);

const CheckInStats = ({ CheckinStatsArray=[] }) => {
  // CheckinStatsArray= []

  const theme = useTheme();

  // Initializing the state variables for selectedShift, labels, and backgroundColors
  const [selectedShift, setSelectedShift] = useState(
    CheckinStatsArray?.length > 0 ? CheckinStatsArray[0].shift_name : ""
  );
  const [labels, setLabels] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState([]);

  // Finding the shiftData that matches the selectedShift
  const shiftData = CheckinStatsArray?.find(
    (item) => item.shift_name === selectedShift
  );

  // chart data
  const data = {
    labels: shiftData?.label || [], // Using the label property of the shiftData if it exists, otherwise using an empty array
    datasets: [
      {
        data: shiftData?.data || [], // Using the data property of the shiftData if it exists, otherwise using an empty array
        backgroundColor: shiftData?.background_color || [], // Using the background_color property of the shiftData if it exists, otherwise using an empty array
      },
    ],
  };

  //chart options
  const options = {
    responsive: true,
    cutout: "83%",
    borderRadius: 25,
    borderColor: theme.palette.background.default,
    displayColor: false,

    plugins: {
      LightTheme: theme?.mode != THEMES.DARK ? true : false, //conditionally applying, dark and light theme plugin, found this one solution for changing plugin conditionally
      DarkTheme: theme?.mode == THEMES.DARK ? true : false,

      legend: {
        display: false,
        position: "top", // lable position left/right/top/bottom
      },

      tooltip: {
        enabled: false,
      },

      datalabels: {
        formatter: (value, ctx) => {
          if (value != 0) return value; //formatting the data label, display the value to the side of chart.()
          else return null
        },
        color: theme.palette.text.primary,
        anchor: "end",
        align: "start",
        offset: -25,
      },
    },

    layout: {
      padding: {
        left: 25,
        right: 25,
        top: 25,
        bottom: 25,
      },
    },
  };

  // data,  if array is empty or does not exists, as we still want to display dognut chart with no data
  const emptyData = {
    labels: [],
    datasets: [
      {
        label: "No data",
        data: [100],
        backgroundColor: ["#E7E9F0"],
      },
    ],
  };

  // options of chart if there is no data
  const emptyOptions = {
    responsive: true,
    cutout: "80%",
    displayColor: false,
    plugins: {
      LightTheme: theme?.mode != THEMES.DARK ? true : false, //conditionally applying, dark and light theme plugin, found this one solution for changing plugin conditionally
      DarkTheme: theme?.mode == THEMES.DARK ? true : false,

      legend: {
        display: false,
        position: "top", // lable position left/right/top/bottom
      },

      tooltip: {
        enabled: false,
      },

      datalabels: {
        formatter: () => "No data found", //formatting the data label, display the value to the side of chart.

        color: theme.palette.text.primary,
        font: {
          size: "17px",
          weight: "500",
        },
        anchor: "start",
        align: "start",
        offset: 70,
      },
    },

    layout: {
      padding: {
        left: 25,
        right: 25,
        top: 25,
        bottom: 25,
      },
    },
  };

  // Using the useEffect hook to set the labels and backgroundColors state variables when selectedShift or CheckinStatsArray changes
  useEffect(() => {
    if (selectedShift) {
      const selectedShiftData = CheckinStatsArray.find(
        (item) => item.shift_name === selectedShift
      );
      setLabels(selectedShiftData?.label || []);
      setBackgroundColors(selectedShiftData?.background_color);
    }
  }, [selectedShift, CheckinStatsArray]);

  const isDataAvailable = shiftData?.data?.filter(x => x > 0).length > 0;
  
  return (
    <>
      <EBoxHRDash className="font-size-20px bold-400">
        <ETypography className="font-size-20px bold-600">
          Today's Check-in Statistics
        </ETypography>

        {
          // If there are more than 1 shift, only then we are displaying the Autocomplete component
          CheckinStatsArray && CheckinStatsArray.length > 1 && (
            <EAutocomplete
              clearIcon={false}
              // disableTextInput ={true}
              options={CheckinStatsArray?.map((item) => item.shift_name)}
              value={selectedShift}
              onChange={(event, value) => setSelectedShift(value)}
              sx={{ width: "204px" }}
              renderInput={(params) => (
                <ETextField
                  {...params}
                  placeholder="Shift"
                />
              )}
            />
          )
        }
      </EBoxHRDash>

      <EBox style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <Grid style={{ width: '60%' }}>
        {/* <Doughnut
              data={emptyData}
              options={emptyOptions}
              plugins={[ChartDataLabels]}
              width="100%"
            /> */}
            
          {isDataAvailable ? (
            <Doughnut
              data={data}
              options={options}
              plugins={[ChartDataLabels]}
              width="100%"
            />
          ) : (
            //For no data 
            <Doughnut
              data={emptyData}
              options={emptyOptions}
              plugins={[ChartDataLabels]}
              width="100%"
            />

          )}
        </Grid>
        <EBox sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {CheckinStatsArray?.length > 0 &&
            labels?.map((label, index) => (
              <EBoxHrLEave
                key={index}
                sx={{ marginRight: "26px", marginBottom: "8px" }}
              >
                <LabelColorDiv
                  sx={{ backgroundColor: backgroundColors[index] }}
                />
                <span>{label}</span>
              </EBoxHrLEave>
            ))}
        </EBox>
      </EBox>
    </>
  );
};

export default CheckInStats;

const LabelColorDiv = styled(EBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "14px",
  height: "14px",
  borderRadius: "2px",
  flex: "0 1 auto",
  marginRight: "4px",
  order: 0,
  flexWrap: "wrap",
}));
