import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import AuthForgotEmailForm from "../../PageComponents/AuthForgotEmailForm";
import AuthForgotEmailSent from "../../PageComponents/AuthForgotEmailSent";
import { useDispatch, useSelector } from "react-redux";
import { ELink, ELinkMUI } from "../../BaseComponents/ELink";
import { linkClear } from "../../action/AuthAction";
import AuthPage from "../../BaseComponents/EAuthPage";
import { AuthNavbar } from "../../layouts/auth-navbar";
import { AuthContentInner, AuthContentStyle, AuthRootStyle } from "../../BaseComponents/EContainer";
import { ETypography } from "../../BaseComponents/ETypography";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { PRIVACY_POLICY_LINK, TERMS_OF_SERVICE_LINK } from "../../constants";
import { EBox } from "../../BaseComponents/EBox";

export const ContentBottomBack = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1.5, 0),
  textAlign: 'center',
  backgroundColor: '#F5F5F5',
  borderRadius: '0 0 8px 8px',
}));

const ChangePassword = () => {
  const dispatch = useDispatch();
  const resetLink = useSelector((state) => state.authResetLinkReducer);

  useEffect(() => {
    //clear email from reset link reducer
    dispatch(linkClear());
  }, []);


  return (
    <AuthPage title="Forgot Password" >
      {!resetLink.authResetLoading ?
        <>
          {!resetLink.authLinkEmail ? <AuthNavbar /> : ''}

          <AuthRootStyle maxWidth="lg">
            <Container maxWidth="sm" className="text-align-webkit-center">

              {!resetLink.authLinkEmail ?
                <>
                  <AuthContentStyle className="p0 grayColor2-color-border">
                    <AuthContentInner>
                      <AuthForgotEmailForm />
                    </AuthContentInner>
                    <ContentBottomBack className=" grayColor2-color-border">
                      <ELink to={'/login'}>
                        Back to <span className="link-color bold-600 ">Login</span>
                      </ELink>
                    </ContentBottomBack>
                  </AuthContentStyle>

                  <AuthContentStyle className="p0 bg-transparent">
                    <ETypography className="font-size-12px bold-400 text-align pt-18px px-16px">
                      This site is protected by reCAPTCHA and the Google{" "}
                      <ELinkMUI className='link-color' href={PRIVACY_POLICY_LINK} target="_blank">Privacy Policy </ELinkMUI> and{" "}
                      <ELinkMUI className='link-color' href={TERMS_OF_SERVICE_LINK} target="_blank">Terms of Service </ELinkMUI> apply.
                    </ETypography>
                  </AuthContentStyle>
                </>
                :
                <AuthForgotEmailSent email={resetLink.authLinkEmail} />
              }
            </Container>
          </AuthRootStyle>
        </>
        :
        <EBox sx={{height:'100vh'}}>
            <LoadingGrid size='xl'  />
        </EBox>
       
      }
    </AuthPage>
  );
};

export default ChangePassword;