
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ETypography } from "../../BaseComponents/ETypography";
import { ECardDashboard } from "../../BaseComponents/ECard";
import Attendance from "../Attendance/Attendance";
import { formatTimeString } from "../../utils/CommonFunctions";
import { useTheme } from "@emotion/react";

function CheckinCheckout(props) {
  const { AttendanceArray, setIsMinimunHoursComplete, isMinimunHoursComplete, IS_BIOMETRIC_CHECK_ENABLED, IS_MOBILE_CHECK_ENABLED, IS_WEB_CHECK_ENABLED } = props
  const theme = useTheme()
  const { checkin_time,
    checkout_time, show_as_talent, min_hour_for_present, minimum_hrs,
    office_open_time } = AttendanceArray
  const min_hour = min_hour_for_present && moment().format('YYYY-MM-DD ') + (min_hour_for_present.replace('.', ":")) + ':00'
  const open_time = min_hour_for_present && moment().format('YYYY-MM-DD ') + office_open_time
  const StandardbufferMinute = open_time && min_hour ? moment(open_time).diff(moment(min_hour), 'minutes') : null
  const LateHoursCount = open_time && min_hour ? moment(checkin_time).diff(moment(open_time), 'seconds') : null
  const lateTime = { hrs: moment.utc(LateHoursCount * 1000).format('HH [hrs] '), mins: moment.utc(LateHoursCount * 1000).format('mm [mins]') }

  return (
    <>
      <ECardDashboard sx={{ marginTop: '20px' }}>
        <Grid container>
          <Attendance status="dashboard" IS_WEB_CHECK_ENABLED={IS_WEB_CHECK_ENABLED} />
        </Grid>
      </ECardDashboard>
      {
        !IS_WEB_CHECK_ENABLED
        &&
        <Typography
          sx={{ marginTop: '16px', fontWeight: 600, color: theme.palette.text.greyColor8 }}
          className="font-size-14px text-align line-height-1"
        >
          {/* What message to display */}
          <MessageDecide 
            IS_BIOMETRIC_CHECK_ENABLED={IS_BIOMETRIC_CHECK_ENABLED}
            IS_MOBILE_CHECK_ENABLED={IS_MOBILE_CHECK_ENABLED}
          />
        </Typography>
      }

      {
        checkout_time &&
        <ECardDashboard sx={{ marginTop: '16px', width: '100%', padding: '8px 16px', borderRadius: '12px' }} >
          <ETypography variant="h4" className="font-size-20px text-align line-height-1" >
            <CountDownFunction checkin_time={checkin_time}
              checkout_time={checkout_time}
              minimum_hrs={minimum_hrs}
              setIsMinimunHoursComplete={setIsMinimunHoursComplete}
              isMinimunHoursComplete={isMinimunHoursComplete}
              show_as_talent={show_as_talent}
            /> worked today !
          </ETypography>

        </ECardDashboard>
      }
    </>
  );
}

//function for timer
export default React.memo(CheckinCheckout)

function CountDownFunction(props) {
  const { checkin_time, checkout_time, setIsMinimunHoursComplete, minimum_hrs, isMinimunHoursComplete, show_as_talent } = props
  const [final, setFinal] = useState()
  var a = checkin_time && !checkout_time ? moment() : moment(checkout_time)
  var b = checkin_time && !checkout_time ? moment(checkin_time) : moment(checkin_time);
  var difference = a.diff(b, 'seconds')
  var FinalReturnTime = show_as_talent && checkin_time && moment.utc(difference * 1000).format('HH:mm:ss') ? moment.utc(difference * 1000).format('HH:mm:ss') : '00:00:00'

  useEffect(() => {
    if (checkin_time && checkout_time == null && show_as_talent) {
      var timerID = setInterval(() => TimeFunction(), 1000);
      return function cleanup() {
        clearInterval(timerID);
      };
    }
  }, [checkin_time, checkout_time]);

  const TimeFunction = () => {
    setFinal(moment().format('hh:mm:ss A')); //to update this function
    var total_hours_served = moment().diff(moment(FinalReturnTime), 'minutes') ?
      moment(FinalReturnTime).diff(moment(FinalReturnTime), 'minutes') / 60 : 0
    if (!isMinimunHoursComplete && minimum_hrs && (total_hours_served >= minimum_hrs)) {
      setIsMinimunHoursComplete(true)
    }
  }

  return formatTimeString(FinalReturnTime);
}

function MessageDecide (props)  {
  const { IS_BIOMETRIC_CHECK_ENABLED, IS_MOBILE_CHECK_ENABLED } = props
  let message;

  switch (true) {
    case IS_BIOMETRIC_CHECK_ENABLED && IS_MOBILE_CHECK_ENABLED:
      message = "You can mark your attendance only through registered biometric device or mobile application";
      break;
    case IS_MOBILE_CHECK_ENABLED:
      message = "You can mark your attendance using mobile app only.";
      break;
    case IS_BIOMETRIC_CHECK_ENABLED:
      message = "You can mark your attendance using registered biometric device only.";
      break;
    default:
      message = "";
      break;
  }

  return <>{message}</>
};
