import React, { useState } from "react";
import moment from "moment";
import { DownLoadFile } from "../../action/AdminAction";
import { ATTENDANCE_REGULARIZATION_REQUEST_DOWNLOAD_URL, DOWNLOAD_CSV, DOWNLOAD_PDF, REGULARIZATION_REQUEST_STATUS_OPTIONS } from "../../constants";
import { PaymentSearchFilter } from "../Payment/PaymentSearchFilter";
import { EBox } from "../../BaseComponents/EBox";



function RegularizationSearchFields(props) {
  const {
    setSearchParam,
    searchParam,
    employeeListData,
    auth
  } = props;
  const [yearmonthValue, setyearmonthValue] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const DownloadReport = (type) => {
    const employee_id = searchParam?.employee_id;
    const year_month = searchParam?.year_month;
    const sort = searchParam?.sort;
    let params = {
      download_format: type,
      download: true,
      ...(year_month && { year_month }),
      ...(employee_id && { employee_id }),
      ...((sort === true || sort === false) && { sort }),
    };
    let queryString = new URLSearchParams(params).toString();

    if (Array.isArray(searchParam.status)) {
      let statusParam = searchParam.status.map(value => `status=${value}`).join('&');
      queryString = `${statusParam+'&'+queryString}`
    }
    const url = `${ATTENDANCE_REGULARIZATION_REQUEST_DOWNLOAD_URL}?${queryString}`;
    DownLoadFile(auth.authtoken, url, "Regularization-request-report")
    setAnchorEl(null);
  }



  const [searchParam2, setSearchParam2] = useState({
    start: null,
    employee:null
  });

  function onSearch(data) {
    const params = {
      start: data?.start ? data?.start : null,
      year_month: data?.start ? moment(data?.start).format("YYYYMM") : null,
      sort: data?.sort,
      employee_id: data?.employee?.id ? data?.employee?.id : null,
      status: data?.status ? data?.status?.map(item => item.id) : null
    }

    setyearmonthValue(params?.year_month && params?.year_month != 'Invalid Date' ? params?.year_month : null)
    const sParams = { ...searchParam, ...params };
    setSearchParam(sParams);
  }


  return (
    <>
      <PaymentSearchFilter
        initialFilter={searchParam2}
        onSearch={onSearch}
        onSort={onSearch}
        status={REGULARIZATION_REQUEST_STATUS_OPTIONS}
        DownloadReport={DownloadReport}
        showTalents={true}
        talentOrder={5}
        statusOrder={6}
        TalentPlaceholder="Select Employee"
        start={{
          placeholder: 'All Month',
          views: ['month', 'year'],
          inputFormat: "MMM yyyy",
          // error: dateError?.startDateError,
          // helperText: dateError?.startDateError ? dateError?.startDateError : null,
          // minDate : moment(company?.companyData?.CompanyActivePlan?.plan_valid_from),
          // maxDate : moment(company?.companyData?.CompanyActivePlan?.plan_valid_to)
        }}
      />
      <EBox sx={{ height: '16px' }} />

    </>
  );
}

export default RegularizationSearchFields;
