import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { SERVER_URL, SALARY_APPRAISAL_LOADING,  SALARY_APPRAISAL_FAILURE, SALARY_APPRAISAL_LIST_SUCCESS, SALARY_APPRAISAL_SAVE_SUCCESS } from '../constants';
import { Logout } from './AuthAction';
import { EmployeeListData } from './EmployeeAction';


export function TalentAppraisalList(token, data, page = null, pageSize = null) {

  let queryString = '';
  

  if (page !== null && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}`;
  }
  if (data?.employee_id) {
    queryString += (queryString == '' ? '' : '&') + `employee_id=${data?.employee_id}`;
}


  return (dispatch) => {
    dispatch(salaryAppraisalLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/appraisal/appraisal-list?${queryString}`,
      crossDomain: true,
       
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(salaryAppraisalSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(salaryAppraisalFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


/**
 * [2022-08-22]
 * Changes by:- Aanchal Sahu
 * Description:- this api is used for add/edit paycut and add/edit appraisal
 **/
export function CreateTalentSalaryAppraisal(token, Data, getFreshAppraisalList = null) {
  let url = 'appraisal/save-appraisal'
  const data = new FormData();
  data.append('employee_id', Data.employeeId);
  data.append('appraisal_from_date', Data.from_date);
  data.append('is_schedule', Data.schedule_month);
  // data.append('is_pay_cut', Data.is_pay_cut);
  data.append('remark', Data.remark);
  data.append('appraisal_type', Data.appraise_type);
  if (Data.appraisal_id) {
    data.append('appraisal_id', Data.appraisal_id);
  }

  if (Data.appraise_type != 'SALARY') {
    data.append('designation_id', Data.designation.id);
    data.append('department_id', Data?.department?.id);
  }

  if (Data.appraise_type != 'DESIGNATION') {
    data.append('amount_before_appraisal', Data.current_ctc);
    data.append('appraisal_amount', Data.appraisal_amount);
    data.append('amount_after_appraisal', Data.final_salary);
  }


  return (dispatch) => {
    dispatch(salaryAppraisalLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/${url}`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(salaryAppraisalSaveSuccess())
        
          if(getFreshAppraisalList){getFreshAppraisalList();}
        }
      }).catch((error) => {
        if(getFreshAppraisalList){getFreshAppraisalList();}
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(salaryAppraisalFailure(error.response.data));
       
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
        
      });
  };
}

export function DeleteTalentSalaryAppraisal(token, id, empId, getFreshAppraisalList = null) {
  const data = new FormData();
  data.append('employee_id', empId);
  data.append('appraisal_id', id);
  return (dispatch) => {
    dispatch(salaryAppraisalLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/appraisal/delete-appraisal`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(salaryAppraisalSaveSuccess());
          if(getFreshAppraisalList){getFreshAppraisalList();}
        }
      }).catch((error) => {
        if(getFreshAppraisalList){getFreshAppraisalList();}
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(salaryAppraisalFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function salaryAppraisalLoading() {
  return {
    type: SALARY_APPRAISAL_LOADING,
  };
}
export function salaryAppraisalSuccess(payload) {
  return {
    type: SALARY_APPRAISAL_LIST_SUCCESS,
    payload: payload
  };
}

export function salaryAppraisalSaveSuccess() {
  return {
    type: SALARY_APPRAISAL_SAVE_SUCCESS,
  };
}

export function salaryAppraisalFailure(payload) {
  return {
    type: SALARY_APPRAISAL_FAILURE,
    payload
  };
}

