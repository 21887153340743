import React from "react";
import { Grid } from '@mui/material';
import { ETypography } from "../../BaseComponents/ETypography";
import { EButton } from "../../BaseComponents/EButtons";
import { useNavigate } from "react-router-dom";

const MailNotVerified = () => {
    const navigate = useNavigate()
    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="center"
            py={25}
        >
             <img className="mb-16px" src='/assets/images/error.svg' />
            <ETypography className="font-size-24px bold-600 text-align mb-16px">
                Email Verification Failed
            </ETypography>

            <ETypography sx={{ maxWidth: '634px' }} className="font-size-18px bold-400 text-align mb-16px">
                We’re sorry, somethings has gone wrong. Please signup again.
            </ETypography>

            <EButton onClick={() => navigate('/sign-up', { replace: true, state: { fromEmailSuccess: true } })}>
                Signup
            </EButton>



        </Grid>
    );
};

export default MailNotVerified;