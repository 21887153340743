import { Avatar, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { ECardBorderLess } from "../../BaseComponents/ECard";
import { ETypography } from "../../BaseComponents/ETypography";
import base64 from 'base-64'
import { ACTIVE_USER, RELEASED_USER } from "../../constants";
import SearchNotFound from "../SearchNotFound";
import moment from "moment";
import { getInitials, HandlePhoto} from "../../utils/CommonFunctions";
import { ECardPagination } from "../../BaseComponents/ETablePagination";

function TalentListCard(props) {

    const { employeeListData, handleChangePage, page, rowsPerPage, userStatus, authPermission } = props;
    const totalNoOfPages = (employeeListData?.count / 12) ? Math.ceil(employeeListData?.count / 12) : 0;

    let thisPage = (page+1);

    return (
        <>
            <Grid container spacing={2}>
                {
                    (employeeListData?.rows && employeeListData?.rows?.length > 0 ? (

                        employeeListData?.rows && employeeListData?.rows.map((row, index) => {

                            const { id, fullname, designationInfo, employeeInfo, company_id ,trash_date,release_date} = row;
                            return (
                                <Grid item xs={12} sm={6} md={4} xl={3} key={index} >
                                    <ECardBorderLess className={"person-card-css "+(userStatus == ACTIVE_USER?"height-220px":"height-240px")}>
                                        <Grid
                                            to={authPermission?`/employee/${base64.encode(row.id)}`:''}//(new updated on - 24-4-23)
                                            component={Link}
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Avatar
                                                title={fullname} alt={fullname}
                                                src={(employeeInfo?.personal_photo || employeeInfo?.personal_gender)? HandlePhoto(employeeInfo?.personal_photo, employeeInfo?.personal_gender):'  '}
                                                key={index} 
                                                className='cursor-pointer card-view-avatar borderRadius-20px'
                                            > 
                                            {!employeeInfo?.personal_gender && <span className="font-size-20px theme-color-static">{getInitials(fullname)}</span>}
                                            </Avatar>
                                            <ETypography className={"card-name-text-style text-dots pt-16px "+(userStatus == ACTIVE_USER?'pt-16px':'')} >
                                                {fullname}
                                            </ETypography>
                                            <ETypography className="card-name-text-style-2 text-dots">
                                                {designationInfo?.designation_name ? designationInfo?.designation_name : '-'}
                                            </ETypography>
                                            {userStatus !== ACTIVE_USER && 
                                            <ETypography className="font-size-14px color-text-error-red bold-600">
                                                {userStatus == RELEASED_USER  && release_date ? moment(release_date).format('DD/MM/YYYY') : 
                                                trash_date?  moment(trash_date).format('DD/MM/YYYY'):
                                                '-'}
                                            </ETypography>}
                                        </Grid>
                                    </ECardBorderLess>
                                </Grid>
                            )
                        })
                    ) : ( <SearchNotFound />))
                }

            </Grid>

            {employeeListData?.rows && employeeListData?.rows?.length > 0 &&
                <Grid container my={3}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">

                    <ECardPagination
                        size="large"
                        count={totalNoOfPages}
                        page={thisPage}
                        onChange={handleChangePage}
                        boundaryCount={0}
                    />
                </Grid>}
        </>
    );
}

export default TalentListCard;
