import { 
    FREELANCER_LIST_LOADING, 
    FREELANCER_LIST_SUCCESS, 
    FREELANCER_LIST_FAILURE,
    FREELANCER_LOADING,
    FREELANCER_SUCCESS,
    FREELANCER_FAILURE,ALL_FREELANCER_FAILURE, ALL_FREELANCER_LOADING, ALL_FREELANCER_SUCCESS
} from '../constants';

const INITIAL_VALUE = {
    freelanceListData: {},
    freelanceListSuccess: false,
    freelanceListLoading: false,
    freelanceListMessage: null,
    
    freelanceData: {},
    freelanceSuccess: false,
    freelanceLoading: false,
    freelanceMessage: null,

    //All Freelancer List, The Following variables are for storing all the Freelancer's list
    allFreelancerData: {},
    allFreelancerSuccess: false,
    allFreelancerLoading: false,
    allFreelancerMessage: null,
};

const FreelancerReducer = (state = INITIAL_VALUE, action) => { 
    switch (action.type) {    
        case FREELANCER_LIST_LOADING:
            return {
                ...state,
                freelanceListLoading: true,
                freelanceListData: {},
                freelanceListSuccess: false,
                freelanceListMessage: null,
            };
        case FREELANCER_LIST_SUCCESS:
            return {
                ...state,
                freelanceListLoading: false,
                freelanceListSuccess: true,
                freelanceListData:  action.payload.data,
                freelanceListMessage: action.payload.message,
            };

        case FREELANCER_LIST_FAILURE:
            return {
                ...state,
                freelanceListLoading: false,
                freelanceListSuccess: false,
                freelanceListData:  {},
                freelanceListMessage: action.payload.message,
            };


            case FREELANCER_LOADING:
            return {
                ...state,
                freelanceLoading: true,
                freelanceData: {},
                freelanceSuccess: false,
                freelanceMessage: null,
            };
        case FREELANCER_SUCCESS:
            return {
                ...state,
                freelanceLoading: false,
                freelanceSuccess: true,
                freelanceData:  action.payload.data,
                freelanceMessage: action.payload.message,
            };

        case FREELANCER_FAILURE:
            return {
                ...state,
                freelanceLoading: false,
                freelanceSuccess: false,
                freelanceData:  {},
                freelanceMessage: action.payload.message,
            };
            case ALL_FREELANCER_LOADING:
                return {
                  ...state,
                  allFreelancerLoading: true,
                  allFreelancerData: {},
                };
          
              case ALL_FREELANCER_SUCCESS:
                return {
                  ...state,
                  allFreelancerLoading: false,
                  allFreelancerSuccess: true,
                  allFreelancerData: action.payload.data,
                  allFreelancerMessage: action.payload.message,
                };
          
              case ALL_FREELANCER_FAILURE:
                return {
                  ...state,
                  allFreelancerLoading: false,
                  allFreelancerSuccess: false,
                  allFreelancerMessage: action.payload.message,
                  allFreelancerData: {},
                };
        default:
            return state;
    }
};

export default FreelancerReducer;
