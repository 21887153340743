import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { CompanyDetail } from './CompanyAction';
import { ON_BOARD_FAILURE, ON_BOARD_SUCCESS, ON_BOARD_LOADING, PLAN_FAILURE, PLAN_LOADING, PLAN_SUCCESS, SERVER_URL, SUBSCRIPTION_PLAN_FAILURE, SUBSCRIPTION_PLAN_SUCCESS, SUBSCRIPTION_PLAN_LOADING }
  from '../constants';
import Cookies from 'universal-cookie';
import { Logout, verifyToken } from './AuthAction';
const cookies = new Cookies();

//For Cookies expiration
let cookieDateExpire = new Date();
cookieDateExpire.setHours(23, 59, 59, 999);

export function createCompany(Data, navigate) {
  const data = new FormData();
  data.append('plan_id', Data.plan_id);
  data.append('fullname', Data.fullname);
  data.append('username', Data.username);
  data.append('company_name', Data.company_name);
  data.append('mobile', Data.mobile);
  data.append('email', Data.email);
  data.append('password', Data.password);
  data.append('user_title', Data.user_title);
  data.append('token', Data.captcha_token)

  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/create-company`,
      // headers: {
      //   "Authorization": 'Bearer ' + token,
      // },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(onboardSuccess(res.data))
          dispatch(getPlan());
          navigate('/subscription', { replace: true });
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(onboardFailure(error.response.data));
          // if(close){close()}
          // if (error?.response?.status == 401) {
          // dispatch(Logout())
          // }
        }
      });
  };
}

export function createSubscription(Data) {
  const data = new FormData();
  data.append('subscription_id', Data.subscription_id);
  data.append('card_no', Data.card_number);
  data.append('card_holder_name', Data.card_holder_name);
  data.append('card_expiry_year_month', Data.card_year_month);
  // data.append('fullname',Data.created_by);  
  // data.append('company_name',Data.department_name);
  // data.append('mobile',Data.created_by);  
  // data.append('email',Data.department_name);
  // data.append('password',Data.created_by);  
  // data.append('user_title',Data.created_by);  

  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/create-subscription`,
      // headers: {
      //   "Authorization": 'Bearer ' + token,
      // },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(onboardSuccess(res.data))

          // if(close){close()}
          // navigate('/master/department', { replace: true });
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(onboardFailure(error.response.data));
          // if(close){close()}
          // if (error?.response?.status == 401) {
          // dispatch(Logout())
          // }
        }
      });
  };
}


// RESEND VERIFICATION  LINK
export function ResendVerificationLink(Data, navigate) {

  const data = new FormData();
  data.append('id', `${Data.id}`);
  data.append('mobile', `${Data.mobile}`);
  data.append('email', `${Data.email}`)

  return (dispatch) => {

    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/resend-verification-link`,
      data,
    })
      .then((res) => {
        // Success.fire({
        //   text: "html",
        //   html: `<span style="color:white">${res.data.message}</span>`,
        // })
        navigate('/subscription', {
          state: {
            fromResend: true
          }
        });
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
        }
      });
  };
}

export function onboardLoading() {
  return {
    type: ON_BOARD_LOADING,
  };
}
export function onboardSuccess(payload) {
  return {
    type: ON_BOARD_SUCCESS,
    payload
  };
}
export function onboardFailure(payload) {
  return {
    type: ON_BOARD_FAILURE,
    payload
  };
}


export function getPlan(Data) {
  return (dispatch) => {
    dispatch(getPlanLoading());
    axios({
      method: 'GET',
      url: `${SERVER_URL}/onboarding/get-plan`,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(getPlanFailure(error.response.data));
        }
      });
  };
}

export function getPlanLoading() {
  return {
    type: PLAN_LOADING,
  };
}
export function getPlanSuccess(payload) {
  return {
    type: PLAN_SUCCESS,
    payload
  };
}
export function getPlanFailure(payload) {
  return {
    type: PLAN_FAILURE,
    payload
  };
}

export function onboardingSendVerificationEmail(token) {

  // const data = new FormData();
  return (dispatch) => {
    dispatch(onboardLoading());

    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/send-email-verification`,
      // data: data,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(onboardSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(onboardFailure(error.response.data));
        }
      });
  };
}




export function obComplete(token) {
  // const data = new FormData();
  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/complete`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(onboardSuccess(res.data))
          dispatch(CompanyDetail(token))
          // window.location.replace('/')
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(onboardFailure(error.response.data));
        }
      });
  };
}


export function obCloseWelcome(token) {
  // const data = new FormData();
  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/close-welcome-box`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(onboardSuccess(res.data))
          dispatch(CompanyDetail(token))

        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(onboardFailure(error.response.data));
        }
      });
  };
}


export function onboardingEmailSuccess(token, navigate) {
  const data = new FormData();
  data.append('token', token);

  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/verify-email`,
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          // Success.fire({
          //   text: "html",
          //   html: `<span style="color:white">${res.data.message}</span>`,
          // })

          if (res.data.data.isVerified) {
            // cookies.set('secretToken', res.data.data.secretToken, { path: '/', expires: cookieDateExpire });
            dispatch(onboardSuccess(res.data));
            //    setTimeout(() => {
            //   window.location.replace('/')
            // }, 5000)
          } else {
            dispatch(onboardSuccess(res.data));
          }

          // setTimeout(() => {
          //   window.location.replace('/')
          // }, 5000)
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(onboardFailure(error.response.data));
          // dispatch(Logout());
          navigate('/mail-not-verified')

          // setTimeout(() => {
          //   window.location.replace('/login')
          // }, 2000)

        }
      });
  };
}

export function onboardingEmailPlanSuccess(token, navigate) {
  const data = new FormData();
  data.append('token', token);

  return (dispatch) => {
    dispatch(onboardLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/onboarding/verify-plan`,
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })

          if (res.data.data.isVerified) {
            // cookies.set('secretToken', res.data.data.secretToken, { path: '/', expires: cookieDateExpire });
            dispatch(verifyToken(res.data.data.secretToken));
            dispatch(onboardSuccess(res.data));
          } else {
            dispatch(onboardSuccess(res.data));
          }

        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(onboardFailure(error.response.data));
          navigate('/mail-not-verified')
        }
      });
  };
}


// Get Subscription Plan : used after signup
export function getSubscriptionPlan() {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'GET',
      url: `${SERVER_URL}/plan/plan-list`,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}


export function subscriptionPlanLoading() {
  return {
    type: SUBSCRIPTION_PLAN_LOADING,
  };
}
export function subscriptionPlanSuccess(payload) {
  return {
    type: SUBSCRIPTION_PLAN_SUCCESS,
    payload
  };
}
export function subscriptionPlanFailure(payload) {
  return {
    type: SUBSCRIPTION_PLAN_FAILURE,
    payload
  };
}

/**
 * This action is for calling skip payment API
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Object} data.onboarding_id Id of the onboarding company
 * @param {Object} navigate navigation object of react-router-dom
 */
export function skipPayment(token, data, navigate) {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'POST',
      url: `${SERVER_URL}/plan/skip-payment`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data.message)
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(verifyToken(res.data.data.secretToken), () => {
            navigate('/', { replace: true });
          });
          // dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}

/**
 * This action is for calling skip payment API
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Number} data.plan_id Id of the onboarding company
 * @param {Number} data.no_of_talents Id of the onboarding company
 * @param {String} data.address Id of the onboarding company
 * @param {String} data.state Id of the onboarding company
 * @param {String} data.city Id of the onboarding company
 * @param {String} data.pin_code Id of the onboarding company
 * @param {String} data.gstin_number Id of the onboarding company
 * @param {Object} navigate navigation object of react-router-dom
 */
export function payPlanAmount(token, data, next = () => null) {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'POST',
      url: `${SERVER_URL}/plan/save-company-plan`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          next(res)
          // dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}

/**
 * This action is for calling skip payment API
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Number} data.plan_id Id of the onboarding company
 * @param {Number} data.no_of_talents Id of the onboarding company
 * @param {Function} next navigation object of react-router-dom
 */
export function addMoreTalent(token, data, next = () => null) {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'POST',
      url: `${SERVER_URL}/plan/add-more-talent`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          next(res)
          // dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}

/**
 * This action is for calling skip payment API
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Number} data.code Id of the onboarding company
 */
export function completePlanPayment(token, data, onSuccess = () => null, onFailure = () => null) {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'POST',
      url: `${SERVER_URL}/plan/complete-plan-payment`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          // Success.fire({
          //   text: "html",
          //   html: `<span style="color:white">${res.data.message}</span>`,
          // })
          onSuccess(res)
        }
      }).catch((error) => {
        if (error?.response) {
          onFailure(error)
        }
      });
  };
}


// available-plan-list 

/**
 * This action is to get active plan details
 * Used in addMoreTalent 
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Number} data.plan_id Id of the onboarding company
 * @param {Number} data.no_of_talents Id of the onboarding company
 * @param {Function} next navigation object of react-router-dom
 */
export function getPlanDetails(type = 0, next = () => null) {
  return (dispatch, getState) => {
    const state = getState()
    const token = state?.authReducer?.authtoken
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'GET',
      url: `${SERVER_URL}/plan/available-plan-list?type=${type}`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {
          next(res.data)
          dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}

/**
 * This action is for calling skip payment API
 * @param {string} token Auth token
 * @param {Object} data Payload data containing the following properties:
 * @param {Number} data.plan_id Id of the onboarding company
 * @param {Number} data.no_of_talents Id of the onboarding company
 * @param {Function} next navigation object of react-router-dom
 */
export function subscriptionPayment(token, data, next = () => null) {
  return (dispatch) => {
    dispatch(subscriptionPlanLoading());
    axios({
      method: 'POST',
      url: `${SERVER_URL}/plan/subscription-payment`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data,
    })
      .then((res) => {
        if (res.status === 200) {
          next(res)
          // dispatch(subscriptionPlanSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(subscriptionPlanFailure(error.response.data));
        }
      });
  };
}

