
import {
    GLOBAL_SETTING_LOADING, GLOBAL_SETTING_FAILURE, GLOBAL_SETTING_SUCCESS
} from '../constants';

const INITIAL_STATE = {
    globalSettingLoading: false,
    globalSettingData: {},
    globalSettingSuccess: false,
    globalSettingMessage: null,
};

const GlobalSettingReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case GLOBAL_SETTING_LOADING:
            return {
                globalSettingLoading: true,
                globalSettingData: {},
            };

        case GLOBAL_SETTING_SUCCESS:
            return {
                globalSettingLoading: false,
                globalSettingSuccess: true,
                globalSettingData: action.payload.data || {},
                globalSettingMessage: action.payload.message,
            };

        case GLOBAL_SETTING_FAILURE:
            return {
                globalSettingLoading: false,
                globalSettingSuccess: false,
                globalSettingData: {},
                globalSettingMessage: action.payload.message,
            };


        default:
            return state;
    }
};

export default GlobalSettingReducer;
