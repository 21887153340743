import React, { useEffect, useState } from 'react'
import { EPageWithBreadCrumbs } from '../../../BaseComponents'
import { EBoxPage } from '../../../BaseComponents/EBox'
import { PaymentSearchFilter } from '../../../PageComponents/Payment/PaymentSearchFilter'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import EScrollbar from '../../../BaseComponents/EScrollbar'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../../BaseComponents/ETable'
import { ECircularProgress } from '../../../BaseComponents/ECircularProgress'
import { APPRAISAL_FOR, APPRAISAL_LIST_DOWNLOAD_URL, DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE } from '../../../constants'
import { Grid } from '@mui/material'
import { ETablePagination } from '../../../BaseComponents/ETablePagination'
import { UserAppraisalReportLog } from '../../../action/ReportAction'
import { capitalizeCapitalString } from '../../../utils/formatText'
import { ETypography } from '../../../BaseComponents/ETypography'
import { numberWithCommas } from '../../../utils/formatNumber'
import { DownLoadFile } from '../../../action/AdminAction'
import SearchNotFound from '../../../PageComponents/SearchNotFound'
import { generateYearArray } from '../../../utils/CommonFunctions'

function AppraisalReport() {
    const { appraisalReportListData, appraisalReportListLoading } = useSelector(state => state.AppraisalReportListReducer)
    const auth = useSelector(state => state.authReducer);
    const { companyData } = useSelector(state => state.CompanyReducer)
    const dispatch = useDispatch();


    const [searchParam, setSearchParam] = useState({
        end: '',
        year: {
            id: moment().format('YYYY').toString(),
            label: moment().format('YYYY').toString()
        },
        sort: 'DESC'
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        const params = {
            year: fnParams?.year ? fnParams?.year?.id : moment().format('YYYY').toString(),
            sort: fnParams?.sort ? "DESC" : "ASC",
            employee_id: fnParams?.employee?.id,
            appraisal_type: fnParams?.particular?.id
        }
        dispatch(UserAppraisalReportLog({ ...params, page: fnPage, per_page: fnRowPerPage }))
    }

    let totalIncrementAmount = 0

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...searchParam }, page, rowsPerPage);
        }
    }, [])


    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };




    function onSearch(data) {
        setSearchParam(data)
        callApi(data, page, rowsPerPage);
    }

    const DownloadReport = (type) => {
        
        const employee_id = searchParam?.employee ? searchParam?.employee.id :  null;
        const year = searchParam?.year ? searchParam?.year.id :  null;
        const appraisal_type = searchParam?.particular ? searchParam?.particular.id :  null;
        const sort = searchParam?.sort;

    

        let params = {
            download_format: type,
            ...(appraisal_type && { appraisal_type }),
            ...(year && { year }),
            ...(employee_id && { employee_id }),
            ...((sort === true || sort === false) && { sort }),
        };
        let queryString = new URLSearchParams(params).toString();
        const url = `${APPRAISAL_LIST_DOWNLOAD_URL}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "Appraisal-report")
    }

    const TABLE_HEAD = getTableHead();
    const yearArray = generateYearArray(companyData?.created_at)

    return (
        <EPageWithBreadCrumbs
            title="Appraisal Report"
            pageHeading="Appraisal Report"
            breadcrumbsTitle="Appraisal Report"
            breadcrumbsLinks={[
                { name: 'Dashboard', href: '/' },
                { name: 'Report', href: '' },
                { name: 'Appraisal Report' }
            ]}
            loading={appraisalReportListLoading || Object.keys(appraisalReportListData)?.length == 0}
        >
            <EBoxPage>
                {/* SEARCH FILTER */}
                <PaymentSearchFilter
                    initialFilter={searchParam}
                    onSearch={onSearch}
                    onSort={onSearch}
                    showAllTalents={true}
                    particulars={APPRAISAL_FOR}
                    particularPlaceholder='Appraisal For'
                    DownloadReport={DownloadReport}
                    particularsOrder={3}
                    talentOrder={2}
                    yearListOrder={1}
                    yearList={yearArray}
                    withoutDateValidation={true}
                />

                {/* TABLE  */}
                {appraisalReportListData?.rows?.length == 0 ?

                    <SearchNotFound /> :
                    <>

                        <EScrollbar sx={{ marginTop: 2 }}>
                            <ETableContainer>
                                <ETable>
                                    <EListHead
                                        headLabel={TABLE_HEAD}
                                    />

                                    {appraisalReportListLoading && (
                                        <ETableBody>
                                            <ETableRow>
                                                <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                    <ECircularProgress color="primary" />
                                                </ETableCell>
                                            </ETableRow>
                                        </ETableBody>
                                    )}

                                    {
                                        appraisalReportListData?.rows?.length > 0 && (

                                            <ETableBody>
                                                {
                                                    appraisalReportListData?.rows?.map((row, index) => {
                                                        const { Employee, appraisal_from, appraised_for, appraisal_amount, appraisal_till, appraisal_date, authorizedBy, amount_before_appraisal, amount_after_appraisal, designationInfo } = row;

                                                        totalIncrementAmount += Number(appraisal_amount)

                                                        return (

                                                            <ETableRow
                                                                key={index}
                                                            >

                                                                {/* Sno */}
                                                                <ETableCell align="left" sx={{ padding: '10px' }}>
                                                                    {index + 1 + (page * 10)}
                                                                </ETableCell>

                                                                {/* Talent Name */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {Employee?.fullname} <br />
                                                                    ({Employee?.employee_code})
                                                                </ETableCell>

                                                                {/* Appraisal Date */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {moment(appraisal_date).format('DD/MM/YYYY')}
                                                                </ETableCell>

                                                                {/* Appraised For */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {appraised_for ? capitalizeCapitalString(appraised_for) : '-'}
                                                                </ETableCell>

                                                                {/* Promoted To  */}
                                                                <ETableCell align="center" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {designationInfo ? designationInfo.designation_name : '-'}
                                                                </ETableCell>

                                                                {/* Previous Salary */}
                                                                <ETableCell align="right" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {numberWithCommas(amount_before_appraisal)}
                                                                </ETableCell>

                                                                {/* Increment */}
                                                                <ETableCell align="right" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {numberWithCommas(appraisal_amount)}
                                                                </ETableCell>

                                                                {/* Updated Salary  */}
                                                                <ETableCell align="right" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {numberWithCommas(amount_after_appraisal)}
                                                                </ETableCell>

                                                                {/* Applicable From */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {moment(appraisal_from).format('DD/MM/YYYY')}
                                                                </ETableCell>

                                                                {/* Up Till */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {appraisal_till ? moment(appraisal_till).format('DD/MM/YYYY') : '-'}
                                                                </ETableCell>

                                                                {/* Appraise By  */}
                                                                <ETableCell align="left" sx={{ padding: '10px', minWidth: '100px' }}>
                                                                    {authorizedBy?.fullname}
                                                                </ETableCell>

                                                            </ETableRow>

                                                        )
                                                    })
                                                }



                                            </ETableBody>


                                        )

                                    }
                                    <ETableBody>
                                        <ETableRow>
                                            {/* Sno */}
                                            <ETableCell colSpan={6} align="left" sx={{ padding: '10px' }}>
                                                <ETypography className='font-size-14px bold-600'>
                                                    Total
                                                </ETypography>
                                            </ETableCell>
                                            {/* Appraise By  */}
                                            <ETableCell align="right" sx={{ minWidth: '120px' }}>
                                                <ETypography className='font-size-14px bold-600 theme-color-static '>
                                                    {numberWithCommas(totalIncrementAmount)}
                                                </ETypography>
                                            </ETableCell>
                                            {/* Sno */}
                                            <ETableCell colSpan={4} align="left" sx={{ padding: '10px' }}>
                                            </ETableCell>
                                        </ETableRow>
                                    </ETableBody>

                                </ETable>

                            </ETableContainer>
                        </EScrollbar>


                        <Grid container my={0}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <ETablePagination
                                rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                                component="div"
                                count={appraisalReportListData?.count ? appraisalReportListData?.count : 10}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </>
                }
            </EBoxPage>
        </EPageWithBreadCrumbs>
    )
};

const getTableHead = () => {
    let tblHead = [
        { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Employee Name', label: 'Employee Name', alignRight: false },
        { id: 'Appraisal Date.', label: 'Appraisal Date', alignRight: false },
        { id: 'Appraised For', label: 'Appraised For', alignRight: false },
        { id: 'Promoted To', label: 'Promoted To', alignRight: 'center' },
        { id: 'Previous Salary', label: 'Previous Salary', alignRight: true },
        { id: 'Increment', label: 'Increment Amount', alignRight: true },
        { id: 'Updated Salary', label: 'Updated Salary', alignRight: true },
        { id: 'Applicable From', label: 'Applicable From', alignRight: false },
        { id: 'Up Till', label: 'Up Till', alignRight: false },
        { id: 'Appraise By', label: 'Appraised By', alignRight: false },
    ];
    return tblHead;
};

export default AppraisalReport