
import {
    EMP_LEAVE_LOG_SUCCESS, EMP_LEAVE_LOG_FAILURE, EMP_LEAVE_LOG_LOADING
} from '../constants';

const INITIAL_STATE = {

  empLeaveLogData: {},
  empLeaveLogSuccess: false,
  empLeaveLogLoading: false,
  empLeaveLogMessage: null,
};
/**
 * [2022-26-11]
 * Author: Aanchal Sahu
 * Description:- created EmployeeLeaveLogReducer
 **/
const EmployeeLeaveLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
      case EMP_LEAVE_LOG_LOADING:
      return  {
        empLeaveLogLoading: true,
        empLeaveLogData: {},
      };

    case EMP_LEAVE_LOG_SUCCESS:
      return {
        empLeaveLogLoading: false,
        empLeaveLogSuccess: true,
        empLeaveLogData: action.payload.data,
        empLeaveLogMessage: action.payload.message,
      };

    case EMP_LEAVE_LOG_FAILURE:
      return  {
        empLeaveLogLoading: false,
        empLeaveLogSuccess: false,
        empLeaveLogMessage: action.payload.message,
        empLeaveLogData: {},
      };
      
    default:
      return state;
  }
};

export default EmployeeLeaveLogReducer;
