import React, { useEffect, useState } from "react";
import { Grid, Link } from "@mui/material";
import { ETypography } from "../../BaseComponents/ETypography";
import { EButton, EButtonOutlinedIconLink } from "../../BaseComponents/EButtons";
import { useDispatch, useSelector } from "react-redux";
import { ResendVerificationLink } from "../../action/OnBoardingAction";
import OpenInNewIcon from "@iconify/icons-material-symbols/open-in-new-rounded";
import { EIcon } from "../../BaseComponents/EIcon";
// import { verifyToken } from "../../action/AuthAction";
import { useLocation, useNavigate } from "react-router-dom";

const SubscriptionSuccess = (props) => {
  const { subscription } = props;
  const onBoardingData = useSelector((state) => state.OnBoardingReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [sniperLink, setSniperLink] = useState({
    link: "",
    img: "",
  });

  const handleResend = () => {
    dispatch(ResendVerificationLink(subscription, navigate));
  };

  // Reference: https://growth.design/sniper-link

  // To get mail service name ie. google/yahoo/outlook etc.
  const getMailName = (email) => {
    return email?.split("@").pop().split(".")[0];
  };

  // To get Sniper link
  const getSniperLink = (email) => {
    switch (email) {
      case "GMAIL":
        return setSniperLink({
          link: `https://mail.google.com/mail/u/${onBoardingData?.onBoardData?.email}/#search/from%3A%40bizolutiontech+in%3Aanywhere+newer_than%3A1d*/`,
          img: "/assets/images/gmail.svg",
        });
      case "YAHOO":
        return setSniperLink({
          link: "https://mail.yahoo.com/d/search/keyword=from%253Ateam%40bizolutiontech",
          img: "/assets/images/yahoo.svg",
        });
      case "OUTLOOK":
        return setSniperLink({
          link: "https://outlook.live.com/mail/",
          img: "/assets/images/outlook.svg",
        });
      case "ICLOUD":
        return setSniperLink({
          link: "https://www.icloud.com/mail/",
          img: "/assets/images/icloud.svg",
        });
      default:
        return setSniperLink(false);
    }
  };

  useEffect(() => {
    getSniperLink(
      getMailName(onBoardingData?.onBoardData?.email)?.toUpperCase()
    );
  }, []);

  // useEffect(() => {
  // if(subscription.secretToken){
  //     setTimeout(() => {
  //         dispatch(verifyToken(subscription.secretToken));
  //     }, 5000)
  // }
  // }, [subscription]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={10}
    >
      {/* Success MSG */}
      <Grid item marginBottom={"37px"}>
        <ETypography
          variant="h4"
          className="font-size-24px bold-600 text-align mb-16px"
        >
          {location?.state?.fromResend
            ? "Resent Email Verification!"
            : "Verification Email Sent! "}
          <br />
          Check your inbox.
        </ETypography>
        <ETypography
          className="font-size-18px bold-400 text-align"
          sx={{ width: "60%", margin: "auto" }}
        >
          {location?.state?.fromResend
            ? "We have successfully sent a new verification link to your registered email address."
            : <>We've sent an email to <b>{onBoardingData?.onBoardData?.email}</b> to confirm your email. Please check your email and follow the instructions to start using Entera Payroll services.</>}
        </ETypography>
      </Grid>

      {sniperLink !== false && (
        <Grid item marginBottom={"100px"}>
          <a href={sniperLink.link} rel="noreferrer" target="_blank">
            <EButtonOutlinedIconLink
              variant="outlined"
              className="button-left-margin"
            >
              <span className="m0px">
                <img src={sniperLink.img} alt="mail" />
              </span>

              <span className="font-size-24px text-transform-capitalize">
                Open{" "}
                {getMailName(onBoardingData?.onBoardData?.email)
                  ? getMailName(onBoardingData?.onBoardData?.email)
                  : ""}
              </span>

              <EIcon
                sx={{ margin: "10px" }}
                className="height-width-18px color-staticGrey"
                icon={OpenInNewIcon}
              />
            </EButtonOutlinedIconLink>
          </a>
        </Grid>
      )}

      {/* Resend Link */}
      <Grid item>
        <ETypography className="font-size-18px bold-500 text-align mb-16px">
          Didn’t receive an Email?{" "}
          <Link
            className="link-color cursor-pointer "
            onClick={() => handleResend()}
          >
            <u>Resend</u>
          </Link>
        </ETypography>
      </Grid>
      <Grid item mt={10}>
        <ETypography className="font-size-18px bold-500 text-align mb-16px">
          <EButton
            onClick={() => navigate('/login')}
          >
            Redirect to Login Page
          </EButton>
        </ETypography>
      </Grid>
    </Grid>
  );
};

export default SubscriptionSuccess;
