import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Box, Grid } from '@mui/material';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import EPage from "./EPage";
import EHeaderBreadcrumbs from './EBreadcrum';
import EHidden from './EHidden';
import { EButtonOutlined, EButtonOutlinedIcon } from './EButtons';
import { ETypographyPageHeading } from './ETypography';
import { useNavigate } from 'react-router';
import { EIcon } from './EIcon';
import { EDivider } from './EDivider';
import { LoadingGrid } from './EGrid';


/**
 * INFO: This component provides a custom common page with a title, breadcrumbs and a back button
 */
export const EPageWithBreadCrumbs = forwardRef(({ children, title = '', breadcrumbsLinks = [], breadcrumbsTitle = '', pageHeading = '', rightComponent = null, hideDivider = false, loading = false, headingRightComponent = null, ...other }, ref) => {

  const navigate = useNavigate();
  return (
    <EPage title={title} ref={ref} {...other}>
      <Grid container display="row" justifyContent="space-between" className="align-center">
        <Grid item>
          {
            breadcrumbsLinks?.length > 0 &&
            <EHeaderBreadcrumbs
              heading={breadcrumbsTitle}
              links={breadcrumbsLinks}
            />
          }
        </Grid>

        {/* INFO: Buttons on right will be hidden on small devices (i.e. on less than 600px) */}
        <EHidden width="mdDown">
          {
            !rightComponent
              ?
              <Grid item>
                <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
              </Grid>
              :
              rightComponent
          }
        </EHidden>
      </Grid>

      <Grid
        container
        display="row"
        justifyContent="space-between"
        className='margin-top-20px '
        alignItems="center"
      >
        <Grid item sx={{ flex: 1, display :'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ETypographyPageHeading variant='h5'>
            {pageHeading}
          </ETypographyPageHeading>
          {
            headingRightComponent && headingRightComponent
          }
        </Grid>

        {/* INFO: Buttons on right will be visible on small devices (i.e. on less than 600px) */}
        <EHidden width="mdUp">
          {
            !rightComponent
              ?
              <Grid item>
                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                  <EIcon icon={ForwardArrow} className='height-width-15px' />
                </EButtonOutlinedIcon>
              </Grid>
              :
              rightComponent
          }
        </EHidden>
      </Grid>

      <EHidden width='mdDown'>
        {
          !hideDivider &&
          <EDivider className='margin-top-05' />
        }
      </EHidden>


      {
        loading
        ?
        
        <LoadingGrid isPage={true} />
        :
        children
      }

    </EPage>
  )
});

EPageWithBreadCrumbs.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  breadcrumbsTitle: PropTypes.string,
  breadcrumbsLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
    }).isRequired
  ),
  pageHeading: PropTypes.string,
  rightComponent: PropTypes.node,
  headingRightComponent: PropTypes.node,
  hideDivider: PropTypes.bool,
};

