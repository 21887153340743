import { Radio, RadioGroup, styled } from "@mui/material";

export const ERadioGroup = styled(RadioGroup)(({ theme }) => ({
    '.MuiFormControlLabel-label': {
        '&.Mui-disabled': {
            color: theme.palette.input.Disabled2 //This handles changes in disabled labels of radio
        }
    }
}));

export const ERadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.text.greyColor9,// INFO: This code is written to change the checkbox svg color
    '&.Mui-disabled': {
        'svg': {
            color: theme.palette.input.Disabled //This handles changes in disabled radio buttons
        },
    },
}));
