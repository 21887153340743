
import React, { useEffect, useState } from 'react';
import { ETable, ETableBody, ETableCell, ETableContainer, ETableRowSmall } from '../../BaseComponents/ETable';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import {
    TALENT_PAYMENT_LOG_DOWNLOAD_URL, PAYMENT_CATEGORY_SALARY,
    PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_OTHER, PENDING_STATUS, COMPLETE_STATUS, APPROVED_STATUS, CANCELLED_STATUS, PAYMENT_CATEGORY_ALL, PAYMENT_CATEGORY
} from '../../constants';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EScrollbar from '../../BaseComponents/EScrollbar';
import EListHeadSmall from '../../BaseComponents/ETable';
import SearchNotFound from '../SearchNotFound';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import { TalentPaymentList } from '../../action/TalentActions';
import { DownLoadFile } from "../../action/AdminAction";
import { EIcon } from '../../BaseComponents/EIcon';
import EChip from '../../BaseComponents/EChip';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { getPaymentCategory } from '../../utils/getPaymentCategory';
import { PaymentSearchFilter } from '../Payment/PaymentSearchFilter';
import { EBox } from '../../BaseComponents/EBox';


const TABLE_HEAD = [
    { id: 'Date', label: 'Date', alignRight: false },
    { id: 'Payment Category', label: 'Particular', alignRight: 'center' },
    { id: 'Amount', label: 'Amount', alignRight: true },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Remark', label: 'Remark', alignRight: 'center' },
];

/**
 * Description:- designed this component according to view profile
 **/

function TalentEditPayments(props) {
    const { auth, employee,setting } = props;
    const employeePayment = useSelector((state) => state.EmployeePaymentReducer);
    const Theme = useSelector((state) => state.ThemeReducer);
    const dispatch = useDispatch();
    const [PaymentFilter, setPaymentFilter] = React.useState('');
    const [PaymentCategoryFilter, setPaymentCategoryFilter] = React.useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [filter, setFilter] = useState({
        sort: null,
        particular: null,
        start :null
      })


    useEffect(() => {
        let PaymentCategoryFilterID = filter?.particular ? filter?.particular?.id : "";
        if (employee?.id) {
            dispatch(TalentPaymentList(auth.authtoken, employee?.id, PaymentFilter, PaymentCategoryFilterID, "request_self", page, rowsPerPage, filter?.start, filter?.sort))
        }
    }, [PaymentFilter, filter?.particular, page, rowsPerPage, employee, filter]);

    useEffect(() => {
        if (employeePayment?.employeePaymentAddSuccess) {
            let PaymentCategoryFilterID = filter?.particular ? filter?.particular?.id : "";
            dispatch(TalentPaymentList(auth.authtoken, employee?.id, PaymentFilter, PaymentCategoryFilterID, "request_self", page, rowsPerPage))
        }
    }, [employeePayment?.employeePaymentAddSuccess]);

    function numberWithCommas(num) {

        let number = Number(num)
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
    }

    const onSearch = (data) => {
        setFilter(data)
        setPage(0)
      }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const DownloadReport = (value) => {
        let url = TALENT_PAYMENT_LOG_DOWNLOAD_URL;
        let file_name = "Payment_log";
        if (value != null) { url += `?download_format=${value}&employee_id=${employee?.id}&page_name=request_self`; }
        if (filter?.particular?.id != null) { url += `&payment_category=${filter?.particular?.id}`; }
        if (filter?.start != null) { url += `&year_month=${moment(filter?.start).format('YYYYMM')}`; }
        if (PaymentFilter) {
            url += `&date=${PaymentFilter}`;
            file_name = file_name + "_" + moment(PaymentFilter).format('MMM-YYYY');
        }
        DownLoadFile(auth.authtoken, url, file_name);
        setAnchorEl(null);
    }

    return (
        <>
            <PaymentSearchFilter
                initialFilter={filter}
                onSearch={onSearch}
                onSort={onSearch}
                particulars={
                    getPaymentCategory('ENABLE_BOTH', PAYMENT_CATEGORY_ALL)
                }
                start={{
                    placeholder: 'Select Month',
                    views: ['month' ,'year'],
                    inputFormat: 'MMM yyyy',
                    order: 1,
                  }}
                DownloadReport={DownloadReport}
            />
            <EBox sx={{ height: '16px' }} />

                <EScrollbar>
                    <ETableContainer >
                        <ETable>
                            <EListHeadSmall
                                headLabel={TABLE_HEAD}
                                onRequestSort={() => null}
                                Theme={Theme}
                            />
                            {employeePayment?.employeePaymentLoading ?
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody> :
                                <ETableBody>
                                    {employeePayment?.employeePaymentData && employeePayment?.employeePaymentData?.rows?.map((row, index) => {
                                        const { transaction_category, transaction_status, pay_amount, transaction_status_date, accountant_remark, payrollData } = row;
                                        return (
                                            <ETableRowSmall
                                                hover
                                                key={index}
                                                tabIndex={-1}
                                                role="checkbox"
                                            >
                                                <ETableCell align='left' className='width-20p'>{transaction_status_date ? moment(transaction_status_date).format('DD/MM/YYYY') : '-'}</ETableCell>
                                                <ETableCell align='center' sx={{ width: '200px !important' }}>
                                                    {transaction_category && transaction_category == PAYMENT_CATEGORY_ADVANCE
                                                        ? 'Advance'
                                                        : transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
                                                            ? 'Reimbursement'
                                                            : transaction_category == PAYMENT_CATEGORY_OTHER
                                                                ? 'Other'
                                                                : transaction_category == PAYMENT_CATEGORY_SALARY ? 'Salary' : '-'}
                                                    <span style={{ fontWeight: "bold" }}>{payrollData?.month_date ? '-' + moment(payrollData?.month_date).format('MMM yyyy') : ""}</span>
                                                </ETableCell>
                                                <ETableCell align="right" className='minWidth-140'>{numberWithCommas(pay_amount)}</ETableCell>
                                                <ETableCell align="center">
                                                    {transaction_status &&
                                                        <EChip
                                                            label={
                                                                transaction_status == PENDING_STATUS ? "Pending" :
                                                                    transaction_status == COMPLETE_STATUS ? "Paid" :
                                                                        transaction_status == APPROVED_STATUS ? "Approved" :
                                                                            transaction_status == CANCELLED_STATUS ? "Reject" :
                                                                                transaction_status
                                                            }
                                                        />
                                                    }
                                                </ETableCell>
                                                <ETableCell align="center" className='width-10px'>
                                                    {accountant_remark ?
                                                        <ETooltip title={accountant_remark} arrow>
                                                            <EIcon icon={InfoIcon} />
                                                        </ETooltip>
                                                        : '-'}
                                                </ETableCell>

                                            </ETableRowSmall>
                                        );
                                    })}
                                </ETableBody>
                            }
                            {employeePayment?.employeePaymentData?.count == 0 && !employeePayment.employeePaymentLoading && (
                                <ETableBody>
                                    <ETableRowSmall>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <SearchNotFound />
                                        </ETableCell>
                                    </ETableRowSmall>
                                </ETableBody>
                            )}
                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {employeePayment?.employeePaymentData && employeePayment?.employeePaymentData?.count > 10 &&
                    <ETablePagination
                        rowsPerPageOptions={[10, 25]}
                        component="div"
                        count={employeePayment?.employeePaymentData?.count ? employeePayment?.employeePaymentData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
        </>
    );
}

export default TalentEditPayments