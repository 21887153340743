import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Stack, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Logo } from './logo';
import NavSection from './NavSection';
import { SidebarConfig, SettingSidebarConfig } from './SidebarConfig';

import { ELinkMUI } from '../BaseComponents/ELink';
import { EBox } from '../BaseComponents/EBox';
import { DRAWER_WIDTH } from '../constants';
import EHidden from '../BaseComponents/EHidden';
import { ETypography } from '../BaseComponents/ETypography';
import { useCompanySettingProvider } from '../context/CompanySettingContext';

const COLLAPSE_WIDTH = 75;


DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, auth, isSettingLayout }) {
 
  // Getting settings context (new updated on - 17-5-23)
  //INFO: since the useContext have to be called in the top level, to bypass the issue: React has detected a change in the order of Hooks
  const settingsContext = useCompanySettingProvider();

  const { pathname } = useLocation();
  const theme = useTheme();
  const [currentTitle, setCurrentTitle] = useState('');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const renderContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        px: '14px',//overall sidebar paddingX= 14px
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: '6px', //overall sidebar paddingX= 14 + 6px= 20px
          pt: '20px',
          pb: '40px',
        }}
      >
        <Stack direction="row" sx={{ pb: 0 }}>
          <Box component={RouterLink} to="/" style={{ textDecoration: "none" }}>
            <Logo />
          </Box>
        </Stack>
      </Stack>

      {auth && auth?.authData?.permission && <>
      
        {isSettingLayout &&
          <ETypography variant='h5' className='pl-16px pb-16px font-size-24px'>
            Settings
          </ETypography>
        }
        <NavSection
          navConfig={!isSettingLayout ? SidebarConfig(auth?.authData?.permission, auth?.authData, settingsContext) : SettingSidebarConfig(auth?.authData)}
          auth={auth}
          isShow={true}
          setCurrentTitle={setCurrentTitle}
          currentTitle={currentTitle}

        />
      </>
      }


      <Box sx={{ flexGrow: 1 }} />

      <Stack alignItems="center"
        sx={{
          px: '31px', //overall sidebar paddingX= 14 + 31px= 45px 
          pb: 5,
          textAlign: 'center'
        }}>
        <ELinkMUI className='linkCompanyNameStyle' href={'https://www.bizolutiontech.com/'} target="_blank"> Bizolution Technologies Pvt Ltd </ELinkMUI>
        <div className='copyrightText font-size-10px'>© Copyright 2021 - 22</div>
        <div className='copyrightText font-size-10px'>v 2.0</div>
      </Stack>
    </Box>
  );

  return (
    <EBox
      sx={{
        width: COLLAPSE_WIDTH,
        position: 'absolute'
      }}
    >
      <EHidden width="lgUp"> {/* This Drawer is for sidebar which is below lg size  */}
        <Drawer
          anchor="left"
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: 'background.drawerBg',
              boxShadow: theme.customShadows.shadowSideNavbar,
              borderRight: '0px'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </EHidden>

      <EHidden width="lgDown">{/* This Drawer is for sidebar which is lg size or above  and is STATIC */}
        <Drawer
          anchor="left"
          open
          variant='permanent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              backgroundColor: 'background.drawerBg',
              boxShadow: theme.customShadows.shadowSideNavbar,
              borderRight: '0px'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </EHidden>
    </EBox>
  );
}

