/**
 * [2022-02-02]
 * changes by: Purva Sharma
 * Description:- Added EDescription
 **/

import {
  FormHelperText,
  styled,
  Typography,
  // InputLabel,
  FormLabel,
} from "@mui/material";

export const ETypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const ELabel = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize:'1.125rem' //"18px",
}));

export const EFieldLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 400,
  fontSize:'1.125rem', //"18px",
  color: theme.palette.text.primary,
}));

export const EFieldLabelSemiBold = styled(FormLabel)(({ theme }) => ({
    fontWeight: 600,
    fontSize: '0.875rem',//14px
    color: theme.palette.text.primary
}));

export const EFieldLabelBold = styled(FormLabel)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.125rem', //"18px"
  color: theme.palette.text.primary,
}));

export const EViewFieldLabel = styled(FormLabel)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.125rem', //"18px"
  color: theme.palette.text.primary,
}));

export const ETypographyStaticColor = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const ETypographyPageHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: "1.5rem",//24px
  [theme.breakpoints.down('lg')]: {
    fontSize:'18px'
  },
}));

export const ETypographyCardHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: "1.25rem !important",//20px
}));

export const ETypographyBreadCrumb = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: "1rem",//16px
}));

export const EFormHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: "0px",
}));

export const EHelperText = styled("div")(({ theme }) => ({
  fontSize: "0.75rem !important",
  marginTop: "2px",
}));

export const EDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.description,
  fontSize: "1rem",//16px
  lineHeight: "22.4px",
  fontWeight: "400",
}));

// INFO: This component is specially made for the word count ex- 34/250, usually seen at the bottom of remark.
export const EWordCount = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize:'0.875rem', //14px
  color: theme.palette.text.greyColor4,
}));