import { ON_BOARD_FAILURE, ON_BOARD_SUCCESS, ON_BOARD_LOADING } from '../constants';


const INITIAL_DATA = {
    onBoardData: {},
    // onBoardEmailSent: false,
    onBoardSuccess: false,
    onBoardLoading: false,
    onBoardMessage: null,

};

const OnBoardingReducer = (initialData = INITIAL_DATA, action) => {
    
    switch (action.type) {    

        case ON_BOARD_LOADING:
            return {
                onBoardData: {},
                onBoardSuccess: false,
                onBoardLoading: true,
                onBoardMessage: null,
            };

        case ON_BOARD_SUCCESS:
            return {
                onBoardLoading: false,
                onBoardSuccess: true,
                onBoardData: action.payload.data,
                onBoardMessage: action.payload.message,
            };

        case ON_BOARD_FAILURE:
            return {
                onBoardMessage: action.payload.message,
                onBoardData: {},
                onBoardSuccess: false,
                onBoardLoading: false,
            };

        default:
            
            return initialData;
    }
};

export default OnBoardingReducer;
