import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { ETypography } from "../BaseComponents/ETypography";
import { useNavigate, useParams } from "react-router-dom";
import { onboardingEmailSuccess } from "../action/OnBoardingAction";
import LinkExpired from "../PageComponents/Auth/LinkExpired";
import { EButton } from "../BaseComponents/EButtons";




const EmailVerificationSuccess = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBoardingData = useSelector(state => state.OnBoardingReducer);
  useEffect(() => {
    if (params.token) {
      dispatch(onboardingEmailSuccess(params.token, navigate))
      // setTimeout(() => {
      //     // dispatch(verifyToken(subscription.secretToken));
      //     dispatch(onboardingEmailSuccess(params.token, navigate))
      // }, 5000)
    }
  }, [params.token]);

  // console.log("success", onBoardingData.onBoardSuccess, "Loading", onBoardingData.onBoardLoading);

  // useEffect(() => {
  //     console.log(onBoardingData);
  // }, [onBoardingData]);



  return (
    <Grid container
      direction="column"
      justifyContent="center"
      alignItems="center"
      py={10}
    >

      {/* Show when email is successfully verified */}
      {!onBoardingData.onBoardLoading &&
        onBoardingData.onBoardSuccess && onBoardingData.onBoardData.isVerified &&
        <>
          <ETypography className="font-size-24px bold-600 text-align mb-40px">
            Your account has been successfully  verified!
          </ETypography>

          <ETypography sx={{maxWidth:'634px'}}  className="font-size-18px bold-400 text-align mb-16px">
          Congratulations on verifying your account. Click on the button below to start with Entera Payroll.
          </ETypography>

          <EButton  onClick={()=>navigate('/login',{replace:true,state:{fromEmailSuccess:true}})}>
            Login
          </EButton>  
        </>
      } 

      {/* LinkExpired: Show if the verification link is expired */}
      {!onBoardingData.onBoardLoading &&
        onBoardingData.onBoardSuccess && (onBoardingData.onBoardData.isVerified == false) &&
        <>
        <LinkExpired data={onBoardingData?.onBoardData}/>
        </>
      }
    </Grid>
  );
};

export default EmailVerificationSuccess;