import React,{ useState } from "react";
import {
  Stack,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import { AddNewDesignationValidation } from "../../utils/validation";
import { EBox } from "../../BaseComponents/EBox";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { EInputAdornment, ETextField } from "../../BaseComponents/ETextField";
import {
  CreateDesignation,
  UpdateDesignation,
} from "../../action/DesignationAction";
// import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EIcon } from "../../BaseComponents/EIcon";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";


function DesignationForm(props) {
  const { auth, designation, close,inputValue, rowData, buttonTitle,formikTalents  } = props;

  const [status, setStatus] = useState(
    rowData?.status === undefined ? "1" : rowData?.status
  );
  const dispatch = useDispatch();

  const handleRadio = (e) => {
    setStatus(() => e.target.value);
    if (e.target.value == "1") {
      setFieldValue("status", true);
    } else {
      setFieldValue("status", false);
    }
  };

  // handleEditStatus : checks for status and returns value to update the designation row ie. (if status == '1' == true) (if status == '0' == false  )
  const handleEditStatus = (status) => {
    switch (status) {
      case 1:
        return true
      case 0:
        return false
      default:
        return status
    }
  }
  

  const formik = useFormik({
    initialValues: {
      id: rowData?.id,
      designation_name: rowData?.designation_name
        ? rowData?.designation_name
        : inputValue ? inputValue :"",
      notice_period: null,
      status: rowData?.status === undefined ? true : rowData?.status,
    },
    enableReinitialize:true,
    validationSchema: AddNewDesignationValidation(designation),
    onSubmit: (data) => {
      if (rowData) {
        data = { ...data, designationId: rowData?.id,status: handleEditStatus(data.status)};
        formikTalents?.setFieldValue("new_designation", true)
        dispatch(UpdateDesignation(auth.authtoken, data, close));
      } else {
        data = { ...data };
        formikTalents?.setFieldValue("new_designation", true) 
        dispatch(CreateDesignation(auth.authtoken, data, close));
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;
  return (
    <EBox>
      <FormikProvider value={formik} id="previewBlock">
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {/* Designation */}
              <ELabelInputMainGrid label={"Designation"}>
                <ETextField
                  fullWidth
                  {...getFieldProps("designation_name")}
                  placeholder="example: Accountant"
                  onChange={(e) => {
                    setFieldValue("designation_name", e.target.value);
                  }}
                  error={Boolean(
                    touched.designation_name && errors.designation_name
                  )}
                  helperText={
                    touched.designation_name && errors.designation_name
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <EInputAdornment position="start"></EInputAdornment>
                    ),
                    endAdornment: (
                      <EInputAdornment
                        position="end"
                        sx={{ margin: "0px" }}
                        className="cursor-pointer"
                        onClick={() => {
                          // onSearch('')
                          setFieldValue("designation_name", "");
                        }}
                      >
                        {values.designation_name.length > 0 && (
                          <EIcon icon={CancelIcon} />
                        )}
                      </EInputAdornment>
                    ),
                  }}
                />
              </ELabelInputMainGrid>

              {/* Status */}
              <EBox className="mt-16px">
                <ELabelInputMainGrid label="Status">
                  <RadioGroup onChange={handleRadio} row name="status">
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      checked={status == "1" ? true : false}
                      label="Enable"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="Disable"
                      checked={status == "0" ? true : false}
                    />
                  </RadioGroup>
                </ELabelInputMainGrid>
              </EBox>
            </Grid>

            {/* //Latter will do */}
            {/* <Grid item xs={12} md={12} lg={12} >
                            <ELabelInputMainGrid label={'Notice Period*'}>
                            <EAutocomplete
                                name="Leave Type"
                                fullWidth
                                {...getFieldProps('notice_period')}
                                options={[{ id: '0', label: 'Immediately' },
                                { id: '30', label: '30 Days' },
                                { id: '45', label: '45 Days' },
                                { id: '60', label: '60 Days' },
                                { id: '90', label: '90 Days' }]}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('notice_period', value ? value : null)
                                }}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='notice_period'
                                        placeholder="Select"
                                        error={Boolean(touched.notice_period && errors.notice_period)}
                                        helperText={touched.notice_period && errors.notice_period}
                                    />
                                )}
                            />
                            </ELabelInputMainGrid>
                        </Grid> */}
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => close()}
            >
              {" "}
              Cancel{" "}
            </EButtonOutlined>
            <ELoadingButton
              loading={designation?.designationLoading}
              size="large"
              variant="contained"
              type="submit"
            >
              {rowData ? "Update" : buttonTitle ? "Add" : 'Submit'}
            </ELoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </EBox>
  );
}

export default DesignationForm;
