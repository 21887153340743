import React from 'react'
import { useState } from 'react'
import { EBox } from '../../BaseComponents/EBox'
import { ETypography } from '../../BaseComponents/ETypography'
import EModal from '../../BaseComponents/EModal'
import ModalList, { CancelModal } from './ModalList'
import { ETooltip } from '../../BaseComponents/ETooltip'
import { EAvatarHrDashboardBirthdayTalent, EAvatarHrDashboardBirthdayToday } from '../../BaseComponents/EAvatar'
import { getInitials } from '../../utils/CommonFunctions'
import { Grid, useTheme } from '@mui/material'
import { ELinkHRDAshMUI } from '../../BaseComponents/ELink'

const BirthdayTalent = ({today = [], matchesXL, type}) => {
    const [header, setHeader] = useState('')
    const theme = useTheme()


    const [open, setOpen] = useState(false)

    const [modalData, setModalData] = useState([])

  return (
    <EBox>

    {
        // Checking if there is no "today's data" and no "upcoming's data"
        // because we want to display a big no data image if both the data is not present
        today.length <= 0  
    ?
        // as both the data is not present
        //  we will display big image with text that no birthday or anniversary today
        <EBox sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', height:'100% !important'}}>

            <img className="no-data-image" 
                src={type == 1?"/assets/images/hrDashboardNodata/birthdays.svg":"/assets/images/hrDashboardNodata/work anniversary.svg" }
                alt="No data" 
                style={{width:'60%', marginBottom:'4px', marginTop:'10%'}}
            />

            <ETypography sx={{fontSize:'16px', fontWeight:'500'}}>
                {type == 1 ? "No Birthday Today" : "No Anniversary Today"}
            </ETypography>
        </EBox>
    :
        <>
            {/* if any of the data is available we will display the following */}

            <EBox sx={{display:'flex', flexDirection:'column',  height:'100% !important'}}>
                {/* Modal to display when user clicks on any avatar */}
                <EModal open={open} headervalue={header} footeractions={<CancelModal open={open} setOpen={setOpen}/>} newMaxWidth='xs'>
                        <ModalList modalData={modalData} type={type}/>
                </EModal>
                
                {/* Heading */}
                <EBox className="font-size-20px bold-400 mb-8px mt-16px" sx={{lineHeight:'28px'}}>Today</EBox>

                <EBox className="display-flex mb-8px">
                        <>
                            <EBox sx={{}}>
                                {/* if today's data is present */}
                                {/* No matter the number of data in the array, we will onl show the first 3 birthday's in the dashboard */}
                                {today?.slice(0, 3).map((row, index) => {
                                    return (
                                        <EBox sx={{display:'flex', marginTop:'16px'}}>
                        
                                                <EAvatarHrDashboardBirthdayTalent className="mr-24px cursor-pointer" key={row}
                                                    onClick={()=>{
                                                        setOpen(!open)
                                                        setModalData(today)
                                                        type==1? setHeader("Today's Birthday") : setHeader("Today's Work Anniversary")
                                                    }}
                                                >

                                                    {row?.employee_image == null || row?.employee_image == '' || row.employee_image==undefined?
                                                        <span className="font-size-20px theme-color-static">{getInitials(row.employee_name)}</span>:
                                                        <img alt='' width={"75px"} src={row.employee_image} className='cursor-pointer'/>
                                                    }
                                                </EAvatarHrDashboardBirthdayTalent>

                                            <EBox sx={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                                <ETypography sx={{fontSize:'16px', fontWeight:'400'}}>{row?.employee_name}</ETypography>
                                                <ETypography sx={{fontSize:'12px', fontWeight:'400', color:theme.palette.text.greyColor5}}>{row?.employee_designation}</ETypography>
                                            </EBox>
                                        </EBox>
                                    )
                                })}

                            </EBox>
                        </>
                </EBox>

                <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="pt12px">
                            <Grid item>
                                <ELinkHRDAshMUI
                                    onClick={()=>{
                                        setOpen(!open)
                                        setModalData(today)
                                        type==1? setHeader("Today's Birthdays") : setHeader("Today's Work Anniversary")
                                    }}
                                    className={'font-size-14px bold-600 cursor-pointer'}>
                                    Show All
                                </ELinkHRDAshMUI>
                            </Grid>
                </Grid>
                
            </EBox>
        </>
    }

    </EBox>
  )
}

export default BirthdayTalent

const NoDataBirthdayBox = (props) => {
    const { children, heading = "",  src} = props;
    return (
        <EBox
            sx={{display:'flex', flexDirection:'column', alignItems:'center !important', width:'100%'}}
        >
            <img src={src} style={{maxHeight:'100px', marginBottom:'10px'}}/>
            <ETypography sx={{fontSize:'16px', fontWeight:'500'}}>{heading}</ETypography>
        {children}
        </EBox>
    );
  };