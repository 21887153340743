import React from 'react'
import { EBox } from '../../../BaseComponents/EBox'
import {  ListItem,  ListItemText } from "@mui/material"


import { ETypography } from '../../../BaseComponents/ETypography'
import { ECard } from '../../../BaseComponents/ECard';



function CustomRoleInfo() {
    return (
        <ECard>

            <EBox className='flex-row-allCenter'  >

                <EBox>
                    <img src="/assets/images/custom_role.svg" />
                    <ETypography variant='h5' className='font-size-16px mt-16px align-center'>
                        No custom role found
                    </ETypography>
                </EBox>

                <EBox px={4}>
                    <ETypography variant='h5' className='font-size-16px'>
                        Note:
                    </ETypography>


                    <ul className='listStylePadding'>

                        <li>
                            <ListItem alignItems='center' disablePadding>

                                <ListItemText primary=
                                    {<ETypography className="font-size-14px"  >
                                       Custom user role refers to a specific set of permissions and access rights that can be created and assigned to users within Entera Payroll by creating a new role.
                                    </ETypography>
                                    }
                                />
                            </ListItem>
                        </li>

                        <li>
                            <ListItem disablePadding>
                                <ListItemText primary=
                                    {<ETypography className="font-size-14px "  >
                                       This enables company owners to design unique roles based on their organization's needs, beyond role provided by Entera Payroll.
                                    </ETypography>
                                    }
                                />
                            </ListItem>
                        </li>

                    </ul>

                    <ul className='listStylePadding'>
                        <li>
                            <ListItem disablePadding>


                                <ListItemText primary=
                                    {<ETypography className="font-size-16px bold-700"  >
                                        Entera offers 5 different types of roles :
                                    </ETypography>
                                    }
                                />


                            </ListItem>
                        </li>

                    </ul>


                    <EBox sx={{ marginLeft: 4 }} >
                        <ul className='listStylePadding'>
                            <TextList role={'Super-Admin'} text={'Primary Super admin can add multiple secondary super admins. This role has no restrictions.'} />
                            <TextList role={'Admin'} text={'The In-charge/Reporting Manager is responsible for activities of the team assigned to them.'} />
                            <TextList role={'HR'} text={'User with this role is authorized for employee profile management, including their leave, appraisal & attendance.'} />
                            <TextList role={'Accountant'} text={'Users with this role have privilege related to payment of all the members of Entera Payroll.'} />
                            <TextList role={'Employees'} text={'All the Employees are authorized to manage their own account only.'} />
                        </ul>
                    </EBox>

                    <ul className='listStylePadding'>
                        <li>
                            <ListItem disablePadding>
                                <ListItemText primary=
                                    {<ETypography className="font-size-14px"  >
                                       The process of creating a custom user role typically involves defining the specific permissions associated with that role.
                                    </ETypography>
                                    }
                                />
                            </ListItem>
                        </li>
                    </ul>


                </EBox>

            </EBox>


        </ECard>
    )
}

export default CustomRoleInfo


export const TextList = ({role,text}) => {
    return <li>
        <ListItemText primary={
            <ETypography className="font-size-14px ">
                <span className=' theme-main-text-color-bold '>{role} : </span> {text}
            </ETypography>

        } />
    </li>
}
