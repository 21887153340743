import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { directPayValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction } from "../../action/PaymentAction";
import { PAYMENT_CATEGORY_OTHER, PAYMENT_MODE, REMARK_LIMIT } from "../../constants";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";



const PaymentDirectPayment = (props) => {
    const { selectedData, setModalState, auth, getPaymentFilterData, employeeList } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            remark: '',
            payment_mode: PAYMENT_MODE[0],
            payment_detail: '',
            talent: null,
            amount: '',
            is_extra_payment: true,
        },
        validationSchema: directPayValidation,
        onSubmit: (data) => {
            data = { ...data, created_by: auth?.authData?.id, transaction_category: PAYMENT_CATEGORY_OTHER }
            dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'direct-payment'));
            setModalState({ modalData: null, modalCategory: null })
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true}>
                            <EAutocomplete
                                name="Employee Name"
                                fullWidth
                                {...getFieldProps('talent')}
                                options={
                                    employeeList.employeeData && employeeList.employeeData?.count > 0 ? employeeList.employeeData?.rows : []
                                }
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('talent', value ? value : null);
                                }}

                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.label}
                                    </li>
                                )}

                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="talent"
                                        placeholder="Select Employee"
                                        error={Boolean(touched.talent && errors.talent)}
                                        helperText={touched.talent && errors.talent}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Amount'} isfullgrid={true} isModal={true}>
                            <ETextField
                                fullWidth
                                placeholder="Amount"
                                autoComplete="amount"
                                {...getFieldProps('amount')}
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Payment Mode'} isfullgrid={true} isModal={true}>
                            <EAutocomplete
                                name="payment_mode"
                                fullWidth
                                {...getFieldProps('payment_mode')}
                                options={PAYMENT_MODE}
                                onChange={(e, value) => {
                                    setFieldValue('payment_mode', value ? value : null);
                                }}
                                renderInput={(params) => (
                                    <ETextField
                                        {...params}
                                        name="payment_mode"
                                        placeholder="Payment Mode"
                                        fullWidth
                                        error={Boolean(touched.payment_mode && errors.payment_mode)}
                                        helperText={touched.payment_mode && errors.payment_mode}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>


                    {getFieldProps('payment_mode').value
                        && getFieldProps('payment_mode').value?.id == 'CHEQUE' &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={'Cheque Number'} isfullgrid={true} isModal={true}>
                                <ETextField
                                    name='payment_detail'
                                    {...getFieldProps('payment_detail')}
                                    placeholder="Cheque Number"
                                    rows={3}
                                    fullWidth
                                    error={Boolean(touched.payment_detail && errors.payment_detail)}
                                    helperText={touched.payment_detail && errors.payment_detail}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true}>
                            <ETextField
                                name='remark'
                                placeholder="Enter your remarks here"
                                multiline
                                rows={3}
                                {...getFieldProps('remark')}
                                fullWidth
                                inputProps={{ maxLength: REMARK_LIMIT }}
                                error={Boolean(touched.remark && errors.remark)}
                                helperText={touched.remark && errors.remark}
                            />
                            <ETypography className='font-size-14px mt-4px greyColor11-color'>
                                {`${formik?.values?.remark?.length || 0}/${REMARK_LIMIT}`}
                            </ETypography>
                        </ELabelInputMainGrid>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalCategory: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default PaymentDirectPayment