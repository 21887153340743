
import {
    COMPANY_LOADING, COMPANY_FAILURE, COMPANY_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    companyLoading: false,
    companyData: {},
    companySuccess: false,
    companyMessage: null,
};

const CompanyReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COMPANY_LOADING:
            return {
                companyLoading: true,
                companyData: {},
            };

        case COMPANY_SUCCESS:
            return {
                companyLoading: false,
                companySuccess: true,
                companyData: action.payload.data,
                employeeMessage: action.payload.message,
            };

        case COMPANY_FAILURE:
            return {
                companyLoading: false,
                companySuccess: false,
                companyData: {},
                employeeMessage: action.payload.message,
            };


        default:
            return state;
    }
};

export default CompanyReducer;
