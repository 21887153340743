import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { AuthGuard } from './auth-guard';
import { Outlet } from 'react-router-dom';


/**
 * [2023-04-5]
 * changes by: Purva Sharma
 * Description:- Removed AuthNavbar from layout and shifted to login and signup pages as per new design
 **/
const AuthLayoutRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  height:'100%',
  paddingTop: '42px', 
  flexDirection: 'column',
  backgroundColor:theme.palette.background.authDefault
}));

function AuthLayout(props) {

  return (
   <>
      <AuthLayoutRoot>
            <Outlet />
      </AuthLayoutRoot>

  </>
  );
};

export default AuthLayout;