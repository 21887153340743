import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { EAutocomplete } from "../BaseComponents/EAutocomplete";
import { EButtonOutlined, ELoadingButton } from "../BaseComponents/EButtons";

import { useFormik, Form, FormikProvider } from "formik";
import { ETextField } from "../BaseComponents/ETextField";
import { CHARACTER_LIMIT } from "../constants";
import { RequestNewFeature } from "../utils/validation";

import { CreateFeatureRequest } from "../action/RequestFeatureAction";
import { ELabelInputMainGrid } from "../BaseComponents/EGrid";
function RequestNewFeatureForm(props) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);

  // To close the modal
  const { close } = props;

  // option array for select field I.e category
 const categoryOptions =  [
    { label: "Dashboard", id: 1 },
    { label: "Employee", id: 2 },
    // { label: "Freelancer Contract", id: 3 },
    { label: "Attendance", id: 4 },
    { label: "Payroll", id: 5 },
    { label: "Payment", id: 6 },
    { label: "Report", id: 7 },
    { label: "Master", id: 8 },   
    { label: "User Management", id: 9 }, 
    { label: "Support", id: 10 },   
    { label: "Settings", id: 11 },
    { label: "My Profile", id: 12 },
    { label: "Change Password", id: 13 },
    { label: "Other/General", id: 14 }
  ]

  // saving object return from useFormik as formik
  const formikRequestFeature = useFormik({
    // IniitalValues for input fields
    initialValues: {
      category: null,
      feature: "",
      description: "",
      featureFile: null
    },
    // Validation for input fields
    validationSchema: RequestNewFeature,
    // onSubmit action
    onSubmit: (data) => {
      data = {
        category_id: data.category.id,
        feature: data.feature,
        description: data.description,
        feature_file: data.featureFile,
      };
      // calling action
      dispatch(CreateFeatureRequest(auth.authtoken, data, close));
    },
  });

  // Formik Props
  const { getFieldProps, setFieldValue, handleSubmit, touched, errors } =
    formikRequestFeature;

  // onChange for input[type='file']
  const requestFile = (e) => {
    if (e.target.files[0]) {
      setFieldValue("featureFile", e.target.files[0]);
    }
  };

  return (
    <FormikProvider value={formikRequestFeature}>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
          {/* Category */}
          <Grid item xs={12} sm={12} lg={12} xl={12}  >
            <ELabelInputMainGrid label={"Category*"}  >
              <EAutocomplete
                name="category"
                {...getFieldProps("category")}
                fullWidth
                options={categoryOptions}
                isOptionEqualToValue={(option, value) => option.id == value.id}
                onChange={(e, value) => {
                  setFieldValue("category", value ? value : null);
                }}
                renderInput={(params) => (
                  <ETextField
                    {...params}
                    name="category"
                    placeholder="Search Category"
                    fullWidth
                    error={Boolean(touched.category && errors.category)}
                    helperText={touched.category && errors.category}
                  />
                )}
              />
            </ELabelInputMainGrid>
          </Grid>

          {/* Feature */}
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid
              label={"Feature*"}
              alignlabel={'true'}
              style={{ border: "solid 2px red" }}
              className="pt-16px"
            >
              <ETextField
                name="feature"
                {...getFieldProps("feature")}
                fullWidth
                multiline
                rows={3}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                error={Boolean(touched.feature && errors.feature)}
                helperText={touched.feature && errors.feature}
                
              />

             {`${formikRequestFeature.values?.feature?.length}/${250}`}
    
            </ELabelInputMainGrid>
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12} lg={12} xl={12}   >

            <ELabelInputMainGrid
              label={"Description"}
              alignlabel={"true"}
            >
              <ETextField
                fullWidth
                name="description"
                {...getFieldProps("description")}
                multiline
                rows={3}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
              />
            </ELabelInputMainGrid>
          
          </Grid>

          {/* Attachment */}
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            className="input-file-selector"
          >
            <ELabelInputMainGrid label={"Attachment"}>
              <ETextField
                name="featureFile"
                type="file"
                accept= 'image/jpg, image/jpeg'
                // {...getFieldProps("featureFile")}
                onChange={(e) => requestFile(e)}
                fullWidth
                error={Boolean(touched.featureFile && errors.featureFile)}
                helperText={touched.featureFile && errors.featureFile}
              />
            </ELabelInputMainGrid>
          </Grid>
        </Grid>

        {/*Form Action */}
        <Stack
          direction="row"
          spacing={2}
          paddingTop={2}
          justifyContent="flex-end"
          className="pb-24px"
        >
          <EButtonOutlined size="large" variant="outlined" onClick={close}>
            {" "}
            Cancel{" "}
          </EButtonOutlined>
          <ELoadingButton size="large" type="submit" variant="contained">
            Submit
          </ELoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default RequestNewFeatureForm;
