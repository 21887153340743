import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { numberWithCommas } from "../../utils/formatNumber";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { PayTalentSalaryFormValidation } from "../../utils/validation";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import currencyRupeeIcon from '@iconify/icons-material-symbols/currency-rupee'
import { useDispatch } from "react-redux";
import { MakeTalentPayment } from "../../action/PaymentAction";
import { HOLD_STATUS, PAYMENT_MODE } from "../../constants";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useTheme } from "@emotion/react";


const PaymentModal = (props) => {
    const { paymentData, setSalaryDueModal, auth, getPaymentFilterData } = props;
    const dispatch = useDispatch();
    const theme = useTheme()

    let balanceAmount = Number(paymentData?.balance_amount) ? Number(paymentData?.balance_amount) : 0;

    const formik = useFormik({
        initialValues: {
            payment_mode: PAYMENT_MODE[0],
            payment_detail: '',
            amount: balanceAmount ? balanceAmount.toFixed(2) : '',
            remark: '',
            balanceAmount: balanceAmount ? balanceAmount.toFixed(2) : '',
            isType: false
        },
        validationSchema: PayTalentSalaryFormValidation,
        onSubmit: (data) => {
            data = {
                ...data,
                employee_id: paymentData?.employeeData?.id,
                payroll_id: paymentData?.payrollData?.id,
                transaction_id: paymentData?.id,
                // created_by: auth?.authData?.id
            }
            dispatch(MakeTalentPayment(auth?.authtoken, data, getPaymentFilterData, paymentData?.payrollData?.id))
            setSalaryDueModal({ modalData: null, modalCategory: null })
        }
    });


    const HandleAmount = (val) => {
        let reg = RegExp(/^\d+(\.\d{0,2})?$/);
        if (reg.test(val.target.value) || val.target.value == '') {
            setFieldValue('amount', val.target.value);
        }
    }

    const CheckDecimal = (val) => {
        if (decimalCount(val.target.value) == 0) {
            setFieldValue('amount', (val.target.value).replace(/\./g, ""));
        }
    }
    const decimalCount = (num) => {
        const numStr = String(num);
        if (numStr.includes('.')) {
            return numStr.split('.')[1].length;
        };
        return 0;
    }

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                            <ETypography>{`${paymentData?.employeeData?.fullname} (${paymentData?.employeeData?.employee_code})`}</ETypography>
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Balance Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                            <ETypography>{paymentData?.transaction_status == HOLD_STATUS && `Balance `}{` ${numberWithCommas(balanceAmount)}`}</ETypography>
                        </ELabelInputMainGrid>

                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Amount'} isfullgrid={true} isModal={true}>
                            <ETextField
                                name='amount'
                                {...getFieldProps('amount')}
                                rows={3}
                                fullWidth
                                InputProps={{
                                }}
                                placeholder="Enter Amount"
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                                onChange={(e) => HandleAmount(e)}
                                onBlur={(e) => CheckDecimal(e)}
                            />
                        </ELabelInputMainGrid>
                    </Grid>



                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Payment Mode'} isfullgrid={true} isModal={true}>
                            <EAutocomplete
                                name="Payment Mode"
                                fullWidth
                                {...getFieldProps('payment_mode')}
                                options={PAYMENT_MODE}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                onChange={(e, value) => {
                                    setFieldValue('payment_mode', value ? value : null)
                                    setFieldValue('isType', value?.id == 'CHEQUE' ? true : false)
                                    if (value?.id != 'CHEQUE') { setFieldValue('payment_detail', '') }
                                }}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='payment_mode'
                                        placeholder="Select Payment Mode"
                                        fullWidth
                                        error={Boolean(touched.payment_mode && errors.payment_mode)}
                                        helperText={touched.payment_mode && errors.payment_mode}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    {
                        getFieldProps('payment_mode').value && getFieldProps('payment_mode').value?.id == 'CHEQUE' &&
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={'Cheque Number'} isfullgrid={true} isModal={true}>
                                <ETextField
                                    name='payment_detail'
                                    {...getFieldProps('payment_detail')}
                                    placeholder="Enter Cheque Number"
                                    rows={3}
                                    fullWidth
                                    error={Boolean(touched.payment_detail && errors.payment_detail)}
                                    helperText={touched.payment_detail && errors.payment_detail}
                                    inputProps={{ maxLength: 6 }}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    }


                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true}>
                            <ETextField
                                name='remark'
                                multiline
                                placeholder="Give a brief description"
                                rows={3}
                                {...getFieldProps('remark')}
                                fullWidth
                                error={Boolean(touched.remark && errors.remark)}
                                helperText={touched.remark && errors.remark}
                                inputProps={{ maxLength: 250 }}
                            />
                            <ETypography sx={{color:theme.palette.border.border2, fontSize:'14px'}}>{formik?.values?.remark?.length}/250</ETypography>
                        </ELabelInputMainGrid>
                    </Grid>

                </Grid>


                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                </Stack>


            </Form>
        </FormikProvider>
    );
};

export default PaymentModal