import React from 'react';
import { ETextFieldSmall } from '../../../BaseComponents/ETextField';
import { EAutocomplete } from '../../../BaseComponents/EAutocomplete';

export const WeekAndDayDropDown = ({ options, placeholder = "", value = null, onChange = () => null, error, helperText, forcePopupIcon, isOptionEqualToValue = () => null }) => {

  return (
    <EAutocomplete
      fullWidth
      options={options}
      forcePopupIcon={forcePopupIcon}
      isOptionEqualToValue={isOptionEqualToValue}
      value={value}
      className='maxWidth-300px'
      sx={{
        maxWidth: '200px',
        '& .MuiInputBase-input': {
          fontSize: 14,
        }
      }}
      onChange={onChange}
      renderInput={params => (
        <ETextFieldSmall
          {...params}
          size='small'
          name='default_weekly_off_day'
          placeholder={placeholder}
          error={error}
          helperText={helperText} />
      )} />
  )
}
