import { Grid } from "@mui/material"


function PlanDetail({data}) {
   

  return (
    <Grid container spacing={2} justifyContent="left">
        <Grid item >

            {
              data?.map((item,index)=>{
                return <li key={index} style={{paddingLeft:'0',marginBottom:'20px'}}>{item.title}</li>
              })
            }

        </Grid>
       
    </Grid>
  )
}

export default PlanDetail