import { Grid, IconButton, InputAdornment } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypography } from "../../BaseComponents/ETypography";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { ETextField } from "../../BaseComponents/ETextField";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { EIcon } from "../../BaseComponents/EIcon";


const AddEmergencyDetail = (props) => {
    const { formikTalents, isOpen, TalentSetting } = props;
    const { errors, touched, getFieldProps, setFieldValue } = formikTalents;

    return (


        <ECard className={!getFieldProps('emergency_contact_detail_check').value ? 'card_design_2' : 'card_design_1 pb-8px'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography variant='h5' className="font-size-20px" >
                    Emergency Details
                </ETypography>
                {!isOpen && 
                <IconButton
                    onClick={() => setFieldValue('emergency_contact_detail_check', !getFieldProps('emergency_contact_detail_check').value)}>
                    <EIcon icon={getFieldProps('emergency_contact_detail_check').value ? minusBox : plusBox} />
                </IconButton>
                }
            </Grid>
            {getFieldProps('emergency_contact_detail_check').value && <>
                <EDivider />
                <Grid container spacing={2} py={2}>
                        <Grid item md={6} sm={6} xs={12} >
                          <ELabelInputMainGrid label={'Name'+(TalentSetting?.field_emer_name?'*':'')} >
                                <ETextField
                                    fullWidth
                                    multiline
                                    autoComplete="emr_person_name"
                                    {...getFieldProps('emr_person_name')}
                                    error={Boolean(touched.emr_person_name && errors.emr_person_name)}
                                    helperText={touched.emr_person_name && errors.emr_person_name}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <ELabelInputMainGrid label={'Relation'+(TalentSetting?.field_emer_relation?'*':'')} >
                                <ETextField
                                    fullWidth
                                    multiline
                                    autoComplete="emr_contact_relation"
                                    {...getFieldProps('emr_contact_relation')}
                                    error={Boolean(touched.emr_contact_relation && errors.emr_contact_relation)}
                                    helperText={touched.emr_contact_relation && errors.emr_contact_relation}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                          <ELabelInputMainGrid label={'Contact Number'+(TalentSetting?.field_emer_contact_no?'*':'')} >
                                <ETextField
                                    fullWidth
                                    autoComplete="emr_contact_mobile"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 10 }}
                                    {...getFieldProps('emr_contact_mobile')}
                                    error={Boolean(touched.emr_contact_mobile && errors.emr_contact_mobile)}
                                    helperText={touched.emr_contact_mobile && errors.emr_contact_mobile}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                               <span className="color-text-primary">+91&nbsp;</span> 
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                </Grid>
            </>}
        </ECard>

    )

};

export default AddEmergencyDetail;