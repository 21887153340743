import React from 'react'
import { ECardBasic } from '../../BaseComponents/ECard'
import { ETypography } from '../../BaseComponents/ETypography'
import { useTheme } from '@mui/material'
import { EBox } from '../../BaseComponents/EBox'

export const SelectableCard = ({ title = "", subTitle = "", onClick = () => { }, cardSx = {}, titleSx = {}, subTitleSx = {}, selected = false, right = null }) => {

  const theme = useTheme()
  return (
    <ECardBasic
      onClick={onClick}
      className={selected ? 'Bg-card4' : 'border-05px-border6'}
      sx={{
        cursor: "pointer",
        padding: 2,
        margin: 0,
        ...cardSx
      }}
    >
      <EBox display="flex" justifyContent="space-between" alignItems="center">
        <EBox>
          <ETypography className='bold-600' sx={{ color: theme.palette.primary.main, ...titleSx }} >{title}</ETypography>
          <ETypography className='bold-600 font-size-18px' sx={{ ...subTitleSx }}>{subTitle}</ETypography>
        </EBox>
        {right}
      </EBox>
    </ECardBasic>
  )
}
