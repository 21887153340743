import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { AddNewTicketValidation } from "../../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { SUPPORT_PRIORITY } from "../../constants";
import { CreateTicket } from "../../action/SupportAction";
import { ELabelInputMainGrid, LoadingGrid } from "../../BaseComponents/EGrid";

const AddTicket = (props) => {

    const { setModalState, auth, getPaymentFilterData } = props;

    const supportReducer = useSelector(state => state.SupportReducer);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            subject: "",
            message: "",
            attachment: null,
            priority: ""
        },
        validationSchema: AddNewTicketValidation,
        onSubmit: (data) => {

            data = { ...data, 'createdBy': auth?.authData?.id }
            data.department = 'Technical';
            data.priority = data.priority.id;
            dispatch(CreateTicket(auth.authtoken, data, getPaymentFilterData));
            // setModalState({ modalData: null, modalName: null })
            // data = {
            //     ...data,
            //     employee_id: paymentData.employee_id,
            //     payroll_id: paymentData.payroll_id,
            //     transaction_id: paymentData.id,
            //     created_by: auth?.authData?.id
            // }
            // dispatch(MakeTalentPayment(auth?.authtoken, data, getPaymentFilterData))
            // setSalaryDueModal({ modalData: null, modalCategory: null })
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;
    const PhotoChange = (e) => {
        if (e.target.files?.length > 0) {
            setFieldValue("attachment", e.target.files);
        }
    };

    return (
        <>
            {!supportReducer.supportLoading ? 
            <FormikProvider value={formik} >
                <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={'Subject'}>
                                <ETextField
                                    fullWidth
                                    name="subject"
                                    {...getFieldProps("subject")}
                                    error={Boolean(touched.subject && errors.subject)}
                                    helperText={touched.subject && errors.subject}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                             <ELabelInputMainGrid label={'Problem Statement'} alignlabel={'align-top'}>
                                <ETextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    name="message"
                                    {...getFieldProps("message")}
                                    error={Boolean(touched.message && errors.message)}
                                    helperText={touched.message && errors.message}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12} className="input-file-selector">
                            <ELabelInputMainGrid label={'Attachment'}>
                                <ETextField
                                    fullwidth="true"
                                    type="file"
                                    fullWidth
                                    inputProps={{
                                        multiple: true
                                    }}
                                    accept='image/png, image/jpg, image/jpeg'
                                    onChange={(e) => { PhotoChange(e) }}
                                    error={Boolean(touched.attachment && errors.attachment)}
                                    helperText={'Allowed formats are *.jpeg, *.jpg & *.png'}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                           <ELabelInputMainGrid label={'Priority'}>
                                <EAutocomplete
                                    name="priority"
                                    fullWidth
                                    options={SUPPORT_PRIORITY?.length > 0 ? SUPPORT_PRIORITY : []}
                                    onChange={(e, value) => {
                                        setFieldValue("priority", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='priority'
                                            fullWidth
                                            placeholder='Select'
                                            error={Boolean(touched.priority && errors.priority)}
                                            helperText={touched.priority && errors.priority}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                    </Grid>
                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                        <EButtonOutlined size="large" variant="outlined"
                            onClick={() => setModalState({ modalData: null, modalName: null })}
                        > Cancel </EButtonOutlined>
                        <ELoadingButton
                            size="large" variant="outlined" type="submit" >Submit</ELoadingButton>
                    </Stack>
                </Form>
            </FormikProvider> :
               <LoadingGrid sx={{ minHeight: '30vh' }}/>
                }

        </>
    );
};

export default AddTicket