import { Grid, ListItem, Stack } from "@mui/material"
import { EBox } from "../../../BaseComponents/EBox"
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid"
import { ETextField } from "../../../BaseComponents/ETextField"
import { EButton, EButtonOutlined } from "../../../BaseComponents/EButtons"
import { Form, FormikProvider, useFormik } from "formik"
import { InviteHRFormValidation, ScheduleACallFormValidation } from "../../../utils/validation"
import { useDispatch } from "react-redux"
import { InviteHRAction } from "../../../action/InviteHRAction"

export const InviteHR = ({ auth,open, setOpen }) => {

    const dispatch = useDispatch()
    const close = ()=>{
        setOpen(!open)  
    }

   
    const formikInviteHR =  useFormik({
        // IniitalValues for input fields
        initialValues: {
          fname: '',
          lname: '',
          email_id:'',
          contact_number:''
        },
        // Validation for input fields
        validationSchema: InviteHRFormValidation, 
        // onSubmit action
        onSubmit: (data) => {
          // call action
          dispatch(InviteHRAction(auth.authtoken,data,close))

        },
      });
      // Formik Props
  const { getFieldProps,resetForm,setFieldValue, handleSubmit, touched, errors } =
  formikInviteHR;

  const handleCancel = ()=>{
    setOpen(false)
    resetForm();
  }
    return (
        <EBox>
            <Stack>
                <FormikProvider value={formikInviteHR}>
                    <Form noValidate onSubmit={handleSubmit}>
                        {/* First Name */}
                        <Grid >
                            <EBox className="flex-row-spaceBetween">

                                <ELabelInputMainGrid label='First Name'>
                                    <ETextField
                                        fullWidth
                                        {...getFieldProps("fname")}
                                        className="mb-16px"
                                        name="fname"
                                        placeholder='First Name'
                                        onChange={(e)=>setFieldValue('fname',e.target.value)}
                                        error={Boolean(touched.fname && errors.fname)}
                                        helperText={touched.fname && errors.fname}
                                    />
                                </ELabelInputMainGrid>
                            </EBox>
                        </Grid>

                         {/* Last Name */}
                         <Grid >
                            <EBox className="flex-row-spaceBetween">

                                <ELabelInputMainGrid label='Last Name'>
                                    <ETextField
                                        fullWidth
                                        {...getFieldProps("lname")}
                                        className="mb-16px"
                                        name="lname"
                                        placeholder='Last Name'
                                        onChange={(e)=>setFieldValue('lname',e.target.value)}
                                        error={Boolean(touched.lname && errors.lname)}
                                        helperText={touched.lname && errors.lname}
                                    />
                                </ELabelInputMainGrid>
                            </EBox>
                        </Grid>

                         {/* email */}
                        <Grid >
                            <EBox className="flex-row-spaceBetween" >
                                <ELabelInputMainGrid label='Email ID'>
                                    <ETextField
                                        fullWidth
                                        {...getFieldProps("email_id")}
                                        className="mb-16px"
                                        placeholder='Email ID'
                                        name="email_id"
                                        onChange={(e)=>setFieldValue('email_id',e.target.value)}
                                        error={Boolean(touched.email_id && errors.email_id)}
                                        helperText={touched.email_id && errors.email_id}
                                  />
                                </ELabelInputMainGrid>
                            </EBox>

                        </Grid>

                           {/* contact_number */}
                           <Grid >
                            <EBox className="flex-row-spaceBetween" >
                                <ELabelInputMainGrid label='Contact Number'>
                                    <ETextField
                                        fullWidth
                                        className="mb-16px"
                                        placeholder='Contact Number'
                                        type="text"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',maxLength: 10 }}
                                        {...getFieldProps("contact_number")}
                                        name="contact_number"
                                        onChange={(e)=>setFieldValue('contact_number',e.target.value)}
                                        error={Boolean(touched.contact_number && errors.contact_number)}
                                        helperText={touched.contact_number && errors.contact_number}
                                    />
                                </ELabelInputMainGrid>
                            </EBox>

                        </Grid>
                        <CancelModal handleClose={handleCancel} />
                    </Form>
                </FormikProvider>


            </Stack>


        </EBox>

    )
}

export const CancelModal = ({ handleClose }) => {
    return (
        <ListItem className='p0 m0' sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>
            <EBox sx={{ marginLeft: 'auto', marginTop: '15px' }}>
                <EButtonOutlined variant='outlined' size='large' onClick={() => handleClose()} sx={{ marginRight: '10px' }}> Cancel</EButtonOutlined>
                <EButton type="submit" variant="contained" size='large' > Invite</EButton>
            </EBox>
        </ListItem>
    )

}

