import { Grid, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { EDesktopDatePicker } from "../../BaseComponents/EDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Field, Form, FormikProvider } from "formik";
import { updateTalentPersonalDetail } from "../../action/TalentActions";
import { EditPersonalDetailValidation } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import moment from "moment";
import { BloodGroupOptions, GenderOptions, MaritalStatusOptions, PINCODE_LIMIT } from "../../constants";
import { CityList } from "../../action/StateCityAction";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { capitalizeCapitalString } from "../../utils/formatText";


const EditModalPersonalDetail = (props) => {
    const { auth, employeeData, closePersonalDetailModal, TalentSetting } = props;
    const cityList = useSelector(state => state.CityReducer.cityListData?.rows);
    const stateList = useSelector(state => state.StateReducer.stateListData?.rows);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialGender = GenderOptions?.find(o => o.id === employeeData.employeeInfo?.personal_gender);

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            personal_detail_check: true,
            talent_id: employeeData.id,
            personal_dob: employeeData.employeeInfo?.personal_dob ? moment(employeeData.employeeInfo?.personal_dob).toDate() : null,
            personal_gender: initialGender ? initialGender : null,


            personal_address_one: employeeData.employeeInfo?.personal_address_one ? employeeData.employeeInfo?.personal_address_one : '',
            personal_address_two: employeeData.employeeInfo?.personal_address_two ? employeeData.employeeInfo?.personal_address_two : '',
            personal_state_id: '',
            personal_district_id: '',
            personal_pin_code: employeeData.employeeInfo?.personal_pin_code ? employeeData.employeeInfo?.personal_pin_code : '',
            landmark: '',

            personal_marital_status_obj: employeeData.employeeInfo?.personal_marital_status ? { id: employeeData.employeeInfo?.personal_marital_status, label: capitalizeCapitalString(employeeData.employeeInfo?.personal_marital_status) } : null,
            personal_blood_group_obj: employeeData.employeeInfo?.personal_blood_group ? { id: employeeData.employeeInfo?.personal_blood_group, label: capitalizeCapitalString(employeeData.employeeInfo?.personal_blood_group) } : null,
            // personal_gender_object: null,
            personal_state: employeeData.employeeInfo?.employeeState ? employeeData.employeeInfo?.employeeState : null,
            personal_district: employeeData.employeeInfo?.employeeCity ? employeeData.employeeInfo?.employeeCity : null,
        },
        validationSchema: EditPersonalDetailValidation(TalentSetting),//(new updated on - 24-4-23)
        onSubmit: (data) => {
            console.log(data);
            if (data) {
                data = {
                    ...data, personal_gender: data.personal_gender?.id ? data.personal_gender?.id : '',
                    company_id: auth.authData?.company_id,
                    landmark: data.personal_address_two
                }
                if (data?.personal_blood_group_obj?.id) { data.personal_blood_group = data.personal_blood_group_obj.id }
                if (data?.personal_marital_status_obj?.id) { data.personal_marital_status = data.personal_marital_status_obj.id; }
                if (data?.personal_district?.id) { data.personal_district_id = data?.personal_district?.id; }
                if (data?.personal_state?.id) { data.personal_state_id = data?.personal_state?.id; }

                dispatch(updateTalentPersonalDetail(auth.authtoken, data, navigate))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={12} xs={12} >
                            <ELabelInputMainGrid label={"Date of Birth" + (TalentSetting?.field_dob ? '*' : '')} >
                                <EDesktopDatePicker
                                    name="personal_dob"
                                    defaultCalendarMonth={moment().subtract(18, 'years')._d} //To open the calender in (current-18) year
                                    {...getFieldProps('personal_dob')}
                                    views={["day", 'month', 'year']}
                                    maxDate={moment().subtract(18, "years")}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(selectedDate) => {
                                        setFieldValue("personal_dob", selectedDate ? selectedDate : null)
                                    }}
                                    inputProps={{ readOnly: true }}
                                    renderInput={(params) =>
                                        <Field
                                            // onClick={(e) => setOpenDateDOB(true)}
                                            component={ETextField}
                                            {...params}
                                            fullWidth
                                            name="personal_dob"
                                            error={Boolean(touched.personal_dob && errors.personal_dob)}
                                            helperText={touched.personal_dob && errors.personal_dob}
                                        />}
                                />
                            </ELabelInputMainGrid>

                        </Grid>
                        <Grid item md={6} sm={12} xs={12} >
                            <ELabelInputMainGrid label={"Gender" + (TalentSetting?.field_gender ? '*' : '')}  >
                                <EAutocomplete
                                    name="personal_gender"
                                    fullWidth
                                    forcePopupIcon={formik?.values?.personal_gender ? false : true}
                                    options={GenderOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_gender')}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_gender", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_gender'
                                            fullWidth
                                            placeholder='Select'
                                            error={Boolean(touched.personal_gender && errors.personal_gender)}
                                            helperText={touched.personal_gender && errors.personal_gender}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} >
                            <ELabelInputMainGrid label={'Marital Status' + (TalentSetting?.field_marital_status ? '*' : '')} >
                                <EAutocomplete
                                    name="personal_marital_status_obj"
                                    fullWidth
                                    forcePopupIcon={formik?.values?.personal_marital_status_obj ? false : true}
                                    options={MaritalStatusOptions && MaritalStatusOptions?.length > 0 ? MaritalStatusOptions : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_marital_status_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_marital_status_obj", value)
                                    }}

                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_marital_status_obj'
                                            fullWidth

                                            placeholder='Select Marital Status'
                                            error={Boolean(touched.personal_marital_status_obj && errors.personal_marital_status_obj)}
                                            helperText={touched.personal_marital_status_obj && errors.personal_marital_status_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <ELabelInputMainGrid label={'Blood Group' + (TalentSetting?.field_blood_group ? '*' : '')} >
                                <EAutocomplete
                                    name="personal_blood_group_obj"
                                    fullWidth
                                    options={BloodGroupOptions && BloodGroupOptions?.length > 0 ? BloodGroupOptions : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    forcePopupIcon={formik?.values?.personal_blood_group_obj ? false : true}
                                    getOptionLabel={(option) => option.label || ''}
                                    {...getFieldProps('personal_blood_group_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue('personal_blood_group_obj', value);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="personal_blood_group_obj"
                                            placeholder="Select Blood Group"
                                            fullWidth
                                            error={Boolean(touched.personal_blood_group_obj && errors.personal_blood_group_obj)}
                                            helperText={touched.personal_blood_group_obj && errors.personal_blood_group_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <ELabelInputMainGrid label={"Address" + (TalentSetting?.field_address ? '*' : '')} isfullgrid={true}  >
                                <ETextField
                                    fullWidth
                                    name='personal_address_one'
                                    autoComplete="personal_address_one"
                                    placeholder="Address "
                                    {...getFieldProps('personal_address_one')}
                                    error={Boolean(touched.personal_address_one && errors.personal_address_one)}
                                    helperText={touched.personal_address_one && errors.personal_address_one}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <ELabelInputMainGrid label={'Landmark' + (TalentSetting?.field_address ? '*' : '')} >
                                <ETextField
                                    fullWidth
                                    name='personal_address_two'
                                    placeholder="Landmark "
                                    {...getFieldProps('personal_address_two')}
                                    error={Boolean(touched.personal_address_two && errors.personal_address_two)}
                                    helperText={touched.personal_address_two && errors.personal_address_two}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} >
                            <ELabelInputMainGrid label={"State" + (TalentSetting?.field_address ? '*' : '')} >
                                <EAutocomplete
                                    name="personal_state"
                                    label="State"
                                    fullWidth
                                    forcePopupIcon={formik?.values?.personal_state ? false : true}
                                    options={stateList && stateList?.length > 0 ? stateList : []}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_state')}
                                    onChange={(e, value) => {
                                        if (value) { dispatch(CityList(auth.authtoken, value.id)) }
                                        setFieldValue("personal_state", value)
                                        setFieldValue("personal_district", null)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_state'
                                            placeholder="Select State"
                                            fullWidth
                                            error={Boolean(touched.personal_state && errors.personal_state)}
                                            helperText={touched.personal_state && errors.personal_state}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                            <ELabelInputMainGrid label={"City" + (TalentSetting?.field_address ? '*' : '')} >
                                <EAutocomplete
                                    name="personal_district"
                                    fullWidth
                                    forcePopupIcon={formik?.values?.personal_district ? false : true}
                                    options={cityList && cityList?.length > 0 ? cityList : []}
                                    {...getFieldProps('personal_district')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_district", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_district'
                                            placeholder="Select City"
                                            fullWidth
                                            error={Boolean(touched.personal_district && errors.personal_district)}
                                            helperText={touched.personal_district && errors.personal_district}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12} >
                            <ELabelInputMainGrid label={"Pincode" + (TalentSetting?.field_address ? '*' : '')} >
                                <ETextField
                                    fullWidth
                                    autoComplete="personal_pin_code"
                                    placeholder="Pincode "
                                    inputProps={{ maxLength: PINCODE_LIMIT }}
                                    {...getFieldProps('personal_pin_code')}
                                    error={Boolean(touched.personal_pin_code && errors.personal_pin_code)}
                                    helperText={touched.personal_pin_code && errors.personal_pin_code}
                                />

                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>

                    <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                        <EButtonOutlined size="large" variant="outlined"
                            onClick={() => closePersonalDetailModal(false)}
                        > Cancel </EButtonOutlined>
                        <ELoadingButton
                            // loading={holiday?.holidayLoading} 
                            size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                    </Stack>
                </LocalizationProvider>
            </Form>
        </FormikProvider>

    )

};

export default EditModalPersonalDetail;