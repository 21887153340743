import { memo } from "react";
import PropTypes from 'prop-types';
import { useTheme } from "@mui/material";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { ETypography } from "./ETypography";

function CustomNoRowsOverlay(customEmptyMsg = "") {
  return (
    <ETypography sx={{ mt: 1, fontWeight: 600 }} className="font-size-14px">
      {customEmptyMsg || "No record found"}
    </ETypography>
  );
}

export const EDataGrid = memo(({ columns = [], rows = [], customEmptyMsg = "", hideDisabledCheckbox = false, ...rest }) => {

  const theme = useTheme();

  return (
    <DataGrid
      rows={rows}
      getRowId={(row) => row.id}
      columns={columns}
      autoHeight
      disableSelectionOnClick={true}
      disableColumnFilter
      disableColumnMenu
      hideFooterPagination={true}
      hideFooter={true}
      showCellRightBorder={false}
      showColumnRightBorder={false}
      disableColumnSelector={true}
      components={{
        NoRowsOverlay: () => CustomNoRowsOverlay(customEmptyMsg),
        NoResultsOverlay: () => CustomNoRowsOverlay(customEmptyMsg),
      }}
      sx={{
        border: 'none !important',
        overflow: 'hidden',
        width: '100%',
        [`& .${gridClasses.main} > div ${rows?.length === 0 ? " + div" : ""}`]: {
          backgroundColor: theme.palette.background.tableHeader,
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.primary.main,
          borderRadius: 0,
          lineHeight: 'initial !important',
          whiteSpace: 'initial !important',
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
          fontWeight: 600,
          textOverflow: 'clip',
          whiteSpace: 'initial !important',
        },
        [`& .${gridClasses.columnHeaderTitleContainerContent} *`]: {
          whiteSpace: 'initial !important',
        },
        [`& .${gridClasses.row}`]: {
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.primary.main,
          minHeight: '50px !important',
          // maxHeight: 'initial !important',
          lineHeight: 'initial !important',
          whiteSpace: 'initial !important',
        },
        [`& .${gridClasses.row}:last-child`]: {
          borderBottom: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus-within, .${gridClasses.cell}:focus-within `]: {
          outline: "none",
        },
        [`& .${gridClasses.columnSeparator}`]: {
          display: 'none',
        },
        [`&.${gridClasses.row}`]: {
          border: 'none',
          whiteSpace: 'initial !important',
        },
        [`& .${gridClasses.cell}`]: {
          border: "none",
          maxHeight: 'initial !important',
        },
        [`& .${gridClasses.cell}:focus`]: {
          outline: "none",
        },
        [`& .Mui-disabled`]: {
          display: hideDisabledCheckbox ? "none" : 'initial',
        },
        ...rest?.sx
      }}
      {...rest}
    />
  )
});

EDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};
