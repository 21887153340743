import {
    TAC_LIST_LOADING,
    TAC_LIST_SUCCESS,
    TAC_LIST_FAILURE,
    TAC_LOADING,
    TAC_SUCCESS,
    TAC_FAILURE
  } from '../constants';
  
  const INITIAL_VALUE = {
    tacListData: {},
    tacListSuccess: false,
    tacListLoading: false,
    tacListMessage: null,
    tacData: {},
    tacSuccess: false,
    tacLoading: false,
    freelanceMessage: null
  };
  
  const TandCReducer = (state = INITIAL_VALUE, action) => {
    switch (action.type) {
      case TAC_LIST_LOADING:
        return {
          ...state,
          tacListLoading: true,
          tacListData: {},
          tacListSuccess: false,
          tacListMessage: null
        };
      case TAC_LIST_SUCCESS:
        return {
          ...state,
          tacListLoading: false,
          tacListSuccess: true,
          tacListData: action.payload.data,
          tacListMessage: action.payload.message
        };
  
      case TAC_LIST_FAILURE:
        return {
          ...state,
          tacListLoading: false,
          tacListSuccess: false,
          tacListData: {},
          tacListMessage: action.payload.message
        };
      case TAC_FAILURE:
        return {
          ...state,
          tacLoading: false,
          tacSuccess: false,
          tacData: {},
          tacMessage: action.payload.message
        };
      case TAC_LOADING:
        return {
          ...state,
          tacLoading: true,
          tacData: {},
          tacSuccess: false,
          tacMessage: null
        };
      case TAC_SUCCESS:
        return {
          ...state,
          tacLoading: false,
          tacSuccess: true,
          tacData: action.payload.data,
          tacMessage: action.payload.message
        };
      default:
        return state;
    }
  };
  
  export default TandCReducer;
  