import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Grid, IconButton } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import { DEFAULT_ROWS_PERPAGE, DELETE, PAGES, PERMISSIONS, ROW_SIZE_PER_PAGE } from '../../constants';
import { EIcon } from '../../BaseComponents/EIcon';
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import EModal from '../../BaseComponents/EModal';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import { LeaveMasterList, CreateUpdateDelLeaveMaster } from '../../action/LeaveAction';
import LeaveMasterForm from '../../PageComponents/Masters/LeaveMasterForm';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import { capitalizeCapitalString } from '../../utils/formatText';
import EChip from '../../BaseComponents/EChip';
import EHidden from '../../BaseComponents/EHidden';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import postAddRounded from '@iconify/icons-material-symbols/post-add-rounded'
import { useCheckPermission } from '../../hooks';


const getTableHead = (allowAction) => {
    const arrayHead = [
        { id: 'Leave Title', label: 'Leave Title', alignRight: false },
        { id: 'Status', label: 'Status', alignRight: 'center' },
    ]
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead
}

function LeaveMaster() {
    const navigate = useNavigate()
    const auth = useSelector((state) => state.authReducer);
    const Theme = useSelector((state) => state.ThemeReducer);
    const leaveReasons = useSelector((state) => state.LeaveMasterReducer);
    const dispatch = useDispatch()
    const [page, setPage] = useState(0);
    const [isModal, SetIsModal] = useState({ open: false, data: null, isDelete: false });
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

    const allowAddEditDeleteMaster = useCheckPermission([PERMISSIONS.ViewAddEditDeleteMaster])
  
    const TABLE_HEAD = useMemo(() => getTableHead(allowAddEditDeleteMaster), [])

    useEffect(() => {
        if (auth.authtoken) {
            callApi(page, rowsPerPage);
        }
    }, [auth?.authtoken])

    const handleChangePage = (event, newPage) => {
        callApi(newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(PAGES, rowPerPageCount);
        setPage(PAGES);
    };

    const callApi = (fnPage, fnRowPerPage) => {
        dispatch(LeaveMasterList(auth.authtoken, fnPage, fnRowPerPage));
    }

    //INFO: This function contains action for delete of leave reason
    const HandleDelete = () => {
        const data = { id: isModal?.data?.id };
        function Close() { SetIsModal({ ...isModal, isDelete: false }) }
        dispatch(CreateUpdateDelLeaveMaster(auth?.authtoken, data, DELETE, Close))
    }

    return (
        <Page title="Leave Master" >
            <Grid container display="row" justifyContent="space-between" className='align-center'>
                <Grid item >
                    <EHeaderBreadcrumbs
                        heading='Leave Master'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Master', href: '' },
                            {
                                name: 'Leave Master',
                            }
                        ]}
                    /></Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        {
                            allowAddEditDeleteMaster &&
                            <EButton variant="contained" onClick={() => SetIsModal({ ...isModal, open: true, data: null })}> Add Leave Title</EButton>
                        }
                        <EButtonOutlined className='button-left-margin' variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
                <Grid item>
                    <ETypographyPageHeading variant='h5'>Leave Master</ETypographyPageHeading>
                </Grid>
                <Grid item>
                    <EHidden width="mdUp">
                        <Grid item>
                            {
                                allowAddEditDeleteMaster &&
                                <EButtonIcon variant="filled" onClick={() => SetIsModal({ ...isModal, open: true, data: null })} className='button-left-margin'>
                                    <EIcon icon={postAddRounded} className='height-width-15px' /> </EButtonIcon>
                            }

                            <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>
                                <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                        </Grid>
                    </EHidden>
                </Grid>
                <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid>
            </Grid>

            <EBoxPage>
                <EScrollbar>
                    <ETableContainer >
                        <ETable>

                            {leaveReasons?.leaveMasterListLoading && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={leaveReasons?.leaveMasterListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />

                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            </>
                            }
                            {!leaveReasons?.leaveMasterListLoading && leaveReasons?.leaveMasterListData?.rows?.length > 0 && <>
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={leaveReasons?.leaveMasterListData?.rows?.length}
                                    onRequestSort={() => null}
                                    authvar={auth}
                                    Theme={Theme}
                                />
                                <ETableBody>

                                    {leaveReasons.leaveMasterListData.rows?.map((row, index) => {
                                        const { leave_reason, id, status } = row;

                                        return (
                                            <ETableRow hover key={index + leave_reason}>
                                                <ETableCell align="left" className='text-transform-capitalize' >{leave_reason}</ETableCell>
                                                <ETableCell align="center" className='width-20'>
                                                    <EChip label={capitalizeCapitalString(status ? 'Enable' : 'Disable')} />
                                                </ETableCell>

                                                {
                                                    allowAddEditDeleteMaster &&
                                                    <ETableCell align="center" className='width-20'>
                                                        {/**
                                                            * The condition row.company_id is for if company_id=0 it means it is
                                                            * default by ENTERA Which is not editable and deletable
                                                        */}
                                                        {row.company_id > 0 &&
                                                            <Grid container sx={{ flexWrap: 'nowrap' }}>
                                                                <Grid item>
                                                                    <IconButton className='p0'
                                                                        onClick={() => SetIsModal({
                                                                            ...isModal, open: true,
                                                                            data: { leave_reason: row?.leave_reason, status: row?.status, id: id }
                                                                        })} >
                                                                        <EIcon icon={EditIcon} />
                                                                    </IconButton>
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton className='p0'
                                                                        onClick={() => SetIsModal({
                                                                            ...isModal, isDelete: true,
                                                                            data: { leave_reason: row?.leave_reason, status: row?.status, id: id }
                                                                        })} >
                                                                        <EIcon icon={DeleteIcon} className='color-text-error-red' />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    </ETableCell>
                                                }
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            </>}


                        </ETable>
                    </ETableContainer>
                </EScrollbar>
                {!leaveReasons?.leaveMasterListLoading && leaveReasons?.leaveMasterListData?.rows?.length == 0 && (
                    <SearchNotFound />
                )}
                {!leaveReasons?.leaveMasterListLoading && leaveReasons?.leaveMasterListData?.count > DEFAULT_ROWS_PERPAGE &&
                    <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={leaveReasons?.leaveMasterListData?.count ? leaveReasons?.leaveMasterListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </EBoxPage>
            {/* INFO: This is the update/add modal */}
            {isModal.open &&
                <EModal open={isModal.open}
                    close={() => {
                        SetIsModal({ ...isModal, open: false });
                    }} headervalue={isModal.data ? "Update Leave Title" : "Add Leave Title"}>
                    <LeaveMasterForm close={() => SetIsModal({ ...isModal, open: false })} rowData={isModal?.data} loading={leaveReasons?.leaveMasterListLoading} auth={auth} />
                </EModal>
            }

            {/* INFO: This is the delete modal */}
            {isModal.isDelete && (
                <EModal
                    open={isModal.isDelete}
                    close={() => SetIsModal({ ...isModal, isDelete: false })}
                    headervalue={"Leave Title: Delete Confirmation"}
                >
                    <Grid container spacing={2} >
                        <Grid item>
                            <ETypography className="font-size-18px "> Are you sure you want to delete
                                <span className="theme-main-text-color-bold font-style-italic">{isModal?.data?.leave_reason ? " " + isModal?.data?.leave_reason : 'this leave title?'}  </span> ?</ETypography>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                            <EButtonOutlined size="large" variant="outlined" onClick={() => { SetIsModal({ ...isModal, isDelete: false }) }} > No </EButtonOutlined>
                            <ELoadingButton size="large" type="submit" variant="contained" onClick={HandleDelete} className='button-left-margin'> Yes </ELoadingButton>
                        </Grid>
                    </Grid>
                </EModal>
            )}

        </Page>
    );
}
export default LeaveMaster
