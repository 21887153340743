import {CITY_LIST_FAILURE, CITY_LIST_SUCCESS, CITY_LIST_LOADING
} from '../constants';


const INITIAL_CITY = {
    cityListData: {},
    cityListSuccess: false,
    cityListLoading: false,
    cityListMessage: null,

};

const CityReducer = (city = INITIAL_CITY, action) => {
    switch (action.type) {    

        case CITY_LIST_LOADING:
            return {
                cityListLoading: true,
                cityListData: {}
            };

        case CITY_LIST_SUCCESS:
            return {
                cityListLoading: false,
                cityListSuccess: true,
                cityListData: action.payload.data,
                cityListMessage: action.payload.message,
            };

        case CITY_LIST_FAILURE:
            return {
                cityListLoading: false,
                cityListSuccess: false,
                cityListMessage: action.payload.message,
                cityListData: {}
            };

        default:
            return city;
    }
};

export default CityReducer;
