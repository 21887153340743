
import {
    NOTIFICATION_LIST_FAILURE, NOTIFICATION_LIST_LOADING, NOTIFICATION_LIST_SUCCESS, 
    // NOTIFICATION_COUNT_LOADING, NOTIFICATION_COUNT_SUCCESS, NOTIFICATION_COUNT_FAILURE
} from '../constants';


const INITIAL_STATE = {
    notificationListData: [],
    notificationListSuccess: false,
    notificationListLoading: false,
    notificationListMessage: null,
    // notificationCountData: [],
    // notificationCountSuccess: false,
    // notificationCountLoading: false,
    // notificationCountMessage: null,

};

const NotificationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST_LOADING:
            return {
                ...state,
                notificationListLoading: true,
                notificationListData: action.payload
            };

        case NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                notificationListLoading: false,
                notificationListSuccess: true,
                notificationListData: action.payload.data,
                notificationListMessage: action.payload.message,
            };

        case NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                notificationListLoading: false,
                notificationListSuccess: false,
                notificationListMessage: action.payload.message,
                notificationListData: action.payload,
            };
            // case NOTIFICATION_COUNT_LOADING:
            //     return {
            //         ...state,
            //         notificationCountLoading: true,
            //         notificationCountData: [],
            //     };
    
            // case NOTIFICATION_COUNT_SUCCESS:
            //     return {
            //         ...state,
            //         notificationCountLoading: false,
            //         notificationCountSuccess: true,
            //         notificationCountData: action.payload.data,
            //         notificationCountMessage: action.payload.message,
            //     };
    
            // case NOTIFICATION_COUNT_FAILURE:
            //     return {
            //         ...state,
            //         notificationCountLoading: false,
            //         notificationCountSuccess: false,
            //         notificationCountMessage: action.payload.message,
            //         notificationCountData: [],
            //     };
    
        default:
            return state;
    }
};

export default NotificationReducer;
