import styled from '@emotion/styled';
import { AppBar, Link, Grid } from '@mui/material';
import { Logo } from './logo';

/**
 * [2023-04-5]
 * changes by: Purva Sharma
 * Description:-Changed background color and removed border as per new design
 **/


const AuthNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.authDefault,
  boxShadow: '0 0 0px 0 !important',
  height:'45px',
  marginTop:'0',
  justifyContent:'center',
  position:'relative'
}));

export const AuthNavbar = (props) => {
  const { ...other } = props;

  return (
    <>
      <AuthNavbarRoot
        {...other}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              className='align-center'
            >
              <Link to="/" sx={{ lineHeight: '0' }}  >
                <Logo  />
              </Link>
              {/* <Tooltip title=" Upgrade Plan" arrow>
                <EButton
                  type="submit"
                >
                  Upgrade Plan
                </EButton>
              </Tooltip> */}
            </Grid>
      </AuthNavbarRoot>

    </>
  );
};

AuthNavbar.propTypes = {
};
