import React, { useEffect, useState } from "react";

import {
  Grid,
  Stack,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import base64 from "base-64";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import { ETypography, EFieldLabelSemiBold } from "../BaseComponents/ETypography";
import { ETextFieldLogin } from "../BaseComponents/ETextField";
import { ELoadingButton } from "../BaseComponents/EButtons";
import VisibilityIcon from "@iconify/icons-material-symbols/visibility-outline-rounded";
import VisibilityOffIcon from "@iconify/icons-material-symbols/visibility-off-outline-rounded";
import Cookies from "universal-cookie";
import { LoginSchema } from "../utils/validation";
import { LoginAction } from "../action/AuthAction";
import { EIconInputAdornment } from "../BaseComponents/EIcon";
import { ELinkStaticBlue } from "../BaseComponents/ELink";
import { ETextField } from "../BaseComponents/ETextField";
// import myProfile from '@iconify/icons-material-symbols/frame-person-rounded'
const cookies = new Cookies();

const LoginForm = ({location}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const [showPassword, setShowPassword] = useState(false);

  const cookieUsername = cookies.get("people")
    ? base64.decode(cookies.get("people").username)
    : "";
  const cookiePassowrd = cookies.get("people")
    ? base64.decode(cookies.get("people").password)
    : "";
  const cookieRemeber = cookies.get("people") ? true : false;
  const formik = useFormik({
    initialValues: {
      username: cookieUsername,
      password: cookiePassowrd,
      remember: cookieRemeber,
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      dispatch(LoginAction(data, navigate));
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    if (auth.authData?.firstLogin) {
      navigate("/reset-password/" + auth.authData.secretToken);
    }
  }, [auth.authData]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid className="text-align-left">
          <ETypography variant="h4" className="font-size-35px">
            Welcome
          </ETypography>
          <ETypography>
            {" "}
            Please enter your login information to access your Entera Payroll
            account{" "}
          </ETypography>
          <Grid className="pt-24px">
            <Stack spacing={2}>

              {/* USERNAME */}
              <Grid>
              <EFieldLabelSemiBold>Username</EFieldLabelSemiBold>
              <ETextField 
                fullWidth
                autoComplete="off"
                type="text"
                placeholder="Username"
                {...getFieldProps("username")}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                // InputLabelProps={{ shrink: true }}
              />
              </Grid>

              {/* PASSWORD */}
              <Grid>
              <EFieldLabelSemiBold>Password</EFieldLabelSemiBold>
              <ETextFieldLogin
                fullWidth
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                {...getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        {showPassword ? (
                          <EIconInputAdornment icon={VisibilityOffIcon} />
                        ) : (
                          <EIconInputAdornment icon={VisibilityIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className=" m0px "
              >
                <Grid className="font-size-12px display-flex align-center">
                  <Checkbox
                    name="remember"
                    checked={getFieldProps("remember").value}
                    size="small"
                    {...getFieldProps("remember")}
                    // onChange={HandleRemember}
                    className="signup-form-checkbox"
                  />        
                  Remember Me
                </Grid>
                <Link
                  to="/forget-password"
                  className="font-size-12px font-style-italic "
                >
                  {"Forgot Password?"}
                </Link>
              </Grid>

              <ELoadingButton
                fullWidth
                size="medium"
                type="submit"
                className="borderRadius-4px font-size-16px bold-700 mt-16px mb-16px"
              >
                Login
              </ELoadingButton>
            </Stack>


            {location.state?.fromEmailSuccess != true && <ETypography className="text text-align">
              Don’t have an account?{" "}
              <ELinkStaticBlue to="/sign-up" underline="none">
                {"Sign Up"}
              </ELinkStaticBlue>
            </ETypography>
            }
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
