import {
  LOGIN_RESET_SUCCESS,LOGIN_RESET_TOKEN,LOGIN_RESET_FAILURE, LOGIN_RESET_LOADING
} from '../constants';

const INITIAL_STATE = {
  authResetData:{},
  authResetLoading: false,
  authResetTokenStatus: false,
  authResetSuccess: false,
  authMessage: null,
};

const authResetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_RESET_LOADING:
      return {
        ...INITIAL_STATE,
        authResetLoading: true,
      };
    case LOGIN_RESET_TOKEN:
      return {
        authResetTokenStatus: action.payload,
        authResetLoading: false,
        authResetSuccess: false,
        authMessage: null,
      };
    case LOGIN_RESET_SUCCESS:
      return {
        authResetLoading: false,
        authResetTokenStatus: true,
        authResetSuccess: action.payload,
        authMessage: action.payload.message,
      };
    case LOGIN_RESET_FAILURE:
      return {
        authResetTokenStatus:false,
        authResetLoading: false,
        authResetSuccess: false,
        authMessage: action.payload.message,
      };
    default:
      return state;
  }
};

export default authResetReducer;
