import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Page from "../../BaseComponents/EPage";
import {
  EButton,
  EButtonOutlined,
  EButtonOutlinedIcon,
} from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from "formik";
import { EBoxPage, ItemBox } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { ECard } from "../../BaseComponents/ECard";
import { ETypographyCardHeading, ETypographyPageHeading,
} from "../../BaseComponents/ETypography";
import { EDivider, EDividerCard } from "../../BaseComponents/EDivider";
import { UpdatePassSchema } from "../../utils/validation";
import { ETextField } from "../../BaseComponents/ETextField";
import { UpdatePasswordAction } from "../../action/UpdateProfileAction";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";

function UserChangePassword(props) {
  const { auth } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const auth = useSelector((state) => state.authReducer);
  // const Theme = useSelector((state) => state.ThemeReducer);

  const formikChangePassword = useFormik({
    initialValues: {
      password: "",
      oldpassword: "",
      confirm_password: "",
      username: "",
    },
    validationSchema: UpdatePassSchema,
    onSubmit: (data) => {
      // SetData(data)
      if (data) {
        data = { ...data, id: auth.authData?.id };
        dispatch(UpdatePasswordAction(auth.authtoken, data, navigate));
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formikChangePassword;

  useEffect(() => {
    if (auth.authtoken) {
      setFieldValue("username", auth?.authData?.username);
    }
  }, [auth.authtoken]);

  return (
    <Page title="Change Password">
      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="align-center"
      >
        <Grid item>
          <EHeaderBreadcrumbs
            heading="Change Password"
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Profile", href: "" },
              {
                name: "Change Password",
              },
            ]}
          />
        </Grid>
        <EHidden width="mdDown">
          <Grid item>
            <EButtonOutlined
              className="button-left-margin"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden>
      </Grid>
      <Grid
        container
        display="row"
        className="pb-2rem margin-top-20px justify-content-space-between align-flex-end"
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Change Password
          </ETypographyPageHeading>
        </Grid>
        <Grid item>
          <EHidden width="mdUp">
            <Grid item>
              <EButtonOutlinedIcon
                variant="outlined"
                onClick={() => navigate(-1)}
                className="button-left-margin"
              >
                <EIcon icon={ForwardArrow} className="height-width-22px" />{" "}
              </EButtonOutlinedIcon>
            </Grid>
          </EHidden>
        </Grid>
        
            <EHidden width="mdDown">
            <Grid xs={12} lg={12} item>
            <EDivider className="margin-top-05" />
            </Grid>
            </EHidden>
          
       
      </Grid>
      <EBoxPage>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center-start"
        >
          <Grid item xs={12}>
            <ECard className="card_design_1">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ETypographyCardHeading variant="h5">
                  Credentials
                </ETypographyCardHeading>
              </Grid>

              <Grid xs={12} lg={12} item mb={2}>
                <EDividerCard className="mt-8px" />
              </Grid>
              <FormikProvider value={formikChangePassword}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container direction="row" spacing={2} my={2}>
                    {/* Username */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label="Username">
                        <ETextField
                          disabled
                          fullWidth
                          name={"username"}
                          placeholder={"Username"}
                          // value={usernameValue}
                          // autoComplete="Username"
                          // label="Username"
                          // type='Username'
                          {...getFieldProps("username")}
                          // error={Boolean(touched.oldpassword && errors.oldpassword)}
                          // helperText={touched.oldpassword && errors.oldpassword}
                          // InputLabelProps={{ shrink: true }}
                        />
                      </ELabelInputMainGrid>
                    </Grid>

                    {/* Old Password */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label="Old Password">
                        <ETextField
                          fullWidth
                          autoComplete="oldpassword"
                          placeholder={"Old Password"}
                          type="password"
                          {...getFieldProps("oldpassword")}
                          error={Boolean(
                            touched.oldpassword && errors.oldpassword
                          )}
                          helperText={touched.oldpassword && errors.oldpassword}
                          InputLabelProps={{ shrink: true }}
                        />
                      </ELabelInputMainGrid>
                    </Grid>

                    {/* New Password */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label="New Password">
                        <ETextField
                          fullWidth
                          type="password"
                          autoComplete="password"
                          placeholder={"New Password"}
                          {...getFieldProps("password")}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </ELabelInputMainGrid>
                    </Grid>

                    {/* Confirm Password  */}
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <ELabelInputMainGrid label="Confirm Password">
                        <ETextField
                          fullWidth
                          type="password"
                          autoComplete="confirm_password"
                          placeholder={"Confirm Password"}
                          {...getFieldProps("confirm_password")}
                          error={Boolean(
                            touched.confirm_password && errors.confirm_password
                          )}
                          helperText={
                            touched.confirm_password && errors.confirm_password
                          }
                        />
                      </ELabelInputMainGrid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <ItemBox>
                      <EButtonOutlined
                        variant="outlined"
                        // onClick={() => setOpenReplyBox(false)}
                      >
                        {" "}
                        Cancel{" "}
                      </EButtonOutlined>
                    </ItemBox>
                    <ItemBox>
                      <EButton variant="contained" type="submit">
                        {" "}
                        Submit{" "}
                      </EButton>
                    </ItemBox>
                  </Grid>
                </Form>
              </FormikProvider>
            </ECard>
          </Grid>
        </Grid>
      </EBoxPage>
    </Page>
  );
}
export default UserChangePassword;
