import { styled } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers"; //DatePicker
import { DesktopTimePicker } from "@mui/x-date-pickersV6"; //TimePicker


//this Date Picker solves the major issue or date picker icon visibility on smaller screen, previous 
export const EDatePickerBase = styled(DesktopDatePicker)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    color: `${theme.palette.text.primary} !important`
  }
}));
export const EDatePicker = ({ ...rest }) => {
  return <EDatePickerBase
    inputProps={{ readOnly: true }}
    {...rest}
  />
}


//this Date Picker solves the major issue or date picker icon visibility on smaller screen
export const EDesktopDatePicker = styled(DesktopDatePicker)(({ theme }) => ({

}));


export const ETimePicker = styled(DesktopTimePicker)(({ theme }) => ({
  borderColor: theme.palette.input.primary,
  borderRadius: '3px',

  '& .Mui-error, .MuiFormHelperText-contained': {
    marginLeft: '0px',
  },
  '& .MuiFormControl-root, .MuiTextField-root, .MuiOutlinedInput-root': {
    border: `0px solid ${theme.palette.input.primary} !important`,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '3px',
    '& input': {
      borderRadius: '3px'
    },
    '&:active svg': {
      'svg': {
        color: theme.palette.input.inputIcon,
        zIndex: 1
      },
    },
    '& fieldset': {
      border: '0.5px solid ',
      borderColor: theme.palette.input.primary,
      borderRadius: '4px !important',
    },
    '&.Mui-focused fieldset ': { // - Set the Input border when parent is focused 
      border: '0.5px solid rgba(217, 121, 4, 0.2)',
    },
    '&.Mui-focused svg ': {
      color: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.input.primary,
    },
  },
  '& .MuiInputAdornment-root': {
    borderRadius: '3px'
  },
  '&:hover': {
    border: `0px solid ${theme.palette.input.primary} !important`,
  },
}))