import {
    HOLIDAY_FAILURE, HOLIDAY_LOADING, HOLIDAY_SUCCESS, HOLIDAY_LIST_FAILURE, HOLIDAY_LIST_SUCCESS,HOLIDAY_LIST_LOADING
} from '../constants';


const INITIAL_STATE = {
    holidayListData: {},
    holidayListSuccess: false,
    holidayListLoading: false,
    holidayListMessage: null,
    holidayData: {},
    holidaySuccess: false,
    holidayLoading: false,
    holidayMessage: null,

};

const HolidayReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case HOLIDAY_LOADING:
            return {
                holidayLoading: true,
                holidayData: {}
            };

        case HOLIDAY_SUCCESS:
            return {
                holidayLoading: false,
                holidaySuccess: true,
                holidayData: action.payload.data,
                holidayMessage: action.payload.message,
            };

        case HOLIDAY_FAILURE:
            return {
                holidayLoading: false,
                holidaySuccess: false,
                holidayMessage: action.payload.message,
            };

        case HOLIDAY_LIST_LOADING:
            return {
                holidayListLoading: true,
                holidayListData: {}
            };

        case HOLIDAY_LIST_SUCCESS:
            return {
                holidayListLoading: false,
                holidayListSuccess: true,
                holidayListData: action.payload.data,
                holidayListMessage: action.payload.message,
            };

        case HOLIDAY_LIST_FAILURE:
            return {
                holidayListLoading: false,
                holidayListSuccess: false,
                holidayListMessage: action.payload.message,
                holidayListData: {}
            };

        default:
            return state;
    }
};

export default HolidayReducer;
