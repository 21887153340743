import { Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EBoxPage } from "../../BaseComponents/EBox";
import { EContainer } from "../../BaseComponents/EContainer";
import { ETab, ETabContext, ETabList, ETabPanel } from "../../BaseComponents/ETabs";
import SalaryDueMainPage from "../../PageComponents/Payment/SalaryDueMainPage";
import { EPageWithBreadCrumbs } from '../../BaseComponents'
import SalaryPayrollDetailByTalent from "../../PageComponents/Payment/SalaryPayrollDetailByTalent";


const Salary = (props) => {

  const { auth } = props;
  const paymentList = useSelector((state) => state.PayrollReducer);
  const [tabvalue, setTabValue] = useState("PENDING");
  const [showDetailPage, setShowDetailPage] = useState({ isShow: false, payrollId: null });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <EPageWithBreadCrumbs
      title="Salary Payment"
      pageHeading="Salary Payment"
      breadcrumbsTitle="Salary Payment"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Payment', href: '' },
        { name: 'Salary Payment' }
      ]}
    >

      <EBoxPage>
        <ETabContext value={tabvalue}>

            <ETabList onChange={handleChange} allowScrollButtonsMobile variant="scrollable" alignitems='flex-start'>
              <ETab label="View by Month" value="PENDING" />
              <ETab label="View by Employee" value="PAID" />
            </ETabList>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={11}>

              <ETabPanel value="PENDING" sx={{ padding: '0px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <SalaryDueMainPage
                      setTabValue={setTabValue}
                      tabvalue={tabvalue}
                      auth={auth}
                      paymentList={paymentList}
                    />
                    
                  </Grid>
                </Grid>
              </ETabPanel>

              <ETabPanel value="PAID" sx={{ padding: '0px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                  <SalaryPayrollDetailByTalent
                    setTabValue={setTabValue}
                    setShowDetailPage={setShowDetailPage}
                    auth={auth}
                    showDetailPage={showDetailPage}
                />
                  </Grid>
                </Grid>
              </ETabPanel>

            </Grid>
          </Grid>
        </ETabContext>
      </EBoxPage>
    </EPageWithBreadCrumbs>
  )
};

export default Salary;