import React, { useEffect, useState } from 'react'
import { Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline';
import AttachFile from '@iconify/icons-material-symbols/file-present-outline-rounded';

import { useCompanySettingProvider } from '../../../context/CompanySettingContext';
import { EButton } from '../../../BaseComponents/EButtons';
import PaymentRequestModal from '../../../PageComponents/Payment/PaymentRequestModal';
import EModal from '../../../BaseComponents/EModal';
import { ACCOUNTANT, ACTIVE_USER, DEFAULT_ROWS_PERPAGE, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_LIST_DOWNLOAD_URL, PAYMENT_TRANSACTION_CATEGORY, PAYMENT_TRANSACTION_STATUS, PAYMENT_TRANSACTION_STATUS_CHIP, PAYMENT_TRANSACTION_STATUS_OPTIONS, PENDING_STATUS, PERMISSIONS, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, SUPER_ADMIN } from '../../../constants';
import { EIcon } from '../../../BaseComponents/EIcon';
import { ETooltip } from '../../../BaseComponents/ETooltip';
import SearchNotFound from '../../../PageComponents/SearchNotFound';
import { EBoxPage } from '../../../BaseComponents/EBox';
import { getPaymentRequestList } from '../../../action/PaymentAction';
import { useCheckPermission } from '../../../hooks';
import { EmployeeListData } from '../../../action/EmployeeAction';
import { ETypography } from '../../../BaseComponents/ETypography';
import { numberWithCommas } from '../../../utils/formatNumber';
import EChip from '../../../BaseComponents/EChip';
import { ELinkMUI } from '../../../BaseComponents/ELink';
import { DownLoadFile } from '../../../action/AdminAction';
import { PaymentSearchFilter } from '../../../PageComponents/Payment/PaymentSearchFilter';
import { getParticulars } from '../../../utils/CommonFunctions';
import { EDataGrid } from '../../../BaseComponents';
import PaymentRequestDelete from '../../../PageComponents/Payment/PaymentRequestDelete';
import PaymentRequestView from '../../../PageComponents/Payment/PaymentRequestView';



export const MyPayment = () => {

  const dispatch = useDispatch();

  const [modalState, setModalState] = useState({ modalData: null, modalName: null });
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
  const [page, setPage] = useState(0);

  const [filter, setFilter] = useState({
    sort: true,
    employee: null,
    particular: null,
    status: null,
  })

  const auth = useSelector((state) => state.authReducer)
  const employee = useSelector((state) => state.EmployeeReducer);
  const company = useSelector(state => state.CompanyReducer);

  const payment = useSelector((state) => state.PaymentReducer);

  const { setting } = useCompanySettingProvider()
  const paymentSettingData = setting?.paymentSettingData?.companySetting;

  const enable_advance_salary = paymentSettingData?.enable_advance_salary;
  const enable_reimbursement = paymentSettingData?.enable_reimbursement;
  const allowVerifyPayment = useCheckPermission([PERMISSIONS.VerifyPayment])


  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    dispatch(getPaymentRequestList({
      payment_category: fnParams?.particular?.id,
      transaction_status: fnParams?.status?.length > 0 ? fnParams?.status?.map(i => i?.id)?.join(", ") : null,
      start_date: fnParams?.start,
      end_date: fnParams?.end,
      employee_id: fnParams?.employee?.id,
      sort: !fnParams.sort ? ' ASC ' : ' DESC',
      withoutRoleCheck: allowVerifyPayment,
      page_name: 'request_my_payment',
      page: fnPage,
      per_page: fnRowPerPage,
    }));
  }

  useEffect(() => {
    if (auth?.authtoken) {
      callApi({ ...filter }, page, rowsPerPage);
      dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
    }
  }, [auth?.authtoken])

  const getFilterData = (searchData) => {
    callApi(searchData, 0, rowsPerPage);
    setPage(0);
  }

  const DownloadReport = (value) => {
    let url = '';

    let file_name = 'Payment_Request_List';

    url += PAYMENT_LIST_DOWNLOAD_URL + '?page_name=request_my_payment&';

    if (value != null) { url += `download_format=${value}&`; }
    if (filter?.employee?.id != null) { url += `employee_id=${filter?.employee?.id}&`; }
    if (filter?.status?.length > 0) { url += `transaction_status=${filter?.status?.map(i => i?.id)?.join(", ")}&`; }
    if (filter?.particular?.id != null) { url += `payment_category=${filter?.particular?.id}&`; }
    if (filter?.start_date != null) { url += `start_date=${moment(filter?.start_date).format('YYYY-MM-DD')}&`; }
    if (filter?.end_date != null) { url += `end_date=${moment(filter?.end_date).format('YYYY-MM-DD')}&`; }
    if (filter?.sort != null) { url += `sort=${filter?.sort ? ' ASC ' : ' DESC'}`; }

    DownLoadFile(auth.authtoken, url, file_name);
  }


  const onSearch = (data) => {
    setFilter(data)
    callApi({ ...data }, page, rowsPerPage);
  }
  const particulars = getParticulars(true, true)

  const columns = [
    {
      field: 'transaction_date',
      headerName: 'Date',
      minWidth: 120,
      maxWidth: 120,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return moment(params?.row?.transaction_date).format('DD/MM/yyyy')

      }
    },
    {
      field: 'Particular',
      headerName: 'Particular',
      minWidth: 80,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {PAYMENT_TRANSACTION_CATEGORY[params?.row?.transaction_category] || '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'Amount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Request Amount',
      type: 'number',
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.pay_amount ? numberWithCommas(params?.row?.pay_amount) : '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'Attachment',
      headerName: 'Attachment',
      minWidth: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      sortable: false,
      editable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <ETypography>
            {params?.row?.reimbursement_attachment ? (
              <ELinkMUI
                variant="link"
                href={params?.row?.reimbursement_attachment}
                target={'_blank'}
              >
                <EIcon icon={AttachFile} style={{ height: '25px', width: '25px' }} />
              </ELinkMUI>
            ) : (
              ''
            )}
          </ETypography>
        );
      }
    },

    {
      field: 'Status',
      headerName: 'Status',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      minWidth: 120,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Grid className="display-flex align-items-center">
            {params?.row?.transaction_status ? (
              <EChip
                label={PAYMENT_TRANSACTION_STATUS[params?.row?.transaction_status]}
                width="100px"
                className={PAYMENT_TRANSACTION_STATUS_CHIP[params?.row?.transaction_status]}
              />
            ) : (
              '-'
            )}
          </Grid>
        );
      },
      editable: false
    },
    {
      field: 'Action',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      sortable: false,
      width: 80,
      type: 'actions',
      renderCell: (params) => {
        const transaction_status = params?.row?.transaction_status
        const employee_id = params?.row?.employee_id
        const created_by = params?.row?.created_by

        const ViewButton = () => {
          return (
            <ETooltip title={'View'} arrow>
              <IconButton onClick={() => setModalState({ modalData: params?.row, modalName: 'VIEW' })}>
                <EIcon icon={VisibilityIcon} />
              </IconButton>
            </ETooltip>

          )
        }
        const DeleteButton = ({ show = true }) => {
          return (
            <ETooltip title={'Delete'} arrow>
              <IconButton onClick={() => setModalState({ modalData: params?.row, modalName: 'DELETE' })}>
                <EIcon icon={DeleteIcon} color={'red'} />
              </IconButton>
            </ETooltip>
          )
        }
        /**
         * 3. When transaction_status is either "CANCELLED" or "COMPLETE" THEN
         *  => show eye icon to view the request detail
         */
        return (
          <>
            <Grid container>
              <Grid item xs={6}>
                <ViewButton />
              </Grid>
              <Grid item xs={6}>
                {
                  transaction_status === PENDING_STATUS && (auth?.authData?.id == employee_id || auth?.authData?.id == created_by) &&
                  <DeleteButton />
                }
              </Grid>
            </Grid>
          </>
        )
      }
    }
  ]
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        rowGap={2}
      >
        <Grid item xs={12} sm={12} md={12}>
          {
            (enable_advance_salary || enable_reimbursement) &&
            <EButton
              variant="contained"
              onClick={() => setModalState({ ...modalState, modalName: 'REQUEST' })}
            >
              +  Request
            </EButton>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PaymentSearchFilter
            initialFilter={filter}
            onSearch={onSearch}
            onSort={onSearch}
            DownloadReport={DownloadReport}
            particulars={particulars}
            status={PAYMENT_TRANSACTION_STATUS_OPTIONS}
            start={{
              placeholder: 'Start Date',
              views: ['year', 'month', 'day'],
              inputFormat: 'dd/MM/yyyy',
              order: 1,
              maxDate: new Date(),
            }}
            end={{
              placeholder: 'End Date',
              views: ['year', 'month', 'day'],
              inputFormat: "dd/MM/yyyy",
              order: 2,
              maxDate: new Date(),
            }}
            particularsOrder={3}
            statusOrder={4}
            talentOrder={5}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <EBoxPage className='p0'>
            {
              !payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length == 0
                ?
                <SearchNotFound />
                :
                <EDataGrid
                  columns={columns}
                  rows={payment?.PaySalaryListData?.rows || []}
                  loading={payment?.PaySalaryListLoading}
                />
            }
          </EBoxPage>
        </Grid>
      </Grid>

      {
        modalState.modalName &&
        <EModal
          open={modalState.modalName === 'REQUEST'}
          headervalue={`Payment Request`}
        >
          <PaymentRequestModal
            setModalState={setModalState}
            auth={auth}
            getPaymentFilterData={() => getFilterData(filter)}
            employeeList={employee}
            companyData={company}
            setting={setting}
            withOutOther={true}
          />
        </EModal>
      }
      {
        (modalState.modalName && modalState.modalData) &&
        <EModal open={modalState.modalName === 'DELETE'}
          headervalue={modalState.modalData?.transaction_category &&
            modalState.modalData?.transaction_category == PAYMENT_CATEGORY_ADVANCE
            ? 'Advance Request'
            : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT
              ? 'Reimbursement Request'
              : modalState.modalData?.transaction_category == PAYMENT_CATEGORY_OTHER
                ? 'Other Request'
                : '-'}
        >
          <PaymentRequestDelete
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => getFilterData(filter)}
            auth={auth}
          />
        </EModal>
      }
      {
        (modalState.modalName && modalState.modalData) &&
        <EModal open={modalState.modalName === 'VIEW'}
          headervalue={PAYMENT_TRANSACTION_CATEGORY[modalState.modalData?.transaction_category] + ' Request' || '-'}
        >
          <PaymentRequestView
            selectedData={modalState}
            setModalState={setModalState}
          />
        </EModal>
      }
    </>
  )
}
