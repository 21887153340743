import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlanDetails } from "../action/OnBoardingAction";
import { GST, PLAN_TIME_FREQUENCY_TYPE, COMPANY_STATE_ID } from "../constants";
import moment from "moment";
import { numberWithCommas } from "../utils/formatNumber";
import { useCompanySettingProvider } from "../context/CompanySettingContext";

export const useCalculatePlan = (isUpgradePlan) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authReducer)
  const { subscriptionPlanData: subscriptions } = useSelector((state) => state.SubscriptionPlanReducer)
  const settingsContext = useCompanySettingProvider()
  const accountSetting = settingsContext?.setting?.accountSettingData?.companySetting
  const companyStateId = accountSetting?.company_data?.permanentState?.id
  const [calculationObj, setCalculationObj] = useState({
    amountWithGST: 0, planCost: 0, total: 0, igst: 0, cgst: 0, sgst: 0,
  })

  const calculatePlan = (plan_type, noOfTalent) => {

    const selectedPlan = subscriptions?.find((plan) => (plan?.plan_time_frequency_type == PLAN_TIME_FREQUENCY_TYPE[plan_type]))
    let planCost = 0
    let igst = 0
    let cgst = 0
    let sgst = 0

    if (selectedPlan?.plan_time_frequency_type === PLAN_TIME_FREQUENCY_TYPE.YEAR) {
      const remainingMonth = isUpgradePlan ? 12 : moment(accountSetting?.activePlan?.plan_valid_to).diff(new Date(), 'months')
      planCost = Number(selectedPlan?.price) * Number(noOfTalent) * remainingMonth
    } else {
      planCost = Number(selectedPlan?.price) * Number(noOfTalent)
    }
    const amountWithGST = (planCost * 0.01) * GST
    const grandTotal = planCost + amountWithGST
    // Check if the company's state is different from the customer's state
    if (COMPANY_STATE_ID !== Number(companyStateId)) {
      igst = amountWithGST; // Apply IGST for inter-state transactions
    } else {
      // If it's an intra-state transaction, split the GST amount into CGST and SGST
      cgst = amountWithGST / 2;
      sgst = amountWithGST / 2;
    }
    setCalculationObj({
      amountWithGST: Number(amountWithGST || 0, true),
      planCost: Number(planCost || 0, true),
      total: Number(grandTotal || 0, true),
      igst: Number(igst || 0, true),
      cgst: Number(cgst || 0, true),
      sgst: Number(sgst || 0, true),
    })
  }

  useEffect(() => {
    if (auth?.authtoken) {
      dispatch(getPlanDetails(isUpgradePlan ? 2 : 0))
    }
  }, [auth?.authtoken]);

  return { subscriptions, calculatePlan, calculationObj }
}
