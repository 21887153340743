import { styled } from '@mui/material/styles';

export const AuthLockIconSolid = styled(() => {
  return (
    <svg width={152} height={143} viewBox="0 0 152 143" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M58.6911 108.336C56.5828 108.336 54.7773 107.585 53.2746 106.082C51.7719 104.579 51.0219 102.775 51.0244 100.669V62.3359C51.0244 60.2276 51.7758 58.4221 53.2784 56.9194C54.7811 55.4168 56.5853 54.6667 58.6911 54.6693H62.5244V47.0026C62.5244 41.6998 64.3938 37.1791 68.1326 33.4403C71.8714 29.7015 76.3909 27.8334 81.6911 27.8359C86.9939 27.8359 91.5146 29.7053 95.2534 33.4441C98.9922 37.1829 100.86 41.7024 100.858 47.0026V54.6693H104.691C106.799 54.6693 108.605 55.4206 110.108 56.9233C111.61 58.4259 112.36 60.2302 112.358 62.3359V100.669C112.358 102.778 111.606 104.583 110.104 106.086C108.601 107.588 106.797 108.338 104.691 108.336H58.6911ZM81.6911 89.1693C83.7994 89.1693 85.6049 88.4179 87.1076 86.9153C88.6103 85.4126 89.3603 83.6084 89.3578 81.5026C89.3578 79.3943 88.6064 77.5888 87.1038 76.0861C85.6011 74.5834 83.7969 73.8334 81.6911 73.8359C79.5828 73.8359 77.7773 74.5873 76.2746 76.0899C74.7719 77.5926 74.0219 79.3968 74.0244 81.5026C74.0244 83.6109 74.7758 85.4164 76.2784 86.9191C77.7811 88.4218 79.5853 89.1718 81.6911 89.1693ZM70.1911 54.6693H93.1911V47.0026C93.1911 43.8082 92.073 41.0929 89.8369 38.8568C87.6008 36.6207 84.8855 35.5026 81.6911 35.5026C78.4966 35.5026 75.7814 36.6207 73.5453 38.8568C71.3091 41.0929 70.1911 43.8082 70.1911 47.0026V54.6693Z" fill="#403F3F" />
      <mask id="path-2-inside-1_911_563857" fill="white">
        <path d="M20.6612 36.4908C29.5817 21.7059 43.6521 10.7398 60.168 5.70038C76.6838 0.660967 94.4796 1.90387 110.135 9.19018C125.79 16.4765 138.199 29.292 144.978 45.1734C151.756 61.0548 152.426 78.8813 146.857 95.2264C141.289 111.571 129.876 125.282 114.811 133.722C99.7469 142.162 82.0946 144.736 65.2473 140.949C48.3999 137.163 33.5466 127.283 23.5424 113.209C13.5381 99.1346 9.08885 81.8592 11.0499 64.7034L13.9492 65.0348C12.07 81.4754 16.3337 98.0306 25.9209 111.518C35.5081 125.005 49.7422 134.473 65.8872 138.102C82.0321 141.731 98.9486 139.264 113.385 131.176C127.821 123.088 138.759 109.949 144.095 94.2853C149.431 78.6217 148.79 61.5383 142.294 46.319C135.798 31.0997 123.905 18.8185 108.903 11.8359C93.9008 4.85336 76.847 3.66227 61.0197 8.4916C45.1923 13.3209 31.7085 23.8298 23.1599 37.9984L20.6612 36.4908Z" />
      </mask>
      <path d="M20.6612 36.4908C29.5817 21.7059 43.6521 10.7398 60.168 5.70038C76.6838 0.660967 94.4796 1.90387 110.135 9.19018C125.79 16.4765 138.199 29.292 144.978 45.1734C151.756 61.0548 152.426 78.8813 146.857 95.2264C141.289 111.571 129.876 125.282 114.811 133.722C99.7469 142.162 82.0946 144.736 65.2473 140.949C48.3999 137.163 33.5466 127.283 23.5424 113.209C13.5381 99.1346 9.08885 81.8592 11.0499 64.7034L13.9492 65.0348C12.07 81.4754 16.3337 98.0306 25.9209 111.518C35.5081 125.005 49.7422 134.473 65.8872 138.102C82.0321 141.731 98.9486 139.264 113.385 131.176C127.821 123.088 138.759 109.949 144.095 94.2853C149.431 78.6217 148.79 61.5383 142.294 46.319C135.798 31.0997 123.905 18.8185 108.903 11.8359C93.9008 4.85336 76.847 3.66227 61.0197 8.4916C45.1923 13.3209 31.7085 23.8298 23.1599 37.9984L20.6612 36.4908Z" stroke="#D97904" strokeWidth={6} mask="url(#path-2-inside-1_911_563857)" />
      <path d="M21.8652 67.05L15.2729 51.365L4.40467 64.4555L21.8652 67.05Z" fill="#D97904" stroke="#D97904" strokeWidth={3} />
    </svg>
  )
})``;
