
import moment from 'moment';
import * as Yup from 'yup';
import { ADHAAR_DOC_ID, DRIVING_LICENCE_DOC_ID, OFFICE_HOURS_TYPE, PAN_DOC_ID, PASSPORT_DOC_ID, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, RELEASE_ID, RESIGN_ID, SERVER_URL, SUPER_ADMIN, USER_LOGIN_STATUS } from '../constants';
import { ValidateCreditCardNumber, ReturnBoolean, ReturnBoolean2 } from './CommonFunctions';
import axios from 'axios';
import { axiosClient } from './AxiosClient';


const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*)?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
var nameRegExp = /^[a-zA-Z][a-zA-Z ]*$/;
// var usernameRegExp = /^[a-zA-Z][a-zA-Z0-9-_ ]*$/;
var subjectRegExp = /^[.a-zA-Z ]*$/;
export var remarkRegExp = /^(?! )[.a-zA-Z0-9 ()/,-]*$/;
export const alphaNumbericRegex = /^[a-zA-Z0-9][a-zA-Z0-9]*$/
// var meetingLocationRegExp = /^[,a-zA-Z0-9- ]*$/;
// const pwd = /(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])/
const pincodeRegExp = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
export const pannumber = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/ // /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/
export const aadharnumber = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
export const drivingLicense = /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/
export const Passport = /^(?!^0+$)[a-zA-Z0-9]{3,20}$/
const digit = /^\d+$/;
// const number = /^[0-9.]/;
const time = /^(0[1-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
const gst = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/
const NoSpecialChar = /^[a-zA-Z0-9_.-:,\s]+$/;
const passwordRegex = /^[a-zA-Z0-9_.\-:,!\@#\$\%\^\&\*\_=+\s]+$/
// var numberDigit = /^[a-zA-Z0-9]*$/;
const companyName = /^[.a-zA-Z0-9 ()',&-]*$/;
const companyNameWithDoublequotes = /^[a-zA-Z][.a-zA-Z0-9: _()",&-]*$/;
const Tnc = /^[a-zA-Z][.a-zA-Z0-9 _()'":= !,&-]*$/;
// const oneSpecialCharacter = /^[0-9A-Za-z]*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][0-9a-zA-Z]*$/;
const ipAddressRegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
var holidayRegExp = /^[a-zA-Z-/ ]*$/;
const customUsernameRegExp = /^[a-zA-Z0-9_@.]+$/;
// const customUsernameRegExp = /^[a-zA-Z][a-zA-Z0-9_@.]*$/  // Username can contain alphabets, numbers, @, . , underscore



export const AddNewDepartmentValidation = (departmentList) => Yup.object().shape({
  id: Yup.string().nullable(),
  department_name: Yup.string()
    .required('Department is required').matches(nameRegExp, "Please enter a valid name")
    .min(2, "Department must be atleast 2 characters")
    .max(50, 'Must be less then 50 characters')
    .test({
      name: "duplicate",
      params: {},
      message: "Department already exists",
      test: function (value) {
        if (departmentList !== undefined) {
          let availability = departmentList?.filter((item) => {
            return item?.department_name?.toLowerCase() == value?.toLowerCase() && item?.id != this.parent.id
          }).length == 0
          return availability
        }
      }
    })
  ,
});


export const AddNewDocumentValidation = Yup.object().shape({
  addDoc: Yup.boolean(),
  document_name: Yup.string()
    .required('Document is required').matches(nameRegExp, "Please enter a valid name")
    .min(2, "Document must be atleast 2 characters")
    .max(50, 'Must be less then 50 characters')
});




export const LoginSchema = Yup.object().shape({
  username: Yup.string().required('Please enter the Username').min(4).max(50, 'Username too long!'),
  password: Yup.string().required('Please enter the Password').min(8, "The password that you've entered is incorrect.")
});


export const ResetPassSchema = Yup.object().shape({
  password: Yup.string().required('New Password is required').min(8, "New Password must atleast 8 character").matches(passwordRegex, 'Special characters are not allowed'),
  confirm_password: Yup.string().required('Confirm Password is required').oneOf([Yup.ref("password"), null], "Confirm Password don't match with the New Password.")
});

export const UpdateCredentialsSchema = (token, id) => Yup.object().shape({
  username: Yup.string().label('Username')
    .when(
      'login_status',
      (login_status, schema) => {
        if (login_status == USER_LOGIN_STATUS.FIRST_LOGIN) {
          const _schema = schema.required("Custom Username is required")
            .matches(customUsernameRegExp, 'Your username can have (A-Z), (a-z), (0-9), (.),(@)')
            .min(8)
            .max(50)
            .test({
              name: "duplicate",
              params: {},
              message: "Username has already been taken",
              test: function (value) {
                if (value?.length >= 8 && customUsernameRegExp.test(value)) {
                  return axios(`${SERVER_URL}/user/username-check?username=${!value ? null : value}`, {
                    method: 'GET',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  }).then(res => {
                    const { availability } = res.data;
                    return availability;
                  })
                }
              }
            })
          return _schema
        }
        return schema
      }),
  login_status: Yup.number().nullable(false).required('Username is required').typeError('Username is required'),
  password: Yup.string().required('New Password is required').min(8, "New Password must atleast 8 character").matches(passwordRegex, 'Special characters are not allowed'),
  confirm_password: Yup.string().required('Confirm Password is required').oneOf([Yup.ref("password"), null], "Confirm Password don't match with the New Password.")
});

export const ForgetPassSchema = Yup.object().shape({
  username: Yup.string().required('Username is required').min(4).matches(customUsernameRegExp, 'Your username can have (A-Z), (a-z), (0-9), (.),(@)').max(50, 'Username too long!'),
});

export const AddNewDesignationValidation = (designationList) => Yup.object().shape({
  id: Yup.string().nullable(),
  designation_name: Yup.string()
    .required('Designation is required')
    .matches(nameRegExp, "Please enter a valid name"
    ).min(2, "Designation must be atleast 2 characters")
    .max(50, 'Must be less then 50 characters')
    .test({
      name: "duplicate",
      params: {},
      message: "Designation already exists",
      test: function (value) {
        if (designationList !== undefined) {
          let availability = designationList?.filter((item) => {
            return item?.designation_name?.toLowerCase() == value?.toLowerCase() && item?.id != this.parent.id
          }).length == 0
          return availability
        }
      }
    })
  ,
});

export const WorkingTimeValidation = Yup.object().shape({
  office_open_time: Yup.date().required("Start Time is required").nullable("Start Time is required").typeError('Invalid Time format').test({
    name: "compare",
    params: {},
    message: "The start time cannot be same as end time",
    test: function (value) {
      return value && this.parent.office_close_time && !(moment(value).isSame(this.parent.office_close_time))
    },
  }),
  office_close_time: Yup.date().required('End Time is required').nullable("End Time is required").typeError('Invalid Time format')
    .min(
      Yup.ref('office_open_time'),
      "End Time can't be before Start Time"
    )
    .test({
      name: "compare",
      params: {},
      message: "The end time cannot be same as start time",
      test: function (value) {
        return value && this.parent.office_open_time && !(moment(value).isSame(this.parent.office_open_time))
      },
    }),
  working_hours: Yup.string()
    .required("Working Hour is required").matches(time, 'Invalid Time format'),

  present_min_hour: Yup.string().required("Min hour for Present is required").matches(time, 'Invalid Time format')
    .test({
      name: "present_min_hour_test",
      params: {},
      message: "Minimum working hours cannot be more than total working hours",
      test: function (value) {
        if (value == '00:00') {
          return false;
        }
        return moment(value, 'HH:mm').isSameOrBefore(moment(this.parent.working_hours, 'HH:mm'));
      },
    }),

  min_halfday_hour: Yup.string().required("Min hour for Half Day is required")
    .test({
      name: "min_halfday_hour_test",
      params: {},
      message: "validation should be -  Minimum working hours cannot be more than total working hours",
      test: function (value) {
        if (value == '00:00') {
          return false;
        }
        return moment(value, 'HH:mm').isSameOrBefore(moment(this.parent.present_min_hour, 'HH:mm'));
      },
    }),

});

export const regularizationCountValidation = Yup.object().shape({
  attendance_regularization_count: Yup.number().required("Attendance regularization count is required").max(10, 'No. Of Regularization cannot be more than 10.').typeError('Attendance regularization count must be number'),
});
export const leaveCycleValidation = Yup.object().shape({
  enable_leave_cycle: Yup.boolean().nullable().required("Please select an option."),
  leave_tenure_category: Yup.object().nullable()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (ReturnBoolean(enable_leave_cycle) == true) {
          return schema.nullable(false).required("Leave Tenure Category is required").typeError('Leave Tenure Category is required')
        }
        return schema
      })
  ,

  duration_string: Yup.string()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (ReturnBoolean(enable_leave_cycle) == true) {
          return schema.required("Duration is required").typeError('Duration is required')
        }
        return schema
      }),

  leave_allowed_per_month: Yup.number().nullable(true)
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (ReturnBoolean(enable_leave_cycle) == true) {
          return schema.test({
            name: "compare",
            params: {},
            message: "Please Enter a sum of half day(0.5) and full day(1)",
            test: function (value) {
              if (value >= 0 && (value % 0.5 == 0 || value == 0)) {
                return (value % 0.5 == 0 || value == 0)
              }
              else {
                return false;
              }
            },
          })
        }
        return schema
      }),
  leave_allowed_per_tenure: Yup.number().nullable()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (enable_leave_cycle && ReturnBoolean(enable_leave_cycle) == true) {
          return schema.required("Total Paid Leave is required").typeError('Please enter only numbers')
            .test("type", "Cannot accept decimal values", function (value) {
              return value && value % 1 === 0; //Here, as per discussion with @Sunil sir & @Dharam sir on 15/6/2023, decimal enteries will be prohibited here
            })
        }
        return schema
      }),
  leave_from_month: Yup.date().nullable()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {

        if (enable_leave_cycle && ReturnBoolean(enable_leave_cycle) == true) {
          return schema.required('From month is required').typeError('Invalid Time format')
            .max(Yup.ref('leave_to_month'), "From Month can't be more than To month.")
        }
        return schema
      }),
  leave_to_month: Yup.date().nullable()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (enable_leave_cycle && ReturnBoolean(enable_leave_cycle) == true) {
          return schema.required('To month is required').typeError('Invalid Time format')
            .min(Yup.ref('leave_from_month'), "To Month can't be less than From Month.")
        }
        return schema
      }),

  enable_paid_leave_notice_period: Yup.boolean().nullable()
    .when(
      'enable_leave_cycle',
      (enable_leave_cycle, schema) => {
        if (enable_leave_cycle && ReturnBoolean(enable_leave_cycle) == true) {
          return schema.required('Please select an option.')
        }
        return schema
      }),


  // {/* Commenting this validation as per @dharam sir on 29th April,23 */}
  // enable_half_day_leave_request: Yup.boolean().nullable().required("Please select an option."),
  enable_leave_application_reason: Yup.boolean().nullable().required("Please select an option."),
  enable_multi_level_leave_approval: Yup.boolean().nullable().required("Please select an option."),
  enable_auto_leave_approval: Yup.boolean().nullable().required("Please select an option."),
  auto_leave_approval_timeframe: Yup.object().nullable()
    .when(
      'enable_auto_leave_approval',
      (enable_auto_leave_approval, schema) => {
        if (ReturnBoolean(enable_auto_leave_approval) == true) {
          return schema.required("Please select a timeframe.")
        }
        return schema
      }),
  // {/* Commenting this validation as per @dharam sir on 13th May 23 */}
  // enable_weekly_off:  Yup.boolean().nullable().required("Please select an option."),

});


export const AddSuperAdminValidation = (token) => Yup.object().shape({
  is_primary: Yup.boolean(),
  show_as_talent: Yup.boolean(),
  fname: Yup.string().required("First Name is required").matches(nameRegExp, "First Name can only contain alphabets"),
  lname: Yup.string().required("Last Name is required").matches(nameRegExp, "Last Name can only contain alphabets"),
  mobile: Yup.string().required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  // Removing the unique check for mobile number as per testing requirements
  // mobile: Yup.string()
  //   .required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid")
  //   .test({
  //     name: "duplicate",
  //     params: {},
  //     message: "Contact Number has already been taken",
  //     test: (value) =>
  //       fetch(`${SERVER_URL}/user/mobile-check?mobile=${!value ? null : value}`, {
  //           method: 'GET',
  //           headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Authorization': 'Bearer ' + token,
  //         }
  //       }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //       }),
  //   }),
  email: Yup.string()
    .required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  // Removing the unique check for email as per testing requirements
  // email: Yup.string()
  //   .required("Email ID is required").matches(emailRegExp, 'Email ID is not valid')
  //   .test({
  //     name: "duplicate",
  //     params: {},
  //     message: "Email ID has already been taken",
  //     test: (value) =>
  //       fetch(`${SERVER_URL}/user/email-check?email=${!value ? null : value}`, {
  //         method: 'GET',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token,
  //         }
  //       }).then(async res => {
  //         const { availability } = await res.json();
  //         return availability;
  //       }),
  //   }),
  /**
   * commenting username because we are no longer allowing user's to add or update the username
   */
  // username: Yup.string()
  //   .required("Custom Username is required").min(5).max(50, 'Username too long!')
  //   .test({
  //     name: "duplicate",
  //     params: {},
  //     message: "Username has already been taken",
  //     test: (value) =>
  //       fetch(`${SERVER_URL}/user/username-check?username=${!value ? null : value}`, {
  //         method: 'GET',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json',
  //           'Authorization': 'Bearer ' + token,
  //         }
  //       }).then(async res => {
  //         const { availability } = await res.json();
  //         return availability;
  //       })
  //   })
  //   .when(
  //     'username_type',
  //     (username_type, schema) => {
  //       if (username_type?.id == OTHER) {
  //         return (schema.matches(usernameRegExp, 'Your username can have (A-Z), (a-z), (0-9), (-),(_), space')
  //         ) 
  //       }
  //       return schema
  //     }),
  // username_type: Yup.object().nullable(false).required('Username is required').typeError('Username is required'),
  doj: Yup.date().nullable()
    .when("show_as_talent", {
      is: true,
      then: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('DOJ is required'),
    }),
  department: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Department is required').typeError('Department is required'),
    }),
  designation: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Designation is required').typeError('Designation is required'),
    }),
  payroll_basic: Yup.string()
    .when("show_as_talent", {
      is: true,
      then: Yup.string().required("Salary(CTC/Month) is required").matches(digit, "Please enter numbers only").max(10, "Salary(CTC/Month) must be max 10 digit"),
    }),
  work_mode: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Work Mode is required').typeError('Work Mode is required'),
    }),
});


export const AddNewHolidayValidation = Yup.object().shape({
  holiday_name: Yup.string().required('Occasion is required').max(50, "Occasion should not exceed 50 characters").matches(holidayRegExp, "Occasion can only contain alphabets"),
  from_date: Yup.date().nullable(false)
    .when('isMultiple', (isMultiple, schema) => {
      if (isMultiple && isMultiple == 'true') {
        return schema.required('From Date is required');
      }
      else {
        return schema.required('Date is required');
      }
    }),
  to_date: Yup.date().nullable(false)
    .when('isMultiple', (isMultiple, schema) => {
      if (isMultiple && isMultiple == 'true') {
        return schema.required('To Date is required').min(Yup.ref('from_date'), "From Date can't be before To Date");
      }
      else {
        return schema;
      }
    }),
});

export const changePrimarySuperAdminValidation = (token, id) => Yup.object().shape({
  talent_name: Yup.object().nullable().required("Employee Name is required"),
})

export const AddNewUserSettingValidation = (token, id) => Yup.object().shape({
  is_primary: Yup.boolean(),
  isEdit: Yup.boolean(),
  isShiftAllowed: Yup.boolean(),
  talent_name: Yup.object().nullable().required("Employee Name is required"),
  shift_object: Yup.mixed()
    .when("isShiftAllowed", {
      is: true,
      then: Yup.mixed().required("Shift Name is required")
    }),
  /**
   * commenting username because we are no longer allowing user's to add or update the username
   */
  // username_type: Yup.object().required("Username is required").typeError('Username is required'),
  // username: Yup.string()
  //   .required("Custom Username is required").min(5).max(50, 'Username too long!')
  //   .when("isEdit", {
  //     is: true,
  //     then: Yup.string()
  //       .required("Custom Username is required").min(5).test({
  //         name: "duplicate",
  //         params: {},
  //         message: "Username has already been taken",
  //         test: (value) =>
  //           fetch(`${SERVER_URL}/user/username-check?username=${!value ? null : value}&uid=${id}`, {
  //             method: 'GET',
  //             headers: {
  //               'Accept': 'application/json',
  //               'Content-Type': 'application/json',
  //               'Authorization': 'Bearer ' + token,
  //             }
  //           }).then(async res => {
  //             const { availability } = await res.json();
  //             return availability;
  //           }),
  //       })
  //   })
  //   .when(
  //     'username_type',
  //     (username_type, schema) => {
  //       if (username_type?.id == OTHER) {
  //         return (schema.matches(usernameRegExp, 'Your username can have (A-Z), (a-z), (0-9), (-),(_), space')
  //         ) 
  //       }
  //       return schema
  //     }),
  reporting_manager: Yup.object().nullable().typeError('Reporting Manager is required')
    .when('role', (role, schema) => {
      if (role?.role_name == SUPER_ADMIN) {
        return schema
      } else {
        return schema.required("Reporting Manager is required")
      }
    }),
  is_active: Yup.string().required("Status is required"),
  role: Yup.object().required("Role is required").typeError('Role is required'),

  doj: Yup.date().nullable()
    .when("show_as_talent", {
      is: true,
      then: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('DOJ is required'),
    }),
  department: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Department is required').typeError('Department is required'),
    }),
  designation: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Designation is required').typeError('Designation is required'),
    }),
  payroll_basic: Yup.string()
    .when("show_as_talent", {
      is: true,
      then: Yup.string().required("Salary is required").matches(digit, "Please enter numbers only").max(10, "Salary must be max 10 digit"),
    }),
  work_mode: Yup.object().nullable(true)
    .when("show_as_talent", {
      is: true,
      then: Yup.object().nullable(false).required('Work Mode is required').typeError('Work Mode is required'),
    }),
});

export const CompanyDetailValidation = Yup.object().shape({
  company_logo: Yup.mixed()
    .test("format", "We only support jpeg & jpg format", function (value) {
      return value == null || (value.type === "image/jpg" || value.type === "image/jpeg");
    })
    .test('FILE_SIZE', "File too big, file size must be between 50kb - 2MB.", function (value) {
      if (value) {
        return value && value.size <= 1048576
      }
      else {
        return true
      }
    }),
  gst: Yup.string().nullable().min(15, "Invalid GSTIN number").max(15, "Invalid GSTIN number").matches(gst, "Invalid GSTIN number"),
  pan: Yup.string().nullable().min(10, "Invalid Pan number").max(10, "Invalid Pan number").matches(pannumber, "Invalid Pan number"),
  email: Yup.string().required("Email Id is required").matches(emailRegExp, 'Email Id is not valid'),
  phone_no: Yup.string().required("Contact No. is required").matches(phoneRegExp, 'Contact No. is not valid').min(10, "Mobile No. is not valid").max(10, "Contact No. is not valid"),

});

export const CompanyProfileValidation = Yup.object().shape({
  company_logo: Yup.mixed()
    .test("format", "We only support jpeg & jpg format", function (value) {
      return value == null || (value.type === "image/jpg" || value.type === "image/jpeg");
    })
    .test('FILE_SIZE', "File too big, file size must be between 50kb - 2MB.", function (value) {
      if (value) {
        return value && value.size <= 1048576
      }
      else {
        return true
      }
    }),
  address: Yup.string().required('Billing Address is required'),
  company_type: Yup.object().nullable().required('Company type is required').typeError('Company type is required'),
  city: Yup.object().required('City is required').typeError('City is required'),
  state: Yup.object().required('State is required').typeError('State is required'),
  pin_code: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  // company_name: Yup.string()
  //   .required("Organization name is required")
  //   .min(3, "Organization name must be atleast 3 characters").matches(companyName, "Please enter a valid name"),
  // description: Yup.string().max(250, "You can enter upto 250 character."),
  gst: Yup.string().nullable().min(15, "Invalid GSTIN number").max(15, "Invalid GSTIN number").matches(gst, "Invalid GSTIN number"),
  pan: Yup.string().nullable().min(10, "Invalid Pan number").max(10, "Invalid Pan number").matches(pannumber, "Invalid Pan number"),
  email: Yup.string().required("Email Id is required").matches(emailRegExp, 'Email Id is not valid'),
  phone_no: Yup.string().required("Contact No. is required").matches(phoneRegExp, 'Contact No. is not valid').min(10, "Mobile No. is not valid").max(10, "Contact No. is not valid"),
  cr_address_same: Yup.boolean(),
  cr_pin_code: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  cr_city: Yup.object().required('City is required').typeError('City is required'),
  cr_state: Yup.object().required('State is required').typeError('State is required'),
  cr_address: Yup.string().required('Address is required'),
});
export const CompanyAdressValidation = Yup.object().shape({

  address: Yup.string().nullable().required('Billing Address is required'),
  city: Yup.object().required('City is required').typeError('City is required'),
  state: Yup.object().required('State is required').typeError('State is required'),
  pin_code: Yup.string().nullable().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),

});


export const NewTalentValidation = (token, TalentSetting) => Yup.object().shape({

  personal_detail_check: Yup.boolean(),
  documents: Yup.boolean(),
  bank_detail_check: Yup.boolean(),
  emergency_contact_detail_check: Yup.boolean(),
  user_check: Yup.boolean(),
  personal_photo_check: Yup.boolean(),

  work_mode_object: Yup.mixed().required("Work Mode is required"),
  isShiftAllowed: Yup.boolean(),
  shift_object: Yup.mixed()
    .when("isShiftAllowed", {
      is: true,
      then: Yup.mixed().required("Shift Name is required")
    }),
  fname: Yup.string().required('First Name is required').matches(nameRegExp, "First Name can only contain alphabets"),
  lname: Yup.string().required('Last Name is required').matches(nameRegExp, "Last Name can only contain alphabets"),
  email: Yup.string()
    .required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  // commented this unique validation as per testing issue by @Sunil on 1st June, 2023
  // .test({
  //   name: "duplicate",
  //   params: {},
  //   message: "Email ID has already been taken",
  //   test: (value) =>
  //     fetch(`${SERVER_URL}/user/email-check?email=${!value ? null : value}`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token,
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),
  doj: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('DOJ is required').typeError('Invalid date format'),
  department_object: Yup.mixed().required("Department is required"),
  designation_object: Yup.mixed().required("Designation is required"),
  payroll_basic: Yup.string().required("Salary is required").matches(digit, "Please enter only numbers").max(10, "Salary must be max 10 digit"),

  // UserLogin

  // username: Yup.string().nullable()
  //   .when("user_check", {
  //     is: true,
  //     then: Yup.string()
  //       .required("Username is required").min(5).max(50, 'Username too long!')
  //       .test({
  //         name: "duplicate",
  //         params: {},
  //         message: "Username has already been taken",
  //         test: (value) =>
  //           fetch(`${SERVER_URL}/user/username-check?username=${!value ? null : value}`, {
  //             method: 'GET',
  //             headers: {
  //               'Accept': 'application/json',
  //               'Content-Type': 'application/json',
  //               'Authorization': 'Bearer ' + token,
  //             }
  //           }).then(async res => {
  //             const { availability } = await res.json();
  //             return availability;
  //           }),
  //       }),
  //   }),
  mobile: Yup.string()
    .required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid')
    .min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  // commented this unique validation as per testing issue by @Sunil on 1st June, 2023
  // .test({ 
  //   name: "duplicate",
  //   params: {},
  //   message: "Contact Number has already been taken",
  //   test: (value) =>
  //     fetch(`${SERVER_URL}/user/mobile-check?mobile=${!value ? null : value}`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token,
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),

  reporting_manager: Yup.object().nullable()
    .when("user_check", {
      is: true,
      then: Yup.object().nullable().required("Reporting Manager is required"),
    }),
  role_object: Yup.object().nullable()
    .when("user_check", {
      is: true,
      then: Yup.object().nullable().required("Role is required"),
    }),

  //=====personal details================//

  personal_photo: Yup.mixed()
    .when('personal_photo_check',
      (personal_photo_check, schema) => personal_photo_check && TalentSetting?.field_profile_photo ?
        schema.required("Profile Photo is required")
          .test("type", "We only support jpeg and jpg format", function (value) {
            if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
            return true;
          }) : schema
    ),

  //=====personal details================//
  personal_gender_object: Yup.mixed()
    .when("personal_detail_check", {
      is: true,
      then: TalentSetting?.field_gender ? Yup.mixed().required("Gender is required") : Yup.mixed()
    }),
  personal_dob: Yup.date().nullable().typeError('Invalid date format')
    .when(
      'personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_dob ? schema.required("Date of Birth is required") : schema,
    ),
  personal_marital_status_obj: Yup.object().nullable()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_marital_status ? schema.required("Marital Status is required") : schema
    ),

  // field_marital_status
  personal_blood_group_obj: Yup.object().nullable()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_blood_group ? schema.required("Blood Group is required") : schema
    ),

  personal_address_one: Yup.string()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("Address is required") : schema
    ),

  landmark: Yup.string()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("Landmark is required") : schema
    ),

  personal_state: Yup.mixed()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("State is required") : schema
    ),

  personal_district: Yup.mixed()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("City is required") : schema
    ),

  personal_pin_code: Yup.string().min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid')
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ?
        schema.required("Pincode is required")
        : schema
    ),

  //================Documents========

  // aadhar_checked validation
  // aadhar_no: Yup.string()
  //   .when("documents_check", {
  //     is: true,
  //     then: Yup.string()
  //       .matches(aadharnumber, 'Invalid Aadhar Card').min(12, 'Invalid Aadhar Card').max(12, 'Invalid Aadhar Card')
  //       .when('requiredDocuments',(requiredDocuments, schema) => {
  //           if (requiredDocuments && requiredDocuments?.length>0) {
  //             return (requiredDocuments?.includes(ADHAAR_DOC_ID) ? schema.required("Aadhar Card is required"):schema)
  //           }
  //           else return schema
  //         })
  //   }),
  // aadhar_file_name: Yup.mixed().when("documents_check", {
  //   is: true,
  //   then: Yup.mixed()
  //     .test("type", "We only support jpeg and jpg format", function (value) {
  //       if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
  //       return true;
  //     })
  //     .test("fileSize", "File Size is too large", (value) => {
  //       const fileSize = value?.size / 1024 / 1024; //in mb
  //       if (fileSize > 2) {
  //         return false;
  //       }
  //       return true;
  //     })
  //     .when('requiredDocuments',(requiredDocuments, schema) => {
  //       if (requiredDocuments && requiredDocuments?.length>0) {
  //         return (requiredDocuments?.includes(ADHAAR_DOC_ID) ? schema.required("Please Choose a file"):schema)
  //       }
  //       else return schema
  //     })
  // }),

  // PAN CARD validation
  // pan_no: Yup.string()
  //   .when("documents_check", {
  //     is: true,
  //     then: Yup.string()
  //       .min(10, "Invalid Pan Card ").max(10, "Invalid Pan Card ").matches(pannumber, 'Invalid Pan Card ')
  //       .when('requiredDocuments',(requiredDocuments, schema) => {
  //         if (requiredDocuments && requiredDocuments?.length>0) {
  //           return (requiredDocuments?.includes(PAN_DOC_ID) ? schema.required("PAN Card is required"):schema)
  //         }
  //         else return schema
  //       })
  //   }),

  // pan_file_name: Yup.mixed().when("documents_check", {
  //   is: true,
  //   then: Yup.mixed()
  //     .test("type", "We only support jpeg and jpg format", function (value) {
  //       if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
  //       return true;
  //     })
  //     .test("fileSize", "File Size is too large", (value) => {
  //       const fileSize = value?.size / 1024 / 1024; //in mb
  //       if (fileSize > 2) {
  //         return false;
  //       }
  //       return true;
  //     })
  //     .when('requiredDocuments',(requiredDocuments, schema) => {
  //       if (requiredDocuments && requiredDocuments?.length>0) {
  //         return (requiredDocuments?.includes(PAN_DOC_ID) ? schema.required("Please Choose a file"):schema)
  //       }
  //       else return schema
  //     }),
  // }),

  // driving licsense validation
  // driving_lic_no: Yup.string()
  //   .when("documents_check", {
  //     is: true,
  //     then: Yup.string().min(16, "Invalid Driving License").max(16, "Invalid Driving License")
  //       .matches(drivingLicense, 'Invalid Driving License')
  //       .when('requiredDocuments',(requiredDocuments, schema) => {
  //         if (requiredDocuments && requiredDocuments?.length>0) {
  //           return (requiredDocuments?.includes(DRIVING_LICENCE_DOC_ID) ? schema.required("Driving License is required "):schema)
  //         }
  //         else return schema
  //       })
  //   }),

  // driving_lic_file: Yup.mixed().when("documents_check", {
  //   is: true,
  //   then: Yup.mixed()
  //     .test("type", "We only support jpeg and jpg format", function (value) {
  //       if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
  //       return true;
  //     })
  //     .test("fileSize", "File Size is too large", (value) => {
  //       const fileSize = value?.size / 1024 / 1024; //in mb
  //       if (fileSize > 2) {
  //         return false;
  //       }
  //       return true;
  //     })
  //     .when('requiredDocuments',(requiredDocuments, schema) => {
  //       if (requiredDocuments && requiredDocuments?.length>0) {
  //         return (requiredDocuments?.includes(DRIVING_LICENCE_DOC_ID) ? schema.required("Please Choose a file"):schema)
  //       }
  //       else return schema
  //     }),
  // }),

  // passport validation
  // passport_no: Yup.string()
  //   .when("documents_check", {
  //     is: true,
  //     then: Yup.string()
  //       .min(8, "Invalid Passport").max(8, "Invalid Passport").matches(Passport, 'Invalid Passport')
  //       .when('requiredDocuments',(requiredDocuments, schema) => {
  //         if (requiredDocuments && requiredDocuments?.length>0) {
  //           return (requiredDocuments?.includes(PASSPORT_DOC_ID) ? schema.required("Passport is required "):schema)
  //         }
  //         else return schema
  //       }),
  //   }),

  // passport_file: Yup.mixed().when("documents_check", {
  //   is: true,
  //   then: Yup.mixed()
  //     .test("type", "We only support jpeg and jpg format", function (value) {
  //       if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
  //       return true;
  //     })
  //     .test("fileSize", "File Size is too large", (value) => {
  //       const fileSize = value?.size / 1024 / 1024; //in mb
  //       if (fileSize > 2) {
  //         return false;
  //       }
  //       return true;
  //     })
  //     .when('requiredDocuments',(requiredDocuments, schema) => {
  //       if (requiredDocuments && requiredDocuments?.length>0) {
  //         return (requiredDocuments?.includes(PASSPORT_DOC_ID) ? schema.required("Please Choose a file"):schema)
  //       }
  //       else return schema
  //     }),
  // }),

  //bank_detail_check
  ifsc_code: Yup.string()
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_ifsc_code ? schema.required("IFSC Code is required") : schema,
    ),

  bank_name: Yup.string().matches(nameRegExp, "Please enter a valid bank name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_bank_name ? schema.required("Please enter valid bank name") : schema,
    ),

  branch_name: Yup.string().matches(nameRegExp, "Please enter a valid branch name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_branch_name ? schema.required("Branch Name is required") : schema,
    ),
  account_no: Yup.string().matches(digit, "Please enter a valid Account Number").min(9, "Please enter a valid Account Number ").max(18, "Please enter a valid Account Number")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_account_number ?
        schema.required("Account Number is required") : schema,
    ),
  bank_account_holder_name: Yup.string().matches(nameRegExp, "Please enter a valid Cardholder's Name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_account_holder_name ?
        schema.required("Cardholder's Name is required") : schema,
    ),

  // EMERGENCY CONTACT

  emr_contact_mobile: Yup.string().matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid")
    .when(
      'emergency_contact_detail_check',
      (emergency_contact_detail_check, schema) => emergency_contact_detail_check && TalentSetting?.field_emer_contact_no ?
        schema.required("Contact Number is required") : schema,
    ),

  emr_person_name: Yup.string().matches(nameRegExp, "Please enter valid person name")
    .when(
      'emergency_contact_detail_check',
      (emergency_contact_detail_check, schema) => emergency_contact_detail_check && TalentSetting?.field_emer_name ?
        schema.required("Person Name is required") : schema,
    ),

  emr_contact_relation: Yup.string().matches(nameRegExp, "Please enter valid relation")
    .when(
      'emergency_contact_detail_check',
      (emergency_contact_detail_check, schema) => emergency_contact_detail_check && TalentSetting?.field_emer_relation ?
        schema.required("Relation is required") : schema,
    ),

});

//Add New Contract
export const ContractFromValidation = Yup.object().shape({
  freelancer: Yup.object().nullable().required('Freelancer Name is required').typeError('Freelancer Name is required'),
  incharge: Yup.object().nullable().required('Incharge is required').typeError('Incharge is required'),
  contract_title: Yup.string()
    .required("Contract Name is required")
    .min(3, "Contract Name must be atleast 3 characters").matches(companyNameWithDoublequotes, "Please enter a valid name").max(50, "You can enter 50 characters"),
  contract_amount: Yup.string().nullable().required("Amount is required").matches(/^\d+(\.\d{1,2})?$/, "Invalid Amount format"),
  contract_start_date: Yup.date().required("Start Date is required").nullable().typeError('Invalid Date format'),
  contract_end_date: Yup.date().required('End Date is required').nullable().typeError('Invalid Date format').min(
    Yup.ref('contract_start_date'),
    "End Date can't be before Start Date"
  ),
  gst: Yup.object().nullable().required('GST is required').typeError('GST is required'),
  // is_igst: Yup.object().nullable()
  //   .when('gst', (id, schema) => {
  //     if (id == null || id.id != 0) {
  //       return schema.required('GST Type is required').typeError('GST Type is required')
  //     }
  //     return schema;
  //   }),
  isAdvance: Yup.string().nullable().required("Amount is required"),
  // 
  terms_cond_text: Yup.string().nullable().matches(companyName, 'Additional Terms & Conditions is not valid'),
  no_of_tnc_selected: Yup.number().nullable()
    .when('is_terms_conditions_list', (id, schema) => {
      if (id) {
        return schema.required('Please select atleast one term and condition').min(1, 'Please select atleast one term and condition').typeError('Please select atleast one term and condition')
      }
      return schema;
    }),
});

export const UpdateContractDetailsValidation = Yup.object().shape({
  contract_start_date: Yup.date().nullable().required("Start Date is required").typeError('Invalid Date format'),
  contract_end_date: Yup.date().required('End Date is required').min(
    Yup.ref('contract_start_date'),
    "End Date can't be equal or before Start Date"
  ).when('contract_start_date',
    (contract_start_date, schema) => {
      if (contract_start_date) {
        const dayAfter = new Date(contract_start_date.getTime() + 86400000);

        return schema.min(dayAfter, 'End date has to be after than start date');
      }

      return schema;

    }),
  contract_title: Yup.string().required("Contract Name is required").matches(companyNameWithDoublequotes, "Please enter a valid name").min(3, "Contract Name must be atleast 3 characters").max(50, "You can enter only 50 characters"),
});

export const UpdateContractPaymentDetailsValidation = Yup.object().shape({

  contract_amount: Yup.string().nullable().required("Amount is required").matches(/^\d+(\.\d{1,2})?$/, "Invalid Amount format"),
  gst: Yup.object().nullable().required('GST is required').typeError('GST is required'),
  isAdvance: Yup.string().nullable().required("Amount is required"),
});

export const UpdateFreelancerValidation = Yup.object().shape({
  freelancer: Yup.object().required('Freelancer Name is required').typeError('Freelancer Name is required'),
});

export const UpdateInChargeValidation = Yup.object().shape({
  incharge: Yup.object().required('Incharge Name is required').typeError('Incharge Name is required'),
});

export const AddDeliverableValidation = Yup.object().shape({
  work_detail: Yup.string().required('Description is required').typeError('Description is required').min(3, "Description must be atleast 3 character"),
  work_title: Yup.string().required('Title is required').min(3, "Title must be atleast 3 character").typeError('Title is required').matches(nameRegExp, "Title can only contain alphabets"),
});

export const NewFreelancerValidation = Yup.object().shape({
  freelancer_detail_check: Yup.boolean(),
  aadhar_checked: Yup.boolean(),
  pan_checked: Yup.boolean(),
  bank_detail_check: Yup.boolean(),
  document_checked: Yup.boolean(),
  freelancer_photo_check: Yup.boolean(),

  freelancer_fname: Yup.string().required('First Name is required').matches(nameRegExp, "First Name can only contain alphabets"),
  freelancer_lname: Yup.string().required('Last Name is required').matches(nameRegExp, "Last Name can only contain alphabets"),
  freelancer_email: Yup.string().required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  freelancer_contact_no: Yup.string().required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  state: Yup.mixed().required("State is required"),
  city: Yup.mixed().required("City is required"),
  pincode: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  address_one: Yup.string().required("Address is required"),

  //=====profile photo===============//
  freelancer_photo: Yup.mixed().when("freelancer_photo_check", {
    is: true,
    then: Yup.mixed()
      .test('required', "Please upload a Profile Photo", (value) => {
        return value != null
      })
      .test("type", "We only support jpeg and jpg format", function (value) {
        return value && (value.type === "image/jpg" || value.type === "image/jpeg");
      })
      .test('FILE_SIZE', "Uploaded file is too big.", function (value) {
        if (value) {
          return value && value.size <= 1048576
        }
        else {
          return true
        }
      }),
  }),

  //=====personal details================//
  freelancer_gender: Yup.mixed()
    .when("freelancer_detail_check", {
      is: true,
      then: Yup.mixed().required("Gender is required")
    }),

  freelancer_dob: Yup.date().nullable(true)
    .when("freelancer_detail_check", {
      is: true,
      then: Yup.date().nullable().required("Date of Birth is required").typeError('Invalid date format')
    }),
  freelancer_marital_status_obj: Yup.object().nullable()
    .when("freelancer_detail_check", {
      is: true,
      then: Yup.object().nullable().required("Marital Status is required").typeError('Invalid date format')
    }),
  freelancer_blood_group_obj: Yup.object().nullable()
    .when("freelancer_detail_check", {
      is: true,
      then: Yup.object().nullable().required("Blood Group is required").typeError('Invalid date format')
    }),

  // aadhar_checked validation
  aadhar_no: Yup.string()
    .when("document_checked", {
      is: true,
      then: Yup.string()
        .matches(aadharnumber, 'Invalid Aadhar Card').min(12, 'Invalid Aadhar Card').max(12, 'Invalid Aadhar Card')
        .when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(ADHAAR_DOC_ID) ? schema.required("Aadhar Card is required") : schema)
          }
          else return schema
        })
    }),
  aadhar_file_name: Yup.mixed().when("document_checked", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support jpeg and jpg format", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when('requiredDocuments', (requiredDocuments, schema) => {
        if (requiredDocuments && requiredDocuments?.length > 0) {
          return (requiredDocuments?.includes(ADHAAR_DOC_ID) ? schema.required("Please Choose a file") : schema)
        }
        else return schema
      })
  }),
  // PAN CARD validation
  pan_no: Yup.string()
    .when("document_checked", {
      is: true,
      then: Yup.string()
        .min(10, "Invalid Pan Card ").max(10, "Invalid Pan Card ").matches(pannumber, 'Invalid Pan Card ')
        .when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(PAN_DOC_ID) ? schema.required("PAN Card is required") : schema)
          }
          else return schema
        })
    }),

  pan_file_name: Yup.mixed().when("document_checked", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support jpeg and jpg format", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when('requiredDocuments', (requiredDocuments, schema) => {
        if (requiredDocuments && requiredDocuments?.length > 0) {
          return (requiredDocuments?.includes(PAN_DOC_ID) ? schema.required("Please Choose a file") : schema)
        }
        else return schema
      }),
  }),

  // driving licsense validation
  driving_lic_no: Yup.string()
    .when("document_checked", {
      is: true,
      then: Yup.string().min(16, "Invalid Driving License").max(16, "Invalid Driving License")
        .matches(drivingLicense, 'Invalid Driving License')
        .when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(DRIVING_LICENCE_DOC_ID) ? schema.required("Driving License is required ") : schema)
          }
          else return schema
        })
    }),

  driving_lic_file: Yup.mixed().when("document_checked", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support jpeg and jpg format", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when('requiredDocuments', (requiredDocuments, schema) => {
        if (requiredDocuments && requiredDocuments?.length > 0) {
          return (requiredDocuments?.includes(DRIVING_LICENCE_DOC_ID) ? schema.required("Please Choose a file") : schema)
        }
        else return schema
      }),
  }),

  // passport validation
  passport_no: Yup.string()
    .when("document_checked", {
      is: true,
      then: Yup.string()
        .min(8, "Invalid Passport").max(8, "Invalid Passport").matches(Passport, 'Invalid Passport')
        .when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(PASSPORT_DOC_ID) ? schema.required("Driving Licence is required ") : schema)
          }
          else return schema
        }),
    }),

  passport_file: Yup.mixed().when("document_checked", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support jpeg and jpg format", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when('requiredDocuments', (requiredDocuments, schema) => {
        if (requiredDocuments && requiredDocuments?.length > 0) {
          return (requiredDocuments?.includes(PASSPORT_DOC_ID) ? schema.required("Please Choose a file") : schema)
        }
        else return schema
      }),
  }),

  //bank_detail_check
  ifsc_code: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("IFSC Code is required")
    }),
  bank_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Bank Name is required").matches(nameRegExp, "Please enter valid bank name")
    }),
  branch_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Branch Name is required")
    }),
  account_no: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Account No. required").matches(digit, "Please enter valid account no.")
    }),
  account_holder_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Cardholder's Name is required").matches(nameRegExp, "Please enter valid Cardholder's Name")
    }),


});
export const RegularizeRemark = Yup.object().shape({
  regularize_remark: Yup.string().required('Remark is required').min(4, "Minimum 4 characters required").max(300, "Remark max. 300 character")
    .matches(NoSpecialChar, "Special characters are not allowed"),

  checkin_time: Yup.date().nullable().typeError('Invalid Time format')
    .when("checkout_time", (checkout_time) => {
      if (checkout_time)
        return Yup.date().nullable().required("Check In time is required").typeError('Invalid Time format')
    }),

  checkout_time: Yup.date().nullable().typeError('Invalid Time format')
    .min(
      Yup.ref('checkin_time'),
      "Check Out Time can't be before Check In Time"
    ),
});


export const EditBacicDetailsValidation = (token, id) => Yup.object().shape({
  fname: Yup.string().matches(nameRegExp, "First Name can only contain alphabets"),//.required('Last Name is required')
  // lname: Yup.string().matches(nameRegExp, "Last Name can only contain alphabets"),//.required('First Name is required')
  email: Yup.string()
    .required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  // commented this unique validation as per testing issue by @Sunil on 1st June, 2023
  // .test({
  //   name: "duplicate",
  //   params: {},
  //   message: "Email ID has already been taken",
  //   test: (value) =>
  //     fetch(`${SERVER_URL}/user/email-check?email=${!value ? null : value}&uid=${id}`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token,
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),
  mobile: Yup.string()
    .required("Mobile No. is required").matches(phoneRegExp, 'Mobile No. is not valid')
    .min(10, "Mobile No. is not valid").max(10, "Mobile No. is not valid"),
  // commented this unique validation as per testing issue by @Sunil on 1st June, 2023
  // .test({
  //   name: "duplicate",
  //   params: {},
  //   message: "Mobile no has already been taken",
  //   test: (value) =>
  //     fetch(`${SERVER_URL}/user/mobile-check?mobile=${!value ? null : value}&uid=${id}`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + token,
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),
  personal_photo: Yup.mixed()
    .test("type", "We only support jpeg and jpg format", function (value) {
      if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
      return true;
    })
    .when('personal_photo_check',//Here personal_photo_checkcontains the value of TalentSetting?.field_profile_photo
      (personal_photo_check, schema) => personal_photo_check ? schema.required("Profile Photo is required") : schema),
});

/**
 * [2022-12-28]
 * changes by: Purva Sharma
 * Description:- Changed Error messages
 **/
export const UpdateFreelancerBasicDetailsValidation = Yup.object().shape({
  freelancer_contact_no: Yup.string().required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  freelancer_email: Yup.string().required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  freelancer_photo: Yup.mixed()
    .test("type", "We only support jpeg and jpg format", function (value) {
      if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg"); }
      return true;
    })
    .test('FILE_SIZE', "Uploaded file is too big.", function (value) {
      if (value) {
        return value && value.size <= 1048576
      }
      else {
        return true
      }
    }),
});

export const EditPersonalDetailValidation = (TalentSetting) => Yup.object().shape({
  //edit personal details
  personal_detail_check: Yup.boolean(),
  personal_gender: Yup.mixed()
    .when("personal_detail_check", {
      is: true,
      then: TalentSetting?.field_gender ? Yup.mixed().required("Gender is required") : Yup.mixed()
    }),
  personal_dob: Yup.date().nullable().typeError('Invalid date format')
    .when(
      'personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_dob ? schema.required("Date of Birth is required") : schema,
    ),

  personal_address_one: Yup.string()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("Address is required") : schema
    ),
  personal_state: Yup.mixed()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("State is required") : schema
    ),
  personal_district: Yup.mixed()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("City is required") : schema
    ),
  personal_pin_code: Yup.string().min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid')
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ?
        schema.required("Pincode is required")
        : schema
    ),

  landmark: Yup.string()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_address ? schema.required("Landmark is required") : schema
    ),
  personal_marital_status_obj: Yup.object().nullable()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_marital_status ? schema.required("Marital Status is required") : schema
    ),
  personal_blood_group_obj: Yup.object().nullable()
    .when('personal_detail_check',
      (personal_detail_check, schema) => personal_detail_check && TalentSetting?.field_blood_group ? schema.required("Blood Group is required") : schema
    ),
});

export const EditFreelancerPersonalDetailValidation = Yup.object().shape({
  personal_detail_check: Yup.boolean(),
  personal_gender: Yup.mixed()
    .when("personal_detail_check", {
      is: true,
      then: Yup.mixed().required("Gender is required").nullable()
    }),
  personal_dob: Yup.date()
    .when("personal_detail_check", {
      is: true,
      then: Yup.date().nullable().required("Date of Birth is required").typeError('Invalid date format')
    }),
  address_one: Yup.string().required("Address is required"),
  freelancerState: Yup.object().nullable().required("State is required").typeError('State is required'),
  freelancerCity: Yup.object().nullable().required("City is required").typeError('City is required'),
  pincode: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers")
    .max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  marital_status_obj: Yup.object().nullable().required("Marital Status is required").typeError('Invalid date format'),
  blood_group_obj: Yup.object().nullable().required("Blood Group is required").typeError('Invalid date format')
});


export const EditDocumentValidation = Yup.object().shape({
  // aadhar_checked validation
  aadhar_no: Yup.string().matches(aadharnumber, 'Invalid Aadhar Card').min(12, 'Invalid Aadhar Card').max(12, 'Invalid Aadhar Card')
    .when('document_id', (document_id, schema) => {
      if (document_id && document_id == ADHAAR_DOC_ID) {
        return schema.when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(document_id) ? schema.required("Aadhar Card is required") : schema)
          }
          else return schema
        })
      }
      else return schema
    }),
  aadhar_file_name: Yup.mixed()
    .test("type", "We only support pdf or jpeg/jpg", function (value) {
      if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
      return true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const fileSize = value?.size / 1024 / 1024; //in mb
      if (fileSize > 2) {
        return false;
      }
      return true;
    })
    .when(['document_id', 'aadhar_no'], (document_id, aadhar_no, schema) => {
      if ((document_id && document_id == ADHAAR_DOC_ID) || (aadhar_no && aadhar_no != 'undefined')) {
        return schema.when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(document_id) || (aadhar_no && aadhar_no != 'undefined') ? schema.required("Please Choose a file") : schema)
          }
          else return schema
        })
      }
      else return schema
    }),

  // pan_checked validation
  pan_no: Yup.string().min(10, "Invalid Pan Card ").max(10, "Invalid Pan Card ").matches(pannumber, 'Invalid Pan Card ')
    .when('document_id', (document_id, schema) => {
      if (document_id && document_id == PAN_DOC_ID) {
        return schema.when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(document_id) ? schema.required("Pan Card is required") : schema)
          }
          else return schema
        })
      }
      else return schema
    }),

  pan_file_name: Yup.mixed()
    .test("type", "We only support pdf or jpeg/jpg", function (value) {
      if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
      return true;
    })
    .test("fileSize", "File Size is too large", (value) => {
      const fileSize = value?.size / 1024 / 1024; //in mb
      if (fileSize > 2) {
        return false;
      }
      return true;
    })
    .when(['document_id', 'pan_no'], (document_id, pan_no, schema) => {
      if ((document_id && document_id == PAN_DOC_ID) || (pan_no && pan_no != 'undefined')) {
        return schema.when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(document_id) || (pan_no && pan_no != 'undefined') ? schema.required("Please Choose a file") : schema)
          }
          else return schema
        })
      }
      else return schema
    }),

  // driving license validation
  driving_lic_no: Yup.string().min(16, "Invalid Driving License").max(16, "Invalid Driving License").matches(drivingLicense, 'Invalid Driving License')
    .when('document_id', (document_id, schema) => {
      if (document_id && document_id == DRIVING_LICENCE_DOC_ID) {
        return schema.when('requiredDocuments', (requiredDocuments, schema) => {
          if (requiredDocuments && requiredDocuments?.length > 0) {
            return (requiredDocuments?.includes(document_id) ? schema.required("Driving License is required") : schema)
          }
          else return schema
        })
      }
      else return schema
    }),

  driving_lic_file: Yup.mixed().when("documents_check", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support pdf or jpeg/jpg", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when(['document_id', 'driving_lic_no'], (document_id, driving_lic_no, schema) => {
        if ((document_id && document_id == DRIVING_LICENCE_DOC_ID) || (driving_lic_no && driving_lic_no != 'undefined')) {
          return schema
            .when('requiredDocuments', (requiredDocuments, schema) => {
              if (requiredDocuments && requiredDocuments?.length > 0) {
                return (requiredDocuments?.includes(document_id) || (driving_lic_no && driving_lic_no != 'undefined') ? schema.required("Please Choose a file") : schema)
              }
              else return schema
            })
        }
        else return schema
      }),
  }),
  // Passport validation
  passport_no: Yup.string().min(8, "Invalid Passport").max(8, "Invalid Passport").matches(Passport, 'Invalid Passport')
    .when('document_id', (document_id, schema) => {
      if (document_id && document_id == PASSPORT_DOC_ID) {
        return schema
          .when('requiredDocuments', (requiredDocuments, schema) => {
            if (requiredDocuments && requiredDocuments?.length > 0) {
              return (requiredDocuments?.includes(document_id) ? schema.required("Passport is required ") : schema)
            }
            else return schema
          })
      }
      else return schema
    }),

  passport_file: Yup.mixed().when("documents_check", {
    is: true,
    then: Yup.mixed()
      .test("type", "We only support pdf or jpeg/jpg", function (value) {
        if (value != undefined && value) { return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf"); }
        return true;
      })
      .test("fileSize", "File Size is too large", (value) => {
        const fileSize = value?.size / 1024 / 1024; //in mb
        if (fileSize > 2) {
          return false;
        }
        return true;
      })
      .when(['document_id', 'passport_no'], (document_id, passport_no, schema) => {
        if ((document_id && document_id == PASSPORT_DOC_ID) || (passport_no && passport_no != 'undefined')) {
          return schema
            .when('requiredDocuments', (requiredDocuments, schema) => {
              if (requiredDocuments && requiredDocuments?.length > 0) {
                return (requiredDocuments?.includes(document_id) || (passport_no && passport_no != 'undefined') ? schema.required("Please Choose a file") : schema)
              }
              else return schema
            })
        }
        else return schema
      }),
  }),
});

export const LeaveApprovalValidation = Yup.object().shape({
  approval_status: Yup.string().required('Please select an option'),
  approval_remark: Yup.string().required('Remark is required'),
})

export const LeaveFormAttendanceValidation = Yup.object().shape({
  leave_is_multiple: Yup.boolean(),

  from_date: Yup.date().nullable().typeError('Please enter a valid date')
    .when('leave_is_multiple', (leave_is_multiple, schema) => {
      if (ReturnBoolean2(leave_is_multiple) == false) { return schema.required("Date is required") }
      else { return schema.required("From Date is required") }
    })
    .when('isTodayCheckIn', (isTodayCheckIn, schema) => { // This validation is validating the min date in according to today's check-in. 
      if (isTodayCheckIn) { return schema.min(new Date(), "You cannot apply for today's leave as you have already marked your attendance."); }
      return schema.min(moment().subtract(1, 'days'), "Please select a valid date");
    }),

  // .min(moment().format(),'Please select a valid date'), // it blocking current date to apply leave
  to_date: Yup.date().nullable()
    .when("leave_is_multiple", {
      is: true,
      then: Yup.date().nullable().required("To Date is required").typeError('Please enter valid date')
        // .min(Yup.ref('from_date'), "From Date can't be before To Date") //changed this validation as per issue list on 16th may, 2023
        .when(['from_date', 'to_date'], (from_date, to_date, schema) => {
          const FromDate = from_date ? new Date(from_date) : new Date()
          const dayAfter = new Date(FromDate.getTime() + 86400000); // from_date's next Date 
          if (to_date && from_date && moment(to_date).isSame(from_date)) { //Info: condition for Date can't be same as From Date
            return schema.min(dayAfter, "To Date can't be same as From Date");
          } else if (from_date) {
            return schema.min(dayAfter, 'To Date has to be after than From Date'); //Info: condition for To Date has to be after than From Date
          }
          return schema;
        }),
    }),

  // leave settings changes
  isReasonRequired: Yup.boolean(),
  leave_is_paid_object: Yup.string().nullable().required("Please select Leave Type."),
  reasonObject: Yup.object().nullable().typeError('Please select a reason.')
    .when("isReasonRequired", {
      is: true,
      then: Yup.object().nullable().required("Please select a reason.").typeError('Please select a reason.')
    }),
  reason: Yup.string()
    .when("isReasonRequired", {
      is: true,
      then: Yup.string().required("Reason is required").max(250, "You can enter only 250 characters"),
    })
    .when('reasonObject', (reasonObject, schema) => {
      if (reasonObject != null && reasonObject?.is_other == true) {
        return schema.required("Reason is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters")
      }
      return schema;
    }),
});

export const checkOutDpr = Yup.object().shape({
  remark: Yup.string().required('Remark is required').max(250, "Remark must atleast 250 character"),
});


export const EditFormEmergencyDetailsValidation = (TalentSetting) => Yup.object().shape({
  // edit emergency details
  emr_contact_detail_check: Yup.boolean(),
  emr_contact_mobile: Yup.string().matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid")
    .when(
      'emr_contact_detail_check',
      (emr_contact_detail_check, schema) => emr_contact_detail_check && TalentSetting?.field_emer_contact_no ?
        schema.required("Contact Number is required") : schema,
    ),
  emr_person_name: Yup.string().matches(nameRegExp, "Please enter valid person name")
    .when(
      'emr_contact_detail_check',
      (emr_contact_detail_check, schema) => emr_contact_detail_check && TalentSetting?.field_emer_name ?
        schema.required("Person Name is required") : schema,
    ),
  emr_contact_relation: Yup.string().matches(nameRegExp, "Please enter valid relation")
    .when(
      'emr_contact_detail_check ',
      (emr_contact_detail_check, schema) => emr_contact_detail_check && TalentSetting?.field_emer_relation ?
        schema.required("Relation is required") : schema,
    ),
});


/**
 * [2022-12-28]
 * changes by: Purva Sharma
 * Description:- Changed Error messages
 **/
export const EditEmpFormBankDetailsValidation = (TalentSetting) => Yup.object().shape({
  //edit Bank details
  bank_detail_check: Yup.boolean(),
  bank_ifsc_code: Yup.string()
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_ifsc_code ? schema.required("IFSC Code is required") : schema,
    ),
  bank_name: Yup.string().matches(nameRegExp, "Please enter a valid bank name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_bank_name ? schema.required("Bank Name is required") : schema,
    ),
  bank_branch_name: Yup.string().matches(nameRegExp, "Please enter a valid branch name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_branch_name ? schema.required("Branch Name is required") : schema,
    ),
  bank_account_no: Yup.string().matches(digit, "Please enter a valid Account Number").min(9, "Please enter a valid Account Number ").max(18, "Please enter a valid Account Number")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_account_number ?
        schema.required("Account Number is required") : schema,
    ),
  bank_account_holder_name: Yup.string().matches(nameRegExp, "Please enter a valid Cardholder's Name")
    .when(
      'bank_detail_check',
      (bank_detail_check, schema) => bank_detail_check && TalentSetting?.field_account_holder_name ?
        schema.required("Cardholder's Name is required") : schema,
    ),
});




export const EditFormBankDetailsValidation = Yup.object().shape({
  //edit Bank details
  bank_detail_check: Yup.boolean(),
  ifsc_code: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("IFSC Code is required")
    }),
  bank_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Bank Name is required").matches(nameRegExp, "Please enter valid Bank name")
    }),
  branch_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Branch Name is required")
    }),
  account_no: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Account No. required").matches(digit, 'Please enter valid account no')
    }),
  account_holder_name: Yup.string()
    .when("bank_detail_check", {
      is: true,
      then: Yup.string().required("Cardholder's Name is required").matches(nameRegExp, "Please enter valid Cardholder's Name")
    }),

});

export const EditOfficalDetailValidation = Yup.object().shape({
  //edit Offical details
  doj: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('DOJ is required'),
  department: Yup.mixed().required("Department is required"),
  designation: Yup.mixed().required("Designation is required"),
  payroll_basic: Yup.string().required("CTC is required").matches(digit, "Please enter numbers only").max(10, "Salary must be max 10 digit"),
  work_mode: Yup.mixed().required("Work Mode is required"),
  isShiftAllowed: Yup.boolean(),
  shift_object: Yup.mixed()
    .when("isShiftAllowed", {
      is: true,
      then: Yup.mixed().required("Shift Name is required")
    }),
});


export const EditAttendanceValidation = Yup.object().shape({
  abbreviation: Yup.mixed().required("Abbreviation is required"),
  final_abbrivation: Yup.mixed().required("Abbreviation is required"),
  attendance_remark: Yup.mixed().required("Remark is required"),
});


export const UpdateAdvanceDeductionValidation = Yup.object().shape({
  previous_advance: Yup.number(),
  amount: Yup.number().required("Advance Deduction Amount is required").moreThan(0, "Advance Deduction Amount should be greater than 0").max(Yup.ref('previous_advance'), 'Advance Deduction Amount cannot be greater than Previous Advance.').typeError('Please enter only numbers.'),
});


export const PayTalentSalaryFormValidation = Yup.object().shape({
  payment_mode: Yup.object().nullable().required('Payment Mode is required'),
  // payment_detail: '',
  payment_detail: Yup.string().nullable().when("isType", {
    is: true,
    then: Yup.string().required("Cheque Number is required")
      .matches(digit, "Special characters are not allowed.")
      .test('len', 'Must be exactly 6 digits', val => val.length === 6)
  }),

  balanceAmount: Yup.number(),
  amount: Yup.number()
    .required('Amount is required').typeError('Please only enter numbers').min(0, 'Amount cannot be less than zero')
    .when('balanceAmount', (balanceAmount) => {
      if (Number(balanceAmount) > 0)
        return Yup.number()
          .nullable()
          .required('Amount is required')
          .typeError('Please only enter numbers')
          .max(balanceAmount, 'Amount cannot be more than balance amount').min(0, 'Amount cannot be less than zero').test(
            'is-decimal',
            'After decimal take 2 digit only',
            value => (value + "").match(/^\d+(\.\d{1,2})?$/),
          )
    }),
  remark: Yup.string().required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
});


export const HoldPaymentFormValidation = Yup.object().shape({
  remark: Yup.string().required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
});


export const BulkPaymentValidation = Yup.object().shape({
  accountant_remark: Yup.string().required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
  payment_medium: Yup.object().nullable().required('Payment Mode is required').typeError('Payment Mode is required'),
  payment_detail: Yup.string().nullable()
    .when('payment_medium', (payment_medium, schema) => {
      if (payment_medium != null && payment_medium?.id == 'CHEQUE') {
        return schema.required('Cheque No is required')
          .matches(digit, "Not a valid cheque number.")
          .test('len', 'Must be exactly 6 digits', val => val?.length === 6)
          .typeError('Cheque No is required')
      }
      return schema;
    }),
});



export const RequestDetailValidation = (ENABLE_REIMBURSEMENT_REASON) => Yup.object().shape({
  is_attachment_required: Yup.boolean(),
  reimbursement_reason: Yup.boolean(),
  transaction_category: Yup.object().nullable().required('Request Type is required').typeError('Request Type is required'),
  // reimbursement_reason_category: Yup.object().nullable().required('Particular is required').typeError('Particular is required'),
  reimbursement_reason_category: Yup.object().nullable()
    .when('transaction_category', (value, schema) => {
      /**
       * If setting for ENABLE_REIMBURSEMENT_REASON is enalbed then it is required
       */
      if (value && ENABLE_REIMBURSEMENT_REASON &&
        [PAYMENT_CATEGORY_REIMBURSEMENT].includes(value.id)) {
        return schema.required('Reason is required').typeError('Reason is required');
      }
      return schema;
    }),

  request_amount: Yup.number().nullable().required("Request Amount is required").min(0.1, "Request amount must be more than 0").typeError('Please enter valid numbers'),
  remark: Yup.string().nullable()
    .when(['transaction_category', 'reimbursement_reason'], (id, reason, schema) => {
      if (id == null || id?.id !== PAYMENT_CATEGORY_REIMBURSEMENT || reason) {
        return schema.matches(remarkRegExp, "Please enter valid characters")
      }
      return schema;
    })
    .required("Reason is required").max(250, "You can enter only 250 characters "),
  reimbursement_bill_date: Yup.date().nullable()
    .when(['transaction_category', 'is_attachment_required'], (id, required, schema) => {
      if (id != null && id?.id == 3 && required) {
        return schema.required('Bill Date is required').typeError('Bill Date is required')
      }
      return schema;
    }),
  reimbursement_attachment: Yup.mixed()
    .when(['transaction_category', 'is_attachment_required'],
      (id, required, schema) => {
        if (id != null && id?.id == 3) {
          if (required) {
            return schema.
              test(required, "Please upload the attachment", (value) => {
                return value != null
              })
              .test("type", "We only support pdf, jpeg and jpg format", function (value) {
                return value && (value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "application/pdf");
              }).test('FILE_SIZE', "Uploaded file size should be less then 2MB", function (value) {
                if (value) {
                  return value && value.size <= 2097152
                }
                else {
                  return true
                }
              })
          }
        }
        return schema;
      }),
  employee_id: Yup.object().nullable()
    .when('transaction_category', (id, schema) => {
      if (id != null && id?.id == PAYMENT_CATEGORY_OTHER) {
        return schema.required('Employee Name is required').typeError('Employee Name is required')
      }
      return schema;
    }),
});

export const ApproveRequestDetailFormValidation = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  remark: Yup.string().nullable()
    .when('status', (id, schema) => {
      if (id != null && id == 'CANCELLED') {
        return schema.required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters")
      }
      return schema;
    }),
});


export const accountantPayValidation = Yup.object().shape({
  accountant_remark: Yup.string().required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
  payment_medium: Yup.object().nullable().required('Payment Mode is required'),
  payment_detail: Yup.string().nullable()
    .when('payment_medium', (payment_medium, schema) => {
      if (payment_medium != null && payment_medium?.id == 'CHEQUE') {
        return schema.required('Cheque No is required').matches(digit, "Not a valid cheque number.")
          .test('len', 'Must be exactly 6 digits', val => val?.length === 6)
          .typeError('Cheque No is required')
      }
      return schema;
    }),
});

export const directPayValidation = Yup.object().shape({
  remark: Yup.string().required("Remark is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
  payment_mode: Yup.object().nullable().required('Payment Mode is required').typeError('Payment Mode is required'),
  talent: Yup.object().nullable().required('Employee Name is required').typeError('Employee Name is required'),
  amount: Yup.string().required("Amount is required").matches(digit, "Please enter only numbers").min(0.1, "Please enter atleast 2 digit"),
  payment_detail: Yup.string().nullable()
    .when('payment_mode', (payment_medium, schema) => {
      if (payment_medium != null && payment_medium?.id == 'CHEQUE') {
        return schema.required('Cheque No is required')
          .matches(digit, "Not a valid cheque number.")
          .test('len', 'Must be exactly 6 digits', val => val?.length === 6)
          .typeError('Cheque No is required')
      }
      return schema;
    }),
});

//Add Appraisal Form validation
export const TalentAppraisalValidation = Yup.object().shape({
  talent: Yup.object().nullable().required('Employee Name is required').typeError('Employee Name is required'),
  appraise_type: Yup.string().required('Appraisal Type is required'),
  current_designation: Yup.object().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'DESIGNATION' || value == 'BOTH')) {
        return schema.required("Current Designation is required")
      }
      return schema;
    }),
  department: Yup.object().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'DESIGNATION' || value == 'BOTH')) {
        return schema.required("Department is required").typeError('Department is required')
      }
      return schema;
    }),
  designation: Yup.object().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'DESIGNATION' || value == 'BOTH')) {
        return schema.required("Designation is required").typeError('Designation is required')
      }
      return schema;
    }),
  current_ctc: Yup.string().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'SALARY' || value == 'BOTH')) {
        return schema.required("Salary (CTC Per Month) is required").matches(/^\d+(\.\d{1,2})?$/, "Invalid Amount format")
      }
      return schema;
    }),
  appraisal_amount: Yup.string().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'SALARY' || value == 'BOTH')) {
        return schema.required("Increment Amount is required").matches(/^\d+(\.\d{1,2})?$/, "Invalid Amount format")
      }
      return schema;
    }),
  final_salary: Yup.string().nullable()
    .when('appraise_type', (value, schema) => {
      if (value && (value == 'SALARY' || value == 'BOTH')) {
        return schema.required("Updated CTC is required").matches(/^\d+(\.\d{1,2})?$/, "Invalid Amount format")
      }
      return schema;
    }),
  remark: Yup.string().required("Remark is required"),
  from_date: Yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).required('Applicable From is required'),
});



export const AddPaymentTermValidation = Yup.object().shape({
  eligiblePayment: Yup.number(),
  is_advance: Yup.boolean(),
  eligiblePerc: Yup.number(),

  deliverable_value: Yup.array()
    .when("is_advance", {
      is: false,
      then: Yup.array().min(1, "Deliverables is required").required('Deliverables is required').typeError('Deliverables is required')
    }),

  percentage: Yup.number()
    .required("Percentage is required")
    .moreThan(0, "Percentage should be greater than 0")
    .max(Yup.ref('eligiblePerc'), "Amount is exceeded")
    .test({
      name: "percentage_test",
      params: {},
      message: "Amount is exceeded",
      test: function (value) {
        if (!value) {
          return false;
        }
        if (this.parent.is_advance && value == 100) {
          return false;
        }
        return true;
      },
    }),
  amount: Yup.number().required("Amount is required").moreThan(0, "Amount should be greater than 0")
    .max(Yup.ref('eligiblePayment'), "Total amount of the contract is exceeding").typeError('Invalid Amount'),
});


export const SalaryBulkPaymentValidation = Yup.object().shape({
  list: Yup.array().of(
    Yup.object().shape({
      balance_amount: Yup.number(),
      updatedPayAmount: Yup.number()
        .max(Yup.ref('balance_amount'), "Pay amount can't exceed balance amount")
        .required('Pay amount is required')
        .min(0.01, "Pay amount can not be 0"),
    })
  ),
});

export const AddNewTicketValidation = Yup.object().shape({
  subject: Yup.string().max(50).required('Subject is required').matches(subjectRegExp, 'Please enter valid Subject').label('Subject'),
  message: Yup.string().required('Problem Statement is required'),
  priority: Yup.mixed().required('Priority is required'),
  attachment: Yup
    .mixed()
    // .test("required", "photo is required", value => value?.length > 0)
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > 5242880) {
            return false;
          }
        }
      }
      return true;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].type != "image/png" && value[i].type != "image/jpg" && value[i].type != "image/jpeg") {
            return false;
          }
        }
      }
      return true;
    }
    ),
});


export const ReplyTicketValidation = Yup.object().shape({
  message: Yup.string().required('Message is required'),
  attachment: Yup
    .mixed()
    //  .test("required", "photo is required", value => value.length > 0)
    .test("fileSize", "File Size is too large", (value) => {
      if (value && value?.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].size > 5242880) {
            return false;
          }
        }
      }
      return true;
    })
    .test("fileType", "Unsupported File Format", (value) => {
      if (value && value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].type != "image/png" && value[i].type != "image/jpg" && value[i].type != "image/jpeg") {
            return false;
          }
        }
      }
      return true;
    }
    ),
});


export const UpdatePassSchema = Yup.object().shape({
  oldpassword: Yup.string().required('Old Password is required'),
  password: Yup.string().required('New Password is required').min(8, "New Password must atleast 8 character").matches(passwordRegex, 'Special characters are not allowed'),
  confirm_password: Yup.string().required('Confirm Password is required').oneOf([Yup.ref("password"), null], "Confirm Password doesn't match with New Password.")

});

// INFO: used in update new password by admin
export const NewPassSchema = Yup.object().shape({
  new_password: Yup.string().required('New Password is required').min(8, "New Password must be atleast 8 character").matches(passwordRegex, 'Special characters are not allowed'),
  confirm_password: Yup.string().required('Confirm Password is required').oneOf([Yup.ref("new_password"), null], "Confirm Password doesn't match with New Password.")
});

export const TerminateContractValidation = Yup.object().shape({
  termination_by: Yup.string().required('Terminated By is required'),
  termination_settlement_date: Yup.date().nullable().typeError('Invalid Date format'),
  termination_remark: Yup.string().required("Reason is required").max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters")
});


export const AddTaCValidation = Yup.object().shape({
  terms_title: Yup.string().required("Description is required").matches(Tnc, "Please enter valid characters"),//
  status: Yup.string().required("Please select an option"),
  wordcount: Yup.string().min(3, "Description must be atleast 3 characters").max(250, "Description cannot be more than 250 characters"),
  // .matches(Tnc, 'Description is not valid'),
});


export const RequestNewFeature = Yup.object().shape({
  category: Yup.object().nullable().required("Please select category"),
  feature: Yup.string().required("Feature is required"),
  wordcount: Yup.string().max(250, "Description cannot be more than 250 characters"),
  featureFile: Yup
    .mixed()
    .test("fileSize", "File Size is too large", (value) => {
      if (value !== null && value.size > 1000000) {
        return false;
      }
      return true;
    })
    .test("fileType", "We only support jpeg & jpg format", (value) => {
      if (value !== null && value.type != "image/jpg" && value.type != "image/jpeg") {
        return false;
      }
      return true;
    }
    ),
});

export const ReasonValidation = Yup.object().shape({
  remark: Yup.string().required('Reason is required').max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters")
});

export const ReleaseTalentValidation = Yup.object().shape({
  transfer_type: Yup.object().nullable().required("Exit Type is required"),
  last_working_date: Yup.string().required("Last Working Date is required"),
  attachment_file: Yup.mixed().test("fileType", "* Allowed formats are pdf and jpeg/jpg", (value) => {
    if (value && value.type !== 'image/jpg' && value.type !== 'image/jpeg' && value.type !== 'application/pdf') {
      return false
    }
    return true
  }),
  noc_file: Yup.mixed().test("fileType", "* Allowed format is pdf", (value) => {

    if (value && !["application/pdf"].includes(value.type)) {
      return false
    }
    return true
  }),

  reason_other: Yup.string().nullable()
    .when('transfer_type', (value, schema) => {
      if (value && value.id == RESIGN_ID) {
        return schema.required('Reason is required').max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters");
      }
      return schema;
    }),
  reason_terminate: Yup.object().nullable()
    .when('transfer_type', (value, schema) => {
      if (value && [RELEASE_ID, 3].includes(value.id)) {
        return schema.required("Reason is required");
      }
      return schema;
    }),
});


export const companySignup = (id) => Yup.object().shape({
  plan_id: Yup.number().required().typeError('Plan must be a number'),
  tncCheck: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  fullname: Yup.string().required('Full Name is required')
    .matches(nameRegExp, "Name can only contain alphabets").max(50, 'Must be less then 50 characters'),
  username: Yup.string().required('Username is required').min(4).matches(customUsernameRegExp, 'Your username can have (A-Z), (a-z), (0-9), (.),(@)').max(50, 'Username too long!')
    .test({
      name: "duplicate",
      params: {},
      message: "Username has already been taken",
      test: function (value) {
        if (value?.length >= 4 && customUsernameRegExp.test(value)) {
          return axios(`${SERVER_URL}/user/username-check?username=${!value ? null : value}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }).then(res => {
            const { availability } = res.data;
            return availability;
          })
        }
      }
    })
  ,
  company_name: Yup.string()
    .required("Company Name is required")
    .min(3, "Company Name must be atleast 3 characters")
    .matches(companyName, "Please enter a valid name"),
  password: Yup.string().required('Password is required').min(8, " Password must atleast 8 character").matches(passwordRegex, 'Special characters are not allowed'),
  user_title: Yup.object().nullable(false).required('Title is required').typeError('Title is required'),
  email: Yup.string()
    .required("Email ID is required").matches(emailRegExp, 'Email ID is not valid'),
  // Removing the duplicate check for email

  // .test({
  //   name: "duplicate",
  //   params: {},
  //   message: "Email ID has already been taken",
  //   test: (value) =>
  //   /**
  //    * The `encodeURIComponent()` method encodes special characters in a URL, such as spaces, ampersands, and plus signs, so they can be used as part of a parameter value.
  //    * 
  //    * For example, the plus sign is often used to represent a space in a URL, so if you want to include a literal plus sign in a URL parameter value, you need to encode it as `%2B`.
  //    */
  //     fetch(`${SERVER_URL}/user/unique-check?param=${!value ? null : encodeURIComponent(value)}&field=email`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),
  mobile: Yup.string()
    .required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid")
  // Removing the duplicate check for phone number

  // .test({
  //   name: "duplicate",
  //   params: {},
  //   message: "Contact Number has already been taken",
  //   test: (value) =>
  //     fetch(`${SERVER_URL}/user/unique-check?param=${!value ? null : value}&field=mobile`, {
  //       method: 'GET',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       }
  //     }).then(async res => {
  //       const { availability } = await res.json();
  //       return availability;
  //     }),
  // }),
});

export const companySignupPayment = Yup.object().shape({
  // remark: Yup.string().required('Reason is required').max(250, "You can enter only 250 characters ").matches(remarkRegExp, "Please enter valid characters"),
  card_holder_name: Yup.string().required('Name is required').matches(nameRegExp, "Name can only contain alphabets"),
  card_number: Yup.number()
    .required("Card No. is required")
    // .test("required", "Card no. length sh", value => value?.length > 15)
    .test({
      name: "valid_card",
      params: {},
      message: "Wrong Card No.",
      test: function (value) {
        if (!ValidateCreditCardNumber(value)) {
          return false;
        }
        return true;
      },
    }),

  card_year: Yup.number()
    .required("Year is required")
    .test("required", "Year length should be 4 digit", value => value !== 0),


  card_month: Yup.number()
    .required("Month is required")
    .test("required", "Month length should be 2 digit", value => value !== 0)

});

export const IpMangerValidation = Yup.object().shape({
  ip_address: Yup.string().required('IP Address is required').matches(ipAddressRegExp, "Please enter valid characters"),
  connection_name: Yup.string().required('Ip Name is required').max(20, 'You can enter only 20 characters')
});


export const AddNewLeaveReasonValidation = Yup.object().shape({
  status: Yup.boolean(),
  leave_reason: Yup.string()
    .required('Title is required')
    .matches(nameRegExp, "Please enter a valid name")
    .min(2, "Title must be atleast 2 characters")
    .max(100, 'Must be less then 100 characters'),
});

export const ManualAttendanceValidation = Yup.object().shape({
  isAllTalents: Yup.boolean().nullable().required('Please select an option'),
  isFullDay: Yup.boolean(),
  from_date: Yup.date().nullable().required('From Date is required'),
  to_date: Yup.date().nullable().required('To Date is required').min(Yup.ref('from_date'), "From Date can't be before To Date"),
  abbreviation: Yup.object().nullable().required('Abbreviation is required'),
  talent_names: Yup.array().nullable()
    .test("required", "Please select atleast one employee", (value) => {
      if (value && value?.length > 0) {
        return true;
      }
      return false;
    })
})
// INFO: Validation for payroll setting
export const PayrollSettingValidation = Yup.object().shape({
  payroll_is_auto: Yup.boolean().required('Please select an option.'),
  payroll_auto_generation_date: Yup.object().nullable()
    .when(
      'payroll_is_auto',
      (payroll_is_auto, schema) => {
        if (ReturnBoolean(payroll_is_auto) == true) {
          return (
            schema.required("Please select a Payroll Generation Date").nullable("Please select a Payroll Generation Date").typeError('Please select a Payroll Generation Date')
          )
        }
        return schema
      }
    ),

})

export const TalentSettingValidation = Yup.object().shape({
  // 1st card: Enable employee directory company-wide
  enable_talent_directory: Yup.boolean().nullable().required('Please select an option.'),
  talent_custom_field: Yup.boolean().nullable().required('Please select an option.'),
  at_least_one_field: Yup.boolean().nullable()
    .when(
      'talent_custom_field',
      (talent_custom_field, schema) => {
        if (ReturnBoolean2(talent_custom_field) == true) {
          return (
            schema.test("format", "At least one checkbox must be selected.", function (value) {
              return value == true;
            })
          )
        }
        return schema
      }
    ),

  // 3rd card : Create custom employee details form
  enable_talent_code_prefix: Yup.boolean().nullable().required('Please select an option.'),
  talent_code_prefix: Yup.string().nullable()
    .when(
      'enable_talent_code_prefix',
      (enable_talent_code_prefix, schema) => {
        if (ReturnBoolean(enable_talent_code_prefix) == true) {
          return (
            schema.required("Code Prefix is required").matches(alphaNumbericRegex, 'Please enter only alphabets or numbers.').min(3, 'Please enter a 3-character prefix code.').max(3, 'Please enter a 3-character prefix code.')
          )
        }
        return schema
      }
    ),

})

/**
 * [2023-04-27]
 * changes by: Purva Sharma
 * Description:- Added PaymentSettingValidation
 **/
export const PaymentSettingValidation = Yup.object().shape({
  enable_reimbursement_reason: Yup.boolean(),
  reimbursementReason: Yup.array().optional().when("enable_reimbursement_reason", {
    is: true,
    then: Yup.array().test(
      'oneOfRequired',
      'Please select at least one reason.',
      function (items) {
        return items?.some(item => item.is_active) === true;
      }
    ),
  })
});

const shiftNameValidation = Yup.string().label("Shift Name")
  .when(
    'is_default_shift',
    (is_default_shift, schema) => {
      if (!is_default_shift) {
        return schema.required("Shift Name is a required field").test({
          name: "shift_name",
          params: {},
          message: "Shift Name already exists",
          test: function (value) {
            const values = this.from[1]?.value
            const shifts = values?.shifts
            const isDuplicate = shifts?.filter(i => (i.id != this.parent?.id && i?.shift_name?.toLowerCase() === value?.toLowerCase())).length > 0
            if (isDuplicate) {
              return false;
            }
            return true
          },
        })
      }
      return schema
    }
  )

const hourlyShiftObjectValidation = Yup.object().shape({
  shift_name: shiftNameValidation,
  check_in_before: Yup.string().nullable().required().label("Check-in Before"),
  min_full_day_hour: Yup.string().required("Minimum Hours (Full Day) is required")
    .test({
      name: "min_full_day_hour",
      params: {},
      message: "Minimum Hours (Full Day) should not exceed 24 hours time",
      test: function (value) {
        if (!value) {
          return false
        }
        value = value?.replace(".", ":")
        const _value = moment(value, 'HH:mm')
        const checkInBefore = moment(this.parent.check_in_before)
        // Get the end of the current day
        const dayEnd = moment().endOf('D')
        // Calculate the allowed time in minutes (difference between dayEnd and checkInBefore)
        const allowedTimeInMinutes = dayEnd.diff(checkInBefore, 'minutes')
        // Get the start of the current day
        const dayStart = moment().startOf('D')
        // Calculate the value in minutes (difference between _value and dayStart)
        const valueInMinutes = _value.diff(dayStart, 'minutes')
        // Check if valueInMinutes exceeds allowedTimeInMinutes
        if (valueInMinutes > allowedTimeInMinutes) {
          return false;
        }
        return true;
      },
    })
    .label("Minimum Hours (Full Day)"),
  min_half_day_hour: Yup.string().required("Minimum Hours (Half Day) is required").label("Minimum Hours (Half Day)")
    .test({
      name: "min_half_day_hour_test",
      params: {},
      message: "Minimum Hours (Half Day) should be less than Minimum Hours (Full Day)",
      test: function (value) {
        if (value == '00:00') {
          return false;
        }
        return moment(value, 'HH:mm').isBefore(moment(this.parent.min_full_day_hour, 'HH:mm'));
      },
    }),
  is_default_shift: Yup.boolean(),
})
const timeBoundShiftObjectValidation = Yup.object().shape({
  shift_name: shiftNameValidation,
  check_in_time: Yup.string().label("Check-in Time")
    .required("Check-in Time is required").nullable("Check-in Time is required")
    .typeError('Invalid Time format')
    .test({
      name: "compare",
      params: {},
      message: "The check-in time cannot be same as check-out time",
      test: function (value) {
        if (!this.parent.check_out_time) return true
        return value && this.parent.check_out_time && !(moment(value).isSame(this.parent.check_out_time))
      }
    }),

  check_out_time: Yup.string().label("Check-out Time")
    .required('Check-out Time is required').nullable("Check-out Time is required").typeError('Invalid Time format')
    .test({
      name: "compare",
      params: {},
      message: "The check-out time cannot be same as check-in time",
      test: function (value) {
        return value && this.parent.check_in_time && !(moment(value).isSame(this.parent.check_in_time))
      }
    })
    .test({
      name: "compare",
      params: {},
      message: "The check-out time can't be before check-in time",
      test: function (value) {
        return value && this.parent.check_in_time && !(moment(value).isBefore(this.parent.check_in_time))
      }
    }),

  buffer_time: Yup.string().required("Buffer Time is required").label("Buffer Time")
    .test({
      name: "buffer_time",
      params: {},
      message: "Buffer Time should be less than total working hour",
      test: function (value) {
        const time1 = moment(this.parent.check_in_time)
        const time2 = moment(this.parent.check_out_time)
        const diffInMinutes = time2.diff(time1, 'minutes')
        return diffInMinutes >= Number(value)
      },
    }),
  min_half_day_hour: Yup.string().required("Minimum Hours (Half Day) is required").label("Minimum Hours (Half Day)")
    .test({
      name: "min_half_day_hour_test",
      params: {},
      message: "Minimum Hours (Half Day) should be less than total working hour",
      test: function (_value) {
        let value = _value?.replace(".", ":")
        const checkInTime = moment(this.parent.check_in_time)
        const checkOutTime = moment(this.parent.check_out_time)
        const diff = checkOutTime.diff(checkInTime, 'milliseconds')
        const totalWorking = moment.utc(diff).format('HH:mm')
        const formattedTime = moment(totalWorking, 'HH:mm')
        let formattedValue = moment(value, 'HH:mm')
        if (value == '00:00') {
          return false
        }
        return formattedValue.isBefore(formattedTime)
      },
    }),
  is_default_shift: Yup.boolean(),
})

const tempWeeklyOffValidation = Yup.array().of(Yup.object().shape({
  addMode: Yup.boolean().nullable(),
  day_name: Yup.string().required("Day is required").label("Day"),
  week_no: Yup.string().required("Week is required").label("Week"),
}))

export const AttendanceSettingValidation = Yup.object().shape({
  allow_attendance_automate: Yup.boolean().nullable().required('Please select an option'),
  at_least_one_field: Yup.boolean().nullable()
    .when(
      'allow_attendance_automate',
      (allow_attendance_automate, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate)) {
          return (schema.test("format", "At least one checkbox must be selected.", function (value) {
            return value;
          }))
        }
        return schema
      }
    ),
  // IP Setting Validations
  allow_ip_check: Yup.boolean().nullable()
    .when(
      'allow_attendance_automate',
      (allow_attendance_automate, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate)) {
          return (schema.required("Please select an option"))
        }
        return schema
      }
    ),
  ip_address: Yup.string().nullable()
    .when(
      'allow_ip_check',
      (allow_ip_check, schema) => {
        if (allow_ip_check && ReturnBoolean(allow_ip_check)) {
          return (schema.required("IP Address is required").matches(ipAddressRegExp, "Please enter valid characters"))
        }
        return schema
      }
    ),
  connection_name: Yup.string().nullable()
    .when(
      'allow_ip_check',
      (allow_ip_check, schema) => {
        if (allow_ip_check && ReturnBoolean(allow_ip_check)) {
          return (schema.required("IP Name is required"))
        }
        return schema
      }
    ),
  // Office Hours Validations
  office_hours_type: Yup.number().nullable().required('Please select an option'),
  showForm: Yup.boolean().nullable(),
  tempShift: Yup.object()
    .when(['allow_attendance_automate', 'showForm', 'office_hours_type'], {
      is: (allow_attendance_automate, showForm, office_hours_type) => allow_attendance_automate && showForm && office_hours_type === OFFICE_HOURS_TYPE.HOURLY,
      then: hourlyShiftObjectValidation,
    })
    .when(['allow_attendance_automate', 'showForm', 'office_hours_type'], {
      is: (allow_attendance_automate, showForm, office_hours_type) => allow_attendance_automate && showForm && office_hours_type === OFFICE_HOURS_TYPE.TIME_BOUND,
      then: timeBoundShiftObjectValidation,
    }),
  // Attendance Regularization Validations
  attendance_regularization_required: Yup.boolean().nullable()
    .when(
      'allow_attendance_automate',
      (allow_attendance_automate, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate)) {
          return (schema.required("Please select an option"))
        }
        return schema
      }
    ),
  attendance_regularization_request: Yup.boolean().nullable()
    .when(['allow_attendance_automate', 'attendance_regularization_required'],
      (allow_attendance_automate, attendance_regularization_required, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate) && attendance_regularization_required && ReturnBoolean(attendance_regularization_required)) {
          return (schema.required("Please select an option"))
        }
        return schema
      }
    ),
  attendance_regularization_count: Yup.number().nullable()
    .when(['allow_attendance_automate', 'attendance_regularization_required', 'attendance_regularization_request'],
      (allow_attendance_automate, attendance_regularization_required, attendance_regularization_request, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate) && attendance_regularization_required && ReturnBoolean(attendance_regularization_required) && attendance_regularization_request != null && !ReturnBoolean(attendance_regularization_request)) {
          return (schema
            .required("Total Regularization is required")
            .min(1, 'Total Regularization cannot be less than 1.')
            .max(10, 'Total Regularization cannot be more than 10.')
            .typeError('Total Regularization must be number')
          )
        }
        return schema
      }
    ),

  // Weekly Off Validations
  enable_weekly_off: Yup.boolean().nullable().required('Please select an option'),
  default_weekly_off_day: Yup.string().nullable()
    .when(
      'enable_weekly_off',
      (enable_weekly_off, schema) => {
        if (enable_weekly_off && ReturnBoolean(enable_weekly_off)) {
          return (schema.required("Please select an option"))
        }
        return schema
      }
    ),
  tempWeeklyOff: Yup.array().of(tempWeeklyOffValidation),
  tempWeeklyOff: Yup.array()
    .when(['enable_weekly_off'], {
      is: (enable_weekly_off) => enable_weekly_off,
      then: tempWeeklyOffValidation,
    }),

  // Payslip Setting Validations
  show_attendance_on_payslip: Yup.boolean().nullable().required('Please select an option'),

  // Biometric Validation
  // enable_biometric: Yup.boolean().nullable().required('Please select an option'),
  enable_biometric: Yup.boolean().nullable()
    .when(
      'allow_attendance_automate',
      (allow_attendance_automate, schema) => {
        if (allow_attendance_automate && ReturnBoolean(allow_attendance_automate)) {
          return (schema.required("Please select an option"))
        }
        return schema
      }
    ),
  biometric: Yup.string().nullable()
    .when(
      'enable_biometric',
      (enable_biometric, schema) => {
        if (enable_biometric && ReturnBoolean(enable_biometric)) {
          return (schema.required("Biometric device serial no. is required"))
        }
        return schema
      }
    ),
});


/**
 * [2022-03-23]
 * changes by: Purva Sharma
 * Description:- Added validation scheme for updating billing information
 **/

export const AccountBillingInfoValidation = Yup.object().shape({
  address: Yup.string().required('Billing Address is required'),
  phone_no: Yup.string().matches(phoneRegExp, 'Please enter a valid contact number'),
  city: Yup.object().required('City is required').typeError('City is required'),
  state: Yup.object().required('State is required').typeError('State is required'),
  pin_code: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  company_name: Yup.string()
    .required("Organization name is required")
    .min(3, "Organization name must be atleast 3 characters").matches(companyName, "Please enter a valid name"),
  description: Yup.string().max(250, "You can enter upto 250 character."),
  gst: Yup.string().min(15, "Invalid GSTIN number").max(15, "Invalid GSTIN number").matches(gst, "Invalid GSTIN number"),
  email: Yup.string().required("Email Id is required").matches(emailRegExp, 'Email Id is not valid'),

});

export const ScheduleACallFormValidation = Yup.object().shape({
  fullname: Yup.string().required('Name is required'),
  contact_number: Yup.string().required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  scheduled_date: Yup.string().required('Date is required').nullable().typeError("Date is invalid"),
  email_id: Yup.string().required("Email Id is required").matches(emailRegExp, 'Email Id is not valid'),
  scheduled_date_time: Yup.string().nullable().when(
    'scheduled_date',
    (scheduled_date, schema) => {
      if (scheduled_date && ReturnBoolean(scheduled_date)) {
        return (schema.required("Time is Required"))
      }
      return schema
    }
  ),

});


export const InviteHRFormValidation = Yup.object().shape({
  fname: Yup.string().required('First Name is required').matches(nameRegExp, "First Name can only contain alphabets"),
  lname: Yup.string().required('Last Name is required').matches(nameRegExp, "Last Name can only contain alphabets"),
  contact_number: Yup.string().required("Contact Number is required").matches(phoneRegExp, 'Contact Number is not valid').min(10, "Contact Number is not valid").max(10, "Contact Number is not valid"),
  email_id: Yup.string().required("Email Id is required").matches(emailRegExp, 'Email Id is not valid'),
});


export const customUserRoleValidation = Yup.object().shape({
  id: Yup.string().nullable(),
  name: Yup.string().required().max(50).matches(nameRegExp, "Please enter a valid Custom Role Name")
    .test({
      name: "duplicate",
      params: {},
      message: "Custom Role Name has already been taken",
      test: function (value) {
        const id = this.parent?.id
        return new Promise((resolve, reject) => {
          try {
            if (!nameRegExp.test(value)) {
              this.options.abortEarly = true
              return resolve(false)
            }
            if (value?.length >= 1) {
              axiosClient({
                url: `setting/validate-custom-user-role`,
                params: {
                  id,
                  name: value,
                },
              })
                .then(res => {
                  const { availability } = res.data;
                  resolve(availability);
                })
                .catch(error => {
                  reject(error)
                });
            }
          } catch (error) {
            reject(error);
          }
        })
      }
    })
    .label('Custom Role Name'),
  existing_role: Yup.object().nullable().required().label('Existing Role'),
})

export const BillingInfoValidation = Yup.object().shape({
  plan: Yup.string().nullable().required('Please select a plan'),
  gst: Yup.string().min(15, "Invalid GSTIN number").max(15, "Invalid GSTIN number").matches(gst, "Invalid GSTIN number"),
  address: Yup.string().nullable().required('This field is required'),
  city: Yup.object().required('City is required').typeError('City is required'),
  state: Yup.object().required('State is required').typeError('State is required'),
  pin_code: Yup.string().required("Pincode is required").min(6, "Pincode must be of 6 numbers").max(6, "Pincode must be of 6 numbers").matches(pincodeRegExp, 'Pincode is not valid'),
  minimum_employee: Yup.string().nullable().required('Please select a plan'),
  maximum_employee: Yup.string().nullable().required('Please select a plan'),
  noOfTalent: Yup.number().nullable().required('This field is required').when(
    ['minimum_employee', 'maximum_employee'],
    (minimum_employee, maximum_employee, schema) => {
      return (schema.min(minimum_employee).max(maximum_employee))
    }
  ).label('No. of Employee'),
})

export const AddMoreTalentValidation = Yup.object().shape({
  minimum_employee: Yup.string(),
  maximum_employee: Yup.string(),
  noOfTalent: Yup.number().nullable().required('This field is required').when(
    ['minimum_employee', 'maximum_employee'],
    (minimum_employee, maximum_employee, schema) => {
      return (schema.min(minimum_employee).max(maximum_employee))
    }
  ).label('No. of Talent')
})

const planValidation = Yup.object().shape({
  minimum_employee: Yup.string(),
  maximum_employee: Yup.string(),
  noOfTalent: Yup.number().nullable().required('This field is required').when(
    ['minimum_employee', 'maximum_employee'],
    (minimum_employee, maximum_employee, schema) => {
      return (schema.min(minimum_employee).max(maximum_employee))
    }
  ).label('No. of Employee')
})

export const PaymentDetailValidation = planValidation
// export const PaymentDetailValidation = Yup.object().shape({
//   plans: Yup.array().of(planValidation),
// })
