import React, { useEffect, useMemo, useState } from 'react'
import { Grid } from "@mui/material";
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { EFieldLabel, ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { ECardBorderLess } from '../../BaseComponents/ECard';
import { ETextFieldSearch } from '../../BaseComponents/ETextField';
import { ELink } from '../../BaseComponents/ELink';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { subscriptionPayment, getPlanDetails, completePlanPayment } from '../../action/OnBoardingAction';
import { useDispatch, useSelector } from 'react-redux';
import { getDuePayment } from '../../action/InvoiceAction';
import { calculatePlan, getBillTenure } from '../../utils/CommonFunctions';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { PaymentDetailValidation } from '../../utils/validation';
import { EPageWithBreadCrumbs } from '../../BaseComponents';
import { PaymentSummary } from './PaymentSummary';
import BillingInfo from '../../PageComponents/Accounts/BillingInfo';


function PaymentDetails() {

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.authReducer)

  const settingsContext = useCompanySettingProvider()
  const accountSetting = settingsContext?.setting?.accountSettingData?.companySetting
  const company = accountSetting?.company_data
  const activePlan = accountSetting?.activePlan

  const { duePaymentData } = useSelector(state => state.InvoiceReducer)

  // const { subscription, calculatePlan, calculationObj } = useCalculatePlan()
  // const { amountWithGST, planCost, total } = {}
  const { subscriptionPlanData: subscriptions } = useSelector((state) => state.SubscriptionPlanReducer)
  let selectedPlan = subscriptions?.[0]
  console.log("selectedPlan", selectedPlan);

  const formik = useFormik({
    validateOnChange: true,
    // InitialValues for input fields
    initialValues: {
      // plans: duePaymentData,
      ...subscriptions?.[0],
      plan: selectedPlan?.id || 0,
      noOfTalent: selectedPlan?.minimum_employee ? selectedPlan?.minimum_employee : 1,
      plan_data: selectedPlan ? selectedPlan : [],
      plan_cost: 0,
      gst_total: 0,
      gst: '',
      igst: '',
      cgst: '',
      sgst: '',
      address: '',
      state: null,
      city: null,
      pin_code: '',
      grand_total: 0,
      minimum_employee: selectedPlan?.minimum_employee ? selectedPlan?.minimum_employee : 1,
      maximum_employee: selectedPlan?.maximum_employee ? selectedPlan?.maximum_employee : 999,
    },
    enableReinitialize: true,
    // Validation for input fields
    validationSchema: PaymentDetailValidation,
    // onSubmit action
    onSubmit: (data) => {
      const payload = {
        id: activePlan?.id,
        plan_id: data?.id,
        no_of_talents: data?.noOfTalent,
      }
      console.log("payload", payload)
      dispatch(subscriptionPayment(auth.authtoken, payload, (res) => {
        const _payload = {
          token: res.data.token,
          status: true,
        }
        dispatch(completePlanPayment(auth.authtoken, _payload,
          (res) => {
            navigate("/payment-success", {
              state: {
                redirectedTo: "Subscription Details",
                redirectedToPath: "/my-subscription",
              }
            })
          },
          (error) => {
            navigate("/payment-failure", {
              state: {
                redirectedTo: "Subscription Details",
                redirectedToPath: "/my-subscription",
              }
            })
          }
        ))
      }));
    },
  });

  // Formik Props
  const { values, setFieldValue, errors } = formik;
  console.log("errrr", errors, values);

  useEffect(() => {
    if (auth?.authtoken) {
      dispatch(getPlanDetails(1))
    }
  }, [auth?.authtoken]);

  useEffect(() => {
    if (auth.authtoken) {
      dispatch(getDuePayment(auth.authtoken));
    }
  }, [auth.authtoken, location?.state])

  return (

    <EPageWithBreadCrumbs
      title="Payment Details"
      pageHeading="Subscription Details"
      breadcrumbsTitle="Payment Details"
      breadcrumbsLinks={[
        { name: "Dashboard", href: "/" },
        { name: "Profile", href: "" },
        { name: "My Subscription", href: "/my-subscription" },
        { name: "Payment Details", href: "" },
      ]}
      // loading={globalSettingLoading || Object.keys(globalSettingData || {}).length === 0}
      hideDivider={true}
    >
      {/* Container */}
      <EBoxPage>
        <FormikProvider value={formik}>
          <Form noValidate>
            <Grid container spacing={2} style={{ padding: 0 }}>
              {/*  Payment Information */}
              <Grid item sm={12} md={7} lg={7} xl={8}>
                <ECardBorderLess className='p-34px'>
                  <ETypography className='font-size-20px theme-color-static bold-400' mb={2}>
                    Subscription Information
                  </ETypography>

                  {/* Subscription Type  */}
                  {
                    activePlan &&
                    <SubscriptionBox
                      company={company}
                      plan={selectedPlan}
                      inputName={selectedPlan?.name}
                      infoText={`*You can’t go less than ${selectedPlan?.minimum_employee}, as there were ${selectedPlan?.minimum_employee} active talents in previous month. However, you can increase number of talent anytime.`}
                      formik={formik}
                      index={0}
                    />
                  }
                  {/* Billing Address */}
                  <BillingInfo />
                </ECardBorderLess>
              </Grid>

              {/*   Payment Summary  */}
              <Grid item sm={12} md={5} lg={5} xl={4}>
                <PaymentSummary
                  data={values}
                  skipButton={
                    <ETypography mt={1} className='text-align' >
                      <ELink
                        onClick={() => navigate(-1)}
                        className=" bold-400 font-size-12px">
                        Go Back
                      </ELink>
                    </ETypography>
                  }
                />
              </Grid>
            </Grid>
          </Form>

        </FormikProvider>

      </EBoxPage>

    </EPageWithBreadCrumbs>
  )
}

export default PaymentDetails


const SubscriptionBox = ({ plan, infoText, formik, index, company }) => {
  const { values, touched, errors, setFieldValue } = formik
  console.log(values, "------------------")

  return (
    <ECardBorderLess className='Bg-card4 mb-16px'>
      <Grid container padding={2} spacing={1} justifyContent={'space-between'}>

        <Grid item sm={6}>
          <ETypography className='font-size-14px bold-600'>
            Subscription Type
            <br />
            <span className='bold-400 greyColor5-color'>
              {plan?.plan_name}
            </span>
          </ETypography>
        </Grid>

        <Grid item sm={6}>
          <ETypography className='font-size-14px bold-600 '>
            Bill Tenure
            <br />
            <span className='bold-400 greyColor5-color'>
              {getBillTenure(plan?.plan_time_frequency_type, plan?.plan_valid_from, plan?.plan_valid_to)}
            </span>
          </ETypography>
        </Grid>
      </Grid>

      {/* No. of Employee */}
      <Grid item sm={6} paddingX={2} marginBottom={0} container flexDirection={'column'}>
        <Grid item>
          <EFieldLabel className='font-size-14px bold-600'>No. of Employee</EFieldLabel>
        </Grid>

        <Grid item>
          <ETextFieldSearch
            value={values?.noOfTalent}
            onChange={(e) => {
              const noOfTalent = e.target.value
              setFieldValue(`is_additional`, values?.is_additional)
              if (Number(e.target.value) > values?.maximum_employee) return
              setFieldValue(`noOfTalent`, e.target.value)
              const data = calculatePlan(values, noOfTalent, company?.permanentState?.id)

              setFieldValue(`gst_total`, data?.amountWithGST)
              setFieldValue(`plan_cost`, data?.planCost)
              setFieldValue(`grand_total`, data?.total)
              setFieldValue(`igst`, data?.igst)
              setFieldValue(`cgst`, data?.cgst)
              setFieldValue(`sgst`, data?.sgst)
            }}
            type='number'
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: values?.minimum_employee, max: values?.maximum_employee }}
            error={Boolean(touched?.noOfTalent && errors?.noOfTalent)}
            helperText={touched?.noOfTalent && errors?.noOfTalent}
          />
        </Grid>
      </Grid>
      {
        // values?.plans?.length - 1 !== index &&
        <ETypography marginTop={0} className='pl-16px font-size-12px bold-400'>
          {infoText}
        </ETypography>
      }
    </ECardBorderLess>

  )
}



