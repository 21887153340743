
import { Card, styled } from "@mui/material";

export const ECard = styled(Card)(({ theme }) => ({
    // padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.background.card,
    border: `0.5px solid ${theme.palette.border.light} !important`,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px ',
    height: 'auto',
    padding: '20px', 
    marginBottom:'25px',
}));

export const ECardHR = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.card7,
    border: `0.5px solid ${theme.palette.border.light} !important`,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px ',
    height: 'auto',
    padding: '15px', 
    marginBottom:'10px',
}));

export const ECardBorderLess = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.card,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px ',
    height: 'auto',
    padding: '16px',
}));


export const ECard2 = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.modalbg,
    border: `0.5px solid ${theme.palette.border.light} !important`,
    borderRadius: '12px',
    height: 'auto',
    padding: '20px'
}));

export const ECardBasic = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.card,
    border: `0.5px solid ${theme.palette.border.light}`,
    borderRadius: '12px',
    boxShadow: '0 0 0 0px ',
    height: 'auto',
    padding: '20px', 
    marginBottom:'25px',
}));

export const ECardDashboard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.default2,
    border: `0.5px solid ${theme.palette.border.border2}`,
    padding: '16px', 

}));

export const ECardBlank = styled(Card)(({ theme }) => ({

}));

export const ECardBirthday = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.birthdayBg,
    border: `0.5px solid ${theme.palette.border.birthdayBorder}`,
    padding: '0',
    borderRadius: '12px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    maxWidth: '909px',
    maxHeight: '1024px',
    height: '100%',
    zIndex: 0,
    [theme.breakpoints.up(1800)] : {
        maxWidth: '74.438rem',
    },
}));

