import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from '../../BaseComponents/EButtons';
import { EHelperText, ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { CalendarYear, FinancialYear, TenureOptions, SET_LEAVE_SETTING_API, XL, TIME_FRAME_OPTIONS, PERMISSIONS } from '../../constants';
import moment from 'moment';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { ELabelInputMainGrid, LoadingGrid } from '../../BaseComponents/EGrid';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextFieldSmall } from '../../BaseComponents/ETextField';
import { Form, FormikProvider, useFormik } from 'formik';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { leaveCycleValidation } from '../../utils/validation';
import { Stack } from '@mui/system';
import { ReturnBoolean2, getTenure } from '../../utils/CommonFunctions';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetLeaveSetting } from '../../action/SettingAction';
import { useCheckPermission } from '../../hooks';
import { useState } from 'react';
import EModal from '../../BaseComponents/EModal';


const initialModal = {
    open: false,
    onClick: () => null,
    header: '',
    body: <></>,
    loading: false,
}

//INFO: This component is for the Leave Setting under Company Settings.                                                                             
function LeaveSettings(props) {

    const { auth, companyData } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const settingData = useSelector((state) => state.SettingReducer);
    const settingsContext = useCompanySettingProvider();
    const company = useSelector(state => state.CompanyReducer)

    const leaveSettings = settingsContext?.setting?.leaveSetting ? settingsContext?.setting?.leaveSetting : {}

    const formik = useFormik({
        initialValues: {
            is_default_setting: leaveSettings?.is_default_setting ? leaveSettings?.is_default_setting : false,

            //leave settings
            leave_from_month: null,
            leave_to_month: null,
            leave_allowed_per_month: '',
            leave_allowed_per_tenure: '',
            leave_tenure_category: null,
            duration_string: '',

            auto_leave_approval_timeframe: null,
            enable_auto_leave_approval: null,
            enable_leave_application_reason: null,
            enable_leave_cycle: null,
            enable_multi_level_leave_approval: null,
            enable_weekly_off: null,
            enable_paid_leave_notice_period: null,
            enable_half_day_leave_request: null,
            enable_paid_unpaid_option : null,
        },
        validationSchema: leaveCycleValidation,
        onSubmit: (data) => {
            handleSave()
        }
    });

    const { errors, touched, getFieldProps, setFieldValue, handleSubmit, values, setFieldTouched } = formik;

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditLeaveSettings])
    const disableFields = !allowEdit

    const [isFormChanged, setIsFormChanged] = useState(Object.keys(touched).length > 0)
    const [modal, setModal] = useState(initialModal)

    useEffect(() => {
        setIsFormChanged(Object.keys(touched).length > 0)
    }, [touched])

    //INFO: This function will be called if we change the tenure
    const handleTenureChange = (tenure_id) => {
        let duration_string = ''
        let from_date = null;
        let to_date = null;
        let duration = 0;
        if (tenure_id) {
            from_date = new Date();
            to_date = new Date();

            if (tenure_id == FinancialYear || tenure_id == CalendarYear) {
                if (tenure_id == FinancialYear) {
                    from_date = moment(moment().startOf('year')).add(3, 'M').toDate();
                    to_date = moment(moment(from_date).add(11, 'M')).endOf('month').toDate();
                    duration = 12;
                }
                if (tenure_id == CalendarYear) {
                    from_date = moment().startOf('year').toDate();
                    to_date = moment().endOf('year').toDate();
                    duration = 12;
                }
                // setShowFromToDate(false);
                duration_string = moment(from_date).format('MMMM YYYY') + ' to ' + moment(to_date).format('MMMM YYYY')                           // The following if condition is both- for financial year and calender year as well.
            }
            if (tenure_id >= 3) {

                // as per discussion with Diksha logic starts with current
                // from_date = moment(companyMinDate).startOf('month').toDate();
                from_date = moment().startOf('month').toDate();

                if (companyData?.updated_by) {
                    from_date = moment().startOf('month').toDate();
                }
                to_date = moment(moment(from_date).add((tenure_id - 3), 'M')).endOf('month').toDate();
                duration = (tenure_id - 2);
                // setShowFromToDate(true);
            }
            duration_string = moment(from_date).format('MMM YYYY') + ' to ' + moment(to_date).format('MMM YYYY')
        } else {
            // setShowFromToDate(false);
        }

        setFieldValue('duration_string', duration_string);
        setFieldValue('leave_from_month', from_date);
        setFieldValue('leave_to_month', to_date);
        setFieldValue('duration', duration);
        setFieldValue('leave_allowed_per_tenure', 0)
        setFieldValue('leave_allowed_per_month', 0)
    };

    // INFO: calling the ValueSettingFunction for setting the values from API (Initializing)
    useEffect(() => {
        if (settingData?.settingLoading == false && settingsContext?.setting?.settingLoading == false && Object.keys(leaveSettings).length != 0) {
            const setting_data = leaveSettings?.companySetting ?? {};
            ValueSettingFunction(setting_data, null)
        }
    }, [leaveSettings.is_default_setting, settingData?.settingLoading]);

    // INFO: This function is for setting the values in formik
    const ValueSettingFunction = (data, isSubmit) => {
        if (data) {
            const { leave_from_month, leave_to_month, leave_allowed_per_month, leave_allowed_per_tenure, leave_tenure_category,
                auto_leave_approval_timeframe, enable_leave_cycle, enable_leave_application_reason,
                enable_multi_level_leave_approval, enable_auto_leave_approval, enable_weekly_off, enable_paid_leave_notice_period,
                enable_paid_unpaid_option , enable_half_day_leave_request
            } = data

            setFieldValue('leave_from_month', leave_from_month ? leave_from_month : null)
            setFieldValue('leave_to_month', leave_to_month ? leave_to_month : null)
            setFieldValue('leave_allowed_per_month', leave_allowed_per_month ? leave_allowed_per_month : '')
            setFieldValue('leave_allowed_per_tenure', leave_allowed_per_tenure ? leave_allowed_per_tenure : '')
            setFieldValue('duration_string', leave_to_month && leave_from_month ? getTenure(leave_from_month, leave_to_month) : '')
            // INFO: setting duration according to two conditions
            if (leave_tenure_category && [1, 2].includes(leave_tenure_category)) { setFieldValue('duration', 12) }
            else { setFieldValue('duration', leave_tenure_category ? (leave_tenure_category - 2) : null) }
            // INFO: searching for tenure option
            const selected_leave_tenure_category = leave_tenure_category ?
                TenureOptions?.find((element) => element.id == leave_tenure_category) : null;
            setFieldValue('leave_tenure_category', selected_leave_tenure_category)
            // INFO: searching for time frame option
            const selected_timeframe = auto_leave_approval_timeframe ?
                TIME_FRAME_OPTIONS?.find((element) => element.id == auto_leave_approval_timeframe) : null;
            setFieldValue('auto_leave_approval_timeframe', selected_timeframe)
            setFieldValue('enable_leave_cycle', enable_leave_cycle)
            // INFO: setting this variable true by default as per @dharam sir on 29th April,23 
            setFieldValue('enable_leave_application_reason', enable_leave_application_reason)
            setFieldValue('enable_multi_level_leave_approval', enable_multi_level_leave_approval)
            setFieldValue('enable_auto_leave_approval', enable_multi_level_leave_approval) //enable_auto_leave_approval
            setFieldValue('enable_weekly_off', enable_weekly_off)
            setFieldValue('enable_paid_leave_notice_period', enable_paid_leave_notice_period)
            setFieldValue('enable_paid_unpaid_option', enable_paid_unpaid_option )
            setFieldValue('enable_half_day_leave_request', enable_half_day_leave_request)

            if (isSubmit == null) {  //means this function is called by 1st time useEffect
                setFieldValue('is_default_setting', leaveSettings?.is_default_setting);
            }
        }
    }

    const handleDefaultApply = () => {
        setModal({
            open: true,
            onClick: () => {
                const Data = {
                    ...leaveSettings?.companyDefaultSetting,
                    is_default_setting: true
                }
                dispatch(SetLeaveSetting(auth.authtoken, Data, SET_LEAVE_SETTING_API, navigate));
            },
            header: 'Default Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    This will restore all settings to their default values.
                </ETypography>
                <ETypography className='font-size-16px'>
                    Any changes you've made will be lost!
                </ETypography>
                <ETypography className='font-size-16px'>
                    Do you want to apply default settings?
                </ETypography>
            </>
        })
    }

    const closeModal = () => {
        setModal(initialModal)
    }

    const handleSave = () => {
        setModal({
            open: true,
            onClick: () => {
                saveSetting()
                closeModal()
            },
            header: 'Custom Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    Are you sure! you want to save these changes in
                    {" "}
                    <span className='theme-main-text-color-bold'>
                        Leave
                    </span>
                    {" "}
                    settings?
                </ETypography>
            </>
        })
    }

    const saveSetting = () => {
        const data = {
            ...values,
            leave_tenure_category: values.leave_tenure_category && values.leave_tenure_category?.id ? values.leave_tenure_category.id : null
        };
        dispatch(SetLeaveSetting(auth.authtoken, data, SET_LEAVE_SETTING_API, navigate));
    }


    const handleCancel = () => {
        formik.resetForm()
        setIsFormChanged(false)
        ValueSettingFunction(leaveSettings?.companySetting, false)
    }
    const handleChange = (field, value) => {
        setFieldTouched(field, true)
        setFieldValue(field, value)
        setIsFormChanged(true)
    }


    return (
        <Page title="Leave Settings" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Leave'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Company Settings', href: '' },
                            {
                                name: 'Leave',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate('/')} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                spacing={3}
                sx={{ paddingTop: { xs: '1rem', md: '0' } }}
            >
                <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
                    <ETypographyPageHeading >
                        Leave
                    </ETypographyPageHeading>

                    <EHidden width="mdUp">
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                    </EHidden>
                </Grid>

                <Grid item xs={12} sm={12} >
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            {settingData?.settingLoading ?
                                <LoadingGrid size={XL} isPage={true} />
                                :
                                <EBoxPage className='p0'>

                                    <Grid container className='mb-16px justify-content-center' spacing={2}>

                                        {/* Card 1 - Leave Cycle */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <CustomizedGrid label='Leave Cycle' >
                                                <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className='py0 text-align-right' >
                                                    <FormControl component="fieldset" {...getFieldProps('enable_leave_cycle')} >
                                                        <ERadioGroup row={true} aria-label="enable_leave_cycle" name="enable_leave_cycle"
                                                            value={formik?.values?.enable_leave_cycle != null ? ReturnBoolean2(formik?.values?.enable_leave_cycle) : null}>
                                                            <FormControlLabel value={true}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_leave_cycle', true)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                            <FormControlLabel value={false}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_leave_cycle', false)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                        </ERadioGroup>
                                                    </FormControl>
                                                    {errors?.enable_leave_cycle && values?.enable_leave_cycle == null ?
                                                        <EHelperText className='color-text-error-red '>  {errors?.enable_leave_cycle}</EHelperText> : ''}
                                                </Grid>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pt-0'>
                                                    <span className='greyColor4-color line-height-140perc'>
                                                        Please note that leave cycle will auto renew after every tenure based on the tenure/paid leave granted.
                                                    </span>
                                                </Grid>
                                                {ReturnBoolean2(formik?.values?.enable_leave_cycle) ? <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <ECardDashboard className={'Bg-card5 border-none shadow-none px-18px py-18px mt-8px'}  >
                                                            <Grid container spacing={2}>
                                                                <LeaveGrid label='Tenure' helpText='Timeframe between which the paid leave will be granted to the employees.'>
                                                                    <EAutocomplete
                                                                        className="width-200px"
                                                                        disabled={disableFields ? true : false}
                                                                        name="leave_tenure_category"
                                                                        {...getFieldProps('leave_tenure_category')}
                                                                        forcePopupIcon={formik?.values?.leave_tenure_category ? false : true}
                                                                        options={TenureOptions?.length > 0 ? TenureOptions : []}
                                                                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                                                        onChange={(e, value) => {
                                                                            handleChange('leave_tenure_category', value ? value : null);
                                                                            handleTenureChange(value && value?.id ? value?.id : '')
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <ETextFieldSmall
                                                                                {...params}
                                                                                name="leave_tenure_category"
                                                                                placeholder="Select tenure"
                                                                                error={Boolean(touched.leave_tenure_category && errors.leave_tenure_category)}
                                                                                helperText={touched.leave_tenure_category && errors.leave_tenure_category}
                                                                                size={'small'}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LeaveGrid>
                                                                <LeaveGrid label='Duration' helpText='Timeframe of the selected tenure.'>
                                                                    <ETextFieldSmall
                                                                        className="width-200px"
                                                                        disabled={true}
                                                                        autoComplete="duration_string"
                                                                        {...getFieldProps('duration_string')}
                                                                        inputProps={{ maxLength: 6 }}
                                                                        error={Boolean(touched.duration_string && errors.duration_string)}
                                                                        helperText={touched.duration_string && errors.duration_string}
                                                                        size={'small'}
                                                                    />

                                                                </LeaveGrid>

                                                                {/* {(getFieldProps('leave_tenure_category') &&
                                                                    getFieldProps('leave_tenure_category').value?.id >= 3) ? */}
                                                                <LeaveGrid label='Paid Leave' smallLabel='per tenure' helpText='Total number of paid leaves granted to the employees in a particular tenure.'>
                                                                    <ETextFieldSmall
                                                                        fullWidth
                                                                        autoComplete="leave_allowed_per_tenure"
                                                                        disabled={disableFields ? true : false}
                                                                        className="width-200px"
                                                                        {...getFieldProps('leave_allowed_per_tenure')}
                                                                        // disabled={!(getFieldProps('leave_tenure_category') &&
                                                                        //     getFieldProps('leave_tenure_category').value?.id >= 3)}
                                                                        error={Boolean(touched.leave_allowed_per_tenure && errors.leave_allowed_per_tenure)}
                                                                        helperText={touched.leave_allowed_per_tenure && errors.leave_allowed_per_tenure}
                                                                    />
                                                                </LeaveGrid>
                                                                {/* : //Commenting this as per discussed with @dharam Sir & @Shweta's requirement on 24th April 2022
                                                                    <LeaveGrid label='Paid Leave' smallLabel='per month'
                                                                        helpText='Total number of paid leaves granted to the talents in a month.'>
                                                                        <ETextFieldSmall
                                                                            className="width-200px "
                                                                            disabled={(getFieldProps('leave_tenure_category') &&
                                                                                getFieldProps('leave_tenure_category').value?.id >= 3)}
                                                                            autoComplete="leave_allowed_per_month"
                                                                            {...getFieldProps('leave_allowed_per_month')}
                                                                            inputProps={{ maxLength: 6 }}
                                                                            onKeyUp={(e) => {
                                                                                handleLeaveCount(e)
                                                                            }}
                                                                            error={Boolean(touched.leave_allowed_per_month && errors.leave_allowed_per_month)}
                                                                            helperText={errors?.leave_allowed_per_month ? errors?.leave_allowed_per_month :
                                                                                <ETypography variant='small' className='greyColor4-color font-style-italic '>
                                                                                    * 0.5 indicates half day
                                                                                </ETypography>}
                                                                        />
                                                                    </LeaveGrid>
                                                                }  */}
                                                                <LeaveGrid label='Grant paid leaves under notice period' helpText='By enabling this setting employee can consume paid leaves while serving notice period.'>
                                                                    <FormControl component="fieldset" {...getFieldProps('enable_paid_leave_notice_period')} >
                                                                        <ERadioGroup row={true} aria-label="enable_paid_leave_notice_period" name="enable_paid_leave_notice_period"
                                                                            value={formik?.values?.enable_paid_leave_notice_period != null ? ReturnBoolean2(formik?.values?.enable_paid_leave_notice_period) : null}>
                                                                            <FormControlLabel
                                                                                disabled={disableFields ? true : false}
                                                                                value={true} control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_paid_leave_notice_period', true)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                                            <FormControlLabel value={false}
                                                                                disabled={disableFields ? true : false}
                                                                                control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_paid_leave_notice_period', false)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                                        </ERadioGroup>
                                                                    </FormControl>
                                                                    {errors?.enable_paid_leave_notice_period && !formik?.values?.enable_paid_leave_notice_period ?
                                                                        <EHelperText className='color-text-error-red'> {errors?.enable_paid_leave_notice_period}  </EHelperText> : ''}
                                                                </LeaveGrid>

                                                                {/* ======================================================================================= */}
                                                                <LeaveGrid label='Allow half-day leave requests' helpText='On enabling this setting, Entera allow the employee to apply for half day leave.'>
                                                                    <FormControl component="fieldset" {...getFieldProps('enable_half_day_leave_request')} >
                                                                        <ERadioGroup row={true} aria-label="enable_half_day_leave_request" name="enable_half_day_leave_request"
                                                                            value={formik?.values?.enable_half_day_leave_request != null ? ReturnBoolean2(formik?.values?.enable_half_day_leave_request) : null}>
                                                                            <FormControlLabel
                                                                                disabled={disableFields ? true : false}
                                                                                value={true} control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_half_day_leave_request', true)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                                            <FormControlLabel value={false}
                                                                                disabled={disableFields ? true : false}
                                                                                control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_half_day_leave_request', false)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                                        </ERadioGroup>
                                                                    </FormControl>
                                                                    {errors?.enable_half_day_leave_request && !formik?.values?.enable_half_day_leave_request ?
                                                                        <EHelperText className='color-text-error-red'> {errors?.enable_half_day_leave_request}  </EHelperText> : ''}
                                                                </LeaveGrid>

                                                                
                                                                {/* ======================================================================================= */}

                                                                <LeaveGrid label='Allow  employees to select between Paid & Unpaid leaves' helpText='On enabling this setting, Entera allow the employee to switch between paid & unpaid leave.'>
                                                                    <FormControl component="fieldset" {...getFieldProps('enable_paid_unpaid_option')} >
                                                                        <ERadioGroup row={true} aria-label="enable_paid_unpaid_option" name="enable_paid_unpaid_option"
                                                                            value={formik?.values?.enable_paid_unpaid_option != null ? ReturnBoolean2(formik?.values?.enable_paid_unpaid_option) : null}>
                                                                            <FormControlLabel
                                                                                disabled={disableFields ? true : false}
                                                                                value={true} control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_paid_unpaid_option', true)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                                            <FormControlLabel value={false}
                                                                                disabled={disableFields ? true : false}
                                                                                control={
                                                                                    <ERadio className=' px-6px py0'
                                                                                        onChange={(e, value) => {
                                                                                            handleChange('enable_paid_unpaid_option', false)
                                                                                        }}
                                                                                    />
                                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                                        </ERadioGroup>
                                                                    </FormControl>
                                                                    {errors?.enable_paid_unpaid_option && !formik?.values?.enable_paid_unpaid_option ?
                                                                        <EHelperText className='color-text-error-red'> {errors?.enable_paid_unpaid_option}  </EHelperText> : ''}
                                                                </LeaveGrid>
                                                                
                                                            </Grid>
                                                        </ECardDashboard>
                                                    </Grid>
                                                </>
                                                    : ''}
                                            </CustomizedGrid>
                                        </Grid>




                                        {/* Card 3- Allow half-day leave requests */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <CustomizedGrid label='Employee must enter a reason when applying for leave' >
                                                <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className='py0 text-align-right' >
                                                    <FormControl component="fieldset" {...getFieldProps('enable_leave_application_reason')} >
                                                        <ERadioGroup row={true} aria-label="enable_leave_application_reason" name="enable_leave_application_reason"
                                                            value={formik?.values?.enable_leave_application_reason != null ? ReturnBoolean2(formik?.values?.enable_leave_application_reason) : null}>
                                                            <FormControlLabel value={true}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_leave_application_reason', true)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                            <FormControlLabel value={false}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_leave_application_reason', false)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                        </ERadioGroup>
                                                    </FormControl>
                                                    {errors?.enable_leave_application_reason && values?.enable_leave_application_reason == null ?
                                                        <EHelperText className='color-text-error-red '>  {errors?.enable_leave_application_reason}</EHelperText> : ''}
                                                </Grid>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pt-0'>
                                                    <span className='greyColor4-color'>
                                                        On enabling this setting you make it complusory for employee to state the reason in leave application form.
                                                    </span>
                                                </Grid>
                                            </CustomizedGrid>
                                        </Grid>

                                        {/* Card 4- Multi Level Approval */}
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <CustomizedGrid label='Multi Level Approval' >
                                                <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className='py0 text-align-right' >
                                                    <FormControl component="fieldset" {...getFieldProps('enable_multi_level_leave_approval')} >
                                                        <ERadioGroup row={true} aria-label="enable_multi_level_leave_approval" name="enable_multi_level_leave_approval"
                                                            value={formik?.values?.enable_multi_level_leave_approval != null ? ReturnBoolean2(formik?.values?.enable_multi_level_leave_approval) : null}>
                                                            <FormControlLabel value={true}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_multi_level_leave_approval', true)
                                                                            setFieldValue('enable_auto_leave_approval', true)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>Yes</span>} />
                                                            <FormControlLabel value={false}
                                                                disabled={disableFields ? true : false}
                                                                control={
                                                                    <ERadio className=' px-6px py0'
                                                                        onChange={(e, value) => {
                                                                            handleChange('enable_multi_level_leave_approval', false)
                                                                            setFieldValue('enable_auto_leave_approval', false)
                                                                        }}
                                                                    />
                                                                } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                        </ERadioGroup>
                                                    </FormControl>
                                                    {errors?.enable_multi_level_leave_approval && values?.enable_multi_level_leave_approval == null ?
                                                        <EHelperText className='color-text-error-red '>  {errors?.enable_multi_level_leave_approval}</EHelperText> : ''}
                                                </Grid>
                                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pt-0'>
                                                    <span className='greyColor4-color'>
                                                        On enabling this setting Entera get the leave approved from reporting manager & HR respectively. If not, only HR gets to take action on leaves.
                                                    </span>
                                                </Grid>
                                                {ReturnBoolean2(values?.enable_auto_leave_approval) ? <>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <ECardDashboard className={'Bg-card5 border-none shadow-none mt-8px'} >

                                                            {/* Auto leave Approval: Changes As per Dharam sir */}
                                                            <ETypography className='bold-600 mb-4px'>Auto leave Approval</ETypography>
                                                            <ETypography className='greyColor4-color line-height-140perc mb-16px'>
                                                                Set a timeframe for auto approval of leave in cases where the HR fails to take any action on the applied leave.<br />
                                                                Note: In case of Reporting Manager, the leave will get auto rejected if no action is taken by the application date.
                                                            </ETypography>
                                                            <ELabelInputMainGrid label={'Timeframe'} isfullgrid={true} labelPT={'0.5rem'}>
                                                                <EAutocomplete
                                                                    name="Leave Type"
                                                                    {...getFieldProps('auto_leave_approval_timeframe')}
                                                                    options={TIME_FRAME_OPTIONS ? TIME_FRAME_OPTIONS : []}
                                                                    forcePopupIcon={formik?.values?.auto_leave_approval_timeframe && formik?.values?.auto_leave_approval_timeframe.length > 0 ? false : true}
                                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                                    className='width-200px'
                                                                    disabled={disableFields ? true : false}
                                                                    onChange={(e, value) => {
                                                                        handleChange('auto_leave_approval_timeframe', value ? value : null)
                                                                    }}
                                                                    renderInput={params => (
                                                                        <ETextFieldSmall
                                                                            {...params}
                                                                            name='auto_leave_approval_timeframe'
                                                                            placeholder="Select Timeframe"
                                                                            error={Boolean(touched.auto_leave_approval_timeframe && errors.auto_leave_approval_timeframe)}
                                                                            helperText={touched.auto_leave_approval_timeframe && errors.auto_leave_approval_timeframe}
                                                                            size={'small'}
                                                                        />
                                                                    )}
                                                                />
                                                            </ELabelInputMainGrid>
                                                        </ECardDashboard>
                                                    </Grid>
                                                </>
                                                    : ''}
                                            </CustomizedGrid>
                                        </Grid>
                                    </Grid>
                                    {
                                        company?.companyData?.onBoarding?.onboarding_is_completed
                                            ?
                                            allowEdit &&
                                            <EBox display={"flex"} justifyContent={"space-between"}>
                                                <EButtonOutlined variant="outlined" onClick={handleDefaultApply}>
                                                    Apply Default Setting
                                                </EButtonOutlined>
                                                <EBox>
                                                    {
                                                        isFormChanged &&
                                                        <EButtonOutlined variant="outlined" onClick={handleCancel}>
                                                            Cancel
                                                        </EButtonOutlined>
                                                    }
                                                    <ELoadingButton disabled={!isFormChanged} type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                                                        Save
                                                    </ELoadingButton>
                                                </EBox>
                                            </EBox>
                                            :
                                            <Stack direction="row" className='justify-content-flex-end '>
                                                <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                                                    Save & Next
                                                </ELoadingButton>

                                            </Stack>
                                    }
                                </EBoxPage>
                            }
                        </Form>
                    </FormikProvider>
                </Grid>
            </Grid>
            {/* INFO: MODAL to confirm form save */}
            <EModal open={modal?.open} headervalue={modal?.header}>
                {modal?.body}
                <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
                    <EButtonOutlined size="large" variant="outlined" onClick={closeModal} color="secondary">No</EButtonOutlined>
                    <ELoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={modal?.onClick}
                    >
                        Yes
                    </ELoadingButton>
                </Stack>
            </EModal>
        </Page>
    );
}
export default LeaveSettings

// INFO: This provides grid for Leave Label-child pair
const LeaveGrid = (props) => {
    const { label, helpText, children, smallLabel = null } = props
    return (
        <>
            <Grid item xl={9.7} lg={9} md={9} sm={6.8} xs={12}>
                <span className='bold-600'>{label}</span>
                <span className='bold-400'>{smallLabel ? ' (' + smallLabel + ')' : ''}</span>
                <br />
                <span className='font-size-16px greyColor4-color'>{helpText}</span>
            </Grid>
            <Grid item xl={2.3} lg={3} md={3} sm={5.2} xs={12} align={'right'} className='pl-0px'>
                {children}
            </Grid>
        </>
    )
}

const CustomizedGrid = (props) => {
    const { label, children, smallLabel = null } = props
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <ECardBasic className='mb-0px border-05px-border6 ' >
                    <Grid container >
                        <Grid item xl={8} lg={8} md={8} sm={6} xs={6} className='align-center display-flex'>
                            <span className='bold-600 font-size-18px'>{label}</span>
                            <span >{smallLabel ? ' (' + smallLabel + ')' : ''}</span>
                            {/* <br /> */}

                        </Grid>
                        {children}
                    </Grid>
                </ECardBasic>
            </Grid>
        </>
    )
}


// INFO: commented on 13/5/23, branch name: leave-employee-default-setting-12-5-23
//workingday setting
// workingDaySetting: {}


//INFO: This method is for handling the Leave count called by Paid Leave input
// const handleLeaveCount = (e) => {
//     const dur = getFieldProps('duration').value;
//     const monthly = e?.target?.value ? e?.target?.value : 0;
//     setFieldValue('leave_allowed_per_tenure', monthly > 0 && (monthly % 0.5 == 0) && dur ? (dur * monthly) : 0)
// }

// INFO: Setting array for weekly off table
// let workingDaySettingNew = JSON.stringify(workingDaySetting);
// setWorkDayTbl(JSON.parse(workingDaySettingNew))
// setFieldValue('workingDaySetting', JSON.parse(workingDaySettingNew));

// The following info is for weekly off
// <Grid item xs={12} sm={12} md={12} >
// <CustomizedGrid label='Weekly Off' >
//     <Grid item xl={4} lg={4} md={4} sm={6} xs={6} className='py0 text-align-right' >
//         <FormControl component="fieldset" {...getFieldProps('enable_weekly_off')} >
//             <ERadioGroup row={true} aria-label="enable_weekly_off" name="enable_weekly_off"
//                 value={formik?.values?.enable_weekly_off != null ? ReturnBoolean(formik?.values?.enable_weekly_off) : null}>
//                 <FormControlLabel value={true} 
//                 control={
//                     <ERadio className=' px-6px py0'
//                         onChange={(e, value) => {
//                             setFieldValue('enable_weekly_off', true)
//                         }}
//                     />
//                 } label={<span className='font-size-16px'>Yes</span>} />
//                 <FormControlLabel value={false}
//                 control={
//                     <ERadio className=' px-6px py0'
//                         onChange={(e, value) => {
//                             setFieldValue('enable_weekly_off', false)
//                         }}
//                     />
//                 } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
//             </ERadioGroup>
//         </FormControl>
//         {errors?.attendance_mode && !formik?.values?.attendance_mode ?
//             <EHelperText className='color-text-error-red'> {errors?.attendance_mode}  </EHelperText> : ''}
//     </Grid>
//     <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className='pt-0'>
//         <span className='greyColor4-color line-height-140perc'>
//             The setting will allow you to select the company-wide day off on weekly basis.
//         </span>
//     </Grid>
//     {ReturnBoolean(formik?.values?.enable_weekly_off) ? <>
//         <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
//             <ECardDashboard className={'Bg-card5 border-none shadow-none mt-8px'} >

//                 <ETableContainer>
//                     <ETable>
//                         <EListHead
//                             headLabel={TABLE_HEAD_WEEK}
//                             onRequestSort={() => null}
//                             authvar={{}}
//                         />
//                         <ETableBody>
//                             {workDayTbl &&
//                                 Object.keys(workDayTbl).map((row, index) => {
//                                     return (
//                                         <ETableRow hover key={index} tabIndex={-1}>
//                                             <ETableCell key={row} align="left">
//                                                 {row}{' '}
//                                             </ETableCell>
//                                             {workDayTbl[row] &&
//                                                 Object.keys(workDayTbl[row]).map((data, index) => {
//                                                     let finalData = workDayTbl[row][data];
//                                                     return (
//                                                         <ETableCell key={index} align="center">

//                                                             <FormControlLabel
//                                                                 control={
//                                                                     <Checkbox
//                                                                         checked={finalData.is_check == 1 ? true : false}
//                                                                         onChange={(e, value) => {
//                                                                             checkedArrayValue(finalData, value);
//                                                                         }}
//                                                                     />
//                                                                 }
//                                                                 label=""
//                                                             />
//                                                         </ETableCell>
//                                                     );
//                                                 })}
//                                         </ETableRow>
//                                     );
//                                 })}
//                         </ETableBody>
//                     </ETable>
//                 </ETableContainer>
//             </ECardDashboard>
//         </Grid>
//     </>
//         : ''}
// </CustomizedGrid>
// </Grid>

// const checkedArrayValue = (value, checkNew) => {
//     let booleanvalue = checkNew == true ? 1 : 0;
//     if (workDayTbl && workDayTbl[value?.day_name]) {
//         setWorkDayTbl((prevState) => {
//             const update = { ...prevState };
//             const item = update[value?.day_name][value?.week_no - 1];
//             update[value?.day_name][value?.week_no - 1] = { ...item, is_check: booleanvalue };
//             return update;
//         });
//     }
// };

// useEffect(() => {
//     setFieldValue('workingDaySetting', workDayTbl);
// }, [workDayTbl]);
