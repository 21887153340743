import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, FormControlLabel, FormGroup, Grid, Stack } from "@mui/material";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import EHidden from "../../BaseComponents/EHidden";
import Page from "../../BaseComponents/EPage";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { EIcon } from "../../BaseComponents/EIcon";
import { EDivider } from "../../BaseComponents/EDivider";
import { ECard, ECardDashboard } from "../../BaseComponents/ECard";
import BackIcon from "@iconify/icons-material-symbols/chevron-left-rounded";
import { useDispatch, useSelector } from "react-redux";
import { EBoxPage } from "../../BaseComponents/EBox";
import { ERadio, ERadioGroup } from "../../BaseComponents/ERadio";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { GET_NOTIFICATION_SETTING_API, OFF, ON, SET_NOTIFICATION_SETTING_API } from "../../constants";
import { ECheckbox } from "../../BaseComponents/ECheckbox";
import { GetUserSettings, SetUserSettings } from "../../action/UserSettingAction";
import { useFormik, Form, FormikProvider } from 'formik';
import { ReturnBoolean } from "../../utils/CommonFunctions";

function NotificationSetting() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.authReducer);
    const setting = useSelector((state) => state.UserSettingReducer);
    const [data, setData] = useState([])
    const [SettingCategories, setSettingCategories] = useState([])
    const default_setting_data = useRef()
    const notification_setting_Data = useRef()

    const formik = useFormik({
        initialValues: {
            notification_is_checked: true,
            notification_is_default: false,
            notification_setting_data: []
        },
        onSubmit: (Data) => {
            Data.notification_setting_data = JSON.stringify(data);
            dispatch(SetUserSettings(auth.authtoken, SET_NOTIFICATION_SETTING_API, Data, GET_NOTIFICATION_SETTING_API));
        }
    });

    const { handleSubmit, getFieldProps, setFieldValue } = formik;

    //INFO: To Call API initially
    useEffect(() => {
        dispatch(GetUserSettings(auth.authtoken, GET_NOTIFICATION_SETTING_API));
    }, []);

    //INFO: To initialize the array values
    useEffect(() => {
        if (setting?.userSettingData?.default_setting_data?.length > 0 && setting?.userSettingLoading == false) {
            let JsonDefault = JSON.stringify(setting?.userSettingData?.default_setting_data); //INFO: Converting to JSON data to remove state mutation
            let JsonData = JSON.stringify(setting?.userSettingData?.notification_setting_Data); //INFO: Converting to JSON data to remove state mutation

            default_setting_data.current = JSON.parse(JsonDefault)
            notification_setting_Data.current = JSON.parse(JsonData)
            //INFO: Setting initializing values
            onDefaultChange(setting?.userSettingData?.notification_is_default)
            setFieldValue('notification_is_checked', setting?.userSettingData?.notification_is_checked)

            //INFO: getting all the ntification_event_id in one
            var x = []; var xArrObj = [];
            notification_setting_Data.current?.map((row) => {
                if (!x.includes(row?.notification_event_id)) {
                    x.push(row?.notification_event_id)
                    xArrObj.push({ id: row?.notification_event_id, label: row?.notification_event })
                }
                return row
            })
            setSettingCategories(xArrObj)

        }
    }, [auth.authtoken, setting.userSettingLoading]);

    //INFO: To Update default notification check
    const onDefaultChange = (defaultValue) => {
        setFieldValue('notification_is_default', defaultValue)
        if (ReturnBoolean(defaultValue) == true) {
            setData(default_setting_data.current)
        } else {
            setData(notification_setting_Data.current)
        }
    }

    //INFO: To Update the setting checkbox
    const OnChangeSetting = (setting_id, index, key_var, value) => {
        setData((prevState) => {
            const update = [...prevState];
            if (update[index].setting_id == setting_id) {

                if (key_var == 'on_email') { update[index] = { ...update[index], on_email: value == true ? 1 : 0 } }
                else {
                    update[index] = { ...update[index], in_entera: value == true ? 1 : 0 }
                }
            }
            return update;
        })
    }

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Page title="Notification Settings">
                    {/* Breadcrumbs */}
                    <Grid container display="row" justifyContent="space-between">
                        <EHeaderBreadcrumbs
                            heading="Notification Settings"
                            links={[
                                { name: "Dashboard", href: "/" },
                                { name: "Notification Settings", href: "" },
                            ]}
                        />
                        <EHidden width="mdDown">
                            <Grid>
                                <EButtonOutlined
                                    className="button-left-margin"
                                    variant="outlined"
                                    onClick={() => navigate(-1)}
                                >
                                    {" "}
                                    Back{" "}
                                </EButtonOutlined>
                            </Grid>
                        </EHidden>
                    </Grid>

                    {/* Header */}
                    <Grid
                        container
                        display="row"
                        justifyContent="space-between"
                        className="pb-2rem margin-top-20px"
                    >
                        <Grid item>
                            <ETypographyPageHeading variant="h5">
                                Notification Settings
                            </ETypographyPageHeading>
                        </Grid>

                        <EHidden width="mdUp">
                            <Grid item>
                                <EButtonOutlinedIcon
                                    variant="outlined"
                                    onClick={() => navigate(-1)}
                                    className="button-left-margin"
                                >
                                    <EIcon icon={BackIcon} className='height-width-15px'/>{" "}
                                </EButtonOutlinedIcon>
                            </Grid>
                        </EHidden>

                        <EHidden width="mdDown">
                            <Grid lg={12} xs={12} item>
                                <EDivider className="margin-top-05" />
                            </Grid>
                        </EHidden>
                    </Grid>

                    {/* Page Container */}
                    <EBoxPage>
                        <ECard>
                            <Grid container spacing={1}>
                                <Grid item xs={12} >
                                    <ELabelInputMainGrid label={"Notifications"} isfullgrid={true} disableColon={true} childrenClassname={' text-align-right'} isNotForm={true}>
                                        <FormControl component="fieldset"  >
                                            <ERadioGroup row={true} aria-label="notification_is_checked" name="notification_is_checked"
                                                {...getFieldProps('notification_is_checked')}
                                            >
                                                <FormControlLabel value={ON} control={
                                                    <ERadio className='py-2px px-6px'
                                                        onChange={(e, value) => {
                                                            setFieldValue('notification_is_checked', ON)
                                                        }}
                                                    />
                                                } label={<span className='font-size-16px'>On</span>} />
                                                <FormControlLabel value={OFF} control={
                                                    <ERadio className='py-2px px-6px'
                                                        onChange={(e, value) => {
                                                            setFieldValue('notification_is_checked', OFF)
                                                        }}
                                                    />
                                                } label={<span className='font-size-16px'>Off</span>} className=' mr-0px' />
                                            </ERadioGroup>
                                        </FormControl>
                                    </ELabelInputMainGrid>
                                    <span className='greyColor4-color'>  Allow notifications either in Entera or by email. </span>
                                </Grid>
                                {ReturnBoolean(formik?.values?.notification_is_checked) == true ? <>
                                    <Grid item xs={12}>
                                        <FormGroup >
                                            <FormControlLabel control={
                                                <ECheckbox
                                                    checked={ReturnBoolean(formik?.values?.notification_is_default)}
                                                    {...getFieldProps('notification_is_default')}
                                                    onChange={(e, value) => {
                                                        onDefaultChange(value)
                                                    }} />
                                            }
                                                label="Default Notifications" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12}>

                                        {SettingCategories?.length > 0 && SettingCategories.map((mainID, index) => {

                                            return (

                                                <ECardDashboard className={'Bg-card5 border-none shadow-none mb-8px'} key={mainID?.label + index}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <GridElements label={mainID?.label} child2={'On Email'} child1={'In Entera'} labelClassName='bold-600' />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {data &&
                                                                data.map((row, index1) => {
                                                                    const { setting_title, setting_description, setting_id, notification_event_id, on_email, in_entera } = row

                                                                    if (notification_event_id == mainID?.id) {
                                                                        return (
                                                                            <GridElements
                                                                                label={setting_title}
                                                                                description={setting_description}
                                                                                labelClassName=' ml-10px'
                                                                                key={index1}
                                                                                child1={<FormGroup className='align-center '>
                                                                                    <FormControlLabel control={
                                                                                        <ECheckbox
                                                                                            checked={in_entera == 1 ? true : false}
                                                                                            onChange={(e, value) => {
                                                                                                // setFieldValue("from_date_is_half", value);
                                                                                                OnChangeSetting(setting_id, index1, 'in_entera', value)
                                                                                            }}
                                                                                            disabled={formik?.values?.notification_is_default ?
                                                                                                Boolean(formik?.values?.notification_is_default) : false}
                                                                                        />
                                                                                    }
                                                                                        label=""
                                                                                        className='m0px' />
                                                                                </FormGroup>}
                                                                                child2={
                                                                                    <FormGroup className='align-center'>
                                                                                        <FormControlLabel control={
                                                                                            <ECheckbox
                                                                                                checked={on_email == 1 ? true : false}
                                                                                                disabled={Boolean(formik?.values?.notification_is_default)}
                                                                                                onChange={(e, value) => {
                                                                                                    // setFieldValue("from_date_is_half", value);
                                                                                                    OnChangeSetting(setting_id, index1, 'on_email', value)
                                                                                                }} />
                                                                                        }
                                                                                            label=''
                                                                                            className='m0px'

                                                                                        />
                                                                                    </FormGroup>}

                                                                            />

                                                                        )
                                                                    }
                                                                    else { return null }
                                                                })}
                                                        </Grid>
                                                    </Grid>
                                                </ECardDashboard>

                                            )

                                        })}


                                    </Grid>
                                </>
                                    : ''}
                            </Grid>

                        </ECard>

                        <Stack direction="row" className='justify-content-flex-end'>
                            <EButtonOutlined  variant="outlined" size="large" onClick={() => navigate('/')}> Cancel </EButtonOutlined>
                            <ELoadingButton type="submit" variant="contained" size="large" className='button-left-margin font-size-14px'>  Save</ELoadingButton>
                        </Stack>

                    </EBoxPage>
                </Page>
            </Form>
        </FormikProvider>
    );
}

export default NotificationSetting;


const GridElements = (props) => {
    const { label, description = null, child1 = null, child2 = null, labelClassName = '', } = props
    return (
        <Grid container className=" mb-16px">
            <Grid item xs={7}  >
                <ETypography className={labelClassName}>{label}
                    <br />
                    <span className="greyColor4-color font-size-14px">{description}</span>
                </ETypography>
            </Grid>
            <Grid item xs={3} align='center'>{child1}</Grid>
            <Grid item xs={2} align='center'> {child2}</Grid>
        </Grid>
    )
}