import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import {
  EButton,
  EButtonIcon,
  EButtonOutlined,
  EButtonOutlinedIcon,
  EIconButton,
  ELoadingButton,
} from "../../BaseComponents/EButtons";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETooltip } from "../../BaseComponents/ETooltip";
import EModal from "../../BaseComponents/EModal";
import Page from "../../BaseComponents/EPage";
import {
  EListHead,
  ETable,
  ETableBody,
  ETableCell,
  ETableContainer,
  ETableRow,
} from "../../BaseComponents/ETable";
import {
  DEFAULT_ROWS_PERPAGE,
  PAGES,
  ROW_SIZE_PER_PAGE,
  TNC_DELETE_WORD_LIMIT,
} from "../../constants";
import { EBoxPage } from "../../BaseComponents/EBox";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EChip from "../../BaseComponents/EChip";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import {
  ETypography,
  ETypographyPageHeading,
} from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import {
  TaCCreateUpdateDelete,
  TandCListData,
} from "../../action/ContractAction";
import { capitalizeCapitalString, LimitWordsInString } from "../../utils/formatText";
import editFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import DeleteIcon from "@iconify/icons-material-symbols/delete-outline-rounded";
import TandCForm from "../../PageComponents/TnC/TandCForm";
import EHidden from "../../BaseComponents/EHidden";
import BackIcon from "@iconify/icons-material-symbols/chevron-left-rounded";
import TnCIcon from "@iconify/icons-material-symbols/gavel-rounded";

// gavel-rounded

const TABLE_HEAD = [
  { id: "Description", label: "Description", alignRight: false },
  { id: "Status", label: "Status", alignRight: "center" },
  { id: "Action", label: "Action", alignRight: "center" },
];

function TnCList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const [page, setPage] = useState(PAGES);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
  // const contractList = useSelector((state) => state.ContractReducer);
  const [isNewForm, setIsNewForm] = useState({ open: false, data: null });
  const tacList = useSelector((state) => state.TandCReducer);
  const [isDeleteTnC, SetIsDeleteTnC] = useState({
    open: false,
    id: null,
    title: "",
  });

  useEffect(() => {
    if (auth?.authtoken) {
      dispatch(TandCListData(auth?.authtoken, "", page, rowsPerPage));
    }
  }, []);

  useEffect(() => {
    if (tacList?.tacListSuccess == true && tacList?.tacLoading == false) {
      dispatch(TandCListData(auth?.authtoken, "", page, rowsPerPage));
    }
  }, [page, rowsPerPage, tacList?.tacLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage, page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0, page, rowsPerPage);
  };

  const HandleDeleteTnC = () => {
    let dataTodelete = { tncid: isDeleteTnC.id };
    dispatch(TaCCreateUpdateDelete(auth.authtoken, dataTodelete, "delete"));
    SetIsDeleteTnC({ open: false, id: null, title: "" });
  };

  return (
    <Page title="Standard T&C">
      <Grid container display="row" justifyContent="space-between">
        <EHeaderBreadcrumbs
          heading="Standard T&C"
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Freelancer Contract", href: "" },
            {
              name: "Standard T&C",
            },
          ]}
        />
        <EHidden width="mdDown">
          <Grid>
            <EButton
              variant="contained"
              component={RouterLink}
              onClick={() => setIsNewForm({ open: true, data: null })}
            >
              Add Terms & Conditions
            </EButton>
            <EButtonOutlined
              className="button-left-margin"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden>
      </Grid>

      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="pb-2rem margin-top-20px"
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Standard T&C
          </ETypographyPageHeading>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            <EButtonIcon
              variant="contained"
              component={RouterLink}
              onClick={() => setIsNewForm({ open: true, data: null })}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={TnCIcon} className='height-width-15px'/>{" "}
            </EButtonIcon>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={BackIcon} className='height-width-15px'/>{" "}
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
        <EHidden width="mdDown">
          <Grid lg={12} xs={12} item>
            <EDivider className="margin-top-05" />
          </Grid>
        </EHidden>
      </Grid>
      <EBoxPage>
        <EScrollbar>
          <ETableContainer>
            <ETable>
              {tacList?.tacListLoading && (
                <>
                  <EListHead
                    headLabel={TABLE_HEAD}
                    rowCount={tacList?.tacListData?.rows?.length}
                    onRequestSort={() => null}
                    authvar={auth}
                  />

                  <ETableBody>
                    <ETableRow>
                      <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <ECircularProgress color="primary" />
                      </ETableCell>
                    </ETableRow>
                  </ETableBody>
                </>
              )}
              {!tacList?.tacListLoading &&
                tacList?.tacListData?.rows?.length > 0 && (
                  <>
                    <EListHead
                      headLabel={TABLE_HEAD}
                      rowCount={tacList?.tacListData?.rows?.length}
                      onRequestSort={() => null}
                      authvar={auth}
                    />
                    <ETableBody>
                      {tacList &&
                        tacList?.tacListData?.rows?.map((row, index) => {
                          const { id, status, terms_title, is_locked } = row;
                          return (
                            <ETableRow
                              hover
                              key={index + "-" + id}
                              className="cursor-pointer"
                            >
                              <ETableCell align="left" sx={{ width: "90%" }}>
                                <ETypography className="font-size-14px"
                                  dangerouslySetInnerHTML={{
                                    __html: terms_title,
                                  }}
                                ></ETypography>
                              </ETableCell>
                              <ETableCell align="center">
                                <EChip
                                  label={capitalizeCapitalString(
                                    status == true ? "Enable" : "Disable"
                                  )}
                                />
                              </ETableCell>
                              <ETableCell
                                align="center"
                                style={{ width: "20px", paddingRight: "0" }}
                              >
                                {!is_locked && (
                                  <Grid container sx={{ flexWrap: "nowrap" }}>
                                    <Grid item>
                                      <EIconButton
                                        onClick={() => {
                                          setIsNewForm({
                                            open: true,
                                            data: row,
                                          });
                                        }}
                                        className="p2px"
                                      >
                                        <ETooltip title={"Edit"} arrow>
                                          <EIcon
                                            icon={editFill}
                                            className="blue-color"
                                            width={24}
                                            height={24}
                                          />
                                        </ETooltip>
                                      </EIconButton>
                                    </Grid>
                                    <Grid item>
                                      <EIconButton
                                        className="p2px"
                                        onClick={() => {
                                          SetIsDeleteTnC({
                                            open: true,
                                            id: id,
                                            title: terms_title,
                                          });
                                        }}
                                      >
                                        <ETooltip title={"Delete"} arrow>
                                          <EIcon
                                            icon={DeleteIcon}
                                            sx={{ color: "text.redColor" }}
                                            width={24}
                                            height={24}
                                          />
                                        </ETooltip>
                                      </EIconButton>
                                    </Grid>
                                  </Grid>
                                )}
                              </ETableCell>
                            </ETableRow>
                          );
                        })}
                    </ETableBody>
                  </>
                )}
            </ETable>
          </ETableContainer>
        </EScrollbar>
        {/* Pagination */}
        {!tacList?.tacListLoading &&
        tacList?.tacListData?.count > DEFAULT_ROWS_PERPAGE ? (
          <ETablePagination
            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
            component="div"
            count={
              tacList?.tacListData?.count ? tacList?.tacListData?.count : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : (
          <></>
        )}
        {!tacList?.tacListLoading &&
          tacList?.tacListData?.rows?.length == 0 && <SearchNotFound />}
      </EBoxPage>

      {/* Edit modal */}
      {isNewForm.open && (
        <EModal
          open={isNewForm.open}
          close={() => setIsNewForm({ open: false, data: null })}
          headervalue={
            isNewForm.data == null
              ? "Add Terms & Conditions"
              : "Update Terms & Conditions"
          }
        >
          <TandCForm
            close={() => setIsNewForm({ open: false, data: null })}
            auth={auth}
            tacList={tacList}
            row={isNewForm.data}
          />
        </EModal>
      )}

      {/* Delete modal */}
      {isDeleteTnC.open && (
        <EModal
          open={isDeleteTnC.open}
          close={() => SetIsDeleteTnC({ open: false, data: null })}
          headervalue="Delete: Confirmation"
        >
          <Grid container>
            <Grid item>
              <ETypography variant="h5" className="font-size-18px">
                {" "}
                Are you sure you want to delete
                <span className="theme-main-text-color-bold text-decoration-underline px-6px">
                  {isDeleteTnC.title?LimitWordsInString(isDeleteTnC.title,TNC_DELETE_WORD_LIMIT):''}
                </span>{" "}
                ?
              </ETypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={12}
              xl={12}
              className="modal1-buttons-stick-bottom pt-24px"
            >
              <EButtonOutlined
                size="large"
                variant="outlined"
                onClick={() => {
                  SetIsDeleteTnC({ open: false, id: null, title: "" });
                }}
              >
                {" "}
                No{" "}
              </EButtonOutlined>
              <ELoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={HandleDeleteTnC}
                className="button-left-margin"
              >
                {" "}
                Yes{" "}
              </ELoadingButton>
            </Grid>
          </Grid>
        </EModal>
      )}
    </Page>
  );
}

export default TnCList;
