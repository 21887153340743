import React, { useEffect, useRef} from 'react'
import Page from '../../../BaseComponents/EPage'
import { Grid, Link, ListItem } from '@mui/material'
import { AuthNavbar } from '../../../layouts/auth-navbar'
import { ECardDashboard } from '../../../BaseComponents/ECard'
import { Stack } from 'immutable'
import { ETypography } from '../../../BaseComponents/ETypography'
import { EButton, EButtonOutlined } from '../../../BaseComponents/EButtons'
import { AuthRootStyle } from '../../../BaseComponents/EContainer'
import YouTube from 'react-youtube';
import { EBox } from '../../../BaseComponents/EBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { onBoardingStepAction } from '../../../action/OnboardingStepAction'
import { Logo } from '../../../layouts/logo'

const WatchDemo = ({auth}) => {
    const navigateTo = useNavigate()
    const location = useLocation()
    const videoId = '1K40R94U33I';
    const company = useSelector(state => state.CompanyReducer)

    function handleClick (){
        dispatch(onBoardingStepAction(auth?.authtoken, 'VIDEO'))
        navigateTo('/')
    }

    const dispatch = useDispatch()


    // Configuration options for the YouTube player
    const opts = {
        // height: '360',
        width: '100%',
        playerVars: {
            autoplay: 0, //auto replay when value is 0
            rel: 1, // Disable related videos
            // showinfo: 0, // Hide video title
            modestbranding: 1, // Display small YouTube logo (which is in the right side)
            controls: 1, // Disable player controls if value is 0
            disablekb: 0, // Disable keyboard control if value is 1 
            // enablejsapi: 1, // Enable the JavaScript API
          },
    };

    const onPlayerReady = (event) => {
        // Do something when the player is ready
        event.target.playVideo();
      };    

      
    
      

  return (
    <Page title="Watch Demo">
    <Grid container justifyContent={'center'} alignItems={'center'}>
        <AuthRootStyle maxWidth="lg">
        {
         location?.state?.showNavbar ?
          <AuthNavbar/>
           : null
        }
            <ECardDashboard sx={{ maxWidth: '800px', margin: '28px auto'}}>
                <Grid container direction="column" className="mb-16px ">
                    {/* <Grid item  >
                        <Stack direction='row'>
                            <ETypography className="mr-8px">
                                <img alt="gift" src="/assets/images/onboarding-gift.png" />
                            </ETypography>
                            <ETypography className="onboarding-heading bold-400 font-size-24px ">
                                Welcome to <span> Entera</span>,
                            </ETypography>
                        </Stack>
                    </Grid> */}

                    {/* <Grid item marginBottom={'34px'} padding={1}>
                        <ETypography className="onboarding-modal-head bold-600 font-size-16px">Hi {auth?.authData?.fullname},</ETypography>

                        <div>
                            <p className="onboarding-modal-paragraph mb-16px ">
                                Thank you for choosing ENTERA as your next payroll superhero. We are
                                happy to have you onboard!
                            </p>
                            <p className="onboarding-modal-paragraph ">
                                We would suggest you to get started with our quick start guide to
                                enable Entera get familiar with your workspace.
                            </p>
                        </div>
                    </Grid> */}

                    {/* <Stack direction='row' justifyContent={'flex-end'} spacing={2}>
                        <EButton>
                            Get Started
                        </EButton>
                    </Stack> */}

                    {/* <ETypography className='font-size-20px' sx={{fontWeight:'600', marginBottom:'20px'
                }}>Dashboard</ETypography> */}
                        {/*
                         // Commenting until the tutorial video is made 

                        <YouTube
                            videoId={videoId} 
                            opts={opts} 
                            onReady={onPlayerReady}
                            title='Watch demo'
                        /> */}

                        <EBox sx={{ height:'350px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <ETypography variant='h2'>
                            Coming Soon....
                        </ETypography>

                        </EBox>
                      
                </Grid>

            </ECardDashboard>

                  {
                      location?.state?.showNavbar && <EBox sx={{ maxWidth: '800px', margin: '28px auto' }}>
                          <SkipBackButton navigateTo={navigateTo} onClick={() => handleClick()} />
                      </EBox>
                  }
            

        </AuthRootStyle>

    </Grid>


</Page>
  )
}

export default WatchDemo

export const SkipBackButton = ({navigateTo, onClick}) => {
    return(
        <ListItem className='p0 m0' sx={{paddingLeft:'0 !important', paddingRight:'0 !important'}}>
            <EBox sx={{ marginLeft: 'auto', marginTop:'15px'}}>
                    <EButtonOutlined variant='outlined' size='large' onClick={()=>{navigateTo(-1)}} sx={{marginRight:'10px'}}> Back</EButtonOutlined>
                    <EButton variant="contained" size='large' onClick={onClick}> Skip</EButton>
            </EBox>
        </ListItem>
    )
  
  }