import React from 'react'
import { useTheme } from '@mui/material';
import { ETypography } from '../../../../BaseComponents/ETypography';
import { EBox } from '../../../../BaseComponents/EBox';


export const ShiftInput = ({ title = "", subTitle = "", children }) => {

  const theme = useTheme()

  return (
    <>
      <EBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          my: 0.8,
        }}
      >
        <ETypography sx={{ fontWeight: 600, flexBasis: '72%', alignSelf: 'center' }}>
          {title}
          {
            subTitle !== ""
            &&
            <ETypography as="span" sx={{ color: theme.palette.text.greyColor4, display: 'block', fontWeight: 400 }}>
              {subTitle}
            </ETypography>
          }
        </ETypography>
        {children && children}
      </EBox>
    </>
  )
}
