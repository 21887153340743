import { Box,Container,styled } from "@mui/material";
import PropTypes from 'prop-types';

/**
 * [2022-03-16]
 * changes by: Purva Sharma
 * Description:- Added EBoxNotification
 **/

export const EBox = styled(Box)(({ theme }) => ({
// backgroundColor:theme.palette.Box.primary
// height:'70px'
}));

export const EBoxHrLEave = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'row',
  fontSize:'13px',
  alignItems:'center',
  }));

export const EBoxHRDash = styled(Box)(({ theme }) => ({
  // backgroundColor:theme.palette.Box.primary,
  lineHeight:'28px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  }));

export const GroupFields = styled(EBox)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    border: (theme) => `1px solid ${theme.palette.divider}`,
}));

export const EBoxPage = styled(Container)(({ theme }) => ({
  padding: '16px 15px 0px 15px',
  [theme.breakpoints.up('xl')]: {
    maxWidth: "74.438rem",//1191px
    align: 'center'
  },
  [theme.breakpoints.down('xl')]: {
    padding: '16px 0px 0px 0px',
  },
}));

export const ItemBox = (props) => {
    const { sx, ...other } = props;
    return (
      <Box
        sx={{
        //   p: 1,
          m: 1,
          ...sx,
        }}
        {...other}
      />
    );
  }
  
  ItemBox.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  export const EBoxNotification = styled(Container)(({ theme }) => ({
    padding: '16px 15px 0px 15px',
    [theme.breakpoints.up('xl')]: {
      maxWidth: "42.5rem",//680px
      align: 'center'
    },
    [theme.breakpoints.down('lg')]: {
      padding: '16px 0px 0px 0px',
    },
  }));