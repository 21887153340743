

import { FormControlLabel, FormGroup, Grid, Link, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect } from 'react';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EFieldLabelBold, ETypography } from '../../BaseComponents/ETypography';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/formatNumber';
import { EIcon } from '../../BaseComponents/EIcon';
import AttachFile from '@iconify/icons-material-symbols/file-present-rounded';
import { APPROVED_STATUS, CANCELLED_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PENDING_STATUS, REMARK_LIMIT } from '../../constants';
import { ETextField } from '../../BaseComponents/ETextField';
import { ApproveRequestDetailFormValidation } from '../../utils/validation';
import { GetAdvanceBalanceAction, HRAccountantPaymentAction } from '../../action/PaymentAction';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import moment from 'moment';
import { EBox } from '../../BaseComponents/EBox';


const PaymentRequestRespond = (props) => {
  const { setModalState, auth, selectedData, getPaymentFilterData } = props;
  const dispatch = useDispatch();
  const payment = useSelector((state) => state.PaymentReducer);
  const formik = useFormik({
    initialValues: {
      status: selectedData.modalData?.transaction_status != 'PENDING' ? selectedData.modalData?.transaction_status : 'APPROVED',
      remark: '',
      previous_amount: ''
    },
    validationSchema: ApproveRequestDetailFormValidation,
    onSubmit: (data) => {
      data = { ...data, contract_id: selectedData.modalData?.id };
      dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, "request_status"));
      setModalState({ modalData: null, modalName: null })
    }
  });


  useEffect(() => {
    if (auth?.authtoken) {
      dispatch(GetAdvanceBalanceAction(auth?.authtoken, selectedData.modalData?.employeeData?.id));
    }
  }, [auth?.authtoken]);


  useEffect(() => {
    if (auth?.authtoken && auth?.authtoken && payment.EmployeePreviousAdvanceLoading == false) {
      const reqamt = Number(payment?.EmployeePreviousAdvanceData?.advance_amount_given) - Number(payment?.EmployeePreviousAdvanceData?.advance_amount_return) ?
        Number(payment?.EmployeePreviousAdvanceData?.advance_amount_given) - Number(payment?.EmployeePreviousAdvanceData?.advance_amount_return) : 0;
      setFieldValue('previous_amount', reqamt);
    }
  }, [payment.EmployeePreviousAdvanceSuccess]);


  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} lg={12} xl={12}>

            <ELabelInputMainGrid label={'Employee Name '} isfullgrid={true} isModal={true} isNotForm={true}>
              <EBox display="flex" gap={0.5}>
                <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
                <ETypography noWrap className="text-capitalize blue-color font-weight-400-with-imp"
                >
                  ({selectedData.modalData?.employeeData?.employee_code ? selectedData.modalData?.employeeData?.employee_code : '-'})
                </ETypography>
              </EBox>
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Request Date'} isfullgrid={true} isModal={true} isNotForm={true}>
              <ETypography>{moment(selectedData.modalData?.transaction_date).format('DD/MM/YYYY')}</ETypography>
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <ELabelInputMainGrid label={'Request Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
              <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
            </ELabelInputMainGrid>
          </Grid>

          {selectedData.modalData?.reimbursement_attachment ?
            (<Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Attachment'} isfullgrid={true} isModal={true} isNotForm={true}>
                {selectedData.modalData?.reimbursement_attachment ? (
                  <Link target={'_blank'} href={selectedData.modalData?.reimbursement_attachment}>
                    <EIcon icon={AttachFile} className='theme-color-static' />
                  </Link>) : <></>}
              </ELabelInputMainGrid>
            </Grid>) : <></>
          }
          {
            selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT &&
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid
                label={'Bill Date'}
                isModal={true}
                isfullgrid={true}
                isNotForm={true}
              >
                <ETypography>{selectedData.modalData?.reimbursement_bill_date ? moment(selectedData.modalData?.reimbursement_bill_date).format('DD/MM/YYYY') : '-'}</ETypography>
              </ELabelInputMainGrid>
            </Grid>
          }
          {
            [PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Reason'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData.remark}</ETypography>
              </ELabelInputMainGrid>
            </Grid>

          }

          {
            selectedData.modalData?.transaction_status == PENDING_STATUS
              ?
              (
                <>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>

                    <ELabelInputMainGrid label={'Status'} isfullgrid={true} isModal={true} labelPT='0.5rem' >
                      <FormGroup>
                        <RadioGroup row aria-label="status" name="status" {...getFieldProps('status')}>
                          <FormControlLabel value="APPROVED" control={<Radio />} label="Approve" />
                          <FormControlLabel value="CANCELLED" control={<Radio />} label="Reject" />
                        </RadioGroup>
                      </FormGroup>
                    </ELabelInputMainGrid>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>

                    <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true} >
                      <ETextField
                        fullWidth
                        placeholder='Enter your remarks here'
                        multiline
                        rows={3}
                        disabled={selectedData.modalData?.transaction_status != PENDING_STATUS ? true : false}
                        {...getFieldProps('remark')}
                        inputProps={{ maxLength: REMARK_LIMIT }}
                        error={Boolean(touched.remark && errors.remark)}
                        helperText={touched.remark && errors.remark}
                      />
                      <ETypography className='font-size-14px mt-4px greyColor11-color'>
                        {`${formik?.values?.remark?.length || 0}/${REMARK_LIMIT}`}
                      </ETypography>
                    </ELabelInputMainGrid>
                  </Grid>
                </>
              )
              :
              (
                <>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>

                    <ELabelInputMainGrid label={'Status'} isfullgrid={true} isModal={true} isNotForm={true}>
                      <ETypography>{selectedData.modalData?.transaction_status == APPROVED_STATUS
                        ? 'Approved'
                        : selectedData.modalData?.transaction_status == CANCELLED_STATUS
                          ? 'Reject'
                          : 'Paid'}
                      </ETypography>
                    </ELabelInputMainGrid>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12} xl={12}>

                    <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true} isNotForm={true}>
                      <EFieldLabelBold>Remark</EFieldLabelBold>
                      <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData?.transaction_status_remark ? selectedData.modalData?.transaction_status_remark : '-'}</ETypography>
                    </ELabelInputMainGrid>
                  </Grid>
                </>
              )
          }

          <Grid item xs={12} sm={12} lg={12} xl={12}>
            <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
              <EButtonOutlined size="large" variant="outlined"
                onClick={() => setModalState({ modalData: null, modalName: null })}
              > Cancel </EButtonOutlined>

              {selectedData.modalData?.transaction_status == PENDING_STATUS && (
                <ELoadingButton
                  size="large" variant="outlined" type="submit" >Submit</ELoadingButton>
              )}

            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};
export default PaymentRequestRespond;
