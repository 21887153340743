import React, { useState, useEffect } from 'react';
import { Card, Button, Grid, Avatar, Input } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IMAGE_URL } from '../../constants';
import ImageEditing from '../ImageEditing';


export const ButtonStyled = styled(Button)(({ theme }) => ({
  border: '2px solid theme.palette.primary.darker',
  borderStyle: 'dashed',
  borderRadius: '124px',
  color: 'primary'
}));

function PhotoUpload(props) {
  const [Picture, SetPicture] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const { setImageUrlData, companyDetail, setFieldValue, errors, touched } = props;
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 1,
    rotate: 0,
    croppedImg: '/assets/images/companyProfile.svg',
    croppedImgFileObject: '',
    newformphotostatus: 'initial'
  });
  const [editor, setEditor] = useState('');
  const HelperText = styled('div')(({ theme }) => ({
    fontSize: '0.75rem !important',
    marginTop: '2px'
  }));
//   useEffect(() => {
//     if (Picture) {
//       setImageUrl(URL.createObjectURL(Picture));
//       setImageUrlData(Picture);
//     }
//   }, [Picture]);

  useEffect(() => {
    // console.log(IMAGE_URL , '/' , companyDetail?.id , '/company/' , companyDetail?.company_logo,companyDetail);
    if (companyDetail?.company_logo) {
      setPicture({
        ...picture,
        croppedImg: companyDetail?.company_logo
      });
      // let url = URL.createObjectURL(IMAGE_URL + '/' + companyDetail?.id + '/company/' + companyDetail?.company_logo);
      // setFieldValue(companyDetail?.company_logo,url);
    }
  }, [companyDetail?.company_logo]);

  const handleProfilePhotoFileChange = (e) => {
    if (e && e.target.files[0]) {
      let url = URL.createObjectURL(e.target.files[0]);
      setFieldValue('company_logo', e.target.files[0]);
      setPicture({
        ...picture,
        img: e.target.files[0],
        cropperOpen: true
      });
    }
  };
  useEffect(() => {
    if (picture.newformphotostatus == 'save') {
      setFieldValue('company_logo', picture.croppedImgFileObject);
    } else {
      setFieldValue('company_logo', '');
    }
  }, [picture.newformphotostatus]);
  return (
    <Grid container
    direction="column"
    justifyContent="center"
    alignItems="center"
    >
      {/* <Grid item className='text-align-center'> */}
        {/* <Card sx={{ padding: '20px' }}> */}
          {/* <div className="CardHeading"> Company Logo</div> */}
          {/* <Grid
            // sx={{
            //   padding: '30px',
            //   height: 'auto',
            //   paddingTop: '1rem',
            //   textAlign: 'center',
            //   mx: 'auto'
            // }}
          > */}
            {!picture.cropperOpen ? (
              <label htmlFor="icon-button-file">
                <div >
                  <Input
                    inputProps={{accept: 'image/jpg, image/jpeg' }}
                    id="icon-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleProfilePhotoFileChange(e)}
                    placeholder="jgns"
                  />
                  <ButtonStyled
                    aria-label="upload picture"
                    component="span"
                    id="ePhoto"
                    style={{
                      paddingTop:'16px',
                      paddingBottom:'16px',
                      border: '2px solid grey',
                      borderStyle: 'dashed',
                      borderRadius: '50% 50%',
                      cursor: 'pointer'
                    }}
                  >
                    <Avatar
                      alt="Profile Pic"
                      src={picture.croppedImg}
                      style={{
                        border: '0.1px solid #D97904'
                     }}
                      sx={{
                        width: 140,
                        height: 140,
                        mx: 'auto',
                        border: '1px',
                        borderColor: '#D97904',
                      }}
                    />
                  </ButtonStyled>
                </div>
              </label>
            ) : (
              <ImageEditing
                picture={picture}
                setPicture={setPicture}
                editor={editor}
                setEditor={setEditor}
              />
            )}
            <div className='helper-text-font mt-10px'>*.jpeg or *.jpg formats only </div>
            <HelperText style={{ color: 'red' }}>
              {' '}
              {errors.company_logo ? errors.company_logo : ''}{' '}
            </HelperText>
          {/* </Grid> */}
        {/* </Card> */}
      {/* </Grid> */}
    </Grid>
  );
}

export default PhotoUpload;
