import {
  ATTENDANCE_LIST_FAILURE,
  ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_LIST_LOADING,

  ATTENDANCE_SUCCESS, 
  ATTENDANCE_FAILURE, 
  ATTENDANCE_LOADING,


} from '../constants';


const INITIAL_STATE = {
  attendanceListData: {},
  attendanceListSuccess: false,
  attendanceListLoading: false,
  attendanceListMessage: null,
  attendanceData: {},
  attendanceSuccess: false,
  attendanceLoading: false,
  attendanceMessage: null,
};

const AttendanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // FOR ATTENDANCE LIST 

    case ATTENDANCE_LIST_LOADING:
      return {
        // ...state,
        attendanceListLoading: true,
        attendanceListData: {}
      };

    case ATTENDANCE_LIST_SUCCESS:
      return {
        // ...state,
        attendanceListLoading: false,
        attendanceListSuccess: true,
        attendanceListData: action.payload.data,
        attendanceListMessage: action.payload.message,
      };

    case ATTENDANCE_LIST_FAILURE:
      return {
        // ...state,
        attendanceListLoading: false,
        attendanceListSuccess: false,
        attendanceListMessage: action.payload.message,
        attendanceListData: {}
      };
    case ATTENDANCE_LOADING:
      return {
        // ...state,
        attendanceLoading: true,
        attendanceData: {},
      };

    case ATTENDANCE_SUCCESS:
      return {
        // ...state,
        attendanceLoading: false,
        attendanceSuccess: true,
        attendanceData: action.payload.data,
        attendanceMessage: action.payload.message,
      };

    case ATTENDANCE_FAILURE:
      return {
        // ...state,
        attendanceLoading: false,
        attendanceSuccess: false,
        attendanceMessage: action.payload.message,
        attendanceData: {},
      };

    default:
      return state;
  }
};

export default AttendanceReducer;
