import {
    LEAVE_MASTER_LIST_FAILURE, LEAVE_MASTER_LIST_SUCCESS, LEAVE_MASTER_LIST_LOADING, LEAVE_MASTER_FAILURE, LEAVE_MASTER_LOADING, LEAVE_MASTER_SUCCESS
} from '../constants';

const INITIAL_STATE = {
    leaveMasterListData: {},
    leaveMasterListSuccess: false,
    leaveMasterListLoading: false,
    leaveMasterListMessage: null,
    leaveMasterData: {},
    leaveMasterSuccess: false,
    leaveMasterLoading: false,
    leaveMasterMessage: null,
};

const LeaveMasterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case LEAVE_MASTER_LOADING:
            return {
                leaveMasterLoading: true,
                leaveMasterData: {}
            };

        case LEAVE_MASTER_SUCCESS:
            return {
                leaveMasterLoading: false,
                leaveMasterSuccess: true,
                leaveMasterData: action.payload.data,
                leaveMasterMessage: action.payload.message,
            };

        case LEAVE_MASTER_FAILURE:
            return {
                leaveMasterLoading: false,
                leaveMasterSuccess: false,
                leaveMasterMessage: action.payload.message,
            };

        // FOR LEAVE_MASTER LIST 

        case LEAVE_MASTER_LIST_LOADING:
            return {
                leaveMasterListLoading: true,
                leaveMasterListData: {}
            };

        case LEAVE_MASTER_LIST_SUCCESS:
            return {
                leaveMasterListLoading: false,
                leaveMasterListSuccess: true,
                leaveMasterListData: action.payload.data,
                leaveMasterListMessage: action.payload.message,
            };

        case LEAVE_MASTER_LIST_FAILURE:
            return {
                leaveMasterListLoading: false,
                leaveMasterListSuccess: false,
                leaveMasterListMessage: action.payload.message,
                leaveMasterListData: {}
            };

        default:
            return state;
    }
};

export default LeaveMasterReducer;
