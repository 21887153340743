import { Grid, IconButton } from "@mui/material";
import { ECard } from "../../BaseComponents/ECard";
import { EDivider } from "../../BaseComponents/EDivider";
import { ETypography } from "../../BaseComponents/ETypography";
import moment from "moment";
import { ETextField } from "../../BaseComponents/ETextField";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { Field } from "formik";
import { BloodGroupOptions, GenderOptions, MaritalStatusOptions, PINCODE_LIMIT } from "../../constants";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useDispatch, useSelector } from "react-redux";
import { CityList } from "../../action/StateCityAction";
import { onKeyDown } from "../../utils/CommonFunctions";
import plusBox from '@iconify/icons-material-symbols/add-box-outline-rounded';
import minusBox from '@iconify/icons-material-symbols/indeterminate-check-box-outline-rounded';
import { EIcon } from "../../BaseComponents/EIcon";

const AddFormPersonalDetail = (props) => {
    const { formikTalents, stateList, token, isOpen, TalentSetting } = props;
    const dispatch = useDispatch()
    const cityList = useSelector(state => state.CityReducer.cityListData?.rows);
    const { errors, touched, getFieldProps, setFieldValue } = formikTalents;

    return (

        <ECard className={!getFieldProps('personal_detail_check').value ? 'card_design_2' : 'card_design_1 pb-8px'}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography variant='h5' className="font-size-20px">
                    Personal Details
                </ETypography>
                {!isOpen &&
                    <IconButton
                        onClick={() => setFieldValue('personal_detail_check', !getFieldProps('personal_detail_check').value)}>
                        <EIcon icon={getFieldProps('personal_detail_check').value ? minusBox : plusBox} />
                    </IconButton>
                }
            </Grid>

            {getFieldProps('personal_detail_check').value && <>
                <EDivider />
                <Grid container>
                    <Grid container spacing={2} py={2}>
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Date of Birth' + (TalentSetting?.field_dob ? '*' : '')}>
                                <EDatePicker
                                    name="personal_dob"
                                    defaultCalendarMonth={moment().subtract(18, 'years')._d} //To open the calender in (current-18) year
                                    {...getFieldProps('personal_dob')}
                                    views={["year", "month", "day"]}
                                    maxDate={moment().subtract(18, 'years')._d}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(selectedDate) => {
                                        setFieldValue("personal_dob", selectedDate ? selectedDate : null)
                                    }}
                                    renderInput={(params) =>
                                        <Field
                                            component={ETextField}
                                            {...params}
                                            fullWidth
                                            onKeyDown={onKeyDown}
                                            name="personal_dob"
                                            error={Boolean(touched.personal_dob && errors.personal_dob)}
                                            helperText={touched.personal_dob && errors.personal_dob}
                                        />}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Gender' + (TalentSetting?.field_gender ? '*' : '')}>
                                <EAutocomplete
                                    name="personal_gender_object"
                                    fullWidth
                                    forcePopupIcon={formikTalents?.values?.personal_gender_object ? false : true}
                                    options={GenderOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_gender_object')}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_gender_object", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_gender_object'
                                            fullWidth
                                            placeholder='Select Gender'
                                            error={Boolean(touched.personal_gender_object && errors.personal_gender_object)}
                                            helperText={touched.personal_gender_object && errors.personal_gender_object}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} >
                            <ELabelInputMainGrid label={'Marital Status' + (TalentSetting?.field_marital_status ? '*' : '')}>
                                <EAutocomplete
                                    name="personal_marital_status_obj"
                                    fullWidth
                                    forcePopupIcon={formikTalents?.values?.personal_marital_status_obj ? false : true}
                                    options={MaritalStatusOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_marital_status_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_marital_status_obj", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_marital_status_obj'
                                            fullWidth
                                            placeholder='Select Marital Status'
                                            error={Boolean(touched.personal_marital_status_obj && errors.personal_marital_status_obj)}
                                            helperText={touched.personal_marital_status_obj && errors.personal_marital_status_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Blood Group' + (TalentSetting?.field_blood_group ? '*' : '')}>
                                <EAutocomplete
                                    name="personal_blood_group_obj"
                                    fullWidth
                                    options={BloodGroupOptions}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    forcePopupIcon={formikTalents?.values?.personal_blood_group_obj ? false : true}
                                    getOptionLabel={(option) => option.label || ''}
                                    {...getFieldProps('personal_blood_group_obj')}
                                    onChange={(e, value) => {
                                        setFieldValue('personal_blood_group_obj', value);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="personal_blood_group_obj"
                                            placeholder="Select Blood Group"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={Boolean(touched.personal_blood_group_obj && errors.personal_blood_group_obj)}
                                            helperText={touched.personal_blood_group_obj && errors.personal_blood_group_obj}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <ELabelInputMainGrid label={'Address' + (TalentSetting?.field_address ? '*' : '')} isfullgrid={true}>
                                <ETextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    name='personal_address_one'
                                    autoComplete="personal_address_one"
                                    placeholder="Address "
                                    {...getFieldProps('personal_address_one')}
                                    error={Boolean(touched.personal_address_one && errors.personal_address_one)}
                                    helperText={touched.personal_address_one && errors.personal_address_one}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Landmark' + (TalentSetting?.field_address ? '*' : '')} >
                                <ETextField
                                    fullWidth
                                    name='landmark'
                                    placeholder="Landmark "
                                    {...getFieldProps('landmark')}
                                    error={Boolean(touched.landmark && errors.landmark)}
                                    helperText={touched.landmark && errors.landmark}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'State' + (TalentSetting?.field_address ? '*' : '')}>
                                <EAutocomplete
                                    name="personal_state"
                                    label="State"
                                    fullWidth
                                    options={stateList && stateList?.length > 0 ? stateList : []}
                                    forcePopupIcon={formikTalents?.values?.personal_state ? false : true}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    {...getFieldProps('personal_state')}
                                    onChange={(e, value) => {
                                        if (value) { dispatch(CityList(token, value.id)) }
                                        setFieldValue("personal_state", value)
                                        setFieldValue("personal_district", null)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_state'
                                            placeholder="Select State"
                                            fullWidth
                                            error={Boolean(touched.personal_state && errors.personal_state)}
                                            helperText={touched.personal_state && errors.personal_state}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'City' + (TalentSetting?.field_address ? '*' : '')}>
                                <EAutocomplete
                                    name="personal_district"
                                    fullWidth
                                    disabled={formikTalents?.values?.personal_state ? false : true}
                                    forcePopupIcon={formikTalents?.values?.personal_district ? false : true}
                                    options={cityList && cityList?.length > 0 ? cityList : []}
                                    {...getFieldProps('personal_district')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={option => option.label || ""}
                                    onChange={(e, value) => {
                                        setFieldValue("personal_district", value)
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='personal_district'
                                            placeholder="Select City"
                                            fullWidth
                                            error={Boolean(touched.personal_district && errors.personal_district)}
                                            helperText={touched.personal_district && errors.personal_district}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Pincode' + (TalentSetting?.field_address ? '*' : '')}>
                                <ETextField
                                    fullWidth
                                    autoComplete="personal_pin_code"
                                    placeholder="Pincode "
                                    inputProps={{ maxLength: PINCODE_LIMIT }}
                                    {...getFieldProps('personal_pin_code')}
                                    error={Boolean(touched.personal_pin_code && errors.personal_pin_code)}
                                    helperText={touched.personal_pin_code && errors.personal_pin_code}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                </Grid>
            </>}
        </ECard>
    )
};

export default AddFormPersonalDetail;