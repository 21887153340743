export const startupData = {
  plans: 
    {
        id:'1',
      title: "Startup Plan",
      features: [
        {
          title: "Employee Onboarding",
        },
        {
          title: "Automated Attendance Management",
        },
        {
          title: "Multilevel Approvals",
        },
        {
          title: "Payroll Generation",
        },
        {
          title: "Timely Tracking of Working Hours        ",
        },
        {
          title: "Scheduled Payments",
        },
        {
          title: "Reimbursement Claim",
        },
        {
          title: "Interactive Dashboard",
        },
        {
          title: "Comprehensive Employee Exit",
        },
        {
          title: "Analytical Report",
        },
      ],
    }
};

export const enterpriseData = {
    plans: 
      {
        id:'2',
        title: "Enterprise Plan",
        features: [
          {
            title: "Startup Plan",
          },
          {
            title: "Freelance Management",
          },
          {
            title: "Track Deliverable Catalogue",
          },
          {
            title: "Lucid Contracts & Easy Payments",
          },
          {
            title: "Contract Completion & Termination",
          },
          {
            title: "Custom Features",
          }
        ],
      },
  };
  