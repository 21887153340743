import {
  LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_FAILURE,  LOGIN_RESET_REDIRECT, LOGOUT
} from '../constants';

const INITIAL_STATE = {
  authData: {},
  authSuccess: false,
  authLoading: false,
  authMessage: null,
  authtoken: null
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_LOADING:
      return {
        ...INITIAL_STATE,
        authLoading: true,
      };

    case LOGIN_SUCCESS:
      return {
        authLoading: false,
        authSuccess: true,
        authData: action.payload.data.user,
        authMessage: action.payload.message,
        authtoken: action.payload.secretToken
      };

      case LOGIN_RESET_REDIRECT:
        return {
          authLoading: false,
          authSuccess: true,
          authData: action.payload,
          authMessage: action.payload.message,
          authtoken: null
        };  

    case LOGIN_FAILURE:
      return {
        authLoading: false,
        authSuccess: false,
        authData: {},
        authMessage: action.payload.message,
        authtoken: null,
      };
    
    case LOGOUT:
      return {
        authLoading: false,
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default authReducer;
