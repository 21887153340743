import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import {
  EButtonIcon,
  EButtonOutlined,
  EButtonOutlinedIcon,
} from "../../BaseComponents/EButtons";
import EHidden from "../../BaseComponents/EHidden";
import Page from "../../BaseComponents/EPage";
import { ETypography, ETypographyPageHeading} from "../../BaseComponents/ETypography";
import { EIcon } from "../../BaseComponents/EIcon";
import { EDivider } from "../../BaseComponents/EDivider";
import { ECard } from "../../BaseComponents/ECard";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { ETextFieldSearch } from "../../BaseComponents/ETextField";
import BackIcon from "@iconify/icons-material-symbols/chevron-left-rounded";
import SettingIcon from "@iconify/icons-material-symbols/settings-outline-rounded";
import { useDispatch, useSelector } from "react-redux";
import NotificationItem from "../../PageComponents/Notification/NotificationItem";
import { EBoxNotification } from "../../BaseComponents/EBox";
import { DEFAULT_ROWS_PERPAGE_20, PAGES, GET_NOTIFICATION_LIST_API, SERVER_URL } from "../../constants";
import { LoadingGrid } from "../../BaseComponents/EGrid";
import { NotificationCategoryList } from "../../action/NotificationAction";

function NotificationDetail(props) {
  const {auth}=props
  const dispatch = useDispatch()
  const notificationCategory = useSelector( (state) => state.NotificationCategoryReducer);
  const navigate = useNavigate();

  const [card, setCard] = useState([]);
  const [filter, setFilter] = useState(null);
  const [isClick, setClick] = useState(false);
  const [page, setPage] = useState(PAGES);
  const [loading, setLoading] = useState(true);

  const totalNotificationCount = useRef(null);
  const totalNotificationRow = useRef(null);

  const myHeaders = new Headers({
    'Authorization': auth?.authtoken,
  });
  
  // INFO: This function is calling the data from API
  const getCardData = async () => {
    if (totalNotificationCount.current == null || totalNotificationCount.current > card.length )  { //INFo: In the following, we are checking if the count is larger than number of rows
      const isFilter = filter ? `&category_id=` + filter : ''; //INFO: This isFilter is checking for filter
      const res = await fetch(
        `${SERVER_URL}/${GET_NOTIFICATION_LIST_API}?page=${page}&per_page=${DEFAULT_ROWS_PERPAGE_20}${isFilter}`,
        {
          headers: myHeaders,
          method: 'GET'
        }
      );

      const data = await res.json();
      if (totalNotificationCount.current == null) { totalNotificationCount.current = data?.data?.count }//INFO: setting the count 
      if (isFilter && page == 0 && data?.data?.rows && data?.data?.rows?.length > 0) { setCard(data?.data?.rows); totalNotificationRow.current = data?.data?.rows }
      else if ( page == 0 && data?.data?.rows && data?.data?.rows?.length > 0) { setCard(data?.data?.rows); totalNotificationRow.current = data?.data?.rows }
      else if ( page != 0&& data?.data?.rows && data?.data?.rows?.length > 0) { totalNotificationRow.current = [...totalNotificationRow.current, ...data?.data?.rows]; setCard((prev) => [...prev, ...data?.data?.rows]); }
      else if (isFilter && page == 0 && data?.data?.rows && data?.data?.rows?.length == 0) { setCard([]); totalNotificationRow.current = null }
    }
    setLoading(false);

  };

  const handelInfiniteScroll = async () => {
      try {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
          // INFO: since in this function, the current value of card and totalNotification was not accessible,
          // In the following state, we are checking if the count is larger than number of rows
          setLoading((prev) => {
            if(totalNotificationCount.current==null || (totalNotificationCount.current > totalNotificationRow?.current?.length )){return !prev}
            else{return prev}});

          setPage((prev) => {
            if(totalNotificationCount.current==null || (totalNotificationCount.current > totalNotificationRow?.current?.length )){return prev + 1}
            else{return prev}
            });
        }
      } catch (error) {
        console.log(error);
      }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  //INFO: This useEffect is to call the category list for the first time only.
  useEffect(() => {
    dispatch(NotificationCategoryList(auth?.authtoken))
  }, []);

  // INFO: This useeffect will be called when the page or filter will change to load more data
  useEffect(() => {
    getCardData();
  }, [page,filter,isClick]);

  const resetFunction=()=>{
    setPage(PAGES)
    totalNotificationCount.current=null
    totalNotificationRow.current=[]
    setCard([])
    setLoading(true)
  }

  return (
    <Page title="Notifications">
      {/* Breadcrumbs */}
      <Grid container display="row" justifyContent="space-between">
        <EHeaderBreadcrumbs
          heading="Notifications"
          links={[
            { name: "Dashboard", href: "/" },
            { name: "Notifications", href: "" },
          ]}
        />
        <EHidden width="mdDown">
          <Grid>
            <EButtonOutlined
              className="button-left-margin"
              variant="outlined"
              onClick={() => navigate(-1)}
            >
              {" "}
              Back{" "}
            </EButtonOutlined>
          </Grid>
        </EHidden>
      </Grid>

      {/* Header */}
      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="pb-2rem margin-top-20px"
        marginBottom={2}
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Notifications
          </ETypographyPageHeading>
        </Grid>
        {/** code comment by ajit as per discussion with sunil and jeera sheet requirement */}
        {/* <EHidden width="mdDown">
          <Grid item>
            <ETypographyPageHeading
              variant="h5"
              className="font-size-16px color-staticBlue3 font-weight-500-with-imp cursor-pointer"
              onClick={() => { navigate("/notification-setting") }}
            >
              Notification Settings
            </ETypographyPageHeading>
          </Grid>
        </EHidden> */}

        <EHidden width="mdUp">
          <Grid item>
            <EButtonIcon
              variant="contained"
              className="button-left-margin"
              onClick={() => {
                navigate("/notification-setting");
              }}
            >
              <EIcon icon={SettingIcon} className='height-width-15px'/>{" "}
            </EButtonIcon>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={BackIcon} className='height-width-15px'/>{" "}
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>

        <EHidden width="mdDown">
          <Grid lg={12} xs={12} item>
            <EDivider className="margin-top-05" />
          </Grid>
        </EHidden>
      </Grid>

      {/* Page Container */}
      <EBoxNotification>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} lg={10} xl={12}>
            <Grid item sm={6} lg={6} marginBottom={2}>
              <EAutocomplete
                name="name"
                fullWidth
                options={
                  notificationCategory?.notificationCategoryData
                    ? notificationCategory?.notificationCategoryData
                    : []
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.notification_category || ""}
                onChange={(e,value)=>{
                  setFilter(value?.id?value?.id:null)
                  resetFunction()
                }}
                renderInput={(params) => (
                  <ETextFieldSearch
                    {...params}
                    placeholder="Filter Notifications"
                    name="name"
                    size="small"
                    sx={{ width: "284px" }}
                  />
                )}
              />
            </Grid>

            {/* card */}
            <Grid item alignItems="center" justifyContent="center">
              <ECard sx={{ padding: "0" }}>
                {card && card?.length == 0 ? (
                  <Grid
                    container
                    alignItems="center"
                    sx={{ height: "78vh" }}
                    justifyContent="center"
                  >
                    <Grid item padding={2}>
                      <>
                        <img
                          height="188.64px"
                          width="213px"
                          src="/assets/images/notification.svg"
                          className="mb-40px ml-50px"
                          alt="no-notification"
                        />
                        <ETypography
                          variant="h4"
                          color={"grey.500"}
                          className="text-align font-size-20px font-weight-600"
                        >
                          No Notifications{" "}
                        </ETypography>
                        <ETypography variant="body1" color={"grey.500"}>
                          Entera has no notifications for you.
                        </ETypography>
                      </>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item padding={3}>
                    {card && card?.length > 0 && card?.map((item) => {
                        return (
                          <NotificationItem key={item.id} data={item} 
                          reset={()=>{resetFunction(true); setClick(!isClick);} } />
                        );
                      })}
                    {loading && <LoadingGrid/>  }
                  </Grid>
                )}
              </ECard>
            </Grid>
          </Grid>
        </Grid>
      </EBoxNotification>
    </Page>
  );
}

export default NotificationDetail;
