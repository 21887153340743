import React, { useEffect, useState } from 'react'
import { Grid, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from "moment";
import RightArrowIcon from '@iconify/icons-material-symbols/chevron-right-rounded'

import EModal from '../../../BaseComponents/EModal';
import { PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_TRANSACTION_CATEGORY } from '../../../constants';
import { EIcon } from '../../../BaseComponents/EIcon';
import SearchNotFound from '../../../PageComponents/SearchNotFound';
import { useLocation, useNavigate } from 'react-router-dom';
import { EBoxPage } from '../../../BaseComponents/EBox';
import { ETypography } from '../../../BaseComponents/ETypography';
import { numberWithCommas } from '../../../utils/formatNumber';
import { EDataGrid, EPageWithBreadCrumbs } from '../../../BaseComponents';
import { SelectableCard } from '../../../PageComponents/Payment/SelectableCard';
import PaymentBulkModal from '../../../PageComponents/Payment/PaymentBulkModal';
import { useBlocker } from '../../../hooks';
import { EButtonOutlined, ELoadingButton } from '../../../BaseComponents/EButtons';


const PAGE_TITLE = "Bulk Payment"

export const BulkPayment = () => {

  const theme = useTheme()
  const navigate = useNavigate();
  const route = useLocation()
  const data = route.state || []

  const [modalState, setModalState] = useState({ modalData: null, modalName: null });
  const [selectedData, setSelectedData] = useState({
    type: '',
    data: data?.data,
  })

  const [advanceData, setAdvanceData] = useState({ data: [], total: 0, type: 'advance' })
  const [reimbursementData, setReimbursementData] = useState({ data: [], total: 0, type: 'reimbursement' })
  const [otherData, setOtherData] = useState({ data: [], total: 0, type: 'other' })

  const auth = useSelector((state) => state.authReducer)

  const payment = useSelector((state) => state.PaymentReducer);

  const [showModal, setShowModal, continueNavigate] = useBlocker(true)

  useEffect(() => {
    if (data?.data?.length > 0) {
      const advance = []
      const reimbursement = []
      const other = []
      let totalAdvance = 0
      let totalReimbursement = 0
      let totalOther = 0
      data?.data?.map((item) => {
        if (item?.transaction_category === PAYMENT_CATEGORY_ADVANCE) {
          totalAdvance += Number(item?.pay_amount || 0)
          advance.push(item)
        }
        if (item?.transaction_category === PAYMENT_CATEGORY_REIMBURSEMENT) {
          totalReimbursement += Number(item?.pay_amount || 0)
          reimbursement.push(item)
        }
        if (item?.transaction_category === PAYMENT_CATEGORY_OTHER) {
          totalOther += Number(item?.pay_amount || 0)
          other.push(item)
        }
      })
      setAdvanceData({ data: advance, total: totalAdvance, type: 'advance' })
      setReimbursementData({ data: reimbursement, total: totalReimbursement, type: 'reimbursement' })
      setOtherData({ data: other, total: totalOther, type: 'other' })
    }
  }, [data])


  const columns = [
    {
      field: 'sno',
      headerName: 'SNo.',
      flex: 1,
      width: 50,
      minWidth: 50,
      maxWidth: 80,
      sortable: false,
      editable: false,
      renderCell: (params) => (params?.api?.getRowIndexRelativeToVisibleRows(params?.row?.id) || 0) + 1,
    },
    {
      field: 'transaction_date',
      headerName: 'Date',
      minWidth: 120,
      maxWidth: 120,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return moment(params?.row?.transaction_date).format('DD/MM/yyyy')

      }
    },
    {
      field: 'TalentName',
      headerName: 'Employee Name',
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ETypography
            variant="subtitle1" noWrap className="cursor-pointer  font-size-14px text-capitalize blue-color"
          >
            {params?.row?.employeeData?.fullname ? params?.row?.employeeData?.fullname : '-'}
            <br />
            <ETypography
              variant="span" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color font-weight-400-with-imp"
            >
              ({params?.row?.employeeData?.employee_code ? params?.row?.employeeData?.employee_code : '-'})
            </ETypography>
          </ETypography>
        )
      }
    },
    {
      field: 'Particular',
      headerName: 'Particular',
      minWidth: 80,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {PAYMENT_TRANSACTION_CATEGORY[params?.row?.transaction_category] || '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'Amount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Request Amount',
      type: 'number',
      minWidth: 200,
      maxWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.pay_amount ? numberWithCommas(params?.row?.pay_amount) : '-'}
          </ETypography>
        );
      }
    },
  ]

  const handleClick = (type) => {
    if (type === 'advance') {
      const _type = type === selectedData.type ? '' : advanceData?.type
      const _data = type === selectedData.type ? data?.data : advanceData?.data
      setSelectedData({
        type: _type,
        data: _data
      })
    }
    if (type === 'reimbursement') {
      const _type = type === selectedData.type ? '' : reimbursementData?.type
      const _data = type === selectedData.type ? data?.data : reimbursementData?.data
      setSelectedData({
        type: _type,
        data: _data
      })
    }
    if (type === 'other') {
      const _type = type === selectedData.type ? '' : otherData?.type
      const _data = type === selectedData.type ? data?.data : otherData?.data
      setSelectedData({
        type: _type,
        data: _data
      })
    }
  }

  return (
    <>
      <EPageWithBreadCrumbs
        title={PAGE_TITLE}
        pageHeading={PAGE_TITLE}
        breadcrumbsTitle={PAGE_TITLE}
        breadcrumbsLinks={[
          { name: 'Dashboard', href: '/' },
          { name: 'Payment', href: '/payment/payment-request' },
          { name: 'Payment Request', href: '/payment/payment-request' },
          { name: PAGE_TITLE },
        ]}
        loading={false}
      >
        <EBoxPage>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowGap={2}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <SelectableCard
                    title='Advance'
                    subTitle={numberWithCommas(advanceData?.total)}
                    selected={selectedData.type === advanceData?.type}
                    onClick={() => handleClick(advanceData?.type)}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SelectableCard
                    title='Reimbursement'
                    subTitle={numberWithCommas(reimbursementData?.total)}
                    selected={selectedData.type === reimbursementData?.type}
                    onClick={() => handleClick(reimbursementData?.type)}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SelectableCard
                    title='Other'
                    subTitle={numberWithCommas(otherData?.total)}
                    selected={selectedData.type === otherData?.type}
                    onClick={() => handleClick(otherData?.type)}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <SelectableCard
                    title='Payable Amount'
                    subTitle={numberWithCommas(advanceData?.total + reimbursementData?.total + otherData?.total)}
                    selected={false}
                    cardSx={{
                      backgroundColor: theme.palette.primary.main,
                      borderColor: theme.palette.primary.main,
                    }}
                    titleSx={{ color: '#fff' }}
                    subTitleSx={{ color: '#fff' }}
                    right={<EIcon icon={RightArrowIcon} sx={{ height: 36, width: 36, color: '#fff' }} />}
                    onClick={() => setModalState({ modalData: { ...data, payment_ids: data?.data?.map(i => i?.id), total_amount: advanceData?.total + reimbursementData?.total + otherData?.total }, modalCategory: 'BULK_PAYMENT' })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <EBoxPage className='p0'>
                {
                  !payment?.PaySalaryListData?.payrollListLoading && payment?.PaySalaryListData?.rows?.length == 0
                    ?
                    <SearchNotFound />
                    :
                    <EDataGrid
                      columns={columns}
                      rows={selectedData?.data || []}
                      loading={payment?.PaySalaryListLoading}
                    />
                }
              </EBoxPage>
            </Grid>
          </Grid>
        </EBoxPage>
      </EPageWithBreadCrumbs>

      {
        modalState.modalCategory && modalState.modalCategory === 'BULK_PAYMENT' &&
        <EModal
          open={modalState.modalCategory === 'BULK_PAYMENT'}
          headervalue={'Bulk Payment'}
        >
          <PaymentBulkModal
            paymentData={modalState.modalData}
            setModalState={setModalState}
            getPaymentFilterData={() => navigate('/payment/payment-request')}
            auth={auth}
          />
        </EModal>
      }
      <EModal
        open={showModal}
        headervalue={"Confirmation"}
        parentClassname='delete-confirmation-modal'
      >
        <Grid container rowSpacing={2}>
          <Grid item>
            <ETypography className="font-size-18px ">
              Any unsaved changes will be lost. Would you like to continue?
            </ETypography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
            <EButtonOutlined size="large" variant="outlined" onClick={() => setShowModal(false)}> No</EButtonOutlined>
            <ELoadingButton size="large" type="button" variant="contained" onClick={continueNavigate} className='button-left-margin'> Yes</ELoadingButton>
          </Grid>
        </Grid>
      </EModal>
    </>
  )
}
