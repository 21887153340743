import React, { useEffect, useState } from 'react'
import { EPageWithBreadCrumbs } from '../../../BaseComponents'
import { EBoxPage } from '../../../BaseComponents/EBox'
import { PaymentSearchFilter } from '../../../PageComponents/Payment/PaymentSearchFilter'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../../BaseComponents/ETable'
import { ECircularProgress } from '../../../BaseComponents/ECircularProgress'
import { COST_TO_COMPANY_BYMONTH_DOWNLOAD, DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE } from '../../../constants'
import {   useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ETypography } from '../../../BaseComponents/ETypography'
import { numberWithCommas } from '../../../utils/formatNumber'
import { ETablePagination } from '../../../BaseComponents/ETablePagination'
import { CostToCompanyDetailByMonth} from '../../../action/ReportAction'
import { DesignationList } from '../../../action/DesignationAction';
import SearchNotFound from '../../../PageComponents/SearchNotFound'
import { DownLoadFile } from '../../../action/AdminAction'


function CostToCompanyDetail() {
    const { costToCompanyListData, costToCompanyListLoading } = useSelector(state => state.CostToCompanyListReducer)
    const auth = useSelector(state => state.authReducer);
    const designation = useSelector((state) => state.DesignationReducer.designationListData?.rows);
    const dispatch = useDispatch();
    const {id} = useParams()


    const [searchParam, setSearchParam] = useState({ end: '', start:  moment(id).format('yyyyMM'), sort: true });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        const params = {
            year_month: fnParams?.start ? moment(fnParams?.start).format('yyyyMM') : null,
            sort: fnParams?.sort,
            employee_id:fnParams?.employee?.id,
            designation_id:fnParams?.particular?.id
        }
        dispatch(CostToCompanyDetailByMonth({ ...params, page: fnPage, per_page: fnRowPerPage }))
    }



    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...searchParam }, page, rowsPerPage);
            dispatch(DesignationList(auth.authtoken))
        }
    }, [])


    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };




    function onSearch(data) {
        setSearchParam(data)
        callApi(data, page, rowsPerPage);
    }


  let totalExpense = 0
  let totalSaving = 0

  const DownloadReport = (type) => {  

    const year_month= searchParam?.start ? searchParam?.start : null;
    const employee_id= searchParam?.employee?.id;
    const designation_id= searchParam?.particular?.id;
    const sort= searchParam?.sort;
    let params = {
      download_format: type,
      ...(year_month && { year_month }),
      ...(employee_id && { employee_id }),
      ...(sort && { sort }),
      ...(designation_id && { designation_id }),
    };
    const queryString = new URLSearchParams(params).toString();
    const url = `${COST_TO_COMPANY_BYMONTH_DOWNLOAD}?${queryString}`;
    DownLoadFile(auth.authtoken, url, "cost-to-company-report")

  }

    const TABLE_HEAD = getTableHead();
    return (
           <EPageWithBreadCrumbs
            title="Cost to Company Report"
            pageHeading={`Cost to Company Report - ${id.split('-').join(' ')}`}
            breadcrumbsTitle="Cost to Company Report"
            breadcrumbsLinks={[
                { name: 'Dashboard', href: '/' },
                { name: 'Report', href: '' },
                { name: 'Cost to Company Report' }
            ]}
              loading={costToCompanyListLoading || Object.keys(costToCompanyListData)?.length == 0}
        >
            <EBoxPage>
                {/* SEARCH FILTER */}
                <PaymentSearchFilter
                    initialFilter={searchParam}
                    onSearch={onSearch}
                    sortTitle='Sort By Name'
                    onSort={onSearch}
                    showAllTalents={true}
                    particulars={designation}
                    particularPlaceholder='All Designation'
                    talentOrder={2}
                    DownloadReport={DownloadReport}
                    withoutDateValidation={true}
                />
               
                {/* TABLE  */}

                {
                    costToCompanyListData?.rows?.length == 0 ? 
                    <SearchNotFound /> : 
                    <>
                     <ETableContainer  className='mt-16px'>
                    <ETable>
                        <EListHead
                            headLabel={TABLE_HEAD}
                        />

                        {costToCompanyListLoading && (
                            <ETableBody>
                                <ETableRow>
                                    <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                        <ECircularProgress color="primary" />
                                    </ETableCell>
                                </ETableRow>
                            </ETableBody>
                        )}

                        {
                            costToCompanyListData?.rows?.length > 0 && (
                                <ETableBody>
                                    {
                                        costToCompanyListData?.rows?.map((row,index)=>{
                                            const {expenses,savings,payrollData,employeeData} = row
                                            totalExpense += Number(expenses)
                                            totalSaving += Number(savings)
                                           
                                            return (
                                                <ETableRow
                                                    sx={{ 'cursor': 'pointer' }}
                                                    key={index}
                                                >

                                                    {/* Sno */}
                                                    <ETableCell align="left" sx={{ padding: '10px' }}>
                                                        {index + 1 + (page * 10)}

                                                    </ETableCell>

                                                    {/* Talent Name */}
                                                    <ETableCell align="left" sx={{ padding: '10px' }}>
                                                        {employeeData?.fullname}
                                                        <br/>
                                                      (  {employeeData?.employee_code} )
                                                    </ETableCell>

                                                    {/* Designation */}
                                                    <ETableCell align="left" sx={{ padding: '10px' }}>
                                                        {employeeData?.designationInfo?.designation_name}
                                                    </ETableCell>

                                                    {/* Expense  */}
                                                    <ETableCell align="right" sx={{ padding: '10px' }}>

                                                        {numberWithCommas(expenses)}
                                                    </ETableCell>

                                                    {/* Saving */}
                                                    <ETableCell align="right" sx={{ padding: '10px' }}>
                                                        {numberWithCommas(savings)}
                                                    </ETableCell>

                                                </ETableRow>
                                            )
                                        })
                                    }

                                </ETableBody>

                            )

                        }

                                <ETableBody>
                                    <ETableRow>
                                        {/* Sno */}
                                        <ETableCell colSpan={3} align="left" sx={{ padding: '10px' }}>
                                            <ETypography className='font-size-14px bold-600'>
                                                Total
                                            </ETypography>
                                        </ETableCell>
                                        {/* Expeses  */}
                                        <ETableCell align="right" >
                                            <ETypography className='font-size-14px bold-600 theme-color-static '>
                                                {numberWithCommas(totalExpense)}
                                            </ETypography>
                                        </ETableCell>
                                        {/* Saving */}
                                        <ETableCell align="right" >
                                            <ETypography className='font-size-14px bold-600 theme-color-static '>
                                                {numberWithCommas(totalSaving)}
                                            </ETypography>
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                    </ETable>

                </ETableContainer>
                

                            {costToCompanyListData?.count > 10 &&
                                <Grid container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center">
                                    <ETablePagination
                                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                                        component="div"
                                        count={costToCompanyListData?.count ? costToCompanyListData?.count : 10}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            }
                        </>
                   

                }


            </EBoxPage>





        </EPageWithBreadCrumbs>
  )
}

export default CostToCompanyDetail

const getTableHead = () => {
    let tblHead = [
        { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Employee Name', label: 'Employee Name', alignRight: false },
        { id: 'Designation', label: 'Designation', alignRight: false },
        { id: 'Expense', label: 'Expense', alignRight: true },
        { id: 'Saving', label: 'Saving', alignRight: true },
    ];
    return tblHead;
};
