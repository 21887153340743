import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack } from '@mui/material';
import moment from 'moment';
import { ViewLeave } from '../../action/EmployeeLeaveAction';
import { EButtonOutlined, EIconButton } from '../../BaseComponents/EButtons';
import { ETypography } from '../../BaseComponents/ETypography';
import { ELabelInputMainGrid, LoadingGrid } from '../../BaseComponents/EGrid';
import { EIcon, EIconSmall } from '../../BaseComponents/EIcon';
import ArrowUp from '@iconify/icons-material-symbols/keyboard-arrow-up-rounded';
import ArrowDown from '@iconify/icons-material-symbols/keyboard-arrow-down-rounded';
import { EBox } from '../../BaseComponents/EBox';
import { EConnector, EStep, EStepIcon, EStepLabel, EStepper } from '../../BaseComponents/EStepper';
import { ETooltip } from '../../BaseComponents/ETooltip';
import InfoIcon from '@iconify/icons-material-symbols/info-outline-rounded';
import { formatDatePeriod, getDayCount } from '../../utils/CommonFunctions';
import { APPROVED_STATUS, HR_STAGE, REJECTED_STATUS, RM_STAGE, TALENT_STAGE } from '../../constants';
import { capitalizeCapitalString } from '../../utils/formatText';
import SearchNotFound from '../../PageComponents/SearchNotFound';

/** INFO: This component is made for leave View; This is for all roles.
 * @param {Object} auth - here we're getting auth data
 * @param {function} close - here we're getting a function to close this modal
 * @param {Object} details - in this object will contain value- id- from list leave data
 **/

function LeaveViewForm(props) {
    const dispatch = useDispatch()
    const { auth, close, details } = props
    const leave = useSelector(state => state.LeaveEmployeeReducer);// ViewLeave Action reducer
    let leaveData = leave?.empLeaveViewData;
    const { label_from_fullday, label_to_fullday, label_from_halfday, label_to_halfday } = leaveData;

    // INFO: state for the show/hide of time periods details
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        if (auth?.authtoken && details?.id) {
            dispatch(ViewLeave(auth.authtoken, details?.id));
        }
    }, [auth.authtoken])

    // INFO: steps value for view leave action progress.
    const steps = useMemo(() =>
        getSteps(leaveData),
        [leaveData]);

    // Info: setting the stage for the progress Stepper
    const CompletedStage = useMemo(() => getCurrentNode(leaveData?.is_direct_status_changed), [leaveData?.is_direct_status_changed]);

    // Counting half days & full days
    const TotalNumberOfFullDays = label_from_fullday || label_to_fullday ? getDayCount(label_from_fullday, label_to_fullday) : null;
    const TotalNumberOfHalfDays = label_from_halfday && label_to_halfday && !moment(new Date(label_from_halfday)).isSame(new Date(label_to_halfday)) ?
        2 : label_from_halfday ? 1 : label_to_halfday ? 1 : null;

    return (<>
        {leave?.empLeaveViewLoading ?
            <LoadingGrid />
            : leaveData && Object.keys(leaveData).length > 0 ?
                <Grid container spacing={2} >
                    {/* Status Indicator- Stepper */}
                    <Grid item xs={12} xl={12} className='p0'>
                        <EBox className='mt-24px mb-16px height-50px position-relative overflow-hidden'>
                            
                            <EStepper activeStep={CompletedStage} connector={<EConnector />} alternativeLabel >
                                
                                {steps && steps?.map((row) => (
                                    <EStep key={row?.id} >
                                        <EStepLabel
                                            StepIconComponent={EStepIcon}>
                                            <span className=' display-flex justify-content-center align-center'>
                                                {row?.label}
                                                {row?.remark ?
                                                    <ETooltip arrow title={
                                                        <span className='text-align-webkit-center'>
                                                            {row?.name ? <span>{row?.name}<br /></span> : ''}
                                                            <span >{row?.remark}</span>
                                                        </span>}>
                                                        <EIconSmall icon={InfoIcon} className='height-width-18px color-text-primary' />
                                                    </ETooltip>
                                                    : ''
                                                }
                                            </span>
                                        </EStepLabel>
                                    </EStep>
                                ))}
                            </EStepper>
                        </EBox>
                    </Grid>
                    {/* Applied Date */}
                    <Grid item xs={12} xl={12}>
                        <ELabelInputMainGrid label={'Applied Date'} isNotForm={true} isModal={true} isfullgrid={true}>
                            <ETypography >
                                {leaveData?.applied_date ? moment(leaveData?.applied_date).format('DD/MM/YYYY') : ''}

                            </ETypography>
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Leave Period */}
                    <Grid item xs={12} xl={12}>
                        <ELabelInputMainGrid label={'Leave Period'} isNotForm isModal isfullgrid>
                            <ETypography>
                                {leaveData ? formatDatePeriod(leaveData?.from_date, leaveData?.to_date, 'DD/MM/YYYY') : '-'}

                                {/* Conditionally: Show Details */}
                                <EIconButton onClick={() => { setShowDetails(!showDetails) }} className='p0 '> <EIcon className='ml-16px theme-color-static ' icon={showDetails ? ArrowUp : ArrowDown} />  </EIconButton>

                                {showDetails ?
                                    <span className='greyColor4-color font-size-14px'>
                                        <br />

                                        {/* Full Days Info: show if TotalNumberOfFullDays!=0 */}
                                        {TotalNumberOfFullDays ? showFullDayLeaveDetails(label_from_fullday, label_to_fullday, TotalNumberOfFullDays) : ''}

                                        {/* putting break if we have both TotalNumberOfFullDays && TotalNumberOfHalfDays */}
                                        {TotalNumberOfFullDays && TotalNumberOfHalfDays ? <br /> : ''}

                                        {/* Half Days Info */}
                                        {TotalNumberOfHalfDays ? showHalfDayLeaveDetails(label_from_halfday, label_to_halfday, TotalNumberOfHalfDays) : ''}

                                    </span>
                                    : ''
                                }
                            </ETypography>
                        </ELabelInputMainGrid>
                    </Grid>

                    {/* Reason */}
                    <Grid item xs={12} xl={12}>
                        <ELabelInputMainGrid label={'Reason'} isNotForm isModal isfullgrid>
                            <ETypography >
                                {leaveData?.reason ? leaveData?.reason : "-"}
                            </ETypography>
                        </ELabelInputMainGrid>
                    </Grid>
                    {/* Status */}
                    <Grid item xs={12} xl={12}>
                        <ELabelInputMainGrid label={'Status'} isfullgrid isModal isNotForm >
                            {leaveData?.main_status ?
                                <span
                                    className={'bold-600 ' + (leaveData?.main_status == APPROVED_STATUS ? 'color-infoText' //Setting color according to status
                                        : leaveData?.main_status == REJECTED_STATUS ? 'color-errorText'
                                            : 'color-secondaryText')}>
                                    {leaveData?.main_status && leaveData?.main_status == 'HRPENDING' ? 'HR Pending' : capitalizeCapitalString(leaveData?.main_status)}
                                </span>
                                : ''
                            }
                        </ELabelInputMainGrid>
                    </Grid>


                </Grid>
                :
                <SearchNotFound page='dashboard' />
        }
        <Grid item xs={12} xl={12}>
            <Stack direction='row' spacing={2} className='modal1-buttons-stick-bottom '>
                <EButtonOutlined variant='outlined' size='large' onClick={() => close()}> Close </EButtonOutlined>
            </Stack>
        </Grid>
    </>
    );
}

export default LeaveViewForm


// This handles full day leave detail information string
export const showFullDayLeaveDetails = (label_from_fullday, label_to_fullday, TotalNumberOfFullDays) => {
    let resultString = '';
    // checking if the dates are equal
    var isEqual = label_from_fullday && label_to_fullday ? moment(new Date(label_from_fullday)).isSame(new Date(label_to_fullday)) : false;

    // defining end string
    let endString = ' (Full day) - ' + TotalNumberOfFullDays + (TotalNumberOfFullDays == 1 || isEqual ? ' day' : ' days')

    //formatting the date in from date - to - end date format using formatDatePeriod function
    if (label_from_fullday || label_to_fullday) {
        resultString = formatDatePeriod(label_from_fullday, label_to_fullday, 'DD/MM/YYYY');
    }

    return resultString ? resultString + endString : '';
}

// This handles half day leave detail information string
export const showHalfDayLeaveDetails = (label_from_halfday, label_to_halfday, TotalNumberOfHalfDays) => {
    let resultString = '';

    // checking if the dates are equal
    var isEqual = label_from_halfday && label_to_halfday ? moment(new Date(label_from_halfday)).isSame(new Date(label_to_halfday)) : false;

    // // defining end string
    let endString = ' (Half day) - ' + TotalNumberOfHalfDays + (TotalNumberOfHalfDays == 1 || !isEqual ? ' day' : ' days');

    // half from day 
    if (label_from_halfday) {
        resultString += moment(label_from_halfday).format('DD/MM/YYYY')
    }
    if (label_to_halfday && !isEqual) {
        resultString += resultString ? ', ' : ""; // putting comma conditionally
        resultString += moment(label_to_halfday).format('DD/MM/YYYY')
    }

    return resultString ? resultString + endString : '';
}


/**
 * This function is returning the steps array
 * @param {Object} leaveData ; data coming from view api
 * @param {Object} leaveSettings leave setting data
 * @returns {Array of Objects} Returns steps object
 */
export const getSteps = (leaveData) => {

    //Hr remark
    const HR_Remark = leaveData?.is_direct_status_changed !== 2 ? null : leaveData?.final_approval_remark;

    const steps = [{ label: 'Employee', id: 1 },
    { label: 'Reporting Manager', id: 2, remark: leaveData?.reporting_manager_remark, name: leaveData?.reportingManager?.fullname ? leaveData?.reportingManager?.fullname : null },
    { label: 'HR', id: 3, remark: HR_Remark, name: leaveData?.authorizedBy?.fullname ? leaveData?.authorizedBy?.fullname : null }];

    return steps;
}

/**
 * This function is returning the steps array
 * Here, the values of is_direct_status_changed means:
 * null-> This is the initial phase when employee has only applies leave and no actions has been taken on this.
 * 0-> If the Leave has not been responded by RM or HR and hence it has been auto rejected.
 * 1-> if the action has been taken by the RM.
 * 2-> if the action has been taken by HR or any other superiors.
 * @param {Number} is_direct_status_changed ; is_direct_status_changed defines the stage of leave
 * @returns {Number} Returns stage of leave i.e. TALENT_STAGE/ HR_STAGE/ RM_STAGE.
 */
export const getCurrentNode = (is_direct_status_changed) => {
    const currentNode = is_direct_status_changed && is_direct_status_changed === 2 ? HR_STAGE
        : is_direct_status_changed === 1 ? RM_STAGE
            : TALENT_STAGE;

    return currentNode;
}