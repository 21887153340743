import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Page from "../../BaseComponents/EPage";
import EditIcon from "@iconify/icons-material-symbols/edit-outline";
import search from "@iconify/icons-material-symbols/search";
import { DeleteDepartment, DepartmentList } from "../../action/DepartmentAction";
import {
  EListHead,
  ETable,
  ETableBody,
  ETableCell,
  ETableContainer,
  ETableRow,
} from "../../BaseComponents/ETable";
import { DEFAULT_ROWS_PERPAGE, PERMISSIONS, ROW_SIZE_PER_PAGE } from "../../constants";
import { EIcon, EIconInputAdornment } from "../../BaseComponents/EIcon";
import {
  EButton,
  EButtonIcon,
  EButtonOutlined,
  EButtonOutlinedIcon,
  ELoadingButton,
} from "../../BaseComponents/EButtons";
import {
  ETypography,
  ETypographyPageHeading,
} from "../../BaseComponents/ETypography";
import {
  EInputAdornment,
  ETextFieldSearch,
} from "../../BaseComponents/ETextField";
import { EDivider } from "../../BaseComponents/EDivider";
import { EBoxPage } from "../../BaseComponents/EBox";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import DepartmentForm from "../../PageComponents/Masters/DepartmentForm";
import EModal from "../../BaseComponents/EModal";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import CancelIcon from "@iconify/icons-material-symbols/cancel-outline-rounded";
import DeleteIcon from "@iconify/icons-material-symbols/delete-outline-rounded";
import AddDocumentIcon from "@iconify/icons-material-symbols/post-add-rounded";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";
import EHidden from "../../BaseComponents/EHidden";
import EChip from "../../BaseComponents/EChip";
import { Stack } from "@mui/system";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { useCheckPermission } from "../../hooks";


const getTableHead = (allowAction) => {
    const arrayHead = [
      { id: "Department", label: "Department", alignRight: false },
      { id: "Status", label: "Status", alignRight: "center" },
    ];
    if (allowAction) {
        arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
    }
    return arrayHead;
};

function Department(props) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authReducer);
  const Theme = useSelector((state) => state.ThemeReducer);
  const dept = useSelector((state) => state.DepartmentReducer);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isEdit, SetIsEdit] = useState({ open: false, data: null });
  const [isDelete, setIsDelete] = useState({ open: false, data: null });
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

  const [SearchParameters, setSearchParameters] = useState({
    department: "",
  });

  const allowAddEditDeleteMaster = useCheckPermission([PERMISSIONS.ViewAddEditDeleteMaster])

  const TABLE_HEAD = useMemo(() => getTableHead(allowAddEditDeleteMaster), [])

  const deleteDepartment = (data,close) =>{
    dispatch(DeleteDepartment(auth.authtoken, data,close))
  }

  useEffect(() => {
    if (auth.authtoken && !isEdit.open) {
      callApi(SearchParameters, page, rowsPerPage);
    }
  }, [auth?.authtoken, isEdit.open]);

  const handleChangePage = (event, newPage) => {
    callApi(SearchParameters, newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
    setRowsPerPage(rowPerPageCount);
    callApi(SearchParameters, 0, rowPerPageCount);
    setPage(0);
  };

  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    dispatch(DepartmentList(auth.authtoken, fnParams, fnPage, fnRowPerPage));
  };

  const onSearch = (value) => {
    const searchParm = { department: value };
    callApi(searchParm, 0, rowsPerPage);
    setSearchParameters(searchParm);
    setPage(0);
  };

  return (
    <Page title="Department">
      <Grid
        container
        display="row"
        justifyContent="space-between"
        className="align-center"
      >
        <Grid item>
          <EHeaderBreadcrumbs
            heading="Department Master"
            links={[
              { name: "Dashboard", href: "/" },
              { name: "Master", href: "" },
              {
                name: "Department Master",
              },
            ]}
          />
        </Grid>
        {/* INFO: This complonent will be hidden wgen screen size goes below md */}
        <EHidden width="mdDown">
          <Grid item>
            <Grid item>
              {
                allowAddEditDeleteMaster &&
                <EButton
                  variant="contained"
                  onClick={() => SetIsEdit({ ...isEdit, open: true, data: null })}
                >
                  {" "}
                  Add Department{" "}
                </EButton>
              }
              <EButtonOutlined
                className="button-left-margin"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                {" "}
                Back{" "}
              </EButtonOutlined>
            </Grid>
          </Grid>
        </EHidden>
      </Grid>

      <Grid
        container
        display="row"
        className="pb-2rem margin-top-20px justify-content-space-between align-flex-end"
      >
        <Grid item>
          <ETypographyPageHeading variant="h5">
            Department Master
          </ETypographyPageHeading>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            {
              allowAddEditDeleteMaster &&
              <EButtonIcon
                onClick={() => SetIsEdit({ ...isEdit, open: true, data: null })}
                component={Link}
                variant="contained"
              >
                <EIcon icon={AddDocumentIcon} className='height-width-15px'/>
              </EButtonIcon>
            }
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => navigate(-1)}
              className="button-left-margin"
            >
              {" "}
              <EIcon icon={ForwardArrow} className='height-width-15px'/>{" "}
            </EButtonOutlinedIcon>
          </Grid>
        </EHidden>
        <EHidden width="lgDown">
          <Grid item>
            <ETextFieldSearch
              // fullWidth
              color="primary"
              size="small"
              placeholder="Search"
              value={SearchParameters?.department}
              onChange={(e) => {
                onSearch(e.target.value ? e.target.value : "");
              }}
              InputProps={{
                startAdornment: (
                  <EInputAdornment position="start">
                    <ETooltip arrow title="Search">
                    <EIconInputAdornment icon={search}></EIconInputAdornment>
                    </ETooltip>
                  </EInputAdornment>
                ),
                endAdornment: (
                  <EInputAdornment
                    position="end"
                    sx={{ margin: "0px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      onSearch("");
                    }}
                  >
                    <ETooltip arrow title="Cancel">
                    <EIcon icon={CancelIcon} />
                    </ETooltip>
                  
                  </EInputAdornment>
                ),
              }}
              sx={{
                width: {
                  xs: "150px",
                  sm: "254px",
                  md: "354px",
                  lg: "354px",
                  xl: "354px",
                },
              }}
            />
          </Grid>
        </EHidden>

        <Grid xs={12} lg={12} item>
          <EHidden width="mdDown">
          <EDivider className="margin-top-05" />
          </EHidden>
          
        </Grid>
      </Grid>

      <EBoxPage>
        <EScrollbar>
          <ETableContainer>
            <ETable>
              {dept?.departmentListLoading && (
                <>
                  <EListHead
                    headLabel={TABLE_HEAD}
                    rowCount={dept?.departmentListData?.rows?.length}
                    onRequestSort={() => null}
                    authvar={auth}
                    Theme={Theme}
                  />

                  <ETableBody>
                    <ETableRow>
                      <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <ECircularProgress color="primary" />
                      </ETableCell>
                    </ETableRow>
                  </ETableBody>
                </>
              )}
              {!dept?.departmentListLoading &&
                dept?.departmentListData?.rows?.length > 0 && (
                  <>
                    <EListHead
                      headLabel={TABLE_HEAD}
                      rowCount={dept?.departmentListData?.rows?.length}
                      onRequestSort={() => null}
                      authvar={auth}
                      Theme={Theme}
                    />
                    <ETableBody>
                      {dept.departmentListData.rows?.map((row, index) => {
                        const { department_name, status } = row;
                        return (
                          <ETableRow hover key={index + department_name}>
                            {/* department name */}
                            <ETableCell
                              align="left"
                              sx={{
                                textTransform: "capitalize",
                                minWidth: "160px",
                              }}
                            >
                              {department_name}
                            </ETableCell>

                            {/* status */}
                            <ETableCell
                              align="center"
                              sx={{
                                textTransform: "capitalize",
                                width: "100px",
                              }}
                            >
                              {status === 1 ? (
                                <EChip label="Enable" />
                              ) : (
                                <EChip label="Disable" />
                              )}
                            </ETableCell>

                            {/* action */}
                            {
                              allowAddEditDeleteMaster &&
                              <ETableCell
                                align="center"
                                style={{ width: "100px" }}
                              >
                                {row.company_id && row.company_id > 0 && (
                                  <>
                                  
                                      <EIcon
                                        className="cursor-pointer"
                                        onClick={() =>
                                          SetIsEdit({
                                            ...isEdit,
                                            open: true,
                                            data: row,
                                          })
                                        }
                                        icon={EditIcon}
                                      />
                                      {/* Showing delete only if status === 1 i.e enabled */}
                                      {status === 1 ? (
                                        <EIcon
                                          className="cursor-pointer icon-red ml-12px"
                                          onClick={() =>
                                            setIsDelete({
                                              ...isDelete,
                                              open: true,
                                              data: row,
                                            })
                                          }
                                          icon={DeleteIcon}
                                        />
                                      ) : (
                                        ""
                                      )}
                                  
                                  </>
                                )}
                              </ETableCell>
                            }
                          </ETableRow>
                        );
                      })}
                    </ETableBody>
                  </>
                )}
            </ETable>
          </ETableContainer>
        </EScrollbar>
        {!dept?.departmentListLoading &&
          dept?.departmentListData?.rows?.length == 0 && <SearchNotFound />}

{!dept?.departmentListLoading &&
        dept?.departmentListData?.count > DEFAULT_ROWS_PERPAGE && (
          <ETablePagination
            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
            component="div"
            count={
              dept?.departmentListData?.count
                ? dept?.departmentListData?.count
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </EBoxPage>
     

        {/* Edit modal */}
      {isEdit.open && (
        <EModal
          open={isEdit.open}
          close={() => {
            SetIsEdit({ ...isEdit, open: false });
          }}
          headervalue={isEdit.data ? "Update Department" : "Add Department"}
        >
          <DepartmentForm
            close={() => SetIsEdit({ ...isEdit, open: false })}
            rowData={isEdit?.data}
            dept={dept?.departmentListData?.rows}
            auth={auth}
          />
        </EModal>
      )}

    {/* Delete modal */}
      {isDelete.open && (
        <EModal
          open={isDelete.open}
          close={() => {
            setIsDelete({ ...isDelete, open: false });
          }}
          headervalue={
            !isDelete.data?.departmentInuse
              ? `Delete - ${isDelete.data.department_name}`
              : "Alert"
          }
        >
          {!isDelete.data?.departmentInuse ? (<>
            <ETypography>
              Are you sure you want to delete{" "}
              <span className="theme-main-text-color-bold">
                {isDelete.data.department_name} Department?
              </span>{" "}
            </ETypography>

            <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                setIsDelete({ ...isDelete, open: false });
              }}
            >
              No
            </EButtonOutlined>
            <ELoadingButton
              loading={dept?.departmentLoading}
              size="large"
              variant="outlined"
              onClick={()=>deleteDepartment(isDelete.data,setIsDelete({ ...isDelete, open: false }))}
            >
              Yes
            </ELoadingButton>
          </Stack>
          
          </>
          
          ) : (
            <>
             <ETypography>
              <span className="theme-main-text-color-bold">
              {isDelete.data.department_name} department
              </span> cannot be deleted at the moment. It
              seems that the users are active in this department. Please assign
              them to a different department before deleting <span className="theme-main-text-color-bold">
              {isDelete.data.department_name}
                </span> .
            </ETypography>
            
            
            
            <Stack
            direction="row"
            spacing={2}
            paddingTop={2}
            className="modal1-buttons-stick-bottom"
          >
            <EButtonOutlined
              size="large"
              variant="outlined"
              onClick={() => {
                setIsDelete({ ...isDelete, open: false });
              }}
            >
             Close
            </EButtonOutlined>
           
          </Stack>
            </>
           

          )}

          
        </EModal>
      )}
    </Page>
  );
}
export default Department;
