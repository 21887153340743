import {
    PLAN_FAILURE, PLAN_LOADING, PLAN_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    planListData: [],
    planListSuccess: false,
    planListLoading: false,
    planListMessage: null,

};

const PlanReducer = (plan = INITIAL_STATE, action) => {
    switch (action.type) {    

        case PLAN_LOADING:
            return {
                planListLoading: true,
                ...plan
            };

        case PLAN_SUCCESS:
            return {
                planListLoading: false,
                planListSuccess: true,
                planListData: action.payload.data,
                planListMessage: action.payload.message,
            };

        case PLAN_FAILURE:
            return {
                planListLoading: false,
                planListSuccess: false,
                planListMessage: action.payload.message,
                planListData: []
            };

        default:
            return plan;
    }
};

export default PlanReducer;
