import React, { useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';

import { AttendanceLeaveList, EmployeeLeaveBalance } from '../../action/EmployeeLeaveAction';
import SearchNotFound from '../../PageComponents/SearchNotFound';
import moment from 'moment';
import { capitalizeCapitalString } from '../../utils/formatText';
import ApplyLeaveForm from './ApplyLeaveForm';
import LeaveViewForm from '../../pages/Attendance/LeaveViewForm';
import LeaveApproveForm from '../../pages/Attendance/LeaveApproveForm';
import { DeleteLeave } from '../../action/EmployeeLeaveAction';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';

import { Grid, Stack } from '@mui/material';
import EModal from '../../BaseComponents/EModal';
import { EIcon } from '../../BaseComponents/EIcon';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../BaseComponents/ETable';
import { EButton, EButtonOutlined, EIconButton } from '../../BaseComponents/EButtons';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { ECircularProgress } from '../../BaseComponents/ECircularProgress';
import {
   CalendarYear, DEFAULT_ROWS_PERPAGE, FinancialYear, HRPENDING, LEAVE_HISTORY_TAB,
   LEAVE_LOG_DOWNLOAD_URL,
   LEAVE_REQUEST_TAB, LEAVE_STATUS_NON_PENDING_OPTIONS, LEAVE_STATUS_PENDING_OPTIONS, MY_LEAVE_TAB, PAGES, PENDING_STATUS, ROW_SIZE_PER_PAGE, TALENT_SM
} from '../../constants';
import EChip from '../../BaseComponents/EChip';
import { EBox } from '../../BaseComponents/EBox';
import { ETypography, ETypographyCardHeading } from '../../BaseComponents/ETypography';
import { EDividerCard } from '../../BaseComponents/EDivider';
import { ECard2 } from '../../BaseComponents/ECard';
import { ETablePagination } from '../../BaseComponents/ETablePagination';
import DeleteCircle from '@iconify/icons-material-symbols/delete-outline-rounded';
import ActionIcon from '@iconify/icons-material-symbols/chevron-right-rounded';
import editFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import EyeIcon from '@iconify/icons-material-symbols/visibility-outline-rounded';
import { PaymentSearchFilter } from '../../PageComponents/Payment/PaymentSearchFilter';
import { DownLoadFile } from '../../action/AdminAction';


// returns the options for leave status filter according to the tab selected
// if MY_LEAVE_TAB: all status options will be there in the dropdown
// if LEAVE_HISTORY_TAB: only approved & rejected status will show
const getLeaveOptions = (childTab) => {

   const LeaveStatusSearchOptions = childTab == MY_LEAVE_TAB ? [...LEAVE_STATUS_PENDING_OPTIONS, ...LEAVE_STATUS_NON_PENDING_OPTIONS] :
      childTab == LEAVE_HISTORY_TAB ? LEAVE_STATUS_NON_PENDING_OPTIONS : [];

   return LeaveStatusSearchOptions;
}

/**
 * INFO: This component is leave log page which is also reused in view employee( talent_id= employee's id) and my profile (isMyProfile= true & talent_id= employee's id). 
 * STATUS INFO: 1=> PENDING, 2=> PARTIALLY, 3=> APPROVED, 4 => REJECTED
 * 
 * @param {boolean} isMyProfile: coming from my profile page; since this is getting reused there in view profile-> leave log tab.
 * @param {number} talent_id : coming from My Profile & View Employee Profile component; talent_id will be set to employee_id and only his leaves will be shown.
 **/

function LeaveLog(props) {

   const { setSearchButtonBar, isMyProfile, talent_id, tabValue = MY_LEAVE_TAB } = props; //INFO: talent_id is for view-employee page
   const isFromOtherPage = talent_id ? true : false;

   const dispatch = useDispatch();

   const auth = useSelector(state => state.authReducer);
   const empleave = useSelector(state => state.LeaveEmployeeReducer);
   const leaveCount = empleave?.empLeaveBalanceData;
   const company = useSelector(state => state.CompanyReducer)
   // minimum date for date filter
   const minDAteForDateFilter = company?.companyData?.created_at ? company?.companyData?.created_at : null

   const [pageNo, setPageNo] = useState(PAGES); //stores page number
   const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE); // stores number of rows per page

   // INFO: Leave settings
   const settingsContext = useCompanySettingProvider(); //INFO: Collecting data from Company Setting Context 
   const leaveSettings = settingsContext?.setting?.leaveSetting?.companySetting ?? {}; //INFO: Collecting leave setting data from settingsContext
   const isMultiLeaveApproval = leaveSettings?.enable_multi_level_leave_approval; //INFO: Storing the multilevel setting
   const isLeaveCycle = leaveSettings?.enable_leave_cycle; //INFO: Storing the leave cycle setting

   const [SearchParameters, setSearchParameters] = useState({
      sort: true, 
      yearMonth: null,
      employee_id: talent_id ? talent_id : null,
      particular: null,
      employee: null,
      approval_status: null,
      start:null
   });
// console.log(SearchParameters);
   const handleSearchParameters = (newValue) => {
      let params = {
         ...newValue,
         yearMonth: newValue?.start ? moment(newValue?.start).format('YYYYMM') : null,
         sort: newValue?.sort,
         approval_status: newValue?.particular?.id,
         employee_id: newValue?.employee ? newValue?.employee?.id : null,
         tab: tabValue
      }
      if(tabValue == MY_LEAVE_TAB){
         params = {
            ...params,
            employee_id: talent_id ? talent_id : null,
         }
      }
      callApi(params, pageNo, rowsPerPage)
      setSearchParameters(params);
      resetPagination();
   };

   // Info: calling view leave log api for leave information of logged in user.
   useEffect(() => {
      if (auth?.authData.id && tabValue == MY_LEAVE_TAB) {
         dispatch(EmployeeLeaveBalance(auth?.authtoken, talent_id ? talent_id : auth?.authData?.id));
      }
   }, [auth?.authSuccess]);


   const callApi = (fnParams, fnPage, fnRowPerPage) => {
      dispatch(AttendanceLeaveList(auth.authtoken, {...fnParams, tab:tabValue}, fnPage, fnRowPerPage));
      if (setSearchButtonBar) { setSearchButtonBar(fnParams) }
  }


   // Info: calling leave log api for leave information of logged in user; will be called on change of page, row per Page and search.
   useEffect(() => {
      if (auth?.authtoken) {
         callApi({...SearchParameters, tab: tabValue}, pageNo, rowsPerPage)
      }
   }, [auth?.authtoken])


   // Get month Duration for My Leave summary
   const monthduration = useMemo(() => getmonthDuration({ leaveSettings }), [leaveSettings?.leave_from_month, leaveSettings?.leave_to_month]);

   // Resetting page and rows per page
   const resetPagination = () => {
      setPageNo(PAGES);
      setRowsPerPage(DEFAULT_ROWS_PERPAGE)
   }
   // State for employee delete action modal
   const [deleteLeaveRequest, setDeleteLeaveRequest] = useState({
      modalOpen: false,
      id: null,
      leaveDetail: {}
   })

   // State that manages the view leave 
   const [isViewLeave, setIsViewLeave] = useState({
      open: false,
      data: {}
   })
   // State for employee delete action modal
   const [isEditLeave, setIsEditLeave] = useState({
      open: false,
      id: null,
      data: {}
   })

   const [isLeaveApprove, setIsLeaveApprove] = useState({ open: false, data: null });
   const TABLE_HEAD = useMemo(() => getTableHead({ auth, tabValue, isFromOtherPage }), [auth, tabValue, isFromOtherPage]);

   //INFO: TO handle page change
   const handleChangePage = (event, newPage) => {
      callApi(SearchParameters, newPage, rowsPerPage);
      setPageNo(newPage, pageNo, rowsPerPage);
   };

   //INFO: To handle change in number of rows
   const handleChangeRowsPerPage = (event) => {
      const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPageNo(0, pageNo, rowsPerPage);
   };

   // This is handling delete leave action
   const handleDeleteLeave = (id) => {
      const data = {
         leave_head_id: id,
         page: pageNo,
         rowsPerPage,
         employee_id: auth?.authData?.id,
         search: { year_month: SearchParameters?.yearMonth, tab: tabValue, ...SearchParameters }
      }
      dispatch(DeleteLeave(auth?.authtoken, data))
      setDeleteLeaveRequest({
         ...deleteLeaveRequest, modalOpen: false
      })
   }

   const LeaveStatusSearchOptions = useMemo(() => getLeaveOptions(tabValue), [tabValue])
   //INFO: This function will handle downloading functionality
   const DownloadReport = (type) => {
      

      const sort = SearchParameters?.sort ;
      const approval_status = SearchParameters?.approval_status ? SearchParameters?.approval_status : null;
      const employee_id = SearchParameters?.employee_id ? SearchParameters?.employee_id : null;
      const year_month = SearchParameters?.yearMonth ? SearchParameters?.yearMonth : null;


        const params = {
            download_format: type,
            tab: tabValue,
            ...((sort === true || sort === false) && { sort }),
            ...(approval_status && { approval_status }),
            ...(employee_id && { employee_id }),
            ...(year_month && { year_month }),
        };
        const queryString = new URLSearchParams(params).toString();

        const url = `${LEAVE_LOG_DOWNLOAD_URL}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "Employee-leave-log")

    
   }

   return (
      <>
         {/* Leave summary */}
         {tabValue == MY_LEAVE_TAB && isLeaveCycle &&
            <LeaveSummaryComponent
               isFromOtherPage={isFromOtherPage}
               monthduration={monthduration} l
               leaveCount={leaveCount}
               isMyProfile={isMyProfile}
            />}

         {/* Search Not Found Error */}
         <EBox mb={2}>
            <PaymentSearchFilter
               initialFilter={SearchParameters}
               onSearch={handleSearchParameters}
               onSort={handleSearchParameters}
               DownloadReport={DownloadReport}
               showTalents={tabValue !== MY_LEAVE_TAB}
               start={{
                  placeholder: 'Start Month',
                  views: ['month', 'year'],
                  inputFormat: "MMM yyyy",
                  order: 1,
                  minDate: minDAteForDateFilter,
               }}
               particulars={LeaveStatusSearchOptions}
               isMultipleStatus={false}
               particularPlaceholder="All Status"
               talentOrder={2}
               particularsOrder={3}
               withoutDateValidation={true}
            />
         </EBox>
         {!empleave?.empLeaveListLoading && empleave?.empLeaveListData?.count == 0 ?
            <SearchNotFound />
            :
            <>
               <EScrollbar>
                  <ETableContainer >
                     <ETable>
                        {/* TABLE HEAD */}
                        <EListHead
                           headLabel={TABLE_HEAD}
                           rowCount={empleave?.empLeaveListData?.rows?.length}
                           onRequestSort={() => null}
                        />

                        {/* Loading */}
                        {empleave?.empLeaveListLoading && (
                           <ETableBody>
                              <ETableRow>
                                 <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                    <ECircularProgress color="primary" />
                                 </ETableCell>
                              </ETableRow>
                           </ETableBody>
                        )}

                        {/* Table Body */}
                        {!empleave?.empLeaveListLoading && empleave?.empLeaveListData?.rows?.length > 0 &&
                           (
                              <ETableBody>
                                 {empleave?.empLeaveListData?.rows?.map((row, index) => {
                                    const { employee, from_date, applied_date, main_status,
                                       employee_id, leave_is_multiple, to_date_is_half, from_date_is_half, color,
                                       from_application, reason, id, to_date, updated_at, leave_is_paid
                                    } = row;
                                    const isReportingManager = employee?.reporting_manager_id == auth?.authData?.id ? true : false;
                                    const actionForReportingManager = isMultiLeaveApproval ? ((main_status == PENDING_STATUS) && isReportingManager) : (tabValue == LEAVE_REQUEST_TAB);//condition for action button for RM
                                    const actionForSuperiors = isMultiLeaveApproval ? ((main_status != PENDING_STATUS) && !isReportingManager) : (tabValue == LEAVE_REQUEST_TAB); //condition for action button for superiors

                                    return (
                                       <ETableRow hover key={index} tabIndex={-1} style={{ background: from_application == 0 ? color : '' }}
                                          className={empleave?.empLeaveListData?.rows?.length == index + 1 ? 'table-border-none' : ''}
                                       >
                                          {/* Applied Date */}
                                          <ETableCell align="left">
                                             {moment(applied_date).format('DD/MM/YYYY')}
                                          </ETableCell>

                                          {/* Employee Name */}
                                          {!isFromOtherPage && tabValue != MY_LEAVE_TAB &&
                                             <ETableCell align="left" style={{ minWidth: '150px' }}>
                                                <span className='bold-600'>{employee?.fullname ? employee?.fullname : "-"}</span>
                                                <br />
                                                {employee?.employee_code ? '(' + employee?.employee_code + ')' : "-"}
                                             </ETableCell>
                                          }

                                          {/* Leave Period */}
                                          <ETableCell align="left" className="minWidth-150 pr-0px" >
                                             {from_date && to_date &&
                                                moment(from_date).format('DD/MM/YYYY') != moment(to_date).format('DD/MM/YYYY')
                                                ? moment(from_date).format('DD/MM/YYYY') + ' to ' + moment(to_date).format('DD/MM/YYYY')
                                                : (moment(from_date).format('DD/MM/YYYY') ==
                                                   moment(to_date).format('DD/MM/YYYY')) && from_date && to_date
                                                   ? moment(from_date).format('DD/MM/YYYY')
                                                   : '-'}
                                          </ETableCell>


                                          {/* Updated On */}
                                          {tabValue == MY_LEAVE_TAB &&
                                             <ETableCell align="left" className="minWidth-100 pr-0px" >
                                                {updated_at ? moment(updated_at).format('DD/MM/YYYY') : ''}
                                             </ETableCell>
                                          }

                                          {/* Status */}
                                          {tabValue != LEAVE_REQUEST_TAB &&
                                             <ETableCell align='center' className="p0 minWidth-100">
                                                {main_status ?
                                                   <EChip width='6.9rem'
                                                      label={main_status == HRPENDING ? 'HR Pending' : capitalizeCapitalString(main_status)}
                                                   /> : ''}
                                             </ETableCell>
                                          }

                                          {/* Actions */}
                                          {!isFromOtherPage &&
                                             <ETableCell align={tabValue == MY_LEAVE_TAB ? "left" : "center"} className='width-80px'>

                                                {/* Respond Button: Will be only visible to the request tab;
                                                                Condition: View button will only shown to RM until he has taken an action provided the multiple leave setting is true. 
                                                                Only after the pending status is changed, other superiors will be able to take any further action.
                                                                */}
                                                {(tabValue == LEAVE_REQUEST_TAB ? (isReportingManager ? actionForReportingManager : actionForSuperiors) : false) &&
                                                   <ETooltip title={'Respond'} arrow>
                                                      <span>
                                                         <EIconButton color='primary' className='p0'
                                                            disabled={
                                                               moment(new Date()).isAfter(from_date) &&
                                                                  moment(new Date()).format('YYYY-MM-DD') != from_date
                                                                  ? true : false
                                                            }
                                                            onClick={() => {
                                                               setIsLeaveApprove({
                                                                  open: true,
                                                                  data: {
                                                                     id,
                                                                     reporting_manager_id: employee?.reporting_manager_id,
                                                                     employee_id,
                                                                     fullname: employee?.fullname,
                                                                     employee_code: employee?.employee_code
                                                                  }
                                                               });
                                                            }}
                                                         >
                                                            {' '}
                                                            <EIcon icon={ActionIcon} width={23} height={23} />{' '}
                                                         </EIconButton></span>
                                                   </ETooltip>
                                                }

                                                {/* View Button: will be visible to my leave tab (if it's not in pending status) & leave history tab
                                                                Condition: View will show on request tab if action button is not visible.
                                                                */}
                                                {(tabValue == LEAVE_HISTORY_TAB || (tabValue === MY_LEAVE_TAB ? (main_status != PENDING_STATUS) : false)
                                                   || (tabValue == LEAVE_REQUEST_TAB ? (isReportingManager ? !actionForReportingManager : !actionForSuperiors) : false)) &&
                                                   <ETooltip title={'View'} arrow>
                                                      <span>
                                                         {/* View Button */}
                                                         <EIconButton color='primary' className='p0'
                                                            onClick={() => { setIsViewLeave({ open: true, data: { from_date, to_date, id } }); }}
                                                         >
                                                            <EIcon icon={EyeIcon} />
                                                         </EIconButton></span>
                                                   </ETooltip>
                                                }

                                                {/* Edit & Delete Buttons: will be visible if the main_status is PENDING */}
                                                {tabValue == MY_LEAVE_TAB && main_status == PENDING_STATUS &&
                                                   <>
                                                      {/* Edit Button */}
                                                      <EIconButton className='p0' onClick={() => setIsEditLeave({
                                                         ...isEditLeave, open: true,
                                                         data: { to_date, from_date, id, reason, leave_is_multiple, to_date_is_half, from_date_is_half, leave_is_paid },
                                                         id: id
                                                      })} >
                                                         <EIcon className='mr-8px' icon={editFill} width={23} height={23} />
                                                      </EIconButton>

                                                      {/* Delete Button */}
                                                      <EIconButton className='p0' onClick={() => setDeleteLeaveRequest({
                                                         ...deleteLeaveRequest, modalOpen: true, leaveDetail: row, id: id
                                                      })} >
                                                         <EIcon className='icon-red' icon={DeleteCircle} width={23} height={23} />
                                                      </EIconButton>
                                                   </>}
                                             </ETableCell>
                                          }
                                       </ETableRow>
                                    );
                                 })}
                              </ETableBody>
                           )}
                     </ETable>
                  </ETableContainer>
               </EScrollbar>
               {empleave?.empLeaveListData?.count && empleave?.empLeaveListData?.count >= DEFAULT_ROWS_PERPAGE ?
                  <ETablePagination
                     rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                     component="div"
                     count={empleave?.empLeaveListData?.count ? empleave?.empLeaveListData?.count : 0}
                     rowsPerPage={rowsPerPage}
                     page={pageNo}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  : <></>}
            </>
         }
         {/* Leave Approve Modal */}
         {isLeaveApprove?.open && (
            <EModal
               open={isLeaveApprove?.open}
               close={() => setIsLeaveApprove({ open: false, data: null })}
               headervalue={'Leave Request ' + (isLeaveApprove?.data?.fullname && isLeaveApprove?.data?.employee_code ? ' - ' + isLeaveApprove?.data?.fullname + ' (' + isLeaveApprove?.data?.employee_code + ')' : '')}
            >
               <LeaveApproveForm
                  auth={auth}
                  details={isLeaveApprove?.data}
                  close={() => setIsLeaveApprove({ open: false, data: null })}
                  SearchParameters={{
                     tab: tabValue,
                     page: pageNo,
                     rowsPerPage: rowsPerPage,
                     ...SearchParameters
                  }}
               />
            </EModal>
         )}

         {/* Leave update Modal */}
         {isEditLeave.open && (
            <EModal
               open={isEditLeave.open}
               close={() => setIsEditLeave(false)}
               headervalue={'Update Leave'}
            >
               <ApplyLeaveForm open={isEditLeave.open}
                  LeaveSearch={{
                     tab: tabValue,
                     page: pageNo,
                     rowsPerPage: rowsPerPage,
                     ...SearchParameters
                  }}
                  close={() => setIsEditLeave(false)} fromPage="Leave" auth={auth} isEditData={isEditLeave?.data} />
            </EModal>
         )}

         {/* Leave View Modal */}
         {isViewLeave.open && (
            <EModal
               open={isViewLeave.open}
               close={() => setIsViewLeave({ open: false, data: null })}
               headervalue={'View Leave Details'}
            >
               <LeaveViewForm open={isViewLeave.open}
                  close={() => setIsViewLeave({ open: false, data: null })}
                  fromPage="Leave"
                  auth={auth}
                  details={isViewLeave?.data}
               />
            </EModal>
         )}

         {/* Delete Request Modal */}
         {deleteLeaveRequest && (
            <EModal open={deleteLeaveRequest.modalOpen} headervalue={'Leave: Delete Confirmation'}>
               <ETypography>
                  Are you sure you want to delete leave   {"   "}
                  <span className='bold-400'>
                     {deleteLeaveRequest?.leaveDetail?.from_date !== deleteLeaveRequest?.leaveDetail?.to_date ?
                        <> from {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.from_date).format('DD/MM/YYYY'))}
                           to {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.to_date).format('DD/MM/YYYY'))}
                        </>
                        : <> of {returnColouredText(moment(deleteLeaveRequest?.leaveDetail?.from_date).format('DD/MM/YYYY'))}</>
                     }
                  </span>
                  ?
               </ETypography>
               <Grid item xs={12} xl={12} paddingTop={'16px'}>
                  <Stack direction='row' spacing={2} className='modal1-buttons-stick-bottom '>
                     <span>
                        <EButtonOutlined variant='outlined' size='large' onClick={() => setDeleteLeaveRequest(!deleteLeaveRequest)}> No</EButtonOutlined>
                     </span>
                     <span>
                        <EButton onClick={() => handleDeleteLeave(deleteLeaveRequest.id)} variant='contained' size='large'> Yes </EButton>
                     </span>
                  </Stack>
               </Grid>
            </EModal>
         )}

      </>
   )
}

export default React.memo(LeaveLog)


// **************************Child components below****************************** 

const getTableHead = (props) => {
   const { tabValue, isFromOtherPage } = props;
   let TABLE_HEAD = [{ id: 'Applied Date', label: 'Applied Date', alignRight: false }];

   // Since employee tab will only be shown in history & request tabs of leave log page.
   if (tabValue == LEAVE_REQUEST_TAB || tabValue == LEAVE_HISTORY_TAB) {
      TABLE_HEAD.push({ id: 'Employee Name', label: 'Employee Name', alignRight: false })
   }

   //No condition, since it will be shown everywhere.
   TABLE_HEAD.push({ id: 'Leave Period', label: 'Leave Period', alignRight: false })

   // Since this updated on will NOT be shown in the history & request tabs
   if (tabValue == MY_LEAVE_TAB) { TABLE_HEAD.push({ id: 'Updated On', label: 'Updated On', alignRight: false }) }

   //Since status will be shown in the my leave & history tab
   if (tabValue != LEAVE_REQUEST_TAB) { TABLE_HEAD.push({ id: 'Status', label: 'Status', alignRight: 'center' }) }

   // INFO: This condition is if this page was accessed through employee view page; we don't have to show action column
   if (!isFromOtherPage) { TABLE_HEAD.push({ id: 'Action', label: 'Action', alignRight: 'center' }) }

   return TABLE_HEAD;
}

// Returning string for the leave summary; example:  Financial Year(Apr 2023 to Mar 2024)
const getmonthDuration = (props) => {
   const { leaveSettings } = props;
   const { leave_to_month, leave_from_month } = leaveSettings;

   var monthDurationString = null;

   if (leave_from_month && leave_to_month) {
      let months = '';
      let monthCount = Math.floor((moment(leave_to_month)).diff((moment(leave_from_month)), 'months', true)) + 1

      if (monthCount == 1) { // means only one month is set as tenure
         months = moment(leave_from_month).format('MMMM');
      } else { // means at least two months is set as tenure
         months = moment(leave_from_month).format('MMM YYYY') + ' to ' + moment(leave_to_month).format('MMM YYYY');
      }

      monthDurationString = monthCount;
      //setting the month string conditionally
      if (leaveSettings?.leave_tenure_category === CalendarYear) {
         monthDurationString = ": Calendar Year (" + months + ")"
      } else if (leaveSettings?.leave_tenure_category === FinancialYear) {
         monthDurationString = ": Financial Year(" + months + ")"
      } else {
         monthDurationString = monthDurationString && months && ": " + monthDurationString + (monthCount === 1 ? " month" : " months") + "(" + months + ")"
      }
   }
   return monthDurationString;
}

//INFO: This return summary component
const LeaveSummaryComponent = (props) => {
   const { isFromOtherPage, monthduration, leaveCount, isMyProfile } = props

   return (
      <ECard2 sx={{ height: 'auto', textAlign: 'left', mx: "auto" }} className='mb-16px'>
         <ETypographyCardHeading variant='h5' >
            {isFromOtherPage != TALENT_SM ? 'My ' : ''} Leave Summary {monthduration ? monthduration : ''}
         </ETypographyCardHeading>
         <EDividerCard className='margin-top-05 mb-16px' />
         <Grid container spacing={2}>
            <LeaveCountGrid count={leaveCount?.total_allowed_leaves ? Number(leaveCount?.total_allowed_leaves).toFixed(1) : '0.0'}
               largeText={'Total'} smallText={'Paid Leaves'} boxBgClassname='darkBlue-bgcolor' />

            <LeaveCountGrid count={leaveCount?.total_allowed_leaves > 0 && leaveCount?.leave_taken >= 0 && (leaveCount?.total_allowed_leaves - leaveCount?.leave_taken >= 0) ?
               (leaveCount?.total_allowed_leaves - leaveCount?.leave_taken).toFixed(1) : '0.0'}
               largeText={'Available'} smallText={'Paid Leaves'} boxBgClassname={'darkGreen-bgcolor'} />

            <LeaveCountGrid count={leaveCount?.leave_taken ? Number(leaveCount?.leave_taken).toFixed(1) : 0}
               largeText={'Exhausted'} smallText={'Paid Leaves'} boxBgClassname='darkGrey-bgcolor' />

            <LeaveCountGrid count={leaveCount?.total_unpaid_leaves ? (Number(leaveCount?.total_unpaid_leaves)).toFixed(1) : 0}
               largeText={!isMyProfile ? 'Unpaid' : 'Taken'} smallText={'Leaves'} boxBgClassname='greyColor3-bgcolor' />
         </Grid>
         {/* Commenting this section as per @dharam sir on 29th April,23 */}
         {/* {leaveSettings?.enable_half_day_leave_request ?  */}
         <ETypography className='font-size-12px mt-10px greyColor4-color'>*0.5 indicates half day</ETypography>

      </ECard2>
   )
};

// This function returns customized grid
const LeaveCountGrid = (props) => {
   const { count, largeText, smallText, boxBgClassname } = props
   return (
      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
         <EBox className={'borderRadius-4px height-120px p-16px display-grid display-flex align-content-space-between ' + (boxBgClassname ? boxBgClassname : '')}>
            <ETypography className='color-staticPrimaryLight line-height-168px'>
               <span className='bold-600 font-size-20px line-height-33px'>{largeText}</span>
               <br />
               <span className='font-weight-400 font-size-14px'>{smallText}</span>
            </ETypography>
            <ETypography className='bold-600 color-staticPrimaryLight font-size-20px line-height-168px'>{count} days
            </ETypography>
         </EBox>
      </Grid>
   )
}


// returns bold & primary text
const returnColouredText = (text) => {
   return text ? <span className='theme-color-static bold-600'> {text} </span> : '';
}
