import React, { useEffect, useRef, useState } from 'react'
import { Grid, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import DataCheckIcon from '@iconify/icons-material-symbols/data-check-rounded';
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import RupeeIcon from '@iconify/icons-material-symbols/currency-rupee';
import AttachFile from '@iconify/icons-material-symbols/file-present-outline-rounded';

import { useCompanySettingProvider } from '../../../context/CompanySettingContext';
import { EButton } from '../../../BaseComponents/EButtons';
import PaymentRequestModal from '../../../PageComponents/Payment/PaymentRequestModal';
import EModal from '../../../BaseComponents/EModal';
import { ACCOUNTANT, ACTIVE_USER, APPROVED_STATUS, DEFAULT_ROWS_PERPAGE, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_ALL, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_LIST_DOWNLOAD_URL, PAYMENT_TRANSACTION_CATEGORY, PAYMENT_TRANSACTION_STATUS, PAYMENT_TRANSACTION_STATUS_CHIP, PAYMENT_TRANSACTION_STATUS_OPTIONS, PENDING_STATUS, PERMISSIONS, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, ROW_SIZE_PER_PAGE, SUPER_ADMIN } from '../../../constants';
import { EIcon } from '../../../BaseComponents/EIcon';
import { ETooltip } from '../../../BaseComponents/ETooltip';
import SearchNotFound from '../../../PageComponents/SearchNotFound';
import { ETablePagination } from '../../../BaseComponents/ETablePagination';
import { useNavigate } from 'react-router-dom';
import { EBox, EBoxPage } from '../../../BaseComponents/EBox';
import { getPaymentRequestList } from '../../../action/PaymentAction';
import { useCheckPermission } from '../../../hooks';
import { EmployeeListData } from '../../../action/EmployeeAction';
import { ETypography } from '../../../BaseComponents/ETypography';
import { numberWithCommas } from '../../../utils/formatNumber';
import EChip from '../../../BaseComponents/EChip';
import { ELinkMUI } from '../../../BaseComponents/ELink';
import { DownLoadFile } from '../../../action/AdminAction';
import { PaymentSearchFilter } from '../../../PageComponents/Payment/PaymentSearchFilter';
import { EDataGrid } from '../../../BaseComponents';
import { getParticulars, getStatus } from '../../../utils/CommonFunctions';
import PaymentRequestRespond from '../../../PageComponents/Payment/PaymentRequestRespond';
import PaymentRequestView from '../../../PageComponents/Payment/PaymentRequestView';
import PaymentActionModal from '../../../PageComponents/Payment/PaymentActionModal';



const initialBulkSelectedValue = {
  ids: [], data: []
}

export const TalentRequests = ({ refetch }) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [modalState, setModalState] = useState({ modalData: null, modalName: null })
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE)
  const [page, setPage] = useState(0)
  const [isAdminRole, setIsAdminRole] = useState(false)
  const [filter, setFilter] = useState({
    sort: true,
    employee: null,
    particular: null,
    status: null,
  })

  const [selectedData, setSelectedData] = useState(initialBulkSelectedValue)
  const SelectedRowsIds = useRef([])
  const SelectedRowsIdsData = useRef([])

  const auth = useSelector((state) => state.authReducer)
  const employee = useSelector((state) => state.EmployeeReducer)
  const company = useSelector(state => state.CompanyReducer)

  const payment = useSelector((state) => state.PaymentReducer)

  const { setting } = useCompanySettingProvider()
  const paymentSettingData = setting?.paymentSettingData?.companySetting

  const enable_advance_salary = paymentSettingData?.enable_advance_salary
  const enable_reimbursement = paymentSettingData?.enable_reimbursement

  const allowVerifyPayment = useCheckPermission([PERMISSIONS.VerifyPayment])
  const allowPayment = useCheckPermission([PERMISSIONS.SideBarTalentPayment])


  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    dispatch(getPaymentRequestList({
      payment_category: fnParams?.particular?.id,
      transaction_status: fnParams?.status?.length > 0 ? fnParams?.status?.map(i => i?.id)?.join(", ") : null,
      start_date: fnParams?.start,
      end_date: fnParams?.end,
      employee_id: fnParams?.employee?.id,
      sort: !fnParams.sort ? ' ASC ' : ' DESC',
      withoutRoleCheck: allowVerifyPayment || allowPayment,
      withoutPendingAndRejected: !allowVerifyPayment,
      page_name: 'request_employee',
      page: fnPage,
      per_page: fnRowPerPage,
    }))
  }

  const getSettingByRole = (role) => {
    let ADMIN_ROLES = [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR]
    let TALENT_ROLES = [ROLE_EMPLOYEE, ACCOUNTANT]
    if (ADMIN_ROLES.includes(role)) {
      return setIsAdminRole(() => true)
    } else if (TALENT_ROLES.includes(role)) {
      return setIsAdminRole(() => false)
    }
    return isAdminRole
  }

  useEffect(() => {
    if (auth?.authtoken) {
      
      callApi({ ...filter }, page, rowsPerPage)
      dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER))
      getSettingByRole(auth?.authData?.role)
    }
  }, [auth?.authtoken, refetch])

  const getFilterData = (searchData) => {
    callApi(searchData, 0, rowsPerPage)
    setPage(0)
    //  empty the bulk payment State
    SelectedRowsIds.current = []
    SelectedRowsIdsData.current = []
    setSelectedData(initialBulkSelectedValue)
  }

  const handleChangePage = (_, newPage) => {
    callApi(filter, newPage, rowsPerPage)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE)
    setRowsPerPage(rowPerPageCount)
    callApi(filter, 0, rowPerPageCount)
    setPage(0)
    //  empty the bulk payment State
    SelectedRowsIds.current = []
    SelectedRowsIdsData.current = []
    setSelectedData(initialBulkSelectedValue)
  }

  const DownloadReport = (value) => {
    let url = ''
    let file_name = 'Payment_Request_List';
    const sort = filter?.sort == true || filter?.sort == false ? filter?.sort : true;

    url += PAYMENT_LIST_DOWNLOAD_URL + '?page_name=request_employee&';

    if (value != null) { url += `download_format=${value}&`; }
    if (filter?.employee?.id != null) { url += `employee_id=${filter?.employee?.id}&`; }
    if (filter?.status?.length > 0) { url += `transaction_status=${filter?.status?.map(i => i?.id)?.join(", ")}&`; }
    if (filter?.particular?.id != null) { url += `payment_category=${filter?.particular?.id}&`; }
    if (filter?.start != null) { url += `start_date=${moment(filter?.start).format('YYYY-MM-DD')}&`; }
    if (filter?.end != null) { url += `end_date=${moment(filter?.end).format('YYYY-MM-DD')}&`; }
    if (filter?.sort != null) { url += `sort=${!sort ? ' ASC ' : ' DESC'}`; }

    DownLoadFile(auth.authtoken, url, file_name);
  }

  const onSearch = (data) => {
    setFilter(data)
    setPage(0)
    callApi({ ...data }, 0, rowsPerPage);
  }

  const particulars = getParticulars(true, true)

  const columns = [
    {
      field: 'transaction_date',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      headerClassName: 'super-app-theme--header',
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return moment(params?.row?.transaction_date).format('DD/MM/yyyy')
      }
    },
    {
      field: 'TalentName',
      headerName: 'Employee Name',
      minWidth: 200,
      headerClassName: 'super-app-theme--header',
      flex: 2,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <ETypography variant="subtitle1" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color" >
              {params?.row?.employeeData?.fullname ? params?.row?.employeeData?.fullname : '-'} <br />
              <ETypography variant="span" noWrap className="cursor-pointer font-size-14px text-capitalize blue-color font-weight-400-with-imp" sx={{ marginTop: '-3px' }}>
                ({params?.row?.employeeData?.employee_code ? params?.row?.employeeData?.employee_code : '-'})
              </ETypography>
            </ETypography>
          </>
        )
      }
    },
    {
      field: 'Particular',
      headerName: 'Particular',
      minWidth: 80,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {PAYMENT_TRANSACTION_CATEGORY[params?.row?.transaction_category] || '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'Amount',
      headerClassName: 'super-app-theme--header',
      headerName: 'Request Amount',
      type: 'number',
      minWidth: 200,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography className='font-size-14px'>
            {params?.row?.pay_amount ? numberWithCommas(params?.row?.pay_amount) : '-'}
          </ETypography>
        );
      }
    },
    {
      field: 'Attachment',
      headerName: 'Attachment',
      headerAlign: 'center',
      minWidth: 100,
      headerClassName: 'super-app-theme--header',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return (
          <ETypography>
            {params?.row?.reimbursement_attachment ? (
              <ELinkMUI
                variant="link"
                href={params?.row?.reimbursement_attachment}
                target={'_blank'}
              >
                <EIcon icon={AttachFile} style={{ height: '25px', width: '25px' }} />
              </ELinkMUI>
            ) : (
              ''
            )}
          </ETypography>
        );
      }
    },

    {
      field: 'Status',
      headerName: 'Status',
      headerAlign: 'center',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      minWidth: 120,
      renderCell: (params) => {
        return (
          <Grid className="display-flex align-items-center">
            {params?.row?.transaction_status ? (
              <EChip
                label={PAYMENT_TRANSACTION_STATUS[params?.row?.transaction_status]}
                width="100px"
                className={PAYMENT_TRANSACTION_STATUS_CHIP[params?.row?.transaction_status]}
              />
            ) : (
              '-'
            )}
          </Grid>
        );
      },
      editable: false
    },
    {
      field: 'Action',
      headerClassName: 'super-app-theme--header',
      headerName: 'Action',
      sortable: false,
      width: 80,
      type: 'actions',
      renderCell: (params) => {
        const transaction_status = params?.row?.transaction_status

        const ViewButton = () => {
          return (
            <ETooltip title={'View'} arrow>
              <IconButton onClick={() => setModalState({ modalData: params?.row, modalName: 'VIEW' })}>
                <EIcon icon={VisibilityIcon} />
              </IconButton>
            </ETooltip>

          )
        }
        const ActionButton = ({ title = 'Action' }) => {
          return (
            <ETooltip title={title} arrow>
              <IconButton onClick={() => setModalState({ modalData: params?.row, modalName: 'RESPOND' })}>
                <EIcon icon={DataCheckIcon} />
              </IconButton>
            </ETooltip>
          )
        }

        const PayButton = ({ title = 'Pay' }) => {
          return (
            <ETooltip title={title} arrow>
              <IconButton onClick={() => setModalState({ modalData: params?.row, modalCategory: 'PAYMENT' })}>
                <EIcon icon={RupeeIcon} />
              </IconButton>
            </ETooltip>
          )
        }
        /**
         * 1. When transaction_status is "PENDING" THEN
         *  => IF logged in user have allowVerifyPayment(i.e. VerifyPayment) permission then show action icon to either Approve or Reject the request
         *  => IF logged in user is "Admin" or "Super Admin" then show pay icon to pay the request amount
         */
        if (transaction_status === PENDING_STATUS) {
          /**
           * ONLY "Admin" and "Super Admin" have authority to pay a request in "PENDING" status
           */
          const showPayIcon = auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN
          return (
            <>
              {/**
               * Action icon to either Approve or Reject the request
               */}
              <ActionButton />
              {/**
               * Rupee Icon to pay the request amount
               */}
              {
                showPayIcon &&
                <PayButton title='Approve and Pay' />
              }

            </>
          )
        }
        /**
         * 2. When transaction_status is "APPROVED" THEN
         *  => IF logged in user have allowPayment(i.e. SideBarTalentPayment) permission then show pay icon to pay the request amount
         *  => ELSE show eye icon to view the request detail
         */
        if (transaction_status === APPROVED_STATUS) {
          return (
            <>
              {
                allowPayment ?
                  <PayButton />
                  :
                  <ViewButton />

              }
            </>
          )
        }
        /**
         * 3. When transaction_status is either "CANCELLED" or "COMPLETE" THEN
         *  => show eye icon to view the request detail
         */
        return (
          <>
            <ViewButton />
          </>
        )
      }
    }
  ]

  const SetSelectedIds = (ids) => {
    SelectedRowsIds.current[page] = ids
    const reduceData = payment?.PaySalaryListData?.rows?.filter((item) => ids.includes(item.id))

    const arrayMainIds = SelectedRowsIds.current.flat();
    SelectedRowsIdsData.current[page] = reduceData;

    const arrayMainIdsData = SelectedRowsIdsData.current.flat();

    setSelectedData({ data: arrayMainIdsData, ids: arrayMainIds });
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        rowGap={2}
      >
        <Grid item xs={12} sm={12} md={12}>
          {
            (allowPayment) &&
            <ETooltip title={''} arrow >
              <EButton
                variant="contained"
                onClick={() => navigate('/payment/payment-request/bulk-payment', { state: selectedData })}
                className='mr-16px'
                disabled={selectedData?.ids?.length === 0}
              >
                Bulk Payment
              </EButton>
            </ETooltip>
          }
          {
            [ROLE_ADMIN, ROLE_HR].includes(auth?.authData?.role) && (enable_advance_salary || enable_reimbursement) &&
            <ETooltip title={'Request payment for employee'} arrow>
              <EButton
                variant="contained"
                onClick={() => setModalState({ ...modalState, modalName: 'REQUEST' })}
              >
                Other Payment
              </EButton>
            </ETooltip>
          }
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <PaymentSearchFilter
            initialFilter={filter}
            onSearch={onSearch}
            onSort={onSearch}
            DownloadReport={DownloadReport}
            showTalents={(auth?.authData?.role == ROLE_HR || auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN)}
            particulars={particulars}
            status={getStatus(!allowVerifyPayment)}
            start={{
              placeholder: 'Start Date',
              views: ['year', 'month', 'day'],
              inputFormat: 'dd/MM/yyyy',
              order: 1,
              maxDate: new Date(),
            }}
            end={{
              placeholder: 'End Date',
              views: ['year', 'month', 'day'],
              inputFormat: "dd/MM/yyyy",
              order: 2,
              maxDate: new Date(),
            }}
            particularsOrder={3}
            statusOrder={4}
            talentOrder={5}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <EBoxPage className='p0'>
            {(!payment?.PaySalaryListLoading && payment?.PaySalaryListData?.rows?.length == 0)
              ?
              <SearchNotFound />
              :
              <EDataGrid
                columns={columns}
                rows={payment?.PaySalaryListData?.rows || []}
                loading={payment?.PaySalaryListLoading}
                pageSize={rowsPerPage}
                disableSelectionOnClick
                checkboxSelection={allowPayment}
                hideDisabledCheckbox={true}
                isRowSelectable={(params) => {
                  return params?.row?.transaction_status === APPROVED_STATUS
                }}
                onSelectionModelChange={(ids) => {
                  SetSelectedIds(ids)
                }}
                selectionModel={selectedData?.ids}
                keepNonExistentRowsSelected
              />
            }
            {!payment?.PaySalaryListLoading && payment?.PaySalaryListData?.count > DEFAULT_ROWS_PERPAGE &&
              <ETablePagination
                rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                component="div"
                count={payment?.PaySalaryListData?.count ? payment?.PaySalaryListData?.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            }
          </EBoxPage>
        </Grid>
      </Grid>

      {
        modalState.modalName &&
        <EModal
          open={modalState.modalName === 'REQUEST'}
          headervalue={`Other Request`}
        >
          <PaymentRequestModal
            setModalState={setModalState}
            auth={auth}
            getPaymentFilterData={() => getFilterData(filter)}
            employeeList={employee}
            companyData={company}
            setting={setting}
            onlyOther={true}
          />
        </EModal>
      }
      {
        (modalState.modalName && modalState.modalData)
        &&
        <EModal
          open={modalState.modalName === 'RESPOND'}
          headervalue={PAYMENT_TRANSACTION_CATEGORY[modalState.modalData?.transaction_category] + ' Request' || '-'}
        >
          <PaymentRequestRespond
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => getFilterData(filter)}
            auth={auth}
          />

        </EModal>
      }
      {
        (modalState.modalName && modalState.modalData) &&
        <EModal
          open={modalState.modalName === 'VIEW'}
          headervalue={PAYMENT_TRANSACTION_CATEGORY[modalState.modalData?.transaction_category] + ' Request' || '-'}
        >
          <PaymentRequestView
            selectedData={modalState}
            setModalState={setModalState}
          />
        </EModal>
      }
      {
        modalState.modalCategory && modalState.modalCategory === 'PAYMENT' &&
        <EModal open={modalState.modalCategory === 'PAYMENT'}
          headervalue={PAYMENT_TRANSACTION_CATEGORY[modalState.modalData?.transaction_category] + ' Request' || '-'}
        >
          <PaymentActionModal
            selectedData={modalState}
            setModalState={setModalState}
            getPaymentFilterData={() => getFilterData(filter)}
            auth={auth}
          />
        </EModal>
      }
    </>
  )
}
