import { Grid } from "@mui/material";
import { ETypography } from "../../BaseComponents/ETypography";
import EChip from "../../BaseComponents/EChip";
import PropTypes from 'prop-types';
import { EAvatar } from "../../BaseComponents/EAvatar";
import { EBox } from "../../BaseComponents/EBox";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux'
import { NotificationReadUnread } from "../../action/NotificationAction";

NotificationItem.propTypes = {
  isPopup: PropTypes.bool,
  data:PropTypes.object.isRequired,
  handleClose:PropTypes.func
};

function NotificationItem(prop) {
  const navigate = useNavigate();
  const auth = useSelector(state => state.authReducer);
  const dispatch = useDispatch()
  const { isPopup, data, handleClose, reset } = prop
  
  const { notification_title, notification_description,is_viewed, created_at, redirect_to, notificationType,id } = data

  // HandleView: calls read/unread api
  const handleView = (id) => {
    if (!is_viewed) {
      dispatch(NotificationReadUnread(auth?.authtoken, id))
      if (reset) { reset() }//INFO: For Detail notification page
    }
  }

  // HandleRedirect: redirect to respective response and calls read/unread for that id
  const handleRedirect = (redirect_to,id)=>{
    handleView(id)
    if (redirect_to) { navigate(redirect_to); if (handleClose) { handleClose() } }
  }
  

  return (
    <EBox onClick={()=>handleView(id)} className={"p-10px pb-0 "+(!is_viewed?' cursor-pointer':'')}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={is_viewed == 0 ? "lightest2-bg borderRadius-8px p-10px align-items-start" : "bg-color-card borderRadius-8px p-10px align-items-start"}
        sx={{ position: 'relative' }}
      >

        {/* Icon */}
        <Grid item xs={1} sm={1.5} xl={isPopup ? 2 : 1.5} lg={1.5} md={isPopup ? 2 : 1}  className=" align-items-start display-flex pt-8px" >
          <EAvatar className={is_viewed == 0 ? "lightest2-bg height-width-44px" : "bg-color-card height-width-44px"} >
            <img alt='' width={"48px"} src={"/assets/images/"+notificationType?.notification_icon } />
          </EAvatar>
        </Grid>

        {/* Information */}
        <Grid item xs={10} sm={7.5} xl={isPopup ? 10 : 7.5} lg={isPopup ? 10 : 7.5} md={isPopup ? 8 : 9}  >
          <ETypography className="font-size-14px bold-600">{notification_title ? notification_title : ''}</ETypography>
          <ETypography className="font-size-12px">
            {/* REM, short for root em, is one of the font-relative measurement units commonly used in css. This px to rem converter is a free online tool you can use to */}
            <span>{notification_description ? notification_description : ''} </span><br />
            <span className="font-style-italic font-size-11px">
            {created_at && ( moment(created_at).format('DD/MM')==moment().format('DD/MM') )? //INFO: Logic to check for today date
            moment(created_at).format(' [Today] hh:mmA') 
            : created_at && moment(created_at).format('DD/MM')==moment().subtract(1,'days').format('DD/MM')? //INFO: Logic to check for yesterday's date
            moment(created_at).format(' [Yesterday] hh:mmA')  
            : created_at?moment(created_at).format('DD/MM/YYYY hh:mmA'):''}
            </span>
          </ETypography>
        </Grid>

        <Grid item xs={1} sm={3} xl={isPopup ? 0 : 3}  lg={3}  md={isPopup ? 2 : 2} align={'right'}>
          {!isPopup && notificationType?.notification_category? <EChip label={notificationType?.notification_category} fontSize={'0.625rem'} width='auto' /> : ""}
        </Grid>

        <span className="font-size-10px mr-8px theme-color-static cursor-pointer"
          // onClick={() => { if (redirect_to) { navigate(redirect_to); if (handleClose) { handleClose() } } }}
          onClick={()=>handleRedirect(redirect_to,id)}
          style={{ position: 'absolute', bottom: '12px', right: '8px' }}>See more</span>
      </Grid>

    </EBox>
  );
}

export default NotificationItem;