import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import React from 'react';
import { EBox } from '../BaseComponents/EBox';
import { ETypography } from '../BaseComponents/ETypography';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
    searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', page = '', text = '', textColor = '', ...other }) {
    return (
        <EBox sx={{ py: 3, width: '100%' }} >
            {!page ?
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ padding: '50px' }} >
                    <img src='/assets/images/noSearchFound.png'  />
                    <ETypography variant='h4' color={'grey.500'} sx={{color: textColor ? textColor:null}}>{text ? text : 'No record found'}</ETypography>
                    {/* <ETypography variant='body1' color={'grey.500'}>{'Please try again.'}</ETypography> */}
                </Grid>
                :
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ padding: '0px' }} >
                    <img src='/assets/images/noSearchFound.png' height={'45px'}  />
                    <ETypography variant='body1' color={'grey.500'} sx={{color: textColor ? textColor:null}}>{text ? text : 'No record found'}</ETypography>
                    {/* <ETypography variant='body1' color={'grey.500'}>{text ? text : 'Please try again.'}</ETypography> */}
                </Grid>
            }
        </EBox>
    );
}
