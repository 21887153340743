


import axios from 'axios';
import { SuccessCenter, Errors } from "../BaseComponents/EToast";
import moment from 'moment';
import {
    EMP_ATTENDANCE_FAILURE, EMP_ATTENDANCE_SUCCESS, EMP_ATTENDANCE_LOADING, ATTENDANCE_SUCCESS,
    ATTENDANCE_FAILURE, ATTENDANCE_LOADING, SERVER_URL, TODAY_ATTENDANCE_SUCCESS,
    TODAY_ATTENDANCE_FAILURE, TODAY_ATTENDANCE_LOADING
} from '../constants';
import { Logout } from './AuthAction';
import { getBirthdayAnniversary } from './HRDashboardAction';

/**
 * [2022-03-28]
 * changes by: Aanchal Sahu
 * Description:- added dispatch(GetTodayAttendance(token))
 **/
export function CheckinCheckoutAttendance(token, Data) {
    const data = new FormData();

    data.append('updated_by', Data.updated_by);
    data.append('employee_id', Data.employee_id);
    data.append('attendance_date', Data.attendance_date);
    data.append('is_mobile', Data.is_mobile);
    data.append('browser_name', Data.browser_name);

    // data.append('ip_address', Data.ip_address);
    if (Data.ip_address) {
        data.append('ip_address', Data.ip_address);
    }
    if (Data.remark != null && Data.remark != '') {
        data.append('checkout_remark', Data.remark);
    }

    return (dispatch) => {
        dispatch(attendanceLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/attendance/employee-mark-attendance`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    // dispatch(attendanceSuccess(res.data))
                    dispatch(GetTodayAttendance(token))
                    dispatch(HRAttendanceList(token, { employee_id: Data.employee_id, year_month: moment(new Date()).format('YYYYMM'), self: true }))
                    dispatch(getBirthdayAnniversary(token));

                    SuccessCenter.fire({
                        text: 'html',
                        html: `
                        <swal
                        <div class='background-default checkin-checkout-swal-container' style='padding:18px 24px 24px; '>
                            <div>   <img style='margin-top:50px;margin-bottom:30px' src=${res.data.data?.check_out_time == null ? '/assets/images/checkin.png' : '/assets/images/checkout.png'} />   </div
                            <div>
                                ${res.data.data?.check_out_time == null ? 'Checked In at ' + moment(res.data.data?.check_in_time).format('hh:mm A')
                                : 'Checked Out at ' + moment(res.data.data?.check_out_time).format('hh:mm A')}
                            </div>
                        <div>
                        `,
                    })
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(attendanceFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            });
    };
}


export function GetTodayAttendance(token) {

    return (dispatch) => {
        dispatch(todayAttendanceLoading());
        axios({
            method: 'GET',
            url: `${SERVER_URL}/attendance/employee-today-attendance`,
            headers: {
                "Authorization": 'Bearer ' + token,
            },

        })
            .then((res) => {

                if (res.status === 200) {
                    dispatch(todayAttendanceSuccess(res.data));
                }
            }).catch((error) => {
                if (error?.response) {
                    Errors.fire({
                        text: "html",
                        html: `<span style="color:white">${error.response.data.message}</span>`,
                    })
                    dispatch(todayAttendanceFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                }
            });
    };
}
/**
 * [2022-04-15]
 * changes by:-AanchaL Sahu
 * Description:-made year_month optional since it is not required in the dashboard anymore
**/
export function HRAttendanceList(token, data, page = null, rowsPerPage = null) {
    // year_month should be required
    let params = `self=${data?.self}`;
    if (page != null && rowsPerPage != null) {
        params += `&page=${page}&per_page=${rowsPerPage}`;
    }
    if (data?.year_month && data?.year_month != null) {
        params += `&year_month=${data?.year_month}`;
    }
    if (data?.employee_id && data?.employee_id != null) {
        params += `&employee_id=${data?.employee_id}`
    }
    if (data?.fullname && data?.fullname != null) {
        params += `&fullname=${data?.fullname}`
    }
    if (data?.sortBy) {
        params += `&sort=${data?.sortBy}`
    }

    return (dispatch) => {
        dispatch(attendanceListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/attendance/employee-attendance-view?${params}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(attendanceListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(attendanceListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                    // Above if block was commented
                }
            });
    };
}


export function AttendanceRegularization(token, Data, defaultParams, close = null) {

    const data = new FormData();
    data.append('employee_id', Data.employee_id);
    data.append('attendance_id', Data.attendance_id);
    data.append('attendance_date', Data.attendance_date);
    data.append('regularize_request_check', Data.regularize_request_check);

    if (Data.checkin_time == null || Data.checkin_time == '') {
        data.append('checkin_time', '');
    } else {
        data.append('checkin_time', moment(Data.checkin_time).format('YYYY-MM-DD hh:mm:ss A'));
    }
    if (Data.checkout_time == null || Data.checkout_time == '') {
        data.append('checkout_time', '');
    } else {
        data.append('checkout_time', moment(Data.checkout_time).format('YYYY-MM-DD hh:mm:ss A'));
    }
    data.append('updated_by', Data.updated_by);
    if (Data.regularize_remark && Data.regularize_remark != null) {
        data.append('regularize_remark', Data.regularize_remark);
    }


    return (dispatch) => {
        dispatch(attendanceListLoading());
        axios({
            method: 'post',
            url: `${SERVER_URL}/attendance/employee-attendance-regularize`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
            data
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(attendanceListSuccess(res.data))
                    dispatch(HRAttendanceList(token, defaultParams))
                    if (close) { close() }
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(attendanceListFailure(error.response.data));
                    // if (error?.response?.status == 401) {
                    //   dispatch(Logout(navigate,token))
                    // }
                }
            });
    };
}


export function attendanceLoading() {
    return {
        type: ATTENDANCE_LOADING,
    };
}
export function attendanceSuccess(payload) {
    return {
        type: ATTENDANCE_SUCCESS,
        payload
    };
}
export function attendanceFailure(payload) {
    return {
        type: ATTENDANCE_FAILURE,
        payload
    };
}


export function attendanceListLoading() {
    return {
        type: EMP_ATTENDANCE_LOADING,
    };
}
export function attendanceListSuccess(payload) {
    return {
        type: EMP_ATTENDANCE_SUCCESS,
        payload
    };
}

export function attendanceListFailure(payload) {
    return {
        type: EMP_ATTENDANCE_FAILURE,
        payload
    };
}

export function todayAttendanceLoading() {
    return {
        type: TODAY_ATTENDANCE_LOADING,
    };
}
export function todayAttendanceSuccess(payload) {
    return {
        type: TODAY_ATTENDANCE_SUCCESS,
        payload
    };
}
export function todayAttendanceFailure(payload) {
    return {
        type: TODAY_ATTENDANCE_FAILURE,
        payload
    };
}
