
import { Grid, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EmployeeListData } from "../../action/EmployeeAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import { EDivider } from "../../BaseComponents/EDivider";
import EModal from "../../BaseComponents/EModal";
import Page from "../../BaseComponents/EPage";
import EScrollbar from "../../BaseComponents/EScrollbar";
import InfoRoundedIcon from '@iconify/icons-material-symbols/info-outline';
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline';
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import ViewIcon from '@iconify/icons-material-symbols/visibility-outline';
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETooltip } from "../../BaseComponents/ETooltip";
import { ETypographyCardHeading, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { ACTIVE_USER, DEFAULT_ROWS_PERPAGE, ROW_SIZE_PER_PAGE } from "../../constants";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import { numberWithCommas } from "../../utils/formatNumber";
import { EIcon } from "../../BaseComponents/EIcon";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
// import PaymentRequestView from "../../PageComponents/Payment/PaymentRequestView";
// import PaymentRequestRespond from "../../PageComponents/Payment/PaymentRequestRespond";
// import PaymentRequestDelete from "../../PageComponents/Payment/PaymentRequestDelete";
import { TalentAppraisalList } from "../../action/TalentSalaryAppraisalAction";
import AppraisalModal from "../../PageComponents/Payment/AppraisalModal";
import AppraisalViewModal from "../../PageComponents/Payment/AppraisalViewModal";
import AppraisalDelete from "../../PageComponents/Payment/AppraisalDelete";
import { ECard } from "../../BaseComponents/ECard";


function PerformancePayment(props) {
    const { auth, employee } = props;
      
    const dispatch = useDispatch();

    //   const payment = useSelector((state) => state.PaymentReducer);
    // const employee = useSelector((state) => state.EmployeeReducer);
    const company = useSelector(state => state.CompanyReducer);
    // const { employeeProfileLoading, employeeProfileData } = useSelector((state) => state.EmployeeReducer);
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const [page, setPage] = useState(0);
    const [modalState, setModalState] = useState({ modalData: null, modalName: null });
    const talentAppraisal = useSelector(state => state.TalentSalaryAppraisalReducer);
    //   const [isRequestDetail, SetIsRequestDetail] = useState(false);
    const [SearchParameters, setSearchParameters] = useState({
        employee_id: employee?.id,
    });
   
    


    const getFilterData = (searchData) => {

        callApi({ ...searchData, sort: !searchData.sort ? ' id ASC ' : 'id DESC' }, 0, rowsPerPage);
        setPage(0);
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...SearchParameters }, page, rowsPerPage);
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER));
        }
    }, [auth?.authtoken])


    const handleChangePage = (event, newPage) => {
        callApi(SearchParameters, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(SearchParameters, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(TalentAppraisalList(auth.authtoken, fnParams, fnPage, fnRowPerPage));
    }



    const TABLE_HEAD = [
        // { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Appraisal Date', label: 'Appraisal Date', alignRight: false },
        { id: 'PromotedTo', label: 'Appraised For', alignRight: false },
        { id: 'Promoted To', label: 'Promoted To', alignRight: false },
        { id: 'Updated Salary', label: 'Updated Salary', alignRight: true },
        { id: 'Remark', label: 'Applicable From', alignRight: false },
        { id: 'Action', label: 'Action', alignRight: false },

    ];
    const currentDate = moment();

    const formattedDate = currentDate.format('YYYY-MM-DD');



    // employee?.lastAppraisal?.from_date);

  
    return (
        <>
       
         {employee.latestPayroll &&
             
                <ECard sx={{ padding: '20px', height: 'auto', textAlign: 'left', mx: "auto" }}>



                    <Grid container display="row" className='pb-2rem margin-top-5px justify-content-space-between align-flex-end '>
                    <Grid item>
                    <ETypographyCardHeading variant='h5'>Appraisal Details</ETypographyCardHeading>
                            </Grid>
                        
                        {
                            moment(formattedDate).isAfter(moment(employee?.lastAppraisal?.from_date)) &&
                            employee?.employeeReleaseInfo === null &&
                            
                             
         

                            <EButton variant="contained"
                                onClick={() => setModalState({ ...modalState, modalName: 'APPRAISAL' })}
                            >  Appraisal </EButton>
                        }
                        <Grid lg={12} xs={12} item className='pt-8px'><EDivider /></Grid>
                    </Grid>

                    <EBoxPage className="p0 mt-16px">

                        <EScrollbar>

                            <ETableContainer >
                                <ETable>

                                    <EListHead
                                        headLabel={TABLE_HEAD}
                                        rowCount={talentAppraisal?.salaryAppraisalData?.rows?.length}
                                        onRequestSort={() => null}

                                    />

                                    {talentAppraisal.salaryAppraisalLoading &&
                                        <ETableBody>
                                            <ETableRow>
                                                <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                                    <ECircularProgress color="primary" />
                                                </ETableCell>
                                            </ETableRow>
                                        </ETableBody>
                                    }
                                    {!talentAppraisal.salaryAppraisalLoading && talentAppraisal?.salaryAppraisalData?.rows?.length > 0 &&
                                        <ETableBody>

                                            {talentAppraisal?.salaryAppraisalData?.rows?.map((row, index) => {

                                                const { Employee, amount_after_appraisal, designationInfo, from_date, remark, updated_at, appraisal_type, apply_status } = row;
 
                                                return (

                                                    <ETableRow hover key={index} tabIndex={-1}>

                                                        <ETableCell align="left" >
                                                            {moment(updated_at).format('DD/MM/yyyy')}
                                                        </ETableCell>
                                                        <ETableCell align="left">
                                                            {/* { Employee?.designationInfo.designation_name? Employee?.designationInfo.designation_name:''} */}
                                                            {appraisal_type ? appraisal_type.charAt(0).toUpperCase() + appraisal_type.slice(1).toLowerCase() : ''}

                                                        </ETableCell>                                                       
                                                        <ETableCell align="left">
                                                            {designationInfo ? designationInfo?.designation_name: ''}

                                                            {/* {(appraisal_type == 'SALARY' || appraisal_type == 'BOTH') && (amount_after_appraisal ? numberWithCommas(amount_after_appraisal) : '')} */}
                                                        </ETableCell>
                                                        <ETableCell align="right" sx={{ width: '145px' }}>
                                                            {numberWithCommas(amount_after_appraisal)}

                                                        </ETableCell>
                                                        <ETableCell align="left">
                                                            {/* {remark && <ETooltip arrow title={remark}>
                                                        <EIcon icon={InfoRoundedIcon} />
                                                    </ETooltip>} */}
                                                            {moment(from_date).format('DD/MM/yyyy')}

                                                        </ETableCell>

                                                        <ETableCell align="left">
                                                            {/* {from_date && moment(from_date).isSameOrAfter(moment().format('YYYY-MM-DD')) &&
                                                     */}
                                                            {apply_status == "PENDING" && <>
                                                                <IconButton onClick={() => setModalState({ modalData: row, modalName: 'EDIT' })}>
                                                                    <EIcon icon={EditIcon} />
                                                                </IconButton>

                                                                <IconButton onClick={() => setModalState({ modalData: row, modalName: 'DELETE' })}>

                                                                    <EIcon className="icon-red" icon={DeleteIcon} />
                                                                </IconButton>
                                                            </>}

                                                            {apply_status == "COMPLETED" &&
                                                                <IconButton onClick={() => setModalState({ modalData: row, modalName: 'VIEW' })}>
                                                                    <EIcon className="" icon={ViewIcon} />
                                                                </IconButton>
                                                            }
                                                        </ETableCell>
                                                    </ETableRow>
                                                );
                                            })}
                                        </ETableBody>
                                    }

                                </ETable>
                            </ETableContainer>
                        </EScrollbar>

                        {!talentAppraisal.salaryAppraisalLoading && talentAppraisal?.salaryAppraisalData?.rows?.length == 0 && (
                            <SearchNotFound />
                        )}

                    </EBoxPage>
                    {!talentAppraisal.salaryAppraisalLoading && talentAppraisal?.salaryAppraisalData?.count > DEFAULT_ROWS_PERPAGE &&
                        <ETablePagination
                            rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                            component="div"
                            count={talentAppraisal?.salaryAppraisalData?.count ? talentAppraisal?.salaryAppraisalData?.count : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    }


                    {modalState.modalName && ['APPRAISAL', 'EDIT'].includes(modalState.modalName) &&
                        <EModal open={['APPRAISAL', 'EDIT'].includes(modalState.modalName)}
                            headervalue={`Appraisal`}
                        >
                            <AppraisalModal
                                setModalState={setModalState}
                                modalState={modalState}
                                auth={auth}
                                getPaymentFilterData={() => getFilterData(SearchParameters)}
                                employeeList={employee}
                                companyData={company} />
                        </EModal>}


                    {modalState.modalName && modalState.modalName === 'VIEW' &&
                        <EModal open={modalState.modalName === 'VIEW'}
                            headervalue={`Appraisal Details`}
                        >
                            <AppraisalViewModal
                                setModalState={setModalState}
                                modalState={modalState}
                                auth={auth}
                                employee={employee}
                            />
                        </EModal>}

                    {modalState.modalName && modalState.modalName === 'DELETE' &&
                        <EModal open={modalState.modalName === 'DELETE'}
                            headervalue={`Confirmation:Delete Appraisal`}
                        >
                            <AppraisalDelete
                                setModalState={setModalState}
                                modalState={modalState}
                                getPaymentFilterData={() => getFilterData(SearchParameters)}
                                auth={auth}
                            />
                        </EModal>}





                </ECard>
}
        </>
    );
}

export default PerformancePayment;
