import {
  TODAY_ATTENDANCE_SUCCESS, 
  TODAY_ATTENDANCE_FAILURE, 
  TODAY_ATTENDANCE_LOADING,
} from '../constants';

const INITIAL_STATE = {
  attendanceData: {},
  attendanceSuccess: false,
  attendanceLoading: false,
  attendanceMessage: null,
};

const AttendanceDayEmployeeReducer = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    // FOR ATTENDANCE LIST 

    case TODAY_ATTENDANCE_LOADING:
      return {
        attendanceLoading: true,
        attendanceData: {},
      };

    case TODAY_ATTENDANCE_SUCCESS:
      return {
        attendanceLoading: false,
        attendanceSuccess: true,
        attendanceData: action.payload.data,
        attendanceMessage: action.payload.message,
      };

    case TODAY_ATTENDANCE_FAILURE:
      return {
        attendanceLoading: false,
        attendanceSuccess: false,
        attendanceMessage: action.payload.message,
        attendanceData: {},
      };


    default:
      return state;
  }
};

export default AttendanceDayEmployeeReducer;
