import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { REPORT_BY_DATE } from '../../constants';
import { ECardDashboard } from '../../BaseComponents/ECard';
import { ETypography } from '../../BaseComponents/ETypography';
import { ETable, ETableBody, ETableCell, ETableContainer, EListHeadSorting, ETableRowSorting } from '../../BaseComponents/ETable';
import EScrollbar from '../../BaseComponents/EScrollbar';
import { EDivider } from '../../BaseComponents/EDivider';
import { ECardPagination } from '../../BaseComponents/ETablePagination';
import { EBox } from '../../BaseComponents/EBox';
import { EAccordion2, EAccordionDetails, EAccordionSummary } from '../../BaseComponents/EAccordion';
import ArrowUp from '@iconify/icons-material-symbols/keyboard-arrow-up-rounded';
import ArrowDown from '@iconify/icons-material-symbols/keyboard-arrow-down-rounded';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EIcon } from '../../BaseComponents/EIcon';
import EHidden from '../../BaseComponents/EHidden';


const TABLE_HEAD_TIME = [{ id: 'Name', label: 'Name', alignRight: false },
{ id: 'Code', label: 'Code', alignRight: false, hideLabelCriteria: 'lgDown' },
{ id: 'Time', label: 'Time', alignRight: false }]
const TABLE_HEAD_TIME_NAME = [{ id: 'Name', label: 'Name', alignRight: false },
{ id: 'Code', label: 'Code', alignRight: false }]

const gridV = {
  initial: { xl: 0.8, lg: 0.8, md: 0.8, sm: 1.7, xs: 2 },
  divider: 0.1,
  tables: { xl: 5.3, lg: 5.3, md: 5.3, sm: 4.8, xs: 3.8 },
  fullTable: { xl: 11, lg: 11, md: 11, sm: 10.1, xs: 9.8 },
  end: { xl: 0.2, lg: 0.2, md: 0.2, sm: 0.2, xs: 0.2 },
}

const Panel = 'panel';
const defaultPanel = Panel + '1';

function ReportsResultsTime(props) {
  const { report, attendance, SearchParameters, page, setPage } = props;
  const auth = useSelector((state) => state.authReducer);
  const Theme = useSelector((state) => state.ThemeReducer);
  const [expanded, setExpanded] = React.useState(defaultPanel);

  // INFO: totalNoOfPages is for card pagination total page count
  const totalNoOfPages = (attendance?.empMonthAttData?.count / 10) ? Math.ceil(attendance?.empMonthAttData?.count / 10) : 0;
  const TABLE_HEAD = [];
  TABLE_HEAD.push(
    { id: 'S.No.', label: 'S.No.', alignRight: 'center' },
  );
  if (report == REPORT_BY_DATE) {
    TABLE_HEAD.push(
      { id: 'Employee Name', label: 'Employee Name', alignRight: false }
    );
  } else {
    TABLE_HEAD.push({ id: 'Date', label: 'Date', alignRight: false });
  }

  TABLE_HEAD.push(
    { id: 'Login Time', label: 'Login Time', alignRight: 'center' },
    { id: 'Logout Time', label: 'Logout Time', alignRight: 'center' },
    { id: 'Check-in Time', label: 'Check In Time', alignRight: 'center' },
    { id: 'Check-out Time', label: 'Check Out Time', alignRight: 'center' },
    { id: 'Default Attendance', label: 'Default Attendance', alignRight: 'center' },
    { id: 'Remark', label: 'Remark', alignRight: 'center' },
    { id: 'Modified To', label: 'Modified To', alignRight: 'center' },
    { id: 'Date & Time of Modulation', label: 'Date & Time of Modulation', alignRight: 'center', notCapitalize: true },
  );

  // INFO: handleCardPageChange is for handling card pagination change
  const handleCardPageChange = (event, newPage) => {
    setPage(newPage - 1);
    setExpanded(defaultPanel)
  };

  const handlePanelChange = (panel) => {
    setExpanded(panel ? panel : false);
  };
  const [order, setOrder] = React.useState({ before: 'desc', after: 'desc' });

  const handleRequestSortBefore = (event, property) => {
    const isAsc = order.before === 'asc';
    setOrder({ ...order, before: isAsc ? 'desc' : 'asc' });
  };

  const handleRequestSortAfter = (event, property) => {
    const isAsc = order.after === 'asc';
    setOrder({ ...order, after: isAsc ? 'desc' : 'asc' });
  };
  //This function is used for sorting the rows
  function stableSort(data, orderby) {
    const result = data && data.slice(0).sort((a, b) => {
      if (orderby == 'asc') {
        return a.check_in_time.localeCompare(b.check_in_time)
      }
      else { return b.check_in_time.localeCompare(a.check_in_time) }
    });
    return result
  }

  return (
    <>
      {!attendance?.empMonthAttLoading && attendance?.empMonthAttData?.rows?.length > 0 && (
        attendance?.empMonthAttData?.rows?.map((row, index) => {
          const { remainTime, afterTime, beforeTime, date, is_holiday } = row;
          const isActivePanel = expanded === Panel + (index + 1) ? true : false
          const isShowOnHoliday = !is_holiday || (is_holiday && ( afterTime?.length > 0 || beforeTime?.length > 0))
          {/* console.log(date ,is_holiday,afterTime?.length, beforeTime?.length) */}
          return (
            <EAccordion2 expanded={expanded === Panel + (index + 1)} onChange={() => handlePanelChange(Panel + (index + 1))} key={index} className='Accordian-color-flip'>
              <EAccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='mb-0px pb-0'>
                <Grid container className='py0' >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <EBox>
                      <Grid container spacing={1} >
                        <InitialGrid className='py0'>
                          <ECardDashboard className={'width-45px p0 pt4px shadow-none default-bgcolor-static border-none  ' + (isActivePanel ? 'border-top-main-color  ' : '')} align={'center'} >
                            <span className='font-size-20px bold-600 line-height-120perc '>{moment(date).format('DD')}</span><br /> <span className='font-size-12px line-height-120perc'>
                              {moment(date).format('MMM YY')}
                            </span>
                          </ECardDashboard>
                        </InitialGrid>
                        {isShowOnHoliday ? <>
                          <Grid item xs={gridV.tables.xs} sm={gridV.tables.sm} md={gridV.tables.md} lg={gridV.tables.lg} xl={gridV.tables.xl}
                            className='display-flex align-center justify-content-space-between py0'>
                            <ETypography className={'text-primary-when-active ' + (isActivePanel ? 'bold-600' : 'font-weight-400')}>
                              Before {moment(SearchParameters?.attendance_time).format('HH:mm')}</ETypography>
                            <EBox className='green-box-text-bg width-30px-report h-30px borderRadius-3px font-size-19px bold-600 text-align'>
                              {beforeTime ? beforeTime?.length : 0}
                            </EBox>
                          </Grid>

                          <Grid item xs={gridV.divider} sm={gridV.divider} md={gridV.divider} lg={gridV.divider} xl={gridV.divider} >
                            <EDivider orientation="vertical" className='borderColor-dark-grey' />
                          </Grid>

                          <Grid item xs={gridV.tables.xs} sm={gridV.tables.sm} md={gridV.tables.md} lg={gridV.tables.lg}
                            xl={gridV.tables.xl} className='display-flex align-center justify-content-space-between py0'>
                            <ETypography className={'text-primary-when-active ' + (isActivePanel ? 'bold-600' : 'font-weight-400')}>
                              After {moment(SearchParameters?.attendance_time).format('HH:mm')}
                            </ETypography>
                            <EBox >
                              <EBox className='red-box-text-bg width-30px-report h-30px borderRadius-3px font-size-19px bold-600 text-align'>{afterTime ? afterTime?.length : 0}</EBox>

                            </EBox>
                          </Grid>
                        </> :
                          <Grid item xs={gridV.fullTable.xs} sm={gridV.fullTable.sm} md={gridV.fullTable.md} lg={gridV.fullTable.lg} xl={gridV.fullTable.xl}
                            className={'display-flex align-center justify-content-space-between py0 blue-color-static bold-600'}>

                            Holiday</Grid>
                        }

                        <EndGrid>
                          {isShowOnHoliday ? <EIconButton > <EIcon icon={isActivePanel ? ArrowUp : ArrowDown} />  </EIconButton> : ''}
                        </EndGrid>
                      </Grid>
                    </EBox>
                  </Grid>
                </Grid>
              </EAccordionSummary>
              {isActivePanel &&
                <EAccordionDetails>
                  <Grid container spacing={1}>
                    <InitialGrid />

                    <Grid item xs={gridV.tables.xs} sm={gridV.tables.sm} md={gridV.tables.md} lg={gridV.tables.lg} xl={gridV.tables.xl}>
                      <EScrollbar>
                        <ETableContainer>
                          <ETable>
                            <EListHeadSorting
                              headLabel={TABLE_HEAD_TIME}
                              rowCount={attendance?.empMonthAttData?.rows?.length}
                              onRequestSort={handleRequestSortBefore}
                              orderBy={'Time'}
                              order={order.before}
                              authvar={auth}
                              Theme={Theme}
                            />
                            {!attendance?.empMonthAttLoading && beforeTime?.length > 0 ?
                              <ETableBody>
                                {stableSort(beforeTime, order.before)?.map((row, index) => {
                                  const { fullname, check_in_time, employee_code, is_regularized } = row;
                                  const isBorderBottom = beforeTime?.length > 0 && index == beforeTime?.length - 1 ? 'borderBottom-none' : ''
                                  const isRegularizeClass =is_regularized? ' theme-main-text-color-bold':''
                                  return (
                                    <ETableRowSorting hover key={index} tabIndex={-1} >
                                      <ETableCell align="left" className={isBorderBottom}> {fullname}
                                      <EHidden width="lgUp"><br/>{employee_code}</EHidden>
                                      </ETableCell>
                                      <EHidden width="lgDown"><ETableCell align="left" className={isBorderBottom}> {employee_code}</ETableCell></EHidden>
                                      <ETableCell align="left" className={" pt-16px width-20 " + isBorderBottom + isRegularizeClass}>
                                        {moment(check_in_time).format('HH:mm')}
                                      </ETableCell>
                                    </ETableRowSorting>
                                  )
                                })}
                              </ETableBody>
                              : beforeTime?.length == 0 ?
                                <ETableBody >
                                  <ETableRowSorting >
                                    <ETableCell align="center" colSpan={3} className='borderBottom-none'>
                                      <span className='bold-600'>No record found </span>
                                    </ETableCell>
                                  </ETableRowSorting>
                                </ETableBody> : ''
                            }
                          </ETable>
                        </ETableContainer>
                      </EScrollbar>
                    </Grid>

                    <Grid item xs={gridV.divider} sm={gridV.divider} md={gridV.divider} lg={gridV.divider} xl={gridV.divider} >
                      <EDivider orientation="vertical" className='borderColor-dark-grey' />
                    </Grid>
                    <EndGrid />
                    <Grid item xs={gridV.tables.xs} sm={gridV.tables.sm} md={gridV.tables.md} lg={gridV.tables.lg} xl={gridV.tables.xl}>
                      <EScrollbar>
                        <ETableContainer>
                          <ETable>
                            <EListHeadSorting
                              headLabel={TABLE_HEAD_TIME}
                              rowCount={attendance?.empMonthAttData?.rows?.length}
                              onRequestSort={handleRequestSortAfter}
                              orderBy={'Time'}
                              order={order.after}
                              authvar={auth}
                              Theme={Theme}
                            />
                            {!attendance?.empMonthAttLoading && afterTime?.length > 0 ? (
                              <ETableBody>
                                {stableSort(afterTime, order.after)?.map((row, index) => {
                                  const { fullname, check_in_time, employee_code ,is_regularized} = row;
                                  const isBorderBottom = afterTime?.length > 0 && index == afterTime?.length - 1 ? ' borderBottom-none' : '';
                                  const isRegularizeClass =is_regularized? ' '+'theme-main-text-color-bold':''
                                  return (
                                    <ETableRowSorting hover key={index} tabIndex={-1} >
                                      <ETableCell align="left" className={isBorderBottom}> {fullname}
                                      <EHidden width="lgUp"><br/>{employee_code}</EHidden>
                                      </ETableCell>
                                      <EHidden width="lgDown"><ETableCell align="left" className={isBorderBottom}> {employee_code}</ETableCell></EHidden>
                                      <ETableCell align="left" style={{ verticalAlign: 'top' }} className={"width-20 pt-16px " + isBorderBottom+isRegularizeClass}>
                                        {moment(check_in_time).format('HH:mm')}
                                      </ETableCell>
                                    </ETableRowSorting>
                                  );
                                })}
                              </ETableBody>

                            )
                              : (
                                <ETableBody >
                                  <ETableRowSorting >
                                    <ETableCell align="center" colSpan={3} className='borderBottom-none'>
                                      <span className='bold-600'>No record found </span>
                                    </ETableCell>
                                  </ETableRowSorting>
                                </ETableBody>
                              )
                            }
                          </ETable>
                        </ETableContainer>
                      </EScrollbar>
                    </Grid>
                    <EndGrid />
                    <InitialGrid />
                    <Grid item xs={gridV.fullTable.xs} sm={gridV.fullTable.sm} md={gridV.fullTable.md} lg={gridV.fullTable.lg} xl={gridV.fullTable.xl}>
                      <EDivider className='borderColor-dark-grey' />
                    </Grid>
                    <EndGrid />
                    <InitialGrid />

                    <Grid item xs={gridV.fullTable.xs} sm={gridV.fullTable.sm} md={gridV.fullTable.md} lg={gridV.fullTable.lg} xl={gridV.fullTable.xl}
                      className={'display-flex align-center justify-content-space-between py0 mt-8px'}>
                      <ETypography className={'text-primary-when-active ' + (isActivePanel ? 'bold-600' : 'font-weight-400')}>
                        Yet to Check-in</ETypography>
                      <EBox className='grey-box-text-bg width-30px-report h-30px borderRadius-3px font-size-19px bold-600 text-align'>
                        {remainTime ? remainTime?.length : 0}
                      </EBox>
                    </Grid>
                    <EndGrid />
                    <InitialGrid />

                    <Grid item xs={gridV.fullTable.xs} sm={gridV.fullTable.sm} md={gridV.fullTable.md} lg={gridV.fullTable.lg} xl={gridV.fullTable.xl}>

                      <EScrollbar>
                        <ETableContainer>
                          <ETable>
                            <EListHeadSorting
                              headLabel={TABLE_HEAD_TIME_NAME}
                              rowCount={attendance?.empMonthAttData?.rows?.length}
                              onRequestSort={() => null}
                              authvar={auth}
                              Theme={Theme}
                            />
                            {!attendance?.empMonthAttLoading && remainTime?.length > 0 ? (
                              <ETableBody>
                                {remainTime?.map((row, index) => {
                                  const { fullname, employee_code } = row;
                                  return (
                                    <ETableRowSorting hover key={index} tabIndex={-1} >
                                      <ETableCell align="left" className='width-30per'> {fullname}</ETableCell>
                                      <ETableCell align="left" > {employee_code}</ETableCell>
                                    </ETableRowSorting>
                                  );
                                })}
                              </ETableBody>)
                              : !attendance?.empMonthAttLoading && remainTime?.length == 0 ?
                                <ETableBody >
                                  <ETableRowSorting >
                                    <ETableCell align="center" colSpan={2} className='borderBottom-none'>
                                      <span className='bold-600'>No record found </span>
                                    </ETableCell>
                                  </ETableRowSorting>
                                </ETableBody> : ''
                            }
                          </ETable>
                        </ETableContainer>
                      </EScrollbar>

                    </Grid>
                  </Grid>
                </EAccordionDetails>}
            </EAccordion2>
          )
        }
        )
      )}
      {attendance?.empMonthAttData?.rows && attendance?.empMonthAttData?.count > 0 &&
        <Grid container my={3}
          direction="row"
          justifyContent="center"
          alignItems="center">

          <ECardPagination
            size="large"
            count={totalNoOfPages}
            page={page + 1}
            onChange={handleCardPageChange}
            boundaryCount={0}
          />
        </Grid>}
    </>
  );
}
export default ReportsResultsTime;

const InitialGrid = ({ children = null, className = null }) => {
  return (
    <Grid item className={className ? className : ''} xs={gridV.initial.xs} sm={gridV.initial.sm} md={gridV.initial.md} lg={gridV.initial.lg} xl={gridV.initial.xl}>
      {children ? children : null}
    </Grid>)
}

const EndGrid = ({ children = null, className = null }) => {
  return (
    <Grid item className={className ? className : ''} xs={gridV.end.xs} sm={gridV.end.sm} md={gridV.end.md} lg={gridV.end.lg} xl={gridV.end.xl}>
      {children ? children : null}
    </Grid>)
}