

import { Grid, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined } from '../../BaseComponents/EButtons';

import { EFieldLabelBold, ETypography } from '../../BaseComponents/ETypography';
import { numberWithCommas } from '../../utils/formatNumber';
import { ECard } from '../../BaseComponents/ECard';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
const AppraisalViewModal = (props) => {
	const talentAppraisal = useSelector(state => state.TalentSalaryAppraisalReducer);
	const { setModalState, auth, modalState, employee } = props;
	const theme = useTheme();

	return (

		<Grid container spacing={2} >
			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<ECard className='width-100' sx={{ backgroundColor: theme.palette.background.card8 }}>
					{/* {['DESIGNATION', 'BOTH'].includes(modalState.modalData?.appraisal_type) &&
                            <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                                Promoted To - {modalState.modalData?.designationInfo?.designation_name}
                            </ETypography>}
                        {['SALARY', 'BOTH'].includes(modalState.modalData?.appraisal_type) &&
                            <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                                Updated CTC - {numberWithCommas(modalState.modalData?.amount_after_appraisal)}
                            </ETypography>}
                        <ETypography my={1} variant="h6" className='bold-600 color-text-default-static'>
                            Scheduled Date - {moment(modalState.modalData?.from_date).format('DD/MM/YYYY')}
                        </ETypography> */}
					<Grid item xs={12} sm={12} lg={12} xl={12}>
						<FieldContainerCustom
							labelwidth={50}
						>
							<EFieldLabelBold> Current Designation </EFieldLabelBold>
							<ETypography>{modalState.modalData.Employee.designationInfo?.designation_name}</ETypography>
						</FieldContainerCustom>
						<Grid item xs={12} sm={12} lg={12} xl={12}>
							<FieldContainerCustom
								labelwidth={50}
							>
								<EFieldLabelBold> Current Department </EFieldLabelBold>
								<ETypography>{modalState.modalData.Employee.departmentInfo?.department_name}</ETypography>
							</FieldContainerCustom>
							<Grid item xs={12} sm={12} lg={12} xl={12}>
								<FieldContainerCustom
									labelwidth={50}
								>

									<EFieldLabelBold> Current CTC
										<small className='small-text' style={{ textTransform: 'lowercase' }}>
											(<span style={{ textTransform: 'capitalize' }}>Per month</span>)
										</small>
									</EFieldLabelBold>
									<ETypography>{numberWithCommas(modalState.modalData.amount_before_appraisal)}</ETypography>
								</FieldContainerCustom>
							</Grid>

						</Grid>

					</Grid>


				</ECard>


			</Grid>
			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<ELabelInputMainGrid
					label={"Name"}
					isModal={true}
					isNotForm={true}
				>
					<ETypography>{modalState.modalData?.Employee?.fullname}</ETypography>
				</ELabelInputMainGrid>
			</Grid>

			{['DESIGNATION', 'BOTH'].includes(modalState.modalData?.appraisal_type) && <>
				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={" Appraisal Date"}
						isModal={true}
						isNotForm={true}
					>
						<ETypography>  {moment(modalState.modalData.created_at).format('DD/MM/yyyy')}</ETypography>

					</ELabelInputMainGrid>
				</Grid>

				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={" Appraisal for"}
						isModal={true}
						isNotForm={true}
					>
						{employee.lastAppraisal?.appraisal_type
							? employee.lastAppraisal.appraisal_type.charAt(0).toUpperCase() +
							employee.lastAppraisal.appraisal_type.slice(1).toLowerCase()
							: ''}
					</ELabelInputMainGrid>
				</Grid>
				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={"Applicable from"}
						isModal={true}
						isNotForm={true}
					>

						<ETypography>  {moment(employee.lastAppraisal?.from_date).format('DD/MM/yyyy')}</ETypography>
					</ELabelInputMainGrid>
				</Grid>
				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={"Updated Department"}
						isModal={true}
						isNotForm={true}
					>
						<ETypography>{employee.departmentInfo.department_name}</ETypography>
					</ELabelInputMainGrid>
				</Grid>
				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={" Updated Designation"}
						isModal={true}
						isNotForm={true}
					>
						<ETypography>{employee.designationInfo?.designation_name}</ETypography>
					</ELabelInputMainGrid>
				</Grid>
			</>}
			{['SALARY', 'BOTH'].includes(modalState.modalData?.appraisal_type) && <>

				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={"  Increment Amount"}
						isModal={true}
						isNotForm={true}
					>
						<ETypography>{numberWithCommas(modalState.modalData?.appraisal_amount)}</ETypography>
						{/* <ETypography>{talentAppraisal.}</ETypography> */}

					</ELabelInputMainGrid>
				</Grid>

				<Grid item xs={12} sm={12} lg={12} xl={12}>
					<ELabelInputMainGrid
						label={"Updated CTC "}
						small_text={'(Per Month)'}
						isModal={true}
						isNotForm={true}
					>
						{/* <EFieldLabelBold> Updated CTC
                        <small className='small-text' style={{ textTransform: 'lowercase' }}>
        (<span style={{ textTransform: 'capitalize' }}>Per month</span>)
    </small>    */}

						{/* </EFieldLabelBold> */}
						<ETypography>{numberWithCommas(modalState.modalData?.amount_after_appraisal)}</ETypography>
					</ELabelInputMainGrid>
				</Grid>
			</>}

			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<ELabelInputMainGrid
					label={"Remark"}
					isModal={true}
					isNotForm={true}
				>
					{modalState.modalData?.remark}

				</ELabelInputMainGrid>
			</Grid>
			
			<Grid item xs={12} sm={12} lg={12} xl={12}>
				<Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
					<EButtonOutlined size="large" variant="outlined"
						onClick={() => setModalState({ modalData: null, modalName: null })}
					> Close </EButtonOutlined>


				</Stack>
			</Grid>
		</Grid>

	);
};
export default AppraisalViewModal;
