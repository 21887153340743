import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import {Link as MuiLink} from '@mui/material'
export const ELinkStaticColor = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const ELink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary
}));

export const ELinkStaticBlue = styled(Link)(({ theme }) => ({
    color: theme.palette.text.staticBlue3
}));



export const ELinkMUI = styled(MuiLink)(({ theme }) => ({
 
}));

export const ELinkHRDAshMUI = styled(MuiLink)(({ theme }) => ({
    position : "absolute",
    bottom: '5px',
    right:'20px'
    // color: theme.palette.link.hrLink
}));