
import { styled } from "@mui/material";

export const EImage = styled('img')(({ theme, top = null, bottom = null, left = null, right = null }) => ({
  // position: 'absolute',
}));

export const EAbsoluteImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  zIndex: -1,
}));