import { CircularProgress, Grid, IconButton, Stack } from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HolidayList, UpdateHoliday } from "../../action/HolidayAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import { EButton, EButtonIcon, EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import moment from 'moment';
import Page from "../../BaseComponents/EPage";
import { ETypography, ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { DEFAULT_ROWS_PERPAGE, DELETE, HOLIDAY_LIST_DOWNLOAD_URL, PERMISSIONS, ROW_SIZE_PER_PAGE } from "../../constants";
import EListHead, { ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import SearchNotFound from "../../PageComponents/SearchNotFound";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import EModal from "../../BaseComponents/EModal";
import HolidaySettingForm from "../../PageComponents/company/HolidaySettingForm";
import { EIcon } from "../../BaseComponents/EIcon";
import DeleteIcon from '@iconify/icons-material-symbols/delete-outline-rounded'
import EditIcon from '@iconify/icons-material-symbols/edit-outline-rounded'
import EHidden from "../../BaseComponents/EHidden";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import HolidayIcon from '@iconify/icons-material-symbols/beach-access-outline-rounded';
import { useCheckPermission } from "../../hooks";
import { PaymentSearchFilter } from "../../PageComponents/Payment/PaymentSearchFilter";
import { DownLoadFile } from "../../action/AdminAction";



const getTableHead = (allowAction) => {
   const arrayHead = [
      { id: 'SNo.', label: 'SNo.', alignRight: false },
      { id: 'Date', label: 'Date', alignRight: false },
      { id: 'NoOfDay', label: 'Number of Days', alignRight: "center", notCapitalize: true },
      { id: 'Occasion', label: 'Occasion', alignRight: false }
   ]
   if (allowAction) {
      arrayHead.push({ id: 'Action', label: 'Action', alignRight: 'center' });
   }
   return arrayHead
}

const Holiday = () => {
   const dispatch = useDispatch();
   const holiday = useSelector((state) => state.HolidayReducer);
   const auth = useSelector((state) => state.authReducer);
   const navigate = useNavigate()
   const [SearchParameters, setSearchParameters] = useState({
      sort: true, year_month: null
   })

   const [IsHolidayDelete, SetIsHolidayDelete] = useState({ isOpen: false, row: null }); //for delete functionality
   const [IsHolidayEdit, SetIsHolidayEdit] = useState({ isOpen: false, row: null }); //for add and edit functionality
   const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
   const [page, setPage] = useState(0);

   const allowEdit = useCheckPermission([PERMISSIONS.ViewEditHoliday])

   const getFilterData = (searchData) => {
      callApi(searchData, 0, rowsPerPage);
      setPage(0);
   }

   useEffect(() => {
      if (auth.authtoken) {
         callApi({ ...SearchParameters }, page, rowsPerPage);
      }
   }, [auth?.authtoken])

   const handleChangePage = (event, newPage) => {
      callApi(SearchParameters, newPage, rowsPerPage);
      setPage(newPage);
   }

   const handleChangeRowsPerPage = (event) => {
      const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
      setRowsPerPage(rowPerPageCount);
      callApi(SearchParameters, 0, rowPerPageCount);
      setPage(0);
   };

   const callApi = (fnParams, fnPage, fnRowPerPage) => {
      dispatch(HolidayList(auth.authtoken,
         { ...fnParams, year_month: fnParams.year_month ? moment(fnParams.year_month).format('YYYYMM') : null, sort: !fnParams.sort ? ' id ASC ' : 'id DESC' },
         fnPage, fnRowPerPage));
   }

   const TABLE_HEAD = useMemo(() => getTableHead(allowEdit), [])

   const closeEdit = () => {
      SetIsHolidayEdit({ ...IsHolidayEdit, isOpen: false, row: null })
   }

   const closeDelete = () => {
      SetIsHolidayDelete({ ...IsHolidayEdit, isOpen: false, row: null })
   }

   const DownloadReport = (value) => {
      let url = '';
      let file_name = 'Holiday-list';
      url += HOLIDAY_LIST_DOWNLOAD_URL;

      if (value != null) { url += `?download_format=${value}`; }
      if (SearchParameters?.year_month != null) {
         file_name += '_' + moment(SearchParameters?.year_month).format('YYYYMM');
         url += `&month_year=${moment(SearchParameters?.year_month).format('YYYYMM')}`;
      }
      if (SearchParameters?.sort != null) {
         file_name += !SearchParameters?.sort ? '_ASC' : '_DESC';
         url += `&sort=${!SearchParameters.sort ? ' id ASC ' : 'id DESC'}`;
      }
      DownLoadFile(auth.authtoken, url, file_name);
   }

   const onSearch = (data) => {
      const params = {
         ...data,
         sort: data?.sort,
         year_month: data?.start ? data?.start : null,

      }
      setSearchParameters(params)
      callApi(params, 0, rowsPerPage);
      setPage(0);
   }

   return (
      <>
         <Page title="Holiday List" >
            <Grid container display="row" justifyContent="space-between" className='align-center'>
               <Grid item >
                  <EHeaderBreadcrumbs
                     heading='Holiday List'
                     links={[
                        { name: 'Dashboard', href: '/' },
                        { name: 'Settings', href: '' },
                        {
                           name: 'Holiday List',
                        }
                     ]}
                  />
               </Grid>
               <Grid item>

                  {
                     allowEdit &&
                     <EHidden width="mdDown">
                        <EButton variant="contained" onClick={() => { SetIsHolidayEdit({ isOpen: true }) }} className="mr-16px" >  Add Holiday </EButton>
                     </EHidden>

                  } {' '}


                  <EHidden width="mdDown">
                     <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                  </EHidden>

               </Grid>
            </Grid>

            <Grid container display="row" className='pb-2rem margin-top-20px justify-content-space-between align-flex-end'>
               <Grid item>
                  <ETypographyPageHeading variant='h5'>Holiday List</ETypographyPageHeading>
               </Grid>
               <EHidden width="mdUp">
                  <Grid item>
                     {
                        allowEdit &&
                        <EButtonIcon variant="outlined" onClick={() => { SetIsHolidayEdit({ isOpen: true }) }} className='button-left-margin'>  <EIcon icon={HolidayIcon} className='height-width-15px' /> </EButtonIcon>
                     }

                     <EButtonOutlinedIcon variant="outlined" onClick={() => navigate(-1)} className='button-left-margin'>  <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>

                  </Grid>



               </EHidden>
               <EHidden width='mdDown'>
                  <Grid xs={12} lg={12} item><EDivider className='margin-top-05' /></Grid>
               </EHidden>
            </Grid>

            <EBoxPage>
               <PaymentSearchFilter
                  initialFilter={SearchParameters}
                  onSearch={onSearch}
                  onSort={onSearch}
                  DownloadReport={DownloadReport}
                  start={{
                     placeholder: 'All Month',
                     views: ['year', 'month'],
                     autoComplete: 'off',
                     inputFormat: "MMM yyyy",
                     minDate: new Date(moment().subtract(1, 'years').format('MMM YYYY')),
                     maxDate: new Date(moment().add(1, 'years').format('MMM YYYY')),
                  }}
               />

               <Grid my={2}>
                  <EScrollbar>
                     <ETableContainer >
                        <ETable>
                           <EListHead
                              headLabel={TABLE_HEAD}
                              rowCount={holiday?.holidayListData?.length}
                              onRequestSort={() => null}

                           />
                           {!holiday?.holidayListLoading && holiday?.holidayListData?.rows?.length > 0 ? (


                              <ETableBody>
                                 {holiday?.holidayListData?.rows?.map((row, index) => {
                                    const { holiday_name, from_date, to_date, holiday_count } = row;
                                    const isSameDate = moment(from_date).isSame(to_date);


                                    const dateTextDate = isSameDate ? (moment(to_date).format('DD/MM/YYYY')) : (`${moment(from_date).format('DD/MM/YYYY')} to ${moment(to_date).format('DD/MM/YYYY')}`)
                                    const dateTextDay = isSameDate ? (moment(to_date).format('dddd')) : (`${moment(from_date).format('dddd')} to ${moment(to_date).format('dddd')}`)
                                    return (
                                       <ETableRow
                                          hover
                                          key={index}
                                          tabIndex={-1}
                                       >
                                          <ETableCell align="left" >
                                             {page == 0 ? index + 1 : (index + 1) + (rowsPerPage * page)}
                                          </ETableCell>
                                          <ETableCell align="left">
                                             {dateTextDate}
                                             <br />
                                             {`(${dateTextDay})`}

                                          </ETableCell>
                                          <ETableCell align="center" sx={{ width: '200px' }}> {holiday_count ? holiday_count : '-'} </ETableCell>
                                          <ETableCell align="left" > {holiday_name ? holiday_name : '-'} </ETableCell>


                                          {allowEdit &&
                                             <ETableCell align="right" sx={{ width: '60px' }}>

                                                <Grid container sx={{ flexWrap: 'nowrap' }}>
                                                   {from_date && moment(from_date).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') &&
                                                      <>
                                                         <Grid item>
                                                            <IconButton onClick={() => { SetIsHolidayEdit({ isOpen: true, row: row }) }} className='p0 '>
                                                               <EIcon icon={EditIcon} width={24} height={24} />

                                                            </IconButton>
                                                         </Grid>

                                                         <Grid item>
                                                            <IconButton className='p0 ml-12px' onClick={() => { SetIsHolidayDelete({ isOpen: true, row: row }) }} >
                                                               <EIcon icon={DeleteIcon} sx={{ color: 'text.redColor' }} width={24} height={24} />
                                                            </IconButton>
                                                         </Grid>

                                                      </>
                                                   }
                                                </Grid>
                                             </ETableCell>
                                          }
                                       </ETableRow>
                                    );
                                 })}
                              </ETableBody>
                           ) : (<>
                              {holiday?.holidayListLoading ? (
                                 <ETableBody>
                                    <ETableRow>
                                       <ETableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                          <CircularProgress color="primary" />
                                       </ETableCell>
                                    </ETableRow>
                                 </ETableBody>
                              ) : (<>
                                 <ETableBody>
                                    <ETableRow>
                                       <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                          <SearchNotFound />
                                       </ETableCell>
                                    </ETableRow>
                                 </ETableBody>
                              </>)
                              }
                           </>)
                           }

                        </ETable>
                     </ETableContainer>
                  </EScrollbar>
                  {holiday.holidayListData?.count && holiday.holidayListData?.count > DEFAULT_ROWS_PERPAGE ?
                     <ETablePagination
                        rowsPerPageOptions={ROW_SIZE_PER_PAGE}
                        component="div"
                        count={holiday.holidayListData?.count ? holiday.holidayListData?.count : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                     />
                     : <></>}
               </Grid>
            </EBoxPage>

         </Page>

         {/* Below is the Modal for Edit holiday */}
         {IsHolidayEdit.isOpen &&
            <EModal
               open={IsHolidayEdit.isOpen}
               close={() => closeEdit()} headervalue={IsHolidayEdit.row ? "Update Holiday" : "Add Holiday"}>
               <HolidaySettingForm
                  obj={IsHolidayEdit.row}
                  close={() => closeEdit()}
                  auth={auth}
                  holiday={holiday}
                  getFilterData={() => getFilterData(SearchParameters)}
               />
            </EModal>
         }

         {/* Below is the Modal for Delete holiday */}
         {IsHolidayDelete?.isOpen &&
            <EModal open={IsHolidayDelete?.isOpen} close={() => closeDelete()} headervalue='Delete Holiday: Confirmation'>
               <ETypography className='font-size-18px'>
                  Are you sure you want to delete holiday on
                  <span className='theme-main-text-color-bold text-decoration'>
                     {"     "}
                     {IsHolidayDelete?.row?.from_date !== IsHolidayDelete?.row?.to_date ? `${moment(IsHolidayDelete?.row?.from_date).format('DD/MM/YYYY')} to ${moment(IsHolidayDelete?.row?.to_date).format('DD/MM/YYYY')} ` :
                        moment(IsHolidayDelete?.row?.from_date).format('DD/MM/YYYY')
                     }
                     <span className='font-style-italic'>
                        {IsHolidayDelete?.row?.holiday_name ? ' - ' + IsHolidayDelete?.row?.holiday_name : ''}
                     </span>

                  </span>
                  {"     "} ?
               </ETypography>

               <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
                  <EButtonOutlined size="large" variant="outlined" onClick={() => closeDelete()} color="secondary" > No </EButtonOutlined>
                  <ELoadingButton
                     size="large"
                     type="submit"
                     variant="contained"
                     onClick={() => {
                        if (IsHolidayDelete?.row?.id) {
                           dispatch(UpdateHoliday(auth.authtoken, { id: IsHolidayDelete?.row?.id }, DELETE, getFilterData(SearchParameters)));
                           closeDelete();
                        }
                     }}
                     loading={holiday?.holidayLoading}
                  >
                     Yes
                  </ELoadingButton>
               </Stack>
            </EModal>
         }
      </>
   )
};

export default Holiday;
