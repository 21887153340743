
import { GET_BIRTHDAY_ANNIVERSARY_FAILURE, GET_BIRTHDAY_ANNIVERSARY_SUCCESS, GET_BIRTHDAY_ANNIVERSARY_LOADING} from '../constants';

const INITIAL_STATE = {
    birthdayAnniversaryData: {},
    birthdayAnniversarySuccess: false,
    birthdayAnniversaryLoading: false,
    birthdayAnniversaryMessage: null,
};

const HRDashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_BIRTHDAY_ANNIVERSARY_LOADING:
            return {
                ...state,
                birthdayAnniversaryLoading: true,
                birthdayAnniversaryData: {}
            };

        case GET_BIRTHDAY_ANNIVERSARY_SUCCESS:
            return {
                ...state,
                birthdayAnniversaryLoading: false,
                birthdayAnniversarySuccess: true,
                birthdayAnniversaryData: action.payload.data,
                birthdayAnniversaryMessage: action.payload.message,
            };

        case GET_BIRTHDAY_ANNIVERSARY_FAILURE:
            return {
                ...state,
                birthdayAnniversaryLoading: false,
                birthdayAnniversarySuccess: false,
                birthdayAnniversaryMessage: action.payload.message,
            };

        default:
            return state;
    }
};

export default HRDashboardReducer;
