import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

EChipGreen.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  sx: PropTypes.object,
  classname: PropTypes.string
};

// Green Chip for regularization request page
export default function EChipGreen({ label, color = 'inherit', bgcolor = 'inherit',width=null,classname=' ',fontSize=null, ...sx }) {
  const uppercase_label = label ? label?.toUpperCase() : '';
  return (
    <Chip
      label={label}
      className="active-green-chip"

      sx={{
        ...sx,
        fontSize:fontSize?fontSize:'0.8125rem',
        maxWidth: '10rem',
        width: width?width:'min-content',
        height: 'fit-content !important',
        borderRadius: "16px !important",
        fontWeight: 700,
        padding: '4px 4px',
        '& .MuiChip-label': {
          whiteSpace: 'break-spaces !important',
        },
      }}
    />
  );
}


