import React, { useEffect, useState, useMemo } from 'react';
import {
    Grid,
} from '@mui/material';
import moment from 'moment';
import ArrowBackIosIcon from '@iconify/icons-material-symbols/chevron-left-rounded';
import ArrowForwardIosIcon from '@iconify/icons-material-symbols/chevron-right-rounded';
import { EIconButton } from '../../BaseComponents/EButtons';
import { EIconStaticColor } from '../../BaseComponents/EIcon';
import { EChipStyled } from '../../BaseComponents/EChip';
import { ETypography } from '../../BaseComponents/ETypography';
import { StartDateForReport } from '../../constants';

/**
 * [2022-09-21]
 * Created by:- Aanchal Sahu
 * Description:- designed this component according to the new theme.
 **/



/**
 * [2023-03-30]
 * Changed by:- Purva Sharma
 * Description:- {
                *  added todayDay: for current date for default view in attendance report log by_date 
                *  removed monthDate condition for day and month scroller
                }
 **/
export default function DateMonthScroller(props) {
    const { isDate, start_date, end_date, date: monthDate, onClick, } = props;
    const fromDate = moment(start_date);
    const curDate = moment(monthDate);
    const toDate = moment(end_date);
    const fromMonth = moment(monthDate).startOf('month');
    const toMonth = moment().startOf('month');
    const todayDay = monthDate ? monthDate : new Date()

    let leftMonthDate = null;
    let midMonthDate = null;
    let rightMonthDate = null;
    let selectedDateMonth = monthDate;
    if (isDate) {
        leftMonthDate = curDate.diff(fromDate, "days") ? moment(monthDate).subtract(1, 'day').format('YYYY-MM-DD') : null;
        midMonthDate = moment(todayDay).format('YYYY-MM-DD');
        rightMonthDate = toDate.diff(curDate, "days") ? moment(monthDate).add(1, 'day').format('YYYY-MM-DD') : null;
    }
    if (!isDate) {
        leftMonthDate = moment(monthDate).subtract(1, 'months').startOf('month').format('MMM YYYY');
        midMonthDate = moment(monthDate).startOf('month').format('MMM YYYY');
        rightMonthDate = null
    }
    const [dateScroll, setDateScroll] = useState({
        leftMonthDate: leftMonthDate,
        midMonthDate: midMonthDate,
        rightMonthDate: rightMonthDate,
        selectedDateMonth: selectedDateMonth,
    });

    useEffect(() => {
        setDateScroll({
            leftMonthDate: leftMonthDate,
            midMonthDate: midMonthDate,
            rightMonthDate: rightMonthDate,
            selectedDateMonth: selectedDateMonth,
        });
    }, [midMonthDate, isDate, end_date, start_date]);

    const leftDateHandler = () => {
        const { selectedDateMonth } = dateScroll;
        if (isDate) {
            let nextSelectedDateMonth = moment(selectedDateMonth).subtract(1, 'day').toDate();
            const curSelDate = moment(nextSelectedDateMonth);
            onClick(nextSelectedDateMonth);
            return setDateScroll({
                selectedDateMonth: nextSelectedDateMonth,
                midMonthDate: moment(nextSelectedDateMonth).format('YYYY-MM-DD'),
                leftMonthDate: curSelDate.diff(fromDate, "days") ? moment(nextSelectedDateMonth).subtract(1, 'day').format('YYYY-MM-DD') : moment(nextSelectedDateMonth).subtract(1, 'day').format('YYYY-MM-DD'),
                rightMonthDate: toDate.diff(curSelDate, "days") ? moment(nextSelectedDateMonth).add(1, 'day').format('YYYY-MM-DD') : null,
            });
        }
        let nextSelectedDateMonth = moment(selectedDateMonth).subtract(1, 'month');
        onClick(nextSelectedDateMonth.toDate());
        setDateScroll({
            selectedDateMonth: nextSelectedDateMonth.toDate(),
            midMonthDate: nextSelectedDateMonth.format('MMM YYYY'),
            leftMonthDate: fromMonth.isBefore(nextSelectedDateMonth) ? nextSelectedDateMonth.subtract(1, 'month').format('MMM YYYY') : null,
            rightMonthDate: nextSelectedDateMonth.add(1, 'month').format('MMM YYYY'),
        })
    }

    const rightDateHandler = () => {
        const { selectedDateMonth } = dateScroll;
        if (isDate) {
            let nextSelectedDateMonth = moment(selectedDateMonth).add(1, 'day').toDate();
            const curSelDate = moment(nextSelectedDateMonth);
            onClick(nextSelectedDateMonth);
            return setDateScroll({
                selectedDateMonth: nextSelectedDateMonth,
                midMonthDate: moment(nextSelectedDateMonth).format('YYYY-MM-DD'),
                rightMonthDate: toDate.diff(curSelDate, "days") ? moment(nextSelectedDateMonth).add(1, 'day').format('YYYY-MM-DD') : null,
                leftMonthDate: curSelDate.diff(fromDate, "days") ? moment(nextSelectedDateMonth).subtract(1, 'day').format('YYYY-MM-DD') : null,
            });
        }
        let nextSelectedDateMonth = moment(selectedDateMonth).add(1, 'month').startOf('month');
        onClick(nextSelectedDateMonth.toDate());
        setDateScroll({
            selectedDateMonth: nextSelectedDateMonth.toDate(),
            midMonthDate: nextSelectedDateMonth.format('MMM YYYY'),
            rightMonthDate: toMonth.isAfter(nextSelectedDateMonth) ? nextSelectedDateMonth.add(1, 'month').format('MMM YYYY') : null,
            leftMonthDate: nextSelectedDateMonth.subtract(1, 'month').format('MMM YYYY'),
        })
    }


    const disablePrevButton = useMemo(() => {
        /**
         * isDate true means report type is "By Date" THEN
         * disable the Previous button if dateScroll.leftMonth is null
         */
        if (isDate) {
            return !dateScroll.leftMonthDate
        }
        /**
         * else isDate is false means report type is "By Name" THEN
         * disable the Previous button if dateScroll?.midMonthDate (i.e. Selected Month and Year) is equal to StartDateForReport
         */
        return moment(StartDateForReport).format('YYYY-MM') == moment(new Date(dateScroll?.midMonthDate)).format('YYYY-MM')
    }, [dateScroll.leftMonthDate, isDate, start_date, dateScroll?.midMonthDate])

    const disableNextButton = useMemo(() => {
        /**
         * isDate true means report type is "By Date" THEN
         * disable the Next button if dateScroll.rightMonthDate is null OR Current Date is less than or equal to dateScroll?.midMonthDate (i.e. Selected Date)
         */
        if (isDate) {
            return !dateScroll?.rightMonthDate || moment().format('YYYY-MM-DD') <= moment(new Date(dateScroll?.midMonthDate)).format('YYYY-MM-DD')
        }
        /**
         * else isDate is false means report type is "By Name" THEN
         * disable the Next button if dateScroll?.midMonthDate (i.e. Selected Month and Year) is less than or equal to Current Date
         */
        return moment().format('YYYY-MM') <= moment(new Date(dateScroll?.midMonthDate)).format('YYYY-MM')
    }, [dateScroll.rightMonthDate, isDate, end_date, dateScroll?.midMonthDate])

    return (
        <>
            <Grid container alignItems="center" className="justify-content-center align-center minHeight-70px" spacing={1} py={1} >
                {/**
                 * Previous Date
                 */}
                <Grid item>
                    <ETypography
                        className={disablePrevButton && 'disabled-text-color'}>
                        {isDate && dateScroll.leftMonthDate ? moment(dateScroll.leftMonthDate).format('DD/MM/YYYY') :
                            isDate && !dateScroll.leftMonthDate ?
                                moment(new Date(dateScroll?.midMonthDate)).subtract(1, 'days').format('DD/MM/YYYY') :
                                moment(dateScroll?.midMonthDate).subtract(1, 'months').startOf('month').format('MMM YYYY')}
                    </ETypography>
                </Grid>
                {/**
                 * Previous Arrow Button
                 */}
                <Grid item>
                    <EIconButton
                        disabled={disablePrevButton}
                        onClick={leftDateHandler}
                    >
                        <EIconStaticColor icon={ArrowBackIosIcon} />
                    </EIconButton>
                </Grid>
                {/**
                 * Selected Date
                 */}
                <Grid item>
                    <EChipStyled
                        label={isDate && moment(new Date(dateScroll?.midMonthDate)).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD') ?
                            'Today' : isDate && dateScroll?.midMonthDate ?
                                moment(dateScroll?.midMonthDate).format('DD/MM/YYYY')
                                : moment(dateScroll?.midMonthDate).format('MMM YYYY')
                        }
                        className='date-chip-class minHeight-40px'
                    />
                </Grid>
                {/**
                 * Next Arrow Button
                 */}
                <Grid item>
                    <EIconButton
                        disabled={disableNextButton}
                        onClick={rightDateHandler}
                    >
                        <EIconStaticColor icon={ArrowForwardIosIcon} height={30} width={30} />
                    </EIconButton>
                </Grid>
                {/**
                 * Next Date
                 */}
                <Grid item>
                    <ETypography
                        className={disableNextButton && 'disabled-text-color'}
                    >
                        {isDate && dateScroll.rightMonthDate ? moment(dateScroll.rightMonthDate).format('DD/MM/YYYY') :
                            isDate && !dateScroll.rightMonthDate ?
                                moment(dateScroll?.midMonthDate).add(1, 'days').format('DD/MM/YYYY') :
                                moment(dateScroll?.midMonthDate).add(1, 'months').startOf('month').format('MMM YYYY')}
                    </ETypography>
                </Grid>
            </Grid>
        </>
    );
}
