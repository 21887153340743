import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { changePrimarySuperAdminValidation } from '../../utils/validation';
import { ChangePrimarySuperAdminAction } from '../../action/EmployeeAuthAction';
import { ACTIVE_USER, SUPER_ADMIN } from '../../constants';
import { Grid, Stack } from '@mui/material';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextField } from '../../BaseComponents/ETextField';
import {  EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EmployeeListData } from '../../action/EmployeeAction';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { EBox } from '../../BaseComponents/EBox';
import { ECardBasic } from '../../BaseComponents/ECard';

/**
 * [2023-4-5]
 * Created By: Aanchal Sahu
 * Description: This is a form to change primary super admin by primary super admin
 **/

function ChangePrimarySuperAdmin(props) {
    const dispatch = useDispatch();
    const { close, isEdit, search,page, empAuth, auth, loadingButton } = props
    const employee = useSelector(state => state.EmployeeReducer)

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            talent_name: isEdit ? { id: isEdit?.id, label: isEdit?.fullname, email: isEdit?.email, mobile: isEdit?.mobile } : null,
            employee_id:''
        },
        validationSchema: changePrimarySuperAdminValidation,
        onSubmit: (data) => {
            data = { ...data,  search: { ...search }}
            dispatch(ChangePrimarySuperAdminAction(auth.authtoken, data, close))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, } = formik;

    useEffect(() => {
        if (auth?.authtoken) {
            /**
             * INFO: only fetch SUPER_ADMIN user
             */
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true, role: SUPER_ADMIN }))
        }
    }, [empAuth?.empAuthSuccess])

    const HandleClose = () => {
        close()
    }

    //INFO: This method is called when the employee is selected
    const selectUser = (user) => {
        setFieldValue('talent_name', user ? user : null)
        setFieldValue('employee_id', user?.id ? user?.id : null)
    }

    const EmployeeList = isEdit ? [{ id: isEdit?.id, label: isEdit?.fullname, email: isEdit?.email, mobile: isEdit?.mobile }] :
        employee && employee?.employeeData?.rows?.length > 0 ? employee?.employeeData?.rows?.filter(item => item.is_active==1 && item.id!=auth?.authData?.id) : []

    return (<>
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ECardBasic className="Bg-card4 shadow-none border-none p-10px mb-0px">
                            Kindly confirm whether you intend to transfer your current seniority privileges
                            {formik?.values?.talent_name ? ' to ' + formik?.values?.talent_name?.label : ''}.
                            Note that upon transfer the seniority privileges cannot be reversed.
                        </ECardBasic>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true}>
                            <EAutocomplete
                                name="Employee Name"
                                fullWidth
                                forcePopupIcon={formik?.values?.talent_name ? false : true}
                                disabled={isEdit ? true : false}
                                {...getFieldProps('talent_name')}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                options={EmployeeList && EmployeeList?.length > 0 ? EmployeeList : []}
                                renderOption={(props, option) => (            //renderOption is for managing unique keys in the dropdown list
                                    <EBox component="li" {...props} key={option.id}>
                                        {option.label}
                                    </EBox>
                                )}
                                onChange={(e, value) => { selectUser(value);}}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='talent_name'
                                        placeholder="Select Employee"
                                        fullWidth
                                        error={Boolean(touched.talent_name && errors.talent_name)}
                                        helperText={touched.talent_name && errors.talent_name}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item xs={12} xl={12} className='modal1-buttons-stick-bottom' >
                        <Stack direction="row" spacing={2} justifyContent='flex-end'>
                            <EButtonOutlined color="secondary" onClick={() => HandleClose()} variant="outlined" size="large"> Cancel </EButtonOutlined> {/* Cancel button */}
                            <ELoadingButton type="submit" variant="contained" size="large"  loading={loadingButton}> Update  </ELoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    </>
    );
}

export default ChangePrimarySuperAdmin;
