import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PayrollList } from "../../action/PayrollAction";
import { EBoxPage } from "../../BaseComponents/EBox";
import EHeaderBreadcrumbs from "../../BaseComponents/EBreadcrum";
import {  EButtonOutlined, EButtonOutlinedIcon } from "../../BaseComponents/EButtons";
import { EDivider } from "../../BaseComponents/EDivider";
import EHidden from "../../BaseComponents/EHidden";
import { EIcon } from "../../BaseComponents/EIcon";
import Page from "../../BaseComponents/EPage";
import { ETypographyPageHeading } from "../../BaseComponents/ETypography";
import { DEFAULT_ROWS_PERPAGE } from "../../constants";
import AttendancePayrollMonthList from "../../PageComponents/Attendance/AttendancePayrollMonthList";
import ForwardArrow from "@iconify/icons-material-symbols/chevron-left-rounded";

const AttendanceList = (props) => {
    const { auth, is_payroll } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);
    const payrollList = useSelector(state => state.PayrollReducer);
    const [searchParams, setSearchParams] = useState({});

    useEffect(() => {
        if (auth?.authtoken) {
            const searchVar = { is_payroll: is_payroll }
            setSearchParams(searchVar)
            callApi(searchVar, page, rowsPerPage)
        }
    }, [auth?.authtoken, is_payroll])



    const handleChangePage = (event, newPage) => {
        callApi(searchParams, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParams, 0, rowPerPageCount);
        setPage(0);
    };

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        dispatch(PayrollList(auth.authtoken, fnParams, fnPage, fnRowPerPage));
    }

    return (
        <Page title={!is_payroll ? 'Attendance List' : 'Payroll List'} >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    {is_payroll &&
                        <EHeaderBreadcrumbs
                            heading={'Payroll'}
                            links={[
                                { name: 'Dashboard', href: '/' },
                                {
                                    name: 'Payroll',
                                }
                            ]}
                        />}
                    {!is_payroll &&
                        <EHeaderBreadcrumbs
                            heading={'Attendance List'}
                            links={[
                                { name: 'Dashboard', href: '/' },
                                { name: 'Attendance', href: '' },
                                {
                                    name: 'Attendance List',
                                }
                            ]}
                        />}

                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate(-1)} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                className='pb-2rem margin-top-20px '
            >
                <Grid item>
                    <ETypographyPageHeading variant='h5'>
                        {!is_payroll && 'Attendance List'}
                        {is_payroll && 'Payroll List'}
                    </ETypographyPageHeading>
                </Grid>
                <EHidden width="mdUp">
                    <Grid item>
                        <EButtonOutlinedIcon
                            variant="outlined"
                            onClick={() => navigate(-1)}
                            className="button-left-margin"
                        >
                            {" "}
                            <EIcon icon={ForwardArrow} className="height-width-22px" />{" "}
                        </EButtonOutlinedIcon>
                    </Grid>
                </EHidden>
            </Grid>

            <EHidden width="mdDown">
                <EDivider className='margin-top-05' />
            </EHidden>

            {/* <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >


                <Grid item > */}
                <EBoxPage>
                    {!payrollList?.payrollListLoading &&
                        <AttendancePayrollMonthList
                            is_payroll={is_payroll}
                            payrollListData={payrollList.payrollListData}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            page={page}
                            rowsPerPage={rowsPerPage}
                        />
                    }

                {/* </Grid>
            </Grid> */}
            </EBoxPage>
        </Page>
    )

};

export default AttendanceList;
