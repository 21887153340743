import { SALARY_APPRAISAL_LOADING, SALARY_APPRAISAL_LIST_SUCCESS, SALARY_APPRAISAL_SAVE_SUCCESS, SALARY_APPRAISAL_FAILURE } from '../constants';

const INITIAL_VALUE = {
    salaryAppraisalData: {},
    salaryAppraisalSuccess: false,
    salaryAppraisalLoading: false,
    salaryAppraisalMessage: null    
};

const TalentSalaryAppraisalReducer = (state = INITIAL_VALUE, action) => {
    switch (action.type) {    
        case SALARY_APPRAISAL_LOADING:
            return {
                ...state,
                salaryAppraisalLoading: true,
                salaryAppraisalSuccess: false,
                salaryAppraisalMessage: null,
            };
        case SALARY_APPRAISAL_LIST_SUCCESS:
            return {
                ...state,
                salaryAppraisalLoading: false,
                salaryAppraisalSuccess: true,
                salaryAppraisalData:  action.payload.data,
                salaryAppraisalMessage: action.payload.message,
            };

        case SALARY_APPRAISAL_SAVE_SUCCESS:
            return {
                ...state,
                salaryAppraisalLoading: false,
                salaryAppraisalSuccess: true,
                salaryAppraisalMessage: action.payload.message,
            };

        case SALARY_APPRAISAL_FAILURE:
            return {
                ...state,
                salaryAppraisalLoading: false,
                salaryAppraisalSuccess: false,
                salaryAppraisalMessage: action.payload.message,
            };

        default:
            return state;
    }
};

export default TalentSalaryAppraisalReducer;
