import {
  CUSTOM_USER_ROLE_LIST_LOADING,
  CUSTOM_USER_ROLE_LIST_SUCCESS,
  CUSTOM_USER_ROLE_LIST_FAILURE,

  CUSTOM_USER_ROLE_DATA_LOADING,
  CUSTOM_USER_ROLE_DATA_SUCCESS,
  CUSTOM_USER_ROLE_DATA_FAILURE,

  CUSTOM_USER_ROLE_LOADING,
  CUSTOM_USER_ROLE_SUCCESS,
  CUSTOM_USER_ROLE_FAILURE,

  PERMISSION_LIST_LOADING,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAILURE,

  SAVE_CUSTOM_USER_ROLE_LOADING,
  SAVE_CUSTOM_USER_ROLE_SUCCESS,
  SAVE_CUSTOM_USER_ROLE_FAILURE,

} from '../constants';


const INITIAL_STATE = {

  /**
   * List of custom roles to display in list
   */
  customRoleList: [],
  customRoleListSuccess: false,
  customRoleListLoading: false,
  customRoleListMessage: null,

  /**
   * List of custom roles to display in dropdowns
   */
  customRoleData: [],
  customRoleDataSuccess: false,
  customRoleDataLoading: false,
  customRoleDataMessage: null,

  /**
   * Single Custom Role for view and edit purpose
   */
  customRole: [],
  customRoleSuccess: false,
  customRoleLoading: false,
  customRoleMessage: null,

  /**
   * List of base permissions
   */
  permissionList: [],
  permissionListSuccess: false,
  permissionListLoading: false,
  permissionListMessage: null,

  /**
   * To Save/Update the Custom User Role
   */
  saveCustomUserRoleSuccess: false,
  saveCustomUserRoleLoading: false,
  saveCustomUserRoleMessage: null,

};

const CustomUserRole = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    // FOR CUSTOM ROLE LIST 
    case CUSTOM_USER_ROLE_LIST_LOADING:
      return {
        ...state,
        customRoleList: [],
        customRoleListLoading: true,
      };

    case CUSTOM_USER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        customRoleListLoading: false,
        customRoleListSuccess: true,
        customRoleList: action.payload.data,
        customRoleListMessage: action.payload?.message,
      };

    case CUSTOM_USER_ROLE_LIST_FAILURE:
      return {
        ...state,
        customRoleListLoading: false,
        customRoleListSuccess: false,
        customRoleListMessage: action.payload?.message,
        customRoleList: [],
      };

    // FOR CUSTOM ROLE DATA 
    case CUSTOM_USER_ROLE_DATA_LOADING:
      return {
        ...state,
        customRoleData: [],
        customRoleDataLoading: true,
      };

    case CUSTOM_USER_ROLE_DATA_SUCCESS:
      return {
        ...state,
        customRoleDataLoading: false,
        customRoleDataSuccess: true,
        customRoleData: action.payload.data,
        customRoleDataMessage: action.payload?.message,
      };

    case CUSTOM_USER_ROLE_DATA_FAILURE:
      return {
        ...state,
        customRoleDataLoading: false,
        customRoleDataSuccess: false,
        customRoleDataMessage: action.payload?.message,
        customRoleData: [],
      };

    // FOR SINGLE CUSTOM ROLE
    case CUSTOM_USER_ROLE_LOADING:
      return {
        ...state,
        customRole: {},
        customRoleLoading: true,
      };

    case CUSTOM_USER_ROLE_SUCCESS:
      return {
        ...state,
        customRoleLoading: false,
        customRoleSuccess: true,
        customRole: action.payload.data,
        customRoleMessage: action.payload?.message,
      };

    case CUSTOM_USER_ROLE_FAILURE:
      return {
        ...state,
        customRoleLoading: false,
        customRoleSuccess: false,
        customRoleMessage: action.payload?.message,
        customRole: {},
      };

    // FOR PERMISSION LIST 
    case PERMISSION_LIST_LOADING:
      return {
        ...state,
        permissionList: [],
        permissionListLoading: true,
      };

    case PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        permissionListLoading: false,
        permissionListSuccess: true,
        permissionList: action.payload.data,
        permissionListMessage: action.payload?.message,
      };

    case PERMISSION_LIST_FAILURE:
      return {
        ...state,
        permissionListLoading: false,
        permissionListSuccess: false,
        permissionListMessage: action.payload?.message,
        permissionList: [],
      };

    // FOR SAVE CUSTOM USER ROLE 
    case SAVE_CUSTOM_USER_ROLE_LOADING:
      return {
        ...state,
        saveCustomUserRoleLoading: true,
      };

    case SAVE_CUSTOM_USER_ROLE_SUCCESS:
      return {
        ...state,
        saveCustomUserRoleLoading: false,
        saveCustomUserRoleSuccess: true,
        saveCustomUserRoleMessage: action.payload?.message,
      };

    case SAVE_CUSTOM_USER_ROLE_FAILURE:
      return {
        ...state,
        saveCustomUserRoleLoading: false,
        saveCustomUserRoleSuccess: false,
        saveCustomUserRoleMessage: action.payload?.message,
      };
    default:
      return state;
  }
};

export default CustomUserRole;
