import { STATE_LIST_FAILURE, STATE_LIST_SUCCESS, STATE_LIST_LOADING } from '../constants';

const INITIAL_STATE = {
    stateListData: {},
    stateListSuccess: false,
    stateListLoading: false,
    stateListMessage: null,

};

const StateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {    

        case STATE_LIST_LOADING:
            return {
                stateListLoading: true,
                stateListData: {}
            };

        case STATE_LIST_SUCCESS:
            return {
                stateListLoading: false,
                stateListSuccess: true,
                stateListData: action.payload.data,
                stateListMessage: action.payload.message,
            };

        case STATE_LIST_FAILURE:
            return {
                stateListLoading: false,
                stateListSuccess: false,
                stateListMessage: action.payload.message,
                stateListData: {}
            };

        default:
            return state;
    }
};

export default StateReducer;
