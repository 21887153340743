import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickersV6/LocalizationProvider';
import { useState } from 'react';
import dayjs from "dayjs";
import { Box, Grid, ListItem, ListItemText, TextField} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickersV6/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickersV6/PickersDay";
import {DateCalendar} from "@mui/x-date-pickersV6"
import { DayCalendarSkeleton } from "@mui/x-date-pickersV6";
import { EBox } from '../../BaseComponents/EBox';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { useTheme } from '@emotion/react';
import EModal, { EModalOnBoarding } from '../../BaseComponents/EModal';
import { CancelModal } from './ModalList';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarDashboard } from '../../action/CalendarDashboard';
import { makeStyles } from '@mui/styles';

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      );

      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException("aborted", "AbortError"));
    };
  });
}

const initialValue = dayjs(new Date());

function ServerDay(props) {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    data = [],
    open,
    setOpen,
    ...other
  } = props;

  const PUBLIC = data && data?.filter(
    (i) => dayjs(i.date).isSame(dayjs(day)) && i.type === "PUBLIC"
  );
  const OFF = data && data?.filter(
    (i) => dayjs(i.date).isSame(dayjs(day)) && i.type === "OFF"

  );

  const BIRTHDAY = data && data?.filter(
    (i) =>
      dayjs(i.date).format("DD-MM") === dayjs(day).format("DD-MM") &&
      i.type === "BIRTHDAY"
  );


  const ANNIVERSARY = data && data?.filter(
    (i) =>
      dayjs(i.date).format("DD-MM") === dayjs(day).format("DD-MM") &&
      i.type === "ANNIVERSARY"
  );  

  const theme = useTheme()

  return (
    <Box
      sx={{
        width: "54px",
        height:'42px',
        // background:theme.palette.background.tableHeader,
        // borderRadius:'70%',
        m: "1px",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column'

      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        onClick={()=>setOpen({
          open:true,
          publicdata: PUBLIC,
          companyData:OFF,
          bdayData:BIRTHDAY,
          anniversaryData:ANNIVERSARY,
          date: dayjs(day).format("DD/MM/YYYY"),
          type:"Modal Heading"})}
        
        day={day}
      />
      <div style={{marginBottom:'2px'}}></div>
      <EBox sx={{
        display:'flex',
        gap: '3px',
      }}>

        {OFF.length > 0 && !outsideCurrentMonth && (
          OFF.map((item, index)=>{
            return(

              <ETooltip key={index} title={'Company Off'} arrow>
              <span
                onClick={() => null}
                style={{
                  cursor: "pointer",
                  display: 'inline-block',
                  width: '6px',
                  height: '6px',
                  borderRadius: '50%',
                  backgroundColor: 'gray'
                }}
              >
                
              </span>
            </ETooltip>
            )
          })

        )}

        {(BIRTHDAY.length > 0 || ANNIVERSARY.length > 0 || PUBLIC.length>0 ) && !outsideCurrentMonth && (
          <ETooltip title={'Event'} arrow>
            <span
              onClick={() => null}
              style={{
                cursor: "pointer",
                display: 'inline-block',
                width: '6px',
                height: '6px',
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main
              }}
            >
              
            </span>
          </ETooltip>
        )}
      </EBox>
    </Box>
  );
}


const Calendar = ({token}) => {
    const requestAbortController = React.useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const data2 = useSelector(state => state.CalendarDashboardReducer) || []

    

    const fetchHighlightedDays = (date) => {
      const controller = new AbortController();
      fakeFetch(date, {
        signal: controller.signal
      })
        .then(({ daysToHighlight }) => {
          setIsLoading(false);
        })
        .catch((error) => {
          // ignore the error if it's caused by `controller.abort`
          if (error.name !== "AbortError") {
            throw error;
          }
        });
  
      requestAbortController.current = controller;
    };

    React.useEffect(() => {
      fetchHighlightedDays(initialValue);
      // abort request on unmount
      return () => requestAbortController.current?.abort();
    }, []);

    const handleMonthChange = (date) => {
      if (requestAbortController.current) {
        // make sure that you are aborting useless requests
        // because it is possible to switch between months pretty quickly
        requestAbortController.current.abort();
      }
  
      setIsLoading(true);
      // setHighlightedDays([]);
      fetchHighlightedDays(date);

      dispatch(getCalendarDashboard(token, date))
    };



    const [open, setOpen] = useState({
      open:false,
      publicdata: null,
      companyData:null,
      bdayData:null,
      anniversaryData:null
    })

    const dispatch = useDispatch()

    useEffect(()=>{
      dispatch(getCalendarDashboard(token, new Date()))
    }, [])

    const useStyles = makeStyles  ((theme) => ({
      datePicker: {
        '& .MuiPickersCalendar-root': {
          minWidth: 0,
        },
        '& .MuiPickersBasePicker-pickerView': {
          width: '100% !imporant',
        },
        '.PrivateDatePickerToolbar-penIcon' : {
          visibility: 'hidden'
        }
      },
    }));
    
    const [value, setValue] = useState(new Date())


  return (
    <>
    
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={initialValue}
        loading={isLoading}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton 
          sx={{
            width: "1000px", 
            maxWidth:'1200px',
            "& .MuiDayCalendarSkeleton-daySkeleton": {
              width:'48px !important',
              height:'48px !important'
            },
            "& .MuiDayCalendarSkeleton-week": {
              margin:'0px !important',
              padding:'0px !important'
            }
          }}/>}
        sx={{
          width: "100%", 
          "& .MuiDayCalendar-weekDayLabel": {
            width: "56px",
            fontWeight:'900 !important',
            background:'rgba(180,180,180,0.1)',
            margin:0,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 3px 3px 0px;'
          },
          "& .MuiPickersDay-root":{
            height:'20px',
            width:'20px',
            fontWeight:'500',
            padding:'0px',

          },
          "& .MuiPickersDay-root.Mui-selected" : {
            width: '28px',
            height: '28px',
            borderRadius: '50%',
          },
          "& .MuiPickersDay-today" : {
            width: '28px',
            height: '28px',
            borderRadius: '50%',
          },
          
        }}
        slots={{
          day: (props) => <ServerDay {...props} data={data2?.calendarDashboardData} open={open} setOpen={setOpen}/>
        }}
        slotProps={{
        }}
      />
    </LocalizationProvider>
    {
      (open?.publicdata?.length > 0 || open?.companyData?.length>0 || open?.bdayData?.length>0 || open?.anniversaryData?.length>0)
      &&
      <EModalOnBoarding open={open.open} headervalue={`Event : ${open?.date}`} showDivider={true} footeractions={<CancelModal open={open} setOpen={setOpen}/>} >
      {open?.publicdata?.map((item, index)=>{
        return (
                <EBox key={index}>
                  {
                      index == 0
                    && 
                    <ListItemCalendar key={index}>
                      Public Holiday
                      </ListItemCalendar>
                  }
                  <ListItemTextCalendar label={item?.label} />
                </EBox>
        )
      })} 

    {open?.companyData?.map((item, index)=>{
        return (
                <EBox key={index}>
                  {
                      index == 0
                    && 
                    <ListItemCalendar key={index}>
                      Company Off
                      </ListItemCalendar>
                  }
                  <ListItemTextCalendar label={item?.label} />
                </EBox>
        )
      })} 

    {open?.bdayData?.map((item, index)=>{
        return (
                <EBox key={index}>
                  {
                      index == 0
                    && 
                    <ListItemCalendar key={index}>
                      Birthday
                      </ListItemCalendar>
                  }
                  <ListItemTextCalendar label={item?.label} />
                </EBox>
        )
      })} 

    {open?.anniversaryData?.map((item, index)=>{
        return (

                <EBox key={index}>
                  {
                      index == 0
                    && 
                    <ListItemCalendar key={index}>
                      Anniversary
                    </ListItemCalendar>
                  }
                  <ListItemTextCalendar label={item?.label} />
                </EBox>
        )
      })} 

    </EModalOnBoarding>   
    }
    </>
  )
}

export default Calendar

const ListItemCalendar = ({children}) =>{
  return(
    <ListItem 
    sx={{padding:'0', marginBottom:'5px', fontWeight:'600', marginTop:'0px', fontSize:'18px'}}
  >
          <span 
          // style={{ borderBottom: '1px solid black'}}
          >
        {children}
      </span>
  </ListItem>
  )
}

const ListItemTextCalendar = ({label})=>{
  return(
    <ListItemText 
    primary={label}
    primaryTypographyProps={{fontWeight:'500', fontSize:'14px', lineHeight:''}}
    />
  )
}