//dharam work
import React, { useEffect, useMemo } from 'react'
import { FormControl, FormControlLabel, Grid, Link, useTheme } from '@mui/material';
import AuthPage from "../../BaseComponents/EAuthPage";
import { AuthContentInner, AuthRootStyle } from "../../BaseComponents/EContainer";
import { EBox } from '../../BaseComponents/EBox';

import { EFieldLabel, ETypography, EFormHelperText, EHelperText } from '../../BaseComponents/ETypography';
import { Logo } from '../../layouts/logo';
import { ELink } from '../../BaseComponents/ELink';
import { EIcon } from '../../BaseComponents/EIcon';
import infoIcon from "@iconify/icons-material-symbols/info-outline-rounded";
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { CityList, StateList } from '../../action/StateCityAction';
import { FormikProvider, useFormik, Form } from 'formik';
import { BillingInfoValidation } from '../../utils/validation';
import { completePlanPayment, getSubscriptionPlan, onboardingEmailPlanSuccess, payPlanAmount, skipPayment } from '../../action/OnBoardingAction';
import { useState } from 'react';
import { ETooltip } from '../../BaseComponents/ETooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { GST, PLAN_TIME_FREQUENCY_TYPE, COMPANY_STATE_ID, PLAN_BILLING_CYCLE_TYPE } from '../../constants';
import { verifyToken } from '../../action/AuthAction';
import { PaymentSummary } from '../Subscription/PaymentSummary';




function BillingInfo() {

  // const PLANID = 2
  // const [planId,setPlanId] = useState(PLANID)
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.authReducer);
  const StateListData = useSelector((state) => state.StateReducer.stateListData?.rows);
  const CityListData = useSelector((state) => state.CityReducer.cityListData?.rows);
  const { subscriptionPlanData: subscriptions } = useSelector((state) => state.SubscriptionPlanReducer)

  const theme = useTheme()

  // let notSelectedPlan = subscription?.subscriptionPlanData.filter((i) => i.id !== PLANID)


  // let savedAmount = filteredData?.reduce((val,acc)=>{
  //     return (Number(val.price * TOTAL_TALENT * 12)) - (Number(acc.price * TOTAL_TALENT * 12)) 
  // })

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.token) {
      dispatch(onboardingEmailPlanSuccess(params.token, navigate))
    }
  }, [params.token]);

  useEffect(() => {
    if (auth.authtoken) {
      dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
    }
    dispatch(getSubscriptionPlan())
  }, [auth.authtoken]);


  let selectedPlan = useMemo(() => {
    const array = subscriptions?.length > 0 ? subscriptions?.filter((i) => i?.id == auth.authData?.onboardingCompany?.plan_id) : []
    return array?.length > 0 ? array[0] : {}
  }, [auth, subscriptions])

  // saving object return from useFormik as formik
  const formik = useFormik({
    validateOnChange: true,
    // IniitalValues for input fields
    initialValues: {
      plan: selectedPlan?.id || 0,
      noOfTalent: selectedPlan?.minimum_employee ? selectedPlan?.minimum_employee : 1,
      plan_data: selectedPlan ? selectedPlan : [],
      plan_cost: 0,
      gst_total: 0,
      gst: '',
      igst: '',
      cgst: '',
      sgst: '',
      address: '',
      state: null,
      city: null,
      pin_code: '',
      grand_total: 0,
      minimum_employee: selectedPlan?.minimum_employee ? selectedPlan?.minimum_employee : 1,
      maximum_employee: selectedPlan?.maximum_employee ? selectedPlan?.maximum_employee : 999,
    },
    enableReinitialize: true,
    // Validation for input fields
    validationSchema: BillingInfoValidation,
    // onSubmit action
    onSubmit: (data) => {
      // calling action
      let payload = {
        onboarding_id: auth.authData?.onboardingCompany?.id,
        plan_id: data?.plan_data?.id,
        no_of_talents: data?.noOfTalent,
        address: data?.address,
        state: data?.state?.id,
        city: data?.city?.id,
        pin_code: data?.pin_code,
        // gstin_number: data?.gst,
      }
      if (data?.gst) {
        payload = {
          ...payload,
          gstin_number: data?.gst
        }
      }
      dispatch(payPlanAmount(auth.authtoken, payload, (res) => {
        const _payload = {
          token: res.data.token,
          status: true,
        }
        console.log(res.data);
        // dispatch(completePlanPayment(auth.authtoken, _payload,
        //   (res) => {
        //     dispatch(verifyToken(res.data.data.secretToken, () => {
        //       //   onSuccess(res)
        //       navigate("/payment-success")
        //     }));
        //   },
        //   (error) => {
        //     navigate("/payment-failure")
        //   }
        // ))
      }));
    },
  });

  // Formik Props
  const { getFieldProps, values, setFieldValue, handleSubmit, touched, errors } = formik;

  const calculatePlan = () => {
    let planCost = 0
    let igst = 0
    let cgst = 0
    let sgst = 0
    if (formik?.values?.plan_data?.plan_time_frequency_type === PLAN_TIME_FREQUENCY_TYPE.YEAR) {
      planCost = Number(values?.plan_data?.price) * Number(values?.noOfTalent) * 12
    } else {
      planCost = Number(values?.plan_data?.price) * Number(values?.noOfTalent)
    }
    const amountWithGST = (planCost * 0.01) * GST
    const grandTotal = planCost + amountWithGST
    setFieldValue('gst_total', amountWithGST)
    setFieldValue('plan_cost', planCost)
    setFieldValue('grand_total', grandTotal)
    if (!values?.state?.id) return
    // Check if the company's state is different from the customer's state
    if (COMPANY_STATE_ID !== Number(values?.state?.id)) {
      igst = amountWithGST; // Apply IGST for inter-state transactions
    } else {
      // If it's an intra-state transaction, split the GST amount into CGST and SGST
      cgst = amountWithGST / 2;
      sgst = amountWithGST / 2;
    }
    setFieldValue('igst', igst)
    setFieldValue('cgst', cgst)
    setFieldValue('sgst', sgst)
  }

  const _skipPayment = () => {
    dispatch(skipPayment(auth.authtoken, { onboarding_id: auth.authData?.onboardingCompany?.id }, navigate))
  }

  useEffect(() => {
    calculatePlan()
  }, [formik?.values?.noOfTalent, formik?.values?.state])


  return (
    <FormikProvider value={formik}>
      <Form noValidate>
        <AuthPage title="Billing Information">
          <AuthRootStyle className='pt-0 flex-row-allCenter'>
            {/* 
                        <EBox
                            className='align-center'
                            sx={{ width: '25%', marginLeft: '17%', marginTop: 0, paddingTop: 0 }}
                        >
                            <Link to="/" sx={{ lineHeight: '0' }}  >
                                <Logo />
                            </Link>

                        </EBox> */}

            <EBox className='flex-column'>
              {/*  Payment Information */}

              <EBox
                className='align-center'
                sx={{ width: '25%', marginTop: 0, paddingTop: 0, marginBottom: '16px' }}
              >
                <Link to="/" sx={{ lineHeight: '0' }}  >
                  <Logo />
                </Link>

              </EBox>
              <EBox className='display-flex '>

                <EBox sx={{ width: '530px', marginRight: '16px' }}>
                  <EBox className="p0 grayColor2-color-border bg-authDefaultCard" borderRadius={1.25}>
                    <AuthContentInner className='p-24px'>

                      <ETypography className='font-size-20px theme-color-static bold-400'>
                        Payment Information
                      </ETypography>
                      {/* Subscription Type */}
                      <ETypography className='font-size-14px bold-600' pt={2} pb={0}>
                        Subscription Type
                      </ETypography>
                      <ETypography className='bold-400 greyColor5-color'>
                        Standard Plan
                      </ETypography>

                      <EBox marginTop={2}>
                        {/* Plan */}
                        <EBox className='display-flex align-center'>
                          <ETypography
                            className='font-size-14px bold-600'>
                            Billing Cycle
                          </ETypography>
                        </EBox>

                        {/* Radio Button */}
                        <EBox>
                          <FormControl component="fieldset" className=''>
                            <ERadioGroup  {...getFieldProps('plan')} row={true}>
                              {
                                subscriptions?.length > 0 && subscriptions?.map(subscription => {
                                  if (!subscription.is_paid) return null
                                  return (
                                    <FormControlLabel
                                      key={subscription.id}
                                      value={subscription.id}
                                      control={
                                        <ERadio
                                          onChange={() => {
                                            setFieldValue('plan', subscription?.id)
                                            setFieldValue('plan_data', subscription)
                                            // setPlanId(notSelectedPlan[0].id)
                                            if (values?.noOfTalent < subscription?.minimum_employee) {
                                              setFieldValue('noOfTalent', subscription?.minimum_employee)
                                            }
                                            setFieldValue('minimum_employee', subscription?.minimum_employee)
                                            setFieldValue('maximum_employee', subscription?.maximum_employee)
                                            calculatePlan()
                                          }}
                                          sx={{ py: 0 }}
                                        />
                                      }
                                      label={PLAN_BILLING_CYCLE_TYPE[subscription.plan_time_frequency_type]}
                                      sx={{ '& .MuiTypography-root': { fontSize: '14px !important' } }}
                                      lab
                                    />
                                  )
                                })
                              }
                            </ERadioGroup>
                            {touched.plan && <EFormHelperText sx={{ marginTop: '0px !important', color: '#D14343' }}> {errors.plan}</EFormHelperText>}
                          </FormControl>
                        </EBox>


                        {/* No. of Employee */}
                        <EBox className='flex-column' marginTop={1.75}>
                          <EFieldLabel className='font-size-14px bold-600'>No. of Employee</EFieldLabel>
                          <ETextFieldSearch
                            name='noOfTalent'
                            {...getFieldProps('noOfTalent')}
                            value={values?.noOfTalent}
                            onChange={(e) => {
                              if (Number(e.target.value) > values?.plan_data.maximum_employee) return
                              setFieldValue('noOfTalent', e.target.value)
                            }}
                            type='number'
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: values?.plan_data.minimum_employee, max: values?.plan_data.maximum_employee }}
                            error={Boolean(touched.noOfTalent && errors.noOfTalent)}
                            helperText={touched.noOfTalent && errors.noOfTalent}
                            sx={{
                              width: '45%',
                              '& .MuiFormHelperText-root': {
                                width: '145%',
                                ml: 0,
                              }
                            }}
                          />
                          <EHelperText className={"greyColor5-color"}>
                            {`*Annual billing starts with min. 20 employees `}
                          </EHelperText>
                        </EBox>


                        {/* Billing Address Information */}

                        <ETypography my={2} className='font-size-20px theme-color-static bold-400'>
                          Billing Address Information
                        </ETypography>

                        {/* Company Name */}
                        <ETypography className='font-size-14px bold-600'>
                          Company Name
                        </ETypography>
                        <ETypography className='bold-400 greyColor5-color'>
                          {auth?.authData?.onboardingCompany?.company_name}
                        </ETypography>

                        {/* Address */}
                        <EBox className='flex-column' mt={2}>
                          <EFieldLabel className='font-size-14px bold-600'>Address</EFieldLabel>
                          <ETextFieldSearch
                            fullWidth
                            onChange={(e) => {
                              setFieldValue('address', e.target.value)
                            }}
                            error={Boolean(touched.address && errors.address)}
                            helperText={touched.address && errors.address}
                            sx={{
                              pt: 0.5,
                              '& .MuiFormHelperText-root': {
                                ml: 0,
                              }
                            }}
                          />
                        </EBox>

                        {/* State and City */}
                        {/* <EBox className='flex-row-allCenter py-10px'> */}
                        <Grid container columnSpacing={2} mt={2}>
                          <Grid item md={6} sm={6} xs={6}>
                            <EFieldLabel className='font-size-14px bold-600'>State</EFieldLabel>
                            <EAutocomplete
                              name="state"
                              fullWidth
                              options={
                                StateListData && StateListData?.length > 0 ? StateListData : []
                              }
                              forcePopupIcon={!formik?.values?.state ? true : false}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option.label || ''}
                              {...getFieldProps('state')}
                              onChange={(e, value) => {
                                if (value) {
                                  setFieldValue("state", value ? value : null);
                                  // setFieldValue("city", null);
                                  dispatch(CityList(auth.authtoken, value.id));
                                }
                                setFieldValue('state', value ? value : null);
                                setFieldValue('city', null);
                              }}

                              renderInput={(params) => (
                                <ETextFieldSearch
                                  {...params}
                                  name="state"
                                  // placeholder="Search State"
                                  fullWidth
                                  error={Boolean(touched.state && errors.state)}
                                  helperText={touched.state && errors.state}
                                  sx={{
                                    pt: 0.5,
                                    '& .MuiFormHelperText-root': {
                                      ml: 0,
                                    }
                                  }}
                                />
                              )}
                            />



                          </Grid>

                          <Grid item md={6} sm={6} xs={6}>
                            <EFieldLabel className='font-size-14px bold-600'>City</EFieldLabel>
                            <EAutocomplete
                              name="city"
                              fullWidth
                              options={
                                CityListData && CityListData?.length > 0 ? CityListData : []
                              }
                              forcePopupIcon={!formik?.values?.city ? true : false}
                              {...getFieldProps('city')}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option.label || ''}
                              onChange={(e, value) => {
                                setFieldValue('city', value ? value : null);

                              }}
                              renderInput={(params) => (
                                <ETextFieldSearch
                                  {...params}
                                  name="city"
                                  // placeholder="Select City"
                                  fullWidth
                                  error={Boolean(touched.city && errors.city)}
                                  helperText={touched.city && errors.city}
                                  sx={{
                                    pt: 0.5,
                                    '& .MuiFormHelperText-root': {
                                      ml: 0,
                                    },
                                    '& .MuiInputBase-input ': {
                                      height: 0,
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>

                        {/* Pincode */}
                        <EBox className='flex-column' mt={2}>
                          <EFieldLabel className='font-size-14px bold-600'>Pincode</EFieldLabel>
                          <ETextFieldSearch
                            fullWidth
                            {...getFieldProps('pin_code')}
                            onChange={(e) => setFieldValue('pin_code', e.target.value)}
                            name='pin_code'
                            inputProps={{
                              maxLength: 6
                            }}
                            error={Boolean(touched.pin_code && errors.pin_code)}
                            helperText={touched.pin_code && errors.pin_code}
                            sx={{
                              pt: 0.5,
                              '& .MuiFormHelperText-root': {
                                ml: 0,
                              }
                            }}
                          />
                        </EBox>


                        {/* GSTIN Number */}
                        <EBox className='flex-column' mt={2}>
                          <EFieldLabel className='font-size-14px bold-600'>GSTIN Number</EFieldLabel>
                          <ETextFieldSearch
                            fullWidth
                            {...getFieldProps('gst')}
                            onChange={(e) => setFieldValue('gst', e.target.value)}
                            name='gst'
                            inputProps={{
                              maxLength: 15
                            }}
                            error={Boolean(touched.gst && errors.gst)}
                            helperText={touched.gst && errors.gst}
                            sx={{
                              pt: 0.5,
                              '& .MuiFormHelperText-root': {
                                ml: 0,
                              }
                            }}
                          />
                        </EBox>
                      </EBox>
                      {/* </EBox> */}

                    </AuthContentInner>
                  </EBox>
                </EBox>

                {/*   Payment Summary  */}
                <EBox sx={{ width: '420px' }}>
                  <PaymentSummary
                    data={values}
                    skipButton={

                      <ETypography mt={1} className='text-align display-flex justify-content-center align-center' >
                        <ELink
                          // to={'/login'}
                          onClick={_skipPayment}
                          className="link-color bold-400 font-size-12px"
                        >
                          Skip & Continue
                        </ELink>
                        <ETooltip
                          arrow
                          title={
                            <>
                              <ETypography sx={{ color: theme.palette.text.primaryOpposite, fontSize: 12 }}>
                                As <b> "Startup Plan"</b> user, you will have maximum limit of 10 employees only
                              </ETypography>
                            </>
                          }
                        >
                          <EIcon icon={infoIcon} sx={{ height: '18px', width: '18px', alignItems: 'center', color: '#D1D1D1', marginLeft: 0.5 }} className='cursor-pointer' />
                        </ETooltip>
                      </ETypography>
                    }
                  />

                  <ETypography className='bold-400 font-size-12px text-align p-10px'>
                    On clicking "Skip and continue" button, you’ll downgrade to "Startup Plan" and will be redirected to the Entera homepage.
                  </ETypography>


                </EBox>
              </EBox>
            </EBox>


          </AuthRootStyle>
        </AuthPage>
      </Form>
    </FormikProvider>

  )
}

export default BillingInfo
