import { Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ETextField } from "../../BaseComponents/ETextField";
import { useDispatch } from "react-redux";
import { Form, FormikProvider } from "formik";
import { useFormik } from "formik";
import { updateTalenBankInformation } from "../../action/TalentActions";
import { EditEmpFormBankDetailsValidation } from "../../utils/validation";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import Search from '@iconify/icons-mdi/search';
import { Icon } from '@iconify/react';
import ifsc from 'ifsc-finder';
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";

const EditModalBankDetail = (props) => {
    const { auth, employeeData, closeModalEditBankDetail, TalentSetting } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            bank_detail_check: true,
            bank_ifsc_code: employeeData?.employeeBankInfo?.bank_ifsc_code ? employeeData?.employeeBankInfo?.bank_ifsc_code : '',
            bank_name: employeeData?.employeeBankInfo?.bank_name ? employeeData?.employeeBankInfo?.bank_name : '',
            bank_branch_name: employeeData?.employeeBankInfo?.bank_branch_name ? employeeData?.employeeBankInfo?.bank_branch_name : '',
            bank_account_no: employeeData?.employeeBankInfo?.bank_account_no ? employeeData?.employeeBankInfo?.bank_account_no : '',
            bank_account_holder_name: employeeData?.employeeBankInfo?.bank_account_holder_name ? employeeData?.employeeBankInfo?.bank_account_holder_name : '',
            talent_id: employeeData?.id,
        },
        validationSchema: EditEmpFormBankDetailsValidation(TalentSetting),//(new updated on - 24-4-23)
        onSubmit: (data) => {
            data = { ...data, company_id: auth.authData?.company_id }
            dispatch(updateTalenBankInformation(auth.authtoken, data, navigate))
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;


    function CheckIFSCCode() {
        const ifscCode = getFieldProps('bank_ifsc_code').value.toUpperCase();
        ifscCode && ifsc.get(ifscCode).then(function (res) {
            if (typeof (res) == 'object') {
                setFieldValue("bank_name", res.BANK)
                setFieldValue("bank_branch_name", res.BRANCH)
                setFieldValue("bank_ifsc_code", ifscCode)
            } else {
                setFieldValue("bank_name", "")
                setFieldValue("bank_branch_name", "")
            }
        })
    }

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

                <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={12} px={2} mb={2}>
                        <ELabelInputMainGrid label={"IFSC Code" + (TalentSetting?.field_ifsc_code ? '*' : '')} isModal>
                            <ETextField
                                placeholder="IFSC Code"
                                fullWidth
                                {...getFieldProps('bank_ifsc_code')}
                                error={Boolean(touched.bank_ifsc_code && errors.bank_ifsc_code)}
                                helperText={touched.bank_ifsc_code && errors.bank_ifsc_code}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={() => CheckIFSCCode()}

                                                size="large">
                                                <Icon icon={Search} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} px={2} mb={2}>
                        <ELabelInputMainGrid label={"Bank Name" + (TalentSetting?.field_bank_name ? '*' : '')} isModal>
                            <ETextField
                                fullWidth
                                placeholder="Bank Name"
                                autoComplete="bank_name"
                                {...getFieldProps('bank_name')}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.bank_name && errors.bank_name)}
                                helperText={touched.bank_name && errors.bank_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={6} sm={6} xs={12} px={2} mb={2}>
                        <ELabelInputMainGrid label={"Branch Name" + (TalentSetting?.field_branch_name ? '*' : '')} isModal>
                            <ETextField
                                fullWidth
                                multiline
                                placeholder="Branch Name"
                                autoComplete="bank_branch_name"
                                {...getFieldProps('bank_branch_name')}
                                inputProps={
                                    { readOnly: false, }
                                }
                                error={Boolean(touched.bank_branch_name && errors.bank_branch_name)}
                                helperText={touched.bank_branch_name && errors.bank_branch_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} px={2} mb={2}>
                        <ELabelInputMainGrid label={"Cardholder's Name" + (TalentSetting?.field_account_holder_name ? '*' : '')} isModal >
                            <ETextField
                                fullWidth
                                placeholder="Cardholder's Name"
                                autoComplete="bank_account_holder_name"
                                {...getFieldProps('bank_account_holder_name')}
                                error={Boolean(touched.bank_account_holder_name && errors.bank_account_holder_name)}
                                helperText={touched.bank_account_holder_name && errors.bank_account_holder_name}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} px={2} mb={2}>
                        <ELabelInputMainGrid label={"Account Number" + (TalentSetting?.field_account_number ? '*' : '')} isModal >
                            <ETextField
                                fullWidth
                                multiline
                                autoComplete="bank_account_no"
                                placeholder="Account Number"
                                {...getFieldProps('bank_account_no')}
                                error={Boolean(touched.bank_account_no && errors.bank_account_no)}
                                helperText={touched.bank_account_no && errors.bank_account_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                </Grid>


                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => closeModalEditBankDetail(false)}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                </Stack>

            </Form>
        </FormikProvider>

    )

};

export default EditModalBankDetail;