import React from 'react';
// material
import { CssBaseline } from '@mui/material';
import { ThemeProvider,  StyledEngineProvider } from '@mui/material/styles';
import { createMuiTheme } from '../theme/theme';
import useSettings from '../hooks/useSettings';
import { THEMES } from '../constants';
// ----------------------------------------------------------------------

// ThemeConfig.propTypes = {
//   children: PropTypes.node
// };

function ThemeConfig(props) {
const{ children, token }=props
  const { settings } = useSettings();

  const theme = createMuiTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: token ? settings.theme : THEMES.LIGHT //INFO: Here, we are setting the LIGHT THEME for BEFORE LOGIN and DEFAULT THEME for AFTER LOGIN
  });

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;