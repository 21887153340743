import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import Page from '../../BaseComponents/EPage';
import { ETypographyPageHeading } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import Attendance from '../../PageComponents/Attendance/Attendance';
import LeaveLog from '../../PageComponents/Attendance/LeaveLog';
import EHidden from '../../BaseComponents/EHidden';
import { EBoxPage } from '../../BaseComponents/EBox';
import { ETab, ETabContext, ETabList, ETabPanel } from '../../BaseComponents/ETabs';
import { LEAVE_HISTORY_TAB, LEAVE_REQUEST_TAB, MY_LEAVE_TAB, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, SUPER_ADMIN } from '../../constants';
import { useLocation } from 'react-router-dom';

function Attendance_leaveLogs(props) {

  const { auth } = props;
  const [searchButtonBar, setSearchButtonBar] = useState({});
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const stateParam = searchParams.get('tab');

  // const [tabValue, setTabValue] = useState(location?.state?.fromDashboard ? LEAVE_REQUEST_TAB : MY_LEAVE_TAB);
  
  //get notification url parameter from url
  const initialTabValue = stateParam === LEAVE_REQUEST_TAB ? LEAVE_REQUEST_TAB : MY_LEAVE_TAB;
  const [tabValue, setTabValue] = useState(initialTabValue);

  useEffect(() => {
    if (location.state && location.state.fromDashboard) {
      setTabValue(LEAVE_REQUEST_TAB);
    }
  }, [location.state]);

  //INFO:  handles tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Page title="Leave Log">

      <Grid container display="row" justifyContent="space-between">
        <Grid item sx={{ mb: 2 }}>
          {
            auth?.authData?.role !== ROLE_EMPLOYEE ?
            <EHeaderBreadcrumbs
            heading='Leave Log'
            links={[
              { name: 'Dashboard', href: '/' },
              { name: 'Attendance', href: '/attendance/leave-log' },
              {
                name: 'Leave Log',
                href: '/'
              }
            ]}
          /> :
          <EHeaderBreadcrumbs
          heading='Leave Log'
          links={[
            { name: 'Dashboard', href: '/' },
            {
              name: 'Leave Log',
              href: '/'
            }
          ]}
        />
          }
         
        </Grid>
        <EHidden width="mdDown">
          <Grid item>
            <Attendance status="leave" LeaveSearch={{ ...searchButtonBar }} leaveTabReset={() => { setTabValue(MY_LEAVE_TAB) }} />
          </Grid>
        </EHidden>
      </Grid>
      <Grid container display="row" justifyContent="space-between" className=' margin-top-20px'>
        <Grid item>
          <ETypographyPageHeading variant='h5'>Leave Log</ETypographyPageHeading>
        </Grid>
        <EHidden width="mdUp">
          <Grid item>
            <Attendance status="leave" LeaveSearch={searchButtonBar} />
          </Grid>
        </EHidden>
        <EHidden width="mdDown">
          <Grid lg={12} xs={12} item>
            <EDivider className='margin-top-05' />
          </Grid>
        </EHidden>
      </Grid>

      <EBoxPage >
        {/* The following Tabs will be shown to leave log page- all roles*/}

        <ETabContext value={tabValue} sx={{ paddingTop: '0px' }}>

          <ETabList onChange={handleTabChange} aria-label="lab API tabs example" allowScrollButtonsMobile variant="scrollable"
            alignitems='left!important'>
            <ETab label="My Leaves" value={MY_LEAVE_TAB} />
            <ETab label="Leave Requests" value={LEAVE_REQUEST_TAB}
              // the condition checks is user is a RM or Admin/ Super Admin/ HR
              sx={{ display: (auth?.authData?.isReportingManager || (auth?.authData?.role && [ROLE_ADMIN, ROLE_HR, SUPER_ADMIN].includes(auth?.authData?.role))) ? 'inline-flex' : 'none' }}
            />
            <ETab label="Leave History" value={LEAVE_HISTORY_TAB} />
          </ETabList>

          {/* MY LEAVE TAB */}
          <ETabPanel value={MY_LEAVE_TAB} className=' pl-0px'>
            <LeaveLog setSearchButtonBar={setSearchButtonBar} tabValue={tabValue} />
          </ETabPanel>

          {/* LEAVE REQUEST TAB */}
          <ETabPanel value={LEAVE_REQUEST_TAB} className='pl-0px'>
            <LeaveLog setSearchButtonBar={setSearchButtonBar} tabValue={tabValue} />
          </ETabPanel>

          {/* LEAVE HISTORY TAB */}
          <ETabPanel value={LEAVE_HISTORY_TAB} className='pl-0px'>
            <LeaveLog setSearchButtonBar={setSearchButtonBar} tabValue={tabValue} />
          </ETabPanel>
        </ETabContext>

      </EBoxPage>
    </Page>
  );
}

export default Attendance_leaveLogs
