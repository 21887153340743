import { styled } from "@mui/material";
import { Icon } from '@iconify/react';

export const EIcon = styled(Icon)(({ theme }) => ({
    height: '24px',
    width: '24px',
    color:theme.palette.text.primary,
}));

export const EIconNav = styled(Icon)(({ theme}) => ({
    height: '24px',
    width: '24px',
    ':hover':{
        color:theme.palette.primary.main,
    }  ,
}));

export const EIconInputAdornment = styled(Icon)(({ theme }) => ({
    height: '24px',
    width: '24px'
}));

export const EIconAccordion = styled(Icon)(({ theme }) => ({
    height: '22px',
    width: '22px',
    color: theme.palette.background.activeAccordianButton
}));

export const EIconSmall = styled(Icon)(({ theme }) => ({
    marginRight: '5px',
    verticalAlign: 'middle',
    width: '18px',
    height: '18px',
    color:theme.palette.text.primary
}));

export const EIconStaticColor = styled(Icon)(({ theme }) => ({
    height: '24px',
    width: '24px',
    color:theme.palette.primary.main
}));

export const EIconSideNavbar = styled(Icon)(({ theme }) => ({
    height: '18px',
    width: '18px',
    color:theme.palette.text.primary,
}));

export const EIconHRLeave = styled(Icon)(({ theme }) => ({
    height: '17px',
    width: '17px',
    color:theme.palette.text.primary,

    [theme.breakpoints.up('md')]: {
        width: '20px',
        height: '20px',
    },
    
}));

export const EIconOnboarding = styled(Icon)(({ theme }) => ({
    height: '48px',
    width: '48px',
    color:theme.palette.text.primary,
}));