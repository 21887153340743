import React, { useEffect, useState } from 'react';
import {
    // Card,
    // Button,
    Grid,
    InputAdornment,
    // TextField,
    // InputAdornment,
    // Autocomplete,
    FormGroup,
    FormControlLabel,
    Checkbox,
    // Typography,
    Stack,
} from '@mui/material';


import { useSelector, useDispatch } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { CityList, StateList } from '../../action/StateCityAction';
import PhotoUpload from '../../PageComponents/company/PhotoUpload';
import { UpdateCompanyDetails } from '../../action/CompanyAction';
import { CompanyProfileValidation } from '../../utils/validation';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography } from '../../BaseComponents/ETypography';
import { EDivider } from '../../BaseComponents/EDivider';
import { ECard } from '../../BaseComponents/ECard';

// import { EBox } from '../../BaseComponents/EBox';
import { ETextField } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { ELink } from '../../BaseComponents/ELink';
import { useNavigate } from 'react-router-dom';
import { EmployeeListData } from '../../action/EmployeeAction';
import { DesignationList } from '../../action/DesignationAction';
import { DepartmentList } from '../../action/DepartmentAction';
import { ACTIVE_USER } from '../../constants';

import { COMPANY_TYPE } from '../../constants';
import { ECheckbox } from '../../BaseComponents/ECheckbox';
/** 
 * [2022-10-07] 
 * Changes By :- Gaurav Singh
 * Description :- Changed  'Company Info' to 'Basic Details' and 'Contact Person Details ' to 'Contact Details'  
 **/
function CompanyProfileForm(props) {
    const { auth, settingData, SetIsFormEdit } = props;

    const [imageUrlData, setImageUrlData] = useState(null);
    const [addressCheck, setAddressCheck] = useState(true)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const StateListData = useSelector((state) => state.StateReducer.stateListData?.rows);
    const CityListData = useSelector((state) => state.CityReducer.cityListData?.rows);
    const companyData = useSelector((state) => state.CompanyReducer?.companyData);







    const categoryOptions = Object.keys(COMPANY_TYPE).map((item) => {
        return { id: item, label: COMPANY_TYPE[item] }
    })


    const formik = useFormik({
        initialValues: {
            company_logo: '',
            company_name: settingData?.company_name ? settingData?.company_name : '',
            description: settingData?.company_description ? settingData?.company_description : '',
            email: settingData?.company_email ? settingData?.company_email : '',
            phone_no: settingData?.company_phone ? settingData?.company_phone : '',
            gst: settingData?.company_gst ? settingData?.company_gst : '',
            pan: settingData?.company_pan ? settingData?.company_pan : '',
            pin_code: settingData?.pin_code ? settingData?.pin_code : '',
            address: settingData?.address ? settingData?.address : '',
            state: settingData?.permanentState ? settingData?.permanentState : null,
            city: settingData?.permanentCity ? settingData?.permanentCity : null,
            cr_address_same: settingData?.cr_address_same ? settingData?.cr_address_same : true,
            cr_pin_code: settingData?.cr_pin_code ? settingData?.cr_pin_code : '',
            cr_state: settingData?.crState ? settingData?.crState : null,
            cr_city: settingData?.crCity ? settingData?.crCity : null,
            cr_address: settingData?.cr_address ? settingData?.cr_address : '',
            company_type: settingData?.company_type ? { label: COMPANY_TYPE[settingData.company_type], id: settingData?.company_type } : null
        },
        validationSchema: CompanyProfileValidation,
        onSubmit: (data) => {
            data = { ...data, updated_by: auth?.authData?.id, id: companyData.id };

            // CONDITION  IS FOR ONBOARDING REDIRECTION TO DASHBOARD
            dispatch(UpdateCompanyDetails(auth.authtoken, data, !SetIsFormEdit ? navigate : null));
        }
    });


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;



    const isSameAddress = (value) => {
        if (value == true) {
            setFieldValue('cr_address_same', true);
            setFieldValue('cr_city', formik.values.city);
            setFieldValue('cr_address', formik.values.address);
            setFieldValue('cr_state', formik.values.state);
            setFieldValue('cr_pin_code', formik.values.pin_code);
        } else {
            setFieldValue('cr_address_same', false);
            setFieldValue('cr_city', null);
            setFieldValue('cr_address', '');
            setFieldValue('cr_state', null);
            setFieldValue('cr_pin_code', '');
        }
    };

    useEffect(() => {
        if (auth?.authtoken) {
            dispatch(EmployeeListData(auth?.authtoken, ACTIVE_USER, { is_active: true, form_list: true }))
            dispatch(DesignationList(auth.authtoken))//INFO: This is called to get all the updated list
            dispatch(DepartmentList(auth.authtoken))//INFO: This is called to get the updated list
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
            isSameAddress(true)
        }
    }, [auth.authtoken])


    return (

        <ECard p={20}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <ETypography variant='h5'>
                    Basic Details
                </ETypography>
                {/* <IconButton onClick={() => SetIsFormEdit(true)} edge="end">
                                            <EditIcon />
                                        </IconButton> */}

            </Grid>
            <EDivider />

            {/* Form Starts Here =========================== */}
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {/* Photo upload */}
                    <Grid
                        py={3}
                    >

                        <PhotoUpload
                            companyDetail={settingData}
                            setImageUrlData={setImageUrlData}
                            imageUrlData={imageUrlData}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                        />

                    </Grid>
                    <Grid container spacing={2}>

                        {/* Organization name */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Organization Name*'} >
                                <ETextField
                                    fullWidth
                                    type="company_name"
                                    autoComplete="company_name" // label="Company Name"
                                    {...getFieldProps('company_name')}
                                    error={Boolean(touched.company_name && errors.company_name)}
                                    helperText={touched.company_name && errors.company_name}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Email */}
                        <Grid item md={6} sm={6} xs={12}>

                            <ELabelInputMainGrid label={'Email id*'} >
                                <ETextField
                                    fullWidth
                                    autoComplete="email"
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Contact number */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Contact Number*'} >
                                <ETextField
                                    fullWidth
                                    autoComplete="phone_no"
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><span className="color-text-primary">+91</span></InputAdornment>
                                    }}
                                    {...getFieldProps('phone_no')}
                                    error={Boolean(touched.phone_no && errors.phone_no)}
                                    helperText={touched.phone_no && errors.phone_no}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Company type */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Company Type*'}>
                                <EAutocomplete
                                    name="company_type"
                                    fullWidth
                                    options={categoryOptions}
                                    forcePopupIcon={!formik?.values?.company_type ? true : false}
                                    {...getFieldProps('company_type')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.label || ''}
                                    onChange={(e, value) => {
                                        setFieldValue('company_type', value ? value : null);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="company_type"
                                            placeholder="Select Company Type"
                                            fullWidth
                                            error={Boolean(touched.company_type && errors.company_type)}
                                            helperText={touched.company_type && errors.company_type}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* GSTIN number */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'GSTIN Number'} >
                                <ETextField
                                    fullWidth
                                    autoComplete="gst"
                                    {...getFieldProps('gst')}
                                    onKeyUp={(event) => {
                                        const { value } = event.target;
                                        setFieldValue('gst', value.toUpperCase());
                                    }}
                                    error={Boolean(touched.gst && errors.gst)}
                                    helperText={touched.gst && errors.gst}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* PAN number */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'PAN Number'}>
                                <ETextField
                                    fullWidth
                                    autoComplete="pan"
                                    onKeyUp={(event) => {
                                        const { value } = event.target;
                                        setFieldValue('pan', value.toUpperCase());
                                    }}
                                    inputProps={{ maxLength: 10 }}
                                    InputLabelProps={{ shrink: true }}
                                    {...getFieldProps('pan')}
                                    error={Boolean(touched.pan && errors.pan)}
                                    helperText={touched.pan && errors.pan}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Billing Address */}
                        <Grid item md={12} sm={12} xs={12}>
                            <ELabelInputMainGrid label={'Billing Address*'} isfullgrid={true} alignlabel='true'>
                                <ETextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    // className='w-89per'
                                    type="address"
                                    autoComplete="address"
                                    onKeyUp={(e, value) => {
                                        setFieldValue('address', e.target.value ? e.target.value : '');
                                        if (formik.values.cr_address_same === true) {
                                            setFieldValue(
                                                'cr_address',
                                                e.target.value ? e.target.value : ''
                                            );
                                        }
                                    }}
                                    onKeyDown={(e, value) => {
                                        setFieldValue('address', e.target.value ? e.target.value : '');
                                        if (formik.values.cr_address_same === true) {
                                            setFieldValue(
                                                'cr_address',
                                                e.target.value ? e.target.value : ''
                                            );
                                        }
                                    }}
                                    placeholder="street, building, floor, apartment, etc."
                                    {...getFieldProps('address')}
                                    error={Boolean(touched.address && errors.address)}
                                    helperText={touched.address && errors.address}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* State */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'State*'}>
                                <EAutocomplete
                                    name="state"
                                    fullWidth
                                    options={
                                        StateListData && StateListData?.length > 0 ? StateListData : []
                                    }
                                    forcePopupIcon={!formik?.values?.state ? true : false}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.label || ''}
                                    {...getFieldProps('state')}
                                    onChange={(e, value) => {
                                        if (value) {
                                            dispatch(CityList(auth.authtoken, value.id));
                                        }
                                        setFieldValue('state', value ? value : null);
                                        setFieldValue('city', null);
                                        if (formik.values.cr_address_same == true) {
                                            setFieldValue('cr_state', value ? value : null);
                                            setFieldValue('cr_city', null);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="state"
                                            placeholder="Select State"
                                            fullWidth

                                            error={Boolean(touched.state && errors.state)}
                                            helperText={touched.state && errors.state}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* City */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'City*'}>
                                <EAutocomplete
                                    name="city"
                                    fullWidth
                                    options={
                                        CityListData && CityListData?.length > 0 ? CityListData : []
                                    }
                                    forcePopupIcon={!formik?.values?.city ? true : false}
                                    {...getFieldProps('city')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.label || ''}
                                    onChange={(e, value) => {
                                        setFieldValue('city', value ? value : null);
                                        if (formik.values.cr_address_same == true) {
                                            setFieldValue('cr_city', value ? value : null);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="city"
                                            placeholder="Select City"
                                            fullWidth
                                            error={Boolean(touched.city && errors.city)}
                                            helperText={touched.city && errors.city}
                                        />
                                    )}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Pin Code */}
                        <Grid item md={6} sm={6} xs={12}>
                            <ELabelInputMainGrid label={'Pincode*'}>
                                <ETextField
                                    autoComplete="pin_code"
                                    name="pin_code"
                                    placeholder="Pincode"
                                    fullWidth
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                    onKeyUp={(e, value) => {
                                        setFieldValue('pin_code', e.target.value ? e.target.value : '');
                                        if (formik.values.cr_address_same === true) {
                                            setFieldValue(
                                                'cr_pin_code',
                                                e.target.value ? e.target.value : ''
                                            );
                                        }
                                    }}
                                    onKeyDown={(e, value) => {
                                        setFieldValue('pin_code', e.target.value ? e.target.value : '');
                                        if (formik.values.cr_address_same === true) {
                                            setFieldValue(
                                                'cr_pin_code',
                                                e.target.value ? e.target.value : ''
                                            );
                                        }
                                    }}
                                    {...getFieldProps('pin_code')}
                                    error={Boolean(touched.pin_code && errors.pin_code)}
                                    helperText={touched.pin_code && errors.pin_code}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        <Grid container spacing={1} padding={2}>
                            {/* Address check */}
                            <Grid item md={6} sm={12} xs={12}>

                                <FormControlLabel
                                    control={
                                        <ECheckbox
                                            {...getFieldProps('cr_address_same')}
                                            // checked={formik.values.cr_address_same}
                                            checked={addressCheck}
                                            onChange={(e, value) => {
                                                isSameAddress(value);
                                                setAddressCheck(!addressCheck)
                                            }}
                                        />
                                    }
                                    label={
                                        <ETypography variant="body2" className="light-text-css">
                                            Is your Corresponding Address same as your Billing Address ?
                                        </ETypography>
                                    }
                                />

                            </Grid>

                            {/* Corresponding address */}
                            {!addressCheck ? (
                                <>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <ELabelInputMainGrid label={'Correspondence Address'} isfullgrid={true} alignlabel='true'>
                                            <ETextField
                                                disabled={formik.values.cr_address_same == true ? true : false}
                                                fullWidth
                                                multiline
                                                rows={4}
                                                type="cr_address"
                                                autoComplete="cr_address"
                                                placeholder="Address"
                                                {...getFieldProps('cr_address')}
                                                error={Boolean(touched.cr_address && errors.cr_address)}
                                                helperText={touched.cr_address && errors.cr_address}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'State'}>
                                            <EAutocomplete
                                                disabled={formik.values.cr_address_same == true ? true : false}
                                                name="state"
                                                fullWidth
                                                options={
                                                    StateListData && StateListData?.length > 0 ? StateListData : []
                                                }
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.label || ''}
                                                {...getFieldProps('cr_state')}
                                                onChange={(e, value) => {
                                                    if (value) {
                                                        dispatch(CityList(auth.authtoken, value.id));
                                                    }
                                                    setFieldValue('cr_state', value ? value : null);
                                                    setFieldValue('cr_city', null);
                                                }}
                                                renderInput={(params) => (
                                                    <ETextField
                                                        {...params}
                                                        name="cr_state"
                                                        placeholder="Select State"
                                                        fullWidth
                                                        error={Boolean(touched.cr_state && errors.cr_state)}
                                                        helperText={touched.cr_state && errors.cr_state}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'City'}>
                                            <EAutocomplete
                                                name="cr_city"
                                                disabled={formik.values.cr_address_same == true ? true : false}
                                                fullWidth
                                                options={
                                                    CityListData && CityListData?.length > 0 ? CityListData : []
                                                }
                                                {...getFieldProps('cr_city')}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(e, value) => {
                                                    setFieldValue('cr_city', value ? value : null);
                                                }}
                                                renderInput={(params) => (
                                                    <ETextField
                                                        {...params}
                                                        name="cr_city"
                                                        placeholder="Select City"
                                                        fullWidth
                                                        error={Boolean(touched.cr_city && errors.cr_city)}
                                                        helperText={touched.cr_city && errors.cr_city}
                                                    />
                                                )}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <ELabelInputMainGrid label={'PIN Code'}>
                                            <ETextField
                                                disabled={formik.values.cr_address_same == true ? true : false}
                                                fullWidth
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                                                autoComplete="cr_pin_code"
                                                placeholder="Pincode"
                                                name="cr_pin_code"
                                                {...getFieldProps('cr_pin_code')}
                                                error={Boolean(touched.cr_pin_code && errors.cr_pin_code)}
                                                helperText={touched.cr_pin_code && errors.cr_pin_code}
                                            />
                                        </ELabelInputMainGrid>
                                    </Grid>
                                </>
                            ) : ''}
                        </Grid>
                    </Grid>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="baseline"
                        spacing={2}
                        marginTop={2}
                    >
                        {SetIsFormEdit ? <EButtonOutlined size="large" variant="outlined" className='mr-16px' onClick={() => {
                            SetIsFormEdit(false);
                        }}  > Cancel </EButtonOutlined> :
                            <EButtonOutlined to="/setup" component={ELink} size="large" variant="outlined" className='mr-16px'> Cancel </EButtonOutlined>
                        }

                        <ELoadingButton loading={false} size="large" type="submit" className="ml-0px" >Save</ELoadingButton>

                    </Stack>

                </Form>
            </FormikProvider>
        </ECard>




    );
}
export default CompanyProfileForm