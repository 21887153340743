import { Grid, Input, InputAdornment, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EHelperText } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { CityList } from "../../action/StateCityAction";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import { updateBasicDetails } from "../../action/TalentActions";
import { EditBacicDetailsValidation } from "../../utils/validation";
import { useEffect, useState } from "react";
import { EButtonOutlined, EButtonProfile, ELoadingButton } from "../../BaseComponents/EButtons";
import ImageEditing from "../ImageEditing";
import { EAvatarProfile } from "../../BaseComponents/EAvatar";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { MOBILE_NUMBER_LIMIT, NIL } from "../../constants";


const EditModalBasicDetail = (props) => {
    const { auth, employeeData, closeBasicDetailModal, TalentSetting, 
        // getPhotoUrl, // INFO: commenting this since it was not in use
    loading 
    } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const employeePhotoUrl = getPhotoUrl(employeeData?.employeeInfo?.personal_photo, employeeData?.employeeInfo?.personal_gender);
    const [editor, setEditor] = useState("");

    const [picture, setPicture] = useState({
        cropperOpen: false,
        img: null,
        zoom: 1,
        rotate: 0,
        croppedImg: "",
        croppedImgFileObject: '',
        defaultImgFileObject: '',
        photostatus: ''
    });
    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            talent_id: employeeData.id,
            fname: employeeData?.fname ? employeeData?.fname : '',
            lname: employeeData.lname ? employeeData.lname : '',
            email: employeeData.email ? employeeData.email : '',
            mobile: employeeData.mobile ? employeeData.mobile : '',
            personal_photo: null,
            personal_photo_check:TalentSetting?.field_profile_photo//(new updated on - 24-4-23)
        },
        validationSchema: EditBacicDetailsValidation(auth.authtoken, employeeData?.id ? employeeData.id : 0),
        onSubmit: (data) => {

            if (data && data.personal_photo) { data.personal_photo = picture.croppedImgFileObject }
            if (data && data.personal_state_id && data.personal_state_id.id) { data.personal_state_id = data.personal_state_id.id; }
            if (data && data.personal_district_id && data.personal_district_id.id) { data.personal_district_id = data.personal_district_id.id; }
            if (data) {
                data = { ...data, company_id: auth.authData?.company_id }
                dispatch(updateBasicDetails(auth.authtoken, data, navigate))
            }
        }
    });
    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    useEffect(() => {
        if (auth?.authtoken && employeeData.employeeInfo?.personal_state_id) {
            dispatch(CityList(auth.authtoken, employeeData.employeeInfo?.personal_state_id))
        }
    }, [auth.authtoken, employeeData.employeeInfo?.personal_state_id])

    // INFO: The following two methoda are were for setting the image but we do'nt need it 
    // and just set the image url directly and if the user changes 
    // the profile picture then the old process will carry on
    // useEffect(() => {
    //     onImageEdit(employeePhotoUrl);
    // }, [employeePhotoUrl])

    // const onImageEdit = async (imgUrl) => {
    //     const response = await fetch(imgUrl);
    //     const blob = await response.blob();
    //     const file = new File([blob], employeeData?.employeeInfo?.personal_photo, {
    //         type: blob.type,
    //     });
    //     setPicture({
    //         ...picture,
    //         defaultImgFileObject: file,
    //         croppedImg: imgUrl,
    //         photostatus: 'initialize'
    //     });
    // }
    const handleFileChange = (e) => {
        if (e && e.target.files[0]) {
            let url = URL.createObjectURL(e.target.files[0]);
            setFieldValue("personal_photo", e.target.files[0])
            setPicture({
                ...picture,
                img: e.target.files[0],
                cropperOpen: true,
                croppedImgFileObject: e.target.files[0],
            });
        }
    };

    return (

        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item md={12}>
                                {!picture.cropperOpen ? (
                                    <label htmlFor="icon-button-file">
                                        <div>
                                            <Input inputProps={{ accept: 'image/jpg, image/jpeg' }}
                                                id="icon-button-file" type="file" style={{ display: 'none', }} onChange={e => handleFileChange(e)} placeholder="jgns" />
                                            <EButtonProfile aria-label="upload picture" component="span" id="ePhoto"
                                            >
                                                <EAvatarProfile title={employeeData?.fullname}
                                                    alt={employeeData?.fullname}
                                                    src={picture.croppedImg ? picture.croppedImg : employeeData?.employeeInfo?.personal_photo ? employeeData?.employeeInfo?.personal_photo : ''}
                                                />
                                            </EButtonProfile>
                                        </div>
                                    </label>
                                ) : (
                                    <ImageEditing picture={picture} setPicture={setPicture} editor={editor} setEditor={setEditor} />
                                )}
                                <EHelperText> *jpeg or *jpg format only </EHelperText>
                                <EHelperText style={{ color: 'red' }}> {errors.personal_photo ? errors.personal_photo : ''} </EHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={8} xl={7}>
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                                <ELabelInputMainGrid label={" Name"} isNotForm={true}>
                                    {employeeData?.fullname ? employeeData?.fullname : NIL}
                                </ELabelInputMainGrid>
                            </Grid>
                            {/* <Grid item md={6} sm={12} xs={12}>
                        <ELabelInputMainGrid label={" First Name"}>
                            <ETextField
                                fullWidth
                                inputProps={
                                    { readOnly: true, }
                                }
                                multiline
                                autoComplete="fname"
                                {...getFieldProps('fname')}
                                error={Boolean(touched.fname && errors.fname)}
                                helperText={touched.fname && errors.fname}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} >
                        <ELabelInputMainGrid label={"Last Name"}>
                            <ETextField
                                fullWidth
                                inputProps={
                                    { readOnly: true, }
                                }
                                multiline
                                autoComplete="lname"
                                {...getFieldProps('lname')}
                                error={Boolean(touched.lname && errors.lname)}
                                helperText={touched.lname && errors.lname}
                            />
                        </ELabelInputMainGrid>
                    </Grid> */}
                            <Grid item md={12} sm={12} xs={12}>
                                <ELabelInputMainGrid label={"Email ID"}>
                                    <ETextField
                                        fullWidth
                                        autoComplete="email"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12} >
                                <ELabelInputMainGrid label={"Contact Number"}>
                                    <ETextField
                                        fullWidth
                                        autoComplete="mobile"
                                        {...getFieldProps('mobile')}
                                        inputProps={{ maxLength: MOBILE_NUMBER_LIMIT }}
                                        error={Boolean(touched.mobile && errors.mobile)}
                                        helperText={touched.mobile && errors.mobile}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <span className="color-text-primary">+91</span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </ELabelInputMainGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => closeBasicDetailModal(false)}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton loading={loading}
                        size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>

    )

};

export default EditModalBasicDetail;