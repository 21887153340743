import React, { useEffect, useState } from 'react'
import { EPageWithBreadCrumbs } from '../../../BaseComponents'
import { EBoxPage } from '../../../BaseComponents/EBox'
import { PaymentSearchFilter } from '../../../PageComponents/Payment/PaymentSearchFilter'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from '../../../BaseComponents/ETable'
import { ECircularProgress } from '../../../BaseComponents/ECircularProgress'
import {  APPRAISAL_LIST_DOWNLOAD_URL, COST_TO_COMPANY_DOWNLOAD, DEFAULT_ROWS_PERPAGE } from '../../../constants'
import { Grid } from '@mui/material'
import { ETypography } from '../../../BaseComponents/ETypography'
import { numberWithCommas } from '../../../utils/formatNumber'
import { CostToCompanyReportLog } from '../../../action/ReportAction'
import { generateYearArray } from '../../../utils/CommonFunctions'
import { EIcon } from '../../../BaseComponents/EIcon'
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import SearchNotFound from '../../../PageComponents/SearchNotFound'
import { useNavigate } from 'react-router-dom'
import { DownLoadFile } from '../../../action/AdminAction'

function CostToCompanyList({showDetail}) {
    const {  costToCompanyListLoading,costToCompanyListData } = useSelector(state => state.CostToCompanyListReducer)
    const auth = useSelector(state => state.authReducer);
    const { companyData } = useSelector(state => state.CompanyReducer)
    const dispatch = useDispatch();
    const navigateTo = useNavigate()


    const [searchParam, setSearchParam] = useState({
        end: '',
        year: {
            id: moment().format('YYYY').toString(),
            label: moment().format('YYYY').toString()
        },
        sort: true
    });


    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        const params = {
            year: fnParams?.year ? fnParams?.year?.id :  moment().format('YYYY').toString(),
            sort: fnParams?.sort,
            employee_id:fnParams?.employee?.id,
            appraisal_type:fnParams?.particular?.id
        }
            dispatch(CostToCompanyReportLog({ ...params, page: fnPage, per_page: fnRowPerPage }))
    }



    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...searchParam });
        }
    }, [])



    function onSearch(data) {
        if(data.year){
            setSearchParam(data)
        }else{
            setSearchParam(
                {
                    end: '',
                    year: {
                        id: moment().format('YYYY').toString(),
                        label: moment().format('YYYY').toString()
                    },
                    sort: true

                })
        }
        callApi(data)
        }

    const DownloadReport = (type) => { 
        const year = searchParam?.year ? searchParam?.year.id :  null;
        const sort = searchParam?.sort;    

        let params = {
            download_format: type,
            ...(year && { year }),
            ...((sort === true || sort === false) && { sort }),
        };
        let queryString = new URLSearchParams(params).toString();



        const url = `${COST_TO_COMPANY_DOWNLOAD}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "Cost-to-company-report")
        // let url = '';
        // let file_name = 'Cost-to-company-report';
        // let download_format = filter;
        // url += APPRAISAL_LIST_DOWNLOAD_URL + `?page_name=request_my_payment&download_format=${download_format}&`;
        // if (searchParam?.appraisal_from != null) { url += `appraisal_from=${searchParam?.appraisal_from}&`; }
        // if (searchParam?.year != null) { url += `year_month=${searchParam?.year.id}&`; }
        // if (searchParam?.Employee != null) { url += `talent_name=${searchParam?.Employee?.fullname}&`; }
        // if (searchParam?.sort != null) { url += `sort=${searchParam?.sort ? ' ASC ' : ' DESC'}`; }
        // DownLoadFile(auth.authtoken, url, file_name);
      }

    const TABLE_HEAD = getTableHead();
    const yearArray = generateYearArray(companyData?.created_at)
    let totalExpense = 0
    let totalSaving = 0

  return (
    <EPageWithBreadCrumbs
    title="Cost to Company Report"
    pageHeading="Cost to Company Report"
    breadcrumbsTitle="Cost to Company Report"
    breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Report', href: '' },
        { name: 'Cost to Company Report' }
    ]}
    //   loading={costToCompanyListLoading || Object.keys(costToCompanyListData)?.length == 0}
    >
        
          <EBoxPage>
              {/* SEARCH FILTER */}
              <PaymentSearchFilter
                  sortTitle="Sort By Month"
                  initialFilter={searchParam}
                  onSearch={onSearch}
                  yearList={yearArray}
                  onSort={onSearch}
                  DownloadReport={DownloadReport}
                  withoutDateValidation={true}
              />



              {/* TABLE  */}
              {
                costToCompanyListData?.length > 0  ?
                      <ETableContainer className='mt-16px'>
                          <ETable>
                              <EListHead
                                  headLabel={TABLE_HEAD}
                              />

                              {costToCompanyListLoading && (
                                  <ETableBody>
                                      <ETableRow>
                                          <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                              <ECircularProgress color="primary" />
                                          </ETableCell>
                                      </ETableRow>
                                  </ETableBody>
                              )

                              }

                              {
                                  costToCompanyListData?.length > 0 && (
                                      <ETableBody>
                                          {
                                              costToCompanyListData?.map((row, index) => {
                                                  const { expenses, savings, date_of_month } = row
                                                  totalExpense += Number(expenses)
                                                  totalSaving += Number(savings)


                                                  return (
                                                      <ETableRow
                                                          key={index}
                                                      >

                                                          {/* Sno */}
                                                          <ETableCell align="center" sx={{ padding: '10px' }}>
                                                              {index + 1}

                                                          </ETableCell>

                                                          {/* Month */}
                                                          <ETableCell align="left" sx={{ padding: '10px' }}>
                                                              {moment(date_of_month).format('MMM YYYY')}
                                                          </ETableCell>



                                                          {/* Expense  */}
                                                          <ETableCell align="right" sx={{ padding: '10px' }}>

                                                              {numberWithCommas(expenses)}
                                                          </ETableCell>

                                                          {/* Saving */}
                                                          <ETableCell align="right" sx={{ padding: '10px' }}>
                                                              {numberWithCommas(savings)}
                                                          </ETableCell>

                                                          {/* Action */}
                                                          <ETableCell  align="center" sx={{ padding: '10px', cursor:'pointer' }}>

                                                              <EIcon icon={VisibilityIcon} onClick={() => navigateTo(`/report/cost-to-company-detail/${moment(date_of_month).format('MMM-YYYY')}`)} sx={{ cursor: 'pointer' }} />
                                                          </ETableCell>

                                                      </ETableRow>
                                                  )
                                              })
                                          }

                                      </ETableBody>

                                  )

                              }
                              <ETableBody>

                                  <ETableRow>
                                      {/* Sno */}
                                      <ETableCell align="center" sx={{ padding: '10px' }}>
                                          <ETypography className='font-size-14px bold-600'>
                                              Total
                                          </ETypography>
                                      </ETableCell>

                                      {/* Appraise By  */}
                                      <ETableCell colSpan={2} align="right" >
                                          <ETypography className='font-size-14px bold-600 theme-color-static '>
                                              {numberWithCommas(totalExpense)}
                                          </ETypography>
                                      </ETableCell>

                                      <ETableCell align="right" >
                                          <ETypography className='font-size-14px bold-600 theme-color-static '>
                                              {numberWithCommas(totalSaving)}
                                          </ETypography>
                                      </ETableCell>

                                      <ETableCell align="center" >

                                      </ETableCell>
                                  </ETableRow>
                                  


                              </ETableBody>

                          </ETable>

                      </ETableContainer> :
            <SearchNotFound/>
              }
          </EBoxPage>





</EPageWithBreadCrumbs>
  )
}

export default CostToCompanyList

const getTableHead = () => {
    let tblHead = [
        { id: 'SNo.', label: 'SNo.', alignRight: 'center' },
        { id: 'Month', label: 'Month', alignRight: false },
        { id: 'Expense', label: 'Expense', alignRight: true },
        { id: 'Saving', label: 'Saving', alignRight: true },
        { id: 'Action', label: 'Action', alignRight: 'center' },
    ];
    return tblHead;
};
