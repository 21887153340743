import { ACCOUNTANT, ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_HR, SUPER_ADMIN } from "../../constants"


export function GlobalSearchConfig(auth) {
    const allowedRoles = [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR] || [SUPER_ADMIN, ROLE_ADMIN, ACCOUNTANT]  || [SUPER_ADMIN, ROLE_ADMIN, ACCOUNTANT, ROLE_HR]
    const currentRole = auth?.authData?.role
    const show = allowedRoles.includes(currentRole)
  
    
    return (
        {
            items: [
                {
                    defaultHeading: 'Leave',
                    default: true,
                    paths: [
                        {
                            heading: 'Leave',
                            title: 'Dashboard > Apply Leave',
                            route: '/dashboard',
                            show: true  //show to all roles
                        },
                        {
                            title: 'Leave log > Apply Leave',
                            route: '/attendance/leave-log',
                            heading: 'Leave',
                            show: true //show to all roles
                        },
                        {
                            title: 'My Profile > Leave Log',
                            route: '/my-profile',
                            heading: 'Leave',
                            show: true //show to all roles
                        },

                        {
                            title: 'Holidays',
                            route: '/setting/holiday',
                            heading: 'Leave',
                            show: true //show to all roles
                        },
                        {
                            title: 'Attendance > Leave log',
                            route: '/attendance/leave-log',
                            heading: 'Leave',
                            show: show // HR, ADMIN, SUPER ADMIN
                        },
                        {
                            title: 'Company Settings > Leave',
                            route: '/setting/leave-setting',
                            heading: 'Leave',
                            show: show // ADMIN, SUPER ADMIN, HR 
                        },
                        {
                            title: 'Master > Leave Master',
                            route: '/master/leave',
                            heading: 'Leave',
                            show: show  // HR, ADMIN, SUPER ADMIN
                        },
                        {
                            title: 'FAQ section',
                            route: '/support/faqs',
                            heading: 'Leave',
                            show: true // show to all roles
                        }

                    ]
                },
                {
                    defaultHeading: 'Payment',
                    default: true,
                    paths: [
                        {
                            heading: 'Payment',
                            title: 'My Profile > Payment Details',
                            route: '/my-profile',
                            show: true //show to all roles
                        },
                        {
                            heading: 'Payment',
                            title: 'Payment Log',
                            route: '/payment/payment-logs',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ACCOUNTANT].includes(currentRole)
                        },
                        {
                            heading: 'Payment',
                            title: 'Payment > Salary Payment',
                            route: '/payment/salary-payment',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ACCOUNTANT , ROLE_HR].includes(currentRole) // ACCOUNTANT, ADMIN, SUPER ADMIN, HR
                        },
                        {
                            heading: 'Payment',
                            title: 'Company Settings > Payment',
                            route: '/setting/payment-setting',
                            show: [SUPER_ADMIN, ROLE_ADMIN , ROLE_HR].includes(currentRole) // ADMIN, SUPER ADMIN, HR 
                        },
                        {
                            heading: 'Payment',
                            title: 'FAQ section',
                            route: '/support/faqs',
                            show: true //show to all roles
                        },
                        {
                            heading: 'Payment',
                            title: 'Payment  > Payment Request',
                            route: '/payment/payment-request',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ACCOUNTANT, ROLE_HR].includes(currentRole), // show to all roles,
                        },
                        {
                            heading: 'Payment',
                            title: 'Payment Request',
                            route: '/payment/payment-request',
                            show: [ROLE_EMPLOYEE].includes(currentRole), // EMPLOYEE
                        }

                    ]
                },
                {

                    paths: [
                        {
                            title: 'Contact Support',
                            heading: 'Support',
                            route: '/support/tickets',
                            show: true // show to all roles
                        },
                        {
                            title: 'Tutorials',
                            heading: 'Support',
                            route: '/watch-demo',
                            show: true // show to all roles
                        },
                        {
                            heading: 'Support',
                            title: 'Personalised Support',
                            route: '/support/onboarding-options',
                            show: true // show to all roles
                        },
                        {
                            heading: 'Support',
                            title: 'FAQ section',
                            route: '/support/faqs',
                            show: true // show to all roles
                        }
                    ]
                },
                {
                    paths: [
                        {
                            heading: 'Attendance',
                            title: `Dashboard > Today's Attendance`,
                            route: '/dashboard',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR].includes(currentRole) // HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Dashboard > Attendance Statistics',
                            route: '/dashboard',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR].includes(currentRole) // HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Attendance > Attendance log',
                            route: '/attendance/attendance-log',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR].includes(currentRole) // ACCOUNTANT, HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Attendance > Attendance list',
                            route: '/attendance/attendance-lists',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR].includes(currentRole) //  HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Attendance log',
                            route: '/attendance/attendance-log',
                            show: [ACCOUNTANT, ROLE_EMPLOYEE].includes(currentRole) // Accountant , Employee
                        },

                        {
                            heading: 'Attendance',
                            title: 'Report > Attendance log report',
                            route: '/report/attendance-log-report',
                            show: show // HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Payroll',
                            route: '/payroll/payroll-lists',
                            show: show // HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Report > Payroll report',
                            route: '/report/payroll-report',
                            show: show // HR , ADMIN, SUPER ADMIN
                        },
                        {
                            heading: 'Attendance',
                            title: 'Company Settings > Attendance',
                            route: '/setting/attendance-setting',
                            show: show // HR , ADMIN, SUPER ADMIN
                        }

                    ]
                },
                {

                    paths: [
                        {
                            heading: 'Profile',
                            title: 'My Profile',
                            route: '/my-profile',
                            show: true // show to all roles
                        }
                    ]
                },
                {

                    paths: [
                        {
                            heading: 'Company',
                            title: 'My Company',
                            route: '/my-company',
                            show: true // show to all roles
                        }
                    ]
                },
                {

                    paths: [
                        {
                            heading: 'Holiday',
                            title: 'Holiday List',
                            route: '/setting/holiday',
                            show: true // show to all roles
                        }
                    ]
                },
                {

                    paths: [
                        {
                            heading: 'Payroll',
                            title: 'Payroll List',
                            route: '/payroll/payroll-lists',
                            show: [SUPER_ADMIN, ROLE_ADMIN, ROLE_HR].includes(currentRole) //  HR , ADMIN, SUPER ADMIN
                        }
                    ]
                },
                {

                    paths: [
                        {
                            heading: 'Password',
                            title: 'My Profile > Change Password',
                            route: '/change-password',
                            show: true //show to all
                        },
                        {
                            heading: 'Password',
                            title: 'User management > Change password',
                            route: '/user-management',
                            show: show //  HR , ADMIN, SUPER ADMIN
                        }
                    ]
                },
            ]
        }
    )

}