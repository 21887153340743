import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { EFieldLabelBold, ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { numberWithCommas } from "../../utils/formatNumber";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { accountantPayValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { HRAccountantPaymentAction  } from "../../action/PaymentAction";
import { APPROVED_STATUS,  PAYMENT_MODE,  PENDING_STATUS } from "../../constants";


   

const FreelancerPaymentActionModal = (props) => {
    const { selectedData, setModalState, auth, getPaymentFilterData } = props;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            payment_medium: PAYMENT_MODE[0],
            payment_detail: '',
            accountant_remark: '',
        },
        validationSchema: accountantPayValidation,
        onSubmit: (data) => {
            data.payment_medium = data.payment_medium.id;
            data = { ...data, payment_id: selectedData.modalData?.id };
            dispatch(HRAccountantPaymentAction(auth?.authtoken, data, getPaymentFilterData, 'final-action'));
            setModalState({ modalData: null, modalCategory: null })
        }
    });




    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Freelancer Name </EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.freelancerData?.freelancer_name}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Contract Name </EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.ContractWorkFace?.ContractWork?.contract_title}</ETypography>
                        </FieldContainerCustom>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Bill Date</EFieldLabelBold>
                            <ETypography>{moment(selectedData.modalData?.transaction_date).format('DD/MM/YYYY')}</ETypography>
                        </FieldContainerCustom>
                    </Grid> */}
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Amount</EFieldLabelBold>
                            <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
                        </FieldContainerCustom>
                    </Grid>

                    {/* <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Reason</EFieldLabelBold>
                            <ETypography>{selectedData.modalData?.remark}</ETypography>
                        </FieldContainerCustom>
                    </Grid> */}

                    

                    {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) ? <>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                                <EAutocomplete
                                    name="Payment Mode"
                                    fullWidth
                                    {...getFieldProps('payment_medium')}
                                    options={PAYMENT_MODE}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(e, value) => {
                                        setFieldValue('payment_medium', value ? value : null)
                                        if (value?.id != 'CHEQUE') { setFieldValue('payment_detail', '') }
                                    }}
                                    renderInput={params => (
                                        <ETextField
                                            {...params}
                                            name='payment_medium'
                                            placeholder="Select Payment Mode"
                                            fullWidth
                                            error={Boolean(touched.payment_medium && errors.payment_medium)}
                                            helperText={touched.payment_medium && errors.payment_medium}
                                        />
                                    )}
                                />
                            </FieldContainerCustom>
                        </Grid>
                        {getFieldProps('payment_medium').value && getFieldProps('payment_medium').value?.id === 'CHEQUE' &&
                            <Grid item xs={12} sm={12} lg={12} xl={12}>
                                <FieldContainerCustom
                                    labelwidth={50}
                                >
                                    <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                    <ETextField
                                        name='payment_detail'
                                        {...getFieldProps('payment_detail')}
                                        rows={3}
                                        fullWidth
                                        error={Boolean(touched.payment_detail && errors.payment_detail)}
                                        helperText={touched.payment_detail && errors.payment_detail}
                                    />
                                </FieldContainerCustom>
                            </Grid>}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={50}
                            >
                                <EFieldLabelBold>Remark</EFieldLabelBold>
                                <ETextField
                                    name='accountant_remark'
                                    multiline
                                    rows={3}
                                    {...getFieldProps('accountant_remark')}
                                    fullWidth
                                    error={Boolean(touched.accountant_remark && errors.accountant_remark)}
                                    helperText={touched.accountant_remark && errors.accountant_remark}
                                />
                            </FieldContainerCustom>
                        </Grid>
                    </> : <>
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Payment Mode</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.payment_medium == 'CASH' ? 'Cash' : 'Cheque'}</ETypography>
                            </FieldContainerCustom>
                        </Grid>
                        {selectedData.modalData?.payment_medium === 'CHEQUE' && <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Cheque Number</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.payment_medium_ref}</ETypography>
                            </FieldContainerCustom>
                        </Grid>}
                        
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <FieldContainerCustom
                                labelwidth={33}
                            >
                                <EFieldLabelBold>Remark</EFieldLabelBold>
                                <ETypography>{selectedData.modalData?.accountant_remark}</ETypography>
                            </FieldContainerCustom>
                        </Grid>
                    </>}

                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setModalState({ modalData: null, modalName: null })}
                    > Close </EButtonOutlined>

                    {(selectedData.modalData?.transaction_status == APPROVED_STATUS || (selectedData.modalData?.transaction_status == PENDING_STATUS)) && (
                        <ELoadingButton
                            size="large" variant="outlined" type="submit" >Pay</ELoadingButton>
                    )}


                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default FreelancerPaymentActionModal