import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { EButtonOutlined, EButtonOutlinedIcon, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { EBox, EBoxPage } from '../../BaseComponents/EBox';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { Stack } from '@mui/system';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import { GET_PAYROLL_SETTING_API, PERMISSIONS, SET_PAYROLL_SETTING_API, XL } from '../../constants';
// import { SalarySlipContainer } from '../../BaseComponents/EContainer';// INFO: COMMENTED FOLLOWING CODE FOR NOW, CODE IS FOR SALARY SLIP
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingGrid } from '../../BaseComponents/EGrid';
import { ReturnBoolean } from '../../utils/CommonFunctions';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ETextField } from '../../BaseComponents/ETextField';
import { PayrollSettingValidation } from '../../utils/validation';
import { useCompanySettingProvider } from '../../context/CompanySettingContext';
import { SetPayrollSettings } from '../../action/SettingAction';
import { useCheckPermission } from '../../hooks';
import EModal from '../../BaseComponents/EModal';



const initialModal = {
    open: false,
    onClick: () => null,
    header: '',
    body: <></>,
    loading: false,
}

const getPayrollGenerationDate = (id) => {
    return Date_Array.find((i) => {
        return i.id == id
    });
}

function PayrollSettings(props) {

    const { auth } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const settingsContext = useCompanySettingProvider();
    const company = useSelector(state => state.CompanyReducer)

    const settingData = settingsContext.setting.payrollSettingData;
    // const [data, setData] = useState([]) // INFO: COMMENTED FOLLOWING CODE FOR NOW, CODE IS FOR SALARY SLIP

    // State to handle toggle state
    const [defaultSetting, setDefaultSetting] = useState(settingData?.is_default_setting !== null ? settingData?.is_default_setting : null)

    // Default settings
    const companyDefaultSetting = {
        is_default_setting: true,
        salarySlip: '',
        payroll_is_auto: settingData?.companyDefaultSetting?.payroll_is_auto ? true : false,
        payroll_auto_generation_date: settingData?.companyDefaultSetting?.payroll_auto_generation_date ? getPayrollGenerationDate(settingData?.companyDefaultSetting?.payroll_auto_generation_date) : null
    }

    // Company settings
    const companySetting = {
        is_default_setting: false,
        salarySlip: '',
        payroll_is_auto: settingData?.companySetting?.payroll_is_auto ? true : false,
        payroll_auto_generation_date: settingData?.companySetting?.payroll_auto_generation_date ? getPayrollGenerationDate(settingData?.companySetting?.payroll_auto_generation_date.toString()) : null  //Using toString to convert the value to string to avoid EAutoComplete warning
    }
    const formik = useFormik({
        initialValues: defaultSetting ? companyDefaultSetting : companySetting,
        validationSchema: PayrollSettingValidation,
        enableReinitialize: true,
        onSubmit: () => {
            handleSave()
        }
    });
    const { errors, touched, setValues, getFieldProps, setFieldValue, handleSubmit, setFieldTouched } = formik;

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditPayrollSettings])

    const [isFormChanged, setIsFormChanged] = useState(Object.keys(touched).length > 0)
    const [modal, setModal] = useState(initialModal)

    useEffect(() => {
        setIsFormChanged(Object.keys(touched).length > 0)
    }, [touched])

    const handleDefaultApply = () => {
        setModal({
            open: true,
            onClick: () => {
                setDefaultSetting(true)
                dispatch(SetPayrollSettings(auth.authtoken, companyDefaultSetting, SET_PAYROLL_SETTING_API, navigate))
            },
            header: 'Default Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    This will restore all settings to their default values.
                </ETypography>
                <ETypography className='font-size-16px'>
                    Any changes you've made will be lost!
                </ETypography>
                <ETypography className='font-size-16px'>
                    Do you want to apply default settings?
                </ETypography>
            </>
        })
    }

    const closeModal = () => {
        setModal(initialModal)
    }

    const handleSave = () => {
        setModal({
            open: true,
            onClick: () => {
                saveSetting()
                closeModal()
            },
            header: 'Custom Setting: Confirmation',
            body: <>
                <ETypography className='font-size-16px'>
                    Are you sure! you want to save these changes in
                    {" "}
                    <span className='theme-main-text-color-bold'>
                        Payroll
                    </span>
                    {" "}
                    settings?
                </ETypography>
            </>
        })
    }
    const saveSetting = () => {
        setDefaultSetting(false)
        let data = { ...formik.values, is_default_setting: false }
        dispatch(SetPayrollSettings(auth.authtoken, data, SET_PAYROLL_SETTING_API, navigate))
    }

    const handleCancel = () => {
        formik.resetForm()
        setIsFormChanged(false)
        setValues(companySetting)
        if (settingData?.companySetting?.payroll_auto_generation_date) {
            const date_Object = Date_Array.find((i) => {
                return i.id == settingData?.companySetting?.payroll_auto_generation_date
            });
            setFieldValue('payroll_auto_generation_date', date_Object)
        }
    }

    const handleChange = (field, value) => {
        setFieldTouched(field, true)
        setFieldValue(field, value)
        setIsFormChanged(true)
    }


    return (
        <Page title="Payroll Settings" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Payroll Settings'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Setting', href: '' },
                            {
                                name: 'Payroll Settings',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate('/')} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>
            <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid
                        container
                        display="row"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
                            <ETypographyPageHeading> Payroll Settings </ETypographyPageHeading>
                            <EHidden width="mdUp">
                                <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                                    <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                            </EHidden>
                        </Grid>

                        <Grid item xs={12} sm={12} >
                            {settingData?.globalSettingLoading ?
                                <LoadingGrid size={XL} isPage={true} />
                                :
                                <EBoxPage className='p0'>
                                    <ECardBasic className='mb-16px border-05px-border6 '>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                                <ETypography className='bold-600 '>
                                                    Autopilot Payroll Date
                                                </ETypography>
                                                <ETypography className=' greyColor4-color'>This feature allows you to auto disburse salary of your employees on the selected date.</ETypography>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align='right'>
                                                <FormControl component="fieldset" {...getFieldProps('payroll_is_auto')} >
                                                    <ERadioGroup row={true} aria-label="payroll_is_auto" name="payroll_is_auto"
                                                        value={formik?.values?.payroll_is_auto != null ? ReturnBoolean(formik?.values?.payroll_is_auto) : null}>
                                                        <FormControlLabel value={true} control={
                                                            <ERadio className=' px-6px'
                                                                disabled={(!allowEdit) ? true : false}
                                                                onChange={(e, value) => {
                                                                    handleChange('payroll_is_auto', true)
                                                                }}
                                                            />
                                                        } label={<span className='font-size-16px'>Yes</span>} />
                                                        <FormControlLabel value={false} control={
                                                            <ERadio className=' px-6px'
                                                                disabled={(!allowEdit) ? true : false}
                                                                onChange={(e, value) => {
                                                                    handleChange('payroll_is_auto', false)
                                                                }}
                                                            />
                                                        } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
                                                    </ERadioGroup>
                                                </FormControl>
                                            </Grid>

                                            {ReturnBoolean(formik?.values?.payroll_is_auto) ? <>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                    <ECardDashboard className={'Bg-card5 border-none shadow-none'} >
                                                        <EAutocomplete
                                                            name="Leave Type"
                                                            {...getFieldProps('payroll_auto_generation_date')}
                                                            options={Date_Array && Date_Array?.length > 0 ? Date_Array : []}
                                                            forcePopupIcon={formik?.values?.payroll_auto_generation_date && formik?.values?.payroll_auto_generation_date.length > 0 ? false : true}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            className='maxWidth-300px'
                                                            onChange={(e, value) => {
                                                                handleChange('payroll_auto_generation_date', value ? value : null)
                                                            }}
                                                            disabled={!allowEdit}
                                                            renderInput={params => (
                                                                <ETextField
                                                                    {...params}
                                                                    name='payroll_auto_generation_date'
                                                                    placeholder="Select Payroll Generation Date"
                                                                    error={Boolean(touched.payroll_auto_generation_date && errors.payroll_auto_generation_date)}
                                                                    helperText={touched.payroll_auto_generation_date && errors.payroll_auto_generation_date}
                                                                />
                                                            )}
                                                        />
                                                    </ECardDashboard>
                                                </Grid>
                                                <Grid item>

                                                    <span className='bold-600'>Note:</span> <br />
                                                    <ul className='ml-25px font-size-14px'>
                                                        <li>The payroll for the previous month will be automated on the selected date of the current month.
                                                            Eg. If you have selected "17th of every month", then payroll for January (previous month) will be automated on the 17th of February (current month).
                                                        </li>
                                                        <li>If the selected date is between the 29th to 31st, then for the month of February(with 28 or 29 days), the payroll will be automated on the last day of the month.
                                                        </li>
                                                    </ul>
                                                </Grid>

                                            </>
                                                : ''}

                                        </Grid>
                                    </ECardBasic>
                                    {
                                        company?.companyData?.onBoarding?.onboarding_is_completed
                                            ?
                                            allowEdit &&
                                            <EBox display={"flex"} justifyContent={"space-between"}>
                                                <EButtonOutlined variant="outlined" onClick={handleDefaultApply}>
                                                    Apply Default Setting
                                                </EButtonOutlined>
                                                <EBox>
                                                    {
                                                        isFormChanged &&
                                                        <EButtonOutlined variant="outlined" onClick={handleCancel}>
                                                            Cancel
                                                        </EButtonOutlined>
                                                    }
                                                    <ELoadingButton disabled={!isFormChanged} type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                                                        Save
                                                    </ELoadingButton>
                                                </EBox>
                                            </EBox>
                                            :
                                            <Stack direction="row" className='justify-content-flex-end '>
                                                <ELoadingButton type='submit' variant="contained" size="large" className='button-left-margin font-size-14px'>
                                                    Save & Next
                                                </ELoadingButton>

                                            </Stack>
                                    }
                                </EBoxPage>
                            }
                        </Grid>

                    </Grid>
                </Form>
            </FormikProvider>
            {/* INFO: MODAL to confirm form save */}
            <EModal open={modal?.open} headervalue={modal?.header}>
                {modal?.body}
                <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
                    <EButtonOutlined size="large" variant="outlined" onClick={closeModal} color="secondary">No</EButtonOutlined>
                    <ELoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={modal?.onClick}
                    >
                        Yes
                    </ELoadingButton>
                </Stack>
            </EModal>
        </Page>
    );
}
export default PayrollSettings

// INFO: The following array is hard coded for date Autocomplete dropdown
const Date_Array = [
    {
        id: 1,
        label: '1st of Every Month'
    },
    {
        id: 2,
        label: '2nd of Every Month'
    },
    {
        id: 3,
        label: '3rd of Every Month'
    },
    {
        id: 4,
        label: '4th of Every Month'
    },
    {
        id: 5,
        label: '5th of Every Month'
    },
    {
        id: 6,
        label: '6th of Every Month'
    },
    {
        id: 7,
        label: '7th of Every Month'
    },
    {
        id: 8,
        label: '8th of Every Month'
    },
    {
        id: 9,
        label: '9th of Every Month'
    },
    {
        id: 10,
        label: '10th of Every Month'
    },
    {
        id: 11,
        label: '11th of Every Month'
    },
    {
        id: 12,
        label: '12th of Every Month'
    },
    {
        id: 13,
        label: '13th of Every Month'
    },
    {
        id: 14,
        label: '14th of Every Month'
    },
    {
        id: 15,
        label: '15th of Every Month'
    },
    {
        id: 16,
        label: '16th of Every Month'
    },
    {
        id: 17,
        label: '17th of Every Month'
    },
    {
        id: 18,
        label: '18th of Every Month'
    },
    {
        id: 19,
        label: '19th of Every Month'
    },
    {
        id: 20,
        label: '20th of Every Month'
    },
    {
        id: 21,
        label: '21st of Every Month'
    },
    {
        id: 22,
        label: '22nd of Every Month'
    },
    {
        id: 23,
        label: '23rd of Every Month'
    },
    {
        id: 24,
        label: '24th of Every Month'
    },
    {
        id: 25,
        label: '25th of Every Month'
    },
    {
        id: 26,
        label: '26th of Every Month'
    },
    {
        id: 27,
        label: '27th of Every Month'
    },
    {
        id: 28,
        label: '28th of Every Month'
    },
    {
        id: 29,
        label: '29th of Every Month'
    },
    {
        id: 30,
        label: '30th of Every Month'
    },
    {
        id: 31,
        label: '31st of Every Month'
    }
]

// ***********______COMMENTED FOLLOWING CODE FOR NOW, FOLLOWING CODE IS FOR SALARY SLIP________******//

//  <ECardBasic className='mb-0px border-05px-border6 '>
//  <Grid container spacing={2}>
//      <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
//          <ETypography className='bold-600 '>
//          Autopilot Payroll Date
//          </ETypography>
//          <ETypography className=' greyColor4-color'>This feature allows you to auto disburse salary of your talents on the selected date.</ETypography>
//      </Grid>
//      <Grid item xs={12} sm={12} md={12} lg={12} xl={4} align='right'>
//      <FormControl component="fieldset" {...getFieldProps('payroll_is_auto')} >
//                  <ERadioGroup row={true} aria-label="payroll_is_auto" name="payroll_is_auto"
//                      value={formik?.values?.attendance_mode ? Number(formik?.values?.payroll_is_auto) : null}>
//                      <FormControlLabel value={true} control={
//                          <ERadio className=' px-6px'
//                              onChange={(e, value) => {
//                                  setFieldValue('payroll_is_auto', true)
//                              }}
//                          />
//                      } label={<span className='font-size-16px'>Yes</span>} />
//                      <FormControlLabel value={false} control={
//                          <ERadio className=' px-6px'
//                              onChange={(e, value) => {
//                                  setFieldValue('payroll_is_auto', false)
//                              }}
//                          />
//                      } label={<span className='font-size-16px'>No</span>} className=' mr-0px' />
//                  </ERadioGroup>
//              </FormControl>
//      </Grid>
//      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
//          <ECardDashboard className={'Bg-card5 border-none shadow-none'} >
//              <Grid container spacing={2}>
//                  {data &&
//                      data?.map((row, index) => {
//                          const { id, sample_image_path, is_active, is_selectable } = row;
//                          const src = '/assets/images/' + sample_image_path
//                          return (
//                              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>

//                                  <SalarySlipContainer key={id} elevation={5}>
//                                      <FormControlLabel
//                                          value={id}
//                                          className='m0px'
//                                          control={
//                                              <ERadio
//                                                  disabled={is_selectable ? false : true}
//                                                  checked={is_active ? true : false}
//                                                  onChange={(e, value) => {
//                                                      if (is_selectable) {
//                                                          HandleChange(index, id)
//                                                      }
//                                                  }}
//                                              />
//                                          }
//                                          label={<ETypography>{`Format ${id}`}</ETypography>}
//                                      />
//                                      <EBox
//                                          onClick={() => { if (is_selectable) { HandleChange(index, id) } }}
//                                          component="img"
//                                          sx={{ height: '100%', width: '100%' }}
//                                          src={src}
//                                      />
//                                  </SalarySlipContainer>
//                              </Grid>
//                          );
//                      })}
//              </Grid>
//          </ECardDashboard>
//      </Grid>
//  </Grid>
// </ECardBasic>