
import React, { useState } from "react";
import { Grid, InputAdornment, Stack } from "@mui/material";
import { EButtonOutlined, EButtonProfile, ELoadingButton } from "../../BaseComponents/EButtons";
import { ECard } from "../../BaseComponents/ECard";
import { ETypography } from "../../BaseComponents/ETypography";
import { EDivider } from "../../BaseComponents/EDivider";
import { IconButton } from '@mui/material';
import { EIcon } from "../../BaseComponents/EIcon";
import { EAvatarProfile } from "../../BaseComponents/EAvatar";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import EditIcon from '@iconify/icons-material-symbols/edit-outline';
import { COMPANY_TYPE } from "../../constants";
import { ETextField } from "../../BaseComponents/ETextField";
import { Form, FormikProvider, useFormik } from "formik";
import { UpdateCompanyBasic } from "../../action/CompanyAction";
import { useDispatch, useSelector } from "react-redux";
import { CompanyDetailValidation, CompanyProfileValidation } from "../../utils/validation";
import PhotoUpload from "../company/PhotoUpload";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";


const CompanyProfile = ({ company }) => {

    return (
        <Grid container spacing={1}   >
            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'Company Name'} isNotForm labelClassname="align-center bold-600">
                    {company?.company_name ? company?.company_name : '-'}
                </ELabelInputMainGrid>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'Company Type'} isNotForm>
                    {company?.company_type ? COMPANY_TYPE[company?.company_type] : '-'}
                </ELabelInputMainGrid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'Email ID'} isNotForm>
                    {company?.company_email ? company?.company_email : '-'}
                </ELabelInputMainGrid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'Contact Number'} isNotForm>
                    {company?.company_phone ? '+91- ' + company.company_phone : '-'}
                </ELabelInputMainGrid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'GSTIN Number'} isNotForm>
                    {company?.company_gst ? company?.company_gst : '-'}
                </ELabelInputMainGrid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ELabelInputMainGrid label={'PAN Number'} isNotForm>
                    {company?.company_pan ? company?.company_pan : '-'}
                </ELabelInputMainGrid>
            </Grid>
        </Grid>
    )

}

const CompanyProfileForm = ({ company, formik, open, setOpen }) => {


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue, values } = formik;
    const categoryOptions = Object.keys(COMPANY_TYPE).map((item) => {
        return { id: item, label: COMPANY_TYPE[item] }
    });
    // const sub = () => {
    //     console.log(handleSubmit, "============================");
    //     handleSubmit();
    // }

    return (

        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'Company Name'} isNotForm labelClassname="align-center bold-600">
                            {company?.company_name ? company?.company_name : '-'}
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'Company Type'} >
                            {company?.company_type ? COMPANY_TYPE[company?.company_type] :

                                <EAutocomplete
                                    name="company_type"
                                    fullWidth
                                    options={categoryOptions}
                                    forcePopupIcon={!formik?.values?.company_type ? true : false}
                                    {...getFieldProps('company_type')}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.label || ''}
                                    onChange={(e, value) => {
                                        setFieldValue('company_type', value ? value : null);
                                    }}
                                    renderInput={(params) => (
                                        <ETextField
                                            {...params}
                                            name="company_type"
                                            placeholder="Select Company Type"
                                            fullWidth
                                            error={Boolean(touched.company_type && errors.company_type)}
                                            helperText={touched.company_type && errors.company_type}
                                        />
                                    )}
                                />
                            }

                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'Email ID'} >
                            <ETextField
                                fullWidth
                                autoComplete="email"
                                {...getFieldProps('email')}
                                error={Boolean(touched.email && errors.email)}
                                helperText={touched.email && errors.email}
                                onChange={(e) => {

                                    setFieldValue('email', e.target.value)
                                }}

                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'Contact Number'} >
                            <ETextField
                                fullWidth
                                autoComplete="phone_no"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><span className="color-text-primary">+91</span></InputAdornment>
                                }}
                                {...getFieldProps('phone_no')}
                                error={Boolean(touched.phone_no && errors.phone_no)}
                                helperText={touched.phone_no && errors.phone_no}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'GSTIN Number'} >
                            <ETextField
                                fullWidth
                                autoComplete="gst"
                                {...getFieldProps('gst')}
                                // value={values.gst ? values.gst.toUpperCase() : ''}
                                onKeyUp={(event) => {
                                    const { value } = event.target;
                                    setFieldValue('gst', value ? value.toUpperCase() : '');
                                }}

                                error={Boolean(touched.gst && errors.gst)}
                                helperText={touched.gst && errors.gst}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <ELabelInputMainGrid label={'PAN Number'}>
                            <ETextField
                                fullWidth
                                autoComplete="pan"
                                onKeyUp={(event) => {
                                    const { value } = event.target;
                                    setFieldValue('pan', value ? value.toUpperCase() : '');
                                }}

                                InputLabelProps={{ shrink: true }}
                                {...getFieldProps('pan')}
                                // value={values.pan ? values.pan.toUpperCase() : ''}

                                error={Boolean(touched.pan && errors.pan)}
                                helperText={touched.pan && errors.pan}
                            />
                        </ELabelInputMainGrid>

                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="baseline"
                            spacing={2}
                            marginTop={2}
                        >


                            <EButtonOutlined size="large" variant="outlined" className='mr-16px' onClick={() => setOpen({ ...open, isCompanyInfo: false })}> Cancel </EButtonOutlined>
                            <ELoadingButton loading={false} size="large" type="submit" className="ml-0px" >Update</ELoadingButton>

                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>)
}

export default function CompanyInfo({ allowEdit = false, open, setOpen }) {
    const company = useSelector((state) => state.CompanyReducer?.companyData);
    const [imageUrlData, setImageUrlData] = useState(null);
    const auth = useSelector((state) => state.authReducer);

    const dispatch = useDispatch()


    const formik = useFormik({
        initialValues: {
            email: company?.company_email,
            phone_no: company?.company_phone,
            gst: company.company_gst ? company.company_gst : '',
            pan: company.company_pan ? company.company_pan : '',
            company_logo: '',
            updated_by: company?.updated_by,
            id: company?.id

        },
        validationSchema: CompanyDetailValidation,
        onSubmit: (data) => {
            dispatch(UpdateCompanyBasic(auth?.authtoken, data));
        }
    });
    const { errors, touched, setFieldValue } = formik;

    console.log(errors);

    return (
        <ECard className="mb-16px" p={20}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ETypography className="font-size-20px bold-600">{open.isCompanyInfo ? 'Update Company Details' : 'Company Details'} </ETypography>
                {allowEdit && !open.isCompanyInfo &&
                    <IconButton onClick={() => {

                        // setValues({
                        //     email: company?.company_email,
                        //     // phone_no: company?.company_phone,
                        //     phone_no: company.company_phone ? company.company_phone : null,
                        //     gst: company.company_gst ? company.company_gst : '',
                        //     pan: company.company_pan ? company.company_pan : '',
                        //     company_logo: '',
                        //     updated_by: auth?.authData?.id,
                        //     id: company?.id


                        // })


                        setOpen({ ...open, isCompanyInfo: !open.isCompanyInfo, isAddressDetails: false })
                    }} >
                        <EIcon icon={EditIcon} className='p0' />
                    </IconButton>
                }
            </Grid>

            <EDivider />

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                // alignItems={open.isCompanyInfo ? "start" : "center"}
                style={{ paddingTop: 20 }}
            // py={3}
            >

                <Grid item xs={12} sm={6} lg={4} xl={4} sx={{ p: 0, textAlign: 'center' }}>
                    {!open.isCompanyInfo ?
                        <EButtonProfile aria-label="upload picture" component="span" id="ePhoto">
                            <EAvatarProfile
                                alt="Profile Pic"
                                src={company?.company_logo ? company?.company_logo : '/assets/images/companyProfile.svg'}
                            />
                        </EButtonProfile>
                        :

                        <Grid
                            py={3}
                        >
                            <PhotoUpload
                                companyDetail={company}
                                setImageUrlData={setImageUrlData}
                                imageUrlData={imageUrlData}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                            />

                        </Grid>
                    }

                </Grid>



                <Grid item xs={12} sm={6} lg={8} xl={8}>
                    {!open.isCompanyInfo ?
                        <CompanyProfile company={company} />
                        :

                        <CompanyProfileForm formik={formik} company={company}
                            open={open} setOpen={setOpen}
                        />
                    }
                </Grid>
            </Grid>
        </ECard>
    )
}
