import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider, Field } from 'formik';
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { EAutocomplete } from "../../BaseComponents/EAutocomplete";
import { RequestDetailValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import { RequestDetailFormAction } from "../../action/PaymentAction";
import { PAYMENT_CATEGORY, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_REIMBURSEMENT, REMARK_LIMIT, ROLE_ADMIN, ROLE_HR, SUPER_ADMIN } from "../../constants";
import { EDatePicker } from "../../BaseComponents/EDatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from "moment";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { getPaymentCategory } from "../../utils/getPaymentCategory";
import { ETypography } from "../../BaseComponents/ETypography";


const PaymentRequestModal = (props) => {
  const { setModalState, auth, setting, getPaymentFilterData, employeeList, fromPage, onlyOther = false, withOutOther = false } = props;

  const PAYMENT_CATEGORY_ROLE = ([ROLE_HR, SUPER_ADMIN, ROLE_ADMIN].includes(auth?.authData?.role) && !withOutOther) ? PAYMENT_CATEGORY : PAYMENT_CATEGORY?.filter((item) => item?.id != PAYMENT_CATEGORY_OTHER);
  const paymentSettingData = setting?.paymentSettingData?.companySetting;
  // Setting the payment category as per settings
  const ENABLE_REMBURSEMENT = paymentSettingData?.enable_reimbursement;
  const ATTACHMENT_REQUIRED = paymentSettingData?.reimbursement_attachment_required;
  const ENABLE_REIMBURSEMENT_REASON = paymentSettingData?.enable_reimbursement_reason;
  const REIMBURSEMENT_REASON_LIST = paymentSettingData?.reimbursementReason.filter((item) => item.is_active)
  const ENABLE_ADVANCE_SALARY_SETTING = paymentSettingData?.enable_advance_salary;



  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      is_attachment_required: ATTACHMENT_REQUIRED,
      transaction_category: onlyOther ? getPaymentCategory('DISABLE_BOTH', PAYMENT_CATEGORY_ROLE)[0] : null,
      request_amount: '',
      remark: null,
      reimbursement_bill_date: null,
      reimbursement_attachment: null,
      reimbursement_reason_category: null,
      employee_id: null,
    },
    validationSchema: RequestDetailValidation(ENABLE_REIMBURSEMENT_REASON),
    onSubmit: (data) => {

      if (data?.transaction_category?.id == PAYMENT_CATEGORY_OTHER) {
        data.employee_id = data?.employee_id?.id;
      } else {
        data.employee_id = auth?.authData?.id
      }
      if (data?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT && data.reimbursement_bill_date !== null) {
        data.reimbursement_bill_date = moment(data.reimbursement_bill_date).format('YYYY-MM-DD');
      }

      data.transaction_category = data?.transaction_category?.id;
      data = { ...data, created_by: auth?.authData?.id };
      dispatch(RequestDetailFormAction(auth?.authtoken, data, getPaymentFilterData, fromPage ? fromPage : null));
      setModalState({ modalData: null, modalName: null })

    }
  });

  // If other category then remove self employee

  const filteredEmployee = employeeList?.employeeData?.rows?.filter(object => {
    return object.id !== auth?.authData?.id;
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  const AttachmentFileFunction = (e) => {
    if (e.target.files[0]) {
      setFieldValue('reimbursement_attachment', e.target.files[0]);
    }
  };


  return (
    <FormikProvider value={formik} >
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

        <Grid container spacing={2} sx={{ paddingTop: "10px" }}  >
          {
            !onlyOther &&
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Request Type'} isfullgrid={true} isModal={true}  >
                <EAutocomplete
                  name="transaction_category"
                  fullWidth
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  {...getFieldProps('transaction_category')}
                  options={
                    ENABLE_REMBURSEMENT && ENABLE_ADVANCE_SALARY_SETTING ?
                      getPaymentCategory('ENABLE_BOTH', PAYMENT_CATEGORY_ROLE) :
                      ENABLE_REMBURSEMENT ?
                        getPaymentCategory('ENABLE_REIMBURSEMENT', PAYMENT_CATEGORY_ROLE)
                        : ENABLE_ADVANCE_SALARY_SETTING ?
                          getPaymentCategory('ENABLE_ADVANCE_SALARY', PAYMENT_CATEGORY_ROLE) :
                          getPaymentCategory('DISABLE_BOTH', PAYMENT_CATEGORY_ROLE)

                  }
                  getOptionLabel={(option) => option.label || ''}
                  onChange={(e, value) => {
                    setFieldValue('transaction_category', value ? value : null);
                    setFieldValue('reimbursement_reason_category', null);
                  }}
                  renderInput={(params) => (
                    <ETextField
                      {...params}
                      name="transaction_category"
                      placeholder="Select Request Type"
                      fullWidth
                      error={Boolean(touched.transaction_category && errors.transaction_category)}
                      helperText={touched.transaction_category && errors.transaction_category}
                    />
                  )}
                />
              </ELabelInputMainGrid>

            </Grid>
          }
          {formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT &&
            <>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ELabelInputMainGrid label={'Bill Date'} isfullgrid={true} isModal={true}  >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <EDatePicker
                      name="reimbursement_bill_date"
                      {...getFieldProps('reimbursement_bill_date')}
                      inputFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      // minDate={x}
                      // value={dates?.start}
                      onChange={(selectedDate) => {
                        setFieldValue(
                          'reimbursement_bill_date',
                          selectedDate ? selectedDate : new Date()
                        );
                        // setDates({ ...dates, start: selectedDate ? selectedDate : null });
                      }}
                      renderInput={(params) => (
                        <Field
                          component={ETextField}
                          {...params}
                          fullWidth
                          name="reimbursement_bill_date"
                          error={Boolean(touched.reimbursement_bill_date && errors.reimbursement_bill_date)}
                          helperText={touched.reimbursement_bill_date && errors.reimbursement_bill_date}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </ELabelInputMainGrid>
              </Grid>
              <Grid item xs={12} sm={12} lg={12} xl={12}>
                <ELabelInputMainGrid childrenClassname={'input-file-selector'} label={`Attachment ${ATTACHMENT_REQUIRED ? '*' : ''} `} isfullgrid={true} isModal={true}  >
                  <ETextField
                    fullWidth
                    type="file"
                    onChange={(e) => {
                      AttachmentFileFunction(e);
                    }}
                    error={Boolean(touched.reimbursement_attachment && errors.reimbursement_attachment)}
                    helperText={touched.reimbursement_attachment && errors.reimbursement_attachment}
                  />

                  <span className="greyColor4-color font-size-12px">Allowed format is pdf or jpeg or jpg</span>
                </ELabelInputMainGrid>
              </Grid>
            </>
          }
          {formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_OTHER &&

            <Grid item xs={12} sm={12} lg={12} xl={12}>

              <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true}  >
                <EAutocomplete
                  name="employee_id"
                  fullWidth
                  // label="Talent Name"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  {...getFieldProps('employee_id')}
                  options={filteredEmployee && filteredEmployee?.length > 0 ? filteredEmployee : []}
                  getOptionLabel={(option) => option.label || ''}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.label} ({option.employee_code})
                    </li>
                  )}
                  onChange={(e, value) => {
                    setFieldValue('employee_id', value ? value : null);
                  }}
                  renderInput={(params) => (
                    <ETextField
                      {...params}
                      name="employee_id"
                      placeholder="Select Employee"
                      error={Boolean(touched.employee_id && errors.employee_id)}
                      helperText={touched.employee_id && errors.employee_id}
                    />
                  )}
                />
              </ELabelInputMainGrid>
            </Grid>
          }

          <Grid item xs={12} sm={12} lg={12} xl={12}>

            <ELabelInputMainGrid label={'Request Amount'} isfullgrid={true} isModal={true}  >
              <ETextField
                fullWidth
                autoComplete="request_amount"
                {...getFieldProps('request_amount')}
                error={Boolean(touched.request_amount && errors.request_amount)}
                helperText={touched.request_amount && errors.request_amount}
                placeholder='Request Amount'
              />
            </ELabelInputMainGrid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* Check setting reimbursement reason is enabled
                and from particular dropdown category is reimbursement  */}
            {
              ENABLE_REIMBURSEMENT_REASON &&
              formik?.values?.transaction_category?.id == PAYMENT_CATEGORY_REIMBURSEMENT && (

                <ELabelInputMainGrid label={'Reason'} isfullgrid={true} isModal={true}  >
                  <EAutocomplete
                    name="reimbursement_reason_category"
                    {...getFieldProps('reimbursement_reason_category')}
                    fullWidth
                    options={REIMBURSEMENT_REASON_LIST ? REIMBURSEMENT_REASON_LIST : []}
                    getOptionLabel={(option) => option['reimbursement_reason'] || option}
                    isOptionEqualToValue={(option, value) => option.reimbursement_reason == value.reimbursement_reason}
                    onChange={(e, value) => {
                      // setFieldValue("remark", value && formik.values.remark != "Other" ? value.reimbursement_reason : null);
                      setFieldValue("reimbursement_reason_category", value);
                      setFieldValue("remark", !value.is_other ? value.reimbursement_reason : null);
                    }}
                    renderInput={(params) => (
                      <ETextField
                        {...params}
                        name="reimbursement_reason_category"
                        placeholder="Select Reason"
                        fullWidth
                        error={Boolean(touched.reimbursement_reason_category && errors.reimbursement_reason_category)}
                        helperText={touched.reimbursement_reason_category && errors.reimbursement_reason_category}
                      />
                    )}
                  />
                </ELabelInputMainGrid>
              )}
          </Grid>

          {/* 
            Below are the points when the reason field will display
            -If The particular is selected by other|advance|default
            -IF reimbursement is enable and reason is selected as 'Other' show the textfield */}
          <Grid item className="pt-0" xs={12} sm={12} md={12} lg={12} xl={12}>
            {(
              //if selected particular is remburment with reason is other then 
              ((!formik.values.transaction_category?.id) || (!ENABLE_REIMBURSEMENT_REASON) ||
                [PAYMENT_CATEGORY_OTHER, PAYMENT_CATEGORY_ADVANCE].includes(formik.values.transaction_category?.id) ||
                (formik.values.reimbursement_reason_category && formik.values.reimbursement_reason_category?.is_other)) &&

              <ELabelInputMainGrid
                label={(formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other) ? '' : 'Reason'}
                isfullgrid={true}
                isModal={true}
                disableColon={(formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other) ? true : false}
              >
                <ETextField
                  fullWidth
                  multiline
                  placeholder="Give a brief description"
                  rows={3}
                  className={((formik.values?.reimbursement_reason_category && formik.values?.reimbursement_reason_category?.is_other)) && 'mt-16px'}
                  // {...getFieldProps('remark')}
                  onChange={(e) => {
                    setFieldValue("remark", e.target.value)
                  }}
                  inputProps={{ maxLength: REMARK_LIMIT }}
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                />
                <ETypography className='font-size-14px mt-4px greyColor11-color'>
                  {`${formik?.values?.remark?.length || 0}/${REMARK_LIMIT}`}
                </ETypography>
              </ELabelInputMainGrid>
            )}
          </Grid>
        </Grid>

        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
          <EButtonOutlined size="large" variant="outlined"
            onClick={() => setModalState({ modalData: null, modalName: null })}
          > Cancel </EButtonOutlined>
          <ELoadingButton
            size="large" variant="outlined" type="submit" >Submit</ELoadingButton>
        </Stack>
      </Form>
    </FormikProvider >
  );
};

export default PaymentRequestModal