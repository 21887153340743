import {
  NOTIFICATION_CATEGORY_LIST_FAILURE,
  NOTIFICATION_CATEGORY_LIST_LOADING,
  NOTIFICATION_CATEGORY_LIST_SUCCESS,
} from "../constants";

const INITIAL_STATE = {
  notificationCategoryData: [],
  notificationCategoryMessage: null,
  notificationCategorySuccess: false,
  notificationCategoryLoading: true,
};

const NotificationCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_CATEGORY_LIST_LOADING:
      return {
        ...state,
        notificationCategoryLoading: true,
        notificationCategoryData: action.payload,
      };
    case NOTIFICATION_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        notificationCategoryLoading: false,
        notificationCategorySuccess: true,
        notificationCategoryData: action.payload.data,
        notificationListMessage: action.payload.message,
      };

    case NOTIFICATION_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        notificationCategoryLoading: false,
        notificationCategorySuccess: false,
        notificationListMessage: action.payload.message,
        notificationCategoryData: action.payload,
      };

    default:
      return state;
  }
};

export default NotificationCategoryReducer;
