import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Grid, Stack } from "@mui/material";
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from "react-redux";
import { AddNewLeaveReasonValidation } from '../../utils/validation';
import { EBox } from '../../BaseComponents/EBox';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { ETextField } from '../../BaseComponents/ETextField';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import { ERadio, ERadioGroup } from '../../BaseComponents/ERadio';
import { CreateUpdateDelLeaveMaster } from '../../action/LeaveAction';
import { ADD, UPDATE } from '../../constants';
import { ReturnBoolean } from '../../utils/CommonFunctions';

function LeaveMasterForm(props) {
    const { auth, loading, close, rowData } = props
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            leave_reason: rowData?.leave_reason ? rowData?.leave_reason : '',
            status: rowData && rowData?.status ? true : rowData && !rowData?.status ? false : true,
        },
        validationSchema: AddNewLeaveReasonValidation,
        onSubmit: (data) => {
            if (rowData) {
                data = { ...data, updated_by: auth?.authData?.id, id: rowData?.id, status: ReturnBoolean(data.status) };
                dispatch(CreateUpdateDelLeaveMaster(auth?.authtoken, data, UPDATE, close))
            }
            else {
                data = { ...data, created_by: auth?.authData?.id, status: ReturnBoolean(data.status) };
                dispatch(CreateUpdateDelLeaveMaster(auth?.authtoken, data, ADD, close))
            }
        }
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <EBox>
            <FormikProvider value={formik} id="previewBlock">
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ELabelInputMainGrid label={'Title'} alignlabel={'flex-start'}>
                                <ETextField
                                    fullWidth
                                    autoComplete="leave_reason"
                                    placeholder='Title'
                                    {...getFieldProps('leave_reason')}
                                    onChange={(e) => { setFieldValue('leave_reason', e.target.value) }}
                                    error={Boolean(touched.leave_reason && errors.leave_reason)}
                                    helperText={touched.leave_reason && errors.leave_reason}
                                />
                            </ELabelInputMainGrid>
                        </Grid>
                        <Grid item xs={12}>
                            <ELabelInputMainGrid label={'Status'}>
                                <FormControl component="fieldset" error={Boolean(touched.status && errors.status)} >
                                    <ERadioGroup row={true} aria-label="status" name="status" {...getFieldProps('status')} >
                                        <FormControlLabel value={true} control={
                                            <ERadio
                                                onChange={(e, value) => {
                                                    setFieldValue('status', true)
                                                }}
                                            />
                                        } label='Enable' />
                                        <FormControlLabel value={false} control={
                                            <ERadio
                                                onChange={(e, value) => {
                                                    setFieldValue('status', false)
                                                }}
                                            />
                                        } label="Disable" />
                                    </ERadioGroup>
                                    {touched.status && <FormHelperText> {errors.status}</FormHelperText>}
                                </FormControl>
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                    <Stack direction='row' spacing={2} paddingTop={2} className='modal1-buttons-stick-bottom'>
                        <EButtonOutlined size="large" variant="outlined" onClick={() => close()}  > Cancel </EButtonOutlined>
                        <ELoadingButton loading={loading} size="large" variant="outlined" type="submit" >{rowData ? "Update" : 'Save'}</ELoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </EBox>
    );
}

export default LeaveMasterForm
