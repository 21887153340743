
import {
    EMPLOYEE_PAYMENT_FAILURE, EMPLOYEE_PAYMENT_LOADING, EMPLOYEE_PAYMENT_SUCCESS, 
    EMPLOYEE_PAYMENT_FORM_SUCCESS, EMPLOYEE_PAYMENT_FORM_FAILURE, EMPLOYEE_PAYMENT_FORM_LOADING
} from '../constants';

const INITIAL_STATE = {
    employeePaymentData: {},
    employeePaymentSuccess: false,
    employeePaymentLoading: false,
    employeePaymentMessage: null,
    employeePaymentAddData: {},
    employeePaymentAddSuccess: false,
    employeePaymentAddLoading: false,
    employeePaymentAddMessage: null,
};

const EmployeePaymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EMPLOYEE_PAYMENT_LOADING:
            return {
                employeePaymentLoading: true,
                employeePaymentData: {},
            };

        case EMPLOYEE_PAYMENT_SUCCESS:
            return {
                employeePaymentLoading: false,
                employeePaymentSuccess: true,
                employeePaymentData: action.payload.data,
                employeePaymentMessage: action.payload.message,
            };

        case EMPLOYEE_PAYMENT_FAILURE:
            return {
                employeePaymentLoading: false,
                employeePaymentSuccess: false,
                employeePaymentMessage: action.payload.message,
                employeePaymentData: {},
            };
        case EMPLOYEE_PAYMENT_FORM_LOADING:
            return {
                employeePaymentAddLoading: true,
                employeePaymentAddData: {},
            };

        case EMPLOYEE_PAYMENT_FORM_SUCCESS:
            return {
                employeePaymentAddLoading: false,
                employeePaymentAddSuccess: true,
                employeePaymentAddData: action.payload.data,
                employeePaymentAddMessage: action.payload.message,
            };

        case EMPLOYEE_PAYMENT_FORM_FAILURE:
            return {
                employeePaymentAddLoading: false,
                employeePaymentAddSuccess: false,
                employeePaymentAddMessage: action.payload.message,
                employeePaymentAddData: {},
            };

        default:
            return state;
    }
};

export default EmployeePaymentReducer;
