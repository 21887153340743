import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import Page from '../../BaseComponents/EPage';
import { EButtonOutlined, EButtonOutlinedIcon, EButton, EIconButton } from '../../BaseComponents/EButtons';
import { ETypography, ETypographyPageHeading } from '../../BaseComponents/ETypography';
import EHeaderBreadcrumbs from '../../BaseComponents/EBreadcrum';
import { ECardBasic, ECardDashboard } from '../../BaseComponents/ECard';
import { GET_ACCOUNT_SETTING_API, PERMISSIONS, TALENT, THEMES, XL } from '../../constants';
import { GetSetting } from '../../action/CompanySettingAction';
import moment from 'moment';
import { EBoxPage } from '../../BaseComponents/EBox';
import { useTheme } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import { LoadingGrid } from '../../BaseComponents/EGrid';
import EHidden from '../../BaseComponents/EHidden';
import { EIcon } from '../../BaseComponents/EIcon';
import EModal from "../../BaseComponents/EModal";
import ForwardArrow from '@iconify/icons-material-symbols/chevron-left-rounded';
import EditIcon from '@iconify/icons-material-symbols/edit-outline-rounded';
import BillingInfoForm from '../../PageComponents/Accounts/BillingInfoForm';
import CheckCircle from '@iconify/icons-material-symbols/check-circle-rounded';
import PlanDetail from "../../PageComponents/company/PlanDetail.js"
import { startupData, enterpriseData } from "../../PlansData"
import { StateList } from '../../action/StateCityAction';
import { useCheckPermission } from '../../hooks';
import AddTalentForm from '../../PageComponents/Settings/AccountSettings/AddTalentForm';

function AccountSetting(props) {



    const [planModal, setPlanModal] = useState({ value: false, id: null, title: '', data: [] })
    const [addTalentForm, setTalentForm] = useState({ isOpen: false, data: [] })
    const { auth } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyDetail = useSelector((state) => state.GlobalSettingReducer);
    const company = companyDetail?.globalSettingData?.companySetting?.company_data
    const theme = useTheme()

    //console.log("message",companyDetail?.globalSettingData);

    // Modal state for billing information form
    const [formOpen, setFormOpen] = useState(false)

    const handleFormClick = () => {
        setFormOpen(!formOpen)
    }



    const planValidFrom = moment(company?.CompanyActivePlan?.plan_valid_from)
    const planValidTo = moment(company?.CompanyActivePlan?.plan_valid_to)
    const daysLeft = planValidTo.diff(planValidFrom, 'days')

    useEffect(() => {
        if (auth.authtoken) {
            dispatch(GetSetting(auth.authtoken, GET_ACCOUNT_SETTING_API));
            dispatch(StateList(auth.authtoken));//INFO: calling all State List here since we have removed it from login and check token actions
        }
    }, [auth.authtoken]);

    const barOptions = {
        responsive: true,
        cutout: '80%',
        // borderRadius: (total_leave && available_leave && (available_leave - total_leave)) ? 20 : 0,
        borderRadius: 20,
        displayColor: false,
        centerText: {
            display: true,
            text: `90%`,
        },
        plugins: {
            LightTheme: theme?.mode != THEMES.DARK ? true : false, //conditionally applying, dark and light theme plugin, found this one solution for changing plugin conditionally
            DarkTheme: theme?.mode == THEMES.DARK ? true : false,
            legend: {
                position: "top", // lable position left/right/top/bottom
                labels: {
                    boxWidth: 0, // lable box size
                },
            },
            tooltip: {
                enabled: false,
            },

        },
    };

    const allowed_talent = company?.CompanyActivePlan?.allowed_talent ? company?.CompanyActivePlan?.allowed_talent : 0
    const active_talent_count = companyDetail?.globalSettingData?.companySetting?.active_employee_count ? companyDetail?.globalSettingData?.companySetting?.active_employee_count : 0
    const allowed_freelancer = company?.CompanyActivePlan?.allowed_freelancer ? company?.CompanyActivePlan?.allowed_freelancer : 0
    const active_freelancer_count = companyDetail?.globalSettingData?.companySetting?.active_freelancer_count ? companyDetail?.globalSettingData?.companySetting?.active_freelancer_count : 0

    const allowEdit = useCheckPermission([PERMISSIONS.ViewEditAccountSettings])

    // INFO: This function provides dynamic data for donut
    const DonutData = (total, available, user) => {
        //INFO: color change in graph according to change talent/freelancer
        const color1 = user == TALENT ? theme?.palette?.info?.blue2 : theme?.palette?.text?.staticLightGrey2;
        return ({
            labels: '',
            datasets: [
                {
                    label: "",
                    data: [available, ((total - available) >= 0 ? total - available : 0)],
                    backgroundColor: [theme?.palette?.text?.staticBlue2, color1],
                    borderWidth: 0,
                    weight: 0.05,
                },
            ],
        })
    }


    return (
        <Page title="Account Settings" >
            <Grid container display="row" justifyContent="space-between" >
                <Grid item>
                    <EHeaderBreadcrumbs
                        heading='Account Settings'
                        links={[
                            { name: 'Dashboard', href: '/' },
                            { name: 'Company Settings', href: '' },
                            {
                                name: 'Account Settings',
                            }
                        ]}
                    />
                </Grid>
                <EHidden width="mdDown">
                    <Grid item>
                        <EButtonOutlined variant="outlined" onClick={() => navigate('/')} >  Back </EButtonOutlined>
                    </Grid>
                </EHidden>
            </Grid>

            <Grid
                container
                display="row"
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item xs={12} sm={12} className='display-flex justify-content-space-between'>
                    <ETypographyPageHeading >
                        Account Settings
                    </ETypographyPageHeading>
                    <EHidden width="mdUp">
                        <EButtonOutlinedIcon variant="outlined" onClick={() => navigate('/')} className='button-left-margin'>
                            <EIcon icon={ForwardArrow} className='height-width-15px' /> </EButtonOutlinedIcon>
                    </EHidden>
                </Grid>

                <Grid item xs={12} sm={12} >
                    {companyDetail.globalSettingLoading ?
                        <LoadingGrid size={XL} isPage={true} />
                        :
                        <EBoxPage className='p0'>
                            <Grid container className='justify-content-center' spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <ECardBasic className='mb-0px border-05px-border6 '>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <ETypography className='bold-600'>
                                                    Manage Subscription
                                                </ETypography>
                                            </Grid>
                                            <br />
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <ETypography className='font-size-14px'>
                                                    Company Name: <span className='bold-600'> {company?.company_name} </span> <br />
                                                    Company ID: <span className='bold-600'> {company?.id} </span> <br />
                                                    Your subscription is valid till<span className='bold-600'> {company?.CompanyActivePlan?.plan_valid_to ? moment(company?.CompanyActivePlan?.plan_valid_to).format('DD/MM/YYYY') : ''} </span>
                                                </ETypography>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                        <ETypography className='bold-600'> Licence</ETypography>
                                                    </Grid>
                                                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12} >
                                                        <DonutGrid data={DonutData(allowed_talent, active_talent_count, TALENT)}
                                                            options={barOptions}
                                                            boxName={'Employees'}
                                                            available={allowed_talent}
                                                            inUse={active_talent_count}
                                                            onClickFunction={() => { setTalentForm({ ...addTalentForm, isOpen: true }) }}
                                                        />
                                                    </Grid>
                                                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12} >
                                                        <DonutGrid
                                                            data={DonutData(allowed_freelancer, active_freelancer_count)}
                                                            options={barOptions}
                                                            boxName={'Freelancers'}
                                                            available={allowed_freelancer}
                                                            inUse={active_freelancer_count}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ECardBasic>
                                </Grid>


                                {/* PLAN INFORMATION */}

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <ECardBasic className='mb-0px border-05px-border6 '>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <ETypography className='bold-600'>
                                                    Plan Details
                                                </ETypography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Grid container spacing={2}>
                                                    {/* Active plan */}
                                                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                                                        <ECardDashboard className="border-main-color p-24px" sx={{ height: '166px' }}>
                                                            <Grid container sx={{ marginBottom: '19px' }} justifyContent="space-between" alignItems="center">
                                                                {/* Plan info */}
                                                                <Grid item>
                                                                    <ETypography className='bold-600'>{company?.CompanyActivePlan?.plan_name}</ETypography>
                                                                    <ETypography className='font-size-12px bold-400 greyColor3-color'>
                                                                        {daysLeft} Days remaining
                                                                    </ETypography>
                                                                </Grid>
                                                                {/* plan mode */}
                                                                <Grid item className='text-align'>
                                                                    <EIcon icon={CheckCircle} className='height-width-30px theme-color-static' />
                                                                    <ETypography className='bold-600'>Active plan</ETypography>
                                                                </Grid>
                                                            </Grid>
                                                            <EButtonOutlined onClick={() => setPlanModal({
                                                                value: true,
                                                                id: '1',
                                                                title: 'Startup Plan',
                                                                data: startupData.plans.features
                                                            })} className='Bg-card5 theme-color-static bold-600'>
                                                                View More
                                                            </EButtonOutlined>
                                                        </ECardDashboard>
                                                    </Grid>

                                                    {/* Enterprise Plan */}
                                                    <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
                                                        <ECardDashboard className="border-main-color bg2-bgcolor-dullLightOrange p-24px" sx={{ height: '166px' }}>
                                                            <Grid container sx={{ marginBottom: '36px' }} alignItems="center" id="card">
                                                                {/* Plan info */}
                                                                <Grid item>
                                                                    <ETypography className='bold-600'>Enterprise Plan</ETypography>
                                                                    <ETypography className='font-size-12px bold-400 greyColor3-color'>365 days </ETypography>
                                                                </Grid>
                                                            </Grid>
                                                            <EButton onClick={() => setPlanModal({
                                                                value: true,
                                                                id: '2',
                                                                title: 'Enterprise Plan',
                                                                data: enterpriseData.plans.features
                                                            })}>View More</EButton>
                                                        </ECardDashboard>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </ECardBasic>
                                </Grid>

                                {/* Billing Information */}
                                <Grid item xs={12} sm={12} md={12} >
                                    <ECardBasic pb={0} className='mb-0px border-05px-border6'>
                                        <Grid
                                            container
                                            alignItems="flex-start"
                                            // spacing={2}
                                            padding={1}
                                        >
                                            <Grid container justifyContent='space-between'>
                                                <Grid item >
                                                    <ETypography className='bold-600'>
                                                        Billing Information
                                                    </ETypography>
                                                </Grid>
                                                {
                                                    allowEdit &&
                                                    <Grid item  >
                                                        <EIconButton onClick={() => handleFormClick()}>
                                                            <EIcon icon={EditIcon} />
                                                        </EIconButton>
                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid item md={12} sm={12} xs={12} >
                                                <ETypography className='font-size-14px'>
                                                    {/* Primary Admin Name */}
                                                    <span className='bold-600 text-transform-capitalize'>{company?.primaryAdmin?.fullname}</span>
                                                    <br />
                                                    {/* Company Name */}
                                                    {company?.company_name}{' '}
                                                    <br />
                                                    {/* Address */}
                                                    {company?.address}
                                                    {company?.permanentCity?.label ? ', ' + company?.permanentCity?.label : ''}
                                                    {company?.permanentState?.label ? ', ' + company?.permanentState?.label : ''}
                                                    {company?.pin_code ? ', ' + company?.pin_code : ''}
                                                    <br />
                                                    {/* Company Mobile */}
                                                    {company?.company_phone ? '+91-' + company?.company_phone : '-'}
                                                    <br />
                                                    {/*Company Email */}
                                                    {company?.company_email ? company?.company_email : '-'}
                                                    <br />
                                                    {/* GST Number */}
                                                    {company?.company_gst ? 'GSTIN: ' + company?.company_gst : 'GSTIN: -'}
                                                </ETypography>
                                            </Grid>
                                        </Grid>
                                    </ECardBasic>
                                </Grid>
                            </Grid>
                        </EBoxPage>
                    }
                </Grid>
            </Grid>

            {/* Billing INformation Form */}
            {
                formOpen && (
                    <EModal open={formOpen} close={() => setFormOpen(false)} headervalue="Update Billing Information" >
                        <BillingInfoForm data={company} close={() => setFormOpen(false)} />
                    </EModal>
                )
            }
            {
                planModal && (
                    <EModal open={planModal.value} close={() => setPlanModal({
                        value: false, id: null, title: '',
                        data: []
                    })} headervalue={planModal.title}>
                        <>
                            <PlanDetail data={planModal.data} />
                            <Grid container justifyContent='right'>
                                <EButtonOutlined variant="outlined" onClick={() => setPlanModal({
                                    value: false, id: null, title: '', data: []
                                })}>Close</EButtonOutlined>
                            </Grid>
                        </>
                    </EModal>
                )
            }

            {/* Add Talent Form */}
            {addTalentForm.isOpen && (
                <EModal open={addTalentForm.isOpen}
                    close={() => { setTalentForm({ isOpen: false, data: null }) }}
                    headervalue="Add Talents">
                    <AddTalentForm close={() => { setTalentForm({ isOpen: false, data: null }) }} />
                </EModal>
            )
            }
        </Page>


    );
}
export default AccountSetting

// INFO: This provides grid for donuts
const DonutGrid = (props) => {
    const { data, options, boxName, available, inUse, onClickFunction = null } = props
    const total = available >= 0 && inUse >= 0 ? (available) : available >= 0 ? available : inUse >= 0 ? inUse : 0
    const available_count = available - inUse < 0 ? 0 : available - inUse
    return (
        <ECardDashboard className={'Bg-card5 border-none shadow-none'} >
            <ETypography className='bold-600'>{boxName}</ETypography>
            <div style={{ top: "-4rem", marginBottom: '4rem' }} className='responsive-height-donut2 position-relative text-align' >
                <div className='font-size-14px position-relative' style={{ right: '7rem', top: '5rem' }}> Available <br /><span className='bold-600'>{available_count ? available_count : 0}</span></div>
                <div className='font-size-14px position-relative' style={{ right: '-5rem', top: '1rem' }}>  In Use <br /><span className='bold-600'>{inUse ? inUse : 0}</span></div>
                <Doughnut data={data} options={options} />
                <div className='font-size-14px position-relative pt-9px' >
                    <span className='bold-600 '>Total {boxName} : {total}</span>
                </div>
            </div>
            <EButton
                target="_blank"
                // href={'https://entera.in/'}
                onClick={() => { onClickFunction() }}
            >
                Get More {boxName == 'Talents' ? 'Users' : 'Freelancers'}
            </EButton>
        </ECardDashboard>
    )
}
