import { Grid, Stack } from "@mui/material";
import { EFieldLabelSemiBold, ETypography } from "../BaseComponents/ETypography";
import React from "react";
import { ETextFieldLogin } from "../BaseComponents/ETextField";
import { EButton } from "../BaseComponents/EButtons";
// import { AuthEmailSent } from "../layouts/icons/auth-email-sent";
// import { AuthEmailInputIcon } from "../layouts/icons/auth-email-input-icon";
// import { AuthKeyIcon } from "../layouts/icons/auth-key-icon";
import { AuthLockIcon } from "../layouts/icons/auth-lock-icon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik, Form, FormikProvider } from 'formik';
import { ForgetPassAction } from "../action/AuthAction";
import { ForgetPassSchema } from "../utils/validation";


const AuthForgotEmailForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validationSchema: ForgetPassSchema,
        onSubmit: (data) => {
            dispatch(ForgetPassAction(data, navigate))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Grid>
                        <AuthLockIcon />
                    </Grid>

                    <Grid>
                        <ETypography variant="h5" mb={1} className='font-size-20px pt-40px'>
                            Trouble logging in?
                        </ETypography>
                        <ETypography className="font-size-16px line-height-168px">
                            Enter your registered username to reset your password.
                        </ETypography>
                    </Grid>

                    <div className=" text-align-webkit-left pt-16px" >
                        <EFieldLabelSemiBold className="line-height-140perc">Username</EFieldLabelSemiBold>
                        <ETextFieldLogin
                            fullWidth
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('username')}
                            error={Boolean(touched.username && errors.username)}
                            helperText={touched.username && errors.username}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Your Registered Username"
                        />
                    </div>

                    <EButton
                        fullWidth
                        size="medium"
                        type="submit"
                        className='font-size-16px bold-700 mt-16px borderRadius-4px'
                    >
                        Send
                    </EButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default AuthForgotEmailForm;