import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SubscriptionDetails from "../../PageComponents/SubscriptionAndInvoices/SubscriptionDetails";
import InvoiceDetails from "../../PageComponents/SubscriptionAndInvoices/InvoiceDetails";
import { EPageWithBreadCrumbs } from "../../BaseComponents";
import { useDispatch, useSelector } from "react-redux";
import { GetSetting } from "../../action/CompanySettingAction";
import { GET_ACCOUNT_SETTING_API } from "../../constants";

function SubscriptionAndInvoices(props) {
  const { auth } = props;
  const navigate = useNavigate();
  const { globalSettingData, globalSettingLoading } = useSelector((state) => state.GlobalSettingReducer);
  const dispatch = useDispatch();
  // console.log('asdh',  Object.keys(PLAN_TIME_FREQUENCY_TYPE).include( globalSettingData?.companySetting?.activePlan?.plan_type)   )

  useEffect(() => {
    if (auth.authtoken) {
      dispatch(GetSetting(auth.authtoken, GET_ACCOUNT_SETTING_API));
    }
  }, [auth.authtoken]);

  return (
    <EPageWithBreadCrumbs
      title="My Subscription"
      pageHeading="Subscription Details"
      breadcrumbsTitle="My Subscription"
      breadcrumbsLinks={[
        { name: 'Dashboard', href: '/' },
        { name: 'Profile', href: '' },
        {
          name: 'My Subscription',
        }
      ]}
      loading={globalSettingLoading || Object.keys(globalSettingData || {}).length === 0}
      hideDivider={true}
    >

      {/* Subscription Details Component*/}
      <SubscriptionDetails />

      {/* Invoice Details component */}
      {

        globalSettingData?.companySetting?.activePlan?.is_paid &&
        <InvoiceDetails auth={auth} />

      }
    </EPageWithBreadCrumbs>
  )
}

export default SubscriptionAndInvoices;



