import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EChip from "../../BaseComponents/EChip";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { ACCOUNTANT, CANCELLED_STATUS, DEFAULT_ROWS_PERPAGE, PAID_STATUS, PARTIALLY_PAID_STATUS, PARTIAL_STATUS, PAYMENT_TRANSACTION_STATUS, PAYMENT_TRANSACTION_STATUS_CHIP, PENDING_STATUS, ROLE_ADMIN, ROLE_HR, SALARY_PAYMENT_TRANSACTION_STATUS, SALARY_STATUS, SUPER_ADMIN, TALENT, TALENT_SALARY_LIST_DOWNLOAD } from "../../constants";
import SearchNotFound from "../SearchNotFound";
import { useNavigate } from "react-router-dom";
import { EIcon } from "../../BaseComponents/EIcon";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import { getPayrollNewList, getPayrollNewListByTalent } from "../../action/PayrollAction";
import { PaymentSearchFilter } from "./PaymentSearchFilter";
import moment from "moment";
import ActionMenu from "./ActionMenu";
import { EMenuItem } from "../../BaseComponents/EMenuPopover";
import SimCardIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded'
import AlternateMailIcon from '@iconify/icons-material-symbols/alternate-email'
import { EBox } from "../../BaseComponents/EBox";
import SalaryStatusDetalViewModal from "./SalaryPaidViewModal";
import EModal from "../../BaseComponents/EModal";
import currencyRupeeIcon from '@iconify/icons-material-symbols/currency-rupee'
import blockIcon from '@iconify/icons-material-symbols/block'
import PaymentCancelModal from "./PaymentCancelModal";
import PaymentModal from "./PaymentModal";
import { useTheme } from "@emotion/react";
import { DownLoadFile } from "../../action/AdminAction";



const SalaryPayrollDetailByTalent = (props) => {

  const { auth, tabvalue } = props;

  const navigate = useNavigate()
  const theme = useTheme()
  const dispatch = useDispatch();

  const [searchParam, setSearchParam] = useState({
    end: null,
    start: null,
    sort: null,
    employee: auth?.authData?.id,
    status: null,
    from_month: null,
    to_month: null
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

  const paymentList = useSelector((state) => state.PayrollReducer);
  const [salaryDueModal, setSalaryDueModal] = useState({ modalData: null, modalCategory: false });

  // const IS_CALLED_WITH_EMPLOYEE_ID = Object.values(searchParam).every(prop => prop === null) ? true : false;
  const IS_CALLED_WITH_EMPLOYEE_ID = !searchParam.employee
  const IS_HR = auth?.authData?.role == ROLE_HR
  const IS_EMPLOYEE = auth?.authData?.role == TALENT
  const IS_SUPERIOR = auth?.authData?.role == ROLE_ADMIN || auth?.authData?.role == SUPER_ADMIN || auth?.authData?.role == ACCOUNTANT

  const callApi = (fnParams, fnPage, fnRowPerPage) => {
    const params = {
      employee_id: fnParams?.employee?.id ? fnParams?.employee?.id : null,
      from_month: fnParams?.start ? moment(fnParams?.start).format('YYYYMM') : null,
      to_month: fnParams?.end ? moment(fnParams?.end).format('YYYYMM') : null,
      transaction_status: fnParams?.status ? fnParams?.status?.map(item => item.id) : null,
      sort: fnParams?.sort ? "ASC" : "DESC"
    }

    dispatch(getPayrollNewListByTalent({ ...params, page: fnPage, per_page: fnRowPerPage }))


  }

  // useEffect(() => {
  //   dispatch(getPayrollNewListByTalent({ page: 0, per_page: 10 }))
  // }, [tabvalue])


  const handleChangePage = (event, newPage) => {
    callApi(searchParam, newPage, rowsPerPage);
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
    setRowsPerPage(rowPerPageCount);
    callApi(searchParam, 0, rowPerPageCount);
    setPage(0);
  };


  function numberWithCommas(num) {
    let number = Number(num)
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
  }

  function onSearch(data) {
    setSearchParam(data)
    callApi(data, page, rowsPerPage);
  }
  const actionEvent = (params1, params2) => {
    setSalaryDueModal({ modalData: params1, modalCategory: params2 });
  }
  const DownloadReport = (type) => {
    const employee_id = searchParam?.employee?.id ? searchParam?.employee?.id : null;
    const from_month = searchParam?.start ? moment(searchParam?.start).format('YYYYMM') : null;
    const to_month = searchParam?.end ? moment(searchParam?.end).format('YYYYMM') : null;
    const sort = searchParam?.status ? searchParam?.status?.map(item => item.id) : null;
    const transaction_status = searchParam?.status ? searchParam?.status?.map(item => item.id) : [];
    const query_tr_status = transaction_status.map(value => `${'transaction_status'}=${encodeURIComponent(value)}`).join('&');

    let params = {
      download_format: type,
      ...(from_month && { from_month }),
      ...(to_month && { to_month }),
      ...(employee_id && { employee_id }),
      ...(sort && { sort }),
    };
    let queryString = new URLSearchParams(params).toString();
    if (query_tr_status) {
      queryString = `${queryString}&${query_tr_status}`
    }
    const url = `${TALENT_SALARY_LIST_DOWNLOAD}?${queryString}`;
    DownLoadFile(auth.authtoken, url, "payroll-payment-report")
  }

  const TABLE_HEAD = gridColumnsHead(tabvalue);

  return (
    <Grid container>

      <Grid item md={12} my={2}>

        <PaymentSearchFilter
          DownloadReport={DownloadReport}
          initialFilter={searchParam}
          onSearch={onSearch}
          onSort={onSearch}
          showTalents={true}
          TalentPlaceholder="Select Employee"
          status={SALARY_STATUS}
          start={{
            placeholder: 'Start Month',
            views: ['month'],
            inputFormat: 'MMMM',
          }}
          end={{
            placeholder: 'End Month',
            views: ['month'],
            inputFormat: "MMMM",
          }}
        />

        <EScrollbar>
          {
            paymentList?.payrollNewListByTalentData?.length == 0 && IS_CALLED_WITH_EMPLOYEE_ID == true
            &&
            <SearchNotFound text='Please enter employee name to see their salary payment details.' textColor={theme.palette.chip.secondaryText} />
          }

          {
            paymentList?.payrollNewListByTalentData?.length == 0 && IS_CALLED_WITH_EMPLOYEE_ID == false
            &&
            <SearchNotFound />
          }

          <ETableContainer sx={{ marginTop: '16px' }}>
            <ETable>
              {paymentList?.payrollNewListByTalentData?.length > 0 && (
                <EListHead
                  headLabel={TABLE_HEAD}
                  rowCount={paymentList?.payrollNewListByTalentData?.length}
                  onRequestSort={() => null}
                />
              )}

              {paymentList?.payrollNewListByTalentLoading && (
                <ETableBody>
                  <ETableRow>
                    <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <ECircularProgress color="primary" />
                    </ETableCell>
                  </ETableRow>
                </ETableBody>
              )}


              {paymentList?.payrollNewListByTalentData?.length > 0 && (
                <ETableBody>
                  {paymentList?.payrollNewListByTalentData?.map((row, index) => {
                    const { transaction_date, updated_at, pay_amount, paid_amount, cancelled_amount, transaction_status, balance_amount, payrollData } = row;
                    row = {
                      ...row,
                      SNo: index + 1,
                      PayableAmt: pay_amount ? numberWithCommas(pay_amount) : numberWithCommas(0),
                      PaidAmt: paid_amount ? numberWithCommas(paid_amount) : numberWithCommas(0),
                      BalanceAmt: balance_amount ? numberWithCommas(balance_amount) : numberWithCommas(0),
                      CancelledAmt: cancelled_amount ? numberWithCommas(cancelled_amount) : numberWithCommas(0),
                      UpdatedOn: moment(transaction_date).format('DD/MM/YYYY'),
                      Status: transaction_status
                    }
                    return (
                      <ETableRow
                        sx={{ 'cursor': 'pointer' }}
                        hover key={index} tabIndex={-1}
                      >

                        <ETableCell align="left" sx={{ padding: '10px' }}>
                          {index + 1 + (page * 10)}
                        </ETableCell>

                        <ETableCell align="left" sx={{ padding: '10px', minWidth: '120px' }}>
                          {moment(payrollData?.month_date).format('MMM YYYY')}
                        </ETableCell>


                        <ETableCell style={{ minWidth: '110px' }} align="right">
                          {pay_amount ? numberWithCommas(pay_amount) : numberWithCommas(0)}
                        </ETableCell>

                        <ETableCell style={{ minWidth: '110px' }} align="right">
                          {paid_amount ? numberWithCommas(paid_amount) : numberWithCommas(0)}
                        </ETableCell>

                        <ETableCell style={{ minWidth: '110px' }} align="right">
                          {numberWithCommas(balance_amount)}
                        </ETableCell>

                        <ETableCell style={{ minWidth: '110px' }} align="right">
                          {cancelled_amount ? numberWithCommas(cancelled_amount) : numberWithCommas(0)}
                        </ETableCell>

                        <ETableCell style={{ minWidth: '110px' }} align="right">
                          {moment(updated_at).format('DD/MM/YYYY')}
                        </ETableCell>

                        <ETableCell align="center" style={{ minWidth: '120px' }}>
                          <EChip
                            label={SALARY_PAYMENT_TRANSACTION_STATUS[transaction_status]}
                            width="115px"
                            className={PAYMENT_TRANSACTION_STATUS_CHIP[transaction_status]}
                          />
                        </ETableCell>

                        <ETableCell align="center" style={{ minWidth: '80px' }}>
                          <EBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <EIcon icon={VisibilityIcon} onClick={() => actionEvent(row, "VIEW")} />
                            {
                              [PARTIAL_STATUS, PENDING_STATUS, PARTIALLY_PAID_STATUS].includes(transaction_status)
                              &&
                              <>
                                {
                                  IS_SUPERIOR
                                  &&
                                  <ActionMenu>
                                    {/* {useCheckPermission([PERMISSIONS.SideBarSalaryPayment]) && */}
                                    <EMenuItem onClick={() => actionEvent(row, PAID_STATUS)}>
                                      <ListItemIcon>
                                        <EIcon icon={currencyRupeeIcon} />
                                      </ListItemIcon>
                                      <ListItemText primary="Pay Salary" />
                                    </EMenuItem>
                                    <EMenuItem onClick={() => actionEvent(row, CANCELLED_STATUS)}>
                                      <ListItemIcon>
                                        <EIcon icon={blockIcon} />
                                      </ListItemIcon>
                                      <ListItemText primary="Cancel Salary" />
                                    </EMenuItem>
                                  </ActionMenu>
                                }
                                {
                                  // (useCheckPermission([PERMISSIONS.SideBarSalaryPayment]) || useCheckPermission([PERMISSIONS.ViewAndCancelSalary]))
                                  IS_HR
                                  &&
                                  <ActionMenu>
                                    <EMenuItem onClick={() => actionEvent(row, CANCELLED_STATUS)}>
                                      <ListItemIcon>
                                        <EIcon icon={blockIcon} />
                                      </ListItemIcon>
                                      <ListItemText primary="Cancel Salary" />
                                    </EMenuItem>
                                  </ActionMenu>
                                }

                              </>
                            }
                            {
                              (row?.transaction_status == PAID_STATUS)
                              &&
                              <ActionMenu>

                                <EMenuItem onClick={() => null}>
                                  <ListItemIcon>
                                    <EIcon icon={SimCardIcon} />
                                  </ListItemIcon>
                                  <ListItemText primary="Salary Slip 1" />
                                </EMenuItem>

                                <EMenuItem onClick={() => null}>
                                  <ListItemIcon>
                                    <EIcon icon={AlternateMailIcon} />
                                  </ListItemIcon>
                                  <ListItemText primary="Email Salary Slip 1" />
                                </EMenuItem>

                              </ActionMenu>
                            }

                            {
                              (row?.transaction_status == CANCELLED_STATUS)
                              &&
                              <ActionMenu >

                                <EMenuItem onClick={() => null} >
                                  <ListItemIcon>
                                    <EIcon icon={AlternateMailIcon} />
                                  </ListItemIcon>
                                  <ListItemText primary="Email Details" />
                                </EMenuItem>

                              </ActionMenu>
                            }
                          </EBox>

                        </ETableCell>
                      </ETableRow>
                    );
                  })}
                </ETableBody>
              )}


            </ETable>
          </ETableContainer>
        </EScrollbar>

        {paymentList?.payrollNewListByTalentData?.count == 0 &&
          !paymentList?.payrollNewListByTalentData?.payrollNewListByTalentLoading && (
            <SearchNotFound />
          )}

        {paymentList?.payrollNewListByTalentData?.count > DEFAULT_ROWS_PERPAGE && (
          <Grid container my={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <ETablePagination
              rowsPerPageOptions={DEFAULT_ROWS_PERPAGE}
              component="div"
              count={paymentList?.payrollNewListByTalentData?.count ? paymentList?.payrollNewListByTalentData?.count : 10}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}

        {salaryDueModal && salaryDueModal.modalCategory == PAID_STATUS &&
          <EModal open={(salaryDueModal.modalCategory == PAID_STATUS)}
            headervalue={`Payment - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
          >
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                <PaymentModal
                  paymentData={salaryDueModal.modalData}
                  setSalaryDueModal={setSalaryDueModal}
                  getPaymentFilterData={() => callApi(searchParam, 0, rowsPerPage)}
                  auth={auth}
                />
              </Grid>
            </Grid>
          </EModal >}

        {
          salaryDueModal && salaryDueModal.modalCategory == CANCELLED_STATUS &&
          <EModal open={(salaryDueModal.modalCategory == CANCELLED_STATUS)}
            headervalue={`Cancel Payment - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
          >
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                <PaymentCancelModal
                  paymentData={salaryDueModal.modalData}
                  setSalaryDueModal={setSalaryDueModal}
                  getPaymentFilterData={() => callApi(searchParam, 0, rowsPerPage)}
                  auth={auth}
                />
              </Grid>
            </Grid>
          </EModal>
        }

        {
          salaryDueModal && salaryDueModal.modalCategory == 'VIEW' &&
          <EModal open={(salaryDueModal.modalCategory == 'VIEW')}
            headervalue={`Salary Details - ${moment(salaryDueModal.modalData?.payrollData?.month_date).format('MMMM YYYY')}`}
          >
            <Grid container spacing={2} >
              <Grid item xs={12} sm={12} lg={12} xl={12} className='modal1-buttons-stick-bottom'>
                <SalaryStatusDetalViewModal
                  paymentData={salaryDueModal.modalData}
                  setSalaryDueModal={setSalaryDueModal}
                  auth={auth}
                />
              </Grid>
            </Grid>
          </EModal>
        }


      </Grid>
    </Grid>
  )

};

const gridColumnsHead = (tabvalue) => {
  let tblHead = [
    { id: 'SNo.', label: 'SNo.', alignRight: false },
    { id: 'Month', label: 'Month', alignRight: false },
    { id: 'Payable Amt.', label: 'Payable Amt.', alignRight: true },
    { id: 'Paid Amt.', label: 'Paid Amt.', alignRight: true },
    { id: 'Balance Amt.', label: 'Balance Amt.', alignRight: true },
    { id: 'Cancelled Amt.', label: 'Cancelled Amt.', alignRight: true },
    { id: 'Updated On', label: 'Updated On', alignRight: true },
    { id: 'Status', label: 'Status', alignRight: 'center' },
    { id: 'Action', label: 'Action', alignRight: false },
  ];
  return tblHead;
};

export default SalaryPayrollDetailByTalent;