import io from "socket.io-client";
// import { decodeToken } from "react-jwt";
// import { ONLINE_USERS,NOTIFICATION_COUNT_SUCCESS } from '../constants/index'
import { store } from '../store';
import { SOCKET_API_URL } from "../constants";
import { NotificationList } from "../action/NotificationAction";


// import { NotificationCountView } from "../actions/NotificationNewAction";
let socket;
// const store = configureStore();
// const SOCKET_URL = SOCKET_API_URL;

export const initiateSocket = (token, user) => {
    socket = io.connect(SOCKET_API_URL, {
        transports: ["websocket"],
        query: { 
            companyId: user.company_id, 
            userId: user.id, 
            fullname: user.fullname, 
            role: user.role
        },
    });
    socket.on('connect', function () {
        socket.on("disconnect", (reason) => {
            console.log(`Socket ${reason} `);
        });
    });

    // 
    // socket.on("CHANNEL_JOIN", (data) => {
        // store.dispatch({
        //     type: ONLINE_USERS,
        //     payload: data
        // });
    // });

    // socket.on("NOTIFICATION", (data) => {
    //     console.log(data,'NOTIFICATION socket.js');
    //     // store.dispatch({
    //     //     type: NOTIFICATION_COUNT_SUCCESS,
    //     //     payload: data
    //     // });
    // });
    //============================================================
    socket.on("NOTIFICATION_ALERT", (data) => {
        store.dispatch(NotificationList(token));
    });

};


export const closeSocket = () => {
    if (socket) {
        // console.log("=================");
        // const dToken = decodeToken(token);
        // socket.emit("REMOVE_USER", { companyId: dToken.companyId, userId: dToken.userId });
        socket.close();
    }
};

// export const switchChannel = (prevChannel, channel) => {
//  if (socket) {
//    socket.emit("CHANNEL_SWITCH", { prevChannel, channel });
//  }
// };
// export const subscribeToMessages = (callback) => {
//  if (!socket) {
//    return;
//  }

//  socket.on("NEW_MESSAGE", (data) => {
//    callback(null, data);
//  });
// };

// export const sendMessage = (data) => {
//  if (!socket) {
//    return;
//  }

//  socket.emit("MESSAGE_SEND", data);
// };

// export const fetchChannels = async () => {
//  const response = await axios.get(`${SOCKET_URL}/getChannels`);

//  return response.data.channels;
// };

// export const fetchChannelMessages = async (channel) => {
//  const response = await axios.get(
//    `${SOCKET_URL}/channels/${channel}/messages`
//  );

//  return response.data.allMessages;
// };

// import io from 'socket.io-client'
// var socket = io.connect(process.env.SOCKET_API_URL, {
//     // reconnect: false,
//     // withCredentials: false,
// });

// socket.on('connect',function(){ 
//     // Send ehlo event right after connect:

//     console.log(`Socket ${socket.id} connected!`);

//     socket.on("disconnect", (reason) => {
//         console.log(`Socket ${reason} `);
//     });
// });


// socket.on("connect_error", (err) => {
//     console.log(`connect_error due to ${err.message}`);
// });

// export default socket;
