import React from 'react'
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, Grid, Autocomplete, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import { ABBREVIATION } from '../../constants';
import { ETextField } from '../../BaseComponents/ETextField';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import moment from 'moment';
import { updateAttendanceDetails } from '../../action/PayrollAction';
import { useDispatch } from 'react-redux';
import { EditAttendanceValidation } from '../../utils/validation';
import { EBox } from '../../BaseComponents/EBox';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';

const ModalAttendanceUpdate = (props) => {
    const dispatch = useDispatch();
    const { setIsOpenUpdateAttendanceModal, attUpdateParams, auth, payrollId, isPayroll, isManual, updateManualAttendance ,close} = props;

    const selectedAbb = !isManual && ABBREVIATION ? ABBREVIATION?.find(elm => elm.id === attUpdateParams?.value) : ABBREVIATION?.find(elm => elm.id === attUpdateParams?.value);

    const formik = useFormik({
        initialValues: {
            abbreviation: selectedAbb ? selectedAbb : null,
            final_abbrivation: selectedAbb ? selectedAbb.id : null,
            attendance_date: attUpdateParams.date ? moment(attUpdateParams.date).format('DD/MM/YYYY') : '',
            attendance_remark: '',
            employeeId: attUpdateParams?.empid,
            payrollId: payrollId
        },
        validationSchema: EditAttendanceValidation,
        onSubmit: (data) => {

            if (!isManual) {
                data.attendance_date = attUpdateParams.date;
                dispatch(updateAttendanceDetails(auth.authtoken, isPayroll, data))
                setIsOpenUpdateAttendanceModal(false);
            }
            else {
                attUpdateParams.abbreviation = data.abbreviation;
                updateManualAttendance({ ...attUpdateParams, attendance_remark: data.attendance_remark })
            }
        }
    });

    const handleChangePL = (e) => {
        setFieldValue('final_abbrivation', e.target.value);
    }

    const closeModal = () => {
        if (!isManual) {
            setIsOpenUpdateAttendanceModal(false);
        }
        else {
            close()
        }
    }

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={" Date"} isModal={true} isfullgrid={true} >
                            <ETextField
                                fullWidth
                                inputProps={{ readOnly: true }}
                                id="outlined-name"
                                name="attendance_date"
                                rows={3}
                                {...getFieldProps('attendance_date')}
                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={"Abbreviation"} isModal={true} isfullgrid={true}  >
                            <Autocomplete
                                name="abbreviation"
                                fullWidth
                                options={ABBREVIATION}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={option => option.label || ""}
                                {...getFieldProps('abbreviation')}
                                onChange={(e, value) => {
                                    setFieldValue("abbreviation", value ? value : null)
                                    setFieldValue("final_abbrivation", value?.id == 'PL' ? null : value?.id)
                                }}
                                renderInput={params => (
                                    <ETextField
                                        {...params}
                                        name='abbreviation'
                                        placeholder='Select Abbreviation'
                                        fullWidth
                                        error={Boolean(touched.abbreviation && errors.abbreviation)}
                                        helperText={touched.abbreviation && errors.abbreviation}
                                    />
                                )}
                            />
                        </ELabelInputMainGrid>
                    </Grid>
                    {getFieldProps('abbreviation').value &&
                        getFieldProps('abbreviation').value.id === 'PL' &&

                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={"Type"} alignlabel={'align-item-center'}isModal={true} isfullgrid={true} >
                                <EBox>
                                    <RadioGroup
                                        onChange={handleChangePL}
                                        row
                                        // aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="paid-leave"
                                        sx={{ pt: 1}}
                                    >
                                        <FormControlLabel value="PHD" control={<Radio />} label="Half Day" />
                                        <FormControlLabel value="PL" control={<Radio />} label="Full Day" />
                                    </RadioGroup>
                                    {errors?.final_abbrivation && <FormHelperText sx={{ color: 'red' }}>{errors?.final_abbrivation}</FormHelperText>}
                                </EBox>
                            </ELabelInputMainGrid>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <ELabelInputMainGrid label={"Remark"}  alignlabel={'align-top '} isModal={true} isfullgrid={true}>
                            <ETextField
                                fullWidth
                                multiline
                                id="outlined-name"
                                name="attendance_remark"
                                rows={3}
                                // label="Remark"
                                {...getFieldProps('attendance_remark')}
                                error={Boolean(touched.attendance_remark && errors.attendance_remark)}
                                helperText={touched.attendance_remark && errors.attendance_remark}

                            />
                        </ELabelInputMainGrid>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                            <EButtonOutlined size="large" variant="outlined"
                                onClick={() => closeModal()}
                            > Cancel </EButtonOutlined>
                            <ELoadingButton
                                // loading={holiday?.holidayLoading} 
                                size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    )
}

export default ModalAttendanceUpdate


