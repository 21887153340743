import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import {
  PAYROLL_LIST_FAILURE,
  PAYROLL_LIST_SUCCESS,
  PAYROLL_LIST_LOADING,

  PAYROLL_DETAIL_FAILURE,
  PAYROLL_DETAIL_SUCCESS,
  PAYROLL_DETAIL_LOADING,

  PAYROLL_FAILURE,
  PAYROLL_LOADING,
  PAYROLL_SUCCESS,
  SERVER_URL,
  ATTENDANCE_LIST_FAILURE,
  ATTENDANCE_LIST_SUCCESS,
  ATTENDANCE_LIST_LOADING,
  PAYROLL_NEW_LIST_LOADING,
  PAYROLL_NEW_LIST_SUCCESS,
  PAYROLL_NEW_LIST_FAILURE,
  PAYROLL_NEW_LIST_DETAIL_LOADING,
  PAYROLL_NEW_LIST_DETAIL_SUCCESS,
  PAYROLL_NEW_LIST_DETAIL_FAILURE,
  PAYROLL_NEW_LIST_BY_TALENT_FAILURE,
  PAYROLL_NEW_LIST_BY_TALENT_SUCCESS,
  PAYROLL_NEW_LIST_BY_TALENT_LOADING,
  // ABBREVIATION_LIST_FAILURE,
  // ABBREVIATION_LIST_SUCCESS,
  // ABBREVIATION_LIST_LOADING,

} from '../constants';
import { Logout } from './AuthAction';
import { CompanyDetail } from './CompanyAction';



// ====================================================================================================

export function getPayrollDetail(token, id) {

  return (dispatch) => {
    dispatch(payrollDetailLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payroll/payroll-detail?payroll_id=${id}`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(payrollDetailSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(payrollDetailFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function payrollDetailLoading() {
  return {
    type: PAYROLL_DETAIL_LOADING,
  };
}
export function payrollDetailSuccess(payload) {
  return {
    type: PAYROLL_DETAIL_SUCCESS,
    payload
  };
}
export function payrollDetailFailure(payload) {
  return {
    type: PAYROLL_DETAIL_FAILURE,
    payload
  };
}

// =============================================================================================================


export function getPayrollNewList(filter) {

  return (dispatch, getState) => {
    const state = getState()
    const token = state?.authReducer?.authtoken
    dispatch(payrollNewListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/salary/payroll-list`,
      params: filter,
      headers: {
        "Authorization": 'Bearer ' + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(payrollNewListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(payrollNewListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function payrollNewListLoading() {
  return {
    type: PAYROLL_NEW_LIST_LOADING,
  };
}
export function payrollNewListSuccess(payload) {
  return {
    type: PAYROLL_NEW_LIST_SUCCESS,
    payload
  };
}
export function payrollNewListFailure(payload) {
  return {
    type: PAYROLL_NEW_LIST_FAILURE,
    payload
  };
}

// =============================================================================================================
export function getPayrollNewListByTalent(filter) {

  const queryString = Object.keys(filter).map(key => {
    if(filter[key] == null) return
    if (Array.isArray(filter[key])) {
      return `${key}=${filter[key].join('&transaction_status=')}`;
    }
    return `${key}=${encodeURIComponent(filter[key])}`;
  }).join('&');

  return (dispatch, getState) => {
    const state = getState()
    const token = state?.authReducer?.authtoken
    dispatch(payrollNewListByTalentLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/salary/talent-salary-list?${queryString}`,
      // params: filter,
      headers: {
        "Authorization": 'Bearer ' + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(payrollNewListByTalentSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(payrollNewListByTalentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function payrollNewListByTalentLoading() {
  return {
    type: PAYROLL_NEW_LIST_BY_TALENT_LOADING,
  };
}
export function payrollNewListByTalentSuccess(payload) {
  return {
    type: PAYROLL_NEW_LIST_BY_TALENT_SUCCESS,
    payload
  };
}
export function payrollNewListByTalentFailure(payload) {
  return {
    type: PAYROLL_NEW_LIST_BY_TALENT_FAILURE,
    payload
  };
}

// =============================================================================================================

export function getPayrollNewDetailList(filter) {

  const queryString = Object.keys(filter).map(key => {
    if(filter[key] == null) return
    if (Array.isArray(filter[key])) {
      return `${key}=${filter[key].join('&transaction_status=')}`;
    }
    return `${key}=${encodeURIComponent(filter[key])}`;
  }).join('&');


  return (dispatch, getState) => {
    const state = getState()
    const token = state?.authReducer?.authtoken
    dispatch(payrollNewDetailListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/salary/payroll-detail-list?${queryString}`,
      // params: filter,
      headers: {
        "Authorization": 'Bearer ' + token,
      },

    })
      .then((res) => {
        if (res.status === 200) {

          dispatch(payrollNewDetailListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(payrollNewDetailListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function payrollNewDetailListLoading() {
  return {
    type: PAYROLL_NEW_LIST_DETAIL_LOADING,
  };
}
export function payrollNewDetailListSuccess(payload) {
  return {
    type: PAYROLL_NEW_LIST_DETAIL_SUCCESS,
    payload
  };
}
export function payrollNewDetailListFailure(payload) {
  return {
    type: PAYROLL_NEW_LIST_DETAIL_FAILURE,
    payload
  };
}

// =============================================================================================================



export function payrollLoading() {
  return {
    type: PAYROLL_LOADING,
  };
}
export function payrollSuccess(payload) {
  return {
    type: PAYROLL_SUCCESS,
    payload
  };
}
export function payrollFailure(payload) {
  return {
    type: PAYROLL_FAILURE,
    payload
  };
}


export function PayrollList(token, search = {}, page = null, pageSize = null) {

  let queryString = "";

  if (page !== null && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}`;
  }

  if (search && Object.keys(search).length > 0) {
    if (search.is_payroll) {
      queryString += `&is_payroll=${search.is_payroll}`;
    }

  }
  return (dispatch) => {

    dispatch(payrollListLoading());

    axios({
      method: 'get',
      url: `${SERVER_URL}/payroll/payroll-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(payrollListSuccess(res.data))
        }
      }).catch((error) => {

        if (error?.response) {
          dispatch(payrollListFailure(error.response.data));
          // if (error?.response?.status == 401) {
          // }
          // above if block was blank
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function payrollListLoading() {
  return {
    type: PAYROLL_LIST_LOADING,
  };
}
export function payrollListSuccess(payload) {

  return {
    type: PAYROLL_LIST_SUCCESS,
    payload
  };
}

export function payrollListFailure(payload) {
  return {
    type: PAYROLL_LIST_FAILURE,
    payload
  };
}


export function AttendanceList(token, payroll_id, is_payroll, Data = null) {

  let queryString = `payroll_id=${payroll_id}&is_payroll=${is_payroll}`;

  // if (payroll_id !== null) {
  //   queryString += `payroll_id=${payroll_id}`;
  // }

  if (Data !== null && Data.empcode != null && Data.empcode != '') {
    queryString += `&employee_code=${Data.empcode}`;
  }

  if (Data !== null && Data.empname != null && Data.empname != '') {
    queryString += `&fullname=${Data.empname}`;
  }

  if (Data !== null && Data.department != null && Data.department != '') {
    queryString += `&department_id=${Data.department.id}`;
  }

  if (Data !== null && Data.designation != null && Data.designation != '') {
    queryString += `&designation_id=${Data.designation.id}`;
  }

  if (Data !== null && Data.sort != null && Data.sort != '') {
    queryString += `&sort=${Data.sort.id}`;
  }

  return (dispatch) => {
    dispatch(attendanceListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/payroll/get-attendance?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(attendanceListSuccess(res.data))
        }
      }).catch((error) => {

        if (error?.response) {
          dispatch(attendanceListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
        }
      });
  };
}



export function attendanceListLoading() {
  return {
    type: ATTENDANCE_LIST_LOADING,
  };
}
export function attendanceListSuccess(payload) {

  return {
    type: ATTENDANCE_LIST_SUCCESS,
    payload
  };
}

export function attendanceListFailure(payload) {
  return {
    type: ATTENDANCE_LIST_FAILURE,
    payload
  };
}


export function updateAttendanceDetails(token, is_payroll, Data) {

  const data = new FormData();
  data.append('attendance_date', Data.attendance_date)
  data.append('employee_id', Data.employeeId)
  data.append('abbreviation', Data.final_abbrivation)
  data.append('remark', Data.attendance_remark)
  return (dispatch) => {
    // dispatch(updateAttendanceLoading()); 
    axios({
      method: 'post',
      url: `${SERVER_URL}/attendance/employee-attendance-update`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(AttendanceList(token, Data.payrollId, is_payroll))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(updateAttendanceFailure(error.response.data)); 
          dispatch(AttendanceList(token, Data.monthPayroll?.id, is_payroll))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}



export function LockedPayroll(token, payroll_id, company_id, is_payroll) {

  const data = new FormData();
  data.append('payroll_id', payroll_id);
  data.append('is_payroll', is_payroll)

  return (dispatch) => {
    // dispatch(lockPayrollLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/payroll/lock-payroll`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(getPayrollDetail(token, payroll_id))
          dispatch(AttendanceList(token, payroll_id, is_payroll))
          company_id && dispatch(CompanyDetail(token, company_id));
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}
/**
 * [2022-08-18]
 * Author:- Aanchal Sahu
 * Description:- made this component for updating Advance Deduction in payroll
 **/
export function updateAdvanceDeductionAction(token, is_payroll, Data) {

  const data = new FormData();
  data.append('payroll_id', Data.payroll_id)
  data.append('collect_amount', Data.amount)
  data.append('employee_id', Data.employee_id)
  data.append('advance_return_id', Data.advance_return_id)

  return (dispatch) => {
    axios({
      method: 'post',
      url: `${SERVER_URL}/payment/advance-repayment`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(AttendanceList(token, Data.payroll_id, is_payroll))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(AttendanceList(token, Data.payroll_id, is_payroll))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}



// INFO: This API is for updating Manual Attendance
export function ManualAttendanceDetails(token, is_payroll, Data, payroll_id) {

  const data = new FormData();
  let manual_attendance = JSON.stringify(Data)
  data.append('data', manual_attendance)

  return (dispatch) => {
    // dispatch(updateAttendanceLoading());// this is commented, took reference from the API updateAttendanceDetails
    axios({
      method: 'post',
      url: `${SERVER_URL}/attendance/add-manual-attendance`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(AttendanceList(token, payroll_id, false))
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          // dispatch(updateAttendanceFailure(error.response.data));// this is commented, took reference from the API updateAttendanceDetails
          dispatch(AttendanceList(token, payroll_id, false))
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}