import { memo } from "react";
import PropTypes from 'prop-types';
import { Grid, useTheme } from "@mui/material";
import { ECard } from "./ECard";
import { EDivider } from "./EDivider";
import { ETypography, ETypographyCardHeading, } from "./ETypography";
import editFill from "@iconify/icons-material-symbols/edit-outline-rounded";
import { EIcon } from "./EIcon";
import { EIconButton } from "./EButtons";
import { Link } from "react-router-dom";

export const ECustomCard = memo(({ children, title = '', subTitle = "", hideDivider = false, dividerColor = '#D0D0D0', rightComponent, onEdit = () => { }, hideEdit = false, href = "" }) => {

  const theme = useTheme()

  return (
    <ECard
      className='card_design_1'

      sx={{ padding: { lg: '18px 24px !important', xs: '18px 24px !important' }, borderColor: `${theme.palette.border.border6} !important` }}
    >
      <Grid
        className="mb-8px"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <ETypographyCardHeading variant='h5'>
            {title}
          </ETypographyCardHeading>
          {
            !rightComponent
              ?
              !hideEdit &&
              <Link to={href}>
                <EIconButton
                  sx={{ p: 0, m: 0, textAlign: "right", color: "dark.0" }}
                  onClick={onEdit}
                >
                  <EIcon icon={editFill} width={23} height={23} />
                </EIconButton>
              </Link>
              :
              rightComponent
          }
        </Grid>
        {
          subTitle !== ""
          &&
          <ETypography
            sx={{
              color: theme.palette.text.greyColor4,
              fontSize: {
                lg: '16px !important',
                xs: '14px !important'
              }
            }}
          >
            {subTitle}
          </ETypography>
        }
      </Grid>

      {
        !hideDivider
        &&
        <EDivider className="mb-16px" sx={{ ...(dividerColor && { borderColor: dividerColor }) }} />
      }

      {children}
    </ECard>
  )

});

ECustomCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  dividerColor: PropTypes.string,
  rightComponent: PropTypes.node,
  hideDivider: PropTypes.bool,
};
