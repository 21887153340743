import React from 'react'
import { ETypography } from '../../BaseComponents/ETypography'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { EBox } from '../../BaseComponents/EBox'
import { EButtonOutlined } from '../../BaseComponents/EButtons'
import { getInitials } from '../../utils/CommonFunctions'
import moment from 'moment'

const ModalList = ({modalData, type, label}) => {

    if(type==1){
        return (
            <>
                <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
        
                    {modalData?.map((row)=>{
                        const BDate = moment(row?.date_of_birth)
                        const formattedDate = BDate.format('DD');
                        const formattedMonth = BDate.format('MMM');
                        return(
                            <ListItem sx={{paddingLeft:'0 !important', paddingRight:'0 !important'}}>
                            <ListItemAvatar>
                                <Avatar>
                                    {row?.employee_image == null || row?.employee_image == '' || row.employee_image==undefined?
                                        <span className="font-size-20px theme-color-static">{getInitials(row.employee_name)}</span>:
                                        <img alt='' width={"75px"} src={row.employee_image} className='cursor-pointer'/>
                                    }
                                </Avatar>
                            </ListItemAvatar>
            
                            <ListItemText 
                                primary={row.employee_name}
                                primaryTypographyProps={{fontWeight:'500', fontSize:'16px', lineHeight:'22.4px'}}
                                secondary={row.employee_designation}
                                secondaryTypographyProps={{fontWeight:'400', fontSize:'12px', lineHeight:'16.8px'}}
                                />
            
                            <EBox sx={{ marginLeft: 'auto' }}>
            
                            <EBox
                                sx={{
                                width: '51px',
                                height: '16px',
                                backgroundColor: '#D97904',
                                border:'1px solid #403F3F',
                                borderBottom:0,
                                borderTopLeftRadius:'2px',
                                borderTopRightRadius:'2px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '4px',
                                }}
                            >
            
                                <ETypography variant="body2" sx={{ color: 'white', fontWeight: 500,
                                            fontSize: '10px',
                                            lineHeight: '140%'}}>
                                {formattedMonth}
                                </ETypography>      
            
                            </EBox>
            
                            <EBox
                                sx={{
                                width: '51px',
                                height: '36px',
                                border:'1px solid #403F3F',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                }}
            >
                                <ETypography variant="body1" sx={{fontWeight: 600,
                                        fontSize: '24px',
                                        lineHeight: '140%'}}>
                                {formattedDate}
                                </ETypography>
                            </EBox>
                            </EBox>
            
                        </ListItem>
                        )
        
                    })}
        
                </List>
            </>
          )
    }

    if(type==2){
        return (
            <>
                <List sx={{ width: '100%', bgcolor: 'background.paper'}}>
        
                    {modalData?.map((row)=>{
                        const BDate = moment(row?.work_anniversary_date)
                        const formattedDate = BDate.format('DD');
                        const formattedMonth = BDate.format('MMM');
                        return(
                            <ListItem sx={{paddingLeft:'0 !important', paddingRight:'0 !important'}}>
                            <ListItemAvatar>
                                <Avatar>
                                    {row?.employee_image == null || row?.employee_image == '' || row.employee_image==undefined?
                                        <span className="font-size-20px theme-color-static">{getInitials(row.employee_name)}</span>:
                                        <img alt='' width={"75px"} src={row.employee_image} className='cursor-pointer'/>
                                    }
                                </Avatar>
                            </ListItemAvatar>
            
                            <ListItemText 
                                primary={row.employee_name}
                                primaryTypographyProps={{fontWeight:'500', fontSize:'16px', lineHeight:'22.4px'}}
                                secondary={row.employee_designation}
                                secondaryTypographyProps={{fontWeight:'400', fontSize:'12px', lineHeight:'16.8px'}}
                                />
            
                            <EBox sx={{ marginLeft: 'auto' }}>
            
                            <EBox
                                sx={{
                                width: '51px',
                                height: '16px',
                                backgroundColor: '#D97904',
                                border:'1px solid #403F3F',
                                borderBottom:0,
                                borderTopLeftRadius:'2px',
                                borderTopRightRadius:'2px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '4px',
                                }}
                            >
            
                                <ETypography variant="body2" sx={{ color: 'white', fontWeight: 500,
                                            fontSize: '10px',
                                            lineHeight: '140%'}}>
                                {formattedMonth}
                                </ETypography>      
            
                            </EBox>
            
                            <EBox
                                sx={{
                                width: '51px',
                                height: '36px',
                                border:'1px solid #403F3F',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                }}
            >
                                <ETypography variant="body1" sx={{fontWeight: 600,
                                        fontSize: '24px',
                                        lineHeight: '140%'}}>
                                {formattedDate}
                                </ETypography>
                            </EBox>
                            </EBox>
            
                        </ListItem>
                        )
        
                    })}
        
                </List>
            </>
          )
    }

}

export const CancelModal = ({open, setOpen}) => {
    return(
        <ListItem className='p0 m0' sx={{paddingLeft:'0 !important', paddingRight:'0 !important'}}>
            <EBox sx={{ marginLeft: 'auto'}}>
                    <EButtonOutlined variant='outlined' size='large' onClick={()=>{setOpen(!open)}}> Cancel</EButtonOutlined>
            </EBox>
        </ListItem>
    )

}

export default ModalList