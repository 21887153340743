import axios from 'axios';
import moment from 'moment';
import { EMP_ATTENDANCE_FAILURE, EMP_ATTENDANCE_SUCCESS, EMP_ATTENDANCE_LOADING, EMP_ATTENDANCE_EMPTY, SERVER_URL, REPORT_BY_TIME, APPRAISAL_REPORT_LIST_SUCCESS, APPRAISAL_REPORT_LIST_LOADING, APPRAISAL_REPORT_LIST_FAILURE, COST_TO_COMPANY_LIST_LOADING, COST_TO_COMPANYT_LIST_SUCCESS, COST_TO_COMPANY_LIST_FAILURE, COST_TO_COMPANY_DETAIL_LOADING, COST_TO_COMPANY_DETAIL_SUCCESS, COST_TO_COMPANY_DETAIL_FAILURE, REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING, REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS, REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE} from '../constants';
import { Logout } from './AuthAction';
import { axiosClient } from '../utils/AxiosClient';
import { Errors } from '../BaseComponents/EToast';


/**
 * [2022-09-22]
 * Created by:- Aanchal Sahu
 * Description:- designed this action for attendance report by date and by talents
 **/
export function UserAttendanceReportLog(token, data, page=null,rowsPerPage=null) {
    let params = ``;
    let url = data?.report_type==REPORT_BY_TIME?`user-attendance-by-time`:`user-attendance-log`;
    const AndCondition=(params==``?'&':'')
    if (page !=null  && rowsPerPage != null ) {
        params += `page=${page}&per_page=${rowsPerPage}`;
    }
    
    if (data?.attendance_month && data?.attendance_month!=null && data?.report_type!=REPORT_BY_TIME) {//__CHECK_____
        if(data?.sort != null){
            params += AndCondition+`sort=${data?.sort}`
        }
        params += AndCondition+`report_type=BYNAME`
        params += AndCondition+`attendance_month=${moment(data?.attendance_month).format('YYYYMM')}`;
    }
    if (data?.attendance_date && data?.attendance_date!=null ) {
        params += AndCondition+`report_type=BYDATE`
        if(data?.sort != null){
            params += AndCondition+`sort=${data?.sort}`
        }
        params += AndCondition+`attendance_date=${data?.attendance_date}`;
    }
    if (data?.employee_id && data?.employee_id != null && data?.report_type!=REPORT_BY_TIME) {//__CHECK_____
        params += AndCondition+`employee_id=${data?.employee_id}`
    }
    if (data?.report_type==REPORT_BY_TIME && data?.attendance_time && data?.attendance_time != null ) {
        params += AndCondition+`attendance_time=${moment(data?.attendance_time).format('HH:mm')}`
        params += AndCondition+`sort=${data?.sort}`
        if(data?.start_date){params += AndCondition+`from_date=${moment(data?.start_date).format('YYYY-MM-DD')}`}
        if(data?.end_date){params += AndCondition+`to_date=${moment(data?.end_date).format('YYYY-MM-DD')}`}
    }

    return (dispatch) => {
        dispatch(attendanceListLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/report/${url}?${params}`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(attendanceListSuccess(res.data))
                }
            }).catch((error) => {

                if (error?.response) {
                    dispatch(attendanceListFailure(error.response.data));
                    if (error?.response?.status == 401) {
                      dispatch(Logout())
                    }
                }
            });
    };
}
export function attendanceListLoading() {
    return {
        type: EMP_ATTENDANCE_LOADING,
    };
}
export function attendanceListSuccess(payload) {
    return {
        type: EMP_ATTENDANCE_SUCCESS,
        payload
    };
}
export function attendanceListEmpty() {
    
    return {
        type: EMP_ATTENDANCE_EMPTY
    };
}

export function attendanceListFailure(payload) {
    return {
        type: EMP_ATTENDANCE_FAILURE,
        payload
    };
}

export function UserAppraisalReportLog(data) {
    let params = {...data, sort:data.sort == 'DESC' ? true : false}
    return async (dispatch) => {
        try {
          dispatch(getAppraisalListLoading())
          const res = await axiosClient({
            url: `report/appraisal-report`,
            params,
          })
          if (res.status === 200) {
            dispatch(getAppraisalListSuccess(res.data));
          }
        } catch (error) {
          if (error?.response) {
            dispatch(getAppraisalListFailure(error.response.data));
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        }
      };
}

export function getAppraisalListLoading() {
    return {
      type: APPRAISAL_REPORT_LIST_LOADING,
    };
  }
  
  export function getAppraisalListSuccess(payload) {
    return {
      type: APPRAISAL_REPORT_LIST_SUCCESS,
      payload: payload
    };
  }
  
  export function getAppraisalListFailure(payload) {
    return {
      type: APPRAISAL_REPORT_LIST_FAILURE,
      payload
    };
  }
  

  export function CostToCompanyReportLog(data) {
    let params = {...data, sort:data.sort == 'DESC' ? false : true}
    return async (dispatch) => {
        try {
          dispatch(getCostToCompanylListLoading())
          const res = await axiosClient({
            url: `report/cost-to-company-report`,
            params,
          })
          if (res.status === 200) {
            dispatch(geCostToCompanyListSuccess(res.data));
          }
        } catch (error) {
          if (error?.response) {
            dispatch(getCostToCompanyListFailure(error.response.data));
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        }
      };
}

export function getCostToCompanylListLoading() {
  return {
    type: COST_TO_COMPANY_LIST_LOADING,
  };
}

export function geCostToCompanyListSuccess(payload) {
  return {
    type: COST_TO_COMPANYT_LIST_SUCCESS,
    payload: payload
  };
}

export function getCostToCompanyListFailure(payload) {
  return {
    type: COST_TO_COMPANY_LIST_FAILURE,
    payload
  };
}


export function CostToCompanyDetailByMonth(data) {
  let params = {...data, sort:data.sort == 'DESC' ? true : false}
  return async (dispatch) => {
      try {
        dispatch(getCostToCompanylListLoading())
        const res = await axiosClient({
          url: `report/cost-to-company-report-bymonth`,
          params,
        })
        if (res.status === 200) {
          dispatch(geCostToCompanyListSuccess(res.data));
        }
      } catch (error) {
        if (error?.response) {
          dispatch(getCostToCompanyListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      }
    };
}

export function getCostToCompanylDetailLoading() {
  return {
    type: COST_TO_COMPANY_DETAIL_LOADING,
  };
}

export function geCostToCompanyDetailSuccess(payload) {
  return {
    type: COST_TO_COMPANY_DETAIL_SUCCESS,
    payload: payload
  };
}

export function getCostToCompanylDetailFailure(payload) {
  return {
    type: COST_TO_COMPANY_DETAIL_FAILURE,
    payload
  };
  
}


// ==========================================================================================
/**
 * Action to get CheckInOUt Report
 */

export function getCheckInOUtReport(filter) {
  return (dispatch, getState) => {
    const state = getState()
    const token = state?.authReducer?.authtoken
    dispatch(reportByCheckInOutLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/report/attendance-log-report/by-check-in-out`,
      crossDomain: true,
      params: filter,
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(reportByCheckInOutSuccess(res.data));
        }
      })
      .catch((error) => {
        if (error?.response) {
          dispatch(reportByCheckInOutFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function reportByCheckInOutLoading() {
  return {
    type: REPORT_BY_CHECK_IN_OUT_DETAIL_LOADING,
  };
}
export function reportByCheckInOutSuccess(payload) {
  return {
    type: REPORT_BY_CHECK_IN_OUT_DETAIL_SUCCESS,
    payload
  };
}
export function reportByCheckInOutFailure(payload) {
  return {
    type: REPORT_BY_CHECK_IN_OUT_DETAIL_FAILURE,
    payload
  };
}
// ==========================================================================================