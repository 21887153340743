import { useSelector } from 'react-redux';

/**
 * This hook checks if the given permissions are allowed for the logged-in user.
 * @param {Array<String>} permissions Array of permission strings to check
 * @returns {boolean} Returns true if the given permissions are allowed for the logged-in user; otherwise, returns false
 */
export const useCheckPermission = (permissions = []) => {

  const { authData } = useSelector(state => state.authReducer);

  const { permission: _permissions } = authData

  return _permissions.filter(i => permissions.includes(i)).length > 0
}
