import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns as TimePickerAdapter } from '@mui/x-date-pickersV6/AdapterDateFns'
import { LocalizationProvider as TimePickerProvider } from '@mui/x-date-pickersV6/LocalizationProvider';
import { Box, Checkbox, Grid, useTheme } from '@mui/material';
import SortByAlphaIcon from '@iconify/icons-material-symbols/sort-by-alpha';
import SortRounded from '@iconify/icons-material-symbols/sort-rounded';
import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import SearchIcon from '@iconify/icons-material-symbols/search-rounded';
import { CheckBox, InsertDriveFile, PictureAsPdf, CheckBoxOutlineBlank } from '@mui/icons-material';
import CancelIcon from '@iconify/icons-material-symbols/cancel-outline-rounded';

import { EButtonOutlinedIcon } from '../../BaseComponents/EButtons';
import { ECardBorderLess } from '../../BaseComponents/ECard';
import { EDatePicker, ETimePicker } from '../../BaseComponents/EDatePicker';
import { EInputAdornment, ETextFieldSearch } from '../../BaseComponents/ETextField';
import { EAutocomplete } from '../../BaseComponents/EAutocomplete';
import { ACTIVE_USER, DOWNLOAD_CSV, DOWNLOAD_PDF } from '../../constants';
import { EIcon, EIconInputAdornment } from '../../BaseComponents/EIcon';
import { ETooltip } from '../../BaseComponents/ETooltip';
import EMenuPopover, { EMenuItem } from '../../BaseComponents/EMenuPopover';
import { ETypography } from '../../BaseComponents/ETypography';
import { useEffect } from 'react';
import { EmployeeListData } from '../../action/EmployeeAction';
import { onKeyDown } from '../../utils/CommonFunctions';


const icon = <CheckBoxOutlineBlank fontSize="small" />
const checkedIcon = <CheckBox fontSize="small" />

/**
 * Component for showing filter in payment modules.
 *
 * @component
 * @example
 * const filter = {
 *  start: '2023',
 *  end: '10',
 *  employee: { id: 1, label: 'Jon Doe'},
 *  particular: { id: 1, label: 'Advance'},
 *  status: { id: 1, label: 'Pending'},
 *  sort: true,
 * }
 * const onSearch = (filterValue: filter) => {}
 * const onSort = (filterValue: filter) => {}
 * const DownloadReport = (downloadType: string = "PDF" || "CSV") => {}
 * const showTalents:boolean = false
 * const particulars = [{ id: 1, label: "One" }, { id: 2, label: "Two" },]
 * const status = [{ id: 1, label: "One" }, { id: 2, label: "Two" },]
 * return (
 *  <PaymentSearchFilter
      initialFilter={filter}
      onSearch={onSearch}
      onSort={onSort}
      DownloadReport={DownloadReport}
      showTalents={showTalents}
      particulars={particulars}
      status={status}
      start={{
        placeholder: 'All Year',
        views: ['year'],
        inputFormat: 'yyyy',
      }}
      end={{
        placeholder: 'All Month',
        views: ['month'],
        inputFormat: "MMMM",
      }}
      particularsOrder={3}
      statusOrder={4}
      talentOrder={5}
      sortTitle="Sort title here"
    />
 * )
 */
export function PaymentSearchFilter(
  {
    DownloadReport = () => { },
    onSearch = () => { },
    onSort = () => { },
    sortTitle = "Sort",
    showTalents = false,
    showAllTalents = false,
    talentList = [],
    withTalentList = false,
    particulars = [],
    particularPlaceholder = 'All Particulars',
    status = [],
    isMultipleStatus = true,
    start = {},
    end = {},
    startTime = {},
    endTime = {},
    initialFilter = {},
    talentOrder = 1,
    statusOrder = 2,
    particularsOrder = 5,
    yearListOrder = 6,
    TalentPlaceholder = 'All Employees',
    withoutDateValidation = false,
    withoutTimeValidation = false,
    extraRightComponent = null,
    yearList = [],
    yearPlaceholder = "All Years"
  }
) {
  const [filter, setFilter] = useState(initialFilter)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme()
  const auth = useSelector((state) => state.authReducer)
  const employeeList = useSelector((state) => state.EmployeeReducer);

  const dispatch = useDispatch()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  useEffect(() => {
    if (auth?.authtoken && showTalents && !withTalentList) {
      dispatch(EmployeeListData(auth?.authtoken, "null"));
    }
  }, [auth?.authtoken])

  useEffect(() => {
    setFilter(initialFilter)
  }, [initialFilter])

  return (
    <ECardBorderLess>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        {
          showTalents &&
          <Grid order={talentOrder || 1} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <EAutocomplete
              name="talent"
              label="Employee Name"
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={talentList.length > 0 ? talentList : employeeList && employeeList?.employeeData?.count > 0 ? employeeList?.employeeData?.rows : []}
              getOptionLabel={option => option.label || ''}
              value={filter?.employee}
              onChange={(e, value) => {
                setFilter(prev => ({
                  ...prev,
                  employee: value
                }));
              }}
              //renderOption is for managing unique keys in the dropdown list
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.label}
                </Box>
              )}
              renderInput={params => <ETextFieldSearch {...params} name="talent" size="small" placeholder={TalentPlaceholder} fullWidth />}
            />
          </Grid>
        }
        {
          showAllTalents &&
          <Grid order={talentOrder || 1} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <EAutocomplete
              name="talent"
              label="Employee Name"
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={employeeList && employeeList?.allEmployeeData?.count > 0 ? employeeList?.allEmployeeData?.rows : []}
              getOptionLabel={option => option.label || ''}
              value={filter?.employee}
              onChange={(e, value) => {
                setFilter(prev => ({
                  ...prev,
                  employee: value
                }));
              }}
              //renderOption is for managing unique keys in the dropdown list
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                  {option.label}
                </Box>
              )}
              renderInput={params => <ETextFieldSearch {...params} name="talent" size="small" placeholder="All Employees" fullWidth />}
            />
          </Grid>
        }
        {
          status.length > 0 &&
          <Grid order={statusOrder || 2} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <EAutocomplete
              name="status"
              options={status}
              value={filter?.status || []}
              onChange={(e, value) => {
                setFilter(prev => ({
                  ...prev,
                  status: value
                }));
              }}
              fullWidth
              disableCloseOnSelect
              multiple={isMultipleStatus}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {
                    isMultipleStatus &&
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  }
                  {option.label}
                </li>
              )}
              renderTags={(selected) => {
                return (
                  <ETypography
                    className="font-size-14px"
                    as='span'
                    sx={{
                      zIndex: 1,
                      display: 'flex',
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selected?.map(i => i?.label)?.join(", ")}
                  </ETypography>
                )
              }}
              renderInput={params => <ETextFieldSearch {...params} name="status" fullWidth placeholder={filter?.status?.length > 0 ? null : "All Status"} size="small" />}
              sx={{
                overflow: 'hidden',
                '& .MuiInputBase-root': {
                  flexWrap: 'nowrap',
                  // overflow: 'hidden',
                },
              }}
            />
          </Grid>
        }
        {
          Object.keys(start).length > 0
          &&
          <Grid order={start?.order || 3} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <EDatePicker
                inputFormat="dd/MM/yyyy"
                disableMaskedInput
                {...start}
                maxDate={(filter.end && !withoutDateValidation) ? new Date(filter.end) : start?.maxDate || new Date()}
                value={filter.start ? new Date(filter.start) : null}
                onChange={(newValue) => {
                  setFilter(prev => ({
                    ...prev,
                    start: newValue ? newValue : null
                  }));
                }}
                clearable
                renderInput={({ inputProps, ...params }) => (
                  <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                    <ETextFieldSearch
                      size='small'
                      fullWidth
                      {...params}
                      error={start?.error}
                      helperText={start?.helperText}
                      inputProps={{
                        ...inputProps,
                        placeholder: start?.placeholder || 'Start Date'
                      }}
                    />
                    {filter.start &&
                      <EInputAdornment
                        position='end'
                        onClick={() => {
                          setFilter(prev => ({
                            ...prev,
                            start: null
                          }));
                        }}
                        className='clear-icon'
                      >
                        <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                      </EInputAdornment>
                    }
                  </div>
                )}
              />
            </LocalizationProvider>
          </Grid>
        }
        {

          Object.keys(end).length > 0
          &&
          <Grid order={end?.order || 4} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <EDatePicker
                disableMaskedInput
                inputFormat="dd/MM/yyyy"
                {...end}
                minDate={(filter.start && !withoutDateValidation) ? new Date(filter.start) : end?.minDate}
                value={filter.end ? new Date(filter.end) : null}
                onChange={(newValue) => {
                  setFilter(prev => ({
                    ...prev,
                    end: newValue ? newValue : null
                  }));
                }}
                renderInput={({ inputProps, ...params }) => (
                  <div className='date-picker-div'>{/*this div contains text field and clear icon*/}
                    <ETextFieldSearch
                      size='small'
                      fullWidth
                      {...params}
                      error={end?.error}
                      helperText={end?.helperText}
                      inputProps={{
                        ...inputProps,
                        placeholder: end?.placeholder || 'End Date'
                      }}
                    />
                    {filter.end &&
                      <EInputAdornment
                        position='end'
                        onClick={() => {
                          setFilter(prev => ({
                            ...prev,
                            end: null
                          }));
                        }}
                        className='clear-icon'
                      >
                        <EIconInputAdornment icon={CancelIcon} color='primary.main' />
                      </EInputAdornment>
                    }
                  </div>
                )}
              />
            </LocalizationProvider>
          </Grid>
        }
        {
          Object.keys(startTime).length > 0
          &&
          <Grid order={startTime?.order || 5} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <TimePickerProvider dateAdapter={TimePickerAdapter}>
              <ETimePicker
                ampm={false}
                {...startTime}
                maxTime={(filter.endTime && !withoutTimeValidation) ? new Date(filter.endTime) : startTime?.maxTime || null}
                value={filter.startTime ? new Date(filter.startTime) : null}
                onChange={(newValue) => {
                  setFilter(prev => ({
                    ...prev,
                    startTime: newValue ? newValue : null
                  }))
                }}
                textField={(props) => <ETextFieldSearch {...props} />}
                slotProps={{
                  textField: {
                    size: 'small',
                    onKeyDown: onKeyDown,
                  }
                }}
              />
            </TimePickerProvider>
          </Grid>
        }
        {

          Object.keys(endTime).length > 0
          &&
          <Grid order={endTime?.order || 6} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <TimePickerProvider dateAdapter={TimePickerAdapter}>
              <ETimePicker
                ampm={false}
                {...endTime}
                minTime={(filter.startTime && !withoutTimeValidation) ? new Date(filter.startTime) : endTime?.minTime || null}
                value={filter.endTime ? new Date(filter.endTime) : null}
                onChange={(newValue) => {
                  setFilter(prev => ({
                    ...prev,
                    endTime: newValue ? newValue : null
                  }));
                }}
                textField={(props) => <ETextFieldSearch {...props} />}
                slotProps={{
                  textField: {
                    size: 'small',
                    onKeyDown: onKeyDown,
                  }
                }}
              />
            </TimePickerProvider>
          </Grid>
        }
        {
          particulars.length > 0 &&
          <Grid order={particularsOrder || 5} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <EAutocomplete
              name="particular"
              options={particulars}
              value={filter?.particular}
              onChange={(e, value) => {
                setFilter(prev => ({
                  ...prev,
                  particular: value ? value : null
                }));
              }}
              fullWidth
              renderInput={params => <ETextFieldSearch {...params} name="particular" fullWidth placeholder={particularPlaceholder} size="small" />}
            />
          </Grid>
        }

        {
          yearList.length > 0 &&
          <Grid order={yearListOrder || 6} item xs={6} sm={6} md={4} lg={3} xl={3}>
            <EAutocomplete
              name="year"
              options={yearList}
              value={filter?.year}
              onChange={(e, value) => {
                setFilter(prev => ({
                  ...prev,
                  year: value ? value : null
                }));
              }}
              getOptionLabel={option => option.label || ''}
              fullWidth
              renderInput={params => <ETextFieldSearch {...params} name="year" fullWidth placeholder={yearPlaceholder} size="small" />}
            />
          </Grid>
        }
        <Grid order={99} item xs={6} sm={6} md={4} lg={3} xl={3} ml={"auto"} display="flex" gap={2} justifyContent="flex-end">
          {
            /**
             * Search Button
             */
          }
          <ETooltip title={'Search'} arrow>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => {
                onSearch(filter)
              }}
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '47px',
                  md: '55px'
                }
              }}
            >
              <EIcon icon={SearchIcon} color={theme.palette.primary.main} className='height-width-15px' />
            </EButtonOutlinedIcon>
          </ETooltip>

          {
            /**
             * Download Button
             */
          }
          <ETooltip title={'Download'} arrow>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={handleClick}
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '47px',
                  md: '55px'
                }
              }}>
              <EIcon icon={FileDownloadIcon} color={theme.palette.primary.main} className='height-width-15px' />
            </EButtonOutlinedIcon>
          </ETooltip>
          {
            /**
             * Download Option EMenuPopover
             */
          }
          <EMenuPopover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            sx={{
              width: 180,
              top: 40
            }}
          >
            <EMenuItem
              sx={{
                typography: 'body2',
                py: 1,
                px: 2.5
              }}
              onClick={() => DownloadReport(DOWNLOAD_PDF)}
            >
              <Box
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              >
                <PictureAsPdf />
              </Box>
              PDF
            </EMenuItem>
            <EMenuItem
              sx={{
                typography: 'body2',
                py: 1,
                px: 2.5
              }}
              onClick={() => DownloadReport(DOWNLOAD_CSV)}
            >
              <Box
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              >
                <InsertDriveFile />
              </Box>
              CSV
            </EMenuItem>
          </EMenuPopover>

          {
            /**
             * Sort Button
             */
          }
          <ETooltip title={sortTitle} arrow>
            <EButtonOutlinedIcon
              variant="outlined"
              onClick={() => {
                const updatedFilter = {
                  ...filter,
                  sort: !filter?.sort
                }
                setFilter(updatedFilter)
                onSort(updatedFilter)
              }}
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '47px',
                  md: '55px'
                },
                transform: filter.sort && 'rotateX(180deg)'
              }}>
              <EIcon icon={SortRounded} color={theme.palette.primary.main} className='height-width-15px' />
            </EButtonOutlinedIcon>
          </ETooltip>
          {
            extraRightComponent
          }
        </Grid>
      </Grid>
    </ECardBorderLess>
  )
}
