import { PAY_SALARY_LOADING, PAY_SALARY_SUCCESS, PAY_SALARY_FAILURE,
    PAY_SALARY_LIST_LOADING, PAY_SALARY_LIST_SUCCESS, PAY_SALARY_LIST_FAILURE ,
    PAY_MODAL_LIST_LOADING, PAY_MODAL_LIST_SUCCESS, PAY_MODAL_LIST_FAILURE,
    EMPLOYEE_SALARY_SLIP_FAILURE, EMPLOYEE_SALARY_SLIP_SUCCESS, EMPLOYEE_SALARY_SLIP_LOADING, 
    EMPLOYEE_PREV_ADVANCE_LOADING, EMPLOYEE_PREV_ADVANCE_SUCCESS, EMPLOYEE_PREV_ADVANCE_FAILURE } from '../constants';

const INITIAL_VALUE = {
  PaySalaryListData: {},
  PaySalaryListSuccess: false,
  PaySalaryListLoading: false,
  PaySalaryListMessage: null,

  PaySalaryData: [],
  PaySalarySuccess: false,
  PaySalaryLoading: false,
  PaySalaryMessage: null,

  PayModalListData: {},
  PayModalListSuccess: false,
  PayModalListLoading: false,
  PayModalListMessage: null,

  EmployeeSalarySlipData: {},
  EmployeeSalarySlipSuccess: false,
  EmployeeSalarySlipLoading: false,
  EmployeeSalarySlipMessage: null ,

  EmployeePreviousAdvanceData: {},
  EmployeePreviousAdvanceSuccess: false,
  EmployeePreviousAdvanceLoading: false,
  EmployeePreviousAdvanceMessage: null ,

};

const PaymentReducer = (state = INITIAL_VALUE, action) => {
  switch (action.type) {
    case PAY_SALARY_LIST_LOADING:
      return {
        ...state,
        PaySalaryListLoading: true,
        PaySalaryListData: {},
        PaySalaryListSuccess: false,
        PaySalaryListMessage: null
      };
    case PAY_SALARY_LIST_SUCCESS:
      return {
        ...state,
        PaySalaryListLoading: false,
        PaySalaryListSuccess: true,
        PaySalaryListData: action.payload.data,
        PaySalaryListMessage: action.payload.message
      };

    case PAY_SALARY_LIST_FAILURE:
      return {
        ...state,
        PaySalaryListLoading: false,
        PaySalaryListSuccess: false,
        PaySalaryListData: {},
        PaySalaryListMessage: action.payload.message
      };

    case PAY_SALARY_LOADING:
      return {
        ...state,
        PaySalaryLoading: true,
        PaySalaryData: {},
        PaySalarySuccess: false,
        PaySalaryMessage: null
      };
    case PAY_SALARY_SUCCESS:
      return {
        ...state,
        PaySalaryLoading: false,
        PaySalarySuccess: true,
        PaySalaryData: action.payload.data,
        PaySalaryMessage: action.payload.message
      };

    case PAY_SALARY_FAILURE:
      return {
        ...state,
        PaySalaryLoading: false,
        PaySalarySuccess: false,
        PaySalaryData: {},
        PaySalaryMessage: action.payload.message
      };
    case PAY_MODAL_LIST_LOADING:
      return {
        ...state,
        PayModalListLoading: true,
        PayModalListData: {},
        PayModalListSuccess: false,
        PayModalListMessage: null
      };
    case PAY_MODAL_LIST_SUCCESS:
      return {
        ...state,
        PayModalListLoading: false,
        PayModalListSuccess: true,
        PayModalListData: action.payload.data,
        PayModalListMessage: action.payload.message
      };

    case PAY_MODAL_LIST_FAILURE:
      return {
        ...state,
        PayModalListLoading: false,
        PayModalListSuccess: false,
        PayModalListData: {},
        PayModalListMessage: action.payload.message
      };

      case EMPLOYEE_SALARY_SLIP_LOADING:
      return {
        ...state,
        EmployeeSalarySlipLoading: true,
        EmployeeSalarySlipData: {},
        EmployeeSalarySlipSuccess: false,
        EmployeeSalarySlipMessage: null
      };
    case EMPLOYEE_SALARY_SLIP_SUCCESS:
      return {
        ...state,
        EmployeeSalarySlipLoading: false,
        EmployeeSalarySlipSuccess: true,
        EmployeeSalarySlipData: action.payload.data,
        EmployeeSalarySlipMessage: action.payload.message
      };

    case EMPLOYEE_SALARY_SLIP_FAILURE:
      return {
        ...state,
        EmployeeSalarySlipLoading: false,
        EmployeeSalarySlipSuccess: false,
        EmployeeSalarySlipData: {},
        EmployeeSalarySlipMessage: action.payload.message
      };
      case EMPLOYEE_PREV_ADVANCE_LOADING:
      return {
        ...state,
        EmployeePreviousAdvanceLoading: true,
        EmployeePreviousAdvanceData: {},
        EmployeePreviousAdvanceSuccess: false,
        EmployeePreviousAdvanceMessage: null
      };
    case EMPLOYEE_PREV_ADVANCE_SUCCESS:
      return {
        ...state,
        EmployeePreviousAdvanceLoading: false,
        EmployeePreviousAdvanceSuccess: true,
        EmployeePreviousAdvanceData: action.payload.data,
        EmployeePreviousAdvanceMessage: action.payload.message
      };

    case EMPLOYEE_PREV_ADVANCE_FAILURE:
      return {
        ...state,
        EmployeePreviousAdvanceLoading: false,
        EmployeePreviousAdvanceSuccess: false,
        EmployeePreviousAdvanceData: {},
        EmployeePreviousAdvanceMessage: action.payload.message
      };

    default:
      return state;
  }
};

export default PaymentReducer;
