import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { DESIGNATION_FAILURE, DESIGNATION_LOADING, DESIGNATION_SUCCESS, DESIGNATION_LIST_FAILURE, DESIGNATION_LIST_SUCCESS, DESIGNATION_LIST_LOADING, SERVER_URL } from '../constants';

export function CreateDesignation(token, Data, close=null) {  
  const data = new FormData();
  data.append('designation_name',Data.designation_name);
  data.append('status',Data.status); 

  return (dispatch) => {
    dispatch(designationsLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/designation/create-designation`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(designationsSuccess(res.data))
          dispatch(DesignationList(token))
          if(close){close()}
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(designationsFailure(error.response.data));
          if(close){close()}
          if (error?.response?.status == 401) {
            // dispatch(Logout())
          }
        }
      });
  };
}

export function UpdateDesignation(token, Data, close=null) {  
  const data = new FormData();
  data.append('designation_name',Data.designation_name);
  data.append('status',Data.status);  
  data.append('id',Data.designationId)

return (dispatch) => {
  dispatch(designationsLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/designation/update-designation`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data
  })
    .then((res) => {
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        })
        dispatch(designationsSuccess(res.data))
        if(close){close()}
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        dispatch(designationsFailure(error.response.data));
        if(close){close()}
        if (error?.response?.status == 401) {
          // dispatch(Logout())
        }
      }
    });
};
}

export function DeleteDesignation(token, Data, close=null) {  
  const data = new FormData();
  data.append('designation_id',Data)

return (dispatch) => {
  dispatch(designationsLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/designation/delete-designation`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data
  })
    .then((res) => {
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        })
        dispatch(designationsSuccess(res.data))
        dispatch(DesignationList(token))
        if(close){close()}
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        dispatch(designationsFailure(error.response.data));
        if(close){close()}
        if (error?.response?.status == 401) {
          // dispatch(Logout())
        }
      }
    });
};
}

export function designationsLoading() {
  return {
    type: DESIGNATION_LOADING,
  };
}
export function designationsSuccess(payload) {
  return {
    type: DESIGNATION_SUCCESS,
    payload
  };
}

export function designationsFailure(payload) {
  return {
    type: DESIGNATION_FAILURE,
    payload
  };
}


  export function DesignationList(token, Data = {},  page = null, pageSize = null ) {

    let queryString="";
      if (page !== null  && pageSize !== null) {
        queryString += `&page=${page}&per_page=${pageSize}`;
      }

      if (Data?.designation && Data?.designation !='' ) {
        queryString += `&designation_name=${Data.designation}`;
      }
      return (dispatch) => {
        dispatch(designationListLoading());
        axios({
          method: 'get',
          url: `${SERVER_URL}/designation/designation-list?${queryString}`,
          crossDomain: true,
          headers: {
            "Authorization": 'Bearer ' + token,
          },
        })
          .then((res) => {
            if (res.status === 200) {
              dispatch(designationListSuccess(res.data))
            }
          }).catch((error) => {
            if (error?.response) {
              dispatch(designationListFailure(error.response.data));
              // if (error?.response?.status == 401) {
              // }
              // above commented code block was blank
              if (error?.response?.status == 401) {
                // dispatch(Logout());
              }
            }
          });
      };
    }


export function designationListLoading() {
  return {
    type: DESIGNATION_LIST_LOADING,
  };
}
export function designationListSuccess(payload) {  
  return {
    type: DESIGNATION_LIST_SUCCESS,
    payload
  };
}

export function designationListFailure(payload) {
  return {
    type: DESIGNATION_LIST_FAILURE,
    payload
  };
}
