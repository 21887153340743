import axios from 'axios';
import { HOLIDAY_FAILURE,EDIT, DELETE, HOLIDAY_LOADING, HOLIDAY_SUCCESS, HOLIDAY_LIST_FAILURE, HOLIDAY_LIST_SUCCESS,HOLIDAY_LIST_LOADING, SERVER_URL } from '../constants';
import { Logout } from './AuthAction';
import { Success, Errors } from '../BaseComponents/EToast';
/**
 * [2022-03-21]
 * Author:-Santosh Mahule
 * Description:- Create holiday for company
 * [2022-03-31]
 * changes by:-Aanchal Sahu
 * Description:- Commented holiday list
 * [2022-10-21]
 * changes by:-Aanchal Sahu
 * Description:- added close=null
 */
export function CreateHoliday(token, Data, close=null) {  
    const data = new FormData();
    data.append('holiday_name', Data.holiday_name);
    data.append('from_date', Data.from_date);
    data.append('to_date', Data.to_date);
    data.append('created_by', Data.created_by);     
  return (dispatch) => {
    dispatch(holidayLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/holiday/create-holiday`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data: data
    })
      .then((res) => {
        if (res.status === 200) {
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })
          dispatch(holidaySuccess(res.data))    
          if(close){close();}
          // dispatch(HolidayList(token, {year_month:Data.filterdate}, 0, 5))         
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(holidayFailure(error.response.data));
          // dispatch(HolidayList(token, null, 0, 5))   
          if(close){close();}
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

/**
 * [2022-03-21]
 * Author:-Santosh Mahule
 * Description:- Update holiday for company
 * [2022-03-31]
 * changes by:-Aanchal Sahu
 * Description:- Commented holiday list
 * [2022-10-21]
 * changes by:-Aanchal Sahu
 * Description:- made this action dynamic for edit as well as delete functionality
 */

export function UpdateHoliday(token, Data, Action, close=null) {  
  const data = new FormData();
  let URL = `holiday/`

  data.append('id',Data.id)   //this is common for both edit and delete

  if(Action==EDIT){ 
    URL+=`update-holiday`
    data.append('holiday_name',Data.holiday_name);
    data.append('from_date',Data.from_date);
    data.append('to_date',Data.to_date);
    data.append('updated_by',Data.created_by);  
  }
  else if(Action==DELETE){
    URL+=`delete-holiday`
  }
  
  

return (dispatch) => {
  dispatch(holidayLoading());
  axios({
    method: 'post',
    url: `${SERVER_URL}/${URL}`,
    headers: {
      "Authorization": 'Bearer ' + token,
    },
    data
  })
    .then((res) => {
    
      if (res.status === 200) {
        Success.fire({
          text: "html",
          html: `<span style="color:white">${res.data.message}</span>`,
        })
        dispatch(holidaySuccess(res.data))
        dispatch(HolidayList(token))    
        if(close){close();}
      }
    }).catch((error) => {
      if (error?.response) {
        Errors.fire({
          text: "html",
          html: `<span style="color:white">${error.response.data.message}</span>`,
        })
        dispatch(holidayFailure(error.response.data));
        if(close){close();}
        // dispatch(HolidayList(token, null, 0, 5))
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    });
};
}

/**
 * [2022-03-21]
 * Author:-Santosh Mahule
 * Description:- View holiday for company
 * [2022-03-31]
 * changes by:-Aanchal Sahu
 * Description:- Commented holiday list
 *  [2023-03-07]
 * changes by:-Aanchal Sahu
 * Description:- Commenting ViewHoliday due to no use
 */

// export function ViewHoliday(token, id) {  

// return (dispatch) => {
//   dispatch(holidayLoading());
//   axios({
//     method: 'get',
//     url: `${SERVER_URL}/holiday/view-holiday?id=${id}`,
//     headers: {
//       "Authorization": 'Bearer ' + token,
//     },
   
//   })
//     .then((res) => {
//       if (res.status === 200) {
//         dispatch(holidaySuccess(res.data))
//         // dispatch(HolidayList(token, null, 0, 5))   
//       }
//     }).catch((error) => {
//       if (error?.response) {
//         Errors.fire({
//           text: "html",
//           html: `<span style="color:white">${error.response.data.message}</span>`,
//         })
//         dispatch(holidayFailure(error.response.data));
//         if (error?.response?.status == 401) {
//           dispatch(Logout())
//         }
//       }
//     });
// };
// }

export function holidayLoading() {
  return {
    type: HOLIDAY_LOADING,
  };
}
export function holidaySuccess(payload) {
  return {
    type: HOLIDAY_SUCCESS,
    payload
  };
}

export function holidayFailure(payload) {
  return {
    type: HOLIDAY_FAILURE,
    payload
  };
}

/**
 * [2022-03-21]
 * Author:-Santosh Mahule
 * Description:- Holiday list for company
 * [2022-03-31]
 * Changes by:-Aanchal Sahu
 * Description:- line 162-added -> (&& data?.year_month) in the if condition
 * [2022-04-02]
 * Changes by:-Aanchal Sahu
 * Description:- upcoming_holiday added in if condition for dashboard holiday list
 */
export function HolidayList(token, data= {}, page = null, pageSize = null) {
 
let queryString = "";

  if (page !== null  && pageSize !== null) {
    queryString += `page=${page}&per_page=${pageSize}&`;
  }  
  if (data && data?.year_month && data?.year_month !== null) {
    queryString += `month_year=${data?.year_month}&`;
  }

  if (data && data?.sort ) {
    queryString += `sort=${data?.sort}&`;
  }
  if (data?.upcoming_holiday && data?.upcoming_holiday !== null && data?.upcoming_holiday !== '') {
    queryString += `upcoming_holiday=${data?.upcoming_holiday}`;
  }

  return (dispatch) => {
    dispatch(holidayListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/holiday/holiday-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(holidayListSuccess(res.data))
        }
      }).catch((error) => {
       
        if (error?.response) {
          dispatch(holidayListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}


export function holidayListLoading() {
  return {
    type: HOLIDAY_LIST_LOADING,
  };
}
export function holidayListSuccess(payload) {  
  return {
    type: HOLIDAY_LIST_SUCCESS,
    payload
  };
}

export function holidayListFailure(payload) {
  return {
    type: HOLIDAY_LIST_FAILURE,
    payload
  };
}

  