import { Grid, LinearProgress, CircularProgress, useTheme, ListItem, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { EButton, EButtonOutlined } from "../../BaseComponents/EButtons";
import { obCloseWelcome, onboardingSendVerificationEmail, obComplete } from "../../action/OnBoardingAction";
import { EBox } from "../../BaseComponents/EBox"; 
import { ROLE_HR, SUPER_ADMIN } from "../../constants";
import OnBoardingSuperAdminSetup from "./Onboarding/OnBoardingSuperAdminSetup";
import OnBoardingHRSetup from "./Onboarding/OnBoardingHRSetup";
import { onBoardingStepAction } from "../../action/OnboardingStepAction";
import { CompanyDetail } from "../../action/CompanyAction";


function CompanySetup(props) {
  const { auth } = props;
  const company = useSelector((state) => state.CompanyReducer);
  const onBoardData = useSelector(state => state.OnBoardingReducer);
  const role = auth?.authData?.role 




  return (
    
      role==SUPER_ADMIN ? 
      <OnBoardingSuperAdminSetup auth={auth} notDisplayGreet={true}/>
      : role==ROLE_HR
        ? <OnBoardingHRSetup auth={auth} notDisplayGreet={true}/>:null

    
  );
}

export default CompanySetup;


export const CancelModal = ({open, setOpen}) => {
  return(
      <ListItem className='p0 m0' sx={{paddingLeft:'0 !important', paddingRight:'0 !important'}}>
          <EBox sx={{ marginLeft: 'auto', marginTop:'15px'}}>
                  <EButtonOutlined variant='outlined' size='large' onClick={()=>{setOpen(false)}} sx={{marginRight:'10px'}}> Cancel</EButtonOutlined>
                  <EButton variant="contained" size='large' onClick={()=>{setOpen(false)}}> Invite</EButton>
          </EBox>
      </ListItem>
  )

}
