
import {
    EMP_AUTH_LIST_FAILURE, EMP_AUTH_LIST_SUCCESS, EMP_AUTH_LIST_LOADING, EMP_AUTH_FAILURE, EMP_AUTH_LOADING, EMP_AUTH_SUCCESS
} from '../constants';


const INITIAL_STATE = {
    empAuthListData: {},
    empAuthListSuccess: false,
    empAuthListLoading: false,
    empAuthListMessage: null,
    empAuthData: {},
    empAuthSuccess: false,
    empAuthLoading: false,
    empAuthMessage: null,

};

const EmployeeAuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case EMP_AUTH_LOADING:
            return {
                ...state,
                empAuthLoading: true,
                empAuthData: {}
            };

        case EMP_AUTH_SUCCESS:
            return {
                ...state,
                empAuthLoading: false,
                empAuthSuccess: true,
                empAuthData: action.payload.data,
                empAuthMessage: action.payload.message,
            };

        case EMP_AUTH_FAILURE:
            return {
                ...state,
                empAuthLoading: false,
                empAuthSuccess: false,
                empAuthMessage: action.payload.message,
            };

        // FOR EMP_AUTH LIST 

        case EMP_AUTH_LIST_LOADING:
            return {
                ...state,
                empAuthListLoading: true,
                empAuthListData: {}
            };

        case EMP_AUTH_LIST_SUCCESS:
            return {
                ...state,
                empAuthListLoading: false,
                empAuthListSuccess: true,
                empAuthListData: action.payload.data,
                empAuthListMessage: action.payload.message,
            };

        case EMP_AUTH_LIST_FAILURE:
            return {
                ...state,
                empAuthListLoading: false,
                empAuthListSuccess: false,
                empAuthListMessage: action.payload.message,
                empAuthListData: {}
            };

        default:
            return state;
    }
};

export default EmployeeAuthReducer;
