import { styled } from "@mui/material";
import { StickyTable,  Row, Cell} from 'react-sticky-table';

export const EStickyTable = styled(StickyTable)(({ theme, stickyHeaderCount, leftStickyColumnCount }) => ({
    border: '1px solid #D0D0D0',
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    height: 'auto !important',
    '& .tColSheet': {
        backgroundColor: "transparent",
        verticalAlign: 'middle',
        minWidth: 50,
        borderLeft: 'none !important',
        borderRight: '1px solid #D0D0D0 !important',
        borderBottom: '1px solid #D0D0D0 !important',
    },
    [`& .tBodySheet > div:nth-of-type(-n+${leftStickyColumnCount})`]: {
        backgroundColor: theme.palette.background.default,
    },
    '& .tHeadSheet .sticky-table-cell': {
        backgroundColor: theme.palette.background.stickyTableHead,
        borderLeft: '1px solid #D0D0D0',
        borderBottom: '1px solid #D0D0D0 !important',
    },
}));

export const TypographyPayrollMonth = styled("i")(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const EStickyRow = styled(Row)(({ theme }) => ({

}));

export const EStickyCell = styled(Cell)(({ theme, isDesignation = false }) => ({
    ...(isDesignation && {
        minWidth: '178px !important',
        maxWidth: '178px !important',
        whiteSpace: "pre-wrap",
        [theme.breakpoints.down('lg')]: {
            minWidth: '151px !important',
            maxWidth: '151px !important',
        },
    }),
}));
