import {
  CUSTOM_USER_ROLE_LIST_LOADING,
  CUSTOM_USER_ROLE_LIST_SUCCESS,
  CUSTOM_USER_ROLE_LIST_FAILURE,

  CUSTOM_USER_ROLE_DATA_LOADING,
  CUSTOM_USER_ROLE_DATA_SUCCESS,
  CUSTOM_USER_ROLE_DATA_FAILURE,

  CUSTOM_USER_ROLE_LOADING,
  CUSTOM_USER_ROLE_SUCCESS,
  CUSTOM_USER_ROLE_FAILURE,

  PERMISSION_LIST_LOADING,
  PERMISSION_LIST_SUCCESS,
  PERMISSION_LIST_FAILURE,

  SAVE_CUSTOM_USER_ROLE_LOADING,
  SAVE_CUSTOM_USER_ROLE_SUCCESS,
  SAVE_CUSTOM_USER_ROLE_FAILURE,
  USER_ROLE_FETCH_TYPE,

} from '../constants';
import { axiosClient } from '../utils/AxiosClient';
import { Errors, Success } from "../BaseComponents/EToast";
import { Logout } from './AuthAction';


/**
 * INFO: The Actions to fetch Custom User Role List
 */
export function getCustomUserRoleList({ params = null } = {}) {

  return async (dispatch) => {
    try {
      dispatch(getCustomUserRoleListLoading());
      const res = await axiosClient({
        url: `setting/get-custom-user-role`,
        params,
      })
      if (res.status === 200) {
        dispatch(getCustomUserRoleListSuccess(res.data));
      }
    } catch (error) {
      dispatch(getCustomUserRoleListFailure(error?.response?.data));
      if (error?.response) {
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    }
  };
}

export function getCustomUserRoleListLoading(payload) {
  return {
    type: CUSTOM_USER_ROLE_LIST_LOADING,
    payload
  };
}

export function getCustomUserRoleListSuccess(payload) {
  return {
    type: CUSTOM_USER_ROLE_LIST_SUCCESS,
    payload
  };
}

export function getCustomUserRoleListFailure(payload) {
  return {
    type: CUSTOM_USER_ROLE_LIST_FAILURE,
    payload
  };
}

/**
 * INFO: The Actions to fetch Custom User Role List for dropdowns
 */
export function getCustomUserRoleData({ params = null } = {}) {

  return async (dispatch) => {
    try {
      dispatch(getCustomUserRoleDataLoading());
      const res = await axiosClient({
        url: `setting/get-custom-user-role`,
        params,
      })
      if (res.status === 200) {
        dispatch(getCustomUserRoleDataSuccess(res.data));
      }
    } catch (error) {
      dispatch(getCustomUserRoleDataFailure(error?.response?.data));
      if (error?.response) {
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    }
  };
}

export function getCustomUserRoleDataLoading(payload) {
  return {
    type: CUSTOM_USER_ROLE_DATA_LOADING,
    payload
  };
}

export function getCustomUserRoleDataSuccess(payload) {
  return {
    type: CUSTOM_USER_ROLE_DATA_SUCCESS,
    payload
  };
}

export function getCustomUserRoleDataFailure(payload) {
  return {
    type: CUSTOM_USER_ROLE_DATA_FAILURE,
    payload
  };
}

/**
 * INFO: The Actions to fetch Permission List pre-populated at the time of login
 */
export function getPermissionList({ params = null } = {}) {

  return async (dispatch) => {
    try {
      dispatch(getPermissionListLoading());
      const res = await axiosClient({
        url: `setting/get-permissions`,
        params,
      })
      if (res.status === 200) {
        dispatch(getPermissionListSuccess(res.data));
      }
    } catch (error) {
      dispatch(getPermissionListFailure(error?.response?.data));
      if (error?.response) {
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
    }
  };
}

export function getPermissionListLoading(payload) {
  return {
    type: PERMISSION_LIST_LOADING,
    payload
  };
}

export function getPermissionListSuccess(payload) {
  return {
    type: PERMISSION_LIST_SUCCESS,
    payload
  };
}

export function getPermissionListFailure(payload) {
  return {
    type: PERMISSION_LIST_FAILURE,
    payload
  };
}

/**
 * INFO: The Actions is to fetch a single Custom User Role
 */
export function getCustomUserRole({ params = null } = {}, next = () => null) {

  return async (dispatch) => {
    try {
      dispatch(getCustomUserRoleLoading());
      const res = await axiosClient({
        url: `setting/get-custom-user-role`,
        params,
      })
      if (res.status === 200) {
        dispatch(getCustomUserRoleSuccess(res.data));
      }
      next(res)
    } catch (error) {
      dispatch(getCustomUserRoleFailure(error?.response?.data));
      if (error?.response) {
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
      next(error)
    }
  };
}

export function getCustomUserRoleLoading(payload) {
  return {
    type: CUSTOM_USER_ROLE_LOADING,
    payload
  };
}

export function getCustomUserRoleSuccess(payload) {
  return {
    type: CUSTOM_USER_ROLE_SUCCESS,
    payload
  };
}

export function getCustomUserRoleFailure(payload) {
  return {
    type: CUSTOM_USER_ROLE_FAILURE,
    payload
  };
}

/**
 * INFO: The Actions is to save/update/delete the Custom User Role
 */
export function saveCustomUserRole({ data = null, url = 'setting/set-custom-user-role' } = {}, next = () => null) {

  return async (dispatch) => {
    try {
      dispatch(saveCustomUserRoleLoading());
      const res = await axiosClient({
        url,
        method: 'POST',
        data,
      })
      if (res.status === 200) {
        Success.fire({
          text: 'html',
          html: `<span style="color:white">${res.data.message}</span>`
        })
        dispatch(saveCustomUserRoleSuccess(res.data));
      }
      /**
       * INFO: If the logged in user is SUPER ADMIN then fetch all Role otherwise exclude SUPER ADMIN Role
       */
      const params = {
        type: USER_ROLE_FETCH_TYPE.ALL
      }
      /**
       * INFO: To fetch User Roles
       */
      dispatch(getCustomUserRoleData({ params }))
      next(res)
    } catch (error) {
      dispatch(saveCustomUserRoleFailure(error?.response?.data));
      if (error?.response) {
        Errors.fire({
          text: 'html',
          html: `<span style="color:white">${error.response.data.message}</span>`
        })
        if (error?.response?.status == 401) {
          dispatch(Logout())
        }
      }
      next(error)
    }
  }
}

export function saveCustomUserRoleLoading(payload) {
  return {
    type: SAVE_CUSTOM_USER_ROLE_LOADING,
    payload
  }
}

export function saveCustomUserRoleSuccess(payload) {
  return {
    type: SAVE_CUSTOM_USER_ROLE_SUCCESS,
    payload
  }
}

export function saveCustomUserRoleFailure(payload) {
  return {
    type: SAVE_CUSTOM_USER_ROLE_FAILURE,
    payload
  }
}

