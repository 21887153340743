import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EChip from "../../BaseComponents/EChip";
import { DownLoadFile } from '../../action/AdminAction';
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import { DEFAULT_ROWS_PERPAGE, PAYMENT_TRANSACTION_STATUS, PAYMENT_TRANSACTION_STATUS_CHIP, PAYROLL_LIST_DOWNLOAD } from "../../constants";
import SearchNotFound from "../SearchNotFound";
import { useNavigate } from "react-router-dom";
import { EIcon } from "../../BaseComponents/EIcon";
import VisibilityIcon from '@iconify/icons-material-symbols/visibility-outline';
import { getPayrollNewList } from "../../action/PayrollAction";
import { PaymentSearchFilter } from "./PaymentSearchFilter";
import moment from "moment";
import { generateYearArray } from "../../utils/CommonFunctions";
import base64 from 'base-64'


const SalaryPayroll = (props) => {

    const { paymentList, auth, tabvalue } = props;

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { companyData } = useSelector(state => state.CompanyReducer)

    const [searchParam, setSearchParam] = useState({
        end: '',
        year: {
            id: moment().format('YYYY').toString(),
            label: moment().format('YYYY').toString()
        },
        sort: 'DESC'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PERPAGE);

    const callApi = (fnParams, fnPage, fnRowPerPage) => {
        const params = {
            month: fnParams?.end ? moment(fnParams?.end).format('MM') : null,
            year: fnParams?.year ? fnParams?.year?.id : null,
            sort: fnParams?.sort ? "DESC" : "ASC"
        }
        dispatch(getPayrollNewList({ ...params, page: fnPage, per_page: fnRowPerPage }))
    }

    useEffect(() => {
        if (auth.authtoken) {
            callApi({ ...searchParam }, page, rowsPerPage);
        }
    }, [tabvalue])


    const handleChangePage = (event, newPage) => {
        callApi(searchParam, newPage, rowsPerPage);
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        const rowPerPageCount = parseInt(event.target.value, DEFAULT_ROWS_PERPAGE);
        setRowsPerPage(rowPerPageCount);
        callApi(searchParam, 0, rowPerPageCount);
        setPage(0);
    };


    function numberWithCommas(num) {
        let number = Number(num)
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: "INR" }).format(number).replace(/^(\D+)/, '$1 ');
    }

    function onSearch(data) {
        setSearchParam(data)
        callApi(data, page, rowsPerPage);
    }

    const TABLE_HEAD = gridColumnsHead(tabvalue);
    const yearArray = generateYearArray(companyData?.created_at)

    const DownloadReport = (type) => {
        const month = searchParam?.end ? moment(searchParam?.end).format('MM') : null;
        const year = searchParam?.year ? searchParam?.year?.id : null;
        const params = {
            download_format: type,
            sort: searchParam?.sort ? "DESC" : "ASC",
            ...(month && { month }),
            ...(year && { year }),
        }
        const queryString = new URLSearchParams(params).toString();
        const url = `${PAYROLL_LIST_DOWNLOAD}?${queryString}`;
        DownLoadFile(auth.authtoken, url, "monthly-payroll-list")
    }

    return (
        <Grid container>

            <Grid item md={12} my={2}>

                <PaymentSearchFilter
                    DownloadReport={DownloadReport}
                    initialFilter={searchParam}
                    onSearch={onSearch}
                    onSort={onSearch}
                    end={{
                        placeholder: 'All Month',
                        views: ['month'],
                        inputFormat: "MMMM",
                        mindate: `${moment(companyData?.created_at)}`
                    }}
                    yearList={yearArray}
                    particularsOrder={3}
                    statusOrder={4}
                    talentOrder={5}
                    withoutDateValidation={true}
                />

                <EScrollbar>
                    <ETableContainer sx={{ marginTop: '16px' }} >
                        <ETable>
                            {paymentList?.payrollNewListData?.count > 0 && (
                                <EListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={paymentList?.payrollNewListData?.rows?.length}
                                    onRequestSort={() => null}
                                />
                            )}

                            {paymentList?.payrollNewListLoading && (
                                <ETableBody>
                                    <ETableRow>
                                        <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                            <ECircularProgress color="primary" />
                                        </ETableCell>
                                    </ETableRow>
                                </ETableBody>
                            )}



                            {paymentList?.payrollNewListData?.count > 0 && (
                                <ETableBody>
                                    {paymentList?.payrollNewListData?.rows?.map((row, index) => {
                                        const { month, employee_count, payble_amount, paid_amount, cancelled_amount, payment_status } = row;
                                        return (
                                            <ETableRow
                                                hover key={index} tabIndex={-1}
                                            >

                                                <ETableCell align="left" sx={{ padding: '10px' }}>
                                                    {index + 1 + (page * 10)}
                                                </ETableCell>

                                                <ETableCell align="left" sx={{ minWidth: '100px' }}>
                                                    {month}
                                                </ETableCell>

                                                <ETableCell align="right" style={{ minWidth: '50px' }}>
                                                    {employee_count}
                                                </ETableCell>

                                                <ETableCell style={{ minWidth: '120px' }} align="right">
                                                    {payble_amount ? numberWithCommas(payble_amount) : numberWithCommas(0)}
                                                </ETableCell>

                                                <ETableCell style={{ minWidth: '120px' }} align="right">
                                                    {paid_amount ? numberWithCommas(paid_amount) : numberWithCommas(0)}
                                                </ETableCell>

                                                <ETableCell style={{ minWidth: '120px' }} align="right">
                                                    {numberWithCommas(payble_amount - paid_amount - cancelled_amount)}
                                                </ETableCell>

                                                <ETableCell style={{ minWidth: '120px' }} align="right">
                                                    {cancelled_amount ? numberWithCommas(cancelled_amount) : numberWithCommas(0)}
                                                </ETableCell>

                                                <ETableCell align="center" style={{ minWidth: '120px' }}>
                                                    <EChip
                                                        label={PAYMENT_TRANSACTION_STATUS[payment_status]}
                                                        width="115px"
                                                        className={PAYMENT_TRANSACTION_STATUS_CHIP[payment_status]}
                                                    />
                                                </ETableCell>

                                                <ETableCell align="center" style={{ minWidth: '80px', cursor: 'pointer' }}>
                                                    <EIcon icon={VisibilityIcon} onClick={() => navigate(`/payment/salary-payment/${month}/${base64.encode(row?.id)}`)} />
                                                </ETableCell>
                                            </ETableRow>
                                        );
                                    })}
                                </ETableBody>
                            )}

                            {paymentList?.payrollNewListData?.count == 0 &&
                                !paymentList?.payrollNewListData?.payrollListLoading && (
                                    <ETableBody>
                                        <ETableRow>
                                            <ETableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                <SearchNotFound />
                                            </ETableCell>
                                        </ETableRow>
                                    </ETableBody>
                                )}


                        </ETable>
                    </ETableContainer>
                </EScrollbar>

                {paymentList?.payrollNewListData?.count > DEFAULT_ROWS_PERPAGE && (
                    <Grid container my={3}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <ETablePagination
                            rowsPerPageOptions={DEFAULT_ROWS_PERPAGE}
                            component="div"
                            count={paymentList?.payrollNewListData?.count ? paymentList?.payrollNewListData?.count : 10}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                )}


            </Grid>
        </Grid>
    )

};



const gridColumnsHead = (tabvalue) => {
    let tblHead = [
        { id: 'SNo.', label: 'SNo.', alignRight: false },
        { id: 'Month', label: 'Month', alignRight: false },
        { id: 'Total Employees', label: 'Total Employees', alignRight: true },
        { id: 'Payable Amt.', label: 'Payable Amt.', alignRight: true },
        { id: 'Paid Amt.', label: 'Paid Amt.', alignRight: true },
        { id: 'Balance Amt.', label: 'Balance Amt.', alignRight: true },
        { id: 'Cancelled Amt.', label: 'Cancelled Amt.', alignRight: true },
        { id: 'Status', label: 'Status', alignRight: 'center' },
        { id: 'Action', label: 'Action', alignRight: false },
    ];
    return tblHead;
};

export default SalaryPayroll;