import axios from 'axios';
import moment from 'moment';
import { Success, Errors } from '../BaseComponents/EToast';
import { 
  SERVER_URL, 
  FREELANCER_LIST_LOADING, 
  FREELANCER_LIST_SUCCESS, 
  FREELANCER_LIST_FAILURE,
  FREELANCER_LOADING, 
  FREELANCER_SUCCESS, 
  FREELANCER_FAILURE,
  ADHAAR_DOC_ID,
  PAN_DOC_ID,
  DRIVING_LICENCE_DOC_ID,
  PASSPORT_DOC_ID ,ALL_FREELANCER_FAILURE, ALL_FREELANCER_LOADING, ALL_FREELANCER_SUCCESS, FREELANCER_PERSONAL_DETAILS_UPDATE, FREELANCER_BANK_DETAILS_UPDATE
 } from '../constants';

import { Logout } from './AuthAction';

export function FreelancerListData(token, data = null, page = null, pageSize = null) {
  let queryString = '';
  if (data?.name && data?.name != null) {
    queryString += `name=${data?.name}`;
  }
  if (page !== null  && pageSize !== null) {
    queryString += `&page=${page}&per_page=${pageSize}`;
  }
  
  if (data?.sort!=null) {
    queryString +=`&sort=${data?.sort}`
  }

  return (dispatch) => {
    dispatch(freelanceListLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/freelancer/freelancer-list?${queryString}`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(freelanceListSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(freelanceListFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function freelanceListLoading() {
    return {
      type: FREELANCER_LIST_LOADING,
    };
  }
  export function freelanceListSuccess(payload) {
    return {
      type: FREELANCER_LIST_SUCCESS,
      payload: payload
    };
  }
  
  export function freelanceListFailure(payload) {
    return {
      type: FREELANCER_LIST_FAILURE,
      payload
    };
  }

  export function CreateFreelancer(token, Data,action=null,navigate=null) {
    const data = new FormData();
    let Url = 'freelancer'

    if(action=="add"){
      Url +=  '/add-freelancer';
      data.append('created_by', Data.created_by);
     }

    else if(action=="update"){
      Url +=  '/update-freelancer'
      data.append('freelancer_id', Data.freelancer_id);  
      data.append('updated_by', Data.updated_by);
     }
     
      data.append('freelancer_detail_check', Data.freelancer_detail_check);
      data.append('bank_detail_check', Data.bank_detail_check);
      data.append('document_checked', Data.document_checked);
      data.append('freelancer_photo_check', Data.freelancer_photo_check);

      data.append('freelancer_fname', Data.freelancer_fname);
      data.append('freelancer_lname', Data.freelancer_lname);
      data.append('freelancer_contact_no', Data.freelancer_contact_no);
      data.append('freelancer_email', Data.freelancer_email);

      data.append('address_one', Data.address_one);
      if(Data.landmark) {data.append('landmark', Data.landmark);}
      data.append('state', Data.state);
      data.append('city', Data.city);
      data.append('pincode', Data.pincode);

    if(Data.bank_detail_check){
      data.append('ifsc_code', Data.ifsc_code);
      data.append('bank_name', Data.bank_name);
      data.append('branch_name', Data.branch_name);
      data.append('account_no', Data.account_no);
      data.append('account_holder_name', Data.account_holder_name);
    }
    if( Data.freelancer_photo_check){
      data.append('freelancer_photo', Data.freelancer_photo);
    }

    if(Data.freelancer_detail_check){
      data.append('freelancer_gender', Data.freelancer_gender);
      data.append('freelancer_dob', moment(Data.freelancer_dob).format('yyyy-MM-DD'));
      data.append('freelancer_marital_status', Data.freelancer_marital_status);
      data.append('freelancer_blood_group', Data.freelancer_blood_group);
    }
    
    if(Data.document_checked){
      if (Data.aadhar_no) { data.append('aadhar_no', Data.aadhar_no);}
      if (Data.aadhar_file_name) { data.append('aadhar_file_name', Data.aadhar_file_name); }
      if (Data.pan_no) {data.append('pan_no', Data.pan_no);}
      if (Data.pan_file_name) { data.append('pan_file_name', Data.pan_file_name); }
      if (Data.driving_lic_no) { data.append('driving_lic_no', Data.driving_lic_no);}
      if (Data.driving_lic_file) { data.append('driving_lic_file', Data.driving_lic_file);}
      if (Data.passport_no) { data.append('passport_no', Data.passport_no);}
      if (Data.passport_file) { data.append('passport_file', Data.passport_file);}
    }

    return (dispatch) => {
      dispatch(freelanceLoading());
      axios({
        method: 'post',
        url: `${SERVER_URL}/${Url}`,
        headers: {
          "Authorization": 'Bearer ' + token,
        },
        data
      })
        .then((res) => {
          if (res.status === 200) {
            Success.fire({
              text: "html",
              html: `<span style="color:white">${res.data.message}</span>`,
            })
            dispatch(freelanceSuccess({}));
            dispatch(FreelancerListData(token))
            dispatch(AllFreelancerList(token));
            if(action=="add"){navigate('/freelancer-contract/freelancer-list')}
          }
        }).catch((error) => {
          if (error?.response) {
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(freelanceFailure(error.response.data));
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }

  export function freelanceLoading() {
    return {
      type: FREELANCER_LOADING,
    };
  }
  export function freelanceSuccess(payload) {
    return {
      type: FREELANCER_SUCCESS,
      payload: payload
    };
  }
  
  export function freelanceFailure(payload) {
    return {
      type: FREELANCER_FAILURE,
      payload
    };
  }

  
  export function GetFreelancerDetails(token, id) {

    return (dispatch) => {
      dispatch(freelanceLoading());
      axios({
        method: 'get',
        url: `${SERVER_URL}/freelancer/freelancer-detail?freelancer_id=${id}`,
        headers: {
          "Authorization": 'Bearer ' + token,
        }
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(freelanceSuccess(res.data));
          }
        }).catch((error) => {
          if (error?.response) {
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(freelanceFailure(error.response.data));
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }


  export function FreelancerContractList(token, data = null, page = null, pageSize = null) {
    
    let queryString = '';

    if (data?.freelancer_id && data?.freelancer_id != null) {
      queryString += `freelancer_id=${data?.freelancer_id}`;
    }

    if (page !== null  && pageSize !== null) {
      queryString += `&page=${page}&per_page=${pageSize}`;
    }
    
    return (dispatch) => {
      dispatch(freelanceListLoading());
      axios({
        method: 'get',
        url: `${SERVER_URL}/freelancer/freelancer-contract-list?${queryString}`,
        crossDomain: true,
        headers: {
          "Authorization": 'Bearer ' + token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch(freelanceListSuccess(res.data))
          }
        }).catch((error) => {
          if (error?.response) {
            dispatch(freelanceListFailure(error.response.data));
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }
/**
 * [2022-08-31]
 * Changes By: Aanchal Sahu
 * Description:- This API is used by many components: freelancer bank details update, freelancer photo update, 
 * freelancer aadhar update, freelancer pan update, freelancer basic details update, freelancer personal details update
 **/
  export function UpdateBasicFreelancerDetailsAction(token, Data,action=null,close=null,navigate=null) {

    const data = new FormData();
    let Url = 'freelancer'
    data.append('freelancer_id', Data.freelancer_id);
    data.append('updated_by', Data.updated_by);

    if(action=="basic-details"){
      Url +=  '/update-freelancer-profile';
      data.append('freelancer_email', Data.freelancer_email);
      data.append('freelancer_contact_no', Data.freelancer_contact_no);
      if(Data.freelancer_photo){data.append('freelancer_photo', Data.freelancer_photo);}
     }

    else if(action==FREELANCER_PERSONAL_DETAILS_UPDATE){
      Url +=  FREELANCER_PERSONAL_DETAILS_UPDATE
      data.append('freelancer_gender', Data.freelancer_gender);  
      data.append('freelancer_dob', Data.freelancer_dob); 
      data.append('freelancer_marital_status', Data.freelancer_marital_status);  
      data.append('freelancer_blood_group', Data.freelancer_blood_group); 
      data.append('address_one', Data.address_one);
      if(Data.landmark){data.append('landmark', Data.landmark);}
      data.append('state', Data.state);
      data.append('city', Data.city);
      data.append('pincode', Data.pincode);
     }
     
    //  if(action=="photo"){
    //   Url +=  '/update-freelancer-photo';
    //   data.append('photo', Data.photo);
    //  }

    //  if(action=="aadhar-detail"){
    //   Url +=  '/update-freelancer-aadhar-detail';
    //    data.append('aadhar_no', Data.aadhar_no);
    //    if(Data.aadhar_file_name){data.append('aadhar_file_name', Data.aadhar_file_name);}
    //  }
     
    //  if(action=="pan-detail"){
    //   Url +=  '/update-freelancer-pan-detail';
    //   data.append('pan_no', Data.pan_no);
    //   if(Data.pan_file_name){data.append('pan_file_name', Data.pan_file_name);}
    //  }
     
     if(action==FREELANCER_BANK_DETAILS_UPDATE){
      Url +=  FREELANCER_BANK_DETAILS_UPDATE;
      data.append('ifsc_code', Data.ifsc_code);
      data.append('bank_name', Data.bank_name);
      data.append('branch_name', Data.branch_name);
      data.append('account_no', Data.account_no);
      data.append('account_holder_name', Data.account_holder_name);
    }

    return (dispatch) => {
      dispatch(freelanceLoading());
      axios({
        method: 'post',
        url: `${SERVER_URL}/${Url}`,
        headers: {
          "Authorization": 'Bearer ' + token,
        },
        data
      })
        .then((res) => {
          if (res.status === 200) {
            Success.fire({
              text: "html",
              html: `<span style="color:white">${res.data.message}</span>`,
            })
            dispatch(freelanceSuccess({}));
            dispatch(GetFreelancerDetails(token, Data.freelancer_id))
            close()
          }
        }).catch((error) => {
          if (error?.response) {
            dispatch(GetFreelancerDetails(token, Data.freelancer_id))
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(freelanceFailure(error.response.data));
            close()
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }
// To update documents of freelancer, and to delete documents
  export function UpdateFreelancerDocsAction(token, Data, api_url=null) {

    const data = new FormData();
    let Url = `freelancer`+ (api_url? api_url: `/update-freelancer-documents`)

      data.append('freelancer_id', Data.freelancer_id);
      if(!api_url){
      data.append('updated_by', Data.updated_by);
    
      if(Data.document_id==ADHAAR_DOC_ID){
        data.append('aadhar_no', Data.aadhar_no);
        if (Data.aadhar_file_name) { data.append('aadhar_file_name', Data.aadhar_file_name); }    
      }
    
      if(Data.document_id==PAN_DOC_ID){
        data.append('pan_no', Data.pan_no.toUpperCase());
        if (Data.pan_file_name) { data.append('pan_file_name', Data.pan_file_name); }
      }
    
      if(Data.document_id==DRIVING_LICENCE_DOC_ID){
        data.append('driving_lic_no', Data.driving_lic_no);
        if (Data.driving_lic_file) { data.append('driving_lic_file', Data.driving_lic_file); }
      }
    
      if(Data.document_id==PASSPORT_DOC_ID){
        data.append('passport_no', Data.passport_no);
        if (Data.passport_file) { data.append('passport_file', Data.passport_file); }
      }
    }else{
      data.append('document_id', Data.document_id);
    }

    return (dispatch) => {
      dispatch(freelanceLoading());
      axios({
        method: 'post',
        url: `${SERVER_URL}/${Url}`,
        headers: {
          "Authorization": 'Bearer ' + token,
        },
        data
      })
        .then((res) => {
          if (res.status === 200) {
            Success.fire({
              text: "html",
              html: `<span style="color:white">${res.data.message}</span>`,
            })
            dispatch(freelanceSuccess({}));
            dispatch(GetFreelancerDetails(token, Data.freelancer_id))
            // close()
          }
        }).catch((error) => {
          if (error?.response) {
            dispatch(GetFreelancerDetails(token, Data.freelancer_id))
            Errors.fire({
              text: "html",
              html: `<span style="color:white">${error.response.data.message}</span>`,
            })
            dispatch(freelanceFailure(error.response.data));
            // close()
            if (error?.response?.status == 401) {
              dispatch(Logout())
            }
          }
        });
    };
  }

  //INFO: This Api collects All Freelancer List, will be called when login, refresh, or whenever any Freelancer's profile has been updated 
export function AllFreelancerList(token) {

  var url = `${SERVER_URL}/freelancer/freelancer-list`
  
  return (dispatch) => {
    dispatch(allFreelancerLoading());
    axios({
      method: 'get',
      url,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(allFreelancerSuccess(res.data))
        }
      }).catch((error) => {
        if (error?.response) {
          dispatch(allFreelancerFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function allFreelancerLoading() {
  return {
    type: ALL_FREELANCER_LOADING,
  };
}
export function allFreelancerSuccess(payload) {
  return {
    type: ALL_FREELANCER_SUCCESS,
    payload
  };
}
export function allFreelancerFailure(payload) {
  return {
    type: ALL_FREELANCER_FAILURE,
    payload
  };
}
