import React, { useState } from 'react'
import EModal from '../BaseComponents/EModal';
import CheckOut from '../PageComponents/Attendance/CheckOut';
import CheckIn from '../PageComponents/Attendance/CheckIn';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const useCheckInOut = () => {
    const [WorkDoneModal, setWorkDoneModal] = useState(false);
    const [checkIn, SetcheckIn] = useState(false);

    const auth = useSelector(state => state.authReducer);

    // Custom hook to manage state of checkin, checkout and  display checkin checkout modal in any other component
    const CheckInOutModal = ()=>{
        return (
            <>
                {WorkDoneModal &&
                    <EModal open={WorkDoneModal} close={() => setWorkDoneModal(false)} headervalue={"Check Out - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                        <CheckOut close={() => setWorkDoneModal(false)} auth={auth} />
                    </EModal>
                }

                {checkIn &&
                    <EModal open={checkIn} close={() => SetcheckIn(false)} headervalue={"Check In - " + moment().format('DD/MM/yyyy, hh:mm A')}>
                        <CheckIn close={() => SetcheckIn(false)} auth={auth} />
                    </EModal>
                }
            </>
          )
    }

    return { checkIn, SetcheckIn, WorkDoneModal, setWorkDoneModal, CheckInOutModal};

}