import { Stack } from "@mui/system";
import { useDispatch } from "react-redux";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { HoldPaymentFormValidation } from "../../utils/validation";
import { CancelTalentPayment } from "../../action/PaymentAction";
import { Grid } from "@mui/material";
import { ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { ELabelInputMainGrid } from "../../BaseComponents/EGrid";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";


const PaymentCancelModal = (props) => {
  const { paymentData, setSalaryDueModal, auth, getPaymentFilterData } = props;

  const dispatch = useDispatch();
  const { id } = useParams()
  const theme = useTheme()

  const formik = useFormik({
    initialValues: {
      remark: ''
    },
    validationSchema: HoldPaymentFormValidation,
    onSubmit: (data) => {
      data = {
        ...data,
        employee_id: paymentData?.employeeData?.id,
        payroll_id: paymentData?.payrollData?.id,
        transaction_id: paymentData?.id,
        created_by: auth?.authData?.id
      };
      dispatch(CancelTalentPayment(auth.authtoken, data, getPaymentFilterData, id));
      setSalaryDueModal({ modalData: null, modalCategory: null })

    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;


  return (
    <>

      <FormikProvider value={formik} >
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} >

          <Grid container spacing={2} sx={{ paddingTop: "10px" }} >

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Employee Name'} isfullgrid={true} isModal={true} isNotForm={true}>
                <ETypography>{`${paymentData?.employeeData?.fullname} (${paymentData?.employeeData?.employee_code})`}</ETypography>
              </ELabelInputMainGrid>
            </Grid>

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              {
                paymentData?.BalanceAmt
                &&
                <ELabelInputMainGrid label={'Amount'} isfullgrid={true} isModal={true} isNotForm={true}>
                  <ETypography>{paymentData?.BalanceAmt}</ETypography>
                </ELabelInputMainGrid>
              }
            </Grid>

            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <ELabelInputMainGrid label={'Remark'} isfullgrid={true} isModal={true}>
                <ETextField
                  name='remark'
                  multiline
                  rows={3}
                  placeholder="Give a brief description"
                  {...getFieldProps('remark')}
                  fullWidth
                  error={Boolean(touched.remark && errors.remark)}
                  helperText={touched.remark && errors.remark}
                  inputProps={{ maxLength: 250 }}
                />
                <ETypography sx={{color:theme.palette.border.border2, fontSize:'14px'}}>{formik?.values?.remark?.length}/250</ETypography>
              </ELabelInputMainGrid>
            </Grid>

          </Grid>


          <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
            <EButtonOutlined size="large" variant="outlined"
              onClick={() => setSalaryDueModal({ modalData: null, modalCategory: null })}
            > Back </EButtonOutlined>
            <ELoadingButton
              size="large" variant="outlined" type="submit" >Cancel Salary</ELoadingButton>
          </Stack>


        </Form>
      </FormikProvider>
    </>
  );
};

export default PaymentCancelModal