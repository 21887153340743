import axios from 'axios';
import { SERVER_URL,GET_BIRTHDAY_ANNIVERSARY_FAILURE, GET_BIRTHDAY_ANNIVERSARY_SUCCESS, GET_BIRTHDAY_ANNIVERSARY_LOADING} from '../constants';
import { Logout } from './AuthAction';

export function getBirthdayAnniversary(token, setLoading = () => null) {

    return (dispatch) => {
        setLoading(true)
        dispatch(getBirthdayAnniversaryLoading());
        axios({
            method: 'get',
            url: `${SERVER_URL}/dashboard/my-dashboard`,
            crossDomain: true,
            headers: {
                "Authorization": 'Bearer ' + token,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    dispatch(getBirthdayAnniversarySuccess(res.data))
                }

            }).catch((error) => {
                if (error?.response) {
                    dispatch(getBirthdayAnniversaryFailure(error.response.data));
                    if (error?.response?.status == 401) {
                        dispatch(Logout())
                    }
                    // Above if block was commented
                }
            }).finally(() => setLoading(false))
    };
}

export function getBirthdayAnniversaryLoading() {
    return {
        type: GET_BIRTHDAY_ANNIVERSARY_LOADING,
    };
}
export function getBirthdayAnniversarySuccess(payload) {
    return {
        type: GET_BIRTHDAY_ANNIVERSARY_SUCCESS,
        payload
    };
}

export function getBirthdayAnniversaryFailure(payload) {
    return {
        type: GET_BIRTHDAY_ANNIVERSARY_FAILURE,
        payload
    };
}
