import React, { useEffect, useMemo, useState } from "react";
import { EBox, EBoxPage } from "../../BaseComponents/EBox";
import { ECard } from "../../BaseComponents/ECard";
import { ETypography, ETypographyCardHeading } from "../../BaseComponents/ETypography";
import {  PLAN_TIME_FREQUENCY_TYPE } from "../../constants";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow, ETableRowSmall } from "../../BaseComponents/ETable";
import { ECircularProgress } from "../../BaseComponents/ECircularProgress";

import FileDownloadIcon from '@iconify/icons-material-symbols/sim-card-download-outline-rounded';
import ViewIcon from '@iconify/icons-material-symbols/visibility-outline-rounded';


import { EIcon } from "../../BaseComponents/EIcon";
import { getDuePayment, getInvoiceHistory } from "../../action/InvoiceAction";
import { useDispatch, useSelector } from "react-redux";
import { numberWithCommas } from "../../utils/formatNumber";
import { getBillTenure } from '../../utils/CommonFunctions';


export default function InvoiceDetails({ auth }) {
  const invoiceDetails = useSelector(state => state.InvoiceReducer)
  const dispatch = useDispatch();



  useEffect(() => {
    if (auth.authtoken) {
      dispatch(getDuePayment(auth.authtoken));
      dispatch(getInvoiceHistory(auth.authtoken));
    }
  }, [auth.authtoken]);



  return (
    <EBoxPage >

      <EBox>
        <ETypographyCardHeading className="font-size-18px mb-16px"> Invoice History </ETypographyCardHeading>
        {
          invoiceDetails?.invoiceHistoryData?.length > 0
            ?
            <TableComponent data={invoiceDetails?.invoiceHistoryData} />
            :
            <EBox padding={0} className="flex-column align-center">
              <img height={'225px'} src="/assets/images/inVoiceNoData.svg" />
              <ETypography marginTop={-2} className="font-size-24px bold-600">
                Hooray! You  have no due bills to pay.
              </ETypography>
            </EBox>
        }
      </EBox>



    </EBoxPage>
  )
}


const TableComponent = ({ data }) => {
  const loading = false;
  const TABLE_HEAD = getTableHead();




  return (
    <ECard className="card_design_1">
      <EScrollbar>
        <ETableContainer >
          <ETable>
            {/* TABLE HEAD */}
            <EListHead
              headLabel={TABLE_HEAD}
              rowCount={3}
              onRequestSort={() => null}
            />

            {/* Loading Component */}
            {loading ? (
              <ETableBody>
                <ETableRow>
                  <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                    <ECircularProgress color="primary" />
                  </ETableCell>
                </ETableRow>
              </ETableBody>
            )
              :
              // {/* Table Body */}
              !loading && data?.length > 0 ?
                (
                  <ETableBody>
                    {data?.map((row, index) => {
                      const { plan_valid_to, bill_no,plan_valid_from, total_active_user, pay_amount, gross_amount, plan_time_frequency_type } = row;
                      return (
                        <ETableRowSmall hover key={index} tabIndex={-1} className={row?.length == index + 1 ? 'table-border-none' : ''}>

                          {/* Sno */}
                          <ETableCell align="left">
                            {index + 1}
                          </ETableCell>

                          {/* Invoice ID */}
                          <ETableCell align="left" className="p-8px" >
                            {bill_no}
                          </ETableCell>

                          {/*	No. Of Users */}
                          <ETableCell align="right" className="p-8px minWidth-150 " >
                            {total_active_user}
                          </ETableCell>

                          {/* Amount (₹) */}
                          <ETableCell align="right">
                            {numberWithCommas(pay_amount, true)}
                          </ETableCell>

                          {/* Subscription Type */}
                          <ETableCell align='left' className="minWidth-100">
                            {
                              plan_time_frequency_type === PLAN_TIME_FREQUENCY_TYPE.MONTH ? 'Monthly Plan' : 'Annual Plan'
                            }
                          </ETableCell>

                          {/* Bill Tenure */}
                          <ETableCell align='left' className="minWidth-100">
                            {/* {Number(gross_amount).toFixed(2)} */}
                            {getBillTenure(plan_time_frequency_type, plan_valid_from, plan_valid_to)}
                           
                          </ETableCell>


                          {/* Action */}
                          <ETableCell align='center' className="">
                          <EIcon icon={ViewIcon} className='height-width-22px cursor-pointer mr-8px' />
                            <EIcon icon={FileDownloadIcon} className='height-width-22px cursor-pointer' />
                           
                          </ETableCell>


                        </ETableRowSmall>
                      );
                    })}
                  </ETableBody>
                ) : 'No Invoice History'}
          </ETable>
        </ETableContainer>
      </EScrollbar>

    </ECard>

  )
}



// returning the table head according to tab
const getTableHead = () => {
  return [
    { id: 'SNo.', label: 'SNo.', alignRight: false },
    { id: 'Invoice ID', label: 'Invoice ID', alignRight: false},
    { id: 'No. of users', label: 'No. of users', alignRight: true },
    { id: 'Amount (₹)', label: 'Amount (₹)', alignRight: true },
    { id: 'Subscription Type', label: 'Subscription Type', alignRight: false },
    { id: 'Bill Tenure', label: 'Bill Tenure', alignRight: false },
    { id: 'Action', label: 'Action', alignRight: 'center' },
  ];
}