import moment from "moment";
import { useNavigate } from "react-router-dom";
import EChip from "../../BaseComponents/EChip";
import EScrollbar from "../../BaseComponents/EScrollbar";
import { EListHead, ETable, ETableBody, ETableCell, ETableContainer, ETableRow } from "../../BaseComponents/ETable";
import { ETablePagination } from "../../BaseComponents/ETablePagination";
import SearchNotFound from "../SearchNotFound";
import base64 from 'base-64'
import { sentenceCaseString } from "../../utils/formatText";

const TABLE_HEAD = [
    { id: 'monthYear', label: 'Month Year', alignRight: false, },
    { id: 'Status', label: 'Status', alignRight: 'center', },
];

const AttendancePayrollMonthList = (props) => {
    const navigate = useNavigate();
    const { payrollListData, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage, is_payroll } = props;

    const setSelectedRow = (row) => {
        if(is_payroll){
            navigate(`/payroll/payroll-lists/${base64.encode(row.id)}`)
        } else {
            navigate(`/attendance/attendance-lists/${base64.encode(row.id)}`)
        }
    }

    return <>
        <EScrollbar>
            <ETableContainer>
                <ETable>
                    <EListHead

                        headLabel={TABLE_HEAD}
                        rowCount={payrollListData?.rows?.length}
                        onRequestSort={() => null}

                    />

                    {payrollListData?.rows?.length > 0 &&
                        <ETableBody>
                            {payrollListData?.rows?.map((row, index) => {
                                const {  month_date, payroll_status } = row;
                                return (
                                    <ETableRow
                                        hover
                                        key={index}
                                        tabIndex={-1}
                                        onClick={() => setSelectedRow(row)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <ETableCell align="left" >
                                            {month_date && moment(month_date).format('MMM YYYY')}
                                        </ETableCell>
                                        <ETableCell align="center" style={{ width: '120px' }}>
                                            {payroll_status && <EChip label={sentenceCaseString(payroll_status)} />}
                                        </ETableCell>
                                    </ETableRow>
                                );
                            })}
                        </ETableBody>
                    }
                    {payrollListData?.rows?.length == 0 && (
                        <ETableBody>
                            <ETableRow>
                                <ETableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                    <SearchNotFound />
                                </ETableCell>
                            </ETableRow>
                        </ETableBody>
                    )}
                </ETable>

            </ETableContainer>
        </EScrollbar>
        {payrollListData?.count > 10 && (
            <ETablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={payrollListData?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        )}
    </>;
};

export default AttendancePayrollMonthList;


