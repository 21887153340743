import axios from 'axios';
import { Success, Errors } from '../BaseComponents/EToast';
import { 
  COMPANY_FAILURE, 
  COMPANY_SUCCESS, 
  COMPANY_LOADING, 
  SERVER_URL } from '../constants';

import { Logout } from './AuthAction';
import { onBoardingNavigate } from '../utils/CommonFunctions';


export function CompanyDetail(token, navigate = () => null, onBoarding = null) {
  
  return async (dispatch) => {  
    dispatch(companyLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/company/company-detail`,
      headers: {
        "Authorization": 'Bearer ' + token,
      }
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(companySuccess(res.data))

          // if onboarding is not completed
          if(res.data.data.onBoarding.onboarding_is_completed == 0)
          {
            // Reading all status
            const status = {
              setting_account_status: res.data.data.onBoarding.setting_account_status,
              setting_talent_status: res.data.data.onBoarding.setting_talent_status,
              setting_attendance_status: res.data.data.onBoarding.setting_attendance_status,
              setting_leave_status: res.data.data.onBoarding.setting_leave_status,
              setting_payroll_status: res.data.data.onBoarding.setting_payroll_status,
              setting_payment_status: res.data.data.onBoarding.setting_payment_status,
              setting_additional_status: res.data.data.onBoarding.setting_additional_status,
            };


            // Navigate to the appropriate onboarding setting page which is incomplete
            onBoardingNavigate(status, navigate)
          } 
          
          else if (onBoarding?.onboarding_is_completed == 0) {
            navigate('/')
          }
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(companyFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

/**
 * [2022-03-21]
 * Created By:-Santosh Mahule
 * Description:- Update Company profile
 */
export function UpdateCompanyDetails(token, Data, navigate = null) {  
  const data = new FormData();
  if(Data.firm_type){data.append('company_type',Data.company_type.id);}
  if(Data.company_name){data.append('company_name',Data.company_name);}
  if(Data.address){data.append('address',Data.address);}
  if(Data?.state && Data?.state?.id){data.append('state_id',Data?.state?.id);}
  if(Data?.city && Data?.city?.id){data.append('city_id_new',Data?.city?.id);}
  if(Data?.pin_code ){data.append('pin_code',Data?.pin_code);}
  if(Data?.cr_address_same ==false || Data?.cr_address_same ){data.append('cr_address_same',Data?.cr_address_same);}
  if(Data?.cr_pin_code ){data.append('cr_pin_code',Data?.cr_pin_code);}
  if(Data?.cr_city ){data.append('cr_city_id',Data?.cr_city?.id);}
  if(Data?.cr_state ){data.append('cr_state_id',Data?.cr_state?.id);}
  if(Data?.cr_address ){data.append('cr_address',Data?.cr_address);}

  data.append('onboard', navigate ? true : false);  
  data.append('company_description',Data.description);
  data.append('company_type',Data?.company_type?.id);
  data.append('company_phone',Data.phone_no);
  data.append('company_email',Data.email);
  if(Data.pan){
    data.append('company_pan',Data.pan);
  }
  
  if(Data.gst){
    data.append('company_gst',Data.gst);
  }
 
  data.append('updated_by',Data.updated_by);
  data.append('company_logo', Data.company_logo);
  data.append('id',Data.id);
  
  return async (dispatch) => {
    dispatch(companyLoading());
    axios({
      method: 'post',
      url: `${SERVER_URL}/company/update-company-profile`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        
        if (res.status === 200) {
         
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })

          
          dispatch(CompanyDetail(token, Data.id))
          if(navigate){
            navigate("/")
          }
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(companyFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };
}

export function UpdateCompanyBasic (token, Data, navigate = null){

  const data = new FormData();
  
  if(Data.address){data.append('address',Data.address);}
   

  data.append('company_phone',Data.phone_no);
  data.append('company_email',Data.email);
  if(Data.pan){
    data.append('company_pan',Data.pan);
  }
  
  if(Data.gst){
    data.append('company_gst',Data.gst);
  }
 
  data.append('updated_by',Data.updated_by);
  data.append('company_logo', Data.company_logo);
  data.append('id',Data.id);
  
  return async (dispatch) => {
   
    axios({
      method: 'post',
      url: `${SERVER_URL}/company/update-company-basic-info`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        
        if (res.status === 200) {
         
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })

          
          dispatch(CompanyDetail(token, Data.id))
          if(navigate){
            navigate("/")
          }
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(companyFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };


}

export function UpdateCompanyAdress (token, Data, navigate = null){

  const data = new FormData();
  
  if(Data.address){data.append('address',Data.address);}
   

 
 
  data.append('updated_by',Data.updated_by);
  data.append('city_id',Data.city.id);
  data.append('state_id',Data.state.id);
  data.append('pin_code',Data.pin_code);
  
  data.append('id',Data.id);
  
  return async (dispatch) => {
   
    axios({
      method: 'post',
      url: `${SERVER_URL}/company/update-company-address-details`,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
      data
    })
      .then((res) => {
        
        if (res.status === 200) {
         
          Success.fire({
            text: "html",
            html: `<span style="color:white">${res.data.message}</span>`,
          })

          
          dispatch(CompanyDetail(token, Data.id))
          if(navigate){
            navigate("/")
          }
        }
      }).catch((error) => {
        if (error?.response) {
          Errors.fire({
            text: "html",
            html: `<span style="color:white">${error.response.data.message}</span>`,
          })
          dispatch(companyFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
        }
      });
  };


}

/**
 * [2022-03-21]
 * Created By:-Santosh Mahule
 * Description:- Update Company Address
 * [2023-03-07]
 * changes by :Aanchal Sahu
 * description: Cannot see any use of this action in the product
 */
// export function UpdateCompanyAddress(token, Data, navigate) {  
//   const data = new FormData();
//   data.append('pin_code',Data.pincode);
//   data.append('city_id',Data.city);
//   data.append('state_id',Data.state);
//   data.append('address',Data.address);  
//   data.append('updated_by',Data.updated_by);
//   data.append('company_logo',Data.company_logo);
//   data.append('id',Data.company_id);
//   return async (dispatch) => {
//     dispatch(companyLoading());
//     axios({
//       method: 'post',
//       url: `${SERVER_URL}/company/update-company-address`,
//       headers: {
//         "Authorization": 'Bearer ' + token,
//       },
//       data
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           Success.fire({
//             text: "html",
//             html: `<span style="color:white">${res.data.message}</span>`,
//           })
//           //dispatch(companySuccess(res.data))
//           dispatch(CompanyDetail(token, Data.company_id))        
//         }
//       }).catch((error) => {
//         if (error?.response) {
//           Errors.fire({
//             text: "html",
//             html: `<span style="color:white">${error.response.data.message}</span>`,
//           })
//           dispatch(companyFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }

export function companyLoading() {
  return {
    type: COMPANY_LOADING,
  };
}
export function companySuccess(payload) {
  return {
    type: COMPANY_SUCCESS,
    payload
  };
}

export function companyFailure(payload) {
  return {
    type: COMPANY_FAILURE,
    payload
  };
}

/**
 * [2023-03-07]
 * changes by :Aanchal Sahu
 * INFO FOR COMMENTING: Cannot see any use of this action in the product
 */
// export function SaveAttendanceSetting(token, Data) {  
//   const data = new FormData();
//   data.append('office_open_time',Data.startTime);
//   data.append('office_close_time',Data.endTime);
//   data.append('working_hours',Data.workingHour);
//   data.append('attendance_regularization_count',Data.regularization);  
//   data.append('updated_by',Data.updated_by);
//   data.append('present_min_hour',Data.present);
//   data.append('min_halfday_hour',Data.halfDay);
//   return async (dispatch) => {
//     dispatch(companyLoading());
//     axios({
//       method: 'post',
//       url: `${SERVER_URL}/company/company-attendance-setting`,
//       headers: {
//         "Authorization": 'Bearer ' + token,
//       },
//       data
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           Success.fire({
//             text: "html",
//             html: `<span style="color:white">${res.data.message}</span>`,
//           })
//           //dispatch(companySuccess(res.data))
//           dispatch(CompanyDetail(token, Data.companyId))       
//         }
//       }).catch((error) => {
//         if (error?.response) {
//           Errors.fire({
//             text: "html",
//             html: `<span style="color:white">${error.response.data.message}</span>`,
//           })
//           dispatch(companyFailure(error.response.data));
//           if (error?.response?.status == 401) {
//             dispatch(Logout())
//           }
//         }
//       });
//   };
// }

