import {
  PAYROLL_REPORT_LIST_FAILURE, PAYROLL_REPORT_LIST_SUCCESS, PAYROLL_REPORT_LIST_LOADING,
} from '../constants';


const INITIAL_STATE = {
  payrollReportData: {},
  payrollReportSuccess: false,
  payrollReportLoading: false,
  payrollReportMessage: null,
};

const PayrollReportReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case PAYROLL_REPORT_LIST_LOADING:
      return {
        ...state,
        payrollReportLoading: true,
        payrollReportData: {}
      };

    case PAYROLL_REPORT_LIST_SUCCESS:
      return {
        ...state,
        payrollReportLoading: false,
        payrollReportSuccess: true,
        payrollReportData: action.payload.data,
        payrollReportMessage: action.payload.message,
      };

    case PAYROLL_REPORT_LIST_FAILURE:
      return {
        ...state,
        payrollReportLoading: false,
        payrollReportSuccess: false,
        payrollReportMessage: action.payload.message,
      };

    default:
      return state;
  }
};

export default PayrollReportReducer;
