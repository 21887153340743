

import { Grid, Link } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { EButtonOutlined } from '../../BaseComponents/EButtons';
import { ETypography } from '../../BaseComponents/ETypography';
import { numberWithCommas } from '../../utils/formatNumber';
import { EIcon } from '../../BaseComponents/EIcon';
// import AttachFile from '@iconify/icons-material-symbols/attach-file';
import AttachFile from '@iconify/icons-material-symbols/file-present-rounded';
import { APPROVED_STATUS, CANCELLED_STATUS, COMPLETE_STATUS, IMAGE_URL, PAYMENT_CATEGORY_ADVANCE, PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_TRANSACTION_APPROVAL_STATUS, PAYMENT_TRANSACTION_APPROVAL_STATUS_COLOR, PAYMENT_TRANSACTION_PAYMENT_MEDIUM, PAYMENT_TRANSACTION_PAYMENT_STATUS, PAYMENT_TRANSACTION_PAYMENT_STATUS_COLOR, PAYMENT_TRANSACTION_STATUS, PENDING_STATUS } from '../../constants';
import { ELabelInputMainGrid } from '../../BaseComponents/EGrid';
import moment from 'moment';
import { EBox } from '../../BaseComponents/EBox';


const PaymentRequestView = (props) => {
  const { setModalState, auth, selectedData, getPaymentFilterData } = props;

  return (

    <Grid container spacing={2} >
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <ELabelInputMainGrid
          label={'Employee Name'}
          isModal={true}
          isfullgrid={true}
          isNotForm={true}
        >
          <EBox display="flex" gap={0.5}>
            <ETypography>{selectedData.modalData?.employeeData?.fullname}</ETypography>
            <ETypography noWrap className="text-capitalize blue-color font-weight-400-with-imp"
            >
              ({selectedData.modalData?.employeeData?.employee_code ? selectedData.modalData?.employeeData?.employee_code : '-'})
            </ETypography>
          </EBox>
        </ELabelInputMainGrid>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <ELabelInputMainGrid
          label={'Request Date'}
          isModal={true}
          isfullgrid={true}
          isNotForm={true}
        >
          <ETypography>{moment(selectedData.modalData?.transaction_date).format('DD/MM/YYYY')}</ETypography>
        </ELabelInputMainGrid>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <ELabelInputMainGrid
          label={'Request Amount'}
          isModal={true}
          isfullgrid={true}
          isNotForm={true}
        >
          <ETypography>{numberWithCommas(selectedData.modalData?.pay_amount)}</ETypography>
        </ELabelInputMainGrid>
      </Grid>
      {/* {
        selectedData.modalData?.transaction_category === PAYMENT_CATEGORY_ADVANCE &&
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ELabelInputMainGrid
            label={'Previous Advance'}
            isModal={true}
            isfullgrid={true}
            isNotForm={true}
          >
            <ETypography>{numberWithCommas(previous_advance)}</ETypography>
          </ELabelInputMainGrid>
        </Grid>
      } */}
      {
        selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT &&
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ELabelInputMainGrid
            label={'Attachment'}
            isModal={true}
            isfullgrid={true}
            isNotForm={true}
          >
            <div>
              {selectedData.modalData?.reimbursement_attachment ? (
                <Link target={'_blank'} href={
                  selectedData.modalData?.reimbursement_attachment}>
                  <EIcon icon={AttachFile} className='theme-color-static' />
                </Link>) : <></>}
            </div>

          </ELabelInputMainGrid>
        </Grid>
      }
      {
        selectedData.modalData?.transaction_category == PAYMENT_CATEGORY_REIMBURSEMENT &&
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ELabelInputMainGrid
            label={'Bill Date'}
            isModal={true}
            isfullgrid={true}
            isNotForm={true}
          >
            <ETypography>{selectedData.modalData?.reimbursement_bill_date ? moment(selectedData.modalData?.reimbursement_bill_date).format('DD/MM/YYYY') : '-'}</ETypography>
          </ELabelInputMainGrid>
        </Grid>
      }
      {
        [PAYMENT_CATEGORY_REIMBURSEMENT, PAYMENT_CATEGORY_ADVANCE].includes(selectedData.modalData?.transaction_category) &&
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ELabelInputMainGrid
            label={'Reason'}
            isModal={true}
            isfullgrid={true}
            isNotForm={true}
          >
            <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData.remark ? selectedData.modalData.remark : '-'}</ETypography>
          </ELabelInputMainGrid>
        </Grid>
      }
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <ELabelInputMainGrid
          label={'Approval Status'}
          isModal={true}
          isfullgrid={true}
          isNotForm={true}
        >
          <ETypography
            fontWeight={600}
            className={`${PAYMENT_TRANSACTION_APPROVAL_STATUS_COLOR[selectedData.modalData?.transaction_status]}`}
          >
            {PAYMENT_TRANSACTION_APPROVAL_STATUS[selectedData.modalData?.transaction_status]}
          </ETypography>
          <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData.transaction_status_remark}</ETypography>
        </ELabelInputMainGrid>
      </Grid>

      {
        (
          selectedData.modalData?.transaction_status === APPROVED_STATUS ||
          selectedData.modalData?.transaction_status === COMPLETE_STATUS
        ) &&
        <Grid item xs={12} sm={12} lg={12} xl={12}>
          <ELabelInputMainGrid
            label={'Payment Status'}
            isModal={true}
            isfullgrid={true}
            isNotForm={true}
          >
            <EBox display="flex" gap={0.5}>
              <ETypography
                fontWeight={600}
                className={`${PAYMENT_TRANSACTION_PAYMENT_STATUS_COLOR[selectedData.modalData?.transaction_status]}`}
              >
                {PAYMENT_TRANSACTION_PAYMENT_STATUS[selectedData.modalData?.transaction_status]}
              </ETypography>
              {
                selectedData.modalData?.transaction_status === COMPLETE_STATUS &&
                <ETypography>
                  (By {PAYMENT_TRANSACTION_PAYMENT_MEDIUM[selectedData.modalData?.payment_medium]}
                  {selectedData.modalData?.payment_date ? " - " + moment(selectedData.modalData?.payment_date).format('DD/MM/YYYY') : ""}
                  )
                </ETypography>
              }
            </EBox>
            <ETypography sx={{ wordBreak: 'break-all' }}>{selectedData.modalData.accountant_remark}</ETypography>
          </ELabelInputMainGrid>
        </Grid>
      }
      <Grid item xs={12} sm={12} lg={12} xl={12}>
        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
          <EButtonOutlined
            size="large"
            variant="outlined"
            onClick={() => setModalState({ modalData: null, modalName: null })}
          >
            Close
          </EButtonOutlined>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default PaymentRequestView
