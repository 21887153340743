import { Stack } from "@mui/system";
import { EButtonOutlined, ELoadingButton } from "../../BaseComponents/EButtons";
import { useFormik, Form, FormikProvider } from 'formik';
import { EFieldLabel, ETypography } from "../../BaseComponents/ETypography";
import { ETextField } from "../../BaseComponents/ETextField";
import { Grid } from "@mui/material";
import { FieldContainerCustom } from "../../BaseComponents/EFieldContainer";
import { ReasonValidation } from "../../utils/validation";
import { useDispatch } from "react-redux";
import moment from "moment";
import { RELEASED_USER } from "../../constants";
import { useNavigate } from "react-router-dom";
import { DeleteTalentAction } from "../../action/TalentActions";


const ReleaseTalentModal = (props) => {
    const { auth, employeeData, setShowReleaseModal } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const formik = useFormik({        
        initialValues: {
            id: employeeData?.id,
            remark:''
        },
       validationSchema: ReasonValidation,
        onSubmit: (data) => {
            if (employeeData?.id) {
                data={...data,trash_remark:data?.remark}
                // let employee_status = RELEASED_USER;
                dispatch(DeleteTalentAction(auth.authtoken, employeeData?.id, navigate, RELEASED_USER,data));
            }
        }
    });


    const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;



    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                {/* <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                    asd
                </Grid> */}
                <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                   
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabel>Release Date</EFieldLabel>
                            <ETypography>{moment().format('DD/MM/YYYY')}</ETypography>
                        </FieldContainerCustom>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabel>Remark</EFieldLabel>
                            <ETextField
                                name='remark'
                                multiline
                                rows={3}
                                {...getFieldProps('remark')}
                                fullWidth
                                error={Boolean(touched.remark && errors.remark)}
                                helperText={touched.remark && errors.remark}
                            />
                        </FieldContainerCustom>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                    <EButtonOutlined size="large" variant="outlined"
                        onClick={() => setShowReleaseModal(false)}
                    > Cancel </EButtonOutlined>
                    <ELoadingButton
                        size="large" variant="outlined" type="submit" >Release</ELoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

export default ReleaseTalentModal