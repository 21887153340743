
import {
    EMP_ATTENDANCE_FAILURE, EMP_ATTENDANCE_SUCCESS, EMP_ATTENDANCE_LOADING, EMP_ATTENDANCE_EMPTY
} from '../constants';


const INITIAL_STATE = {
    empMonthAttData: {},
    empMonthAttSuccess: false,
    empMonthAttLoading: false,
    empMonthAttMessage: null,
};

const EmployeeMonthAttendanceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case EMP_ATTENDANCE_LOADING:
            return {
                ...state,
                empMonthAttLoading: true,
                empMonthAttData: {}
            };

        case EMP_ATTENDANCE_SUCCESS:
            return {
                ...state,
                empMonthAttLoading: false,
                empMonthAttSuccess: true,
                empMonthAttData: action.payload.data,
                empMonthAttMessage: action.payload.message,
            };

        case EMP_ATTENDANCE_FAILURE:
            return {
                ...state,
                empMonthAttLoading: false,
                empMonthAttSuccess: false,
                empMonthAttMessage: action.payload.message,
            };
            
        case EMP_ATTENDANCE_EMPTY:
            return {
                ...state,
                empMonthAttLoading: false,
                empMonthAttSuccess: false,
                empMonthAttData: {}
            };
        default:
            return state;
    }
};

export default EmployeeMonthAttendanceReducer;
