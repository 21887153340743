import axios from 'axios';
import {
  SERVER_URL,
  GET_DUE_PAYMENT_FAILURE, GET_DUE_PAYMENT_SUCCESS, GET_DUE_PAYMENT_LOADING,
  GET_INVOICE_HISTORY_FAILURE, GET_INVOICE_HISTORY_SUCCESS, GET_INVOICE_HISTORY_LOADING,
} from '../constants';
import { Logout } from './AuthAction';


export function getDuePayment(token, setLoading = () => null) {

  return (dispatch) => {
    setLoading(true)
    dispatch(getDuePaymentLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/plan/get-invoices?bill_status=0`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getDuePaymentSuccess(res.data))
        }

      }).catch((error) => {
        if (error?.response) {
          dispatch(getDuePaymentFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
          // Above if block was commented
        }
      }).finally(() => setLoading(false))
  };
}

export function getDuePaymentLoading() {
  return {
    type: GET_DUE_PAYMENT_LOADING,
  };
}
export function getDuePaymentSuccess(payload) {
  return {
    type: GET_DUE_PAYMENT_SUCCESS,
    payload
  };
}

export function getDuePaymentFailure(payload) {
  return {
    type: GET_DUE_PAYMENT_FAILURE,
    payload
  };
}

export function getInvoiceHistory(token, setLoading = () => null) {

  return (dispatch) => {
    setLoading(true)
    dispatch(getInvoiceHistoryLoading());
    axios({
      method: 'get',
      url: `${SERVER_URL}/plan/get-invoices?bill_status=1`,
      crossDomain: true,
      headers: {
        "Authorization": 'Bearer ' + token,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getInvoiceHistorySuccess(res.data))
        }

      }).catch((error) => {
        if (error?.response) {
          dispatch(getInvoiceHistoryFailure(error.response.data));
          if (error?.response?.status == 401) {
            dispatch(Logout())
          }
          // Above if block was commented
        }
      }).finally(() => setLoading(false))
  };
}

export function getInvoiceHistoryLoading() {
  return {
    type: GET_INVOICE_HISTORY_LOADING,
  };
}
export function getInvoiceHistorySuccess(payload) {
  return {
    type: GET_INVOICE_HISTORY_SUCCESS,
    payload
  };
}

export function getInvoiceHistoryFailure(payload) {
  return {
    type: GET_INVOICE_HISTORY_FAILURE,
    payload
  };
}
