import React from 'react'
import { useFormik, Form, FormikProvider } from 'formik';
// import { Stack, TextField, Grid, Typography } from '@mui/material';;
// import { LoadingButton } from '@mui/lab';
import { useDispatch } from "react-redux";
import { Grid, Stack } from '@mui/material';
import { EButtonOutlined, ELoadingButton } from '../../BaseComponents/EButtons';
import { EFieldLabel, EFieldLabelBold } from '../../BaseComponents/ETypography';
import { EBox } from '../../BaseComponents/EBox';
import { FieldContainerCustom } from '../../BaseComponents/EFieldContainer';
import { ETextField } from '../../BaseComponents/ETextField';
import { UpdateAdvanceDeductionValidation } from '../../utils/validation';
import { updateAdvanceDeductionAction } from '../../action/PayrollAction';
// import { numberWithCommas } from '../../utils/formatNumber';
/**
 * [2022-08-18]
 * Author:- Aanchal Sahu
 * Description:- added UpdateAdvanceDeduction and made it dynamic
 **/
const ModalAdvanceDeductionUpdate = (props) => {

    const { auth, attUpdateParams, payrollId, isPayroll, setAdvDeductionStatus } = props
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            amount: attUpdateParams?.previousAdvance?parseFloat(attUpdateParams?.value.replace(/,/g, "")) :0,
            previous_advance:attUpdateParams?.previousAdvance?attUpdateParams?.previousAdvance:0,
            advance_return_id: attUpdateParams?.advanceReturnId ? attUpdateParams?.advanceReturnId : 0,
            payment_log_id : attUpdateParams?.previousAdvance?attUpdateParams?.previousAdvance:0, 
            attendance_remark:'',
        },
        validationSchema: UpdateAdvanceDeductionValidation,
        onSubmit: (data) => {
            data = {...data, employee_id: attUpdateParams?.empid, payroll_id: payrollId}
            dispatch(updateAdvanceDeductionAction(auth.authtoken,isPayroll,data ))
            setAdvDeductionStatus(false)
            // close();
        }
    });

    const { errors, touched, handleSubmit,  getFieldProps } = formik;

    return (
        <FormikProvider value={formik} >
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Grid container spacing={2} sx={{ paddingTop: "10px" }} >
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Previous Advance</EFieldLabelBold>
                            <EBox><EFieldLabelBold>₹ {getFieldProps('previous_advance').value}</EFieldLabelBold></EBox>
                            
                        </FieldContainerCustom>
                    </Grid>

                      <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabel>Advance Deduction* </EFieldLabel>
                            <ETextField
                                fullWidth
                                // multiline
                                id="outlined-name"
                                name="Amount"
                                // onChange={(e) => console.log(e.target.value)}
                                // rows={3}
                                // label="Remark"
                                {...getFieldProps('amount')}
                                error={Boolean(touched.amount && errors.amount)}
                                helperText={touched.amount && errors.amount}
                                InputLabelProps={{ shrink: true }}  

                            />
                        </FieldContainerCustom>
                    </Grid> 
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={33}
                        >
                            <EFieldLabelBold>Current Advance</EFieldLabelBold>
                            <EBox><EFieldLabelBold>₹ {   getFieldProps('amount').value > 0 ? (getFieldProps('previous_advance').value - getFieldProps('amount').value) : 0}</EFieldLabelBold></EBox>
                            
                        </FieldContainerCustom>
                    </Grid>
                    
                    
                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <FieldContainerCustom
                            labelwidth={50}
                        >
                            <EFieldLabel>Remark </EFieldLabel>
                            <ETextField
                                fullWidth
                                multiline
                                id="outlined-name"
                                name="attendance_remark"
                                rows={3}
                                // label="Remark"
                                {...getFieldProps('attendance_remark')}
                                error={Boolean(touched.amount && errors.attendance_remark)}
                                helperText={touched.attendance_remark && errors.attendance_remark}
                                InputLabelProps={{ shrink: true }}  

                            />
                        </FieldContainerCustom>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                        <Stack direction='row' spacing={2} paddingTop={2} justifyContent='flex-end'>
                            <EButtonOutlined size="large" variant="outlined"
                                onClick={() => setAdvDeductionStatus(false)}
                            > Cancel </EButtonOutlined>
                            <ELoadingButton
                                // loading={holiday?.holidayLoading} 
                                size="large" variant="outlined" type="submit" >Update</ELoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
        )
}

export default ModalAdvanceDeductionUpdate


