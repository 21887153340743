import { useFormik, Form, FormikProvider } from "formik";
import { FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { ELabelInputMainGrid } from "../../../BaseComponents/EGrid";
import { Stack } from "@mui/system";
import { EButton, EButtonOutlined } from "../../../BaseComponents/EButtons";
import { ETypography } from "../../../BaseComponents/ETypography";
import { ERadio, ERadioGroup } from "../../../BaseComponents/ERadio";
import { EDivider } from "../../../BaseComponents/EDivider";
import { useState } from "react";
import { ETextField } from "../../../BaseComponents/ETextField";

function AddTalentForm(props) {

    const { close } = props;
    const [level, setLevel] = useState(0)

    const formik = useFormik({
        initialValues: {
            hey: '',

        },
        // validationSchema: AccountBillingInfoValidation,
        onSubmit: (data) => {
            // calling action
            // console.log(data, 'dataaaaaaaaaaaaaaaaaa');
        },
    });

    // Formik Props
    const { getFieldProps, handleSubmit, touched, errors } = formik;

    const FormLevelFunction = (val) => {
        setLevel(val)
    }

    return (
        <FormikProvider value={formik}>
            <Form noValidate onSubmit={handleSubmit}>
                {level == 0 ?
                    <Grid container spacing={2} sx={{ paddingTop: "10px" }}>

                        {/* Subscription Tenure */}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Subscription Tenure"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center">STARTUP
                                    <span className="font-size-12px ml-4px "> (Jan 2023 to Dec 2023)</span>
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Existing Plan */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={"Existing Plan"} isModal isfullgrid isNotForm >
                                <ETypography>Jan 2023 to Dec 2023</ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Bill Period */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={"Bill Period"} isModal isfullgrid labelPT={'0.1rem'}>
                                <FormControl component="fieldset" error={Boolean(touched.leave_is_multiple && errors.leave_is_multiple)} className='mb-minus-10px mt-minus-6px'>
                                    <ERadioGroup row={true} aria-label="leave_is_multiple" name="leave_is_multiple" {...getFieldProps('leave_is_multiple')} >
                                        <FormControlLabel value={false} control={
                                            <ERadio size="small"
                                            // onChange={() => { OnIsLeaveMultipleChange(false) }}
                                            />
                                        } label='Single Day' />
                                        <FormControlLabel value={true} control={
                                            <ERadio size="small"
                                            // onChange={() => { OnIsLeaveMultipleChange(true) }}
                                            />
                                        } label="Multiple Days" />
                                    </ERadioGroup>
                                    {touched.leave_is_multiple && <FormHelperText> {errors.leave_is_multiple}</FormHelperText>}
                                </FormControl>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Add Talent  */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={"Add Talent "} isModal isfullgrid  >
                                <ETextField
                                    fullWidth
                                    autoComplete="off"
                                    type="text"
                                    placeholder="Username"
                                    {...getFieldProps("username")}
                                    error={Boolean(touched.username && errors.username)}
                                    helperText={touched.username && errors.username}
                                // InputLabelProps={{ shrink: true }}
                                />
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Amount */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ELabelInputMainGrid label={"Amount"} isModal isfullgrid isNotForm >
                                <ETypography>1800 (₹ 100.00 per license/month)</ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Divider */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <EDivider className="borderColor-staticBorder7" />
                        </Grid>

                        {/* Note */}
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <ETypography className="font-size-16px mb-8px">Note</ETypography>
                            <ul className="pl-24px font-size-14px">
                                {NOTE_DATA && NOTE_DATA?.map((content, index) => {
                                    return (
                                        <li key={index}>
                                            {content}
                                        </li>
                                    )
                                })
                                }
                            </ul>
                        </Grid>
                    </Grid>
                    : <Grid container spacing={2} sx={{ paddingTop: "10px" }}>

                        {/* Billing Period */}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Billing Period"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center"> Monthly / Existing Annual Plan
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/*Tenure */}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Tenure"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center"> Jul 2023 to Dec 2023
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Add Talent*/}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Add Talent"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center"> 3
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Amount*/}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Amount"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center"> ₹ 1800.00
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* GST(18%) */}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"GST(18%)"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center"> ₹ 324.00
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>

                        {/* Total Amount */}
                        <Grid item xs={12} sm={12} lg={12} xl={12} >
                            <ELabelInputMainGrid label={"Total Amount"} isModal isfullgrid isNotForm >
                                <ETypography className="display-flex align-center">₹ 2134.00
                                </ETypography>
                            </ELabelInputMainGrid>
                        </Grid>
                    </Grid>
                }
                {/*Form Action */}
                <Stack
                    direction="row"
                    spacing={2}
                    paddingTop={2}
                    justifyContent="flex-end"
                    className="pb-24px"
                >


                    <EButtonOutlined size="large" variant="outlined" onClick={close}>
                        Cancel
                    </EButtonOutlined>
                    {level == 1 &&
                        <EButtonOutlined size="large" variant="outlined" onClick={() => FormLevelFunction(0)}>
                            Back
                        </EButtonOutlined>
                    }
                    {level == 0 ?
                        <EButton size="large" variant="contained" onClick={() => FormLevelFunction(1)}>
                            Proceed
                        </EButton>
                        :
                        <EButton size="large" type="submit" variant="contained">
                            Pay Now
                        </EButton>
                    }
                </Stack>
            </Form>
        </FormikProvider>
    );
}

export default AddTalentForm;

const NOTE_DATA = [
    '"Existing Plan" refers to when we add more talents to the current plan and calculate the cost by multiplying the days left with the number of talents added.',
    '"Monthly Bill" refers to a separate invoice generated each month for additional talents.'
]